import "./PastPerformanceReviews.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
import PerformanceReview from "../../models/PerformanceReview"
import CompensationAnswersModal from "./CompensationAnswersModal"
import EvaluationsTable, {
  HEADER_CELLS,
} from "../EvaluationsTable/EvaluationsTable"
import Loader from "../../components/Loader/Loader"
import EmptyState from "../../components/EmptyState/EmptyState"
import emptyTeamEvaluationsImg from "../../static/images-2017/drawings/emptystates/evals.png"

export default class PastPerformanceReviews extends React.Component {
  static propTypes = {
    reviews: PropTypes.arrayOf(PropTypes.instanceOf(PerformanceReview)),
    revieweeName: PropTypes.string,
    emptyState: PropTypes.node,
  }

  constructor(props) {
    super(props)

    this.state = {
      reviewIdToShow: null,
    }
  }

  render() {
    const {
      props: { reviews, revieweeName, emptyState },
      state: { reviewIdToShow },
    } = this

    if (!reviews) {
      return <Loader />
    }

    if (reviews.length === 0) {
      return (
        emptyState || (
          <EmptyState
            header={
              <FormattedMessage {...strings.profile.evaluationsEmptyHeader} />
            }
            body={
              <FormattedMessage
                {...strings.profile.evaluationsEmptyBody}
                values={{ user: revieweeName }}
              />
            }
            imgSrc={emptyTeamEvaluationsImg}
          />
        )
      )
    }
    const reviewToShow = reviews.find(({ id }) => id === reviewIdToShow)

    return (
      <div className="PastPerformanceReviews">
        <EvaluationsTable
          reviews={reviews}
          getCycleForReview={(review) => review.performance_cycle}
          columns={[
            { ...HEADER_CELLS.cycleWithDate, toggleable: false, on: true },
            { ...HEADER_CELLS.bucket, toggleable: false, on: true },
          ]}
          sortable={false}
          isReviewSelectable={(review) => review.complete}
          onSelectReview={(review) =>
            this.setState({ reviewIdToShow: review.id })
          }
        />

        <CompensationAnswersModal
          review={reviewToShow}
          cycle={_.get(reviewToShow, "performance_cycle")}
          updateReviewAction={null}
          onClose={() => this.setState({ reviewIdToShow: null })}
        />
      </div>
    )
  }
}
