import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import cx from "classnames"
import _ from "lodash"
import User from "../../models/User"
import ProfilePic from "../ProfilePic/ProfilePic"
import Button from "../../components/Button/Button"
import SavingIndicator from "../../components/SavingIndicator/SavingIndicator"
import ConversationMessage from "./ConversationMessage"
import TextEditor from "../../components/TextEditor"
import DraftStore, { DraftSource } from "../../refluxStores/DraftStore"
import DraftActions from "../../refluxActions/DraftActions"
import strings from "../../locale/strings"
import { isNonBlank } from "../../utils/strings"
import { debounce } from "../../utils/timers"
import connect from "../../higherOrderComponents/connect"
import "./LeveledConversation.less"

class LeveledConversation extends React.Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    nested: PropTypes.bool,
    onSubmit: PropTypes.func,
    loadUsersForMention: PropTypes.func,
    lastSavedReplyId: PropTypes.number,
    canReply: PropTypes.bool,
  }

  static defaultProps = {
    canReply: true,
  }

  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      readyToReply: false,
      inputMarkdown: "",
      inputVersion: 0,
    }
  }

  componentDidMount() {
    const { comment } = this.props
    DraftActions.get({ parentObjType: "goal", parentObjId: comment.id }).then(
      () => {
        this.initInputFromDraft()
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputMarkdown !== this.state.inputMarkdown) {
      this.handleDraftEditing()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastSavedReplyId !== this.props.lastSavedReplyId) {
      this.setState((state) => ({
        inputMarkdown: null,
        inputVersion: state.inputVersion + 1,
      }))
    }

    const nextPropsDraft = this.getDraft({ props: nextProps })

    if (!this.getDraft(this) && nextPropsDraft) {
      this.initInputFromDraft({ props: nextProps })
    }
  }

  getDraft({ props } = this) {
    const {
      comment,
      draftData: { draftsBySource },
    } = props
    return draftsBySource.get(
      DraftSource({ parentObjType: "goal", parentObjId: comment.id })
    )
  }

  initInputFromDraft({ props } = this) {
    const body = _.get(this.getDraft({ props }), "body") || ""
    this.setState((state) => ({
      inputMarkdown: body,
      inputVersion: state.inputVersion + 1,
      readyToReply: !!body,
    }))
  }

  @debounce(2000)
  saveDraft() {
    const { comment } = this.props
    const { inputMarkdown } = this.state
    DraftActions.save({
      parentObjType: "goal",
      parentObjId: comment.id,
      body: inputMarkdown,
    })
  }

  createMessageList = (comment) => {
    const {
      author: { user },
      created_at: createdAt,
      private: isPrivate,
      id,
      body,
      child_comments: comments,
    } = comment

    return (
      <ConversationMessage
        key={id}
        body={body}
        timestamp={createdAt}
        id={id}
        sender={user}
        comments={comments}
        isPrivate={isPrivate}
      />
    )
  }

  handleOnChange = (value) => {
    this.setState({ inputMarkdown: value })
  }

  handleSubmit = (comment) => {
    const { inputMarkdown } = this.state
    const { onSubmit } = this.props
    if (isNonBlank(inputMarkdown)) {
      onSubmit(inputMarkdown, comment)
      this.setState({
        inputMarkdown: "",
        inputVersion: 0,
        readyToReply: false,
      })
    }
  }

  handleLoadUsersForMention(query) {
    const { loadUsersForMention } = this.props
    return loadUsersForMention(query)
  }

  handleDraftEditing() {
    const { comment } = this.props
    DraftActions.notifyEditing({
      parentObjType: "goal",
      parentObjId: comment.id,
    })
    this.saveDraft()
  }

  renderReplyForm(comment) {
    const {
      nested,
      intl: { formatMessage },
    } = this.props
    const { inputMarkdown, inputVersion } = this.state
    const { saveState } = this.getDraft() || {}
    const replyRowClasses = cx(
      {
        "LeveledConversation--reply-action-row-active": isNonBlank(
          inputMarkdown
        ),
      },
      "LeveledConversation--reply-action-row layout horizontal center end-justified"
    )

    return (
      <div
        className={`LeveledConversation--reply-form${nested ? "-nested" : ""}`}
      >
        <div className="layout horizontal">
          <ProfilePic className="flex none" user={this.context.user} />

          <div className="flex one">
            <TextEditor
              key={inputVersion}
              className="LeveledConversation--reply-text-editor"
              placeholder={formatMessage(strings.general.commentPlaceholder)}
              initialValue={inputMarkdown}
              onChange={this.handleOnChange}
              hasEmojiPicker={true}
              loadUsersForMention={(query) =>
                this.handleLoadUsersForMention(query)
              }
            />
          </div>
        </div>
        {
          <div className={replyRowClasses}>
            <SavingIndicator saveState={saveState} />
            <Button
              className="LeveledConversation--send-button"
              actionType="secondary"
              onClick={() => this.handleSubmit(comment)}
            >
              <FormattedMessage {...strings.general.send} />
            </Button>
          </div>
        }
      </div>
    )
  }

  renderComment = (comment, nested) => {
    const { readyToReply } = this.state
    const { canReply } = this.props
    const sender = comment.author.user
    const isDeleted = sender === null
    const isAnon = !isDeleted && !sender.email

    const isPrivate = comment.private
    const hasNastedComments =
      comment.child_comments && comment.child_comments.length > 0

    const isOwnComment = sender.id === this.context.user.id

    let author
    if (isAnon) {
      author = <FormattedMessage {...strings.general.anonymous} />
    } else if (isDeleted) {
      author = <FormattedMessage {...strings.user.deleted} />
    } else {
      author = User.getDisplayName(sender)
    }
    return (
      <div
        key={`container-${comment.id}`}
        className={`LeveledConversation--group layout vertical`}
      >
        <div key={comment.id} className="layout horizontal">
          <ProfilePic
            className="LeveledConversation--profile-pic flex none"
            user={sender}
          />
          <div className="LeveledConversation--content layout vertical start flex one">
            <div className="layout horizontal">
              {!isDeleted ? (
                <div className="LeveledConversation--author">{author}</div>
              ) : (
                <i className="LeveledConversation--author">{author}</i>
              )}
              {isPrivate && !isOwnComment && (
                <div className="LeveledConversation--author-private-indicator">
                  <FormattedMessage
                    {...strings.goals.conversations.privateLabel}
                    values={{ user: sender.best_name }}
                  />
                </div>
              )}
              {isPrivate && isOwnComment && (
                <div className="LeveledConversation--own-private-indicator">
                  <FormattedMessage
                    {...strings.goals.conversations.ownCommentPrivateLabel}
                  />
                </div>
              )}
              {sender && User.isDeactivated(sender) && (
                <div className="LeveledConversation--author-deactivated">
                  <FormattedMessage {...strings.user.deactivated} />
                </div>
              )}
            </div>
            <div className="LeveledConversation--messages">
              {this.createMessageList(comment)}
            </div>
            {hasNastedComments &&
              !nested &&
              _.map(comment.child_comments, (comment) =>
                this.renderComment(comment, true)
              )}
          </div>
        </div>
        {!isPrivate && !nested && canReply && (
          <div className="LeveledConversation--reply">
            <div
              className={`LeveledConversation--reply-text${
                hasNastedComments ? "-nested" : ""
              }`}
              onClick={() => this.setState({ readyToReply: !readyToReply })}
            >
              <FormattedMessage {...strings.goals.conversations.reply} />
            </div>
            {readyToReply && this.renderReplyForm(comment)}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { comment, nested } = this.props
    if (!comment) {
      return null
    }
    return (
      <div className={`LeveledConversation layout vertical`}>
        {this.renderComment(comment, nested)}
      </div>
    )
  }
}

export default _.compose(
  connect(DraftStore, "draftData"),
  injectIntl
)(LeveledConversation)

export { LeveledConversation as RawLeveledConversation }
