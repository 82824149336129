import { Size } from "../types/Positioning"

// We want the popover arrow to be centered to the button, but the popover box
// should be aligned with the main body content.
export const calculatePopoverOffset = (
  rootDims: Size | null,
  popoverDims: Size | null
): number => {
  if (!rootDims || !popoverDims) return 0
  const diff = popoverDims.width - rootDims.width
  if (diff <= 0) return 0
  return diff * -0.5
}
