import { Text } from "@kaizen/component-library"
import * as React from "react"
import Avatar from "../Avatar/Avatar"
import styles from "./AvatarDetails.scss"

type AvatarDetailsProps = {
  bestName: string
  avatarURL: string
  jobTitle?: string
  avatarSize?: "small" | "medium" | "large" | "xlarge"
}

// Also see HeadingWithAvatar for a similar component, but with a heading font.
// Maybe these should be merged...
const AvatarDetails = ({
  bestName,
  avatarURL,
  jobTitle,
  avatarSize = "medium",
}: AvatarDetailsProps) => {
  return (
    <div className={styles.container}>
      <div>
        <Avatar size={avatarSize} imageURL={avatarURL} />
      </div>
      <div className={styles.details}>
        <Text tag="div" style="small-bold" inheritBaseline>
          {bestName}
        </Text>
        {jobTitle && (
          <div className={styles.jobTitle}>
            <Text tag="div" style="small" inheritBaseline>
              {jobTitle}
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

export default AvatarDetails
