import "./CollapsibleSection.less"
import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import ExpandIndicator from "../ExpandIndicator/ExpandIndicator"

export default class CollapsibleSection extends React.Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    className: PropTypes.string,
    numberOfItems: PropTypes.number,
    children: PropTypes.node,
  }

  static defaultProps = {
    className: "",
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  toggleSection = () => {
    this.setState((state) => ({ expanded: !state.expanded }))
  }

  render() {
    const { title, className, children, numberOfItems } = this.props
    const { expanded } = this.state

    return (
      <div className={`CollapsibleSection ${className}`}>
        <div
          className="CollapsibleSection--title layout horizontal center"
          onClick={this.toggleSection}
        >
          {title}
          {numberOfItems > 0 && (
            <div className="CollapsibleSection--number">{numberOfItems}</div>
          )}
          {children && <ExpandIndicator expanded={expanded} />}
        </div>

        <div
          className={cx("CollapsibleSection--content", { hidden: !expanded })}
        >
          {children}
        </div>
      </div>
    )
  }
}
