// Need this implementation file separate from
// SessionActions to prevent
// circular dependency between SessionStore and
// SteadyfootAgent

import SessionActions from "./SessionActions"
import UIActions, { loadingTypes } from "./UIActions"
// NOTE: import SteadyfootAgent after SessionActions
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
} from "./lib/apiActionHelpers"
import Settings from "../settings"
import strings from "../locale/strings"

const agent = SteadyfootAgent.defaultInstance

/* SessionActions Action handlers not allowed there because
 * SteadyfootAgent depends on SessionActions
===============================*/

function authenticate(payload) {
  return new Promise((resolve, reject) => {
    UIActions.setLoadingState(true, { light: true, type: loadingTypes.AUTH })

    agent
      .post("/oauth/token")
      .send({ client_id: Settings.ZUGATA_CLIENT_ID, ...payload })
      .end((err, res) => {
        UIActions.setLoadingState(false, { type: loadingTypes.AUTH })

        if (err) {
          reject(err)
        } else {
          resolve(res.body)
        }
      }, true)
  })
}

SessionActions.login.listenAndPromise(
  (credentials, { silent = false } = {}) => {
    const authPromise = authenticate({
      grant_type: "password",
      username: credentials.user.email,
      password: credentials.user.password,
    })

    if (silent) {
      return authPromise
    } else {
      return addToasts(
        { defaultError: strings.signin.genericLoginError },
        authPromise
      )
    }
  }
)

// Alias for "login", but with different failure semantics in the UI
SessionActions.autoLogin.preEmit = function (credentials) {
  SessionActions.login(credentials, { silent: true }).then(
    this.completed,
    this.failed
  )
}

SessionActions.exchangeAuthCode.preEmit = function (authCode) {
  authenticate({
    grant_type: "authorization_code",
    code: authCode,
    redirect_uri: Settings.BEACONSTAR_URL,
  }).then(
    (session) => {
      SessionActions.login.completed(session)
      SessionActions.autoLogin.completed(session)
      this.completed(session)
    },
    (err) => {
      const body = err.response && err.response.body
      SessionActions.login.failed(body, err)
      SessionActions.autoLogin.failed(body, err)
      this.failed(body, err)
    }
  )
}

SessionActions.confirmAndLogin.listenAndPromise((
  confirmation_token // eslint-disable-line camelcase
) =>
  addLoadingState(
    [true, { light: true, type: loadingTypes.AUTH }],
    addToasts(
      { defaultError: strings.signin.genericConfirmAccountError },
      extractResponseBody(
        agent.post("/oauth/token").send({
          confirmation_token,
          grant_type: "password",
          client_id: Settings.ZUGATA_CLIENT_ID,
        })
      )
    )
  )
)

SessionActions.refresh.listenAndPromise((session) =>
  addToasts(
    { defaultError: strings.signin.genericAuthenticationError },
    extractResponseBody(
      agent
        .post("/oauth/token")
        .send({
          grant_type: "refresh_token",
          refresh_token: session.refresh_token,
          client_id: Settings.ZUGATA_CLIENT_ID,
        })
        .skipAuth(true)
    ).then((body) => SessionActions.login.completed(body))
  )
)

SessionActions.logout.listenAndPromise((session) => {
  return SessionActions.logout.completed()
})

SessionActions.Saml.startLogin.listen(({ redirect_url: redirectUrl }) => {
  window.location = redirectUrl
})
