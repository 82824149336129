import React from "react"
import Aid from "../../constants/automationId"
// @ts-ignore
import Typeahead from "../../components/Typeahead/Typeahead"
// @ts-ignore
import TypeaheadRowGroup from "../TypeaheadRowGroup/TypeaheadRowGroup"
// @ts-ignore
import TypeaheadRowWithAvatar from "../TypeaheadRowWithAvatar/TypeaheadRowWithAvatar"
import { ReviewerSuggestion } from "../../api/decoders/teamFeedbackReviewersSuggestionsDecoder"

export type FeedbackOptionUser = {
  id: number
  name: string
  email: string
  avatar: string
  group: false
  aggregateId?: string
}

type FeedbackOptionGroup = {
  group: true
  count: number
  title: string
  users: FeedbackOptionUser[]
}

type FeedbackOption = FeedbackOptionUser | FeedbackOptionGroup

type FeedbackRequestTypeahead = React.FunctionComponent<{
  reviewers: FeedbackOptionUser[]
  setReviewers: (reviewers: FeedbackOptionUser[]) => void
  suggestions: ReviewerSuggestion[]
  placeholder: string
  title: string
}>

/**
 * This file is an abstraction of the Typeahead that was being used
 * initially inside of the TeamFeedbackRequest file. Abstracting this enables
 * us to add some flexibility and to reuse it for the updated SurveyFeedbackRequests
 * and AdminSurveyFeedbackRequest components.
 *
 * @param {*} {
 *   setReviewers,
 *   placeholder
 * }
 * @returns
 */
const FeedbackRequestTypeahead: FeedbackRequestTypeahead = ({
  reviewers,
  setReviewers,
  suggestions,
  placeholder,
  title,
}) => {
  return (
    <Typeahead
      automationId={Aid.teamBasedFeedbackTypeAhead}
      className="SurveyFeedbackRequests--reviewer-widget-dropdown flex"
      zugataStyle={true}
      //key={`${reviewers ? reviewers.length : 0}`}
      value={reviewers}
      placeholder={placeholder}
      // @ts-ignore
      valueComponent={(config) => {
        const { value, onRemove } = config
        return (
          <TypeaheadRowWithAvatar
            user={{
              full_name: value.name,
              email: value.email,
              profile_image_url: value.avatar,
            }}
            showEmail={false}
            clearable={true}
            onClear={() => onRemove(value)}
          />
        )
      }}
      // @ts-ignore
      optionRenderer={(option) =>
        option.group ? (
          <TypeaheadRowGroup count={option.count} title={option.title} />
        ) : (
          <TypeaheadRowWithAvatar
            user={{
              full_name: option.name,
              email: option.email,
              profile_image_url: option.avatar,
            }}
          />
        )
      }
      loadOptions={() => {
        const shouldIncludeGroupOption = suggestions.some(
          (s) => !reviewers.find((r) => r.id === s.id)
        )

        const results = shouldIncludeGroupOption
          ? [
              {
                group: true,
                count: suggestions.length,
                title: title,
                users: suggestions,
              },
              ...suggestions,
            ]
          : suggestions

        return Promise.resolve({
          results,
        })
      }}
      refreshOptionsOnFocus={true}
      clearable={false}
      allowCreate={false}
      searchable={true}
      multi={true}
      onChange={(options: FeedbackOption[]) => {
        const reviewers = options.reduce<FeedbackOptionUser[]>(
          (users, option) =>
            option.group ? [...option.users] : [...users, option],
          []
        )
        setReviewers(reviewers)
      }}
      labelKey="name"
      valueKey="email"
    />
  )
}

export default FeedbackRequestTypeahead
