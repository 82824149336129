export default function handleLazyLoadAction(
  store,
  action,
  { getLocalData, handleCompletion }
) {
  if (!(store && store.listenTo)) {
    throw new Error("The first argument must be a Reflux store")
  }

  if (!(action && action.completed && action.forceLoad)) {
    throw new Error(
      "The second argument must be an async Reflux action with a 'forceLoad' child action"
    )
  }

  if (!(getLocalData && typeof getLocalData === "function")) {
    throw new Error("The 'getLocalData' option must be supplied as a function!")
  }

  store.listenTo(action, function () {
    // eslint-disable-next-line prefer-rest-params
    const data = getLocalData.apply(store, arguments)

    if (data === null || data === undefined) {
      // eslint-disable-next-line prefer-rest-params,prefer-spread
      action.forceLoad.apply(action, arguments)
    } else {
      action.completed(data)
    }
  })

  if (handleCompletion) {
    store.listenTo(action.completed, handleCompletion.bind(store))
  }
}
