import React from "react"
import { withRouter, WithRouterProps } from "react-router"
import PropTypes from "prop-types"
import FeatureFlags from "../../constants/featureFlags"
import { getPathQueryHash, logRedirectWarning } from "../../utils/routing"
import { hasFlag } from "../../domain/user/user"
import { CurrentUser } from "../../types/User"

type Props = WithRouterProps

/**
 * A hoc version of feature flagging. Handy to use with the routes.jsx config.
 *
 * More info: docs/featureFlags.md
 */
export const featureToggle = ({
  flag,
  predicate,
  On,
  Off,
  onRedirect,
  offRedirect,
}: {
  // You may supply either a feature flag, or a predicate. Not zero, and not both.
  flag?: FeatureFlags
  predicate?: (user: CurrentUser) => boolean
  On?: React.ComponentType
  Off?: React.ComponentType
  onRedirect: string
  offRedirect: string
}) => {
  class FeatureToggleWrapper extends React.PureComponent<Props> {
    static contextTypes = {
      user: PropTypes.object.isRequired,
    }

    componentDidMount() {
      const { router } = this.props
      const { location } = this.props

      if (this._getIsOn()) {
        if (onRedirect) {
          logRedirectWarning(
            getPathQueryHash(location),
            onRedirect,
            `the "${flag}" feature flag is turned on`
          )
          router.replace(onRedirect)
        }
      } else {
        if (offRedirect) {
          logRedirectWarning(
            getPathQueryHash(location),
            offRedirect,
            `the "${flag}" feature flag is turned off`
          )
          router.replace(offRedirect)
        }
      }
    }

    _getIsOn = () => {
      if (!flag && !predicate) {
        throw new Error("Either a feature flag or predicate is required")
      }

      const { user } = this.context

      return (flag && hasFlag(user, flag)) || (predicate && predicate(user))
    }

    render() {
      if (this._getIsOn()) {
        return On ? <On {...this.props} /> : null
      } else {
        return Off ? <Off {...this.props} /> : null
      }
    }
  }

  return withRouter(FeatureToggleWrapper)
}
