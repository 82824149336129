import React from "react"
import { useQuery } from "@apollo/react-hooks"
import { Heading, Paragraph } from "@kaizen/component-library"
import { InjectedIntl, injectIntl } from "react-intl"
import strings from "../../locale/strings"
import styles from "./ProfileHeader.scss"
import Aid from "../../constants/automationId"
// @ts-ignore
import ProfilePicWrapper from "../ProfilePic/ProfilePic"
import { GET_EMPLOYEE_PROFILE } from "./ProfileHeader.gql"
import {
  GetEmployeeProfileQuery,
  GetEmployeeProfileQueryVariables,
} from "./__generated__/ProfileHeader.gql.generated"

export type Props = {
  intl: InjectedIntl
  extraSubtitle?: string
  userId?: number
}

const ProfileHeader = ({ intl, extraSubtitle, userId }: Props) => {
  const { formatMessage } = intl
  const { error, loading, data } = useQuery<
    GetEmployeeProfileQuery,
    GetEmployeeProfileQueryVariables
  >(GET_EMPLOYEE_PROFILE, {
    variables: { userId: userId?.toString() },
  })

  if (loading) return <p>{formatMessage(strings.toast.loading)}</p>
  if (error)
    return (
      <p>
        {formatMessage(strings.toast.error)}: {error.message}
      </p>
    )
  if (data === undefined) return <p>{formatMessage(strings.toast.error)}</p>

  return (
    <div
      className={styles.profileHeader}
      data-automation-id={Aid.profileHeaderContainer}
    >
      <Heading variant="heading-2">{data.employee?.fullName}</Heading>
      {!data.employee?.isActive && (
        <div
          className={styles.profileHeaderDeactivated}
          data-automation-id={Aid.profileHeaderDeactivated}
        >
          {formatMessage(strings.user.deactivated)}
        </div>
      )}
      <Paragraph variant="body" color="dark-reduced-opacity">
        {data.employee?.jobTitle?.title ? (
          <div data-automation-id={Aid.profileHeaderJobtTitle}>
            {data.employee?.jobTitle?.title}
          </div>
        ) : null}
        {extraSubtitle ? (
          <div data-automation-id={Aid.profileHeaderExtraSubtitle}>
            {extraSubtitle}
          </div>
        ) : null}
      </Paragraph>
      <ProfilePicWrapper
        className={styles.profileHeaderProfilePic}
        showHorizontalRule={true}
        user={{
          id: data.employee?.id,
          profile_image_url: data.employee?.profileImageUrl,
        }}
      />
    </div>
  )
}

export default injectIntl(ProfileHeader)
