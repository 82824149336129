import bytesToBinaryString from "./bytesToBinaryString"
const crypto = window.crypto || window.msCrypto

/**
 * Generates a random Base64-encoded string in a cryptographically-secure way.
 */
export function cryptoRandomString(numBytes) {
  const bytes = new Uint8Array(numBytes)
  crypto.getRandomValues(bytes)
  return btoa(bytesToBinaryString(bytes))
}
