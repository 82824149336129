import * as React from "react"
import { createContext, useContext } from "react"
import { injectIntl, InjectedIntl } from "react-intl"

// @ts-ignore: this will get injected immediately, so we'll assume that this will never actually be null
const RouterContext = createContext<InjectedIntl>(null)

// Added at App.js
export const IntlProvider = injectIntl(({ intl, children }) => {
  return (
    <RouterContext.Provider value={intl}>{children}</RouterContext.Provider>
  )
})

// Hook version of the injectIntl hoc. When we upgrade to the latest react-intl, we'll
// no longer need this wrapper.
export const useIntl = (): InjectedIntl => {
  return useContext(RouterContext)
}
