import { JsonDecoder } from "ts.data.json"
import { SuggestedArticle } from "@cultureamp/help-center"
import { perfApiPost } from "../../api/utils"

const suggestedArticleDecoder = JsonDecoder.object<SuggestedArticle>(
  {
    url: JsonDecoder.string,
    title: JsonDecoder.string,
  },
  "SuggestedArticles"
)

const suggestedArticlesDecoder = JsonDecoder.array(
  suggestedArticleDecoder,
  "SuggestedArticles"
)

const fullResponseDecoder = JsonDecoder.object(
  {
    suggested_articles: suggestedArticlesDecoder,
  },
  "FullResponseDecoder"
)

const getSuggestedArticles = async (route: string) => {
  const response = await perfApiPost("/suggested_articles", {
    params: {
      route: route,
    },
  })

  if (response) {
    return await fullResponseDecoder.decodePromise(response.data)
  } else {
    throw Error
  }
}

export default getSuggestedArticles
