import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/draft-modal"
import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import strings from "../../locale/strings"
import { trackTeamCreatedSuccessModalViewed } from "../../utils/analytics"
import styles from "./NewTeamModal.scss"

type NewTeamModal = React.FunctionComponent<{
  router: InjectedRouter
  teamId: string
  intl: InjectedIntl
}>

const NewTeamModal: NewTeamModal = ({ router, teamId, intl }) => {
  useEffect(() => {
    trackTeamCreatedSuccessModalViewed()
  }, [])
  return (
    <ConfirmationModal
      isOpen
      type="positive"
      title={intl.formatMessage(strings.teamGoals.confirmationModal.title)}
      confirmLabel={intl.formatMessage(
        strings.teamGoals.confirmationModal.confirmLabel
      )}
      dismissLabel={intl.formatMessage(
        strings.teamGoals.confirmationModal.dismissLabel
      )}
      onDismiss={() =>
        router.replace({
          pathname: `teams/${teamId}`,
          state: {
            newTeam: false,
          },
        })
      }
      onConfirm={() =>
        router.push({
          pathname: `new_goals/team/${teamId}/new`,
          state: {
            newTeam: false,
          },
        })
      }
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          {intl.formatMessage(strings.teamGoals.confirmationModal.modalContent)}
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default injectIntl(NewTeamModal)
