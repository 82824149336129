import request from "superagent"
import _ from "lodash"
import valvelet from "valvelet"
import * as Sentry from "@sentry/browser"

const authPromisesByAccessToken = {}

function getAuthPromise(session) {
  return session && authPromisesByAccessToken[session.access_token]
}

function setAuthPromise(session, p) {
  if (session) {
    authPromisesByAccessToken[session.access_token] = p
  }
}

function getAuthHeader(session) {
  return {
    Authorization:
      session && session.access_token ? "Bearer " + session.access_token : ""
  }
}

export default class SupremeAgent {
  constructor(opts = {}) {
    if (!opts.base || typeof opts.base != "string") {
      throw new Error(
        "No API URL provided to constructor. " +
          "Pass in a string under the 'base' attribute."
      )
    }

    if (!(opts.refreshFunctor instanceof Function)) {
      throw new Error(
        "No refresh function provided to constructor. " +
          "Pass in a functor for a Promise under the 'refreshFunctor' attribute. " +
          "It should accept a session object."
      )
    }

    if (!(opts.sessionGetter instanceof Function)) {
      throw new Error(
        "No session getter function provided to constructor. " +
          "Pass in a function under the 'sessionGetter' attribute. " +
          "It should return a session object with an 'access_token' attribute."
      )
    }

    if (
      Boolean(opts.rateLimitInterval) !==
      Boolean(opts.maxRequestsPerRateLimitInterval)
    ) {
      throw new Error(
        "If one of 'rateLimitInterval' or 'maxRequestsPerRateLimitInterval' is " +
          "provided, both must be provided"
      )
    }

    this.setBase(opts.base)
    this.refreshSession = opts.refreshFunctor
    this.getSession = opts.sessionGetter

    this._requestRunner = requestFunc => requestFunc()

    if (opts.rateLimitInterval) {
      this._requestRunner = valvelet(
        this._requestRunner,
        opts.maxRequestsPerRateLimitInterval,
        opts.rateLimitInterval
      )
    }

    this._plugins = []
  }

  /**
   * Applies the given SuperAgent plugin to all requests created by this
   * agent.
   */
  use(plugin) {
    this._plugins.push(plugin)
    return this
  }

  /**
   * Creates a new `Request` object, returned from the `get` / `post` / ...
   * methods. May be overridden by subclasses, allowing them to, for example,
   * return a custom `Request` class. The return value must be an instance
   * of `Request` or a subclass.
   */
  createRequest(method, url) {
    return new Request(this, method, url)
  }

  /**
   * Plumbing associated with creating a new request. The `createRequest` method
   * is designed to be overridable by subclasses, so is only expected to create
   * and return a new request instance.
   */
  _createAndConfigureRequest(method, url) {
    const req = this.createRequest(method, this._prependBase(url))
    return this._plugins.reduce((req, plugin) => req.use(plugin), req)
  }

  /**
   * REST METHODS
   */

  head(url) {
    return this._createAndConfigureRequest("head", url)
  }

  get(url) {
    return this._createAndConfigureRequest("get", url)
  }

  post(url) {
    return this._createAndConfigureRequest("post", url)
  }

  put(url) {
    return this._createAndConfigureRequest("put", url)
  }

  del(url) {
    return this._createAndConfigureRequest("del", url)
  }

  /**
   * CUSTOM METHODS
   */

  setBase(url) {
    // Ensure ending slash
    if (url.lastIndexOf("/") != url.length - 1) {
      url += "/"
    }
    this.base = url
    return this
  }

  /**
   * HELPERS
   */

  _prependBase(url) {
    if (url.indexOf("/") == 0) {
      url = url.substr(1)
    }
    return this.base + url
  }
}

export class Request {
  constructor(agent, method, url) {
    this._agent = agent
    this._requestCalls = [[method.toLowerCase(), [url]]]
    this._requestRetries = 0
    this._requestPromise = null
    this._requestBuildPromise = null
    this._skipAuth = false
    this._session = null
  }

  /**
   * SUPERAGENT OPERATIONS
   */

  end(callback, isAuth = this._skipAuth) {
    let onRequestBuilt

    this._requestBuildPromise = new Promise(resolve => {
      // need to wrap the request, because superagent requests are "thenable", causing them
      // to be interpreted as promises
      onRequestBuilt = req => resolve({ value: req })
    })

    this._requestPromise = new Promise((resolve, reject) => {
      const finish = (err, res) => {
        try {
          callback(err, res)
        } finally {
          if (err) {
            reject(err)
          } else {
            resolve(res)
          }
        }
      }

      const performRequest = () => {
        this._agent._requestRunner(() => {
          if (!isAuth) {
            this._session = this._agent.getSession()
            this._callRequest("set", getAuthHeader(this._session))
          }
          const req = this._buildRequest()
          onRequestBuilt(req)
          req.end(this.handleResponse.bind(this, finish, isAuth))
        })
      }

      const authPromise = !isAuth
        ? getAuthPromise(this._agent.getSession())
        : null

      if (authPromise) {
        authPromise.then(performRequest)
      } else {
        performRequest()
      }
    })

    return this
  }

  query(data) {
    this._callRequest("query", data)
    return this
  }

  send(data) {
    this._callRequest("send", data)
    return this
  }

  use(plugin) {
    this._callRequest("use", plugin)
    return this
  }

  set() {
    this._callRequest("set", ...arguments)
    return this
  }

  attach({ name, file }) {
    this._callRequest("attach", name, file)
    return this
  }

  abort() {
    if (this._requestBuildPromise) {
      this._requestBuildPromise.then(({ value }) => value.abort())
    }
    return this
  }

  /**
   * Promise support
   */

  then(onSuccess, onFailure) {
    return this._getPromise().then(onSuccess, onFailure)
  }

  catch(onFailure) {
    return this._getPromise().catch(onFailure)
  }

  /**
   * CUSTOM METHODS
   */

  /**
   * If called, this request will skip authorization. This behavior can
   * also be achieved by passing `true` for the `isAuth` argument to `end`.
   */
  skipAuth(skip = true) {
    this._skipAuth = skip
    return this
  }

  retryAuth(callback) {
    if (this._requestRetries >= 2) {
      const msg = "Too many auth retries, aborting"
      console.warn(msg)
      callback(new Error(msg), null)
      return
    } else {
      this._requestRetries += 1
    }

    const session = this._agent.getSession()
    if (!(session && session.access_token)) {
      console.warn("Retried auth without an existing session")
      this.end(callback)
      return
    }

    setAuthPromise(session, this._agent.refreshSession(session))

    getAuthPromise(session)
      .then(() => {
        setAuthPromise(session, null)
        this.end(callback)
      })
      .catch(err => {
        err = err || new Error("Unspecified error refreshing session")
        setAuthPromise(session, null)
        console.error(err)
        callback(err, null)
      })
  }

  /**
   * HELPERS
   */

  handleResponse(callback, isAuth, err, res) {
    if (err && !isAuth) {
      if (!res || (res && res.status == 401)) {
        this._handleUnauthorized(callback, err, res)
      } else {
        this._handleAuthorizedError(callback, err, res)
      }
    } else {
      callback(err, res)
    }
  }

  _handleUnauthorized(callback, err, res) {
    const redirectUrl = encodeURIComponent(window.location)
    const signInUrl = `/session/sign_in?redirect=${redirectUrl}`
    window.location = signInUrl
  }

  _handleAuthorizedError(callback, err, res) {
    callback(err, res)
  }

  _callRequest(method, ...args) {
    this._requestCalls.push([method, args])
  }

  _buildRequest() {
    return this._requestCalls.reduce(
      (req, [method, args]) => req[method].apply(req, args),
      request
    )
  }

  _getPromise() {
    if (!this._requestPromise) {
      this.end(_.noop)
    }
    return this._requestPromise
  }
}
