import { Box, Heading, Paragraph } from "@kaizen/component-library"
import { Link } from "react-router"
import classNames from "classnames"
import * as React from "react"
import styles from "./ErrorPage.scss"
import logoCoral from "../../static/images-2017/CA-logo-coral.svg"

type ErrorPageProps = {
  code: number
  title: string
  message: string
  body: string
  imageUrl: string
}

const mailtoLink = (code: number) => {
  const email = "support@cultureamp.com"
  const subject = "Houston we have a problem"
  const body = `Hi there,\n\nI received a ${code} error page while I was trying to...`
  return encodeURI(`mailto:${email}?subject=${subject}&amp;body=${body}`)
}

// Error page, copy/pasted from here:
//   https://github.com/cultureamp/big-frontend-repo/blob/master/packages/error-pages/components/ErrorPage.tsx
const ErrorPage = ({
  title,
  code,
  message,
  body,
  imageUrl,
}: ErrorPageProps): JSX.Element => (
  <div className={styles.card}>
    <section className={styles.textContent}>
      <Heading variant="heading-1">
        <span
          className={classNames(styles.errorCode, styles["errorCode" + code])}
        >
          {code}
        </span>
      </Heading>
      <Box mb={1}>
        <span className={styles.heading}>
          <Heading variant="heading-2">{message}</Heading>
        </span>
      </Box>
      <Box mb={1}>
        <Paragraph variant="intro-lede">{body}</Paragraph>
      </Box>
      <Box mb={1}>
        <Paragraph variant="body">
          Maybe pop back to <Link to="/">home</Link> and try again?
        </Paragraph>
      </Box>
      <Paragraph variant="body">
        Or if you&rsquo;re still having problems, drop us a line at{" "}
        <a href={mailtoLink(code)}> support@cultureamp.com </a>
      </Paragraph>
    </section>
    <section className={styles.imageContent}>
      <img alt={title} src={imageUrl} className={styles.image} />
      <a href="http://cultureamp.com" className={styles.logo}>
        <img alt="logo" src={logoCoral} />
      </a>
    </section>
  </div>
)

export default ErrorPage
