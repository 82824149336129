import PropTypes from "prop-types"
import React from "react"
import modalDeprecated from "../../higherOrderComponents/modalDeprecated"
import CompletedSelfReview from "./CompletedSelfReview"

@modalDeprecated({
  className: "CompletedSelfReviewModal",
  key: (props) => props.modalKey,
})
export default class CompletedSelfReviewModal extends React.Component {
  static propTypes = {
    loadReview: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    modalKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  loadReview = () => {
    const { loadReview, onClose } = this.props

    return loadReview().catch(() => onClose())
  }

  render() {
    const { readOnly } = this.props

    return (
      <CompletedSelfReview loadReview={this.loadReview} readOnly={readOnly} />
    )
  }
}
