import PropTypes from "prop-types"
import React from "react"
import { createPortal } from "react-dom"
import { injectIntl } from "react-intl"
import cx from "classnames"
import _ from "lodash"
import { Button } from "@kaizen/component-library"
import closeIcon from "@kaizen/component-library/icons/close.icon.svg"
import { keyCode } from "../../constants/keyCodes"
import ResponsiveWrapper from "../../components/ResponsiveWrapper/ResponsiveWrapper"
import ConfirmationModal from "../modals/ConfirmationModalDeprecated"
import strings from "../../locale/strings"
import { throttle } from "../../utils/timers"
import Aid from "../../constants/automationId"
import "./ModalDeprecated.less"

const DOC_ELEM_MODAL_OPEN_CLASS = "html--Modal-open"
const MODAL_OPEN_DURATION = 500

// HACK - delay the history entry a bit to avoid interference from components changing the
// location while reacting to location changes. A common example of this is components clearing
// temporary query params out of the URL.
const HISTORY_ENTRY_DELAY = 50

/**
 * DEPRECATED - decorator is no longer required. Please create modals by adding
 * to the configuration under src/constants/modals.ts
 */
@injectIntl
export default class ModalDeprecated extends React.Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    className: PropTypes.string,
    showConfirmationModal: PropTypes.bool,
    useHistoryForClose: PropTypes.bool,
    onNavigationClose: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    onConfirmationYes: PropTypes.func,
    onConfirmationNo: PropTypes.func,
    children: PropTypes.node,
    translucent: PropTypes.bool,
    hasDefaultExitButton: PropTypes.bool,
    allowEscToClose: PropTypes.bool,
  }

  static defaultProps = {
    className: "",
    opened: false,
    showConfirmationModal: false,
    useHistoryForClose: true,
    translucent: false,
    hasDefaultExitButton: true,
    allowEscToClose: true,
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this._frozenScrollPosition = null
    this._historyCheckpointKey = null
    this._nextLocation = null
    this._historyUnsubscribers = []
  }

  componentDidMount() {
    this.updateDocumentScrolling()
    if (this.props.opened) {
      this.addHistoryEntry()
      if (this.props.allowEscToClose) {
        document.addEventListener("keydown", this.handleKeyDown)
      }
    }
  }

  @throttle(MODAL_OPEN_DURATION)
  handleKeyDown(e) {
    const { onClose } = this.props
    if (e.keyCode === keyCode.escape) {
      onClose()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.opened !== prevProps.opened) {
      this.updateDocumentScrolling()

      if (this.props.opened) {
        if (this.props.allowEscToClose) {
          document.addEventListener("keydown", this.handleKeyDown)
        }
        this.addHistoryEntry()
      } else {
        document.removeEventListener("keydown", this.handleKeyDown)
        this.tryRemoveHistoryEntry()
      }
    }
  }

  componentWillUnmount() {
    this.unfreezeScrolling()
    document.removeEventListener("keydown", this.handleKeyDown)
    if (_.get(this._nextLocation, "state.modalKey")) {
      // The last location we transitioned to is associated with a Modal, so close
      this.tryRemoveHistoryEntry()
    } else {
      this.cleanupHistoryTracking()
    }
  }

  getOwnerDocument() {
    return _.get(this.container, "ownerDocument") || window.document
  }

  updateDocumentScrolling() {
    if (this.props.opened) {
      this.freezeScrolling()
    } else {
      this.unfreezeScrolling()
    }
  }

  @throttle(MODAL_OPEN_DURATION, { leading: false })
  freezeScrolling() {
    const { documentElement, defaultView } = this.getOwnerDocument()

    if (documentElement.classList.contains(DOC_ELEM_MODAL_OPEN_CLASS)) {
      return
    }

    this._frozenScrollPosition = [
      defaultView.pageXOffset,
      defaultView.pageYOffset,
    ]
    documentElement.classList.add(DOC_ELEM_MODAL_OPEN_CLASS)
  }

  unfreezeScrolling() {
    this.freezeScrolling.cancel()

    if (!this._frozenScrollPosition) {
      return
    }

    const { documentElement, defaultView } = this.getOwnerDocument()

    documentElement.classList.remove(DOC_ELEM_MODAL_OPEN_CLASS)
    defaultView.scroll(...this._frozenScrollPosition)
    this._frozenScrollPosition = null
  }

  @throttle(HISTORY_ENTRY_DELAY, { leading: false })
  addHistoryEntry() {
    const { useHistoryForClose } = this.props
    const { router, location } = this.context

    if (!useHistoryForClose) {
      return
    }

    this._nextLocation = location
    this._historyUnsubscribers = [
      router.listen(this.handleLocationChange),
      router.listenBefore(this.handleLocationWillChange),
    ]
    this._historyCheckpointKey = location.key

    // Add a history entry so the user can go "back" to the page under the modal.
    //
    // HACK We're using react-router's hash-based implementation of history, and apparently plain
    // hash changes very soon after page load may not add a history entry. So we call pushState at
    // the browser level to guarantee that we're adding a history entry. We then call router.replace
    // to get the state we need into the hash-based history implementation (which isn't looking at
    // the state managed by the browser's pushState). Once we switch to HTML5 history, we can
    // remove this hack.
    //
    window.history.pushState(
      null,
      null,
      `#${location.pathname}${location.search}`
    )

    router.replace({
      ..._.pick(location, "pathname", "query"),
      state: { modalKey: location.key },
    })
  }

  handleLocationWillChange = (location) => {
    this._nextLocation = location
  }

  handleLocationChange = (location) => {
    const { onNavigationClose, onClose } = this.props

    if (location.key === this._historyCheckpointKey) {
      this.cleanupHistoryTracking()

      if (onNavigationClose) {
        onNavigationClose()
      } else {
        onClose()
      }
    }
  }

  tryRemoveHistoryEntry = () => {
    const haveCheckpoint = !!this._historyCheckpointKey

    this.cleanupHistoryTracking()

    if (haveCheckpoint) {
      this.context.router.goBack()
    }
  }

  cleanupHistoryTracking = () => {
    this.addHistoryEntry.cancel()
    this._historyCheckpointKey = null
    this._nextLocation = null
    this._historyUnsubscribers.forEach((unsub) => unsub())
    this._historyUnsubscribers = []
  }

  render() {
    const {
      className,
      children,
      opened,
      onClose,
      showConfirmationModal,
      onConfirmationNo,
      onConfirmationYes,
      translucent,
      hasDefaultExitButton,
      intl: { formatMessage },
    } = this.props

    return createPortal(
      <div
        // Used by MultiStepModal to add a scroll listener. I would fix up
        // this code, but I intend to revamp how modals work entirely, CORE-288
        id="modal-div-container"
        ref={(container) => (this.container = container)}
        className={cx(
          "Modal animated",
          { "Modal--hidden": !opened, "Modal--translucent": translucent },
          className
        )}
        data-automation-id={opened ? Aid.modal : undefined}
      >
        <ResponsiveWrapper className="Modal--content">
          {hasDefaultExitButton && (
            <div className="Modal--close-btn animated">
              <Button
                secondary={true}
                icon={closeIcon}
                iconPosition="end"
                label={formatMessage(strings.general.exit)}
                onClick={onClose}
              />
            </div>
          )}
          {children}

          {showConfirmationModal && (
            <ConfirmationModal
              opened={showConfirmationModal}
              onClose={onConfirmationNo}
              onConfirmClick={onConfirmationYes}
              headerContent={formatMessage(strings.general.confirmExit)}
              yesButtonText={formatMessage(
                strings.general.confirmationPositive
              )}
              noButtonText={formatMessage(strings.general.confirmationNegative)}
            />
          )}
        </ResponsiveWrapper>
      </div>,
      document.getElementById("app") || document.createElement("div") // For Jest Testing
    )
  }
}
