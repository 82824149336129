import * as Sentry from "@sentry/browser"
import { ProvideAdhocFeedbackTask, Employee } from "../types/graphql"

const errorsBlockList: RegExp[] = [new RegExp(/Network error: Failed to fetch/)]

/**
 * Report errors to our error reporter of choice.
 * Takes an optional data param to pass more debugging
 * information.
 *
 * Note: IT IS IMPERATIVE THAT THE DATA DOES NOT CONTAIN
 * PII IF YOU ARE GOING TO USE THE DATA PARAM.
 *
 * @param {Error} err Error to report to exception handler.
 * @param {T} data Warning: Ensure data does not contain PII.
 */
export const captureException = <T>(err: Error, data?: T) => {
  // filter errors from the block list
  if (errorsBlockList.some((regex) => regex.test(err.message))) {
    return
  }

  if (data) {
    Sentry.addBreadcrumb({
      category: "debug",
      level: Sentry.Severity.Debug,
      message: "Extra captureException debug info",
      data: data,
    })
  }

  Sentry.captureException(err)
}

// Breadcrumbs: Handle the breadcrumbs
// for what should be removed for PII.

/**
 * Filter out known PII keys for the
 * Employee object.
 *
 * @param {*} breadcrumb
 * @returns
 */
export const filterTaskListSubtaskEmployeeData = (
  breadcrumb: Sentry.Breadcrumb
) => {
  // Handble base case
  if (!breadcrumb?.data) {
    return breadcrumb
  }

  // We should note that this could take any of the data objects
  // with a subtask. While the static checking won't differ,
  // might be worth considering using a union that has subtasks.
  const {
    taskListBreadcrumbs,
  }: { taskListBreadcrumbs: ProvideAdhocFeedbackTask } = breadcrumb.data

  // Handle base case for destructured value
  if (!taskListBreadcrumbs || !taskListBreadcrumbs.subTasks) {
    return breadcrumb
  }
  const denyList: Array<keyof Employee> = [
    "email",
    "fullName",
    "manager",
    "preferredName",
    "profileImageUrl",
  ]

  breadcrumb.data.taskListBreadcrumbs.subTasks = taskListBreadcrumbs.subTasks.map(
    (subtask) => {
      if (!subtask.employee) {
        return subtask
      }

      // remove PII properties from employee
      for (const property of denyList) {
        subtask.employee[property] = undefined as never
      }

      // remove name from requestedBy
      subtask.requestedBy = undefined as never

      return subtask
    }
  )

  return breadcrumb
}

/**
 * Compose the functions used for breadcrumbs. MUST take breadcrumb
 * and return one. Any error will nullify the breadcrumb and report
 * the failure to Sentry. Very meta.
 *
 * @param {...Array<Function>} fns
 */
export const composeBreadcrumbFns = (...fns: Array<Function>) => (
  breadcrumb: Sentry.Breadcrumb
): Sentry.Breadcrumb | null => {
  try {
    return fns.reduceRight(
      // MUST take Sentry Breadcrumb and return Sentry Breadcrumb
      (arg: Sentry.Breadcrumb, fn): Sentry.Breadcrumb => fn(arg),
      breadcrumb
    )
  } catch (err) {
    // pass data as object to handle "never" type edge cases
    captureException(err)

    // null used for empty breadcrumb. This should never happen if
    // we enforce return a breadcrumb.
    return null
  }
}

export const filterBreadcrumbs = composeBreadcrumbFns(
  filterTaskListSubtaskEmployeeData
)
