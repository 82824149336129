import React from "react"
import _ from "lodash"
import { Map } from "immutable"

/**
 * Higher-order component that takes care of loading the values selected in a typeahead-type
 * control. This allows the values to be passed in as integers or strings but be displayed
 * as full objects (e.g., in "pills").
 *
 * @param getValues - function that should take props and return the array of selected values
 * @param getObjectId - function that should take props an object and return the "id" of that object
 * @param loadObjectsForIds - function that should take props and an array of ids and return a
 *                            Promise for the loading of the associated objects. This function
 *                            may return null to avoid loading objects.
 *
 * This HOC injects an object of the following props:
 *
 * - `values`: represents the full object values that have been loaded for the selected values
 * - `cacheObjects`: hook that can be used in the wrapped component to avoid redundant load calls.
 *                   If an object is passed to this function, it will not need to be loaded
 *                   remotely. Typically this would be called during a `handleChange` operation to
 *                   add soon-to-be-selected values to the local cache.
 *
 * By default, these props are injected under the key `selectedValueLoader`. This can be customized
 * with the `propKey` argument to the HOC.
 *
 * @example
 *
 *    @selectedValueLoader({
 *      getValues: props => props.values,
 *      getObjectId: (_props, obj) => obj.id,
 *      loadObjectsForIds: (props, ids) => props.loadOptionsForIds(ids),
 *    })
 *    class MyTypeahead extends React.Component {
 *      handleChange = newValues => {
 *        this.props.selectedValueLoader.cacheObjects(newValues);
 *        this.props.onChange(newValues);
 *      };
 *
 *      render() {
 *        return (
 *          <Select
 *            multi={true}
 *            value={this.props.selectedValueLoader.values}
 *            onChange={this.handleChange}
 *          />
 *        );
 *      }
 *    }
 */
export default function selectedValueLoader({
  getValues,
  getObjectId,
  loadObjectsForIds,
  propKey = "selectedValueLoader",
}) {
  return (InnerComponent) =>
    class SelectedValueLoader extends React.Component {
      constructor(props) {
        super(props)

        this.state = {
          loadedObjectsById: Map(),
        }
      }

      componentDidMount() {
        this.fetchObjectsForIds(this.getIdValues())
      }

      componentWillReceiveProps(nextProps) {
        const nextIds = this.getIdValues(nextProps)

        // Prune unneeded loaded values
        this.setState((state) => ({
          loadedObjectsById: state.loadedObjectsById.filter((_value, id) =>
            nextIds.includes(id)
          ),
        }))
      }

      componentDidUpdate(prevProps) {
        const { loadedObjectsById } = this.state

        const idValues = this.getIdValues()
        const prevIdValues = this.getIdValues(prevProps)
        const cachedIds = loadedObjectsById.keySeq().toArray()
        const idsToLoad = _.difference(idValues, prevIdValues, cachedIds)

        this.fetchObjectsForIds(idsToLoad)
      }

      getIdValues(props = this.props) {
        return _.reject(getValues(props), _.isObject)
      }

      fetchObjectsForIds(ids) {
        if (ids.length === 0) {
          return
        }

        const resultPromise = Promise.resolve(
          loadObjectsForIds(this.props, ids)
        )

        resultPromise.then((results) => {
          if (results && results.length) {
            this.mergeLoadedObjects(results)
          }
        })
      }

      mergeLoadedObjects(loadedObjects) {
        this.setState((state, props) => ({
          // Can't use Map#merge because Immutable deep converts values to Immutable types
          loadedObjectsById: state.loadedObjectsById.withMutations(
            (mutator) => {
              for (const obj of loadedObjects) {
                const id = getObjectId(props, obj)
                mutator.set(id, obj)
              }
            }
          ),
        }))
      }

      /**
       * Hook for the wrapped component to cache selected objects so we don't have to refetch them
       */
      cacheObjects = (newObjects) => {
        this.mergeLoadedObjects(newObjects)
      }

      render() {
        const { props } = this
        const { loadedObjectsById } = this.state

        const injectedProps = {
          values: getValues(props)
            .map((v) => loadedObjectsById.get(v))
            .filter(Boolean),
          cacheObjects: this.cacheObjects,
        }

        return <InnerComponent {...props} {...{ [propKey]: injectedProps }} />
      }
    }
}
