import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import cx from "classnames"
import { List } from "immutable"
import _ from "lodash"
import ZugataFormattedDate from "../../components/ZugataFormattedDate/ZugataFormattedDate"
import strings from "../../locale/strings"
import DraggableTile from "../../components/DraggableTile/DraggableTile"
import GoalActions from "../../refluxActions/GoalActions"
import FadeoutTruncated from "../../components/FadeoutTruncated/FadeoutTruncated"
import CommentIndicator from "../../components/CommentIndicator/CommentIndicator"
import GoalAlignmentsIndicator from "./GoalAlignmentsIndicator"
import GoalPriorityIndicator from "./GoalPriorityIndicator"
import NewGoalLabel from "./NewGoalLabel"
import GoalProgressBar from "./GoalProgressBar"
import Select from "../../components/Select/Select"
import Icon from "../../components/Icon/Icon"
import STATUS_OPTIONS from "./utils/goalStatusOptions"
import "./GoalsGrid.less"

const STATUS_OPTION_TITLES = _(STATUS_OPTIONS)
  .indexBy("value")
  .mapValues("text")
  .value()

export default class GoalsGrid extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
    user: PropTypes.object.isRequired,
  }

  static propTypes = {
    className: PropTypes.string,
    createNewGoalText: PropTypes.node,
    readOnly: PropTypes.bool,
    goalType: PropTypes.oneOf(["my_goal", "department_goal", "company_goal"])
      .isRequired,
    goals: PropTypes.instanceOf(List),
    sortStatusGoals: PropTypes.func,
    onSelectGoal: PropTypes.func.isRequired,
    onStartCreate: PropTypes.func,
    singleColumnMode: PropTypes.bool,
    sortingOptions: PropTypes.array,
    onSortingChange: PropTypes.func,
    selectedSortingOption: PropTypes.string,
  }

  static defaultProps = {
    className: "",
  }

  constructor(props) {
    super(props)

    this.goalColumns = {}

    this.state = {
      draggingAllowed: true,
      dragging: false,
      columnDraggedFrom: null,
      columnDraggingOver: null,
    }
  }

  componentWillMount() {
    this.updateDraggingAllowed()
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDraggingAllowed)
    window.addEventListener("orientationchange", this.updateDraggingAllowed)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDraggingAllowed)
    window.removeEventListener("orientationchange", this.updateDraggingAllowed)
  }

  isDraggingInColumn({ columnType, x, y }) {
    const column = this.goalColumns[columnType]
    if (!column) {
      return false
    }

    const { left, right, top, bottom } = column.getBoundingClientRect()

    return left <= x && x <= right && top <= y && y <= bottom
  }

  handleCardDrag(event, goal) {
    const {
      isFinal,
      center: { x, y },
    } = event
    const { columnDraggedFrom, columnDraggingOver } = this.state
    const { status } = goal

    if (!columnDraggedFrom) {
      // Blocked cards appear in the "ongoing" column
      this.setState({
        columnDraggedFrom: status === "blocked" ? "ongoing" : status,
      })
    }

    if (isFinal) {
      if (
        columnDraggingOver !== goal.status &&
        !(columnDraggingOver === "ongoing" && goal.status === "blocked")
      ) {
        GoalActions.update({
          originalGoal: goal,
          updatedGoal: { ...goal, status: columnDraggingOver },
        })
      }

      this.setState({
        columnDraggedFrom: null,
        columnDraggingOver: null,
      })
    } else {
      this.setState({
        columnDraggingOver: [
          "created",
          "ongoing",
          "accomplished",
        ].find((columnType) => this.isDraggingInColumn({ columnType, x, y })),
      })
    }
  }

  updateDraggingAllowed = () => {
    const draggingAllowed =
      (window.innerWidth || document.body.clientWidth) > 800

    if (this.state.draggingAllowed !== draggingAllowed) {
      this.setState({ draggingAllowed })
    }
  }

  renderCreateGoalCard() {
    const { onStartCreate, createNewGoalText } = this.props

    return (
      <div
        className="GoalsGrid--create-goal-card"
        onClick={() => onStartCreate && onStartCreate()}
      >
        <div className="GoalsGrid--create-goal-card-icon">
          <Icon iconName="add" />
        </div>
        <span className="GoalsGrid--create-goal-card-title">
          {createNewGoalText}
        </span>
      </div>
    )
  }

  renderGoalCard = (goal) => {
    if (!goal) {
      return
    }

    const { user } = this.context
    const {
      id,
      due_at: dueAt,
      name,
      author_has_unseen_comments: authorHasUnseenComments,
      number_of_comments: numberOfComments,
      incoming_alignment_goal_ids: incomingAlignedGoalsIds,
      status,
    } = goal
    const { goalType, onSelectGoal, readOnly, singleColumnMode } = this.props
    const {
      draggingAllowed,
      columnDraggedFrom,
      columnDraggingOver,
    } = this.state

    return (
      <DraggableTile
        key={id}
        disableDragging={!draggingAllowed || readOnly}
        onTap={() => onSelectGoal(goal)}
        onDrag={(e) => this.handleCardDrag(e, goal)}
        overDragTarget={
          !!columnDraggingOver && columnDraggedFrom !== columnDraggingOver
        }
      >
        <div className="GoalsGrid--card layout vertical">
          <div className="GoalsGrid--card-content">
            <div
              className={`GoalGrid--card-status-container ${
                status ? `GoalGrid--card-${status}` : ""
              }`}
            >
              {status && (
                <FormattedMessage {...STATUS_OPTION_TITLES[goal.status]} />
              )}
            </div>
            <div className="layout vertical center-justified flex left-align">
              <div className="layout horizontal center">
                <GoalPriorityIndicator priority={goal.priority} />
                {singleColumnMode && <NewGoalLabel goal={goal} viewer={user} />}
              </div>

              <FadeoutTruncated className="GoalsGrid--card-name flex">
                {name}
              </FadeoutTruncated>
            </div>

            <div className="GoalsGrid--card-due-row layout horizontal end justified flex none">
              <div className="GoalsGrid--card-due-at">
                <FormattedMessage
                  {...strings.general.due}
                  values={{
                    time: <ZugataFormattedDate value={dueAt} month="short" />,
                  }}
                />
              </div>
              {goalType === "my_goal" && (
                <CommentIndicator
                  count={numberOfComments}
                  hasUnread={authorHasUnseenComments}
                />
              )}
              {(goalType === "department_goal" ||
                goalType === "company_goal") && (
                <GoalAlignmentsIndicator
                  count={incomingAlignedGoalsIds.length}
                />
              )}
            </div>
          </div>

          <GoalProgressBar
            value={goal.completion}
            status={status}
            goalProgressBarClass="ModalGoalProgressBar"
          />

          {singleColumnMode && (
            <div className="GoalsGrid--card-interactive-indicator">
              <Icon iconName="chevronRight" />
            </div>
          )}
        </div>
      </DraggableTile>
    )
  }

  getGoalsColumns() {
    const { goals, sortStatusGoals, singleColumnMode } = this.props
    const columns = []

    if (singleColumnMode) {
      columns.push({
        header: <FormattedMessage {...strings.general.all} />,
        status: "all",
        goals,
      })
    } else {
      const goalsByStatus = goals
        .groupBy((g) => g.status)
        .map((goals) => sortStatusGoals(goals))
      const createdGoals = goalsByStatus.get("created")
      const blockedGoals = goalsByStatus.get("blocked")
      const inProgressGoals = goalsByStatus.get("ongoing")
      const completedGoals = goalsByStatus.get("accomplished")

      columns.push({
        header: <FormattedMessage {...strings.goals.status.created} />,
        status: "created",
        goals: createdGoals,
      })
      columns.push({
        header: <FormattedMessage {...strings.goals.status.ongoing} />,
        status: "ongoing",
        goals: blockedGoals
          ? blockedGoals.concat(inProgressGoals)
          : inProgressGoals,
      })
      columns.push({
        header: <FormattedMessage {...strings.goals.status.accomplished} />,
        status: "accomplished",
        goals: completedGoals,
      })
    }

    return columns
  }

  render() {
    const {
      readOnly,
      className,
      singleColumnMode,
      sortingOptions,
      onSortingChange,
      selectedSortingOption,
    } = this.props
    const { columnDraggedFrom, columnDraggingOver } = this.state

    const goalsColumns = this.getGoalsColumns()

    return (
      <div
        className={cx("GoalsGrid", className, {
          "GoalsGrid--single-column": singleColumnMode,
        })}
      >
        {singleColumnMode && sortingOptions && (
          <Select
            className="GoalsGrid--order-dropdown"
            options={sortingOptions}
            value={selectedSortingOption}
            onChange={(option) => onSortingChange(option.value)}
          />
        )}
        {goalsColumns.map(({ header, goals, status }) => (
          <div
            key={status}
            id={`GoalsGrid--${status}`}
            className={cx("GoalsGrid--column", {
              "GoalsGrid--column-draggingOver":
                status !== columnDraggedFrom && status === columnDraggingOver,
            })}
            ref={(col) => (this.goalColumns[status] = col)}
          >
            {!singleColumnMode && (
              <div className="GoalsGrid--column-header">{header}</div>
            )}
            {status === "created" && !readOnly && this.renderCreateGoalCard()}
            {goals && goals.map(this.renderGoalCard)}
          </div>
        ))}
      </div>
    )
  }
}
