import { useEffect, useState } from "react"

const getScrollParent = (node?: HTMLDivElement): HTMLElement | undefined => {
  if (!node) return undefined

  const overflowY = window.getComputedStyle(node).overflowY
  const isScrollable =
    overflowY &&
    !(overflowY.includes("hidden") || overflowY.includes("visible"))

  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node
  }

  return getScrollParent(node.parentElement as HTMLDivElement) || document.body
}

const useScroll = (element: HTMLDivElement | null) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false)

  const handleScroll = (event: Event) => {
    const scrollElement = event.target as HTMLDivElement

    setScrolledToBottom(
      scrollElement.scrollHeight ===
        scrollElement.scrollTop + scrollElement.clientHeight
    )
  }

  useEffect(() => {
    if (element) {
      const scrollElement = getScrollParent(element)
      if (scrollElement) {
        scrollElement.addEventListener("scroll", handleScroll)
        return () => scrollElement.removeEventListener("scroll", handleScroll)
      }
    }
  }, [element])

  return scrolledToBottom
}

export default useScroll
