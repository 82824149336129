import "./TabsDeprecated.less"
import * as React from "react"
import { memo, useState } from "react"
import { FormattedMessage, InjectedIntl } from "react-intl"
import cx from "classnames"
import Tooltip from "rc-tooltip"
import { Heading } from "@kaizen/component-library"
import TabContent from "./TabContent/TabContent"
import { Props as TabContentProps } from "./TabContent/TabContent"
import { injectIntl } from "../../lib/react-intl-with-custom-terms"

// @ts-ignore
import Icon from "../Icon/Icon"
// @ts-ignore
import { isMobileBrowser } from "../../utils/browser"

type Props = {
  align?: string
  className?: string
  iconTabStyle?: boolean
  initialTabId?: string
  intl: InjectedIntl
  onChange?: Function
  onFirstVisitToTab?: Function
  switchable?: boolean
  tabs: Array<TabContentProps>
}

const TabsDeprecated = (props: Props) => {
  const {
    align = "left",
    className,
    iconTabStyle = false,
    initialTabId,
    intl: { formatMessage },
    onChange = () => {},
    onFirstVisitToTab = () => {},
    switchable = true,
    tabs,
  } = props

  const [activeTabId, setActiveTab] = useState(
    initialTabId || props?.tabs[0]?.id
  )

  const [setOfDirtyTabs, setDirtyTab] = useState(new Set())

  const handleAnActiveTab = (id: string): string => (
    switchable && setActiveTab(id), id
  )

  const handleADirtyTab = (id: string): string =>
    setOfDirtyTabs.has(id)
      ? id
      : (setDirtyTab((dirtyTabs) => new Set(dirtyTabs).add(id)),
        onFirstVisitToTab(id),
        id)

  // Required for the initial content view
  handleADirtyTab(activeTabId)

  return (
    <div className={cx("Tabs", className)}>
      <div className="Tabs--bar layout horizontal start-center">
        {tabs.map(({ name, icon, id }) => (
          <Tooltip
            key={id}
            placement="top"
            mouseEnterDelay={1}
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
            trigger={iconTabStyle && !isMobileBrowser() ? ["hover"] : []}
            overlay={
              <div className="Tabs--bar--tooltip">{formatMessage(name)}</div>
            }
          >
            <div
              key={id}
              className={cx(
                "Tabs--tab truncate layout horizontal center-justified flex",
                {
                  "Tabs--tab-center": align === "center",
                  "Tabs--tab-active": id === activeTabId,
                  "Tabs--tab-icon-style": iconTabStyle,
                }
              )}
              onClick={() => onChange(handleADirtyTab(handleAnActiveTab(id)))}
            >
              {iconTabStyle && icon && <Icon iconName={icon} />}
              {!iconTabStyle && (
                <Heading variant="heading-4" color="dark-reduced-opacity">
                  <span className="Tabs--tab-name truncate">
                    <FormattedMessage {...name} />
                  </span>
                </Heading>
              )}
            </div>
          </Tooltip>
        ))}
      </div>
      <TabContent
        {...(tabs
          .filter(({ id }) => id === activeTabId)
          .pop() as TabContentProps)}
      />
    </div>
  )
}

export default injectIntl(memo(TabsDeprecated))
