import * as React from "react"
import cx from "classnames"
import { withRouter, WithRouterProps } from "react-router"
import { Tabs as KaizenTabs } from "@kaizen/draft-tabs"
import { memo, useRef } from "react"
import { isRegularMouseClick } from "../../utils/domEvent"
import styles from "./Tabs.scss"
import { getClosestScrollAncestor, getElementRect } from "../../utils/dom"

export type Tab = {
  label: string
  active?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  href?: string
}

type Props = WithRouterProps & {
  tabs: (Tab | false | null | undefined)[] // falsy values in the array will be filtered out
  renderTab?: (renderProps: {
    tab: Tab
    tabClassName: string
    activeTabClassName: string
    disabledTabClassName: string
  }) => React.ReactNode
  isSticky?: boolean
}

// This is for if the tabs are displayed in sticky mode (ie. isStickyIs true, and the
// user has scrolled passed the sticky header), and the user has clicked
// on a new tab. We want to take the user back up to the top of the tab content
// automatically.
const scrollToTopOfTabContentIfNecessary = (
  wrapperElement: HTMLElement | null
) => {
  if (!wrapperElement) return // This shouldn't happen

  const wrapperRect = getElementRect(wrapperElement)
  const scrollAncestor = getClosestScrollAncestor(wrapperElement)

  if (!scrollAncestor) return // This probably wouldn't happen, but just in case

  if (wrapperRect.top < 0) {
    scrollAncestor.scrollTop = scrollAncestor.scrollTop + wrapperRect.top
  }
}

/**
 * A wrapper around the kaizen tabs component.
 *
 * It adds the following functionality:
 *   - Links the `href` property of a tab to react router. As in, prevent
 *     a page reload, in the same way that the `Link` component does.
 *   - Add convenience functionality around the tabs array, so if false is passed,
 *     the tab will not get rendered.
 */
const Tabs = ({ router, tabs, isSticky, ...rest }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const filteredTabs: Tab[] = tabs.filter((t) => t) as Tab[]
  const modifiedTabs = filteredTabs.map((t: Tab) => {
    return {
      ...t,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (t.onClick) {
          t.onClick(e)
        }

        if (e.defaultPrevented) return

        if (t.href && isRegularMouseClick(e)) {
          e.preventDefault()
          router.replace(t.href as string)
        }

        if (isSticky) {
          scrollToTopOfTabContentIfNecessary(ref.current)
        }
      },
    }
  })

  return (
    // This first wrapper is needed as so we can calculate where the tabs should be,
    // when not in sticky mode.
    // The inner wrapper is needed to set the css styling for position: sticky,
    // only if isSticky is true.
    <span className={cx(isSticky && styles.rootSticky)} ref={ref}>
      <div className={cx(isSticky && styles.tabsSticky)}>
        <KaizenTabs tabs={modifiedTabs} {...rest} />
      </div>
    </span>
  )
}

export default withRouter(memo(Tabs))
