import Reflux from "reflux-core"
import Actions from "../refluxActions"
import observeSearchActions from "./lib/observeSearchActions"

const DevelopmentResourceStore = Reflux.createStore({
  init() {
    this.initializeData()

    observeSearchActions(this, {
      getResults: () => this.data.resourceSearchResults,
      searchAction: Actions.DevelopmentResource.searchResources,
      pageAction: Actions.DevelopmentResource.pageResources,
      onChange: (resourceSearchResults, resourceSearchState) => {
        this.data = {
          ...this.data,
          resourceSearchResults,
          resourceSearchState,
        }
        this.trigger(this.data)
      },
    })

    this.listenTo(Actions.DevelopmentResource.likeResource, this.onLikeResource)
    this.listenTo(
      Actions.DevelopmentResource.unlikeResource,
      this.onUnlikeResource
    )

    this.listenTo(Actions.DevelopmentResource.localClear, () => {
      this.initializeData()
      this.trigger(this.data)
    })
  },

  initializeData() {
    this.data = {
      resourceSearchResults: null,
      resourceSearchState: null,
    }
  },

  getInitialState() {
    return this.data
  },

  _likeOrUnlikeResource({ id, type, liked }) {
    this.data = {
      ...this.data,
      resourceSearchResults: this.data.resourceSearchResults.map((resource) =>
        resource.id === id && resource.type === type
          ? {
              ...resource,
              likes: resource.likes + (liked ? 1 : -1),
              liked,
            }
          : resource
      ),
    }

    this.trigger(this.data)
  },

  onLikeResource({ id, type }) {
    this._likeOrUnlikeResource({ id, type, liked: true })
  },

  onUnlikeResource({ id, type }) {
    this._likeOrUnlikeResource({ id, type, liked: false })
  },
})

export default DevelopmentResourceStore
