import { useCallback, useContext, useEffect, useState } from "react"
import { getDepartmentSummariesResponseDecoder } from "../../api/decoders/GetDepartmentSummariesResponseDecoder/getDepartmentSummariesResponseDecoder"
import {
  DepartmentSummariesContext,
  DepartmentSummary,
} from "../../context/DepartmentSummariesState"
import useDataAPI from "../performanceApi/useDataAPI"

interface Filters {
  my_department?: boolean
  q?: string
  from?: Date
  to?: Date
}

const useDepartmentSummaries = (filters: Filters = { my_department: true }) => {
  const { state, dispatch } = useContext(DepartmentSummariesContext)
  const fetchAPI = useDataAPI("/goals/departments_summary", "GET")
  const hasSearchFilter = filters.q && filters.q.length > 0
  const [fetchDepartmentsError, setFetchDepartmentsError] = useState(null)

  const { fire } = fetchAPI
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        setFetchDepartmentsError(null)
        const response = await fire({
          ...filters,
          page: 1,
        })

        const departments = await getDepartmentSummariesResponseDecoder.decodePromise(
          response.data
        )

        if (hasSearchFilter) {
          dispatch({
            type: "FETCH_DEPARTMENT_SUMMARIES_SEARCH",
            payload: departments,
          })
        } else {
          if (filters.my_department) {
            dispatch({
              type: "FETCH_MY_DEPARTMENT_SUMMARIES",
              payload: departments,
            })
          } else {
            dispatch({
              type: "FETCH_ALL_DEPARTMENT_SUMMARIES",
              payload: departments,
            })
          }
        }
      } catch (ex) {
        setFetchDepartmentsError(ex)
      }
    }

    fetchDepartments()
  }, [dispatch, filters, fire, hasSearchFilter])

  const filteredIds = hasSearchFilter
    ? state.searchResults
    : state[filters.my_department ? "myDepartments" : "allDepartments"]

  const fetchMore = useCallback(() => {
    const nextPage = filteredIds.nextPage
    if (nextPage) {
      fire({ ...filters, page: nextPage })
    }
  }, [filteredIds.nextPage, filters, fire])

  const refetch = useCallback(() => {
    const { currentPage } = filteredIds
    fire({ ...filters, page: currentPage })
  }, [filteredIds, filters, fire])

  const departments = Object.values(filteredIds.pages)
    .reduce((res, departments) => [...res, ...departments], [])
    .map(
      (departmentId) =>
        state.departmentSummariesById[departmentId] as DepartmentSummary
    )

  const hasMore = filteredIds.totalCount > departments.length

  return {
    hasMore,
    fetchMore,
    refetch,
    departments,
    error: fetchDepartmentsError,
    loading: fetchAPI.loading,
  }
}

export default useDepartmentSummaries
