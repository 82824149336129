import { ApolloClient } from "apollo-client"
import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import { split } from "apollo-link"
import { HttpLink } from "apollo-link-http"
import ActionCable from "actioncable"
import ActionCableLink from "graphql-ruby-client/dist/subscriptions/ActionCableLink"
import { getMainDefinition } from "apollo-utilities"
// generated by Fragment Matcher plugin
import introspectionResult from "./introspection-result"
import Settings from "./settings"
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
})
const cache = new InMemoryCache({ fragmentMatcher })
const httpLink = new HttpLink({
  uri: Settings.PERFORMANCE_API_GQL_URL,
})

const cable = ActionCable.createConsumer(Settings.PERFORMANCE_API_WS_URL)

// Create a WebSocket link:
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  new ActionCableLink({ cable }),
  httpLink
)
export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      returnPartialData: true,
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
})
