import _ from "lodash"

/**
 * Uses `store` to listen to `searchAction` and `pageAction`, representing async Reflux
 * actions for, respectively, performing a search and paging an existing search. Both actions
 * should complete with an object of the form
 * `{results, meta: {pagination}, clientMeta: {searchParams}}`. In response to
 * these actions, the `onChange` callback will be called (see below).
 *
 *
 * @param {Reflux.Store} store - the Reflux store to use for listening
 *
 * @param {{searchAction, pageAction, onChange}} args - an object of:
 *
 *    - `getResults()`: required. Should return the current search results in the store. May return `null`.
 *    - `searchAction`: optional. The async Reflux action that performs the initial search
 *    - `pageAction`: optional. The async Reflux action that pages the current search
 *    - `clearAction`: optional. When this action is received, the search results will be cleared
 *    - `onChange(results, searchState)`: a callback taking two arguments.
 *      `results` is the new array of search results and `searchState` is an object of the form
 *      `{pagination, searchParams}`. If `clearAction` is provided, this will be called with
 *      `(null, null)` when the search is cleared.
 */
export default function observeSearchActions(
  store,
  { getResults, searchAction, pageAction, clearAction, onChange }
) {
  /**
   * @type {Array<{offset, size}>}
   */
  let pageInfoByPageNum = []

  if (clearAction) {
    store.listenTo(clearAction, () => {
      pageInfoByPageNum = []
      onChange(null, null)
    })
  }

  if (searchAction) {
    store.listenTo(searchAction.completed, ({ results, meta, clientMeta }) => {
      pageInfoByPageNum = []
      mergeSearchResults(null, results, meta, clientMeta)
    })
  }

  if (pageAction) {
    store.listenTo(pageAction.completed, ({ results, meta, clientMeta }) => {
      mergeSearchResults(getResults(), results, meta, clientMeta)
    })
  }

  function mergeSearchResults(existingResults, resultsToAdd, meta, clientMeta) {
    const currentPage = meta.pagination.current_page
    let insertionIndex, numResultsToRemove

    if (pageInfoByPageNum[currentPage]) {
      // replacing existing page
      const { offset, size } = pageInfoByPageNum[currentPage]
      insertionIndex = offset
      numResultsToRemove = size
    } else {
      const precedingPage = _.findLast(
        pageInfoByPageNum,
        (pageInfo, pageNum) => pageInfo && pageNum < currentPage
      )
      insertionIndex = precedingPage
        ? precedingPage.offset + precedingPage.size
        : 0
      numResultsToRemove = 0
    }

    const newResults = existingResults ? existingResults.slice() : []
    newResults.splice(insertionIndex, numResultsToRemove, ...resultsToAdd)

    const newPageInfoByPageNum = pageInfoByPageNum.slice()
    newPageInfoByPageNum[currentPage] = {
      offset: insertionIndex,
      size: resultsToAdd.length,
    }

    // update later pages' offsets
    for (
      let pageNum = currentPage + 1;
      pageNum < newPageInfoByPageNum.length;
      pageNum += 1
    ) {
      if (newPageInfoByPageNum[pageNum]) {
        const { offset, size } = newPageInfoByPageNum[pageNum]
        newPageInfoByPageNum[pageNum] = {
          offset: offset - numResultsToRemove + resultsToAdd.length,
          size,
        }
      }
    }

    pageInfoByPageNum = newPageInfoByPageNum
    onChange(newResults, { ...meta, ...clientMeta })
  }
}
