import { useCallback, useContext, useEffect, useRef } from "react"
import { parseUrl } from "query-string"
import {
  BrowserHistoryStack,
  useBrowserHistoryStack,
} from "../../context/BrowserHistory"
import {
  addOrReplaceModalParams,
  getModalCloseHref,
  getModalHref,
} from "../../utils/modal"
import { useRouter } from "../routing/useRouter"
import { modalQsKey, Modals } from "../../constants/modals"
import { ModalContext } from "../../containers/ModalRouter/Modal"
import { QueryStringParams } from "../../types/url"
import { getPathQueryHash } from "../../utils/routing"

/**
 * More information about the modals system found under docs/modals.md
 */

const getPreviousLocationInStack = (
  browserHistoryStack: BrowserHistoryStack,
  currentIndex: number
) => {
  return currentIndex > 0 ? browserHistoryStack[currentIndex - 1] : null
}

const getPreviousPathQueryHashInStack = (
  browserHistoryStack: BrowserHistoryStack,
  currentIndex: number
) => {
  const location = getPreviousLocationInStack(browserHistoryStack, currentIndex)
  return (
    location &&
    [location.pathname, location.search, location.hash].filter(Boolean).join("")
  )
}

export const useCloseModalHref = () => {
  const router = useRouter()

  return getModalCloseHref(getPathQueryHash(router.location))
}

export const useCloseModal = () => {
  const router = useRouter()
  const { browserHistoryStack, currentIndex } = useBrowserHistoryStack()
  getPreviousPathQueryHashInStack(browserHistoryStack, currentIndex)

  const closedHref = getModalCloseHref(getPathQueryHash(router.location))

  return useCallback(() => {
    const previousHref = getPreviousPathQueryHashInStack(
      browserHistoryStack,
      currentIndex
    )

    if (previousHref === closedHref) {
      router.goBack()
    } else {
      // The user has likely either deep linked to the modal, or pressed
      // refresh while the modal was open. In which case, use a router.replace,
      // as to not add a second entry to the browser history, and to prevent
      // the user from going "back" into the open modal.
      router.replace(closedHref)
    }
  }, [browserHistoryStack, closedHref, currentIndex, router])
}

export const useModalHref = (modalKey: Modals, params?: QueryStringParams) => {
  const router = useRouter()

  return getModalHref(modalKey, params, getPathQueryHash(router.location))
}

export const useModalContext = () => useContext(ModalContext)

const useCurrentModalKey = () => {
  const router = useRouter()
  return parseUrl(getPathQueryHash(router.location)).query[modalQsKey]
}

// Effect that gets called automatically when any modal has closed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEffectOnModalClose = <T, Args extends any[]>(
  cb: (() => void) | (() => () => void)
) => {
  const modalKey = useCurrentModalKey()
  const initialCallRef = useRef(false)
  useEffect(() => {
    if (!initialCallRef.current) {
      // Don't run on the initial render
      initialCallRef.current = true
    } else {
      if (!modalKey) {
        // I guess this means that the cleanup would happen when the modal opens.
        // Not a big deal, but probably not what you would expect to happen.
        return cb()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalKey])
}

/**
 * Returns a function that allows you to add new modal params to the query string.
 * If the modal param already exists, it will be replaced instead.
 */
export const useAddOrReplaceModalParams = () => {
  const router = useRouter()

  return useCallback(
    (additionalParams: QueryStringParams) => {
      const currentUrl = getPathQueryHash(router.location)
      const newHref = addOrReplaceModalParams(currentUrl, additionalParams)
      router.replace(newHref)
    },
    [router]
  )
}
