import "./AnimatedEllipsis.less"
import React from "react"

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <div className="AnimatedEllipsis">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  )
}
