import _ from "lodash"
import RequestSupplanter from "./RequestSupplanter"
import { extractResponseBody, extractResponseKey } from "./apiActionHelpers"

/**
 * Performs searches against a REST API, using a given SupremeAgent instance.
 * When a search is requested, if there is a previous, ongoing request, the previous
 * request will be aborted.
 */
export default class SearchAgent {
  /**
   * @param {SupremeAgent} requestAgent
   * @param {string} resourcePath - base path for requests.
   * @param {number} pageSize - required. The default size of result pages to
   *    request. If you're not using the API in a paginated way, set this value
   *    to an arbitrarily large value like `1e9` to ensure requests always return
   *    all results.
   * @param {string} resourceName - required. Used to extract the results from the response body.
   * @param {string} resourceNameSingle - Used to extract the results from a get request.
   * @param {Function} modelWrapper - if provided, wraps any objects returned. Typically this will
   *    be a constructor function of a model class, like `PerformanceReview.of`.
   */
  constructor({
    requestAgent,
    resourcePath,
    resourceName,
    resourceNameSingle,
    pageSize,
    modelWrapper = _.identity,
  }) {
    if (!pageSize) {
      throw new Error("pageSize must be supplied")
    }
    if (!resourceName) {
      throw new Error("resourceName must be supplied")
    }
    this.requestSupplanter = new RequestSupplanter()
    this.requestAgent = requestAgent
    this.resourcePath = resourcePath
    this.resourceName = resourceName
    this.resourceNameSingle = resourceNameSingle
    this.pageSize = pageSize
    this.modelWrapper = modelWrapper
  }

  getRequestPath(fullResourcePath, path) {
    return fullResourcePath
      ? fullResourcePath
      : path
      ? `${this.resourcePath}/${path}`
      : this.resourcePath
  }

  /**
   * Performs a search against the configured `resourcePath`, prepended to `path`
   * if provided, combining `searchParams`, `page`, and `per_page` to form the
   * request query. If `fullResourcePath` is provided, it will overwrite the `resourcePath` used.
   * If there is a previous, ongoing request, that request will be aborted.
   *
   * @param {Object=} searchParams
   * @param {number=} page - defaults to 1
   * @param {number=} per_page - defaults to 1e9 (i.e., an attempt to load everything)
   * @param {string=} path
   * @param {string=} fullResourcePath
   *
   * @return {Promise} a promise that will resolve with an Object with the following
   *    properties:
   *
   *    - `results`: an Array of search results
   *    - `meta`: the `meta` object from the server's response body
   *    - `clientMeta`: an object of the form `{searchParams}`
   */
  search({
    searchParams = {},
    page = 1,
    per_page = this.pageSize,
    path = "",
    fullResourcePath = "",
  } = {}) {
    const requestPath = this.getRequestPath(fullResourcePath, path)
    const requestPromise = this.requestSupplanter.replaceRequest(
      this.requestAgent.get(requestPath).query({
        ...searchParams,
        page,
        per_page,
      })
    )

    return extractResponseBody(requestPromise).then((body) => {
      const results = body[this.resourceName].map(this.modelWrapper)

      const meta =
        body.meta && body.meta.pagination
          ? body.meta
          : {
              ...body.meta,
              pagination: {
                current_page: 1,
                next_page: null,
                prev_page: null,
                total_pages: 1,
                total_count: results.length,
              },
            }

      return { results, meta, clientMeta: { searchParams } }
    })
  }

  get({ itemId, path, fullResourcePath }) {
    const { requestAgent, resourceNameSingle, modelWrapper } = this

    if (!resourceNameSingle) {
      throw new Error("ResourceNameSingle not provided!")
    }

    const requestUrl = this.getRequestPath(fullResourcePath, path)

    return extractResponseKey(
      resourceNameSingle,
      modelWrapper,
      requestAgent.get(`${requestUrl}/${itemId}`)
    )
  }
}
