import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import Icon from "../Icon/Icon"
import "./CommentIndicator.less"

const CommentIndicator = ({ count, hasUnread }) =>
  count > 0 ? (
    <div
      className={cx("CommentIndicator layout horizontal center", {
        "CommentIndicator--highlighted": hasUnread,
      })}
    >
      <Icon iconName="comment" style={{ color: "#0168B3" }} />
      {count}
    </div>
  ) : null

CommentIndicator.propTypes = {
  count: PropTypes.number.isRequired,
  hasUnread: PropTypes.bool,
}

export default CommentIndicator
