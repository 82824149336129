import * as React from "react"
import { Text } from "@kaizen/component-library"
import { Select } from "@kaizen/draft-select"
import Avatar from "../../components/Avatar/Avatar"
import styles from "./GoalOwnerSelect.scss"
import { TeamGoalOwner } from "../../types/Goals"

export type GoalOwnerOption = {
  avatar: string
  label: string
  value: TeamGoalOwner
}

type GoalOwnerSelect = React.FunctionComponent<{
  title: string
  placeholder: string
  owners: TeamGoalOwner[]
  setFields: (users: TeamGoalOwner[]) => void
}>

const shapeOption = (member: TeamGoalOwner) => ({
  value: member,
  label: member.name,
  avatar: member.profileImage,
})

/**
 * Note that we are using !important in this component to override
 * React Select styles. Not the greatest idea, but this is that 0.1%
 * time we should use !important.
 */
const GoalOwnerSelect: GoalOwnerSelect = ({
  title,
  placeholder,
  owners,
  setFields,
}) => {
  const lockedOwners = owners.map((owner) => shapeOption(owner))

  return (
    <>
      <div className={styles.titleContainer}>
        <Text tag="h4" style="label">
          {title}
        </Text>
      </div>
      <Select
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        value={lockedOwners}
        styles={{
          // @ts-ignore
          multiValue: (provided, state) => ({
            ...provided,
            padding: "0px !important",
            paddingRight: "12px !important",
            borderRadius: "24px !important",
          }),
          // @ts-ignore
          multiValueLabel: (provided, state) => ({
            ...provided,
            padding: "3px !important",
          }),
          // @ts-ignore
          multiValueRemove: (provided, state) => ({
            ...provided,
            display: "none",
          }),
        }}
        options={[]}
        placeholder={placeholder}
        isDisabled={true}
        isMulti={true}
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        formatOptionLabel={({ label, avatar }: GoalOwnerOption) => (
          <div className={styles.goalOwnerSelect}>
            <Avatar size="small" imageURL={avatar} />
            <div className={styles.labelContainer}>{label}</div>
          </div>
        )}
      />
    </>
  )
}

export default GoalOwnerSelect
