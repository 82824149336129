import "./TabContent.less"
import * as React from "react"
import * as ReactIntl from "react-intl"
import { FormattedMessage } from "react-intl"
import { Heading } from "@kaizen/component-library"

export type Props = {
  content: React.ReactNode
  iconTabStyle?: boolean
  icon?: string
  id: string
  name: ReactIntl.FormattedMessage.MessageDescriptor
}

const TabContent = (props: Props) => (
  <section key={props.id} className={"Tabs--content cf"}>
    {props?.iconTabStyle && (
      <Heading variant="heading-2">
        <FormattedMessage {...props.name} />
      </Heading>
    )}
    {props.content}
  </section>
)

export default TabContent
