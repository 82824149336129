import React from "react"
import cx from "classnames"
import { IconButton } from "@kaizen/component-library"

import closeIcon from "@kaizen/component-library/icons/close.icon.svg"
import styles from "./Dossier.scss"

type DossierProps = {
  children: React.ReactElement
  isOpen: boolean
  size?: "small" | "default"
  hasDrawer?: boolean
  onClose?: () => void
}

export const Dossier: React.FunctionComponent<DossierProps> = ({
  children,
  onClose,
  isOpen,
  size = "default",
  hasDrawer = false,
}) => {
  return (
    <div
      className={cx(styles.dossier, {
        [styles.hasDrawer]: hasDrawer,
        [styles.dossierOpen]: isOpen,
        [styles.isSmall]: size === "small",
      })}
    >
      <div className={styles.dossierContainer}>
        <div className={styles.closeButton}>
          <IconButton label="" icon={closeIcon} onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  )
}
