import { Location } from "history"
import * as React from "react"
import { useEffect, useState } from "react"

import { hot } from "react-hot-loader/root"
import { IndexRedirect, InjectedRouter, Route } from "react-router"
import { TitleBlockZen, NavigationTab } from "@kaizen/draft-title-block-zen"
import Authenticate, {
  checkConfigurationOptions,
  // @ts-ignore
} from "../../utils/auth/Authenticate"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import EditPersonalGoal from "../EditPersonalGoal/EditPersonalGoal"
import EditTeamGoal from "../../entry/EditTeamGoal/EditTeamGoal"
import EditDepartmentGoal from "../EditDepartmentGoal/EditDepartmentGoal"
import EditCompanyGoal from "../EditCompanyGoal/EditCompanyGoal"
import GoalsList from "../GoalsList/GoalsList"
import NewPersonalGoal from "../NewPersonalGoal/NewPersonalGoal"
import NewTeamGoal from "../../entry/NewTeamGoal/NewTeamGoal"
import NewDepartmentGoal from "../NewDepartmentGoal/NewDepartmentGoal"
import NewCompanyGoal from "../NewCompanyGoal/NewCompanyGoal"
import PersonalGoalsList from "../../entry/PersonalGoalsList/PersonalGoalsList"
import TeamSummariesList from "../TeamSummariesList/TeamSummariesList"
import DepartmentSummariesList from "../DepartmentSummariesList/DepartmentSummariesList"
import TeamSummaryDetail from "../TeamSummaryDetail/TeamSummaryDetail"
import { PageLayout } from "../../components/layout/PageLayout/PageLayout"
import useGoalDetails from "../../domainHooks/goals/useGoalDetails"
import GoalDetails from "../../containers/GoalDossier/GoalDetails"
import Loading from "../../components/Loading/Loading"
import CompanySummariesList from "../../entry/CompanySummariesList/CompanySummariesList"
import ConfigurationOptions from "../../constants/configurationOptions"
import strings from "../../locale/strings"
import { useIntl } from "../../hooks/locale/useIntl"
import {
  getManagedDepartments,
  hasConfigOption,
  isAdmin,
  isHrbp,
} from "../../domain/user/user"
import { CurrentUser } from "../../types/User"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import useTeams from "../../domainHooks/teams/useTeams"

type GoalsApp = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
}>

type Goal = {
  type: string
  createLabel: string
  tabLabel: string
  path: string
  createGoalPath: string
  visible: () => void
  createGoalTitle: string
}

const GOAL_TYPES = {
  personal: {
    type: "personal",
    sectionName: strings.goalsPage.goalType.individual.sectionName,
    createLabel: strings.goalsPage.goalType.individual.createGoalButton,
    tabLabel: strings.goalsPage.goalType.individual.tabLabel,
    path: "/new_goals/personal",
    createGoalPath: "/new_goals/personal/new",
    createGoalTitle: strings.newIndividualGoal.title,
    editGoalTitle: strings.newIndividualGoal.edit,
    visible: () => true,
  },
  team: {
    type: "team",
    sectionName: strings.goalsPage.goalType.team.sectionName,
    createLabel: strings.goalsPage.goalType.team.createGoalButton,
    tabLabel: strings.goalsPage.goalType.team.tabLabel,
    path: "/new_goals/team",
    createGoalPath: "/new_goals/team/new",
    createGoalTitle: strings.newTeamGoal.title,
    editGoalTitle: strings.newTeamGoal.edit,
    visible: (user: CurrentUser) =>
      hasConfigOption(user, ConfigurationOptions.teamGoals),
  },

  department: {
    type: "department",
    sectionName: strings.goalsPage.goalType.department.sectionName,
    createLabel: strings.goalsPage.goalType.department.createGoalButton,
    tabLabel: strings.goalsPage.goalType.department.tabLabel,
    path: "/new_goals/department",
    createGoalPath: "/new_goals/department/new",
    createGoalTitle: strings.newDepartmentGoal.title,
    editGoalTitle: strings.newDepartmentGoal.edit,
    visible: (user: CurrentUser) =>
      isAdmin(user) || isHrbp(user) || getManagedDepartments(user).length > 0,
  },
  company: {
    type: "company",
    sectionName: strings.goalsPage.goalType.company.sectionName,
    createLabel: strings.goalsPage.goalType.company.createGoalButton,
    tabLabel: strings.goalsPage.goalType.company.tabLabel,
    path: "/new_goals/company",
    createGoalPath: "/new_goals/company/new",
    createGoalTitle: strings.newCompanyGoal.title,
    editGoalTitle: strings.newCompanyGoal.edit,
    visible: () => true,
  },
}

const GoalsApp: GoalsApp = ({ children, location, router }) => {
  const {
    isOpen: dossierIsOpen,
    close: closeDossier,
    goalId = "",
  } = useGoalDossier(router, location)
  const { goal } = useGoalDetails(Number(goalId))
  const currentUser = useCurrentUser()
  const { formatMessage } = useIntl()
  const { teams } = useTeams({ all_teams: false })

  const [activePage, setActivePage] = useState(GOAL_TYPES.personal.type)
  const [creatingGoal, setCreatingGoal] = useState("")
  const [editingGoal, setEditingGoal] = useState("")

  const resetGoal = () => {
    setCreatingGoal("")
    setEditingGoal("")
  }

  useEffect(() => {
    // Set context from url for edit and create modes.
    const url = location.pathname.replace("new_goals", "goals")

    if (url.includes("edit")) {
      Object.keys(GOAL_TYPES).forEach((k) => {
        if (url.includes(k)) setEditingGoal(k)
      })
    }

    if (url.includes("new")) {
      Object.keys(GOAL_TYPES).forEach((k) => {
        if (url.includes(k)) setCreatingGoal(k)
      })
    }

    if (!url.includes("new") && !url.includes("edit")) resetGoal()
  }, [location])

  const handleNavigationTabPageChange = (goal: Goal) => {
    setActivePage(goal.type)
    resetGoal()

    router.push(goal.path)
  }

  const teamGoalCreationEnabled = teams.length > 0

  const handleCreateButtonClick = (goal: Goal) => {
    setActivePage(goal.type)
    setCreatingGoal(goal.type)

    router.push(goal.createGoalPath)
  }

  const renderNavigationTabs = () => {
    return Object.keys(GOAL_TYPES).map((k: string) => {
      // @ts-ignore
      const goal = GOAL_TYPES[k]
      return (
        goal.visible(currentUser) && (
          <NavigationTab
            key={goal.type}
            text={formatMessage(goal.tabLabel)}
            href="#"
            handleClick={() => handleNavigationTabPageChange(goal)}
            active={activePage === goal.type}
          />
        )
      )
    })
  }

  const getGoalButtonMenuItems = () => {
    return Array.from(
      Object.keys(GOAL_TYPES).map((k: string) => {
        // @ts-ignore
        const goal = GOAL_TYPES[k]

        return {
          label: formatMessage(goal.createLabel),
          action: () => handleCreateButtonClick(goal),
          disabled: !(goal.type === "team"
            ? teamGoalCreationEnabled && goal.visible(currentUser)
            : goal.visible(currentUser)),
        }
      })
    )
  }

  const getGoal = (type: string) => {
    // @ts-ignore
    return GOAL_TYPES[type]
  }

  return (
    <PageLayout
      dossierSettings={{
        isOpen: dossierIsOpen,
        content: goal ? <GoalDetails goal={goal} /> : <Loading />,
        onClose: closeDossier,
      }}
      headingContent={
        creatingGoal || editingGoal ? (
          <TitleBlockZen
            title={
              creatingGoal
                ? formatMessage(getGoal(creatingGoal).createGoalTitle)
                : formatMessage(getGoal(editingGoal).editGoalTitle)
            }
            breadcrumb={{
              path: "#",
              text: formatMessage(strings.goalsPage.backToGoalsSection, {
                section: formatMessage(
                  getGoal(creatingGoal || editingGoal).sectionName
                ),
              }),
              handleClick: () =>
                handleNavigationTabPageChange(
                  getGoal(creatingGoal || editingGoal)
                ),
            }}
          />
        ) : (
          <TitleBlockZen
            title={formatMessage(strings.goalsPage.header)}
            navigationTabs={renderNavigationTabs()}
            primaryAction={{
              label: formatMessage(strings.goalsPage.createGoalButton),
              menuItems: getGoalButtonMenuItems(),
            }}
            surveyStatus={{
              text: formatMessage(strings.general.live),
              status: "live",
            }}
          />
        )
      }
    >
      {children}
    </PageLayout>
  )
}

export const routes = (
  <Route
    path="new_goals"
    component={hot(GoalsApp)}
    onEnter={Authenticate({
      // @ts-ignore
      predicate: (user) => user.company.allow_new_goals_interface,
    })}
  >
    <Route
      path="team/new"
      component={hot(NewTeamGoal)}
      onEnter={checkConfigurationOptions(ConfigurationOptions.teamGoals)}
    />
    <Route component={hot(GoalsList)}>
      <Route path="personal" component={hot(PersonalGoalsList)} />
      <Route
        path="team"
        component={hot(TeamSummariesList)}
        onEnter={checkConfigurationOptions(ConfigurationOptions.teamGoals)}
      />
      <Route path="department" component={hot(DepartmentSummariesList)} />
      <Route path="company" component={hot(CompanySummariesList)} />
      <Route
        path="team/:teamId"
        component={hot(TeamSummaryDetail)}
        onEnter={checkConfigurationOptions(ConfigurationOptions.teamGoals)}
      />
    </Route>
    <Route path="personal/new" component={hot(NewPersonalGoal)} />
    <Route path="company/new" component={hot(NewCompanyGoal)} />
    <Route path="personal/edit/:goalId" component={hot(EditPersonalGoal)} />
    <Route path="department/new" component={hot(NewDepartmentGoal)} />
    <Route path="company/edit/:goalId" component={hot(EditCompanyGoal)} />
    <Route
      path="department/:departmentId/edit/:goalId"
      component={hot(EditDepartmentGoal)}
    />
    <Route
      path="team/:teamId/new"
      component={hot(NewTeamGoal)}
      onEnter={checkConfigurationOptions(ConfigurationOptions.teamGoals)}
    />
    <Route
      path="team/:teamId/edit/:goalId"
      component={hot(EditTeamGoal)}
      onEnter={checkConfigurationOptions(ConfigurationOptions.teamGoals)}
    />
    <IndexRedirect to="personal" />
  </Route>
)
