import BaseModel from "./BaseModel"

export const DEPARTMENT_TYPE = "department"
export const JOB_TITLE_TYPE = "job_title"

export function getDeptLabeledJobTitle({ parent_job_title, title }) {
  return parent_job_title ? `${title} (${parent_job_title.title})` : title
}

/**
 * The purpose of this is for when we want the job title text to be the query
 * value but we need a unique id in the select etc. Would be used in conjunction
 * with destructJobTitleIdWithDept
 */
export function createJobTitleIdWithDept({ jobTitle }) {
  return `${jobTitle.title};dId;${jobTitle.parent_job_title.id}`
}

/**
 * Partner to the function createJobTitleIdWithDept. Strips the id out of the
 * string so we can query based on title rather than id.
 */
export function destructJobTitleIdWithDept({ jobTitleIdWithDept }) {
  return jobTitleIdWithDept.split(";dId;")
}

/**
 * Base class that represents either a `Department` or a `JobTitle`
 */
export default class EmployeeGrouping extends BaseModel {
  constructor(props) {
    super(props)

    if (this.parent_job_title_id === undefined) {
      this.parent_job_title_id =
        this.parent_job_title && this.parent_job_title.id
    }
  }

  /**
   * Creates either a `JobTitle` or `Department` instance from `props`.
   */
  static of(props) {
    const Class =
      // Disabling because this was before my time, but really a parent shouldn't
      // know anything about its children.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      props.job_title_type === DEPARTMENT_TYPE ? Department : JobTitle
    return new Class(props)
  }
}

export class JobTitle extends EmployeeGrouping {
  job_title_type = JOB_TITLE_TYPE
}

export class Department extends EmployeeGrouping {
  job_title_type = DEPARTMENT_TYPE
  job_titles_count = this.job_titles_count || 0

  createJobTitle(props) {
    const jobTitle = new JobTitle(props)
    jobTitle.parent_job_title_id = this.id
    return jobTitle
  }
}
