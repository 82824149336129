import moment, { Moment } from "moment-timezone"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import {
  EvaluationCycle,
  EvaluationCycleListingItem,
  EvaluationCycleQuestion,
  EvaluationState,
  ManagerEvaluationModule,
  Module,
  ModuleMap,
  ModuleType,
  PeerAndUpwardFeedbackModule,
  QuestionsGroup,
  QuestionsListType,
} from "../../types/EvaluationCycle"
import { CurrentUser } from "../../types/User"
import { isAdmin, hasFlag } from "../user/user"
import { moduleTitles, questionTitles } from "../../constants/evaluationCycles"
import { flatMap } from "../../utils/array"
import FeatureFlags from "../../constants/featureFlags"

export const allowForNewEvaluationCyclesAdminPages = (user: CurrentUser) => {
  return (
    hasFlag(user, FeatureFlags.mrfCycles) &&
    // ie. is an HRBP admin user
    // Currently, the backend is not ready to allow for HRBP users, so
    // we're temporarily disabling this functionality.
    // This is expected to be completed by end of July 2020.
    isAdmin(user)
  )
}

export const getCycleDateRange = (
  cycle: EvaluationCycle
): {
  startDate: Moment | undefined
  endDate: Moment | undefined
} => {
  if (!cycle.timezone) throw new Error("Timezone must be supplied")

  const manEval = cycle.modules.manager_evaluation
  const pauf = cycle.modules.peer_and_upward_feedback
  const selfReview = cycle.modules.self_review

  const startDates = ([
    manEval.is_included && manEval.start_date,
    pauf.is_included && pauf.reviewer_selection_start_date,
    pauf.is_included && pauf.completion_start_date,
    pauf.peer_nomination_cycle.is_included &&
      pauf.peer_nomination_cycle.start_date,
    selfReview.is_included && selfReview.start_date,
  ].filter(Boolean) as string[]).map((dateStr: string) =>
    moment(dateStr).tz(cycle.timezone as string)
  )

  const endDates = ([
    manEval.is_included && manEval.end_date,
    pauf.is_included && pauf.reviewer_selection_end_date,
    pauf.is_included && pauf.completion_end_date,
    pauf.peer_nomination_cycle.is_included &&
      pauf.peer_nomination_cycle.end_date,
    selfReview.is_included && selfReview.end_date,
  ].filter(Boolean) as string[]).map((dateStr: string) =>
    moment(dateStr).tz(cycle.timezone as string)
  )

  return {
    startDate: startDates.length
      ? _.min(startDates, (d: Moment) => d.valueOf())
      : undefined,
    endDate: endDates.length
      ? _.max(endDates, (d: Moment) => d.valueOf())
      : undefined,
  }
}

// get "Peer and upward feedback" date range
// Note: The returned date ranges are in the timezone as specified by the
// cycle. The only areas in the app that we want this is in the admin creation
// and edit modals. Everywhere else, we actually want to show times in the user's
// local timezone. So be careful when using this function.
export const getPaufDateRange = (
  cycle: EvaluationCycle
): {
  startDate: Moment | null
  endDate: Moment | null
} => {
  if (!cycle.timezone) throw new Error("Timezone must be supplied")

  const pauf = cycle.modules.peer_and_upward_feedback

  const startDates = ([
    pauf.reviewer_selection_start_date,
    pauf.completion_start_date,
    pauf.peer_nomination_cycle.start_date,
  ].filter(Boolean) as string[]).map((dateStr: string) =>
    moment(dateStr).tz(cycle.timezone as string)
  )

  const endDates = ([
    pauf.reviewer_selection_end_date,
    pauf.completion_end_date,
    pauf.peer_nomination_cycle.end_date,
  ].filter(Boolean) as string[]).map((dateStr: string) =>
    moment(dateStr).tz(cycle.timezone as string)
  )

  return {
    startDate: startDates.length
      ? _.min(startDates, (d: Moment) => d.valueOf())
      : null,
    endDate: endDates.length
      ? _.max(endDates, (d: Moment) => d.valueOf())
      : null,
  }
}

// get "Manager Evaluation" date range
// Note: The returned date ranges are in the timezone as specified by the
// cycle. The only areas in the app that we want this is in the admin creation
// and edit modals. Everywhere else, we actually want to show times in the user's
// local timezone. So be careful when using this function.
export const getManEvalDateRange = (
  cycle: EvaluationCycle
): {
  startDate: Moment | null
  endDate: Moment | null
} => {
  if (!cycle.timezone) throw new Error("Timezone must be supplied")

  const manEval = cycle.modules.manager_evaluation

  return {
    startDate: manEval.start_date
      ? moment(manEval.start_date).tz(cycle.timezone as string)
      : null,
    endDate: manEval.end_date
      ? moment(manEval.end_date).tz(cycle.timezone as string)
      : null,
  }
}

export const getSelfReviewDateRange = (
  cycle: EvaluationCycle
): {
  startDate: Moment | null
  endDate: Moment | null
} => {
  if (!cycle.timezone) throw new Error("Timezone must be supplied")

  const selfReview = cycle.modules.self_review

  return {
    startDate: selfReview.start_date
      ? moment(selfReview.start_date).tz(cycle.timezone as string)
      : null,
    endDate: selfReview.end_date
      ? moment(selfReview.end_date).tz(cycle.timezone as string)
      : null,
  }
}

export const createEmptyCycle = (): EvaluationCycle => ({
  name: "",
  subject_ids: [],
  modules: {
    peer_and_upward_feedback: {
      is_included: false,
      peer_nomination_cycle: {
        is_included: false,
      },
    },
    manager_evaluation: { is_included: false },
    self_review: { is_included: false },
  },
  state: "draft",
})

// Note: non-admins who are hrbp users can still see the "admin -> evaluation cycles" screen,
// but they are not allowed to create cycles or make edits.
export const getCanCreateEvaluationCycles = (user: CurrentUser) => {
  return isAdmin(user)
}

export const getCanUseDraftModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => isAdmin(user) && cycle.state === "draft"

export const getCanUseEditSettingsModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) =>
  hasFlag(user, FeatureFlags.ecIncompleteFeatures) &&
  isAdmin(user) &&
  cycle.state !== "draft"

export const getCanUseEditEmployeesModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => isAdmin(user) && cycle.state !== "draft" && cycle.state !== "closed"

export const getCanEditScheduleModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => isAdmin(user) && cycle.state !== "draft" && cycle.state !== "closed"

export const canViewScheduleOnly = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) =>
  !hasFlag(user, FeatureFlags.ecIncompleteFeatures) &&
  isAdmin(user) &&
  cycle.state !== "draft"

export const isStartDateDisabled = (
  cycleState: EvaluationState,
  date: string,
  now: Moment
): boolean => {
  return cycleState !== "draft" ? now.isAfter(moment(date as string)) : false
}
// We return `false` if the cycle is in draft mode. This is only temporary, as
// the perf-api backend does not support this yet. Ideally we should be
// able to duplicate the questions of any cycle, as long as the user is an admin.
export const getCanDuplicateCycle = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) =>
  hasFlag(user, FeatureFlags.ecIncompleteFeatures) &&
  isAdmin(user) &&
  cycle.state !== "draft"

export const getCanCloseCycle = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => isAdmin(user) && cycle.state === "active"

export const getCanReopenCycle = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) =>
  hasFlag(user, FeatureFlags.ecIncompleteFeatures) &&
  isAdmin(user) &&
  cycle.state === "closed"

export const getCanDeleteCycle = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => hasFlag(user, FeatureFlags.ecIncompleteFeatures) && isAdmin(user)

// Returns if the questions can be edited for a particular module
export const getCanEditQuestions = (
  moduleType: ModuleType,
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => {
  return (
    isAdmin(user) &&
    cycle.modules[moduleType].is_included &&
    (cycle.modules[moduleType].state === "ready" ||
      cycle.modules[moduleType].state === "draft")
  )
}

export type ModuleWithType = Module & { type: ModuleType }

export const getIncludedModules = (
  cycle: EvaluationCycle
): Partial<ModuleMap> => {
  return _.pick(cycle.modules, (module: Module) => module.is_included)
}

export const getIncludedModulesAsList = (
  cycle: EvaluationCycle
): ModuleWithType[] => {
  const unsortedList = (_.pairs(cycle.modules) as [ModuleType, Module][])
    .filter(([moduleKey, module]) => module.is_included)
    .map(([moduleKey, module]) => ({
      type: moduleKey,
      ...(module as Module),
    }))

  const moduleToTypesMap: {
    [type in ModuleType]: number
  } = {
    self_review: 1,
    peer_and_upward_feedback: 2,
    manager_evaluation: 3,
  }

  return _.sortBy(
    unsortedList,
    (module: ModuleWithType) => moduleToTypesMap[module.type]
  )
}

export const paufQuestionsIntoGroups = (
  peerQuestions: EvaluationCycleQuestion[] | undefined, // These undefined checks shouldn't be necessary
  upwardQuestions: EvaluationCycleQuestion[] | undefined
): QuestionsGroup[] => [
  {
    type: "peer_feedback",
    moduleType: "peer_and_upward_feedback",
    questions: peerQuestions || [],
  },
  {
    type: "upward_feedback",
    moduleType: "peer_and_upward_feedback",
    questions: upwardQuestions || [],
  },
]

export const getQuestionsGroupsAsList = (
  cycle: EvaluationCycle
): QuestionsGroup[] => {
  const modules = getIncludedModulesAsList(cycle)
  return flatMap(modules, (m: ModuleWithType) =>
    // Peer and upward feedback is the only module that has two sets
    // of questions.
    m.type === "peer_and_upward_feedback"
      ? paufQuestionsIntoGroups(
          (m as PeerAndUpwardFeedbackModule).peer_feedback_questions,
          (m as PeerAndUpwardFeedbackModule).upward_feedback_questions
        )
      : [
          {
            type: m.type,
            questions: (m as ManagerEvaluationModule).questions,
            moduleType: m.type,
          } as QuestionsGroup,
        ]
  )
}

// Same as getQuestionsGroupsAsList, but return an object instead
export const getQuestionsGroupsAsObject = (
  cycle: EvaluationCycle
): Partial<{ [key in QuestionsListType]: EvaluationCycleQuestion[] }> => {
  return _.zipObject(
    getQuestionsGroupsAsList(cycle).map((qg) => [qg.type, qg.questions])
  )
}

const getDoesCycleHaveAnyModulesWithEditableQuestions = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => {
  // @ts-ignore: I'm not too sure how to fix the getModulesAsList function
  // to allow for both "EvaluationCycle | EvaluationCycleListingItem", given
  // that their module types are both different. So I'll just ignore this error...
  return getIncludedModulesAsList(cycle).some((module) =>
    getCanEditQuestions(module.type, cycle, user)
  )
}

export const getCanUseEditQuestionsAndSharingModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) => {
  return (
    hasFlag(user, FeatureFlags.ecIncompleteFeatures) &&
    isAdmin(user) &&
    cycle.state !== "draft" &&
    getDoesCycleHaveAnyModulesWithEditableQuestions(cycle, user)
  )
}

export const getCanUsePreviewModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) =>
  isAdmin(user) &&
  cycle.state !== "draft" &&
  (!getDoesCycleHaveAnyModulesWithEditableQuestions(cycle, user) ||
    !hasFlag(user, FeatureFlags.ecIncompleteFeatures))

// Returns true if we even need to bother showing the sharing step to the user
// Manager evaluations are the only modules that actually have sharing
export const isSharingStepRelevant = (
  cycle: EvaluationCycle | EvaluationCycleListingItem
): boolean => cycle.modules.manager_evaluation.is_included

// Sub-related to the `isSharingStepRelevant` function above. This determines
// if that "Enable sharing" switch is visible or not. For draft and ready states,
// it makes no sense to show this button, since we typically want to share the
// results all after the feedback has been given. There are also some backend
// notification issues when you turn on sharing prior to the cycle starting.
// We also return false if no individual questions have been selected for sharing, because
// the admin would effectively be sharing no information at all.
export const getIsGlobalSharingRelevant = (cycle: EvaluationCycle): boolean =>
  cycle.state !== "draft" &&
  cycle.modules.manager_evaluation.is_included &&
  cycle.modules.manager_evaluation.state !== "ready" &&
  !!cycle.modules.manager_evaluation.questions?.find((q) => q.can_share)

// This is for the sharing step.
// If the state of the cycle is `active`, meaning we can't edit questions,
// and there were no individual questions marked as sharable, it would be
// completely pointless to allow the admin to toggle global sharing, because
// there would be nothing to share.
export const getIsNothingSharableWarningVisible = (
  cycle: EvaluationCycle,
  user: CurrentUser
) =>
  cycle.modules.manager_evaluation.is_included &&
  !getCanEditQuestions("manager_evaluation", cycle, user) &&
  !getIsGlobalSharingRelevant(cycle)

export const getCanUseEditSharingModal = (
  cycle: EvaluationCycle | EvaluationCycleListingItem,
  user: CurrentUser
) =>
  isAdmin(user) &&
  isSharingStepRelevant(cycle) &&
  cycle.state !== "draft" &&
  !getDoesCycleHaveAnyModulesWithEditableQuestions(cycle, user)

export const getModuleTitle = (
  type: ModuleType
): FormattedMessage.MessageDescriptor => moduleTitles[type]

export const getQuestionsTitle = (
  type: QuestionsListType
): FormattedMessage.MessageDescriptor => questionTitles[type]

export const getQuestionsAsListWithTitles = (
  cycle: EvaluationCycle,
  formatMessage: (s: FormattedMessage.MessageDescriptor) => string
): {
  type: QuestionsListType
  moduleType: ModuleType
  questions?: EvaluationCycleQuestion[]
  title: string
}[] => {
  return getQuestionsGroupsAsList(cycle).map((q) => ({
    ...q,
    title: formatMessage(getQuestionsTitle(q.type)),
  }))
}

export const getHasPaufCompletionStarted = (
  cycle: EvaluationCycle,
  now: Date
): boolean => {
  return cycle.modules.peer_and_upward_feedback.is_included &&
    cycle.modules.peer_and_upward_feedback.completion_start_date
    ? moment(
        cycle.modules.peer_and_upward_feedback.completion_start_date
      ).isBefore(now)
    : false
}

export const getCanDownloadCsv = (
  cycle: EvaluationCycle | EvaluationCycleListingItem
) => cycle.state !== "draft" && cycle.modules.manager_evaluation.is_included

export const getCanViewFeedback = (
  cycle: EvaluationCycle | EvaluationCycleListingItem
) =>
  cycle.state !== "draft" &&
  cycle.modules.manager_evaluation.is_included &&
  cycle.modules.peer_and_upward_feedback.is_included
