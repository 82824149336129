import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import commentIcon from "@kaizen/component-library/icons/comment.icon.svg"
import starIcon from "@kaizen/component-library/icons/star-on.icon.svg"
import communicationsIcon from "@kaizen/component-library/icons/communications.icon.svg"
import connect from "../../higherOrderComponents/connect"
import Company from "../../models/Company"
import Actions from "../../refluxActions"
import TabsDeprecated from "../../components/TabsDeprecated/TabsDeprecated"
import PastFeedback from "./PastFeedback"
import PastPerformanceReviews from "./PastPerformanceReviews"
import GivenFeedbackStore from "../../refluxStores/GivenFeedbackStore"
import PerformanceReviewHackStore from "../../refluxStores/PerformanceReviewHackStore"
import SurveyResponseStore from "../../refluxStores/SurveyResponseStore"
import FeedbackStore from "../../refluxStores/FeedbackStore"
import GoalsOverview from "../goals/GoalsOverview"
import GoalsTableDeprecated from "../goals/GoalsTableDeprecated"
import ViewGoalModal from "../goals/ViewGoalModal"
import GoalStore, { GoalSource } from "../../refluxStores/GoalStore"
import ProfileHeader from "../ProfileHeader/ProfileHeader"
import strings from "../../locale/strings"
import Loader from "../../components/Loader/Loader"
import UserNotes from "../UserNotes/UserNotes"
import UserInfo from "./UserInfo"
import EmptyState from "../../components/EmptyState/EmptyState"
import PersonalGoalsEmptyState from "../PersonalGoalsEmptyState/PersonalGoalsEmptyState"
import GoalDateRangePicker from "../goals/GoalDateRangePicker"
import User from "../../models/User"
import { isSmallScreenBrowser } from "../../utils/browser"
import ProfileSelfReflectionEmptyState from "../self-reflections/ProfileSelfReflectionEmptyState"
import ProfileSelfReflectionSurveyResponseList from "../surveys/self-reflections/ProfileSelfReflectionSurveyResponseList"
import noFeedbackGivenImg from "../../static/images-2017/drawings/emptystates/feedback.png"
import emptyTeamEvaluationsImg from "../../static/images-2017/drawings/emptystates/evals.png"
import { ActionItems } from "../../components/ActionItems/ActionItems"
import FeatureFlags from "../../constants/featureFlags"
import "./ProfileContent.less"
import ConfigurationOptions from "../../constants/configurationOptions"
import * as AdminActions from "../../admin/refluxActions"
import AdminGoalStore from "../../admin/refluxStores/AdminGoalStore"

class ProfileContent extends React.PureComponent {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    selfReflectionToExcludeId: PropTypes.number,
    hideActionButtons: PropTypes.bool,
    className: PropTypes.string,
    iconTabStyle: PropTypes.bool,
    useGoalsTable: PropTypes.bool,
    savedViewId: PropTypes.number,
    tabsAlign: PropTypes.string,
    dossierDrawer: PropTypes.bool,
    isOnAdminDashboard: PropTypes.bool,
    includeTabs: PropTypes.array,
  }

  static defaultProps = {
    iconTabStyle: false,
    hideActionButtons: false,
    className: "",
    tabsAlign: "center",
    dossierDrawer: false,
    isOnAdminDashboard: false,
    includeTabs: [
      "feedback",
      "selfReflection",
      "goals",
      "evaluations",
      "notes",
      "info",
    ],
  }

  constructor(props, context) {
    super(props, context)

    const { user } = this.context

    this.state = {
      selectedFeedbackFilter: "all",
      selectedGoalRange: Company.getEnclosingGoalPeriod(
        user.company,
        new Date()
      ),
      selectedGoalSorting: "due_at",
      goalToShow: null,
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const {
      selectedGoalRange,
      selectedGoalSorting,
      selectedFeedbackFilter,
    } = this.state

    if (
      !_.isEqual(selectedGoalRange, prevState.selectedGoalRange) ||
      selectedGoalSorting !== prevState.selectedGoalSorting
    ) {
      this.searchGoalsWithSortCriteria(selectedGoalSorting)
    } else if (selectedFeedbackFilter !== prevState.selectedFeedbackFilter) {
      this.loadReviewsGivenByRevieweeId()
    }
  }

  // Generally used to determine if we are looking at a self reflection page or not
  isOwnProfile() {
    return User.equals(this.context.user, this.props.user)
  }

  getUserId() {
    const { user } = this.props
    return user.user_id || user.id
  }

  handleFirstVisitToTab(id) {
    const userId = this.getUserId()
    const { savedViewId } = this.props

    switch (id) {
      case "feedback":
        this.loadReviewsGivenByRevieweeId()
        break
      case "selfReflection":
        Actions.SurveyResponse.loadCompletedSelfReflectionByUserId({
          userId,
          savedViewId,
          currentUser: this.context.user,
        })
        break
      case "evaluations":
        Actions.PerformanceReview.getPastReviewsByUserId({
          userId,
          savedViewId,
        })
        break
      case "goals":
        this.searchGoalsWithSortCriteria("due_at")
        break
      case "notes":
        Actions.User.getNote({ userId })
        break
    }
  }

  loadReviewsGivenByRevieweeId() {
    const {
      user: { id: revieweeId },
      savedViewId,
      isOnAdminDashboard,
    } = this.props
    const { selectedFeedbackFilter } = this.state
    const userId = this.getUserId()

    const isOnAdminDashboardString = isOnAdminDashboard ? "true" : "false"

    Actions.Feedback.localClearReviewsGivenByRevieweeId({ revieweeId })
    Actions.Feedback.loadReviewsGivenByRevieweeId({
      userId,
      revieweeId,
      type: selectedFeedbackFilter,
      savedViewId: savedViewId ? savedViewId : null,
      isOnAdminDashboard: isOnAdminDashboardString,
    })
  }

  searchGoalsWithSortCriteria = (sortBy) => {
    this.searchGoals({
      sortBy,
      sortOrder: sortBy === "due_at" ? "asc" : "desc",
    })
  }

  searchGoals = (searchParams) => {
    const { selectedGoalRange } = this.state
    const { user, isOnAdminDashboard } = this.props

    const listGoalsParams = {
      userId: this.getUserId(),
      usersDepartmentId: user.department_id,
      showLoadingState: false,
      from: selectedGoalRange[0],
      to: selectedGoalRange[1],
      type: "my_goal",
      ...searchParams,
    }

    isOnAdminDashboard
      ? AdminActions.Goal.list(listGoalsParams)
      : Actions.Goal.list(listGoalsParams)
  }

  transformOwnReviews(reviews) {
    return {
      reviews: _(reviews)
        .map((review) => ({
          id: review.id,
          source_obj: {
            // eslint-disable-line camelcase
            type: "review",
            review,
          },
        }))
        .value(),
    }
  }

  transformSelfReflections(selfReflections) {
    if (!selfReflections) {
      return selfReflections
    }

    return _(selfReflections)
      .filter((selfReflection) => selfReflection.completed_at)
      .map((selfReflection) => ({
        id: selfReflection.id,
        source_obj: {
          self_reflection: selfReflection,
          type: "self_reflection",
        },
      }))
      .value()
  }

  renderOptionFilters(company) {
    const currentUser = this.context.user
    const dropDownOption = [
      {
        text: strings.profileContent.optionsFilter.allFeedback,
        value: "all",
      },
      {
        text: strings.profileContent.optionsFilter.managerRequestedFeedback,
        value: "manager_requested_feedbacks",
      },
      {
        text: strings.profileContent.optionsFilter.continuousFeedback,
        value: "reviews",
      },
    ]

    if (User.hasFlag(currentUser, FeatureFlags.mrfCycles)) {
      dropDownOption.push(
        {
          text: strings.profileContent.optionsFilter.peerFeedback,
          value: "peer_feedbacks",
        },
        {
          text: strings.profileContent.optionsFilter.upwardFeedback,
          value: "upward_feedbacks",
        }
      )
    }

    if (
      User.hasConfigOption(currentUser, ConfigurationOptions.teamBasedFeedback)
    ) {
      dropDownOption.push({
        text: strings.profileContent.optionsFilter.teamBasedFeedback,
        value: "team_based_feedbacks",
      })
    }

    return company.allow_peer_feedback_invites && company.allow_reviews
      ? dropDownOption
      : []
  }

  getSelfReflections() {
    const { selfReflectionToExcludeId } = this.props
    const userId = this.getUserId()

    const selfReflections = this.props.surveyResponseData
      .selfReflectionsByUserId[userId]

    return (
      selfReflections &&
      _.reject(selfReflections, { id: selfReflectionToExcludeId })
    )
  }

  getFeedbackReviews() {
    const { givenFeedbackData, user } = this.props

    return givenFeedbackData.reviewsGivenByRevieweeId[user.id]
  }

  handleFeedbackFilterChange = (value) => {
    this.setState({ selectedFeedbackFilter: value })
  }

  loadNextFeedbackPage = () => {
    const { user, savedViewId, isOnAdminDashboard } = this.props
    const reviews = _.get(this.getFeedbackReviews(), "reviews")
    const userId = this.getUserId()

    const isOnAdminDashboardString = isOnAdminDashboard ? "true" : "false"

    if (_.get(reviews, "length") > 0) {
      const lastSourceObj = reviews[reviews.length - 1].source_obj
      const lastReview = lastSourceObj[lastSourceObj.type]
      const newQueryDate = lastReview.completed_at || lastReview.created_at
      return Actions.Feedback.loadReviewsGivenByRevieweeId({
        revieweeId: user.id,
        type: this.state.selectedFeedbackFilter,
        beforeDate: moment(newQueryDate).subtract(1, "s").toISOString(),
        userId,
        savedViewId,
        isOnAdminDashboard: isOnAdminDashboardString,
      })
    }

    return Promise.resolve()
  }

  hasMoreReviewPages = () => {
    return !!_.get(this.getFeedbackReviews(), "hasMore")
  }

  handleGoalsRangeChange = (selectedGoalRange) => {
    this.setState({ selectedGoalRange })
  }

  handleGoalsSortingChange = (selectedGoalSorting) => {
    this.setState({ selectedGoalSorting })
  }

  renderActionButtons() {
    const { user } = this.props
    const { email } = user
    const { id } = user
    const menuItems = [
      {
        icon: communicationsIcon,
        text: strings.general.requestFeedback,
        onClick: () => {
          this.context.router.push({
            pathname: "requests",
            query: { reviewerId: user.id },
          })
        },
        priorityAction: true,
      },
      {
        icon: starIcon,
        text: strings.general.reviewSkills,
        onClick: () => {
          this.context.router.push({
            pathname: "review",
            query: { email },
          })
        },
        priorityAction: true,
      },
      {
        icon: commentIcon,
        text: strings.general.quickComment,
        onClick: () => {
          this.context.router.push({
            pathname: "quick_comment",
            query: { revieweeId: id },
          })
        },
        priorityAction: true,
      },
    ]
    return <ActionItems menuItems={menuItems} />
  }

  renderGoalsTab() {
    const {
      useGoalsTable,
      user,
      dossierDrawer,
      goalData: { goalsBySource },
      adminGoalData: { goalsBySourceAdminView },
      isOnAdminDashboard,
    } = this.props
    const { selectedGoalRange, goalToShow, selectedGoalSorting } = this.state

    const goals = isOnAdminDashboard
      ? goalsBySourceAdminView.get(
          GoalSource({ goalType: "my_goal", sourceId: this.getUserId() })
        )
      : goalsBySource.get(
          GoalSource({ goalType: "my_goal", sourceId: this.getUserId() })
        )

    const userName = User.getFirstName(user)
    const goalDateRangePicker = (
      <GoalDateRangePicker
        key="goalDateRangePicker"
        targetRange={selectedGoalRange}
        onTargetRangeChange={this.handleGoalsRangeChange}
      />
    )

    const goalsOptions = (
      <div className="Goals--options">{goalDateRangePicker}</div>
    )

    return goals ? (
      useGoalsTable ? (
        [
          goalsOptions,
          <GoalsTableDeprecated
            key="table"
            searchResults={goals.toArray()}
            onFiltersChange={this.searchGoals}
            onSelectGoal={(goalToShow) => this.setState({ goalToShow })}
            tableOnly={true}
            emptyState={
              <div
                className={`ProfileContent--empty-state${
                  dossierDrawer ? "-dossier" : ""
                }`}
              >
                <PersonalGoalsEmptyState
                  viewingOwnGoals={this.isOwnProfile()}
                  userName={userName}
                  email={user.email}
                />
              </div>
            }
          />,
          <ViewGoalModal
            key="goalModal"
            goal={goalToShow}
            onClose={() => this.setState({ goalToShow: null })}
          />,
        ]
      ) : (
        [
          goalsOptions,
          <GoalsOverview
            isOnAdminDashboard={isOnAdminDashboard}
            key="goalsOverview"
            goalsBySource={goalsBySource}
            goalsBySourceAdminView={goalsBySourceAdminView}
            goalType="my_goal"
            sourceId={this.getUserId()}
            sourceName={User.getFirstName(user)}
            sourceUser={user}
            singleColumnMode={true}
            selectedSortingOption={selectedGoalSorting}
            readOnly={true}
            emptyState={
              <div
                className={`ProfileContent--empty-state${
                  dossierDrawer ? "-dossier" : ""
                }`}
              >
                <PersonalGoalsEmptyState
                  viewingOwnGoals={false}
                  userName={User.getFirstName(user)}
                  email={user.email}
                />
              </div>
            }
          />,
        ]
      )
    ) : (
      <Loader />
    )
  }

  render() {
    const {
      user,
      hideActionButtons,
      performanceReviewData,
      initialTabId,
      className,
      iconTabStyle,
      tabsAlign,
      dossierDrawer,
      isOnAdminDashboard,
      includeTabs,
    } = this.props
    const { selectedFeedbackFilter } = this.state
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      user: currentUser,
      user: { company },
    } = this.context
    const userFirstName = User.getFirstName(user)

    const reviews = _.get(this.getFeedbackReviews(), "reviews")
    const tabs = _.compact([
      (company.allow_peer_feedback_invites || company.allow_reviews) && {
        id: "feedback",
        icon: "communications",
        content: (
          <PastFeedback
            key={`Feedback-${selectedFeedbackFilter}`}
            hasMore={this.hasMoreReviewPages()}
            reviews={reviews}
            refreshReviews={() => this.loadReviewsGivenByRevieweeId()}
            reviewee={user}
            isOnAdminDashboard={isOnAdminDashboard}
            optionFilters={this.renderOptionFilters(company)}
            onFilterChange={this.handleFeedbackFilterChange}
            onNextPageNeeded={this.loadNextFeedbackPage}
            selectedFeedbackFilter={selectedFeedbackFilter}
            allowDelete={
              currentUser.flags.allow_admin_feedback_delete &&
              isOnAdminDashboard
            }
            emptyState={
              <div
                className={`ProfileContent--empty-state${
                  dossierDrawer ? "-dossier" : ""
                }`}
              >
                <EmptyState
                  header={
                    <FormattedMessage
                      {...strings.pastFeedback.emptyStateHeader}
                    />
                  }
                  body={
                    <FormattedMessage
                      {...strings.pastFeedback.emptyStateBody}
                      // TODO: Amalgamate User types
                      // emulated User helper getFirstName
                      // that takes a CurrentUser arg type
                      // but unsure how useful ths really is
                      values={{
                        user: user?.name
                          ? user.name.trim().split(" ")[0]
                          : User.getFirstName(user),
                      }}
                    />
                  }
                  imgSrc={noFeedbackGivenImg}
                />
              </div>
            }
            hideManagerHeader={this.isOwnProfile()}
          />
        ),
        name: strings.profile.feedback,
      },
      (this.isOwnProfile() || user.is_managed) &&
        company.allow_self_reflections && {
          id: "selfReflection",
          icon: "library_books",
          content: (
            <ProfileSelfReflectionSurveyResponseList
              htmlId={`ProfileContent-${user.id}-self-reflections`}
              user={user}
              surveyResponses={this.getSelfReflections()}
              emptyState={
                <div
                  className={`ProfileContent--empty-state${
                    dossierDrawer ? "-dossier" : ""
                  }`}
                >
                  <ProfileSelfReflectionEmptyState user={user} />
                </div>
              }
            />
          ),
          name: strings.general.selfReflections,
        },
      this.getUserId() &&
        Company.isGoalsEnabled(this.context.user.company) && {
          id: "goals",
          icon: "success",
          content: this.renderGoalsTab(),
          name: strings.general.goals,
        },
      user.is_managed &&
        !this.isOwnProfile() &&
        company.allow_performance_cycles && {
          id: "evaluations",
          icon: "thumb_up",
          content: (
            <PastPerformanceReviews
              reviews={
                performanceReviewData.pastReviewsByUserId[this.getUserId()]
              }
              revieweeName={userFirstName}
              emptyState={
                <div
                  className={`ProfileContent--empty-state${
                    dossierDrawer ? "-dossier" : ""
                  }`}
                >
                  <EmptyState
                    header={
                      <FormattedMessage
                        {...strings.profile.evaluationsEmptyHeader}
                      />
                    }
                    body={
                      <FormattedMessage
                        {...strings.profile.evaluationsEmptyBody}
                        values={{ user: userFirstName }}
                      />
                    }
                    imgSrc={emptyTeamEvaluationsImg}
                  />
                </div>
              }
            />
          ),
          name: strings.general.evaluations,
        },
      !this.isOwnProfile() && {
        id: "notes",
        icon: "create",
        content: <UserNotes user={user} />,
        name: strings.general.notes,
      },
      _.has(user, "hire_date") && {
        id: "",
        icon: "info",
        content: <UserInfo user={user} />,
        name: strings.userInfo.info,
      },
    ]).filter((tab) => includeTabs.includes(tab.id))

    return (
      <div className={`ProfileContent ${className}`}>
        {!hideActionButtons &&
          company.allow_reviews &&
          this.renderActionButtons()}
        {!dossierDrawer && <ProfileHeader userId={user.id} />}
        <TabsDeprecated
          key={user.id}
          align={tabsAlign}
          className="ProfileContent--tabs"
          initialTabId={initialTabId}
          onFirstVisitToTab={this.handleFirstVisitToTab.bind(this)}
          tabs={tabs}
          iconTabStyle={iconTabStyle || isSmallScreenBrowser()}
        />
      </div>
    )
  }
}

export default _.compose(
  connect(GoalStore, "goalData"),
  connect(AdminGoalStore, "adminGoalData"),
  connect(GivenFeedbackStore, "givenFeedbackData"),
  connect(PerformanceReviewHackStore, "performanceReviewData"),
  connect(FeedbackStore, "myReceivedFeedbackData", {
    clearData: Actions.Feedback.localClear,
  }),
  connect(SurveyResponseStore, "surveyResponseData")
)(ProfileContent)

export { ProfileContent as RawProfileContent }
