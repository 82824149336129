import React from "react"
import PropTypes from "prop-types"
import RCSlider from "rc-slider/lib/Slider"
import Handle from "rc-slider/lib/Handle"
import { FormattedNumber } from "react-intl"
import "./Slider.less"

/**
 * Renders a slider control.
 *
 * Props:
 *
 * - `className`
 * - `value`: controlled value for the component. If given, must be updated from `onChange`.
 * - `min`, `max`, `step`: set bounds and increments for the slider
 * - `onChange`: fires on each incremental change to the slider (e.g., mouse move)
 * - `onAfterChange`: fires when the user "commits" the change (e.g., mouse up)
 * - `renderLabel(value)`: optional. Customizes how the label inside the "handle" is rendered
 */
export default class Slider extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    renderLabel: PropTypes.func,
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
  }

  static defaultProps = {
    className: "",
    min: 0,
    max: 100,
    step: 1,
    renderLabel: (value) => <FormattedNumber value={value} />,
  }

  renderHandle = (handleProps) => {
    const { renderLabel } = this.props
    const { value } = handleProps

    return <Handle {...handleProps}>{renderLabel(value)}</Handle>
  }

  render() {
    const {
      className,
      value,
      min,
      max,
      step,
      onChange,
      onAfterChange,
    } = this.props

    return (
      <div className={`Slider ${className}`}>
        <RCSlider
          min={min}
          max={max}
          step={step}
          value={value}
          handle={this.renderHandle}
          onChange={onChange}
          onAfterChange={onAfterChange}
        />
      </div>
    )
  }
}
