import Reflux from "reflux-core"
import _ from "lodash"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import strings from "../locale/strings"
import UserActions from "./UserActions"
import UIActions from "./UIActions"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { GENERAL_URLS, REVIEWS_URLS } = endpoints
const { REVIEWS_URL, CURRENT_OR_CREATE_URL, INVITATIONS_URL } = REVIEWS_URLS
const { QUEUE_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"],
}

const ReviewActions = Reflux.createActions({
  setActiveReview: {},
  load: ASYNC_SETTINGS,
  createForUser: ASYNC_SETTINGS,
  acceptInvite: ASYNC_SETTINGS,
  setReview: { sync: true },
  skipAll: ASYNC_SETTINGS,
  complete: ASYNC_SETTINGS,
  submitPraise: ASYNC_SETTINGS,
  submitQuickComment: ASYNC_SETTINGS,
  loadRevieweeById: ASYNC_SETTINGS,
  getFeedbackTemplates: ASYNC_SETTINGS,
  loadQueue: ASYNC_SETTINGS,
  confirmSkill: {},
  denySkill: {},
  skipSkill: {},
})

/* Review Actions
===============================*/
ReviewActions.createForUser.listenAndPromise(
  ({ email, reviewCreationType = null }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.review.errors.creation },
        extractResponseKey(
          "review",
          agent.post(REVIEWS_URL).send({
            review: {
              reviewee: email,
              reviewer: "me",
              random_attributes: 3,
              review_creation_type: reviewCreationType,
            },
          })
        )
      )
    ).then(ReviewActions.load.completed, (error) =>
      ReviewActions.load.failed(_.get(error, "response.body"), error)
    )
)

ReviewActions.load.listenAndPromise((showLoader = true) =>
  addLoadingState(
    showLoader,
    extractResponseKey("review", agent.post(CURRENT_OR_CREATE_URL))
  ).catch((err) => {
    if (_.get(err, "status") !== 422) {
      UIActions.error(
        _.get(err, "response.body.error") || strings.review.errors.loading
      )
    }
    return Promise.reject(err)
  })
)

ReviewActions.acceptInvite.listenAndPromise((invitationToken) =>
  addLoadingState(
    true,
    addToasts(
      { defaultError: strings.review.errors.notFound },
      extractResponseKey(
        "review_invitation",
        agent.post(`${INVITATIONS_URL}/${invitationToken}/accept`)
      )
    )
  )
)

ReviewActions.complete.preEmit = function (review) {
  review = {
    ...review,
    ratings: review.ratings.map((r) =>
      r.rating || r.rating === 0 ? r : { ...r, rating: 0 }
    ),
    completed_at: new Date().toISOString(),
    complete: true,
  }

  // HACK Make sure React knows about rate rating
  ReviewActions.skipSkill(review)

  updateReview(review)
    .then(this.completed)
    .catch(() => {
      // eslint-disable-next-line prefer-rest-params
      this.failed(arguments)
    })
}

ReviewActions.submitPraise.listenAndPromise(
  ({ reviewee, body, successMessage, values }) => {
    return addLoadingState(
      true,
      agent
        .post(REVIEWS_URL)
        .send({
          review: {
            reviewee: reviewee.email,
            complete: true,
            body,
            review_type: "praise_review",
          },
        })
        .then(() => UIActions.success(successMessage, values))
        .catch((err) => {
          return UIActions.error(
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.error) ||
              strings.praise.publicPraiseForm.errorMessage
          )
        })
    )
  }
)

ReviewActions.submitQuickComment.listenAndPromise(
  ({ reviewee, body, shareWithManager, successMessage, values }) => {
    return addLoadingState(
      true,
      agent
        .post(`${REVIEWS_URL}/create_quick_comment`)
        .send({
          review: {
            reviewee: reviewee.email,
            body: body,
            share_with_manager: shareWithManager || false,
          },
        })
        .then(() => UIActions.success(successMessage, values))
        .catch((err) => {
          return UIActions.error(
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.error) ||
              strings.review.errorMessage
          )
        })
    )
  }
)

ReviewActions.skipAll.preEmit = function (review, skip_reason) {
  updateReview(_.assign({}, review, { skip_reviewee: true, skip_reason }))
    .then(this.completed)
    .catch(this.failed)
}

ReviewActions.loadRevieweeById.listenAndPromise((id) =>
  addLoadingState(true, UserActions.loadById(id))
)

ReviewActions.getFeedbackTemplates.listenAndPromise(({ reviewId }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey(
        "review_templates",
        agent.get(`${REVIEWS_URL}/${reviewId}/review_templates`)
      )
    )
  )
)

ReviewActions.loadQueue.listenAndPromise(() =>
  addLoadingState(
    true,
    extractResponseKey("queue_entries", agent.get(QUEUE_URL))
  )
)

// HELPERS
function updateReview(review) {
  return new Promise((resolve, reject) => {
    agent
      .put(`${REVIEWS_URL}/${review.id}`)
      .send({ review })
      .end((err, res) => {
        if (err || !res.body.review) {
          notifyFailure(err, res)
          // ReviewActions[actionName].failed(res && res.body, err);
          reject(res && res.body, err)
        } else {
          // ReviewActions[actionName].completed(res.body.review);
          resolve(res.body.review)
        }
      })
  })
}

function notifyFailure(err, res) {
  switch (res && res.status) {
    case 422:
      UIActions.error(res.body.error)
      break
    default:
      UIActions.error(strings.review.errors.cantSaveReview)
  }
}

export default ReviewActions
