import React, { FormEvent, memo, useCallback } from "react"
import { Label } from "@kaizen/draft-form"
import { Button } from "@kaizen/draft-button"
import sendIcon from "@kaizen/component-library/icons/send.icon.svg"
import { Heading, Box } from "@kaizen/component-library"
import styles from "./RequestFormSection.scss"
import strings from "../../locale/strings"
import ReviewerSelectionTypeahead, {
  EmployeeSuggestion,
} from "../../containers/ReviewerSelectionTypeahead/ReviewerSelectionTypeahead"
import { useIntl } from "../../hooks/locale/useIntl"
import { BasicEmployeeInfo, EmployeeV2 } from "../../types/Employee"
import { useUuid } from "../../hooks/id/uuid"
import { RequestedReview } from "../../types/EvaluationCycle"
import { differenceWith } from "../../utils/array"
import GuidanceBlockSmall from "../../components/GuidanceBlockSmall"

type Employee = EmployeeV2 | EmployeeSuggestion

type Props = {
  onSubmit: (e: FormEvent) => void
  subject: BasicEmployeeInfo
  onChangeReviewers: (reviewers: Employee[]) => void
  selectedReviewers: Employee[]
  currentUserDirects: EmployeeV2[] | undefined | null
  peerNominations: EmployeeV2[] | undefined | null
  subjectDirects: EmployeeV2[] | undefined | null
  isSendingRequests: boolean
  requestedReviews: RequestedReview[] | null
}

const filterOutRejectedReviews = (rs: RequestedReview[]) =>
  rs.filter((r) => r.state !== "rejected")

// Warning! The ReviewerSelectionTypeahead component also seems to do some
// filtering of the employees. I didn't want to touch that, given that it's
// reusable component. We'll soon be replacing this typeahead component
// anyway, so no point making things perfect.
export const _filterOutAlreadyRequestedEmployees = (
  employees: EmployeeV2[] | null | undefined,
  requestedReviews: RequestedReview[] | null
) =>
  employees &&
  differenceWith(
    employees,
    filterOutRejectedReviews(requestedReviews || []),
    (e: EmployeeV2, rr: RequestedReview) => e.id === rr.author.id
  )

const RequestFormSection = ({
  onSubmit,
  subject,
  onChangeReviewers,
  selectedReviewers,
  peerNominations,
  currentUserDirects,
  subjectDirects,
  isSendingRequests,
  requestedReviews,
}: Props) => {
  const { formatMessage } = useIntl()
  const typeaheadId = useUuid()

  const filterResults = useCallback(
    (results) => {
      return _filterOutAlreadyRequestedEmployees(
        results,
        requestedReviews
      ) as EmployeeV2[]
    },
    [requestedReviews]
  )

  return (
    <section className={styles.panel}>
      <div className={styles.guidanceBlockWrapper}>
        <GuidanceBlockSmall
          title={formatMessage(strings.ecPaufRequestFeedback.whenRequesting)}
          content={formatMessage(strings.ecPaufRequestFeedback.intro)}
        />
      </div>
      <form onSubmit={onSubmit} className={styles.requestForm}>
        <div>
          <Box mb={1}>
            <Heading variant="heading-2">
              {formatMessage(strings.ecPaufRequestFeedback.requestFrom)}
            </Heading>
          </Box>
          <Label htmlFor={typeaheadId}>
            {formatMessage(strings.ecPaufRequestFeedback.addPeers)}
          </Label>
          <ReviewerSelectionTypeahead
            id={typeaheadId}
            reviewee={subject}
            revieweeId={subject.id}
            onChangeReviewers={onChangeReviewers}
            reviewers={selectedReviewers}
            filterResults={filterResults}
            peerNominations={_filterOutAlreadyRequestedEmployees(
              peerNominations,
              requestedReviews
            )}
            currentUserDirects={_filterOutAlreadyRequestedEmployees(
              currentUserDirects,
              requestedReviews
            )}
            revieweeDirects={_filterOutAlreadyRequestedEmployees(
              subjectDirects,
              requestedReviews
            )}
          />
          <div className={styles.buttonWrapper}>
            <Button
              label={formatMessage(strings.ecPaufRequestFeedback.sendRequests)}
              primary={true}
              type="submit"
              disabled={!selectedReviewers.length || isSendingRequests}
              icon={sendIcon}
            />
          </div>
        </div>
      </form>
    </section>
  )
}

export default memo(RequestFormSection)
