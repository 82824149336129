import _ from "lodash"
import User from "./User"

export default class Goal {
  static isEditableByUser(goal, user) {
    const { goal_type: goalType, department } = goal

    return (
      (User.isAdmin(user) &&
        (goalType === "department_goal" || goalType === "company_goal")) ||
      (goalType === "department_goal" &&
        User.managesDepartment(user, department)) ||
      (goalType === "my_goal" && Goal.isOwnedByUser(goal, user))
    )
  }

  static completionEditableByUser(goal, user) {
    return (
      Goal.isEditableByUser(goal, user) ||
      (user &&
        goal.goal_type &&
        goal.user &&
        goal.goal_type === "my_goal" &&
        User.isManagerOf(user, goal.user))
    )
  }

  static isOwnedByUser(goal, user) {
    return goal.user_id === user.id
  }

  static getTitleString(goal, user) {
    switch (goal.goal_type) {
      case "my_goal":
        return Goal.isOwnedByUser(goal, user)
          ? "myGoalTitle"
          : "otherUsersGoalTitle"
      case "department_goal":
        return "viewDeptGoalTitle"
      case "company_goal":
        return "viewCompanyGoalTitle"
      default:
        return ""
    }
  }

  static getTitleValue(goal) {
    switch (goal.goal_type) {
      case "my_goal":
        return goal.user?.best_name
      case "department_goal":
        return goal.department.title
      default:
        return ""
    }
  }

  static isVisibleByUser(goal, userId) {
    return (
      _.some(goal.access_permissions, (p) => _.get(p, "user.id") === userId) ||
      goal.user_id === userId
    )
  }
}
