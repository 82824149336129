// Can't import variables from styleguide's responsive.scss into JS
// This is equal to ca-media-mobile
// in Engagment mobile max-width is 767px
export const MOBILE_QUERY = "(max-width: 500px)"

// This is equal to ca-media-desktop
// currently no desktop size in _variables.less file
export const DESKTOP_QUERY = "(min-width: 1024px)"

// we should be aware the situation  width>500px width<1024px
