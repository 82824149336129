import { DraftSourceType } from "../../context/Drafts"

const saveDraftEncoder = (
  sourceId: number,
  sourceType: DraftSourceType,
  body: string,
  recipientId?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => ({
  parent_obj_id: sourceId,
  parent_obj_type: sourceType,
  recipient_id: recipientId,
  body,
})

export default saveDraftEncoder
