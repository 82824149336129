import Reflux from "../../refluxActions/ActionsInitializer"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import {
  addToasts,
  extractResponseKey,
} from "../../refluxActions/lib/apiActionHelpers"
import endpoints from "../../constants/endpointsDeprecated"

const {
  ADMIN_REVIEWS_URLS: { ADMIN_REVIEWS_URL },
} = endpoints

const agent = SteadyfootAgent.defaultInstance

const AdminReviewActions = Reflux.createActions({
  deleteReview: { asyncResult: true },
})

AdminReviewActions.deleteReview.listenAndPromise(
  ({ surveyId, successMessage, errorMessage }) =>
    addToasts(
      { defaultError: errorMessage, success: successMessage },
      extractResponseKey(
        "review",
        agent.del(`${ADMIN_REVIEWS_URL}/${surveyId}`)
      )
    )
)

export default AdminReviewActions
