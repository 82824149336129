import _ from "lodash"

/**
 * Reduces an array of group types to a single group type for the API.
 *
 * @param {Array<string>=} groupTypes
 * @returns {string}
 */
export function collapseGroupTypes(groupTypes) {
  const uniqueTypes = _.uniq(groupTypes)

  if (uniqueTypes.length > 1) {
    // Currently, the group types are directs, indirects and team, where team = directs + indirects.
    // So if there are at least two distinct group types, it's the same as "team"
    return "team"
  } else {
    return uniqueTypes[0]
  }
}
