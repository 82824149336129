import { convertToMessageTranslations } from "./translations"

export default {
  en: () => Promise.resolve({}),

  da: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./da.json")),
          reactIntl: require("react-intl/locale-data/da"),
          flatpickr: require("flatpickr/dist/l10n/da").da
        })
      })
    }),
  de: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./de.json")),
          reactIntl: require("react-intl/locale-data/de"),
          flatpickr: require("flatpickr/dist/l10n/de").de
        })
      })
    }),
  "en-GB": () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./en-GB.json")),
          reactIntl: require("react-intl/locale-data/en")
        })
      })
    }),
  es: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./es.json")),
          reactIntl: require("react-intl/locale-data/es"),
          flatpickr: require("flatpickr/dist/l10n/es").es
        })
      })
    }),
  fr: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./fr.json")),
          reactIntl: require("react-intl/locale-data/fr"),
          flatpickr: require("flatpickr/dist/l10n/fr").fr
        })
      })
    }),
  he: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./he.json")),
          reactIntl: require("react-intl/locale-data/he"),
          flatpickr: require("flatpickr/dist/l10n/he").he
        })
      })
    }),
  it: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./it.json")),
          reactIntl: require("react-intl/locale-data/it"),
          flatpickr: require("flatpickr/dist/l10n/it").it
        })
      })
    }),
  ja: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./ja.json")),
          reactIntl: require("react-intl/locale-data/ja"),
          flatpickr: require("flatpickr/dist/l10n/ja").ja
        })
      })
    }),
  nl: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./nl.json")),
          reactIntl: require("react-intl/locale-data/nl"),
          flatpickr: require("flatpickr/dist/l10n/nl").nl
        })
      })
    }),
  pt: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./pt.json")),
          reactIntl: require("react-intl/locale-data/pt"),
          flatpickr: require("flatpickr/dist/l10n/pt").pt
        })
      })
    }),
  ru: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./ru.json")),
          reactIntl: require("react-intl/locale-data/ru"),
          flatpickr: require("flatpickr/dist/l10n/ru").ru
        })
      })
    }),
  sv: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./sv.json")),
          reactIntl: require("react-intl/locale-data/sv"),
          flatpickr: require("flatpickr/dist/l10n/sv").sv
        })
      })
    }),
  te: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./te.json")),
          reactIntl: require("react-intl/locale-data/te")
        })
      })
    }),
  zh: () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./zh.json")),
          reactIntl: require("react-intl/locale-data/zh"),
          flatpickr: require("flatpickr/dist/l10n/zh").zh
        })
      })
    }),
  "zh-TW": () =>
    new Promise((resolve, reject) => {
      require.ensure([], require => {
        resolve({
          messages: convertToMessageTranslations(require("./zh-TW.json")),
          reactIntl: require("react-intl/locale-data/zh"),
          flatpickr: require("flatpickr/dist/l10n/zh").zh
        })
      })
    })
}
