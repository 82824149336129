import "./ListEditorUncontrolled.less"
import React from "react"
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl"
import { List } from "immutable"
import cx from "classnames"
import FlipMove from "react-flip-move"
import RepositionableItemNumber from "../../components/RepositionableItemNumber/RepositionableItemNumber"

// The `Item` type that we pass down to the ListEditor doesn't need to have
// an `id` or `deleted_at` property, however there is some special functionality
// associated with these properties.
export type ItemInterface = {
  // If no id is supplied, a `clientId` will be tacked onto the passed in item
  id?: string
  // If supplied, the items marked with a deleted_at property will be filtered
  // out. I'm not sure if the type should be a string or date, but I don't
  // think it seems to matter.
  deleted_at?: string | Date
}

type Props<Item> = {
  className?: string
  initialItems: List<Item>
  minItemsErrorMessage?: FormattedMessage.MessageDescriptor
  addMoreItemsText?: FormattedMessage.MessageDescriptor
  showItemNumbers?: boolean
  renderItems: (opts: {
    items: List<Item>
    onChangeItem: (changedItemIndex: number, updates: Partial<Item>) => void
    onRemoveItem: (itemIndex: number) => void
  }) => List<React.ReactNode>
  customItemAdder?: (opts: {
    items: List<Item>
    onAddItem: (item: Item) => void
  }) => React.ReactNode
  onChange: (items: List<Item>) => void
  itemAutomationId: string
  intl: InjectedIntl
}

type State<Item> = {
  items: List<
    Item & {
      clientId?: number
    }
  >
  deletedItems: List<
    Item & {
      clientId?: number
    }
  >
  nextClientId: number
  focusNewItem: boolean
}

/**
 * Also see `../ListEditor`, which is a controlled version of the same component.
 * We may want to use that, rather than this component
 * for the following additional reasons:
 * - To move away from immutable js (we still use immutable data structures, just
 *   without the library)
 * - To move the state management out, making things easier to integrate with
 *   the react-final-form library.
 * - This component will add deleted_at to deleted fields, but we no longer need
 *   to keep track of the deleted items in some of the new api architecture.
 *
 * Note, the one downside from the change is that newly created items must have
 * an id passed down. Before, we simply added `clientIds` if an id was not
 * supplied.
 *
 * Other changes:
 * - The "Add" button should be a `button`, not an `a`nchor.
 * - I'd like to move away from using `React.cloneElement`. It causes surprises,
 *   even though the example in this file seems harmless.
 * - Change from .less styling to .scss
 */
class ListEditorUncontrolled<
  Item extends ItemInterface
> extends React.PureComponent<Props<Item>, State<Item>> {
  static defaultProps = {
    showItemNumbers: false,
  }

  constructor(props: Props<Item>) {
    super(props)

    const { initialItems } = props
    const isDeleted = (item: Item | undefined): boolean =>
      Boolean(item?.deleted_at)
    const deletedItems = initialItems.filter(isDeleted)

    const items = initialItems.filterNot(isDeleted).map((item, i) =>
      // Mark items without an `id` as client-only objects. The `clientId` is used in this
      // component for synthesizing React keys and recognizing them when removing objects.
      // Start numbering the IDs at 1 so that they're always truthy.
      !item?.id ? { ...item, clientId: (i as number) + 1 } : item
    )

    this.state = {
      // @ts-ignore: time boxed effort to get file converted to ts, please fix if you have time
      items: items,
      // @ts-ignore: time boxed effort to get file converted to ts, please fix if you have time
      deletedItems: deletedItems,
      nextClientId: items.size + 1,
      focusNewItem: false,
    }
  }

  componentDidUpdate(prevProps: Props<Item>, prevState: State<Item>) {
    const {
      props: { onChange },
      state: { items, deletedItems },
    } = this

    if (prevState.focusNewItem) {
      this.setState({ focusNewItem: false })
    }
    if (prevState.items !== items) {
      // @ts-ignore: time boxed effort to get file converted to ts, please fix if you have time
      onChange(items.concat(deletedItems))
    }
  }

  handleChangeItem = (changedItemIndex: number, updates: Partial<Item>) => {
    this.setState(({ items }) => ({
      items: items.update(changedItemIndex, (item) => ({
        ...item,
        ...updates,
      })),
    }))
  }

  handleAddMoreItems = (itemToAdd = {}) => {
    this.setState(({ items, nextClientId }) => {
      // Create new items with a client ID that can be used to identify them
      const newItem = { ...itemToAdd, clientId: nextClientId }

      return {
        // @ts-ignore: time boxed effort to get file converted to ts, please fix if you have time
        items: items.push(newItem),
        nextClientId: nextClientId + 1,
        focusNewItem: true,
      }
    })
  }

  handleMove = (itemIndex: number, delta: number) => {
    this.setState(({ items }) => {
      const item = items.get(itemIndex)
      return {
        items: items.delete(itemIndex).insert(itemIndex + delta, item),
      }
    })
  }

  handleRemoveItem = (itemIndex: number) => {
    this.setState(({ items, deletedItems }) => {
      const item = items.get(itemIndex)

      return {
        items: items.delete(itemIndex),
        deletedItems:
          // Save the deleted item so that higher-up components know to tell the server that it
          // was deleted. If it's a client-only item, though, just discard it.
          !item.clientId
            ? /* eslint-disable prettier/prettier */
              // @ts-ignore: time boxed effort to get file converted to ts, please fix if you have time
              deletedItems.push({ ...item, deleted_at: new Date() })
            : deletedItems
        /* eslint-enable prettier/prettier */
      }
    })
  }

  render() {
    const {
      className,
      renderItems,
      addMoreItemsText,
      customItemAdder,
      showItemNumbers,
      itemAutomationId,
      intl: { formatMessage },
    } = this.props
    const { items, focusNewItem } = this.state
    return (
      <div className={`ListEditor ${className || ""}`}>
        <FlipMove
          easing="ease-in-out"
          enterAnimation="fade"
          leaveAnimation="fade"
          duration={200}
        >
          {renderItems({
            items: items,
            onChangeItem: this.handleChangeItem,
            onRemoveItem: this.handleRemoveItem,
          }).map(
            // @ts-ignore: time boxed effort to get file converted to ts, please fix if you have time
            (
              renderedItem: React.ReactNode,
              index: number,
              renderedItems: List<React.ReactNode>
            ) => {
              // NB: we assume renderedItems maps items to rendered elements 1:1
              const item = items.get(index)
              const key = item.id
                ? `id-${item.id}`
                : `client-id-${item.clientId}`
              const autoFocus = index + 1 === renderedItems.size && focusNewItem

              if (showItemNumbers) {
                return (
                  <div
                    key={key}
                    className="ListEditor--item layout horizontal"
                    data-automation-id={itemAutomationId}
                  >
                    <RepositionableItemNumber
                      className="flex none"
                      itemIndex={index}
                      itemCount={items.size}
                      onMove={(delta: number) => this.handleMove(index, delta)}
                    />
                    {/*
                    // @ts-ignore: Ignored due to time boxing. Please fix if you have the time. */}
                    {React.cloneElement(renderedItem, {
                      // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
                      className: cx("flex", renderedItem.props.className),
                      autoFocus,
                    })}
                  </div>
                )
              } else {
                return (
                  <div
                    key={key}
                    className="ListEditor--item layout horizontal"
                    data-automation-id={itemAutomationId}
                  >
                    {/*
                    // @ts-ignore: Ignored due to time boxing. Please fix if you have the time. */}
                    {React.cloneElement(renderedItem, {
                      // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
                      className: cx("flex", renderedItem.props.className),
                      autoFocus,
                    })}
                  </div>
                )
              }
            }
          )}
        </FlipMove>
        <div className="col s12 center-align">
          {customItemAdder ? (
            customItemAdder({ items, onAddItem: this.handleAddMoreItems })
          ) : (
            <a
              href="#"
              className="ListEditor--add-more-items-link"
              onClick={(e) => {
                e.preventDefault()
                this.handleAddMoreItems()
              }}
            >
              {addMoreItemsText ? formatMessage(addMoreItemsText) : ""}
            </a>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(ListEditorUncontrolled)
