import Reflux from "./ActionsInitializer"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import { addLoadingState, extractResponseKey } from "./lib/apiActionHelpers"
import SearchAgent from "./lib/SearchAgent"
import Survey from "../models/Survey"
import endpoints from "../constants/endpointsDeprecated"

const {
  SURVEYS_URLS: { SURVEYS_URL },
} = endpoints
const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: SURVEYS_URL,
  resourceName: "surveys",
  pageSize: 25,
  modelWrapper: Survey.of,
})

const SurveyActions = Reflux.createActions({
  searchSurveys: { asyncResult: true },
  pageSurveys: { asyncResult: true },
  loadSurvey: { asyncResult: true },
  clearSurveys: {},
})

SurveyActions.searchSurveys.listenAndPromise(({ kind }) =>
  addLoadingState(
    [true, { light: true }],
    searchAgent.search({ searchParams: { kind } })
  )
)

SurveyActions.pageSurveys.listenAndPromise(({ page, kind }) =>
  searchAgent.search({ page, searchParams: { kind } })
)

SurveyActions.loadSurvey.listenAndPromise(({ id }) =>
  addLoadingState(
    [true, { light: true }],
    extractResponseKey("survey", Survey.of, agent.get(`${SURVEYS_URL}/${id}`))
  )
)

export default SurveyActions
