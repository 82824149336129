import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Radio } from "@kaizen/draft-radio"
import RadioGroupRow from "../RadioGroupRow/RadioGroupRow"
import styles from "./PrioritySelector.scss"
import strings from "../../locale/strings"

type RadioGroupProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedValue: number
  intl: InjectedIntl
}

const priorityValues = {
  low: { value: 0, label: strings.prioritySelector.low },
  medium: { value: 1, label: strings.prioritySelector.medium },
  high: { value: 2, label: strings.prioritySelector.high },
}

const PrioritySelector = ({
  onChange,
  selectedValue,
  intl,
}: RadioGroupProps) => {
  const { formatMessage } = intl
  return (
    <RadioGroupRow labelText={formatMessage(strings.prioritySelector.title)}>
      <div className={styles.radio}>
        {Object.entries(priorityValues).map(([key, priority]) => (
          <span
            key={`${key}${priority.value}`}
            className={styles.radioContainer}
          >
            <Radio
              labelText={formatMessage(priority.label)}
              name={"priority-selector"}
              id={`priority-selector-${key}`}
              selectedStatus={selectedValue === priority.value}
              onChange={onChange}
              value={`${priority.value}`}
              inline={true}
            />
          </span>
        ))}
      </div>
    </RadioGroupRow>
  )
}

export default injectIntl(PrioritySelector)
