import PropTypes from "prop-types"
import React from "react"
import "./Loader.less"
import Icon from "../Icon/Icon"
import Aid from "../../constants/automationId"

// IMPORTANT NOTE
// This is a static component that doesn't allow updates yet.
// see shouldComponentUpdate
class Loader extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const { style, className } = this.props
    return (
      <div
        className={`Loader ${className}`}
        style={style}
        data-automation-id={Aid.loader}
      >
        <Icon iconName="spinner" inheritSize={true} />
      </div>
    )
  }
}

export default Loader
