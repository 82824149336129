import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import _ from "lodash"
import cx from "classnames"
import Button from "@kaizen/component-library/components/Button/Button"
import chevronDownIcon from "@kaizen/component-library/icons/chevron-down.icon.svg"
import strings from "../../locale/strings"
import Loader from "../../components/Loader/Loader"
import ProfilePic from "../ProfilePic/ProfilePic"
import { isNonBlank } from "../../utils/strings"
import TextEditor from "../../components/TextEditor"
import Actions from "../../refluxActions"
import DraftStore, { DraftSource } from "../../refluxStores/DraftStore"
import MarkdownText from "../../components/MarkdownText/MarkdownText"
import MessageGroup from "./MessageGroup"
import SavingIndicator from "../../components/SavingIndicator/SavingIndicator"
import connect from "../../higherOrderComponents/connect"
import { debounce } from "../../utils/timers"
import "./Conversation.less"

class Conversation extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    sourceType: PropTypes.string.isRequired,
    sourceId: PropTypes.number.isRequired,
    recipientId: PropTypes.number,
    sender: PropTypes.object,

    conversationHeader: PropTypes.node,

    messages: PropTypes.array.isRequired,

    loading: PropTypes.bool,
    lastSavedReplyId: PropTypes.number,

    wrapReplyForm: PropTypes.func,

    onRepliesNeeded: PropTypes.func,
    onReply: PropTypes.func,

    readOnly: PropTypes.bool,
    preview: PropTypes.bool,

    commentActions: PropTypes.shape({
      seen: PropTypes.func,
    }),

    inputPrompt: PropTypes.node,
    inputPlaceholder: PropTypes.string,

    draftActions: PropTypes.shape({
      get: PropTypes.func,
      save: PropTypes.func,
      notifyEditing: PropTypes.func,
    }),

    loadUsersForMention: PropTypes.func,

    isReplyFormIndented: PropTypes.bool,
  }

  static defaultProps = {
    preview: false,
    recipientId: null,

    wrapReplyForm: _.identity,

    onReply: _.noop,
    onRepliesNeeded: _.noop,

    commentActions: {
      seen: Actions.Comments.seen,
    },

    draftActions: {
      get: Actions.Draft.get,
      save: Actions.Draft.save,
      notifyEditing: Actions.Draft.notifyEditing,
    },
    isReplyFormIndented: false,
  }

  static contextTypes = {
    user: PropTypes.shape({
      id: PropTypes.Number,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      inputMarkdown: "",
      inputVersion: 0,
    }
  }

  componentDidMount() {
    const {
      sourceType,
      sourceId,
      recipientId,
      draftActions,
      readOnly,
      preview,
    } = this.props

    this.initInputFromDraft()

    // mark all shown comments as seen
    this.markCommentsAsSeen()

    if (!readOnly && !preview) {
      draftActions.get({
        parentObjType: sourceType,
        parentObjId: sourceId,
        recipientId,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastSavedReplyId !== this.props.lastSavedReplyId) {
      this.setState((state) => ({
        inputMarkdown: null,
        inputVersion: state.inputVersion + 1,
      }))
    }

    if (!this.getDraft(this) && this.getDraft({ props: nextProps })) {
      this.initInputFromDraft({ props: nextProps })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.messages.length !== prevProps.messages.length) {
      // mark all shown comments as seen
      this.markCommentsAsSeen()
    }

    if (prevProps.lastSavedReplyId !== this.props.lastSavedReplyId) {
      this.fetchReplies()
    }

    if (prevState.inputMarkdown !== this.state.inputMarkdown) {
      this.handleDraftEditing()
    }
  }

  getDraft({ props } = this) {
    const {
      sourceType,
      sourceId,
      recipientId,
      draftData: { draftsBySource },
    } = props

    return draftsBySource.get(
      DraftSource({
        parentObjType: sourceType,
        parentObjId: sourceId,
        recipientId,
      })
    )
  }

  getMessages() {
    return _.sortByOrder(
      this.props.messages,
      (message) => message.props.timestamp,
      ["asc"]
    )
  }

  initInputFromDraft({ props } = this) {
    this.setState((state) => ({
      inputMarkdown: _.get(this.getDraft({ props }), "body") || "",
      inputVersion: state.inputVersion + 1,
    }))
  }

  markCommentsAsSeen() {
    const { sourceType, readOnly, preview } = this.props
    const userId = this.context.user.id
    const lastMessageIndex = _.findLastIndex(
      this.getMessages(),
      (message) => _.get(message.props, "sender.id") !== userId
    )

    /*
     * The first message is not a comment (except for goals) therefore the index
     * must be greater than 0 to be considered a comment.
     */
    if (
      !preview &&
      !readOnly &&
      (lastMessageIndex > 0 || (sourceType === "goal" && lastMessageIndex >= 0))
    ) {
      this.props.commentActions.seen(this.getMessages()[lastMessageIndex].props)
    }
  }

  fetchReplies = () => {
    this.props.onRepliesNeeded()
  }

  submit = () => {
    if (isNonBlank(this.state.inputMarkdown)) {
      this.props.onReply(this.state.inputMarkdown)
      this.setState((state) => ({
        inputMarkdown: "",
        inputVersion: state.inputVersion + 1,
      }))
    }
  }

  updateInput = (value) => {
    this.setState({ inputMarkdown: value })
  }

  handleDraftEditing() {
    const {
      draftActions,
      sourceType,
      sourceId,
      recipientId,
      preview,
    } = this.props

    if (!preview) {
      draftActions.notifyEditing({
        parentObjType: sourceType,
        parentObjId: sourceId,
        recipientId,
      })
      this.saveDraft()
    }
  }

  @debounce(2000)
  saveDraft() {
    const {
      props: { draftActions, sourceType, sourceId, recipientId, preview },
      state: { inputMarkdown },
    } = this

    if (!preview) {
      draftActions.save({
        parentObjType: sourceType,
        parentObjId: sourceId,
        recipientId,
        body: inputMarkdown,
      })
    }
  }

  renderMessages = (messages) => {
    const messagesGroupedByUser = messages.reduce(
      (messageGroups, message, messageIndex) => {
        const lastMessage = messages[messageIndex - 1]
        const isNewGroup =
          messageIndex === 0 ||
          lastMessage.props.sender.email !== message.props.sender.email ||
          lastMessage.props.groupKey !== message.props.groupKey

        return isNewGroup
          ? messageGroups.concat([[message]])
          : _.set(
              messageGroups,
              messageGroups.length - 1,
              messageGroups[messageGroups.length - 1].concat(message)
            )
      },
      []
    )

    return messagesGroupedByUser.map((messages, index) => (
      <MessageGroup
        ref={index === 0 ? "firstMessageGroup" : null}
        key={index}
        messages={messages}
        isFirst={index === 0}
      />
    ))
  }

  renderReplyForm() {
    const {
      inputPlaceholder,
      inputPrompt,
      wrapReplyForm,
      preview,
      loadUsersForMention,
      intl: { formatMessage },
      isReplyFormIndented,
    } = this.props
    const { inputMarkdown, inputVersion } = this.state
    const { saveState } = this.getDraft() || {}

    const replyRowClasses = cx(
      {
        "Conversation--reply-action-row-active":
          isNonBlank(inputMarkdown) || saveState === "saving",
        "Conversation--reply-action-row-prompted": !!inputPrompt,
      },
      "Conversation--reply-action-row layout horizontal center end-justified"
    )

    return wrapReplyForm(
      <div
        className={cx("Conversation--reply-form", {
          "Conversation--reply-form-indented": isReplyFormIndented,
        })}
      >
        <div className="Conversation--reply-form-container layout horizontal">
          <ProfilePic className="flex none" user={this.context.user} />

          <div className="Conversation--reply-form-text flex one">
            <TextEditor
              key={inputVersion}
              className="Conversation--reply-text-editor"
              placeholder={
                inputPlaceholder ||
                formatMessage(strings.general.commentPlaceholder)
              }
              initialValue={inputMarkdown}
              disabled={preview}
              onChange={this.updateInput}
              hasEmojiPicker={!preview}
              loadUsersForMention={loadUsersForMention}
              inputPrompt={inputPrompt}
            />
          </div>
        </div>
        {!preview && (
          <div className={replyRowClasses}>
            <SavingIndicator saveState={saveState} />
            <div className="Conversation--send-button">
              <Button
                label={formatMessage(strings.general.send)}
                type="button"
                onClick={this.submit}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      className,
      conversationHeader,
      loading,
      readOnly,
      hasMoreReplies,
      isReplyFormIndented,
      intl: { formatMessage },
    } = this.props

    const messages = this.getMessages()

    return (
      <div className={cx("Conversation layout vertical flex", className)}>
        {conversationHeader && (
          <p className="Conversation--header">
            <MarkdownText text={conversationHeader} inline={true} />
          </p>
        )}
        <div
          className={cx("Conversation--messages", {
            flex: messages.length > 0,
          })}
        >
          {this.renderMessages(messages)}
          {loading && <Loader />}
          {hasMoreReplies && (
            <div
              className={cx("Conversation--show-more", {
                "Conversation--show-more-indented": isReplyFormIndented,
              })}
            >
              <Button
                label={formatMessage(strings.general.showAllComments)}
                onClick={this.fetchReplies}
                type="button"
                icon={chevronDownIcon}
                iconPosition="end"
                secondary
              />
            </div>
          )}
        </div>
        {!readOnly && this.renderReplyForm()}
      </div>
    )
  }
}

export default _.compose(
  connect(DraftStore, "draftData"),
  injectIntl
)(Conversation)

export { Conversation as RawConversation }
