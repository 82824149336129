/**
 * Returns a copy of the source array, with elements of a certain value
 * inserted between each element. So
 *
 * shallowIntersperse([], 0) -> []
 * shallowIntersperse([1], 0) -> [1]
 * shallowIntersperse([1, 2, 3], 0) -> [1, 0, 2, 0, 3]
 * shallowIntersperse([1, 2, 3], 'foo') -> [1, 'foo', 2, 'foo', 3]
 *
 * Analagous to array.join, but returning an array instead of a string.
 * It is thus useful for joining an array of React nodes like array.join.
 *
 * The source values are shallow-copied from the input array, and all
 * of the interspersed entries refer to the same instance.
 *
 * @param arr The source array
 * @param sep The element that will be interspersed.
 * @returns {Array} A new array, constructed as above.
 */

export function intersperse(arr, sep) {
  if (!arr || !arr.length) {
    return []
  }

  const ret = new Array(arr.length * 2 - 1)

  // fill all but the last element, adding separators
  for (let i = 0; i < arr.length - 1; i++) {
    ret[i * 2] = arr[i]
    ret[i * 2 + 1] = sep
  }
  // fill last element
  ret[ret.length - 1] = arr[arr.length - 1]

  return ret
}

/**
 * Splits `arr` into groups on contiguous elements sharing the same value for a given property or key function.
 *
 * For example, suppose we have
 *
 *   x | 'a' | 'b' | 'c' | 'd' | 'e'
 * ----+-----+-----+-----+-----+-----
 * f(x)| 'A' | 'B' | 'B' | 'A' | 'B'
 *
 * Then
 *
 * chunkBy(null, f) === []
 *
 * chunkBy([], f)   === []
 *
 * chunkBy(['a','b','c','d','e'], f) === [
 *    {key: 'A', values: ['a'] },
 *    {key: 'B', values: ['b', 'c'] },
 *    {key: 'A', values: ['d'] },
 *    {key: 'B', values: ['e'] }
 * ]
 *
 * chunkBy(['b','a','d','b'], f) === [
 *    {key: 'B', values: ['b'] },
 *    {key: 'A', values: ['a', 'd'] },
 *    {key: 'B', values: ['b'] }
 * ]
 *
 *
 * Obviously, this implies that multiple groups with the same key can exist in the output.
 *
 * @param {Array} arr The source array
 * @param {Function|String} key Either:
 *   - A function which maps elements to their group key
 *   - A string key describing which member to use as a group key
 * @param {function(?, ?): boolean} keyEquality A function which determines the equality of the keys
 *   (if unspecified, keys are compared as with the === operator)
 *   useful when keys are e.g. object instances, like users
 */
export function chunkBy(arr, key, keyEquality) {
  if (!arr || !arr.length) {
    return []
  }

  const keyIsFunction = typeof key === "function"

  const ret = []

  const head = arr[0]

  // nb invariant: prevValues is always non-empty
  let prevValues = [head]
  let prevKey = keyIsFunction ? key(head) : head[key]

  for (let i = 1; i < arr.length; i++) {
    const elt = arr[i]
    const eltKey = keyIsFunction ? key(elt) : elt[key]

    // Use keyEquality parameter if defined, otherwise fallback to ===
    const keysEqual = keyEquality
      ? keyEquality(eltKey, prevKey)
      : eltKey === prevKey

    if (keysEqual) {
      // Same key, add to group
      prevValues.push(elt)
    } else {
      // Different key, open new group
      ret.push({ key: prevKey, values: prevValues })
      prevKey = eltKey
      prevValues = [elt]
    }
  }

  // Push last group - always nonempty by above
  ret.push({ key: prevKey, values: prevValues })

  return ret
}
