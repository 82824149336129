import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import Tooltip from "rc-tooltip"
import ReactDOM from "react-dom"
import { injectIntl } from "react-intl"
import connect from "../../higherOrderComponents/connect"
import User from "../../models/User"
import Actions from "../../refluxActions"
import ConversationStore from "../../refluxStores/ConversationStore"
import MultiCommentContent from "./MultiCommentContent"
import Loader from "../../components/Loader/Loader"
import strings from "../../locale/strings"
import ConversationMessage from "../conversations/ConversationMessage"
import BreadcrumbAndMenuHeader from "../../components/BreadcrumbAndMenuHeader/BreadcrumbAndMenuHeader"
import SurveyQuestion from "../SurveyQuestion/SurveyQuestion"
import Settings from "../../settings"

const { CYCLE_POLL_TIMER_INTERVAL } = Settings

class CompletedSelfReview extends React.Component {
  static propTypes = {
    loadReview: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    breadcrumb: PropTypes.object,
  }

  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      reviewId: null,
    }

    this.poller = null
  }

  componentDidMount() {
    const { loadReview } = this.props

    this.pollForNewComments()

    loadReview().then(this.onLoadReview)

    if (this.getReview()) {
      this.markAsSeen()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevReview = this.getReview({ props: prevProps, state: prevState })
    const review = this.getReview()

    if (!prevReview && review) {
      this.markAsSeen()
    }
  }

  onLoadReview = (result) => {
    this.setState({ reviewId: result.id || _.get(result, "selfReflection.id") })
  }

  pollForNewComments() {
    this.poller = setInterval(() => {
      this.loadReview()
    }, CYCLE_POLL_TIMER_INTERVAL)
  }

  componentWillUnmount() {
    clearTimeout(this.poller)
    this.poller = null
  }

  getReview({ props, state } = this) {
    return state.reviewId
      ? _.get(
          props.conversationData,
          `sourceObjectsByType[self_reflection][${state.reviewId}]`
        )
      : null
  }

  markAsSeen() {
    const review = this.getReview()

    if (User.isManagerOf(this.context.user, review.user)) {
      Actions.SelfReview.markAsManagerSeen(review.id)
    }
  }

  transformSelfReflectionAnswer(answer, review) {
    const {
      intl: { formatMessage },
    } = this.props
    const { user } = this.context
    const {
      comments,
      question_content: questionContent,
      id,
      loadingComments,
      lastSavedCommentId,
      manager_prompt: managerPrompt,
    } = answer
    const { user: reviewee, completed_at: completedAt } = review

    const commentsAsMessages =
      comments &&
      comments.map((comment, index) => {
        const {
          id,
          body,
          author: { user },
          created_at: createdAt,
        } = comment

        return (
          <ConversationMessage
            // Show tooltip with manager prompt for the first comment if manager prompt is present
            body={
              User.isManagerOf(user, reviewee) &&
              managerPrompt &&
              index === 0 ? (
                <Tooltip
                  placement="top"
                  overlay={
                    <div className="ConversationMessage--tooltip">
                      {managerPrompt}
                    </div>
                  }
                  arrowContent={<div className="rc-tooltip-arrow-inner" />}
                  // eslint-disable-next-line react/no-find-dom-node
                  getTooltipContainer={() =>
                    // eslint-disable-next-line react/no-find-dom-node
                    ReactDOM.findDOMNode(this.component)
                  }
                >
                  <div>{body}</div>
                </Tooltip>
              ) : (
                body
              )
            }
            timestamp={createdAt}
            id={id}
            sender={user}
            key={id}
          />
        )
      })

    const messages = [
      <ConversationMessage
        body={this.renderAnswer(answer, review.id)}
        timestamp={completedAt}
        id={id}
        sender={reviewee}
        key={id}
      />,
    ].concat(commentsAsMessages)

    const prompt = _.some(comments, (comment) => {
      const author = _.get(comment, "author.user")
      return author && User.equals(author, user)
    })
      ? null
      : User.isManagerOf(user, reviewee) && managerPrompt
      ? managerPrompt
      : null

    return {
      ...answer,
      sourceType: "self_reflection_answer",
      sourceId: id,
      conversationHeader: questionContent,
      messages,
      rating_summary: {},
      reviewee: this.context.user,
      loading: loadingComments,
      lastSavedReplyId: lastSavedCommentId,
      inputPlaceholder: formatMessage(strings.general.commentPlaceholder),
      inputPrompt: prompt,
    }
  }

  renderAnswer(answer, selfReflectionId) {
    return (
      <SurveyQuestion
        answer={answer}
        question={{
          title: answer.question_content,
          type: "open-ended",
        }}
        htmlId={`self-reflection-${selfReflectionId}-answer-${answer.id}`}
        hideQuestion={true}
        readOnly={true}
      />
    )
  }

  handleSelfReflectionQuestionReply(sourceObject, body) {
    Actions.Comments.comment({
      sourceObject,
      body,
      type: "self_reflection_answer",
      collectionName: "self_reflection_answers",
      parentType: "self_reflection",
      parentSourceObject: this.getReview(),
    })
  }

  getTitle(review) {
    const {
      intl: { formatMessage },
    } = this.props
    const { user } = this.context
    const { user: reviewee } = review

    return User.equals(user, reviewee)
      ? formatMessage(strings.selfReflection.yourSelfReflection)
      : formatMessage(strings.selfReflection.usersSelfReflection, {
          name: User.getFirstName(reviewee),
        })
  }

  canComment(review) {
    const { user } = this.context
    const { user: reviewee } = review

    return User.equals(user, reviewee) || User.isManagerOf(user, reviewee)
  }

  render() {
    const review = this.getReview()
    const { loadReview, readOnly, breadcrumb } = this.props

    return review ? (
      <div>
        <BreadcrumbAndMenuHeader
          breadcrumb={breadcrumb}
          headerText={this.getTitle(review)}
        />
        <MultiCommentContent
          ref={(component) => (this.component = component)}
          sections={review.self_reflection_answers.map((a) =>
            this.transformSelfReflectionAnswer(a, review)
          )}
          loading={review.loadingComments}
          onRepliesNeeded={loadReview}
          onReply={this.handleSelfReflectionQuestionReply.bind(this)}
          readOnly={readOnly || !this.canComment(review)}
        />
      </div>
    ) : (
      <Loader />
    )
  }
}

export default _.compose(
  connect(ConversationStore, "conversationData"),
  injectIntl
)(CompletedSelfReview)

export { CompletedSelfReview as RawCompletedSelfReview }
