import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "react-intl"
import MarkdownText from "../MarkdownText/MarkdownText"

/**
 * Similar to `FormattedMessage` in `react-intl`, but renders Markdown in the message.
 * Only inline styles are allowed - bold and links. For now, `values` can't contain elements as with
 * `FormattedMessage`.
 *
 * @example
 *
 *    <MarkdownFormattedMessage {...strings.feedbackStats.breakdownMessage} />
 */
const MarkdownFormattedMessage = ({
  className,
  id,
  defaultMessage,
  values,
  intl,
}) => (
  <MarkdownText
    className={`MarkdownFormattedMessage ${className}`}
    inline={true}
    text={intl.formatMessage({ id, defaultMessage }, values)}
  />
)

MarkdownFormattedMessage.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  values: PropTypes.object,
}

MarkdownFormattedMessage.defaultProps = {
  className: "",
}

export default injectIntl(MarkdownFormattedMessage)
export { MarkdownFormattedMessage as RawMarkdownFormattedMessage }
