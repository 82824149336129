import Reflux from "reflux-core"
import Actions from "../refluxActions"
import BaseSurveyStore from "./BaseSurveyStore"

const SurveyStore = Reflux.createStore({
  ...BaseSurveyStore,

  init() {
    BaseSurveyStore.init.call(this, { actions: Actions.Survey })
  },
})

export default SurveyStore
