import React, { memo } from "react"
import { assetUrl } from "@kaizen/hosted-assets"
import { Box, Heading, Paragraph } from "@kaizen/component-library"
import styles from "./styles.scss"

type Props = { title: string; content: string }

const guidanceBlockImg = assetUrl("illustrations/spot/moods-informative.svg")

// Similar to the GuidanceBlock in kaizen, but smaller
const GuidanceBlockSmall = ({ title, content }: Props) => {
  return (
    <div className={styles.root}>
      <img
        src={guidanceBlockImg}
        role="presentation"
        className={styles.image}
      />
      <div className={styles.box}>
        <Box mb={0.5}>
          <Heading variant="heading-5">{title}</Heading>
        </Box>
        <Paragraph variant="body">{content}</Paragraph>
      </div>
    </div>
  )
}

export default memo(GuidanceBlockSmall)
