/**
 * Converts a string to a number. It will ignore any invalid inputs,
 * and return null instead. In other words, we pretend that the
 * invalid input never existed.
 *
 * Practical example, parsing a number from a querystring. A user could
 * manually change a numeric value to include invalid characters.
 */
export const safeParseInt = (str: string | null | undefined) => {
  if (str == null) return str
  const num = parseInt(str, 10)

  if (isNaN(num)) return null

  return num
}
