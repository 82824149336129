import { BasicEmployeeInfo } from "../../types/Employee"
import { RequestedReview } from "../../types/EvaluationCycle"

export const mockDirectSubjects: BasicEmployeeInfo[] = [
  {
    id: 13,
    email: "stacy@zugata.com",
    full_name: "Stacy Baxter",
    profile_image_url:
      "/performance/api/v1/profile_image/58F204EE-BE81-4D1C-AC7D-B8477397F60B",
  },
  {
    id: 14,
    email: "philippe@zugata.com",
    full_name: "Philippe Van Nuijs",
    profile_image_url:
      "/performance/api/v1/profile_image/5C55F87C-73F7-464E-A577-29157566F578",
  },
  {
    id: 15,
    email: "kirsten@zugata.com",
    full_name: "Kirsten Moore",
    profile_image_url:
      "/performance/api/v1/profile_image/81D22DA9-BB6F-4533-ADB0-D3A42D9A8A03",
  },
]

// This mock was copied from /manager_requested_feedbacks?user_id=15&from=2020-03-21T22%3A49%3A58.201Z
export const mockRequestedReviews: RequestedReview[] = [
  {
    id: 176,
    kind: "PeerFeedback",
    invitation_token:
      "zjHDHnfw8qqDxVSERLL8HMiE7s9CKqSqLXY398o3V2haRnadYuF2QUuzqNRN9kyCs3dK3UFAb94dBtN1hseoyhULDFBGtV2cmMvgvFsiAB1sKt3bxRgag2UZ15vGV6qoGpkrLagFCFCTSLpTpsHCT8HwgvJfSspLcJvFBi6gpa8r",
    state: "completed",
    completed_at: "2020-04-20T23:40:57Z",
    created_at: "2020-04-20T23:35:15Z",
    reject_reason: null,
    creator_id: 12,
    rescinded_at: null,
    author: {
      id: 13,
      email: "stacy@zugata.com",
      job_title: "Manager",
      full_name: "Stacy Baxter",
      best_name: "Stacy Baxter",
      avatar_images: {
        thumb_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/SB?size=100",
        small_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/SB?size=200",
        medium_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/SB?size=300",
        large_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/SB?size=500",
      },
      manager_email: "sk@zugata.com",
      profile_image_url:
        "/performance/api/v1/profile_image/58F204EE-BE81-4D1C-AC7D-B8477397F60B",
    },
  },
  {
    id: 177,
    kind: "PeerFeedback",
    invitation_token:
      "xi9aMBarzVpKdNSLHvXxF4mkcNq3W5pvTSRZFog6isgs5SuzNFT4kh3i3HR4rdc9nz9kH5fPE1NmoWsiX4wPNKRwEqvW2rw1gZGnpuyeFBbgsnqPwSwqjcGLspdpL3dii2Aaw38cx6P92334d9HLLG9VpxbUVSm5gpwsxYF3rDAr",
    state: "created",
    completed_at: null,
    created_at: "2020-04-20T23:35:15Z",
    reject_reason: null,
    creator_id: 12,
    rescinded_at: null,
    author: {
      id: 14,
      email: "philippe@zugata.com",
      job_title: "Manager",
      full_name: "Philippe Van Nuijs",
      best_name: "Philippe Van Nuijs",
      avatar_images: {
        thumb_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/PVN?size=100",
        small_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/PVN?size=200",
        medium_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/PVN?size=300",
        large_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/PVN?size=500",
      },
      manager_email: "sk@zugata.com",
      profile_image_url:
        "/performance/api/v1/profile_image/5C55F87C-73F7-464E-A577-29157566F578",
    },
  },
  {
    id: 178,
    kind: "PeerFeedback",
    invitation_token:
      "FYr6HwY9gyqSzfUhXGzF38ciusNcuNExssVsVNqyU2scHRjry8hYp3fHDNb1PeqBr6PqFBsmuJP7p9uy9xQqvyG6BwwGknz63B12FewdXjuNn6AXNv9SH8ukuoo55xzqbpndmsZJonWTMWEpuNmqkuHyqwCMz9ZNg7Cs98a7hvAr",
    state: "created",
    completed_at: null,
    created_at: "2020-04-20T23:35:15Z",
    reject_reason: null,
    creator_id: 12,
    rescinded_at: null,
    author: {
      id: 17,
      email: "jessica.l@zugata.com",
      job_title: "Manager",
      full_name: "Jessica Lenker",
      best_name: "Jessica Lenker",
      avatar_images: {
        thumb_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/JL?size=100",
        small_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/JL?size=200",
        medium_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/JL?size=300",
        large_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/JL?size=500",
      },
      manager_email: "kirsten@zugata.com",
      profile_image_url:
        "/performance/api/v1/profile_image/B022C71B-99E1-4955-BA44-C32312D7DD1B",
    },
  },
  {
    id: 179,
    kind: "UpwardFeedback",
    invitation_token:
      "ZWNsyp2xSDHPp8XMRDFxzVoqw5EVisdCKeAbQAjtxdgCgi7HFgghnH2guzMskrSXqGemwsNkHDzsTvmhppdkjgb5DmySELYtoUGeW3Hj4UTrieax4DUXPdgn7qq5sNzqM7wdvpKsQy79z8yxeajCD9Za3MVz12Gq5ZM8hrpKsqAr",
    state: "created",
    completed_at: null,
    created_at: "2020-04-20T23:35:15Z",
    reject_reason: null,
    creator_id: 12,
    rescinded_at: null,
    author: {
      id: 22,
      email: "nic@zugata.com",
      job_title: "Manager",
      full_name: "Nic Edwards",
      best_name: "Nic Edwards",
      avatar_images: {
        thumb_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/NE?size=100",
        small_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/NE?size=200",
        medium_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/NE?size=300",
        large_url:
          "https://tzefwd0vl7.execute-api.us-west-2.amazonaws.com/perform/profilePic/NE?size=500",
      },
      manager_email: "kirsten@zugata.com",
      profile_image_url:
        "/performance/api/v1/profile_image/848B0177-832D-4943-A583-08D63DD08283",
    },
  },
]
