import endpoints from "../constants/endpointsDeprecated"
import createIncompleteSurveyResponseActions from "./lib/createIncompleteSurveyResponseActions"

const {
  SURVEYS_URLS: { SURVEY_RESPONSES_URL, SURVEY_ITEMS_URL },
} = endpoints

export default createIncompleteSurveyResponseActions({
  surveyResponsesUrl: SURVEY_RESPONSES_URL,
  surveyItemsUrl: SURVEY_ITEMS_URL,
})
