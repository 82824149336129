import { Size } from "../types/Positioning"

export const getViewportDimensions = (): Size => {
  return {
    width: Math.max(
      document.documentElement && document.documentElement.clientWidth,
      window.innerWidth || 0
    ),
    height: Math.max(
      document.documentElement && document.documentElement.clientHeight,
      window.innerHeight || 0
    ),
  }
}

export const getElementRect = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    width: rect.width,
    height: rect.height,
  }
}

/**
 * Gets the element relative to the supplied scroll container.
 */
const getElementRectInsideScrollElement = (
  element: HTMLElement,
  scrollElement: HTMLElement
) => {
  if (!element || !scrollElement) return null

  const rect = getElementRect(element)
  const scrollElementRect = getElementRect(scrollElement)

  const elemScrollLeft = scrollElement.scrollLeft
  const elemScrollTop = scrollElement.scrollTop

  return {
    top: rect.top - scrollElementRect.top + elemScrollTop,
    left: rect.left - scrollElementRect.left + elemScrollLeft,
    width: rect.width,
    height: rect.height,
  }
}

/**
 * Takes an element, and finds the nearest ancestor that is scrollable.
 */
export const getClosestScrollAncestor = (
  node: (HTMLElement | ParentNode) | null
): HTMLElement | null => {
  const isElement = node instanceof HTMLElement
  const overflowY =
    isElement && window.getComputedStyle(node as Element).overflowY
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden"

  if (!node) {
    return null
  } else if (
    isScrollable &&
    (node as HTMLElement).scrollHeight >= (node as HTMLElement).clientHeight
  ) {
    return node as HTMLElement
  }

  // @ts-ignore: Property 'parentNode' does not exist on type 'ParentNode'...but it does
  return getClosestScrollAncestor(node.parentNode) || document.body
}

/**
 * Allows you to anchor an element to a position relative to the closest
 * scroll element, while you do some dom manipulation.
 *
 * ie. before you make some dom changes, we take a
 * snapshot of where the element position is, in relation to the closest
 * scroll element. Then we adjust scroll position on the next animation
 * frame. To the user, it will look like that element never even moved!
 *
 * If there is any sort of problem, this function silently returns a noop function.
 * This is because the nature of the function is not show stopping.
 */
export const scrollAnchor = (elementToLock: HTMLElement | null) => {
  if (!elementToLock) return _.noop

  const scrollElement = getClosestScrollAncestor(elementToLock) as HTMLElement

  if (!scrollElement) return _.noop

  const originalRec = getElementRectInsideScrollElement(
    elementToLock,
    scrollElement
  )
  if (!originalRec) return _.noop
  const origScrollTop = scrollElement.scrollTop

  const release = () => {
    const newRec = getElementRectInsideScrollElement(
      elementToLock,
      scrollElement
    )
    if (!originalRec || !newRec) return

    const diff = newRec.top - originalRec.top
    scrollElement.scrollTop = origScrollTop + diff
  }

  return release
}

/**
 * Smooth animate scrolls to a specific pixel value.
 * Since native smooth scrolling is not supported by IE11, the scrolling
 * will not be animated in that browser. There's no point adding a polyfill for
 * something non-critical.
 */
export const smoothScroll = (
  element: HTMLElement,
  { top, left }: { top?: number; left?: number }
) => {
  if (element.scroll) {
    element.scroll({
      top,
      left,
      behavior: "smooth",
    })
  } else {
    if (top != null) element.scrollTop = top
    if (left != null) element.scrollLeft = left
  }
}

export const scrollToElement = (element: HTMLElement | null) => {
  if (!element) return

  const scrollPanel = getClosestScrollAncestor(element)

  if (!scrollPanel || !element) return

  const rect = getElementRect(element)

  smoothScroll(scrollPanel, { top: scrollPanel.scrollTop + rect.top })
}
