import { useCallback, useContext, useEffect } from "react"
// eslint-disable-next-line import/no-named-as-default
import surveyResponsesDecoder from "../../api/decoders/SurveyResponsesDecoder/surveyResponsesDecoder"
import {
  SurveyResponse,
  SurveyResponsesContext,
} from "../../context/SurveyState"
import useDataAPI from "../performanceApi/useDataAPI"

interface Filters {
  completed?: boolean
  kind: "MRF" | "TBF"
  mine?: boolean
}

const useSurveyResponses = (
  filters: Filters = { kind: "MRF", mine: false, completed: false }
) => {
  const { state, dispatch } = useContext(SurveyResponsesContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading, error, fire } = useDataAPI(
    `/survey_responses${filters.mine ? "/mine" : ""}`,
    "GET"
  )

  useEffect(() => {
    fire({
      ...filters,
      page: 1,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.kind, filters.completed])

  useEffect(() => {
    if (data) {
      surveyResponsesDecoder
        .decodePromise(data)
        .then((response) => {
          if (filters.mine) {
            dispatch({
              type: "FETCH_MY_SURVEY_RESPONSES",
              payload: response,
            })
          } else {
            dispatch({
              type: "FETCH_SURVEY_RESPONSES",
              payload: response,
            })
          }
        })
        .catch((error) => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const filteredIds =
    state[filters.mine ? "mySurveyResponses" : "allSurveyResponses"]

  const fetchMore = useCallback(() => {
    const nextPage = filteredIds.nextPage
    if (nextPage) {
      fire({ ...filters, page: nextPage })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredIds.nextPage, filters.completed, filters.kind, filters.mine]) // no entiendo bien esto

  const surveyResponses = Object.values(filteredIds.pages)
    .reduce((res, surveyResponses) => [...res, ...surveyResponses], [])
    .map((id) => state.surveyResponsesById[id] as SurveyResponse)

  const hasMore = filteredIds.totalCount > surveyResponses.length

  return {
    hasMore,
    fetchMore,
    surveyResponses,
    loading,
    error,
  }
}

export default useSurveyResponses
