import React from "react"
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl"
import { Link } from "react-router"
import ellipsis from "@kaizen/component-library/icons/ellipsis.icon.svg"
import { Icon as KaizenIcon } from "@kaizen/component-library"
import cx from "classnames"
import ButtonMenu from "../ButtonMenu/ButtonMenu"
// @ts-ignore
import Icon from "../Icon/Icon"
import Button from "../Button/Button"
import "./MoreButtonsMenu.less"
import strings from "../../locale/strings"

export type Option = {
  icon: string | object
  onClick?: () => void
  to?: string
  text: FormattedMessage.MessageDescriptor
  className?: string
}

type Props = {
  options: Option[]
  hasLabel: boolean
  className?: string
  onMouseLeave?: boolean // Yes, this is actually supposed to be a boolean
  showTip?: boolean
  menuAlignment?: "left" | "middle" | "right"
  intl: InjectedIntl
}

function MoreButtonsMenu({
  options,
  className = "",
  onMouseLeave,
  hasLabel,
  showTip = false,
  intl,
  menuAlignment = "right",
}: Props) {
  const { formatMessage } = intl

  const renderMenuItem = ({ icon, text, onClick, to }: Option) => {
    const Root = to ? Link : "button"
    const rootProps = to ? { to } : { onClick, type: "button" }

    return (
      // @ts-ignore
      <Root
        className="MoreButtonsMenu--row layout horizontal"
        onClick={onClick}
        {...rootProps}
      >
        {formatMessage(text)}
        <div className="MorebuttonsMenu--row-icon">
          {/* If the icon is a string, it is using the legacy icon type */}
          {typeof icon === "string" ? (
            <Icon iconName={icon} />
          ) : (
            <KaizenIcon icon={icon} role="presentation" />
          )}
        </div>
      </Root>
    )
  }

  return (
    <ButtonMenu
      className={cx(
        `MoreButtonsMenu layout vertical center-center ${className}`,
        { "MoreButtonsMenu--no-label": !hasLabel }
      )}
      showTip={showTip}
      menuAlignment={menuAlignment}
      items={options.map(renderMenuItem)}
      onMouseLeave={onMouseLeave}
    >
      <Button actionType="secondary" iconPosition={"end"} icon={ellipsis}>
        {hasLabel ? formatMessage(strings.moreButtonMenu) : ""}
      </Button>
    </ButtonMenu>
  )
}

export default injectIntl(MoreButtonsMenu)
