import "./GoalAlignmentList.less"
import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import strings from "../../locale/strings"
import Row from "./Row"
import AlignableGoalItemFormat from "./AlignableGoalItemFormat"

export default class GoalAlignmentList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    alignments: PropTypes.array.isRequired,
    onClickAlignment: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: "",
  }

  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  company = () => {
    const {
      user: { company },
    } = this.context
    return company
  }

  renderRow = (goal) => {
    const companyName = this.company().name

    const { onClickAlignment } = this.props

    return (
      <Row
        key={goal.id}
        className="GoalAlignmentList--item"
        onClick={onClickAlignment.bind(null, goal)}
      >
        <AlignableGoalItemFormat goal={goal} companyName={companyName} />
      </Row>
    )
  }

  /* Filter out team goals if the new goals interface is disabled */
  filteredAlignments = (alignments) => {
    if (!this.company().allow_new_goals_interface) {
      return alignments.filter(
        (alignment) => alignment.goal_type !== "team_goal"
      )
    }
    return alignments
  }

  alignedGoalTypes = (alignments) => {
    const alignmentTypes = _.chain(alignments)
      .map("goal_type")
      .uniq()
      .sort()
      .value()

    if (alignmentTypes.length === 1) return alignmentTypes
    else if (this.company().allow_new_goals_interface) return "all_goal_types"
    else return alignmentTypes.join(";")
  }

  render() {
    const { className, alignments } = this.props
    const filteredAlignments = this.filteredAlignments(alignments)

    return (
      <div className={`GoalAlignmentList ${className}`}>
        <div className="GoalAlignmentList--header">
          <FormattedMessage
            {...strings.goals.alignments.sectionHeader}
            values={{
              alignedGoalTypes: this.alignedGoalTypes(filteredAlignments),
              alignmentCount: alignments.length,
            }}
          />
        </div>

        {filteredAlignments.map(this.renderRow)}
      </div>
    )
  }
}
