import { JsonDecoder } from "ts.data.json"
const { failover, number, object } = JsonDecoder

export interface GoalStats {
  goalCount: number
  overallProgress: number | null
}

const goalStatsDecoder = object<GoalStats>(
  {
    goalCount: number,
    overallProgress: failover(null, number),
  },
  "goalStats",
  {
    goalCount: "goal_count",
    overallProgress: "overall_progress",
  }
)

export default goalStatsDecoder
