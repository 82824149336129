import { cryptoRandomString } from "./random"

export default class XSRFProtector {
  constructor({ storage = window.localStorage } = {}) {
    this.storage = storage
  }

  _getKey(key) {
    return `XSRFProtector--${key}`
  }

  establishToken(key) {
    const token = cryptoRandomString(16)
    this.storage.setItem(this._getKey(key), token)
    return token
  }

  checkToken(key, token) {
    if (!token) {
      throw new Error(`Possible XSRF attack: no token provided for ${key}`)
    }

    const existingToken = this.storage.getItem(this._getKey(key))

    if (!existingToken) {
      throw new Error(
        `Possible XSRF attack: no existing XSRF token found for ${key}`
      )
    }

    if (existingToken !== token) {
      throw new Error(`Possible XSRF attack: tokens did not match for ${key}`)
    }
  }
}
