import useDataAPI from "../performanceApi/useDataAPI"
import { DepartmentGoalFields } from "../../containers/DepartmentGoalForm/DepartmentGoalForm"
import logCreateGoalEvent from "../../api/analytics/logCreateGoalEvent"
import { TeamGoalOwner } from "../../types/Goals"

const useCreateDepartmentGoal = () => {
  const fetchAPI = useDataAPI("/goals", "POST")
  const createDepartmentGoal = (fields: DepartmentGoalFields) => {
    const goaldata = {
      // id is set as integer on backend
      type: "department_goal",
      department_id: parseInt(fields.departmentId),
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      goal_owners: fields.owners.map(
        (owner: TeamGoalOwner) => owner.aggregateId
      ),
      key_results: fields.keyResults.map(({ title }) => ({
        title,
        completion: 0,
      })),
      outgoing_alignment_associations_attributes: fields.alignedGoalIds.map(
        (id: number) => ({
          incoming_alignment_goal_id: id,
        })
      ),
    }
    fetchAPI.fire({ goal: goaldata }).then((res) => {
      logCreateGoalEvent("Department", res.data.goal)
    })
  }
  return {
    createDepartmentGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading,
  }
}

export default useCreateDepartmentGoal
