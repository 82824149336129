import React from "react"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
// @ts-ignore
import BaseEditableQuestion from "./BaseEditableQuestion"

export type Props = {
  className?: string
  showRequired: boolean
  title?: string
  required?: boolean
  prompt?: string
  showPrompt?: boolean
  description?: string
  showDescription?: boolean
  autoFocus?: boolean
  promptMessages?: {
    label: FormattedMessage.MessageDescriptor
    addLabel: FormattedMessage.MessageDescriptor
    placeholder: FormattedMessage.MessageDescriptor
  }
  onChangeTitle: (title: string) => void
  onChangeDescription?: (desc: string) => void
  onChangePrompt?: () => void
  onDelete: () => void
  onRequiredChange?: (required: boolean) => void
  onFocus: () => void
  onBlur: () => void
  status?: "error" | "default"
  validationMessage?: string
}

function EditableOpenEnded({
  className = "",
  showRequired,
  ...otherProps
}: Props) {
  return (
    <BaseEditableQuestion
      className={`EditableOpenEnded ${className}`}
      titlePlaceholder={strings.customizedQuestions.openEnded.placeholder}
      showRequired={showRequired}
      {...otherProps}
    />
  )
}

export default EditableOpenEnded
