import endpoints from "../../constants/endpointsDeprecated"
import createIncompleteSurveyResponseActions from "../../refluxActions/lib/createIncompleteSurveyResponseActions"

const {
  ADMIN_SURVEYS_URLS: { ADMIN_SURVEY_RESPONSES_URL, ADMIN_SURVEY_ITEMS_URL },
} = endpoints

export default createIncompleteSurveyResponseActions({
  surveyResponsesUrl: ADMIN_SURVEY_RESPONSES_URL,
  surveyItemsUrl: ADMIN_SURVEY_ITEMS_URL,
})
