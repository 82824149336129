import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react"
import { TextField } from "@kaizen/draft-form"
import { Select as KaizenSelect } from "@kaizen/draft-select"
import { Text } from "@kaizen/component-library"
import searchIcon from "@kaizen/component-library/icons/search.icon.svg"
import { InjectedIntl, injectIntl } from "react-intl"
import useDebounce from "../../hooks/timeout/useDebounce"
import styles from "./FiltersBar.scss"
import { DatePicker as DatePickerComp } from "../DatePicker"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"

export type FilterValue = string | number | boolean | Date | Date[] | undefined
export type Filters = Record<string, FilterValue>

type FiltersBarState = {
  filters: Filters
  updateFilter: (filterName: string, filterValue: FilterValue) => void
  intl?: InjectedIntl
}

const FiltersBarContext = createContext<FiltersBarState>({
  filters: {},
  updateFilter: () => {},
})

function useFiltersContext() {
  const context = useContext(FiltersBarContext)

  if (!context) {
    throw new Error(
      "FiltersBar compound components must be render within a FiltersBar component"
    )
  }

  return context
}

const Search = ({
  name,
  placeholder,
  searchTracking,
}: {
  name: string
  placeholder?: string
  searchTracking?: () => void
}) => {
  const { filters, updateFilter, intl } = useFiltersContext()
  const [value, setValue] = useState(filters[name] || "")
  const debouncedValue = useDebounce(value, 500)

  useEffect(() => {
    updateFilter(name, debouncedValue)
    if (searchTracking && debouncedValue !== "") {
      searchTracking()
    }
  }, [debouncedValue, name, updateFilter, searchTracking])

  return (
    <TextField
      id={Aid.alignGoalSearchFieldInput}
      labelText={intl?.formatMessage(strings.filters.search)}
      inputType="text"
      // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
      inputValue={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setValue(e.target.value)
      }
      placeholder={placeholder}
      icon={searchIcon}
    />
  )
}

type SelectOption = {
  value: string | boolean | number
  label: string
}

// fix for select z-index issue on mobile
const Select = ({
  name,
  options,
}: {
  name: string
  options: SelectOption[]
}) => {
  const { filters, updateFilter, intl } = useFiltersContext()
  const selectedOption = options.find(
    (option) => option.value === filters[name]
  )
  return (
    <div className={styles.select}>
      <Text tag="p" style="label" inheritBaseline>
        {intl?.formatMessage(strings.filters.filter)}
      </Text>
      <KaizenSelect
        // @ts-ignore for untyped styles object on next line
        styles={{ menu: (styles) => ({ ...styles, zIndex: 1000 }) }}
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        options={options}
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        onChange={({ value }: SelectOption) => updateFilter(name, value)}
        // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
        value={selectedOption}
      />
    </div>
  )
}

const DatePicker = ({
  name,
  allowDateRange = false,
  initialDate,
}: {
  name: string | ((index: number) => string)
  allowDateRange?: boolean
  initialDate?: Date[]
}) => {
  const { updateFilter, intl } = useFiltersContext()

  const onChange = (dates: Date[]) => {
    if (typeof name === "string") {
      updateFilter(name, dates)
    } else {
      ;[0, 1].map((index) => updateFilter(name(index), dates[index]))
    }
  }

  return (
    <DatePickerComp
      id="date-picker"
      label={intl?.formatMessage(strings.filters.dueDate)}
      allowDateRange={allowDateRange}
      initialDate={initialDate}
      onChange={onChange}
    />
  )
}

const FiltersBar: React.FC<{
  defaultFilters?: Filters
  onFiltersChange?: (filters: Filters) => void
  intl: InjectedIntl
}> = ({ children, onFiltersChange, defaultFilters = {}, intl }) => {
  const [filters, setFilters] = useState<Filters>(defaultFilters)

  useEffect(() => {
    onFiltersChange && filters && onFiltersChange(filters)
  }, [filters, onFiltersChange])

  const updateFilter = useCallback(
    (filterName: string, filterValue: FilterValue) => {
      setFilters((filters) => ({
        ...filters,
        [filterName]: filterValue,
      }))
    },
    []
  )

  const value = useMemo(
    () => ({
      filters,
      updateFilter,
      intl,
    }),
    [filters, updateFilter, intl]
  )

  return (
    <div className={styles.filtersBar}>
      <FiltersBarContext.Provider value={value}>
        {children}
      </FiltersBarContext.Provider>
    </div>
  )
}

const injectedComponent = injectIntl(FiltersBar)

const InjectedFiltersBar: typeof injectedComponent & {
  Search: typeof Search
  Select: typeof Select
  DatePicker: typeof DatePicker
} = Object.assign(injectedComponent, {
  Search,
  Select,
  DatePicker,
})

export default InjectedFiltersBar
