import Reflux from "./ActionsInitializer"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const agent = SteadyfootAgent.defaultInstance

const {
  SAVED_VIEW_URLS: { SAVED_VIEW_URL },
} = endpoints

const SavedViewActions = Reflux.createActions({
  list: { asyncResult: true },
  load: { asyncResult: true },
})

SavedViewActions.list.listenAndPromise(({ type }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey(
        "saved_views",
        agent.get(SAVED_VIEW_URL).query({ type, per_page: 1e9 })
      ).then((savedViews) => ({ savedViews, type }))
    )
  )
)

SavedViewActions.load.listenAndPromise(({ id }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey("saved_view", agent.get(`${SAVED_VIEW_URL}/${id}`))
    )
  )
)

export default SavedViewActions
