import React from "react"
import cx from "classnames"
import { Heading, Icon } from "@kaizen/component-library"
import arrowBack from "@kaizen/component-library/icons/arrow-backward.icon.svg"
import styles from "./Drawer.scss"

type DrawerProps = {
  children: React.ReactNode
  drawerHeading?: React.ReactElement | string
  drawerFooter?: React.ReactElement | string
  onToggle?: () => void
  isOpen?: boolean
}

export const DrawerSection = () => {}

export const Drawer: React.FunctionComponent<DrawerProps> = ({
  children,
  drawerHeading,
  drawerFooter,
  onToggle,
  isOpen,
}) => {
  return (
    <div
      className={cx(styles.drawer, {
        [styles.drawerOpen]: isOpen,
      })}
    >
      <button onClick={onToggle} className={styles.drawerHeading}>
        <span className={styles.drawerToggle}>
          <Icon icon={arrowBack} />
        </span>
        <span className={styles.drawerHeadingElement}>
          <Heading variant="heading-6">{drawerHeading}</Heading>
        </span>
      </button>
      <div className={styles.drawerContent}>{children}</div>
      {drawerFooter && (
        <div className={styles.drawerFooter}>{drawerFooter}</div>
      )}
    </div>
  )
}
