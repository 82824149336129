import React, { memo, useCallback } from "react"
import { ConfirmationModal } from "@kaizen/draft-modal"
import { EvaluationCycle } from "../../types/EvaluationCycle"
import styles from "./styles.scss"
import { getHasPaufCompletionStarted } from "../../domain/evaluationCycles/evaluationCycles"
import strings from "../../locale/strings"
import { useIntl } from "../../hooks/locale/useIntl"
import { formatDateToLocalDate } from "../../utils/date"
import R from "../../constants/routes"
import { useRouter } from "../../hooks/routing/useRouter"
import { EmployeeV2 } from "../../types/Employee"
import { EmployeeSuggestion } from "../../containers/ReviewerSelectionTypeahead/ReviewerSelectionTypeahead"

type Employee = EmployeeV2 | EmployeeSuggestion

type Props = {
  selectedReviewers: Employee[]
  handleViewRequestsClick: () => void
  cycle: EvaluationCycle
}

/**
 * The modal that shows up after the manager has selected the reviewers,
 * clicked "Send requests", and the api call was successful.
 */
const RequestsSentModal = ({
  handleViewRequestsClick,
  cycle,
  selectedReviewers,
}: Props) => {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const handleBackToDashboardClick = useCallback(() => {
    router.push(R.dashboard)
  }, [router])

  return (
    <ConfirmationModal
      isOpen
      type="positive"
      title={formatMessage(strings.ecPaufRequestFeedback.feedbackRequestsSent, {
        count: selectedReviewers.length,
      })}
      dismissLabel={formatMessage(strings.ecPaufRequestFeedback.viewRequests)}
      confirmLabel={formatMessage(
        strings.ecPaufRequestFeedback.backToDashboard
      )}
      onDismiss={handleViewRequestsClick}
      onConfirm={handleBackToDashboardClick}
    >
      <p className={styles.modalText}>
        {getHasPaufCompletionStarted(cycle, new Date())
          ? formatMessage(
              strings.ecPaufRequestFeedback.feedbackRequestsSentMessageImmediate
            )
          : formatMessage(
              strings.ecPaufRequestFeedback.feedbackRequestsSentMessage,
              {
                date: formatDateToLocalDate(
                  cycle.modules.peer_and_upward_feedback.completion_start_date
                ),
              }
            )}
      </p>
    </ConfirmationModal>
  )
}

export default memo(RequestsSentModal)
