import { TableCard, TableRow } from "@kaizen/draft-table"
import React from "react"
import Aid from "../../constants/automationId"

// Having a separate TableCard and TableRow for a table row is a bit too
// granular for most use cases. This component combines them into one.
const TableCardRow = ({
  children,
  forceHoverState,
  automationId,
}: {
  children: React.ReactNode
  forceHoverState?: boolean
  automationId?: Aid
}) => (
  <TableCard forceHoverState={forceHoverState}>
    {/* The kaizen component doesn't have the ability to pass down the
    automation id, so I'm just wrapping it in a div */}
    <div data-automation-id={automationId}>
      <TableRow>{children}</TableRow>
    </div>
  </TableCard>
)

export default TableCardRow
