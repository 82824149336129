import React, { memo } from "react"
import { hot } from "react-hot-loader"
import { Box } from "@kaizen/component-library"
import visibleIcon from "@kaizen/component-library/icons/visible.icon.svg"
import styles from "./FeedbackPageHeader.scss"
import { useIntl } from "../../hooks/locale/useIntl"
import strings from "../../locale/strings"
import BackButton from "../../components/BackButton/BackButton"
import R from "../../constants/routes"
import Button from "../../components/Button/Button"
import { useModalHref } from "../../hooks/modals/modals"
import { Modals } from "../../constants/modals"
import { BasicEmployeeInfo } from "../../types/Employee"
import HeadingWithAvatar from "../../components/HeadingWithAvatar/HeadingWithAvatar"
import DEFAULT_IMAGE_URL from "../../static/images-2017/avatars/manager.png"

const MainHeading = ({
  subject,
}: {
  subject: BasicEmployeeInfo | undefined
}) => {
  const { formatMessage } = useIntl()
  return !subject ? (
    <HeadingWithAvatar
      headingTag="heading-1"
      noMargin
      imageUrl={DEFAULT_IMAGE_URL}
      title={formatMessage(strings.ecPaufRequestFeedback.requestFeedback)}
    />
  ) : (
    <HeadingWithAvatar
      headingTag="heading-1"
      noMargin
      imageUrl={subject.profile_image_url}
      title={formatMessage(strings.ecPaufRequestFeedback.requestFeedbackFor, {
        name: subject.full_name,
      })}
    />
  )
}

type Props = {
  // The only time the cycle id could be undefined, is if the user manually
  // changes the url
  cycleId?: number | null
  cycleTitle?: string
  cycleDueDate?: string
  subject: BasicEmployeeInfo | undefined
  isPreviewQuestionsButtonVisible?: boolean
}

const FeedbackPageHeader = ({
  cycleId,
  cycleTitle,
  cycleDueDate,
  subject,
  isPreviewQuestionsButtonVisible = true,
}: Props) => {
  const { formatMessage } = useIntl()
  const href = useModalHref(Modals.ecPaufPreviewQuestions, {
    id: cycleId,
  })

  return (
    <header>
      <div className={styles.topArea}>
        <BackButton to={R.dashboard} compensateForLeftMargin />
      </div>

      <Box my={0.25}>
        <MainHeading subject={subject} />
      </Box>

      <div className={styles.subSection}>
        <p className={styles.cycleTitleAndDate}>
          {cycleTitle}
          {" ・ "}
          {cycleDueDate}
        </p>
        <div className={styles.previewButtonWrapper}>
          {cycleId && isPreviewQuestionsButtonVisible ? (
            <Button actionType="secondary" to={href} icon={visibleIcon}>
              {formatMessage(
                strings.ecPaufRequestFeedback.previewQuestionsButton
              )}
            </Button>
          ) : null}
        </div>
      </div>
    </header>
  )
}

export default hot(module)(memo(FeedbackPageHeader))
