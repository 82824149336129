import usersDecoder from "../../api/decoders/UsersDecoder/usersDecoder"
import { User } from "../../types/User"
import useCurrentUser from "../auth/useCurrentUser"
import useSearch from "../performanceApi/useSearch"

const useUserSearch = (query: string, includeCurrentUser: boolean) => {
  const currentUser = useCurrentUser()
  const { results, loading, error } = useSearch<User>(
    "/users",
    usersDecoder,
    query
  )

  const currentUserResponse: User = {
    aggregateId: currentUser.employee_aggregate_id,
    role: currentUser.job_title_name ? currentUser.job_title_name : "",
    profileImage: currentUser.profile_image_url,
    name: currentUser.best_name,
  }

  return {
    results: includeCurrentUser
      ? [
          /**
           * Notice here that we are sending the parameters expected from the new current user.
           * This should be fixed when we update our new current user to be camel case and we can
           * transform different types as TeamLead, TeamMember, etc to be a partial from the CurrentUser
           */
          currentUserResponse,
          ...results,
        ]
      : results,
    loading,
    error,
  }
}

export default useUserSearch
