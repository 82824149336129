import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import d3 from "d3"
import cx from "classnames"
import { FormattedMessage, injectIntl } from "react-intl"
import { Tag } from "@kaizen/draft-tag"
import { Tooltip } from "@kaizen/draft-tooltip"
import { rescaleDomain, scaleFromObject } from "../../utils/scales"
import Settings from "../../settings"
import MarkdownText from "../MarkdownText/MarkdownText"
import strings from "../../locale/strings"
import Icon from "../Icon/Icon"
import "./MultipleChoice.less"

@injectIntl
export default class MultipleChoice extends React.Component {
  static propTypes = {
    question: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      canShare: PropTypes.bool,
      choices: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            description: PropTypes.string,
          })
        ),
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }).isRequired,
    selectedAnswerValue: PropTypes.number,
    htmlId: PropTypes.string.isRequired,
    onSelectAnswer: PropTypes.func,
    answerOptionsMapper: PropTypes.func,
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    orientation: PropTypes.oneOf(["vertical", "horizontal"]),
    textAlign: PropTypes.oneOf(["left-align", "center-center"]),
    className: PropTypes.string,
    hideAsterisk: PropTypes.bool,
    disableColor: PropTypes.bool,
    hideQuestion: PropTypes.bool,
    automationId: PropTypes.string,
  }

  static defaultProps = {
    answerOptionsMapper: (question) =>
      _(question.answer_titles_hash)
        .map((value, key) => ({ title: value, value: Number(key) }))
        .sortBy("value")
        .value(),
    orientation: "horizontal",
    textAlign: "left-align",
    onSelectAnswer: _.noop,
    className: "",
    hideQuestion: false,
  }

  handleSelectAnswer(value) {
    const { selectedAnswerValue, onSelectAnswer } = this.props
    if (selectedAnswerValue === value) {
      onSelectAnswer(null)
    } else {
      onSelectAnswer(value)
    }
  }

  getButtonCSSContent(selected, buttonId, color, orientation) {
    const { readOnly } = this.props
    const isVertical = orientation === "vertical"

    let html = ""
    if (!readOnly) {
      if (isVertical) {
        html = `#${buttonId}:hover {
                  border-left: 4px solid #21A38B !important;
                  background-color: #f8f8f9;
                }

                #${buttonId}:focus {
                  border-left: 4px solid #21A38B !important;
                  background-color: #f8f8f9;
                }`
      } else {
        const lightText = "#FFFFFF"
        const darkText = "#35374A"

        const backgroundLuminance = d3.hcl(color).l
        const lightTextLuminance = d3.hcl(lightText).l
        const darkTextLuminance = d3.hcl(darkText).l

        const lightTextContrast = Math.abs(
          lightTextLuminance - backgroundLuminance
        )
        const darkTextContrast = Math.abs(
          darkTextLuminance - backgroundLuminance
        )

        const textColor =
          lightTextContrast > darkTextContrast ? lightText : darkText

        html = `#${buttonId}:hover {
                  background-color: ${color};
                  color: ${textColor};
                }
                #${buttonId}:focus {
                  background-color: ${color};
                  color: ${textColor};
                }`
      }
    } else {
      if (selected) {
        if (isVertical) {
          html = `#${buttonId} {
                    padding-left: 20px !important;
                  }`
        } else {
          html = ""
        }
      } else {
        if (isVertical) {
          html = `#${buttonId} {
                    padding-left: 24px !important;
                    opacity: 0.7;
                  }`
        } else {
          html = `#${buttonId} {
                    opacity: 0.7;
                  }`
        }
      }
    }

    if (selected) {
      if (isVertical) {
        html += `#${buttonId} {
                    background-color: #f8f8f9;
                    border-left: 4px solid #21A38B !important;
                  }`
      } else {
        html += `#${buttonId} {
                    background-color: ${color};
                    color: white;
                  }
                  @media print {
                    #${buttonId} {
                      background-color: transparent;
                      color: ${color};
                      border: 5px solid ${color};
                    }
                  }`
      }
    } else {
      html += ""
    }

    return {
      __html: html,
    }
  }

  getColorScale(choices) {
    const [answerMin, answerMax] = d3.extent(choices, (opt) => opt.value)
    return rescaleDomain(
      scaleFromObject("linear", Settings.RATING_COLOR_SCALE),
      [answerMin, answerMax]
    )
  }

  renderButton({
    buttonId,
    selected,
    buttonColor,
    text,
    readOnly,
    orientation,
    value,
    textAlign,
    description,
  }) {
    return (
      <div
        key={buttonId}
        id={buttonId}
        disabled={readOnly}
        type="button"
        className={cx(
          "MultipleChoice--button flex one",
          `MultipleChoice--button-${orientation}`
        )}
        onClick={() => this.handleSelectAnswer(value)}
      >
        <style
          dangerouslySetInnerHTML={this.getButtonCSSContent(
            selected,
            buttonId,
            buttonColor,
            orientation
          )}
        />
        {orientation === "vertical" && !readOnly && (
          <div
            className={`MultipleChoice--button-icon ${
              selected ? "selected" : ""
            }`}
          >
            <div className="MultipleChoice--button-icon-dot">
              <Icon iconName="empty" className="empty" />
              <Icon iconName="full" className="full" />
            </div>
          </div>
        )}
        {description ? (
          <Tooltip text={description}>
            <div
              className={`MultipleChoice--button-content layout vertical ${textAlign}`}
            >
              {text}
            </div>
          </Tooltip>
        ) : (
          <div
            className={`MultipleChoice--button-content layout vertical ${textAlign}`}
          >
            {text}
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      className,
      question,
      orientation,
      htmlId,
      index,
      readOnly,
      hideAsterisk,
      disableColor,
      selectedAnswerValue,
      answerOptionsMapper,
      textAlign,
      hideQuestion,
      automationId,
      intl: { formatMessage },
    } = this.props
    const { can_share: canShare, title, description } = question
    const choices = answerOptionsMapper(question)
    const colorScale = this.getColorScale(choices)

    const renderShareableIndicator = () =>
      !hideQuestion && canShare && !hideAsterisk ? (
        <div className="MultipleChoice--question-shared">
          <Tooltip
            text={formatMessage(strings.customizedQuestions.sharedReminder)}
          >
            <Tag variant="validationInformative" inline>
              <FormattedMessage {...strings.customizedQuestions.shared} />
            </Tag>
          </Tooltip>
        </div>
      ) : null

    const renderOptionalIndicator = () =>
      // The assessment group question is a special snowflake and doesn’t have a “required” attribute
      !question.required &&
      question.required !== undefined && (
        <span className="MultipleChoice--question-optional">
          <FormattedMessage
            {...strings.customizedQuestions.optionalIndicator}
          />
        </span>
      )

    return (
      <div
        className={`MultipleChoice ${className}`}
        ref={(ref) => (this.container = ref)}
        data-automation-id={automationId}
      >
        <div className="MultipleChoice--question-container layout horizontal">
          {index && (
            <div className="MultipleChoice--question-number flex none">
              {index + ". "}
            </div>
          )}
          {!hideQuestion && (
            <div className="MultipleChoice--question layout vertical flex one">
              <div className="MultipleChoice--question-title">
                <MarkdownText text={title} inline={true} />
                {renderOptionalIndicator()}
              </div>
              {description && (
                <div>
                  <MarkdownText
                    className="MultipleChoice--question-description"
                    text={description}
                    inline={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {renderShareableIndicator()}
        <div className={`layout ${orientation}`}>
          {choices.map(({ title: text, description, value }) => {
            const buttonId = `${htmlId}-${value}`
            const selected = selectedAnswerValue === value
            const buttonColor = disableColor
              ? Settings.COLORS.TEXT_COLOR_SECONDARY
              : colorScale(value)
            return this.renderButton({
              buttonId,
              selected,
              buttonColor,
              text,
              readOnly,
              value,
              orientation,
              textAlign,
              description,
            })
          })}
        </div>
      </div>
    )
  }
}
