import Reflux from "reflux-core"
import _ from "lodash"
import SteadyfootAgent from "./lib/SteadyfootAgent"

const ZugiPointsActions = Reflux.createActions({
  updatePointsInfo: {},
})

SteadyfootAgent.defaultInstance.use((request) => {
  request.on("response", (res) => {
    const meta = _.get(res.body, "meta")

    if (meta && meta.total_zugi_points !== undefined) {
      ZugiPointsActions.updatePointsInfo({
        totalPoints: meta.total_zugi_points,
        recentZugiPointsActivities: meta.recent_zugi_points_activities,
      })
    }
  })
})

export default ZugiPointsActions
