/* eslint-disable @typescript-eslint/no-explicit-any, no-console */
// @ts-ignore
import Settings from "../settings"

export const isLogTagEnabled = (tag: string): boolean => {
  return Settings.LOG_TAGS.indexOf(tag) !== -1
}

export const groupCollapsed = (...args: any) => {
  if (console.groupCollapsed) {
    console.groupCollapsed(...args)
  } else {
    // Old browsers don't support console.groupCollapsed
    console.log("---")
    console.log(...args)
  }
}

export const groupEnd = () => {
  if (console.groupEnd) {
    console.groupEnd()
  } else {
    // Old browsers don't support console.groupEnd
    console.log("---")
  }
}
