import Reflux from "../../refluxActions/ActionsInitializer"
import strings from "../../locale/strings"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "../../refluxActions/lib/apiActionHelpers"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import endpoints from "../../constants/endpointsDeprecated"

const agent = SteadyfootAgent.defaultInstance

const {
  ADMIN_SAVED_VIEW_URLS: { ADMIN_SAVED_VIEW_URL },
} = endpoints

const AdminSavedViewActions = Reflux.createActions({
  create: { asyncResult: true },
  list: { asyncResult: true },
  load: { asyncResult: true },
  delete: { asyncResult: true },
  update: { asyncResult: true },
  share: { asyncResult: true },
})

AdminSavedViewActions.create.listenAndPromise(({ name, type, options }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {
        success: strings.savedViews.toasts.create.success,
        defaultError: strings.savedViews.toasts.create.error,
      },
      extractResponseKey(
        "saved_view",
        agent
          .post(ADMIN_SAVED_VIEW_URL)
          .send({ saved_view: { name, type, options } })
      )
    )
  )
)

AdminSavedViewActions.list.listenAndPromise(({ type }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey(
        "saved_views",
        agent.get(ADMIN_SAVED_VIEW_URL).query({ type, per_page: 1e9 })
      ).then((savedViews) => ({ savedViews, type }))
    )
  )
)

AdminSavedViewActions.load.listenAndPromise(({ id }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey(
        "saved_view",
        agent.get(`${ADMIN_SAVED_VIEW_URL}/${id}`)
      )
    )
  )
)

AdminSavedViewActions.delete.listenAndPromise(({ id }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {
        success: strings.savedViews.toasts.delete.success,
        defaultError: strings.savedViews.toasts.delete.error,
      },
      extractResponseKey(
        "saved_view",
        agent.del(`${ADMIN_SAVED_VIEW_URL}/${id}`)
      )
    )
  )
)

AdminSavedViewActions.update.listenAndPromise(({ id, options }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {
        success: strings.savedViews.toasts.update.success,
        defaultError: strings.savedViews.toasts.update.error,
      },
      extractResponseKey(
        "saved_view",
        agent
          .put(`${ADMIN_SAVED_VIEW_URL}/${id}`)
          .send({ saved_view: { options } })
      )
    )
  )
)

AdminSavedViewActions.share.listenAndPromise(({ id, userIds }) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {
        success: strings.savedViews.toasts.share.success,
        defaultError: strings.savedViews.toasts.share.error,
      },
      extractResponseKey(
        "saved_view",
        agent
          .post(`${ADMIN_SAVED_VIEW_URL}/${id}/share`)
          .send({ user_ids: userIds })
      )
    )
  )
)

export default AdminSavedViewActions
