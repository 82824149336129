import { v4 as uuid } from "uuid"

type KeyResult = {
  id: string
  title: string
  completion?: number
  _destroy?: boolean
  isNew?: boolean
}

export const updatedKeyResults = (
  keyResultsToUpdate: KeyResult[],
  originalKeyResults: KeyResult[]
): KeyResult[] => {
  const removedKeyResults = originalKeyResults
    .filter(
      (keyResult) => !keyResultsToUpdate.some(({ id }) => id === keyResult.id)
    )
    .map((keyResult) => ({
      ...keyResult,
      _destroy: true,
    }))
  const addedKeyResults = keyResultsToUpdate.filter(
    (keyResult) => !originalKeyResults.some(({ id }) => id === keyResult.id)
  )
  const updatedKeyResults = keyResultsToUpdate.filter(
    (kr) => kr.isNew === undefined
  )

  return [...removedKeyResults, ...updatedKeyResults, ...addedKeyResults]
}

export const newKeyResult: () => KeyResult = () => ({
  title: "",
  id: uuid(),
  isNew: true,
  completion: 0,
})
