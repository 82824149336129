import PropTypes from "prop-types"
import React from "react"
import MarkdownText from "../../components/MarkdownText/MarkdownText"
import "./CopyableSurveyItem.less"

const CopyableSurveyItem = ({ className, surveyItem, containerRef }) => (
  <div className={`CopyableSurveyItem ${className}`} ref={containerRef}>
    <MarkdownText text={surveyItem.question_title} inline={true} />
    <br />
    <br /> {/* HTML breaks will be preserved thru copy + paste */}
    <MarkdownText text={surveyItem.answer} />
  </div>
)

CopyableSurveyItem.propTypes = {
  className: PropTypes.string,
  surveyItem: PropTypes.object,
  containerRef: PropTypes.func,
}

CopyableSurveyItem.defaultProps = {
  className: "",
}

export default CopyableSurveyItem
