import useDataAPI from "../performanceApi/useDataAPI"
import { CompanyGoalFields } from "../../containers/CompanyGoalForm/CompanyGoalForm"
import logCreateGoalEvent from "../../api/analytics/logCreateGoalEvent"
import { TeamGoalOwner } from "../../types/Goals"

const useCreateCompanyGoal = () => {
  const fetchAPI = useDataAPI("/goals", "POST")
  const createCompanyGoal = (fields: CompanyGoalFields) => {
    const goaldata = {
      type: "company_goal",
      company_id: fields.companyId,
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      goal_owners: fields.owners.map(
        (owner: TeamGoalOwner) => owner.aggregateId
      ),
      key_results: fields.keyResults.map(({ title }) => ({
        title,
        completion: 0,
      })),
      outgoing_alignment_associations_attributes: fields.alignedGoalIds.map(
        (id: number) => ({
          incoming_alignment_goal_id: id,
        })
      ),
    }
    fetchAPI.fire({ goal: goaldata }).then((res) => {
      logCreateGoalEvent("Company", res.data.goal)
    })
  }
  return {
    createCompanyGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading,
  }
}

export default useCreateCompanyGoal
