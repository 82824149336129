import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import { addLoadingState, addToasts } from "./lib/apiActionHelpers"
import strings from "../locale/strings"
import endpoints from "../constants/endpointsDeprecated"

const { GENERAL_URLS } = endpoints
const { UNSUBSCRIBE_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const EmailActions = Reflux.createActions({
  unsubscribe: { asyncResult: true },
})

EmailActions.unsubscribe.listenAndPromise(({ email, signature, type }) =>
  addToasts(
    { defaultError: strings.emailUnsubscribe.errorMessage },
    addLoadingState(
      [true, { light: true }],
      agent
        .post(UNSUBSCRIBE_URL)
        .send({ user_communication_preference: { email, signature, type } })
    )
  )
)

export default EmailActions
