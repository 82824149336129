import "./GoalStatus.less"
import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import STATUS_OPTIONS from "./utils/goalStatusOptions"

const STATUS_OPTION_TITLES = _(STATUS_OPTIONS)
  .indexBy("value")
  .mapValues("text")
  .value()

const GoalStatus = ({ status }) => (
  <div className={`GoalStatus GoalStatus--${status}`}>
    <FormattedMessage {...STATUS_OPTION_TITLES[status]} />
  </div>
)

GoalStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
}

GoalStatus.defaultProps = {
  className: "",
}

export default GoalStatus
