export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string
      name: string
      possibleTypes: {
        name: string
      }[]
    }[]
  }
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "Node",
        possibleTypes: [
          {
            name: "Employee",
          },
          {
            name: "JobTitle",
          },
          {
            name: "Department",
          },
          {
            name: "EvaluationCycle",
          },
          {
            name: "PeerNominationCycle",
          },
          {
            name: "PerformanceCycle",
          },
        ],
      },
      {
        kind: "UNION",
        name: "Task",
        possibleTypes: [
          {
            name: "BlockedGoalTask",
          },
          {
            name: "CalibrationViewTask",
          },
          {
            name: "PeerNominationTask",
          },
          {
            name: "PerformanceReviewTask",
          },
          {
            name: "ProvideAdhocFeedbackTask",
          },
          {
            name: "ProvideFeedbackTask",
          },
          {
            name: "RequestFeedbackTask",
          },
          {
            name: "SelfReflectionTask",
          },
        ],
      },
    ],
  },
}
export default result
