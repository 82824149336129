import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import DeprecatedAvatar from "./DeprecatedAvatar"
import User from "../../models/User"
import "./ProfilePic.less"

class ProfilePic extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      avatar_images: PropTypes.shape({
        thumb_url: PropTypes.string,
        small_url: PropTypes.string,
        medium_url: PropTypes.string,
        large_url: PropTypes.string,
      }),
      profile_image_url: PropTypes.string,
    }),
    size: PropTypes.oneOf(["thumb", "small", "medium", "large"]),
    showHorizontalRule: PropTypes.bool,
  }

  static defaultProps = {
    user: {},
    size: "medium",
    showHorizontalRule: false,
  }

  static contextTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      avatar_url: props.user?.profile_image_url,
    }
  }

  render() {
    const { size, showHorizontalRule, className, user } = this.props

    const noAvatar = !user || User.isDeactivated(user)
    return (
      <div
        key={`${showHorizontalRule}-${noAvatar ? "deact" : user.id}`}
        className={cx(className, "ProfilePic", `ProfilePic--size-${size}`)}
      >
        {showHorizontalRule && <hr className="ProfilePic--hr" />}

        {noAvatar ? (
          <i
            className={`ProfilePic--deactivated material-icons ${
              showHorizontalRule ? "overlapsHr" : ""
            }`}
          >
            account_circle
          </i>
        ) : (
          <DeprecatedAvatar
            size={size === "thumb" ? "small" : size}
            key={`${showHorizontalRule}-${noAvatar ? "deact" : user.id}-avatar`}
            className={showHorizontalRule ? "ProfilePic--overlapsHr" : ""}
            url={this.state.avatar_url}
          />
        )}
      </div>
    )
  }
}

const ProfilePicWrapper = (props) => (
  <ProfilePic
    {...props}
    key={`${props.user ? props.user.id : "profile-pic-key"}`}
  />
)

export default ProfilePicWrapper
