import "./InfiniteScroll.less"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import React from "react"
import $ from "jquery"
import _ from "lodash"
import ScrollTracker from "../../utils/ScrollTracker"
import getExtraProps from "../../utils/getExtraProps"
import Loader from "../Loader/Loader"

const propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  hasMore: PropTypes.bool,
  onNextPageNeeded: PropTypes.func.isRequired,

  scrollActivateOffset: PropTypes.number,
}

export default class InfiniteScroll extends React.Component {
  static propTypes = propTypes

  static defaultProps = {
    className: "",
    scrollActivateOffset: 100,
  }

  constructor(props) {
    super(props)

    this.containerNode = null
    this.scrollTracker = null

    this.state = {
      loadingMore: false,
    }
  }

  getScrollParent = (node) => {
    return node ? $(node).scrollParent()[0] : null
  }

  componentDidMount() {
    // setTimeout of 0 to allow for custom scroll parent to mount/be attached to refs
    setTimeout(() => {
      const scrollParent = this.getScrollParent(this.containerNode)
      if (scrollParent) {
        this.scrollTracker = new ScrollTracker(
          scrollParent,
          this.handleScrollboxChange.bind(this)
        )
        this.scrollTracker.start()
      }
    }, 0)
  }

  componentWillReceiveProps(nextProps) {
    // REVIEW
    const resultSizeChanged =
      _.get(this.props.items, "length") !== _.get(nextProps.items, "length")

    if (this.state.loadingMore && (!nextProps.hasMore || resultSizeChanged)) {
      this.setState({ loadingMore: false })
    }
  }

  componentWillUnmount() {
    if (this.scrollTracker) {
      this.scrollTracker.stop()
    }
  }

  loadNextPage() {
    this.setState({ loadingMore: true })
    this.props.onNextPageNeeded()
  }

  handleScrollboxChange({ clientTop, height }) {
    const {
      props: { hasMore, scrollActivateOffset },
      state: { loadingMore },
    } = this

    if (hasMore && !loadingMore) {
      // eslint-disable-next-line react/no-find-dom-node
      const boundingBox = ReactDOM.findDOMNode(this).getBoundingClientRect()

      if (boundingBox.bottom - scrollActivateOffset < clientTop + height) {
        this.loadNextPage()
      }
    }
  }

  render() {
    const {
      props: { className, children },
      state: { loadingMore },
    } = this
    return (
      <div
        className={`InfiniteScroll ${className}`}
        ref={(node) => (this.containerNode = node)}
        {...getExtraProps(this)}
      >
        {children}
        {loadingMore ? (
          <div className="InfiniteScroll--loader-panel">
            <Loader />
          </div>
        ) : null}
      </div>
    )
  }
}
