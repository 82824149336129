import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../refluxActions"
import BaseEmployeeGroupingStore from "./BaseEmployeeGroupingStore"

export default Reflux.createStore(
  _.assign({}, BaseEmployeeGroupingStore, {
    init() {
      BaseEmployeeGroupingStore.init.call(this)

      this.listenTo(
        Actions.Company.listEmployeeGroupings.completed,
        this.onListEmployeeGroupings
      )

      this.listenTo(
        Actions.Company.getDepartments.completed,
        this.onGetDepartments
      )
    },
  })
)
