import React from "react"
import PropTypes from "prop-types"
import Tooltip from "rc-tooltip"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
import User from "../../models/User"
import Icon from "../Icon/Icon"
import "./ProfileItemVisibilityIndicator.less"

const ProfileItemVisibilityIndicator = (
  { className, itemType, subjectUser, getTooltipContainer },
  { user: currentUser }
) => {
  const itemIsForManager = [
    "peer_feedback",
    "self_reflection",
    "survey_response",
  ].includes(itemType)

  const lookingAtOwnSelfReflection =
    itemType === "self_reflection" && User.equals(currentUser, subjectUser)

  const shouldShow =
    itemIsForManager &&
    subjectUser.is_managed && // This actually means current user is manager/admin/HRBP of user
    !User.isManagerOf(currentUser, subjectUser) &&
    !lookingAtOwnSelfReflection

  if (!shouldShow) {
    return null
  }

  const tooltipMessageType =
    User.isHrbp(currentUser) || User.isAdmin(currentUser)
      ? "adminOrHrbp"
      : "indirect"

  return (
    <Tooltip
      placement="top"
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
      overlay={
        <div className="ProfileItemVisibilityIndicator--tooltip">
          <FormattedMessage
            {...strings.pastFeedback.visibilityTooltip[tooltipMessageType]}
          />
        </div>
      }
      getTooltipContainer={getTooltipContainer}
    >
      <div
        className={`ProfileItemVisibilityIndicator material-icons ${className}`}
      >
        <Icon iconName="visible" />
      </div>
    </Tooltip>
  )
}

ProfileItemVisibilityIndicator.propTypes = {
  className: PropTypes.string,
  subjectUser: PropTypes.object.isRequired,
  itemType: PropTypes.oneOf([
    "self_reflection",
    "peer_feedback",
    "review",
    "praise_review",
    "review_share",
    "survey_response",
  ]).isRequired,
  getTooltipContainer: PropTypes.func.isRequired,
}

ProfileItemVisibilityIndicator.contextTypes = {
  user: PropTypes.object.isRequired,
}

ProfileItemVisibilityIndicator.defaultProps = {
  className: "",
}

export default ProfileItemVisibilityIndicator
