import _ from "lodash"
import { collapseGroupTypes } from "./teamHelpers"

export function adaptSurveyResponseSearchParams(
  {
    departmentIds,
    managerIds,
    groupTypes,
    jobTitleIds,
    userIds,
    bucketId,
    optionalAttributeIds,
  },
  { stringifyArrays = true } = {}
) {
  const convertArray = (array) =>
    array && stringifyArrays ? array.join(",") : array

  let extraParams = {}
  if (bucketId === "incomplete") {
    extraParams = { completed: false }
  } else if (bucketId) {
    extraParams = { [bucketId]: true }
  }

  const params = {
    department_ids: convertArray(departmentIds),
    manager_ids: convertArray(managerIds),
    job_title_ids: convertArray(jobTitleIds),
    group_type: collapseGroupTypes(groupTypes),
    user_ids: convertArray(userIds),
    optional_attribute_ids: convertArray(optionalAttributeIds),

    // TODO - so far, the only thing using surveys is self reflection, and the filter buckets for
    // that are incomplete|seen_by_recipient|..., which are expressed in the API as separate boolean
    // parameters. To support evaluations, we'll need extra logic to support numeric buckets.
    ...extraParams,
  }

  return _.pick(params, (value) => value || value === 0 || value === false)
}
