import * as React from "react"
import { useEffect, useState } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import useTeam from "../../domainHooks/teams/useTeam"
import TeamForm from "../TeamForm/TeamForm"
import useUpdateTeam from "../../domainHooks/teams/useUpdateTeam"
import strings from "../../locale/strings"

interface EditTeamProps {
  router: InjectedRouter
  params: { teamId: string }
  intl: InjectedIntl
}

const EditTeam = ({ router, params, intl }: EditTeamProps) => {
  const { team } = useTeam(params.teamId)
  const [notification, setNotification] = useState()
  const { updateTeam, loading, data, error } = useUpdateTeam(params.teamId)
  const { formatMessage } = intl
  useEffect(() => {
    // @ts-ignore
    if (data && data.status === "ok") {
      router.push(`/teams/${params.teamId}`)
    }
    if (error) {
      // @ts-ignore
      const message = error.response ? error.response.data.error : error.message
      setNotification(message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])
  if (!team || !team.details) return <div>Loading</div>
  const teamFields = {
    id: team.id,
    name: team.name,
    description: team.description,
    teamLeads: team.details.teamLeads,
    teamMembers: team.details.teamMembers,
  }

  return (
    <TeamForm
      notification={notification}
      defaultFields={teamFields}
      onCancel={router.goBack}
      onSubmit={updateTeam}
      disabled={loading}
      title={formatMessage(strings.teams.newTeamForm.update) + ` ${team.name}`}
      submitLabel={formatMessage(strings.teams.newTeamForm.update)}
    />
  )
}

export default injectIntl(EditTeam)
