import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../refluxActions/index"

const FeedbackReplyStore = Reflux.createStore({
  init() {
    this.data = {
      repliesByReviewId: {},
      reviewIdsLoadingReplies: {},
      lastSavedReplyIdByReviewId: {},
    }

    this.listenTo(Actions.Feedback.loadReplies, this.onLoadRepliesStarted)
    this.listenTo(
      Actions.Feedback.loadReplies.completed,
      this.onLoadRepliesCompleted
    )
    this.listenTo(Actions.Feedback.loadReplies.failed, this.onLoadRepliesFailed)

    this.listenTo(Actions.Feedback.reply.completed, this.onReplyCompleted)
  },

  getInitialState() {
    return this.data
  },

  onLoadRepliesStarted(review) {
    this.data.reviewIdsLoadingReplies = {
      ...this.data.reviewIdsLoadingReplies,
      [review.id]: true,
    }

    this.trigger(this.data)
  },

  onLoadRepliesCompleted({ replies, reviewId }) {
    this.data.repliesByReviewId = {
      ...this.data.repliesByReviewId,
      [reviewId]: replies,
    }

    this.data.reviewIdsLoadingReplies = _.omit(
      this.data.reviewIdsLoadingReplies,
      reviewId
    )

    this.trigger(this.data)
  },

  onLoadRepliesFailed(body, err, reviewId) {
    this.data.reviewIdsLoadingReplies = _.omit(
      this.data.reviewIdsLoadingReplies,
      reviewId
    )

    this.trigger(this.data)
  },

  onReplyCompleted({ reply, reviewId }) {
    this.data.lastSavedReplyIdByReviewId = {
      ...this.data.lastSavedReplyIdByReviewId,
      [reviewId]: reply.id,
    }
    this.trigger(this.data)
  },
})

export default FeedbackReplyStore
