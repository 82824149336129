import { useContext } from "react"
import { CurrentUserContext } from "../../context/CurrentUser"

const useCurrentUser = () => {
  const currentUser = useContext(CurrentUserContext)
  if (currentUser != null) {
    return currentUser
  } else {
    throw "Current user should not be null"
  }
}

export default useCurrentUser
