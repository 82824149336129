import "./MessageGroup.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import cx from "classnames"
import strings from "../../locale/strings"
import User from "../../models/User"
import ProfilePic from "../ProfilePic/ProfilePic"
import ZugataFormattedRelativeDate from "../../components/ZugataFormattedDate/ZugataFormattedRelativeDate"

export default class MessageGroup extends React.Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    className: PropTypes.string,
    isFirst: PropTypes.bool,
  }

  static defaultProps = {
    className: "",
    isFirst: false,
  }

  render() {
    const { messages, className, isFirst } = this.props
    const messageGroupSender = messages[0].props.sender
    const messageGroupTime = isFirst && messages[0].props.timestamp
    const isDeleted = messageGroupSender === null
    const isAnon = !isDeleted && !messageGroupSender.email
    let author
    if (isAnon) {
      author = <FormattedMessage {...strings.general.anonymous} />
    } else if (isDeleted) {
      author = <FormattedMessage {...strings.user.deleted} />
    } else {
      author = User.getDisplayName(messageGroupSender)
    }

    return (
      <div
        className={cx(
          "MessageGroup layout horizontal",
          {
            "MessageGroup-indented": !isFirst,
          },
          className
        )}
      >
        <ProfilePic
          className="MessageGroup--profile-pic flex none"
          user={messageGroupSender}
        />
        <div className="MessageGroup--content layout vertical start flex one">
          <div className="layout horizontal">
            {!isDeleted ? (
              <div className="MessageGroup--info">
                <span className="MessageGroup--author">{author}</span>
                {messageGroupTime && (
                  <ZugataFormattedRelativeDate value={messageGroupTime} />
                )}
              </div>
            ) : (
              <i className="MessageGroup--author">{author}</i>
            )}
            {messageGroupSender && User.isDeactivated(messageGroupSender) && (
              <div className="MessageGroup--author-deactivated">
                <FormattedMessage {...strings.user.deactivated} />
              </div>
            )}
          </div>
          <div className="MessageGroup--messages">
            {messages.map((message, index) =>
              React.cloneElement(message, { key: index })
            )}
          </div>
        </div>
      </div>
    )
  }
}
