import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import SearchAgent from "./lib/SearchAgent"
import { addLoadingState } from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { USERS_URLS } = endpoints
const { USERS_URL } = USERS_URLS
const agent = SteadyfootAgent.defaultInstance

const resourceName = "users"

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: USERS_URL,
  resourceName,
  pageSize: 30,
})

const SearchActions = Reflux.createActions({
  searchPeople: { asyncResult: true },
  pagePeopleSearchResults: { asyncResult: true },
})

SearchActions.searchPeople.listenAndPromise(({ searchParams = {} } = {}) =>
  addLoadingState([true, { light: true }], searchAgent.search({ searchParams }))
)

SearchActions.pagePeopleSearchResults.listenAndPromise(
  ({ searchParams = {}, page }) => {
    return searchAgent.search({ searchParams, page })
  }
)

export default SearchActions
