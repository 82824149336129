import React from "react"
import _ from "lodash"

/**
 * Reflux's `connect` as a higher-order component, usable as a ES7 decorator.
 * Note that instead of attaching store state to the component's `state`, it is
 * passed in `props`.
 *
 * @param {Reflux.Store} store
 * @param {string=} optKey
 * @param {Reflux.Action=} clearData - called to clear out the store when the component mounts
 * and unmounts
 *
 * @example
 *
 * 		@connect(UserStore)
 * 		class MyComponent extends React.Component {
 * 			render() {
 * 				return <div>{this.props.currentUser}</div>;
 * 			}
 * 		}
 *
 * 		// also:
 * 		MyComponent = connect(UserStore)(MyComponent);
 *
 * 		// with a key:
 * 		@connect(UserStore, 'userStore')
 * 		class MyComponent extends React.Component {
 * 			render() {
 * 				return <div>{this.props.userStore.currentUser}</div>;
 * 			}
 * 		}
 */
export default function connect(store, optKey, { clearData } = {}) {
  return (Component) =>
    class WrappedComponent extends React.Component {
      constructor(props) {
        super(props)

        this.state = {
          storeState: store.getInitialState && store.getInitialState(),
        }
      }

      componentDidMount() {
        this.unsub = store.listen((storeState) => this.setState({ storeState }))
        if (clearData) {
          clearData()
        }
      }

      componentWillUnmount() {
        if (this.unsub) {
          this.unsub()
        }
        if (clearData) {
          clearData()
        }
      }

      render() {
        const { storeState } = this.state
        const props = _.clone(this.props)

        if (optKey) {
          props[optKey] = storeState
        } else {
          _.assign(props, storeState)
        }

        return React.createElement(Component, props)
      }
    }
}
