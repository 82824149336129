/**
 * @fileoverview Misc utilities for use with d3.scale.
 */

import d3 from "d3"
import _ from "lodash"

/**
 * Creates a d3 scale using `obj`'s keys as the domain and
 * `obj`'s values as the range.
 *
 * @param {string|Function} scaleCreator - either a d3.scale
 *    constructor or the name of one, for example, `d3.scale.linear`
 *    or "linear".
 *
 * @param {Object} obj - the object whose keys and values represent
 *    the scale's domain and range, respectively.
 *
 * @return {Function} the created scale
 */
export function scaleFromObject(scaleCreator, obj) {
  if (typeof scaleCreator === "string") {
    scaleCreator = d3.scale[scaleCreator]
  }

  const [keys, values] = _(obj).pairs().unzip().value()

  return scaleCreator().domain(keys).range(values)
}

/**
 * Transforms a d3 scale by scaling its domain up or down.
 *
 * @param {Function} originalScale the d3.scale object to scale
 *
 * @param {Array} newDomain - the new domain for the created scale. Only
 *  two values may be supplied, and are interpreted as [min, max].
 */
export function rescaleDomain(originalScale, newDomain) {
  if (newDomain.length !== 2) {
    throw new Error(
      `newDomain must be a two-element array. Got: ${JSON.stringify(newDomain)}`
    )
  }

  const origDomain = originalScale.domain()

  const transormedDomain = origDomain.map(
    d3.scale.linear().domain(d3.extent(origDomain)).range(newDomain)
  )

  return originalScale.copy().domain(transormedDomain)
}
