import * as React from "react"
import { Text } from "@kaizen/component-library"
import { InjectedIntl, injectIntl } from "react-intl"
import { FormattedMessage } from "react-intl"
import styles from "./FeedbackRequestHeader.scss"
import { TeamMember } from "../../context/TeamsState"
import Aid from "../../constants/automationId"
// @ts-ignore
import MarkdownFormattedMessage from "../../components/MarkdownFormattedMessage/MarkdownFormattedMessage"
import HeadingWithAvatar from "../../components/HeadingWithAvatar/HeadingWithAvatar"

import FeatureFlags from "../../constants/featureFlags"
import { FeatureSwitch } from "../../components/FeatureSwitch/FeatureSwitch"

type FeedbackRequestHeader = React.FunctionComponent<{
  teamMember: Pick<TeamMember, "profileImage" | "avatar" | "name">
  intl: InjectedIntl
  title: string
  body: FormattedMessage.MessageDescriptor
}>

const FeedbackRequestHeader: FeedbackRequestHeader = ({
  teamMember,
  title,
  body,
}) => {
  return (
    <>
      <FeatureSwitch
        flag={[FeatureFlags.zenHeader, FeatureFlags.topNavigation]}
      >
        <FeatureSwitch.Disabled>
          <div className={styles.titleContainer}>
            <Text tag="h2" style="zen-heading-2">
              {title}
            </Text>
          </div>
        </FeatureSwitch.Disabled>
      </FeatureSwitch>
      <HeadingWithAvatar
        imageUrl={teamMember.profileImage}
        title={
          <MarkdownFormattedMessage
            {...body}
            values={{ name: teamMember.name }}
          />
        }
        automationId={Aid.teamBasedFeedbackReviewee}
        avatarAutomationId={Aid.teamBasedFeedbackAvatar}
      />
    </>
  )
}

export default injectIntl(FeedbackRequestHeader)
