import _ from "lodash"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setAtIndex = (arr: any[], val: any, index: number) => {
  if (index < 0) throw new Error("Invalid array index " + index)
  if (index >= arr.length) {
    return [...arr, ..._.range(0, index - arr.length).map(() => undefined), val]
  }
  const newArr = arr.slice(0, index)
  newArr.push(val)
  newArr.push(...arr.slice(index + 1))
  return newArr
}

// Yes, this is a simple map function. It's purpose is for the code to
// be a little more expressive.
export const updateWhere = <T>(
  to: (item: T) => T,
  predicate: (item: T) => boolean,
  arr: T[]
): T[] | undefined => {
  return arr.map((item: T) => {
    return predicate(item) ? to(item) : item
  })
}

export const flatMap = <OldItem, NewItem>(
  arr: OldItem[],
  mapFn: (item: OldItem) => NewItem[]
): NewItem[] => {
  return _.flatten(_.map(arr, mapFn))
}

/**
 * Take arrayA, and remove the entries that are also contained in arrayB.
 * The isEqualPredicate lets the function know which items are considered equal.
 */
export const differenceWith = <A, B>(
  arrayA: A[],
  arrayB: B[],
  isEqualPredicate: (a: A, b: B) => boolean
) => {
  return arrayA.filter((a: A) => {
    return !arrayB.find((b: B) => isEqualPredicate(a, b))
  })
}
