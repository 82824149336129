import { useContext, useEffect } from "react"
// eslint-disable-next-line import/no-named-as-default
import { surveyResponseDecoder } from "../../api/decoders/SurveyResponsesDecoder/surveyResponsesDecoder"
import { SurveyResponsesContext } from "../../context/SurveyState"
import useDataAPI from "../performanceApi/useDataAPI"

const useSurveyResponseDetail = (id?: number) => {
  const { state, dispatch } = useContext(SurveyResponsesContext)
  const fetchAPI = useDataAPI(`/survey_responses/${id}`, "GET")

  useEffect(() => {
    if (id) {
      fetchAPI.fire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (fetchAPI.data) {
      surveyResponseDecoder
        .decodePromise(fetchAPI.data)
        .then((surveyResponse) => {
          dispatch({ type: "FETCH_SURVEY_RESPONSE", payload: surveyResponse })
        })
        .catch((error) => {
          throw error
        })
    }
  }, [dispatch, fetchAPI.data])

  const surveyResponse = id ? state.surveyResponsesById[id] : undefined
  return { surveyResponse, loading: fetchAPI.loading }
}

export default useSurveyResponseDetail
