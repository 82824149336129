import { useState } from "react"
import { useAsyncEffect } from "../../hooks/async/async"
import * as usersApi from "../../api/users"
import * as paufCyclesApi from "../../api/paufCycles"
import { RequestedReview } from "../../types/EvaluationCycle"
import { useCycleForManager } from "../../admin/domainHooks/evaluationCycles/evaluationCyclesApi"

/**
 * The reviewer selection typeahead field has two group selections available:
 * "Request from {subject}'s directs", and "Request from my other directs".
 * This hook fetches the data requierd for these typeahead groups.
 */
export const useTypeaheadGroupData = (
  cycleId: number | undefined | null,
  subjectId: number | null | undefined
) => {
  const {
    data: subjectDirects,
    isLoading: isFetchingSubjectDirects,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAsyncEffect(async () => {
    try {
      const { users } = await usersApi.fetchDirectReports({
        managerId: subjectId || undefined,
        order: "random", // I'm not sure why the order is random, that's just what already existed in the ad-hoc MRF request feedback page
        perPage: 200,
      })
      return users
    } catch (err) {
      return []
    }
  }, [subjectId])

  const {
    data: currentUserDirects,
    isLoading: isFetchingCurrentUserDirects,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAsyncEffect(async () => {
    try {
      const { users } = await usersApi.fetchDirectReports({
        groupType: "directs",
        perPage: 200,
      })
      return users
    } catch (err) {
      return []
    }
  }, [])

  // Fetch the peers that the subject has nominated to give a review.
  // This one won't always be necessary, since the admin may have disabled
  // peer nominations from the cycle
  // (ie. cycle.modules.peer_and_upward_feedback.peer_nomination_cycle.is_included === false).
  // In which case, the array will just be empty, and we just won't show the group
  // in the typeahead.
  const {
    data: peerNominations,
    isLoading: isFetchingPeerNominations,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAsyncEffect(async () => {
    try {
      if (!cycleId || !subjectId) return null

      const { peer_nominations } = await paufCyclesApi.fetchPeerNominations(
        cycleId,
        subjectId
      )
      return peer_nominations
    } catch (err) {
      return []
    }
  }, [cycleId, subjectId])

  return {
    subjectDirects: subjectDirects,
    currentUserDirects,
    peerNominations,
    isFetchingTypeaheadGroupData:
      isFetchingSubjectDirects ||
      isFetchingCurrentUserDirects ||
      isFetchingPeerNominations,
  }
}

/**
 * This fetches and returns the evaluation cycle, and the subjects that the manager
 * must request reviewers for that cycle.
 * This hook may appear to hold an arbitrary selection of responsibility.
 * The reason why I did it this way is because the request feedback page
 * requires both pieces of information to really do anything. So it's just
 * a way of cleaning up the `EcPaufRequestFeedbackPage` file.
 *
 * From @jeremy8883: With that, it would be nice if from an efficiency point of view,
 * if this endpoint also returned the basic cycle information. That way we don't need
 * to make an extra call to /evaluation_cycles/:id.
 * This was the original idea, but we didn't end up scoping it because of time.
 * If we do end up finding time, first revert commit: 9dabe269abdae6884af4d3d413b1fc922661a357
 * Then cherry pick commit: 1fbf830c20df395a2063d49130a7f845c21d2244
 * It should add all of the changes you need for the frontend.
 */
export const useBasicDataForRequestFeedbackPage = (
  cycleId: number | null | undefined
) => {
  const {
    data,
    isLoading: isFetchingSubjects,
    error: subjectsFetchError,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAsyncEffect(async () => {
    if (!cycleId) return
    return await paufCyclesApi.fetchPaufSubjectsToRequestFeedback(cycleId)
  }, [cycleId])

  const { cycle, isFetchingCycle, fetchCycleError } = useCycleForManager(
    cycleId
  )

  return {
    cycle,
    directSubjects: data?.subjects,
    isFetchingCycleOrSubjects: isFetchingSubjects || isFetchingCycle,
    cycleOrSubjectsFetchError: subjectsFetchError || fetchCycleError,
  }
}

export const useRequestedReviews = (
  cycleId: number | null | undefined,
  subjectId: number | null
) => {
  const [requestedReviews, setRequestedReviews] = useState<
    RequestedReview[] | null
  >(null)

  const {
    isLoading: isFetchingRequestedReviews,
    error: requestedReviewsError,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useAsyncEffect(async () => {
    if (!subjectId || !cycleId) return null

    const { requested_reviews } = await paufCyclesApi.fetchPaufRequests(
      cycleId,
      subjectId
    )
    setRequestedReviews(requested_reviews)
    return requested_reviews
  }, [cycleId, subjectId])

  return {
    requestedReviews,
    isFetchingRequestedReviews,
    requestedReviewsError,
    setRequestedReviews,
  }
}
