import PropTypes from "prop-types"
import React from "react"
import Icon from "../../components/Icon/Icon"
import modalDeprecated from "../../higherOrderComponents/modalDeprecated"
import FeedbackReactionsManager from "./FeedbackReactionsManager"
import "./ShareReactionsModal.less"

@modalDeprecated({
  className: "ShareReactionsModal",
})
export default class ShareReactionsModal extends React.Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    review: PropTypes.shape({
      id: PropTypes.number.isRequired,
      reactions: PropTypes.array.isRequired,
    }).isRequired,
    onUpdateReactions: PropTypes.func.isRequired,
  }

  render() {
    const { onClose, review, onUpdateReactions } = this.props
    return (
      <div className="ShareReactionsModal">
        <div className="ShareReactionsModal--close-btn" onClick={onClose}>
          <Icon iconName="close" />
        </div>
        <FeedbackReactionsManager
          review={review}
          onClose={onClose}
          onUpdateReactions={onUpdateReactions}
        />
      </div>
    )
  }
}
