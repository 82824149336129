import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import _ from "lodash"
import { Box } from "@kaizen/component-library"
import shallowEquals from "../../utils/shallowEquals"
import strings from "../../locale/strings"
import GoalDateRangePicker from "./GoalDateRangePicker"
import TeamFilterBar from "../TeamFilterBar/TeamFilterBar"
import BucketSummary from "../../components/BucketSummary/BucketSummary"
import Table from "../../components/Table/Table"
import EmptyState from "../../components/EmptyState/EmptyState"
import SearchEmptyState from "../../components/SearchEmptyState/SearchEmptyState"
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll"
import GoalPriorityIndicator from "./GoalPriorityIndicator"
import NewGoalLabel from "./NewGoalLabel"
import GoalProgressBar from "./GoalProgressBar"
import Avatar from "../../components/Avatar/Avatar"
import ZugataFormattedDate from "../../components/ZugataFormattedDate/ZugataFormattedDate"
import { debounce } from "../../utils/timers"
import FadeoutTruncated from "../../components/FadeoutTruncated/FadeoutTruncated"
import CommentIndicator from "../../components/CommentIndicator/CommentIndicator"
import SortableCell from "../../components/SortableCell/SortableCell"
import Company from "../../models/Company"
import emptyTeamGoalsImg from "../../static/images-2017/drawings/emptystates/goals.png"
import "./GoalsTableDeprecated.less"

import Settings from "../../settings"
const { SEARCHFIELD_DEBOUNCE } = Settings

const DEFAULT_SORT_BY = "created_at"
const DEFAULT_SORT_ORDER = "desc"

const DEFAULT_SEARCH_PARAMS = {
  query: null,
  managerId: null,
  bestTitle: null,
  departmentId: null,
  status: null,
  groupType: null,
  startDate: null,
  endDate: null,
  sortBy: DEFAULT_SORT_BY,
  sortOrder: DEFAULT_SORT_ORDER,
}

const HEADER_CELLS = [
  {
    id: "owner-name",
    text: strings.general.employee,
    sortBy: "owner_name",
    className: "GoalsTable--owner-name-cell",
  },
  {
    id: "priority",
    text: strings.general.priority.priority,
    sortBy: "priority",
  },
  {
    id: "due-date",
    text: strings.general.dueDate,
    sortBy: "due_at",
    className: "GoalsTable--due-date-cell",
  },
  {
    id: "completion",
    text: strings.goals.percentComplete,
    sortBy: "completion",
  },
]

export default class GoalsTableDeprecated extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  static propTypes = {
    className: PropTypes.string,

    searchResults: PropTypes.arrayOf(PropTypes.object.isRequired),
    pagination: PropTypes.object,
    stats: PropTypes.object,

    onPage: PropTypes.func,
    onSelectGoal: PropTypes.func.isRequired,

    loadManagerOptions: PropTypes.func,
    loadManagerById: PropTypes.func,
    loadJobTitleOptions: PropTypes.func,
    loadJobTitleById: PropTypes.func,
    loadDepartmentOptions: PropTypes.func,

    adminView: PropTypes.bool,
    tableOnly: PropTypes.bool,
    emptyState: PropTypes.node,
    hideHeaderRow: PropTypes.bool,

    filters: PropTypes.object,
    onFiltersChange: PropTypes.func,
    resetFilters: PropTypes.object,
  }

  static defaultProps = {
    className: "",
    onPage: _.noop,
  }

  constructor(props, context) {
    super(props, context)

    const defaultEnclosingGoalPeriod = Company.getEnclosingGoalPeriod(
      this.context.user.company,
      new Date()
    )

    this.state = {
      searchParams: props.filters || {
        ...DEFAULT_SEARCH_PARAMS,
        startDate: defaultEnclosingGoalPeriod[0],
        endDate: defaultEnclosingGoalPeriod[1],
      },
    }
  }

  componentDidMount() {
    if (!this.props.filters) {
      this.notifyFiltersChange()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters) {
      this.setState({ searchParams: nextProps.filters })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchParams } = this.state

    if (
      searchParams !== this.props.filters &&
      !shallowEquals(searchParams, prevState.searchParams)
    ) {
      if (searchParams.query !== prevState.searchParams.query) {
        // Delay searches resulting from query changes, as those are the only rapidly occuring
        // changes
        this.notifyFiltersChangeWithDelay()
      } else {
        // Otherwise, force immediate search
        this.notifyFiltersChangeWithDelay.cancel()
        this.notifyFiltersChange()
      }
    }
  }

  notifyFiltersChange = () => {
    const { searchParams } = this.state
    const { onFiltersChange } = this.props

    if (onFiltersChange) {
      onFiltersChange(searchParams)
    }
  }

  @debounce(SEARCHFIELD_DEBOUNCE)
  notifyFiltersChangeWithDelay() {
    this.notifyFiltersChange()
  }

  handleFiltersChange = (filters) => {
    this.setState((state) => ({
      searchParams: {
        ...state.searchParams,

        // TeamFilterBar calls the job title ID just "jobTitle", so substitute "jobTitleId" for
        // "jobTitle"
        // TODO: rename to jobTitleId in TeamFilterBar
        ..._.omit(filters, "jobTitle"),
        ...(_.has(filters, "jobTitle") ? { bestTitle: filters.jobTitle } : {}),
      },
    }))
  }

  handleSortColumn = (sortBy, sortOrder) => {
    this.setState((state) => ({
      searchParams: { ...state.searchParams, sortBy, sortOrder },
    }))
  }

  handleDateParamChange = (dateRange) => {
    if (!dateRange || dateRange.length < 2) {
      return
    }

    this.setState((state) => ({
      searchParams: {
        ...state.searchParams,
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
    }))
  }

  handleBucketChange = (bucket) => {
    this.handleFiltersChange({ status: _.get(bucket, "id") })
  }

  handleNextPageNeeded = () => {
    const { pagination, onPage } = this.props
    const { searchParams } = this.state

    onPage({
      ...searchParams,
      page: pagination.next_page,
    })
  }

  resetFilters = () => {
    const { resetFilters } = this.props
    this.handleFiltersChange(resetFilters || DEFAULT_SEARCH_PARAMS)
  }

  renderHeaderRow() {
    const {
      searchParams: { sortBy, sortOrder },
    } = this.state
    const { adminView } = this.props

    return (
      <div key="headerRow">
        {HEADER_CELLS.map((cell) => (
          <SortableCell
            key={cell.sortBy}
            className={cell.className}
            sortState={sortBy === cell.sortBy ? sortOrder : null}
            onSort={(sortOrder) =>
              this.handleSortColumn(cell.sortBy, sortOrder)
            }
          >
            <FormattedMessage {...cell.text} />
          </SortableCell>
        ))}

        {!adminView && <div className="GoalsTable--comment-indicator" />}
      </div>
    )
  }

  renderGoalsTable() {
    const { user } = this.context
    const {
      searchResults,
      pagination,
      onSelectGoal,
      adminView,
      hideHeaderRow,
    } = this.props

    return (
      <InfiniteScroll
        items={searchResults}
        hasMore={!!_.get(pagination, "next_page")}
        onNextPageNeeded={this.handleNextPageNeeded}
      >
        <Table
          className="GoalsTable--search-results"
          responsive={true}
          headerRows={hideHeaderRow ? undefined : [this.renderHeaderRow()]}
          rows={searchResults.map((goal) => {
            const { goal_type: goalType } = goal
            return (
              <div key={goal.id} onClick={() => onSelectGoal(goal)}>
                <div className="GoalsTable--owner-cell">
                  <div className="GoalsTable--owner-cell-info">
                    {goalType === "my_goal" && (
                      <Avatar
                        className="GoalsTable--owner-avatar"
                        size="large"
                        imageURL={goal.user_profile_image_url}
                      />
                    )}
                    <div className="GoalsTable--owner-info layout vertical start">
                      {goalType === "my_goal" && (
                        <div className="GoalsTable--owner-name-row layout horizontal center">
                          <span className="GoalsTable--owner-name">
                            {goal.user_best_name}
                          </span>
                          <NewGoalLabel goal={goal} viewer={user} />
                        </div>
                      )}
                      {goalType === "department_goal" && (
                        <div className="GoalsTable--owner-name-row layout horizontal center">
                          <span className="GoalsTable--owner-name">
                            {goal.department.title}
                          </span>
                        </div>
                      )}
                      <div className="GoalsTable--goal-name">{goal.name}</div>
                    </div>
                  </div>
                </div>

                <div className="GoalsTable--priority-cell">
                  <div className="layout vertical">
                    <GoalPriorityIndicator priority={goal.priority} />

                    <FadeoutTruncated className="GoalsTable--goal-name-mobile">
                      {goal.name}
                    </FadeoutTruncated>
                  </div>
                </div>

                <div className="GoalsTable--due-date-cell">
                  <ZugataFormattedDate value={goal.due_at} month="short" />
                </div>

                <div className="GoalsTable--completion-cell">
                  <GoalProgressBar
                    value={goal.completion}
                    status={goal.status}
                  />
                </div>

                {!adminView && (
                  <div className="GoalsTable--comment-indicator">
                    <CommentIndicator
                      count={_.get(goal, "number_of_comments", 0)}
                    />
                  </div>
                )}
              </div>
            )
          })}
        />
      </InfiniteScroll>
    )
  }

  renderEmptyState() {
    const { emptyState } = this.props
    const {
      searchParams: { query, jobTitleId, status },
    } = this.state

    if (emptyState) {
      return emptyState
    } else if (query && query.length) {
      return (
        <SearchEmptyState
          query={query}
          ctaText={<FormattedMessage {...strings.goals.seeAllGoals} />}
          onCtaClick={this.resetFilters}
        />
      )
    } else if (jobTitleId || status) {
      return (
        <EmptyState
          imgSrc={emptyTeamGoalsImg}
          header={
            <FormattedMessage {...strings.goals.emptySearchResultsMessage} />
          }
          ctaText={<FormattedMessage {...strings.goals.seeAllGoals} />}
          ctaProps={{ onClick: this.resetFilters }}
        />
      )
    } else {
      return (
        <EmptyState
          imgSrc={emptyTeamGoalsImg}
          header={<FormattedMessage {...strings.goals.emptyGoalsMessage} />}
          body={<FormattedMessage {...strings.goals.emptyGoalsBody} />}
        />
      )
    }
  }

  renderDateRangeSubheader() {
    const {
      searchParams: { startDate, endDate },
    } = this.state
    return (
      <div className="GoalsTable--date-range-subheader">
        <Box mb={1}>
          <GoalDateRangePicker
            targetRange={[startDate, endDate]}
            onTargetRangeChange={this.handleDateParamChange}
          />
        </Box>
      </div>
    )
  }

  render() {
    const {
      className,
      searchResults,
      stats,
      loadManagerOptions,
      loadJobTitleOptions,
      loadDepartmentOptions,
      adminView,
      tableOnly,
      loadManagerById,
      loadJobTitleById,
    } = this.props
    const filters = this.state.searchParams

    if (!searchResults) {
      return null
    }

    return (
      <div className={`GoalsTable ${className}`}>
        {!tableOnly && this.renderDateRangeSubheader()}

        {!tableOnly && (
          <TeamFilterBar
            className="GoalsTable--filter-bar"
            filters={{
              ...filters,
              jobTitle: filters.jobTitleId,
            }}
            loadManagers={loadManagerOptions}
            loadManagerById={loadManagerById}
            loadJobTitles={loadJobTitleOptions}
            loadJobTitleById={loadJobTitleById}
            loadDepartments={loadDepartmentOptions}
            onChange={this.handleFiltersChange}
            supportsGroupType={true}
            requireManagerForGroupType={adminView}
          />
        )}

        {!tableOnly && stats && (
          <BucketSummary
            sections={[
              {
                buckets: [
                  {
                    id: "created",
                    title: (
                      <FormattedMessage {...strings.goals.status.created} />
                    ),
                    count: stats.created,
                  },
                  {
                    id: "blocked",
                    title: (
                      <FormattedMessage {...strings.goals.status.blocked} />
                    ),
                    count: stats.blocked,
                  },
                  {
                    id: "ongoing",
                    title: (
                      <FormattedMessage {...strings.goals.status.ongoing} />
                    ),
                    count: stats.ongoing,
                  },
                  {
                    id: "accomplished",
                    title: (
                      <FormattedMessage
                        {...strings.goals.status.accomplished}
                      />
                    ),
                    count: stats.accomplished,
                  },
                ],
                totalCount:
                  stats.created +
                  stats.blocked +
                  stats.ongoing +
                  stats.accomplished,
                denominationMsgDescriptor: strings.goals.numOfGoals,
              },
            ]}
            onSelectBucket={this.handleBucketChange}
            selectedBucketId={filters.status}
          />
        )}

        {searchResults.length ? (
          this.renderGoalsTable()
        ) : (
          <div className="GoalsTable--empty-state flex layout vertical center-center">
            {this.renderEmptyState()}
          </div>
        )}
      </div>
    )
  }
}
