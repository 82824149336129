import React from "react"
import FeatureFlags from "../../constants/featureFlags"
import User from "../../models/User"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"

type Props = {
  children: React.ReactElement | React.ReactElement[]
  flag: FeatureFlags[]
}

type ChildProps = {
  children?: React.ReactNode
  featureEnabled?: boolean
}

const Disabled = ({ children, featureEnabled }: ChildProps) => {
  return featureEnabled ? null : <>{children}</>
}

const Enabled = ({ children, featureEnabled }: ChildProps) => {
  return featureEnabled ? <>{children}</> : null
}

export const FeatureSwitch = ({ children, flag }: Props) => {
  const currentUser = useCurrentUser()
  const featureEnabled =
    flag.filter((f) => User.hasFlag(currentUser, f)).length === flag.length

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { featureEnabled })
      )}
    </>
  )
}

FeatureSwitch.Disabled = Disabled
FeatureSwitch.Enabled = Enabled
