import {
  EvaluationCycle,
  EvaluationCycleQuestion,
  EvaluationCycleListingItem,
} from "../../types/EvaluationCycle"
import { LocalBucket } from "../../types/PerformanceCycle"

export const mockPeerQuestions: EvaluationCycleQuestion[] = [
  {
    id: 233,
    type: "open-ended",
    title:
      "What are a few things you have observed them excelling at that they should continue? (Please describe the specific situation, behavior, and the impact they had)",
    required: true,
  },
  {
    id: 234,
    type: "multiple-choice",
    title: "This is a multiple choice question for peer feedback",
    choices: [
      {
        title: "This is choice A",
        value: 1,
      },
      {
        title: "This is choice B",
        value: 2,
      },
      {
        title: "This is choice C",
        value: 3,
      },
      {
        title: "This is choice D",
        value: 4,
      },
    ],
    required: true,
    description:
      "And here is the description for that multiple choice question for peer feedback",
  },
  {
    id: 235,
    type: "rating",
    title: "And here is a rating scaled question for peer feedback",
    required: true,
    choices: [
      {
        title: "Very poor",
        description: "This is a very poor description for peer feedback",
        value: 1,
      },
      {
        title: "Poor",
        description: "",
        value: 2,
      },
      {
        title: "Average",
        description: "",
        value: 3,
      },
      {
        title: "Great!",
        description: "",
        value: 4,
      },
    ],
    description:
      "This is a description for that rating scaled question for peer feedback",
  },
]

export const mockUpwardQuestions: EvaluationCycleQuestion[] = [
  {
    id: 333,
    type: "open-ended",
    title: "What do you love about this person?",
    required: true,
  },
  {
    id: 334,
    type: "multiple-choice",
    title: "This is another multiple choice question",
    choices: [
      {
        title: "This is choice A for an upward feedback",
        value: 1,
      },
      {
        title: "This is choice B for an upward feedback",
        value: 2,
      },
      {
        title: "This is choice C for an upward feedback",
        value: 3,
      },
      {
        title: "This is choice D for an upward feedback",
        value: 4,
      },
    ],
    required: true,
    description:
      "And here is the description for that multiple choice question for upward feedback",
  },
  {
    id: 335,
    type: "rating",
    title: "And here is a rating scaled question for upward feedback",
    required: true,
    choices: [
      {
        title: "Very poor",
        description: "This is a very poor description for upward feedback",
        value: 1,
      },
      {
        title: "Poor",
        description: "",
        value: 2,
      },
      {
        title: "Average",
        description: "",
        value: 3,
      },
      {
        title: "Great!",
        description: "",
        value: 4,
      },
    ],
    description:
      "This is a description for that rating scaled question for upward feedback",
  },
]

export const mockManagerEvaluationQuestions: EvaluationCycleQuestion[] = [
  {
    id: 905,
    title:
      "What did this person achieve over the last 6 months? What specific impact did they have on the team’s goals?",
    type: "open-ended",
    can_share: true,
    required: true,
    description:
      "Take some time to review their self-reflection, as well as progress made toward goals this quarter. Include any relevant feedback you've received from colleagues about this individual.",
  },
  {
    id: 918,
    title: "This is a multiple choice question",
    choices: [
      { value: 1, title: "Option A" },
      { value: 2, title: "Option B" },
      { value: 3, title: "Option C" },
      { value: 4, title: "Option D" },
    ],
    type: "multiple-choice",
    can_share: false,
    required: true,
    description: "This is a description for that multiple choice question",
  },
  {
    id: 919,
    title: "Overall, how would you evaluate this person's performance?",
    type: "performance-buckets",
    can_share: false,
    required: true,
    description: "This is a description for an assessment group",
    choices: [
      {
        title: "Exceptional",
        description: "Always Exceeds Expectations",
        value: 6,
      },
      {
        title: "Above Average",
        description: "Often Exceeds Expectations",
        value: 5,
      },
      {
        title: "Average",
        description: "",
        value: 2,
      },
      {
        title: "Below Average",
        description: "Sometimes Misses Expectations",
        value: 1,
      },
    ],
  },
]

export const mockSelfReviewQuestions: EvaluationCycleQuestion[] = [
  {
    id: 1905,
    title:
      "What are your most notable achievements over the last 6 months? What specific impact did you have on your team’s goals?",
    type: "open-ended",
    required: true,
    description: "description.",
  },
  {
    id: 1906,
    title:
      "What are 2-3 skills or competencies where you'd like to focus further development? Describe ways you plan to get there.",
    type: "open-ended",
    required: true,
    description: "description.",
  },
  {
    id: 1918,
    title: "self review default multiple choice question 2",
    choices: [
      { value: 1, title: "Option A" },
      { value: 2, title: "Option B" },
      { value: 3, title: "Option C" },
      { value: 4, title: "Option D" },
    ],
    type: "multiple-choice",
    required: true,
    description: "This is a description for that multiple choice question",
  },
]

export const mockCycleDraft: EvaluationCycle = {
  id: 1234,
  name: "This is a cycle",
  subject_ids: [
    14, // Philippe
    12, // SK
    34, // Abraham
  ],
  timezone: "Australia/Perth",
  modules: {
    peer_and_upward_feedback: {
      reviewer_selection_start_date: "2020-06-19T02:53:28.000Z",
      reviewer_selection_end_date: "2020-06-24T02:53:28.000Z",
      completion_start_date: "2020-06-22T02:53:28.000Z",
      completion_end_date: "2020-06-26T02:53:28.000Z",
      is_included: true,
      state: "draft",
      peer_feedback_questions: mockPeerQuestions,
      upward_feedback_questions: mockUpwardQuestions,
      peer_nomination_cycle: {
        is_included: true,
        start_date: "2020-02-17T02:53:28.000Z",
        end_date: "2020-02-22T02:53:28.000Z",
      },
    },
    manager_evaluation: {
      id: 5678,
      start_date: "2020-06-24T02:53:28.000Z",
      end_date: "2020-06-25T02:53:28.000Z",
      is_included: true,
      state: "draft",
      can_share_performance_review: false,
      questions: mockManagerEvaluationQuestions,
    },
    self_review: {
      id: 1234,
      start_date: "2020-02-29T02:53:28.000Z",
      end_date: "2020-03-25T02:53:28.000Z",
      is_included: true,
      state: "draft",
      questions: mockSelfReviewQuestions,
    },
  },
  state: "draft",
}

export const mockCycleScheduled: EvaluationCycle = {
  ...mockCycleDraft,
  id: 1235,
  name: "This is a scheduled cycle",
  state: "ready",
  modules: {
    peer_and_upward_feedback: {
      ...mockCycleDraft.modules.peer_and_upward_feedback,
      state: "ready",
    },
    manager_evaluation: {
      ...mockCycleDraft.modules.manager_evaluation,
      id: 80, // An ID of a performance cycle taken from my local machine. Feel free to an ID on yours
      state: "ready",
    },
    self_review: {
      ...mockCycleDraft.modules.self_review,
      id: 82,
      state: "ready",
    },
  },
}

export const mockCycleActive: EvaluationCycle = {
  ...mockCycleDraft,
  id: 1236,
  name: "This is an active cycle",
  state: "active",
  modules: {
    peer_and_upward_feedback: {
      ...mockCycleDraft.modules.peer_and_upward_feedback,
      state: "active",
    },
    manager_evaluation: {
      ...mockCycleDraft.modules.manager_evaluation,
      id: 50, // An ID of a performance cycle taken from my local machine. Feel free to an ID on yours
      state: "active",
    },
    self_review: {
      ...mockCycleDraft.modules.self_review,
      id: 82,
      state: "active",
    },
  },
}

export const mockCycleClosed: EvaluationCycle = {
  ...mockCycleDraft,
  id: 1237,
  name: "This is an ended cycle",
  state: "closed",
  modules: {
    peer_and_upward_feedback: {
      ...mockCycleDraft.modules.peer_and_upward_feedback,
      state: "closed",
    },
    manager_evaluation: {
      ...mockCycleDraft.modules.manager_evaluation,
      id: 50, // An ID of a performance cycle taken from my local machine. Feel free to an ID on yours
      state: "closed",
    },
    self_review: {
      ...mockCycleDraft.modules.self_review,
      id: 82,
      state: "closed",
    },
  },
}

export const mockCycleNoManagerEval: EvaluationCycle = {
  ...mockCycleScheduled,
  id: 1238,
  name: "This is cycle with no manager evaluation",
  state: "ready",
  modules: {
    ...mockCycleScheduled.modules,
    manager_evaluation: {
      is_included: false,
    },
  },
}

export const mockCyclePartiallyActive: EvaluationCycle = {
  ...mockCycleDraft,
  id: 1239,
  name: "This is a partially active cycle",
  state: "active",
  modules: {
    peer_and_upward_feedback: {
      ...mockCycleDraft.modules.peer_and_upward_feedback,
      state: "active",
    },
    manager_evaluation: {
      ...mockCycleDraft.modules.manager_evaluation,
      id: 50, // An ID of a performance cycle taken from my local machine. Feel free to an ID on yours
      state: "ready",
    },
    self_review: {
      ...mockCycleDraft.modules.self_review,
      state: "active",
    },
  },
}

export const mockCycleActiveNotSharable: EvaluationCycle = {
  ...mockCycleActive,
  id: 1240,
  name: "This is an active cycle, but no questions are sharable",
  modules: {
    ...mockCycleActive.modules,
    manager_evaluation: {
      ...mockCycleActive.modules.manager_evaluation,
      id: 50, // An ID of a performance cycle taken from my local machine. Feel free to an ID on yours
      state: "active",
      questions: mockManagerEvaluationQuestions.map((q) => ({
        ...q,
        can_share: false,
      })),
    },
  },
}

export const mockDefaultBuckets: LocalBucket[] = [
  {
    title: "Exceptional (default value)",
    description: "Always Exceeds Expectations (default value)",
    value: 6,
  },
  {
    title: "Above Average (default value)",
    description: "Often Exceeds Expectations (default value)",
    value: 5,
  },
  {
    title: "Average (default value)",
    description: "(default value)",
    value: 2,
  },
  {
    title: "Below Average (default value)",
    description: "Sometimes Misses Expectations (default value)",
    value: 1,
  },
]

export const cycleToListingItem = (
  cycle: EvaluationCycle
): EvaluationCycleListingItem => ({
  id: cycle.id as number,
  name: cycle.name,
  start_date: "2018-02-08T01:00:00.000Z",
  end_date: "2018-02-13T01:00:00.000Z",
  state: cycle.state,
  completion_stats: {
    completed: 39,
    total: 45,
  },
  modules: {
    manager_evaluation: {
      is_included: cycle.modules.manager_evaluation.is_included,
      id: cycle.modules.manager_evaluation.id,
      state: cycle.modules.manager_evaluation.state,
    },
    peer_and_upward_feedback: {
      is_included: cycle.modules.peer_and_upward_feedback.is_included,
      state: cycle.modules.peer_and_upward_feedback.state,
      peer_nomination_cycle: {
        is_included:
          cycle.modules.peer_and_upward_feedback.peer_nomination_cycle
            .is_included,
      },
    },
    self_review: {
      is_included: cycle.modules.self_review.is_included,
      state: cycle.modules.self_review.state,
    },
  },
})

export const allMockCycles: EvaluationCycle[] = [
  mockCycleDraft,
  mockCycleScheduled,
  mockCycleActive,
  mockCycleClosed,
  mockCycleNoManagerEval,
  mockCyclePartiallyActive,
  mockCycleActiveNotSharable,
]

export const mockCycleListings: EvaluationCycleListingItem[] = allMockCycles.map(
  cycleToListingItem
)
