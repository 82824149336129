import { injectIntl, InjectedIntl } from "react-intl"
import { CheckboxField } from "@kaizen/draft-form"
import { Text } from "@kaizen/component-library/components"
import * as React from "react"
import { useState } from "react"
import classnames from "classnames"
import strings from "../../locale/strings"
import Tabs from "../Tabs/Tabs"
import styles from "./AlignableGoals.scss"
import FiltersBar, { Filters } from "../../components/FiltersBar/FiltersBar"
import useAlignableGoals from "../../domainHooks/goals/useAlignableGoals"
import Loading from "../../components/Loading/Loading"
import { trackAlignableGoalComponentSearched } from "../../utils/analytics/events"

const defaultFilters = {
  all_values: false,
  q: "",
}

type AlignableGoalsType = {
  type: string
  alignableType: string
  tabText: ReactIntl.FormattedMessage.MessageDescriptor
  searchPlaceholder: ReactIntl.FormattedMessage.MessageDescriptor
  emptyStateTitle: {
    mine: ReactIntl.FormattedMessage.MessageDescriptor
    all: ReactIntl.FormattedMessage.MessageDescriptor
  }
  emptyStateDescription: {
    mine: ReactIntl.FormattedMessage.MessageDescriptor
    all: ReactIntl.FormattedMessage.MessageDescriptor
  }
}

type GoalType = "teamGoal" | "departmentGoal" | "companyGoal"

const teamGoal: AlignableGoalsType = {
  type: "team_goal",
  alignableType: "Goals::TeamGoal",
  tabText: strings.teamGoalForm.alignableGoals.tabs.team,
  searchPlaceholder: strings.teamGoalForm.alignableGoals.filter.searchTeamGoals,
  emptyStateTitle: {
    mine: strings.teamGoalForm.alignableGoals.emptyState.team.mine.title,
    all: strings.teamGoalForm.alignableGoals.emptyState.team.all.title,
  },
  emptyStateDescription: {
    mine:
      strings.teamGoalForm.alignableGoals.emptyState.team.mine.descriptionText,
    all:
      strings.teamGoalForm.alignableGoals.emptyState.team.all.descriptionText,
  },
}

const departmentGoal: AlignableGoalsType = {
  type: "department_goal",
  alignableType: "Goals::DepartmentGoal",
  tabText: strings.teamGoalForm.alignableGoals.tabs.department,
  searchPlaceholder:
    strings.teamGoalForm.alignableGoals.filter.searchDepartmentGoals,
  emptyStateTitle: {
    mine: strings.teamGoalForm.alignableGoals.emptyState.department.mine.title,
    all: strings.teamGoalForm.alignableGoals.emptyState.department.all.title,
  },
  emptyStateDescription: {
    mine:
      strings.teamGoalForm.alignableGoals.emptyState.department.mine
        .descriptionText,
    all:
      strings.teamGoalForm.alignableGoals.emptyState.department.all
        .descriptionText,
  },
}

const companyGoal: AlignableGoalsType = {
  type: "company_goal",
  alignableType: "Goals::CompanyGoal",
  tabText: strings.teamGoalForm.alignableGoals.tabs.company,
  searchPlaceholder:
    strings.teamGoalForm.alignableGoals.filter.searchCompanyGoals,
  emptyStateTitle: {
    mine: strings.teamGoalForm.alignableGoals.emptyState.company.title,
    all: strings.teamGoalForm.alignableGoals.emptyState.company.title,
  },
  emptyStateDescription: {
    mine:
      strings.teamGoalForm.alignableGoals.emptyState.company.descriptionText,
    all: strings.teamGoalForm.alignableGoals.emptyState.company.descriptionText,
  },
}

const goalTypes = { teamGoal, departmentGoal, companyGoal }

interface AlignableGoalsProps {
  currentGoalId?: number
  alignedGoalIds: number[]
  onAdd: (id: number, goalType?: GoalType) => void
  onRemove: (id: number) => void
  disabled?: boolean
  hideTeams?: boolean
  hideDepartments?: boolean
  initGoalType?: GoalType
  intl: InjectedIntl
}

const AlignableGoals = ({
  currentGoalId,
  alignedGoalIds,
  onAdd,
  onRemove,
  disabled,
  intl,
  initGoalType = "companyGoal",
  hideTeams = false,
  hideDepartments = false,
}: AlignableGoalsProps) => {
  const [activeGoalType, setActiveGoalType] = useState<AlignableGoalsType>(
    goalTypes[initGoalType]
  )

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const { goals: alignableGoals, loading } = useAlignableGoals({
    sourceGoalId: currentGoalId,
    targetGoalType: activeGoalType.alignableType,
    all_values: !!filters.all_values,
    query: filters.q?.toString(),
  })

  return (
    <div className={styles.container}>
      <Text tag="h3" style="zen-heading-3">
        {intl.formatMessage(strings.teamGoalForm.alignableGoals.title)}
      </Text>
      <Tabs
        tabs={[
          !hideTeams && {
            label: intl.formatMessage(goalTypes.teamGoal.tabText),
            onClick: () => setActiveGoalType(goalTypes.teamGoal),
            active: activeGoalType.type === goalTypes.teamGoal.type,
          },
          !hideDepartments && {
            label: intl.formatMessage(goalTypes.departmentGoal.tabText),
            onClick: () => setActiveGoalType(goalTypes.departmentGoal),
            active: activeGoalType.type === goalTypes.departmentGoal.type,
          },
          {
            label: intl.formatMessage(goalTypes.companyGoal.tabText),
            onClick: () => setActiveGoalType(goalTypes.companyGoal),
            active: activeGoalType.type === goalTypes.companyGoal.type,
          },
        ]}
      />
      {
        <div className={styles.list}>
          <div>
            <FiltersBar
              key={`key-${activeGoalType.type}`}
              onFiltersChange={setFilters}
              defaultFilters={defaultFilters}
            >
              {activeGoalType.type !== goalTypes.companyGoal.type && (
                <div className={classnames([styles.filter])}>
                  <FiltersBar.Select
                    name="all_values"
                    options={[
                      {
                        value: false,
                        label: intl.formatMessage(
                          activeGoalType.type === goalTypes.teamGoal.type
                            ? strings.teamGoalForm.alignableGoals.filter.myTeams
                            : strings.teamGoalForm.alignableGoals.filter
                                .myDepartment
                        ),
                      },
                      {
                        value: true,
                        label: intl.formatMessage(
                          activeGoalType.type === goalTypes.teamGoal.type
                            ? strings.teamGoalForm.alignableGoals.filter
                                .allTeams
                            : strings.teamGoalForm.alignableGoals.filter
                                .allDepartments
                        ),
                      },
                    ]}
                  />
                </div>
              )}
              <div className={classnames([styles.filter, styles.filterRight])}>
                <FiltersBar.Search
                  name="q"
                  placeholder={intl.formatMessage(
                    activeGoalType.searchPlaceholder
                  )}
                  searchTracking={trackAlignableGoalComponentSearched}
                />
              </div>
            </FiltersBar>

            {loading ? (
              <Loading />
            ) : (
              <div className={styles.table}>
                {alignableGoals.length > 0 ? (
                  alignableGoals.map((goal) => {
                    const checked = alignedGoalIds.includes(goal.id)
                    const status = checked ? "on" : "off"
                    return (
                      <div key={goal.id} className={styles.goal}>
                        <div
                          key={`checkbox-container-${goal.id}`}
                          className={styles.checkboxContainer}
                        >
                          <CheckboxField
                            id={`checkbox-${goal.id}`}
                            labelText={goal.name}
                            onCheck={() => {
                              checked ? onRemove(goal.id) : onAdd(goal.id)
                            }}
                            noBottomMargin={true}
                            checkedStatus={status}
                            disabled={disabled}
                          ></CheckboxField>
                        </div>
                        {activeGoalType.type !== goalTypes.companyGoal.type && (
                          <Text tag="p" inheritBaseline>
                            {goal.description}
                          </Text>
                        )}
                      </div>
                    )
                  })
                ) : (
                  <div className={styles.emptyState}>
                    <Text tag="h3" style="zen-heading-3">
                      {intl.formatMessage(
                        activeGoalType.emptyStateTitle[
                          filters["all_values"] ? "all" : "mine"
                        ]
                      )}
                    </Text>
                    <Text tag="p" inheritBaseline>
                      {intl.formatMessage(
                        activeGoalType.emptyStateDescription[
                          filters["all_values"] ? "all" : "mine"
                        ]
                      )}
                    </Text>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}

export default injectIntl(AlignableGoals)
