import "./SearchEmptyState.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import EmptyState from "../EmptyState/EmptyState"
import strings from "../../locale/strings"
import emptyStateImg from "../../static/images-2017/drawings/emptystates/negative.png"

export default class SearchEmptyState extends React.Component {
  static propTypes = {
    query: PropTypes.string,
    ctaText: PropTypes.node.isRequired,
    onCtaClick: PropTypes.func.isRequired,
  }

  render() {
    const { query, ctaText, onCtaClick } = this.props

    return (
      <EmptyState
        imgSrc={emptyStateImg}
        header={
          <FormattedMessage
            {...strings.search.emptyStateHeader}
            values={{
              query: <span className="SearchEmptyState--query">{query}</span>,
            }}
          />
        }
        body={<FormattedMessage {...strings.search.emptyStateBody} />}
        ctaText={ctaText}
        ctaProps={{
          onClick: onCtaClick,
        }}
      />
    )
  }
}
