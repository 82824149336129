import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  extractResponseBody,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"
import EmployeeGrouping from "../models/EmployeeGrouping"

const { GENERAL_URLS } = endpoints
const { JOB_TITLES_URL, DEPARTMENTS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance

const CompanyActions = Reflux.createActions({
  suggestEmployeeGroupings: { asyncResult: true },
  listEmployeeGroupings: { asyncResult: true },
  getJobTitles: { asyncResult: true },
  getJobTitleById: { asyncResult: true },
  getDepartments: { asyncResult: true },
})

CompanyActions.suggestEmployeeGroupings.listenAndPromise(
  ({ q, ids, type, page = 1, distinct_by, paginate }) => {
    const request = agent.get(JOB_TITLES_URL).query({
      q,
      ids: ids && ids.join(","),
      job_title_type: type || undefined,
      distinct_by: distinct_by,
      ...(paginate ? { page, per_page: 20 } : {}),
    })

    return paginate
      ? extractResponseBody(request).then(({ job_titles, meta }) => ({
          results: job_titles,
          meta,
        }))
      : extractResponseKey("job_titles", request)
  }
)

CompanyActions.getJobTitleById.listenAndPromise((jobTitleId) =>
  extractResponseKey("job_title", agent.get(`${JOB_TITLES_URL}/${jobTitleId}`))
)

CompanyActions.getJobTitles.listenAndPromise(({ parentJobTitleId }) =>
  addLoadingState(
    true,
    extractResponseKey(
      "job_titles",
      agent.get(JOB_TITLES_URL).query({
        job_title_type: "job_title",
        distinct_by: "title",
        parent_job_title_id: parentJobTitleId || undefined,
      })
    )
  )
)

CompanyActions.listEmployeeGroupings.listenAndPromise(() =>
  extractResponseKey(
    "job_titles",
    EmployeeGrouping.of,
    agent.get(JOB_TITLES_URL).query({
      per_page: 1e9,
    })
  )
)

CompanyActions.getDepartments.listenAndPromise(() =>
  extractResponseKey(
    "departments",
    agent.get(DEPARTMENTS_URL).query({ per_page: 1e9 })
  )
)

export default CompanyActions
