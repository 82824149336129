import * as React from "react"
import { useCallback, useState } from "react"
import { WithRouterProps } from "react-router"
import { InlineNotification } from "@kaizen/component-library"
import { logEvent } from "@cultureamp/amplitude"
import Loading from "../../components/Loading/Loading"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import usePersonalGoals from "../../domainHooks/goals/usePersonalGoals"
import ScrollContainer from "../../components/ScrollContainer/ScrollContainer"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import GoalsTableHeader from "../../components/GoalsTableHeader/GoalsTableHeader"
import GoalsTable from "../../containers/GoalsTable/GoalsTable"
import strings from "../../locale/strings"
const { emptyState } = strings.personalGoalsList
import GoalsEmptyState from "../../containers/GoalsEmptyState/GoalsEmptyState"
import FiltersBar, { Filters } from "../../components/FiltersBar/FiltersBar"
import styles from "./PersonalGoalsList.scss"
import { useIntl } from "../../hooks/locale/useIntl"
import Aid from "../../constants/automationId"

export type PersonalGoalsListProps = React.FunctionComponent<WithRouterProps>

const datePickerFilterName = (index: number) => (index === 0 ? "from" : "to")

const PersonalGoalsList: PersonalGoalsListProps = ({ router, location }) => {
  const currentUser = useCurrentUser()
  const { open: openGoalDossier } = useGoalDossier(router, location)
  const { formatMessage } = useIntl()
  const defaultFilters = {
    from: undefined,
    to: undefined,
  }
  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const {
    personalGoals,
    loading,
    goalStats,
    fetchMore: fetchMorePersonalGoals,
    hasMore: hasMorePersonalGoals,
  } = usePersonalGoals(filters)

  React.useEffect(() => {
    // @ts-ignore
    logEvent("Individual Goals Index Viewed")
  }, [])

  const handleCreateButtonClick = useCallback(() => {
    router.push("/new_goals/personal/new")
  }, [router])

  return (
    <>
      {currentUser.is_a_manager && (
        <InlineNotification
          title={formatMessage(
            strings.personalGoalsList.lookingForDirectReports
          )}
          type="informative"
          persistent={true}
          automationId={Aid.personalGoalsListLookingForReports}
        >
          {formatMessage(strings.personalGoalsList.youCanView)}{" "}
          <a href="/performance/goals/team">
            {formatMessage(strings.personalGoalsList.viewGoals)}
          </a>
        </InlineNotification>
      )}
      <FiltersBar onFiltersChange={setFilters} defaultFilters={defaultFilters}>
        <div className={styles.filter}>
          <FiltersBar.DatePicker name={datePickerFilterName} allowDateRange />
        </div>
      </FiltersBar>
      <ScrollContainer
        onScrolledToBottom={() =>
          hasMorePersonalGoals && fetchMorePersonalGoals()
        }
      >
        {loading && !hasMorePersonalGoals ? (
          <Loading />
        ) : personalGoals.length > 0 ? (
          <React.Fragment>
            <GoalsTableHeader goalType={"personal"} />
            <GoalsTable
              titleName={currentUser.best_name}
              goals={personalGoals}
              goalsCount={goalStats.goalCount}
              goalProgress={Math.floor(
                (personalGoals.reduce(
                  (progress, goal) => progress + goal.completion,
                  0
                ) /
                  goalStats.goalCount) *
                  100
              )}
              openGoal={(goalId) => openGoalDossier(goalId)}
              loading={loading && hasMorePersonalGoals}
            />
          </React.Fragment>
        ) : (
          <GoalsEmptyState
            headingText={emptyState.headingText}
            bodyText={emptyState.bodyText}
            createGoalAction={handleCreateButtonClick}
          />
        )}
      </ScrollContainer>
    </>
  )
}

export default PersonalGoalsList
