import { JsonDecoder } from "ts.data.json"
import { TeamSummary } from "../../../context/TeamSummariesState"
import paginationDecoder, {
  Pagination,
} from "../PaginationDecoder/paginationDecoder"
import { teamSummaryDecoder } from "../TeamSummary/teamSummary"

type ResponseType = {
  teams: TeamSummary[]
  meta: {
    pagination: Pagination
  }
}

export const getTeamSummariesResponseDecoder = JsonDecoder.object<ResponseType>(
  {
    teams: JsonDecoder.array(teamSummaryDecoder, "teamSummaries"),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder,
      },
      "meta"
    ),
  },
  "getTeamSummariesResponse"
).map(({ teams, meta: { pagination } }) => ({
  teamSummaries: teams,
  pagination,
}))
