import * as React from "react"

// Just say you have a div with multiple textfields and focusable components.
// And you want to have a `onBlur` and an `onFocus` callback for that div,
// so if any child element is focused, an `onFocus` is called, and the `onBlur`
// callback won't be called until none of the child elements are focused anymore.
// This HOC allows you to do this.
// The only thing is that you need to make sure every child focusable field
// has the supplied `funnelOnFocus` and `funnelOnBlur` added.
export const withFocusFunnel = <P,>(
  WrappedComponent: React.ComponentType<
    P & {
      funnelOnFocus: (e: React.FocusEvent) => void
      funnelOnBlur: (e: React.FocusEvent) => void
    }
  >
  // I'm not sure why this Omit was required...
): React.ComponentType<
  Omit<P, "funnelOnFocus" | "funnelOnBlur"> & {
    onBlur?: () => void
    onFocus?: () => void
    // As a quick fix, I've allowed the consumer of the parent to override the
    // funnelOnFocus and funnelOnBlur callbacks.
    // Use case: The EditableBuckets component, uses this `withFocusFunnel` HOC.
    // It also contains `EditableAnswerChoice` as a child, which also uses this
    // `withFocusFunnel` HOC. The funnelOnFocus/Blur callbacks require a dom input
    // element to reference. This allows us to inject the funnelled callbacks
    // into the grand children.
    funnelOnFocus?: (e: React.FocusEvent) => void
    funnelOnBlur?: (e: React.FocusEvent) => void
  }
> => {
  class WithFocusFunnel extends React.PureComponent<
    P & {
      onBlur?: () => void
      onFocus?: () => void
    }
  > {
    _focusedItem: EventTarget | null = null
    _blurAnimationFrame: number | null = null

    componentWillUnmount() {
      if (this._blurAnimationFrame) {
        cancelAnimationFrame(this._blurAnimationFrame)
      }
    }

    _handleFocus = (e: React.FocusEvent) => {
      const { onFocus } = this.props
      if (!this._focusedItem && onFocus) {
        onFocus()
      }
      this._focusedItem = e.target
    }

    _handleBlur = (e: React.FocusEvent) => {
      const { onBlur } = this.props
      e.persist()
      this._blurAnimationFrame = requestAnimationFrame(() => {
        // ie. we haven't focused on another item inside this BaseEditableQuestion component
        if (this._focusedItem === e.target) {
          if (onBlur) {
            onBlur()
          }
          this._focusedItem = null
        }
      })
    }

    render() {
      return (
        <WrappedComponent
          funnelOnFocus={this._handleFocus}
          funnelOnBlur={this._handleBlur}
          {...this.props}
        />
      )
    }
  }

  // @ts-ignore: Blah! It's fine!
  return WithFocusFunnel
}
