import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../refluxActions/index"

const FeedbackStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function () {
    this.initializeData()

    this.listenTo(
      Actions.Feedback.loadReview.completed,
      this.onSingleLoadCompleted
    )
    this.listenTo(Actions.Feedback.loadReviews.completed, this.onLoadCompleted)
    this.listenTo(Actions.Feedback.shareReview.completed, this.onReviewShared)
    this.listenTo(Actions.Feedback.buy.completed, this.onBuyCompleted)
    this.listenTo(Actions.Feedback.buy.failed, this.onBuyFailed)
    this.listenTo(Actions.Feedback.comment.completed, this.onReviewChange)
    this.listenTo(
      Actions.Feedback.updateReactions.completed,
      this.onReviewChange
    )

    this.listenTo(Actions.Feedback.localClear, () => {
      this.initializeData()
      this.trigger(this.data)
    })
  },

  initializeData: function () {
    this.data = {
      reviews: null,
      review: null,
      pagination: null,
    }
  },

  getInitialState: function () {
    return this.data
  },

  onSingleLoadCompleted: function (newReview) {
    const review = this.data

    if (review && review.id === newReview.id) {
      this.data = { ...this.data, review: { ...review, ...newReview } }
    } else {
      this.data = { ...this.data, review: newReview }
    }

    if (this.data.reviews) {
      this.updateReviews(newReview)
    }
    this.trigger(this.data)
  },

  onReviewShared: function (reviewId) {
    // We reload the review after sharing to refresh
    // the "sharee_ids" property of the review,
    // i.e. the list of IDs of people the review
    // has been shared with.
    Actions.Feedback.loadReview(reviewId)
  },

  onLoadCompleted: function ({ reviews, meta }) {
    Actions.UI.setLoadingState(false)
    const pagination = meta.pagination

    if (pagination && pagination.current_page > 1) {
      const oldReviews = this.data.reviews

      // REVIEW: based on how this store currently works, it assumes pages are loaded in order,
      // starting with the first. So if we receive a subsequent page and `this.data.reviews` is null,
      // ignore it.
      // TODO: adapt this store to use observeSearchActions
      //
      if (oldReviews) {
        this.data = _.assign({}, this.data, {
          reviews: oldReviews.concat(reviews),
          pagination: pagination,
        })
      }
    } else {
      this.data = _.assign({}, this.data, { reviews, pagination })
    }
    this.trigger(this.data)
  },

  onReviewChange: function (updatedReview) {
    if (_.get(this.data.review, "id") === updatedReview.id) {
      this.data = { ...this.data, review: updatedReview }
    }

    if (this.data.reviews) {
      this.updateReviews(updatedReview)
    }

    this.trigger(this.data)
  },

  onBuyCompleted(boughtReview) {
    this.updateBuyStatus(boughtReview, true)
  },

  onBuyFailed(buyFailedReview) {
    this.updateBuyStatus(buyFailedReview, false)
  },

  updateReviews(review) {
    if (this.data.reviews) {
      this.data = {
        ...this.data,
        reviews: this.data.reviews.map((r) =>
          r.id === review.id ? review : r
        ),
      }
    }
  },

  updateBuyStatus(updatedReview, paid_for) {
    if (_.get(this.data.review, "id") === updatedReview.id) {
      this.data.review = { ...this.data.review, paid_for, buyAttempted: true }
    }

    if (this.data.reviews) {
      this.data = {
        ...this.data,
        reviews: this.data.reviews.map((review) =>
          review.id === updatedReview.id
            ? { ...review, paid_for, buyAttempted: true }
            : review
        ),
      }
    }

    this.trigger(this.data)
  },
})

export default FeedbackStore
