import Reflux from "reflux-core"
import Slack from "slack-api"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import Settings from "../settings"
import UIActions from "./UIActions"
import XSRFProtector from "../utils/XSRFProtector"
import endpoints from "../constants/endpointsDeprecated"
import strings from "../locale/strings"
import browserSubdomainRedirector from "../utils/SubdomainRedirector"

const { GENERAL_URLS } = endpoints
const { EMAIL_COLLECTIONS_URL } = GENERAL_URLS
const agent = SteadyfootAgent.defaultInstance
const ASYNC_SETTINGS = {
  children: ["completed", "failed"],
}
const TWO_MONTHS = 60

const xsrfProtector = new XSRFProtector()

const ImportActions = Reflux.createActions({
  collect: ASYNC_SETTINGS,
})

ImportActions.Exchange = Reflux.createActions({
  getAccessToken: ASYNC_SETTINGS,
  startWorkflow: {},
  oauthCollect: ASYNC_SETTINGS,
  collect: ASYNC_SETTINGS,
})
ImportActions.Slack = Reflux.createActions({
  getAccessToken: {},
  collect: ASYNC_SETTINGS,
})

ImportActions.Slack.getAccessToken.preEmit = function () {
  const slackUrl = Slack.oauth.getUrl({
    redirect_uri: Settings.SLACK_REDIRECT_URI,
    client_id: Settings.SLACK_CLIENT_ID,
    state: xsrfProtector.establishToken("slack"),
    scope: Settings.SLACK_SCOPES.join(","),
  })

  if (Settings.USE_SUBDOMAINS) {
    browserSubdomainRedirector.storeSubdomain()
  }

  window.location = slackUrl
}

ImportActions.Exchange.collect.preEmit = function (
  credentials,
  collectionDays = TWO_MONTHS
) {
  UIActions.notify(strings.importHelpers.connectingExchangeServer)

  agent
    .post(EMAIL_COLLECTIONS_URL)
    .send({
      email_collection: {
        username: credentials.exchange_email,
        password: credentials.exchange_password,
        days: collectionDays,
        exchange_url: credentials.exchange_url,
        domain: credentials.domain,
        type: "exchange_import",
      },
    })
    .end((err, res) => {
      if (err) {
        ImportActions.Exchange.collect.failed(res && res.body, err)
        ImportActions.collect.failed(res && res.body, err)
      } else {
        ImportActions.Exchange.collect.completed(res.body)
        ImportActions.collect.completed(res.body)
        notifySuccess()
      }
    })
}

ImportActions.Exchange.getAccessTokenFromPopup = function ({ company }) {
  const companyExchangeAppId = company.exchange_app_id

  if (!companyExchangeAppId) {
    return UIActions.error(strings.toast.appIdNotProvided)
  }
  localStorage.setItem("exchange_app_id", companyExchangeAppId)

  const applicationConfig = {
    clientID: companyExchangeAppId,
    authority: Settings.EXCHANGE_AUTHORITY,
    graphScopes: Settings.EXCHANGE_SCOPES,
    graphEndpoint: Settings.EXCHANGE_GRAPH_ENDPOINT,
  }

  // eslint-disable-next-line no-undef
  const userAgentApplication = new Msal.UserAgentApplication(
    applicationConfig.clientID,
    applicationConfig.authority,
    () => {}
  )

  userAgentApplication.loginPopup(applicationConfig.graphScopes).then(
    () => {
      userAgentApplication
        .acquireTokenSilent(applicationConfig.graphScopes)
        .then(
          (accessToken) => {
            ImportActions.Exchange.oauthCollect(
              accessToken,
              userAgentApplication.getUser().displayableId
            )
          },
          (error) => {
            UIActions.error(error)
          }
        )
    },
    (error) => {
      UIActions.error(error)
    }
  )
}

ImportActions.Exchange.oauthCollect.preEmit = function (
  accessToken,
  displayableId
) {
  agent
    .post(EMAIL_COLLECTIONS_URL)
    .send({
      email_collection: {
        oauth_token: accessToken,
        username: displayableId,
        type: "exchange_365",
      },
    })
    .end((err, res) => {
      if (err) {
        ImportActions.Exchange.oauthCollect.failed()
        ImportActions.collect.failed(res && res.body, err)
        notifyFailure(err, res)
      } else {
        ImportActions.Exchange.oauthCollect.completed(res.body)
        ImportActions.collect.completed(res.body)
        notifySuccess()
      }
    })
}

ImportActions.Slack.collect.preEmit = function ({
  accessToken,
  oauthCode,
  oauthState,
  redirectUri,
  collectionDays = TWO_MONTHS,
}) {
  xsrfProtector.checkToken("slack", oauthState)

  if (Boolean(accessToken) === Boolean(oauthCode)) {
    throw new Error("Exactly one of accessToken or oauthCode must be provided")
  }

  if (oauthCode && !redirectUri) {
    throw new Error("redirectUri must accompany oauthCode")
  }

  UIActions.setLoadingState(true)

  agent
    .post(EMAIL_COLLECTIONS_URL)
    .send({
      email_collection: {
        oauth_token: accessToken,
        oauth_code: oauthCode,
        redirect_uri: redirectUri,
        days: collectionDays,
        type: "slack",
      },
    })
    .end((err, res) => {
      UIActions.setLoadingState(false)

      if (err) {
        ImportActions.Slack.collect.failed(res && res.body, err)
        ImportActions.collect.failed(res && res.body, err)
        notifyFailure(err, res)
      } else {
        ImportActions.Slack.collect.completed(res.body)
        ImportActions.collect.completed(res.body)
        notifySuccess()
      }
    })
}

function notifySuccess() {
  UIActions.success(strings.importHelpers.successMessage)
}

function notifyFailure(err, res) {
  let error
  switch (res && res.status) {
    case 429:
      error = res.body && res.body.error
      UIActions.notify(error || strings.importHelpers.alreadyInProgress)
      break
    case 422:
      UIActions.error(res.body.error || strings.importHelpers.errorTryAgain)
      break
    default:
      error = res && res.body && res.body.error
      UIActions.error(error || strings.toast.genericErrorMessage)
  }
}

export default ImportActions
