import "./SharedFeedbackModal.less"
import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import _ from "lodash"
import connect from "../../higherOrderComponents/connect"
import Actions from "../../refluxActions"
import modalDeprecated from "../../higherOrderComponents/modalDeprecated"
import ConversationStore from "../../refluxStores/ConversationStore"
import UserStore from "../../refluxStores/UserStore"
import Loader from "../../components/Loader/Loader"
import ConversationModalWrapper from "../conversations/ConversationModalWrapper"
import Conversation from "../conversations/Conversation"
import ConversationMessage from "../conversations/ConversationMessage"
import MessageGroup from "../conversations/MessageGroup"
import User from "../../models/User"
import ReviewNotesComment from "../ReviewNotesComment/ReviewNotesComment"
import strings from "../../locale/strings"

class SharedFeedbackModal extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  static propTypes = {
    shareId: PropTypes.number.isRequired,
    managerReviewShare: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    this.loadContent()
  }

  getShare({ props } = this) {
    const {
      shareId,
      conversationData: { sourceObjectsByType },
    } = props
    return _.get(sourceObjectsByType.review_share, shareId)
  }

  loadContent() {
    const { shareId, onClose } = this.props

    Actions.Feedback.loadShare({ shareId })
      .then((share) => Actions.User.loadById(share.user_id))
      .catch(() => onClose())

    this.handleRepliesNeeded()
  }

  handleRepliesNeeded = () => {
    const { shareId } = this.props
    Actions.Comments.list({
      sourceObjectId: shareId,
      type: "review_share",
      loadingState: false,
    })
  }

  handleReply = (body) => {
    Actions.Comments.comment({
      sourceObject: this.getShare(),
      body,
      type: "review_share",
    })
  }

  renderMessages = ({ comments, defaultNotes }) => {
    const { review, notes, id, created_at: createdAt } = this.getShare()

    const { managerReviewShare } = this.props
    const commentsAsMessages = comments.map((comment) => {
      const {
        id,
        body,
        author: { user },
        created_at: createdAt,
      } = comment
      return (
        <ConversationMessage
          key={id}
          body={body}
          timestamp={createdAt}
          id={id}
          sender={user}
        />
      )
    })

    const { reviewer, reviewee } = review

    /*
     * The original review is rendered as a message group
     * containing a single message within the first message
     * appearing within this modal.
     */
    const reviewAsMessageGroup = (
      <MessageGroup
        key="group"
        className="SharedFeedbackModal--nested-review"
        messages={[
          <ConversationMessage
            key="message"
            body={<ReviewNotesComment review={review} />}
            id={review.id}
            timestamp={review.completed_at}
            sender={reviewer}
          />,
        ]}
      />
    )

    const managerSharedFeedbackReview = [
      <ConversationMessage
        key={id}
        body={[
          notes || <em key="notes">{defaultNotes}</em>,
          <hr key="hr" className="SharedFeedbackModal--share-divider"></hr>,
          <br key="break" />,
          <ReviewNotesComment key="review" review={review} />,
        ]}
        timestamp={createdAt}
        id={id}
        sender={reviewer}
      />,
      ...commentsAsMessages,
    ]

    const sharedFeedbackReview = [
      <ConversationMessage
        key={id}
        body={[
          notes || <em key="notes">{defaultNotes}</em>,
          <hr key="hr" className="SharedFeedbackModal--share-divider" />,
          reviewAsMessageGroup,
        ]}
        id={id}
        timestamp={createdAt}
        sender={reviewee}
        groupKey="review"
      />,
      ...commentsAsMessages,
    ]

    return managerReviewShare
      ? managerSharedFeedbackReview
      : sharedFeedbackReview
  }

  getRelativeString = ({ whoShared, whoAmI, stringType, peopleNames }) => {
    const {
      intl: { formatMessage },
    } = this.props
    return formatMessage(
      _.get(strings.sharedFeedback, [whoShared, whoAmI, stringType]),
      { ...peopleNames }
    )
  }

  render() {
    const {
      shareId,
      managerReviewShare,
      conversationData: { commentsByTypeSourceAndRecipient },
      userData: { usersById },
      intl: { formatMessage },
    } = this.props
    const reviewShare = this.getShare()

    // HACK: The API and CommentsStore requires a recipient ID,
    // although that's actually only used for comments on goals.
    // Since comments are not attached to review_shares, we need
    // to get them through the comments API, so no recipient ID
    // is passed in. The result is that undefined is the key in
    // the recipient index, which is why we use that here.
    const comments = _.get(commentsByTypeSourceAndRecipient.review_share, [
      shareId,
      undefined,
    ])

    if (!(reviewShare && comments)) {
      return <Loader />
    }

    const {
      id,
      user_id: shareeId,
      review: { reviewer, reviewee, named_reviewer: namedReview },
    } = reviewShare
    const { loadingComments, lastSavedCommentId } = this.getShare()

    const shareeUser = usersById.get(shareeId)

    if (!shareeUser) {
      return <Loader />
    }

    // a manager review share is feedback shared to the manager by a reviewer
    // i.e. the reviewer selected manager sharing when submitting feedback
    const whoShared = managerReviewShare ? "reviewerShared" : "revieweeShared"

    const reviewerSharer =
      managerReviewShare && reviewer.user_id === this.context.user.id
    const revieweeSharer =
      !managerReviewShare && reviewee.id === this.context.user.id

    const whoAmI = reviewerSharer || revieweeSharer ? "sharer" : "sharee"

    const peopleNames = {
      sharer: managerReviewShare
        ? User.getFirstName(reviewer)
        : User.getFirstName(reviewee),
      sharee: User.getFirstName(shareeUser),
      reviewer: namedReview
        ? User.getFirstName(reviewer)
        : formatMessage(strings.general.someone),
      reviewee: User.getFirstName(reviewee),
    }

    const [title, placeholder, defaultNotes] = [
      "title",
      "placeholder",
      "defaultNotes",
    ].map((stringType) =>
      this.getRelativeString({ whoShared, whoAmI, stringType, peopleNames })
    )

    return (
      <ConversationModalWrapper title={title}>
        <Conversation
          sourceType="review_share"
          sourceId={id}
          messages={this.renderMessages({ comments, defaultNotes })}
          inputPlaceholder={placeholder}
          loading={loadingComments}
          lastSavedReplyId={lastSavedCommentId}
          onRepliesNeeded={this.handleRepliesNeeded}
          onReply={this.handleReply}
        />
      </ConversationModalWrapper>
    )
  }
}

export default _.compose(
  connect(ConversationStore, "conversationData"),
  connect(UserStore, "userData"),
  injectIntl,
  modalDeprecated({
    className: "SharedFeedbackModal",
    opened: (props) => !!props.shareId,
    key: (props) => props.shareId,
  })
)(SharedFeedbackModal)

export { SharedFeedbackModal as RawSharedFeedbackModal }
