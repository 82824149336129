import React from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import strings from "../../locale/strings"
import BaseEditableMultipleChoice, {
  Props as BaseEditableMultipleChoiceProps,
} from "./BaseEditableMultipleChoice"

export type Props = Omit<
  BaseEditableMultipleChoiceProps,
  | "getChoicePlaceholders"
  | "renderChoiceValue"
  | "funnelOnBlur"
  | "funnelOnFocus"
> & {
  intl: InjectedIntl
  onBlur?: () => void
  onFocus?: () => void
  funnelOnBlur?: (e: React.FocusEvent) => void
  funnelOnFocus?: (e: React.FocusEvent) => void
}

function EditableMultipleChoice({
  className = "",
  intl: { formatMessage },
  ...baseEditableMultipleChoiceProps
}: Props) {
  return (
    <BaseEditableMultipleChoice
      className={`EditableMultipleChoice ${className}`}
      getChoicePlaceholders={({ index }: { index: number }) => ({
        title: formatMessage(
          strings.customizedQuestions.multipleChoice.choicePlaceholder,
          { optionNumber: index + 1 }
        ),
      })}
      {...baseEditableMultipleChoiceProps}
    />
  )
}

export default injectIntl(EditableMultipleChoice)
export { EditableMultipleChoice as RawEditableMultipleChoice }
