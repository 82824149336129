import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import ExpandIndicator from "../ExpandIndicator/ExpandIndicator"
import strings from "../../locale/strings"
import MarkdownText from "../MarkdownText/MarkdownText"
import ZugataFormattedDate from "../ZugataFormattedDate/ZugataFormattedDate"

import "./CollaboratorCommentsList.less"

class CollaboratorCommentsList extends React.Component {
  static propTypes = {
    /* eslint-disable camelcase */
    user: PropTypes.shape({
      best_name: PropTypes.string,
    }).isRequired,
    commenters: PropTypes.arrayOf(
      PropTypes.shape({
        full_name: PropTypes.string,
        comments: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            created_at: PropTypes.string,
            body: PropTypes.string,
          })
        ),
      })
    ),
    /* eslint-enable camelcase */
    collapsable: PropTypes.bool,
    initiallyExpanded: PropTypes.bool,
  }

  static defaultProps = {
    collapsable: false,
    initiallyExpanded: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: props.initiallyExpanded,
    }
  }

  renderCollapsableHeader() {
    const { commenters, user } = this.props
    const { expanded } = this.state
    const count = _(commenters).sum(({ comments }) => comments.length)
    const name = user.best_name || user.full_name

    return (
      <div
        className="CollaboratorCommentsList--collapsable-header layout horizontal"
        onClick={() => this.setState({ expanded: !this.state.expanded })}
      >
        <div className="layout horizontal">
          <div className="CollaboratorCommentsList--collapsable-header-comments">
            <FormattedMessage {...strings.evaluations.collaborators.comments} />
            &nbsp;
            <FormattedMessage
              {...strings.evaluations.collaborators.commentsHeader}
              values={{ name, count }}
            />
          </div>
        </div>
        <ExpandIndicator expanded={expanded} />
      </div>
    )
  }

  renderCommentsList({ collaborator, comments }) {
    return (
      <div key={collaborator} className="CollaboratorCommentsList--comments">
        <span className="CollaboratorCommentsList--comments-collaborator">
          {collaborator}
        </span>
        {comments.map((comment) => (
          <div
            key={comment.id}
            className="CollaboratorCommentsList--comments-comment layout vertical"
          >
            <span className="CollaboratorCommentsList--comments-comment-date">
              <ZugataFormattedDate value={comment.created_at} />
            </span>
            <MarkdownText text={comment.body} />
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { collapsable, commenters } = this.props
    const { expanded } = this.state

    return (
      <div className="CollaboratorCommentsList">
        {collapsable && this.renderCollapsableHeader()}
        {(!collapsable || (collapsable && expanded)) &&
          _.map(commenters, ({ full_name: collaborator, comments }) =>
            this.renderCommentsList({ collaborator, comments })
          )}
      </div>
    )
  }
}

export default CollaboratorCommentsList
