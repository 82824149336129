import PropTypes from "prop-types"
import React from "react"
import Icon from "../Icon/Icon"
import "./ExpandIndicator.less"

export default class ExpandIndicator extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool,
  }

  render() {
    const { expanded } = this.props

    return (
      <div
        className={`material-icons ExpandIndicator ${
          expanded ? "ExpandIndicator--expanded" : ""
        }`}
      >
        <Icon iconName="chevronDown" />
      </div>
    )
  }
}
