import path from "path"
import { addLoadingState, extractResponseKey } from "./apiActionHelpers"
import SteadyfootAgent from "./SteadyfootAgent"
import endpoints from "../../constants/endpointsDeprecated"
import SearchAgent from "./SearchAgent"

const { GENERAL_URLS } = endpoints
const agent = SteadyfootAgent.defaultInstance
const { BASE_URL } = GENERAL_URLS

export function getCycles({
  baseUrl = BASE_URL,
  cycleType,
  cycleKey,
  loadingState = true,
  includeDrafts,
} = {}) {
  return addLoadingState(
    [loadingState, { light: true }],
    extractResponseKey(
      cycleKey,
      cycleType.of,
      agent.get(path.join(baseUrl, `${cycleKey}`)).query({
        per_page: 1e9,
        include_drafts: includeDrafts,
      })
    )
  )
}

export class CycleSearchAgent extends SearchAgent {
  constructor({
    requestAgent = agent,
    baseUrl = BASE_URL,
    cycleKey,
    cycleKeySingle,
    itemKey,
    itemKeySingle,
    cycleItemType,
    pageSize = 25,
  } = {}) {
    super({
      requestAgent,
      resourceName: itemKey,
      pageSize,
      modelWrapper: cycleItemType.of,
    })

    this.baseUrl = baseUrl
    this.cycleKey = cycleKey
    this.cycleKeySingle = cycleKeySingle
    this.itemKey = itemKey
    this.itemKeySingle = itemKeySingle
    this.cycleItemType = cycleItemType
  }

  _getRequestUrl(cycleId, groupType) {
    const { baseUrl, cycleKey, itemKey } = this

    return Array.isArray(cycleId)
      ? // Multi-cycle request - cycleIds will be part of params rather than URL
        path.join(baseUrl, itemKey, groupType || "")
      : path.join(baseUrl, cycleKey, String(cycleId), itemKey, groupType || "")
  }

  // TODO: rename cycleId -> cycleIds and have everything pass in an array
  search({ searchParams: searchParamsArg = {}, cycleId, ...args } = {}) {
    const { group_type: groupType, ...searchParams } = searchParamsArg

    const requestUrl = this._getRequestUrl(cycleId, groupType)

    return super.search({
      fullResourcePath: requestUrl, // Default value, so placed before ...args
      ...args,
      searchParams: {
        ...searchParams,
        [`${this.cycleKeySingle}_ids`]: Array.isArray(cycleId)
          ? cycleId.join(",")
          : undefined,
      },
    })
  }

  get({ cycleId, itemId } = {}) {
    const { requestAgent: agent, cycleItemType, itemKeySingle } = this

    if (!itemKeySingle) {
      throw new Error("ItemKeySingle not provided!")
    }

    const requestUrl = this._getRequestUrl(cycleId)

    return extractResponseKey(
      itemKeySingle,
      cycleItemType.of,
      agent.get(`${requestUrl}/${itemId}`)
    )
  }
}
