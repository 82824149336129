import { Button, Text } from "@kaizen/component-library"
import * as React from "react"
import { useState } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import TextEditor from "../TextEditor"
import Avatar from "../Avatar/Avatar"
import styles from "./AvatarTextField.scss"
import strings from "../../locale/strings"

// Instead of redefining TextField props, we should grab TextField props type when it's available
type AvatarTextFieldProps = {
  avatarURL: string
  value?: string
  placeholder?: string
  detailText?: string
  onChange: (v: string) => void
  onSubmit: (v: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadUsersForMention: (q: { query: string }) => Promise<any>
  intl: InjectedIntl
}

const AvatarTextField = ({
  avatarURL,
  placeholder,
  value,
  detailText,
  onChange,
  onSubmit,
  loadUsersForMention,
  intl,
}: AvatarTextFieldProps) => {
  const { formatMessage } = intl
  // Increment a key to force-remounting of TextEditor
  // to clear the value
  const [draftKey, setDraftKey] = useState(0)
  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <div className={styles.avatar}>
          <Avatar imageURL={avatarURL} />
        </div>
        <div className={styles.textfield}>
          <TextEditor
            key={draftKey}
            // @ts-ignore
            className="Conversation--reply-text-editor"
            placeholder={placeholder}
            initialValue={value}
            onChange={onChange}
            hasEmojiPicker={true}
            loadUsersForMention={loadUsersForMention}
          />
        </div>
      </div>
      <div className={styles.details}>
        {detailText && (
          <Text tag="div" style="small">
            {detailText}
          </Text>
        )}
      </div>
      <div className={styles.actionsContainer}>
        {value && (
          <Button
            label={formatMessage(strings.general.send)}
            onClick={() => {
              setDraftKey((k) => k + 1)
              onSubmit(value)
            }}
            secondary
          />
        )}
      </div>
    </div>
  )
}

export default injectIntl(AvatarTextField)
