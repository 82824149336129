import _ from "lodash"
import createTimerDecorator from "./createTimerDecorator"

/**
 * Lodash's debounce as a method decorator.
 *
 * @example
 *
 *    class Foo {
 *      @debounce(200)
 *      bar() {
 *        console.log('bar');
 *      }
 *
 *      baz() {
 *        this.bar(); // regular invocations will be debounced
 *
 *        // this.bar is the actual debounced function, so you can do:
 *        this.bar.cancel();
 *        this.bar.flush();
 *      }
 *    }
 */
export function debounce(wait, options) {
  return createTimerDecorator("debounce", (thisArg, callback) =>
    _.debounce(callback.bind(thisArg), wait, options)
  )
}

/**
 * Lodash's throttle as a method decorator.
 *
 * @example
 *
 *    class Foo {
 *      @throttle(200)
 *      bar() {
 *        console.log('bar');
 *      }
 *
 *      baz() {
 *        this.bar(); // regular invocations will be throttled
 *
 *        // this.bar is the actual throttled function, so you can do:
 *        this.bar.cancel();
 *        this.bar.flush();
 *      }
 *    }
 */
export function throttle(wait, options) {
  return createTimerDecorator("throttle", (thisArg, callback) =>
    _.throttle(callback.bind(thisArg), wait, options)
  )
}

/**
 * Provides similar functionality to lodash's debounce method, except that it delays invocation of the method
 * until the next frame using the requestAnimationFrame API where supported.
 * This is a bit more responsive than an arbitrary timeout.
 *
 * @example
 *
 *    class Foo {
 *      @debounceAnimationFrame
 *      bar() {
 *        console.log('bar');
 *      }
 *
 *      baz() {
 *        this.bar(); // regular invocations will be throttled
 *
 *        // this.bar provides the following methods, that work like the corresponding
 *        // method that lodash's debounce wrapper provides.
 *        this.bar.cancel();
 *        this.bar.flush();
 *      }
 *    }
 */
export function debounceAnimationFrame() {
  return createTimerDecorator(
    "debounceAnimationFrame",
    debounceAnimationFrameFactory
  )
}

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  ((callback) => window.setTimeout(callback, 50)) // fallback: ~20fps delay

const cancelAnimationFrame =
  window.cancelAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.clearTimeout

function debounceAnimationFrameFactory(thisArg, callback) {
  let requestId = null
  let requestArgs = null

  function invokeCallbackWithArgs() {
    callback.apply(thisArg, requestArgs)
    requestId = null
    requestArgs = null
  }

  const ret = function () {
    if (requestId) {
      cancelAnimationFrame(requestId)
    }
    // eslint-disable-next-line prefer-rest-params
    requestArgs = arguments
    requestId = requestAnimationFrame(invokeCallbackWithArgs)
  }
  // Emulation of lodash's cancel
  ret.cancel = () => {
    if (requestId) {
      cancelAnimationFrame(requestId)
    }
    requestId = null
    requestArgs = null
  }
  // Emulation of lodash's flush
  ret.flush = () => {
    if (requestId) {
      cancelAnimationFrame(requestId)

      invokeCallbackWithArgs()
    }
  }

  return ret
}
