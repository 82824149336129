import React, { RefObject } from "react"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
// @ts-ignore
import BaseEditableQuestion from "./BaseEditableQuestion"
import AddAnswerChoiceButton from "./AddAnswerChoiceButton"
import EditableAnswerChoice from "./EditableAnswerChoice"
import "./BaseEditableMultipleChoice.less"
import { withFocusFunnel } from "../../higherOrderComponents/withFocusFunnel"

export type Choice = { title: string; value: number; description?: string }

export type Props = {
  className?: string
  title?: string
  required?: boolean
  prompt?: string
  showPrompt?: boolean
  description?: string
  showDescription?: boolean
  showRequired?: boolean
  autoFocus?: boolean
  promptMessages?: {
    label: FormattedMessage.MessageDescriptor
    addLabel: FormattedMessage.MessageDescriptor
    placeholder: FormattedMessage.MessageDescriptor
  }
  onChangeTitle: (title: string) => void
  onChangeDescription?: (desc: string) => void
  onChangePrompt?: () => void
  onDelete: () => void
  onRequiredChange?: (isRequired: boolean) => void
  choices: {
    title: string
    description?: string
  }[]
  getChoicePlaceholders: (opts: {
    index: number
  }) => { title: string; desc?: string }
  showChoiceDescriptions?: boolean
  minChoices?: number
  maxChoices?: number
  renderChoiceValue?: (opts: {
    index: number
    totalChoices: number
    getContainerRef: RefObject<HTMLDivElement>
  }) => React.ReactNode
  onChangeChoices: (choices: Choice[]) => void
  tooltipsEnabled?: boolean
  funnelOnBlur: (e: React.FocusEvent) => void
  funnelOnFocus: (e: React.FocusEvent) => void
  status?: "error" | "default"
  validationMessage?: string
}

class BaseEditableMultipleChoice extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
    showChoiceDescriptions: false,
    minChoices: 2,
    showRequired: false,
    tooltipsEnabled: true,
    renderChoiceValue: () => null,
  }

  choiceContainerRefs: RefObject<HTMLDivElement>[]

  constructor(props: Props) {
    super(props)

    this.choiceContainerRefs = []
  }

  handleChoicesChange = (choices: Choice[]) => {
    const { onChangeChoices } = this.props

    // Update values on choices before they are passed to the other handler
    if (choices.length) {
      onChangeChoices(choices.map((choice, i) => ({ ...choice, value: i + 1 })))
      onChangeChoices(
        choices.map((choice: Choice, i: number) => ({
          ...choice,
          value: i + 1,
        }))
      )
    }
  }

  handleUpdateChoice = (index: number, updates: Partial<Choice>) => {
    const { choices } = this.props
    if (choices.length) {
      this.handleChoicesChange(
        // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
        choices.map((choice, i) =>
          i === index ? { ...choice, ...updates } : choice
        )
      )
    }
  }

  handleAddChoice = () => {
    const { choices } = this.props
    // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
    this.handleChoicesChange([...choices, { title: "", description: "" }])
  }

  handleRemoveChoice = (index: number) => {
    const { choices } = this.props
    // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
    this.handleChoicesChange(_.reject(choices, (_choice, i) => i === index))
  }

  renderChoiceRow = (
    { title, description }: Choice,
    index: number,
    choices: Choice[]
  ) => {
    const {
      showChoiceDescriptions,
      getChoicePlaceholders,
      minChoices,
      renderChoiceValue,
      funnelOnFocus,
      funnelOnBlur,
    } = this.props

    return (
      <EditableAnswerChoice
        key={index}
        title={title}
        description={description}
        // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
        containerRef={(ref) => {
          this.choiceContainerRefs[index] = ref
        }}
        // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
        valueIndicator={renderChoiceValue({
          index,
          totalChoices: choices.length,
          // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
          getContainerRef: () => this.choiceContainerRefs[index],
        })}
        // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
        showRemove={choices.length > minChoices}
        showDescription={showChoiceDescriptions}
        placeholders={getChoicePlaceholders({
          index,
          // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
          totalChoices: choices.length,
        })}
        onChangeTitle={(title) => this.handleUpdateChoice(index, { title })}
        onChangeDescription={(description) =>
          this.handleUpdateChoice(index, { description })
        }
        onRemove={() => this.handleRemoveChoice(index)}
        funnelOnFocus={funnelOnFocus}
        funnelOnBlur={funnelOnBlur}
      />
    )
  }

  render() {
    const {
      className,
      choices,
      maxChoices,
      showRequired,
      funnelOnBlur,
      funnelOnFocus,
      ...otherProps
    } = this.props
    const baseEditableQuestionProps = _.omit(
      otherProps,
      "getChoicePlaceholders",
      "showChoiceDescriptions",
      "minChoices",
      "renderChoiceValue",
      "onChangeChoices"
    )

    return (
      <BaseEditableQuestion
        className={`BaseEditableMultipleChoice ${className}`}
        titlePlaceholder={
          strings.customizedQuestions.multipleChoice.placeholder
        }
        showRequired={showRequired}
        funnelOnBlur={funnelOnBlur}
        funnelOnFocus={funnelOnFocus}
        {...baseEditableQuestionProps}
      >
        {/*
        // @ts-ignore: Ignored due to time boxing. Please fix if you have the time. */}
        {choices?.map(this.renderChoiceRow)}
        {choices?.length < (maxChoices == null ? Infinity : maxChoices) && (
          <div className="BaseEditableMultipleChoice--add">
            <AddAnswerChoiceButton
              onBlur={funnelOnBlur}
              onFocus={funnelOnFocus}
              onClick={this.handleAddChoice}
            />
          </div>
        )}
      </BaseEditableQuestion>
    )
  }
}

export default withFocusFunnel(BaseEditableMultipleChoice)
