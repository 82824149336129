import { CommentSourceType } from "../../domainHooks/goals/useComments"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const newCommentEncoder: any = ({
  sourceId,
  sourceType,
  body,
  parentCommentId,
}: {
  sourceId: string
  sourceType: CommentSourceType
  body: string
  parentCommentId: number
}) => ({
  comment: {
    source_obj_id: sourceId,
    source_obj_type: sourceType,
    body,
    parent_id: parentCommentId,
  },
})

export default newCommentEncoder
