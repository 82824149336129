import { useContext, useEffect } from "react"
import { getTeamSummaryResponseDecoder } from "../../api/decoders/GetTeamSummaryResponseDecoder/getTeamSummaryResponseDecoder"
import { TeamSummariesContext } from "../../context/TeamSummariesState"
import useDataAPI from "../performanceApi/useDataAPI"

const useTeamSummary = (teamId: string) => {
  const { state, dispatch } = useContext(TeamSummariesContext)
  const fetchAPI = useDataAPI(`goals/team_goals_index?team_id=${teamId}`, "GET")

  useEffect(() => {
    fetchAPI.fire({ page: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (fetchAPI.data) {
      getTeamSummaryResponseDecoder
        .decodePromise(fetchAPI.data)
        .then((response) => {
          dispatch({ type: "FETCH_TEAM_SUMMARY", payload: response })
        })
        .catch((error) => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAPI.data])

  return {
    team: state.teamSummariesById[teamId],
    loading: fetchAPI.loading,
  }
}

export default useTeamSummary
