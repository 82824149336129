/**
 * @fileoverview Emulates the "finally" method available in some Promise frameworks,
 * but not included in ES6 Promises.
 */

export default function promiseFinally(promise, callback) {
  return promise.then(
    (res) => {
      callback()
      return res
    },
    (err) => {
      callback()
      return Promise.reject(err)
    }
  )
}
