import _ from "lodash"
import { LocalBucket } from "../types/PerformanceCycle"
import {
  EvaluationCycle,
  EvaluationCycleListingItem,
  EvaluationCycleQuestion,
} from "../types/EvaluationCycle"
import {
  mockCycleDraft,
  mockCycleListings,
  allMockCycles,
} from "./temporaryMocks/evaluationCycles"
import {
  mockError,
  mockFetch,
  perfApiDelete,
  perfApiGet,
  perfApiPost,
  perfApiPut,
} from "./utils"
import { convertToUtc } from "../utils/date"

const insertMockQuestionIds = <T>(questions: EvaluationCycleQuestion[]) => {
  return questions.map((q, i) => (q.id ? q : { ...q, id: i }))
}

const createMockCycleResponse = (cycle: EvaluationCycle) => ({
  ...mockCycleDraft,
  ...cycle,
  modules: {
    peer_and_upward_feedback: {
      ...mockCycleDraft.modules.peer_and_upward_feedback,
      state: "draft",
      reviewer_selection_start_date: null,
      reviewer_selection_end_date: null,
      completion_start_date: null,
      completion_end_date: null,
      ...cycle.modules.peer_and_upward_feedback,
      peer_feedback_questions: cycle.modules.peer_and_upward_feedback
        .peer_feedback_questions
        ? insertMockQuestionIds(
            cycle.modules.peer_and_upward_feedback.peer_feedback_questions
          )
        : mockCycleDraft.modules.peer_and_upward_feedback
            .peer_feedback_questions,
      upward_feedback_questions: cycle.modules.peer_and_upward_feedback
        .upward_feedback_questions
        ? insertMockQuestionIds(
            cycle.modules.peer_and_upward_feedback.upward_feedback_questions
          )
        : mockCycleDraft.modules.peer_and_upward_feedback
            .upward_feedback_questions,
    },
    manager_evaluation: {
      ...mockCycleDraft.modules.manager_evaluation,
      state: "draft",
      start_date: null,
      end_date: null,
      ...cycle.modules.manager_evaluation,
      questions: cycle.modules.manager_evaluation.questions
        ? insertMockQuestionIds(cycle.modules.manager_evaluation.questions)
        : mockCycleDraft.modules.manager_evaluation.questions,
    },
    self_review: {
      ...mockCycleDraft.modules.self_review,
      state: "draft",
      start_date: null,
      end_date: null,
      ...cycle.modules.self_review,
      questions: cycle.modules.self_review.questions
        ? insertMockQuestionIds(cycle.modules.self_review.questions)
        : mockCycleDraft.modules.self_review.questions,
    },
  },
})

// At the time of writing, perf-api does not have support self_reviews. However,
// we already have some of its functionality implemented on the frontend.
// This function adds the missing `self_review` module that we expect to exist.
const adaptCycle = <T>(
  cycle: T & (EvaluationCycle | EvaluationCycleListingItem)
) => {
  return {
    ...cycle,
    modules: {
      ...cycle.modules,
      self_review: {
        is_included: false,
        ...cycle.modules?.self_review,
      },
    },
  }
}

export const createCycle = async (
  cycle: EvaluationCycle,
  baseId?: number,
  isMocked = false
): Promise<{ evaluation_cycle: EvaluationCycle }> => {
  if (isMocked) {
    return await mockFetch("createCycle", [cycle, baseId], {
      evaluation_cycle: createMockCycleResponse(cycle),
    })
  }

  const { data } = await perfApiPost("/dashboard/evaluation_cycles", {
    data: {
      name: cycle.name,
      modules: {
        peer_and_upward_feedback: {
          is_included: cycle.modules.peer_and_upward_feedback.is_included,
          peer_nomination_cycle: {
            is_included:
              cycle.modules.peer_and_upward_feedback.peer_nomination_cycle
                .is_included,
          },
        },
        manager_evaluation: {
          is_included: cycle.modules.manager_evaluation.is_included,
        },
        self_review: {
          is_included: cycle.modules.self_review.is_included,
        },
      },
    },
    params: {
      base_id: baseId,
    },
  })
  return {
    evaluation_cycle: adaptCycle(data.evaluation_cycle),
  }
}

export const updateCycle = async (
  cycle: EvaluationCycle,
  stepName: "settings" | "questions" | "subjects" | "schedule",
  baseId?: number,
  isMocked = false
): Promise<{ evaluation_cycle: EvaluationCycle }> => {
  if (isMocked) {
    return await mockFetch("updateCycle", [cycle, baseId], {
      evaluation_cycle: createMockCycleResponse(cycle),
    })
  }

  const { id } = cycle
  let peerAndUpwardFeedbackPayload = {}
  if (cycle.modules.peer_and_upward_feedback.is_included) {
    peerAndUpwardFeedbackPayload = {
      ...cycle,
      modules: {
        peer_and_upward_feedback: {
          ...cycle.modules.peer_and_upward_feedback,
          // There is a requirement by the api to have all dates sent back
          // as UTC. The rest of the app can handle dates in any timezone,
          // so this conversion is necessary.
          reviewer_selection_start_date: convertToUtc(
            cycle.modules.peer_and_upward_feedback.reviewer_selection_start_date
          ),
          reviewer_selection_end_date: convertToUtc(
            cycle.modules.peer_and_upward_feedback.reviewer_selection_end_date
          ),
          completion_start_date: convertToUtc(
            cycle.modules.peer_and_upward_feedback.completion_start_date
          ),
          completion_end_date: convertToUtc(
            cycle.modules.peer_and_upward_feedback.completion_end_date
          ),
          peer_feedback_questions:
            cycle.modules.peer_and_upward_feedback.peer_feedback_questions,
          upward_feedback_questions:
            cycle.modules.peer_and_upward_feedback.upward_feedback_questions,
        },
      },
    }
  } else {
    peerAndUpwardFeedbackPayload = {
      ...cycle,
      modules: {
        peer_and_upward_feedback: {
          is_included: cycle.modules.peer_and_upward_feedback.is_included,
          peer_nomination_cycle: {
            is_included:
              cycle.modules.peer_and_upward_feedback.peer_nomination_cycle
                .is_included,
          },
        },
      },
    }
  }

  let managerEvaluationPayload = {}
  if (cycle.modules.manager_evaluation.is_included) {
    managerEvaluationPayload = {
      ...cycle,
      modules: {
        manager_evaluation: {
          ...cycle.modules.manager_evaluation,
          start_date: convertToUtc(cycle.modules.manager_evaluation.start_date),
          end_date: convertToUtc(cycle.modules.manager_evaluation.end_date),
          questions: cycle.modules.manager_evaluation.questions,
        },
      },
    }
  } else {
    managerEvaluationPayload = {
      ...cycle,
      modules: {
        manager_evaluation: {
          is_included: cycle.modules.manager_evaluation.is_included,
        },
      },
    }
  }

  let selfReviewPayload = {}
  if (cycle.modules.self_review.is_included) {
    selfReviewPayload = {
      ...cycle,
      modules: {
        self_review: {
          ...cycle.modules.self_review,
          start_date: convertToUtc(cycle.modules.self_review.start_date),
          end_date: convertToUtc(cycle.modules.self_review.end_date),
          questions: cycle.modules.self_review.questions,
        },
      },
    }
  } else {
    selfReviewPayload = {
      ...cycle,
      modules: {
        self_review: {
          is_included: cycle.modules.self_review.is_included,
        },
      },
    }
  }

  const { data } = await perfApiPut(
    "/dashboard/evaluation_cycles/:id/partial/:stepName",
    {
      data: {
        evaluation_cycle: _.merge(
          {},
          peerAndUpwardFeedbackPayload,
          managerEvaluationPayload,
          selfReviewPayload
        ),
      },
      params: {
        id,
        stepName,
        base_id: baseId,
      },
    }
  )
  return {
    evaluation_cycle: adaptCycle(data.evaluation_cycle),
  }
}

export const closeCycle = async (
  cycleId: number | string,
  isMocked = false
): Promise<undefined> => {
  if (isMocked) {
    return await mockFetch("closeCycle", [cycleId], {})
  }

  await perfApiPut("/dashboard/evaluation_cycles/:cycleId/close", {
    params: {
      cycleId,
    },
  })
}

export const reopenCycle = async (
  cycleId: number | string,
  isMocked = true
): Promise<undefined> => {
  if (isMocked) {
    return await mockFetch("reopenCycle", [cycleId], {})
  }

  await perfApiPut("/dashboard/evaluation_cycles/:cycleId/reopen", {
    params: {
      cycleId,
    },
  })
}

export const deleteCycle = async (
  cycleId: number | string,
  isMocked = true
): Promise<undefined> => {
  if (isMocked) {
    return await mockFetch("deleteCycle", [cycleId], {})
  }

  await perfApiDelete("/dashboard/evaluation_cycles/:id", {
    params: { cycleId },
  })
}

export const fetchDefaultPerformanceBuckets = async (
  cycleId: number | string
): Promise<{ default_performance_buckets: LocalBucket[] }> => {
  if (!cycleId) {
    throw new Error("Cycle id not supplied")
  } else {
    const {
      data,
    } = await perfApiGet(
      `/dashboard/evaluation_cycles/:cycleId/modules/manager_evaluation/default_performance_buckets`,
      { params: { cycleId } }
    )
    return data
  }
}

const mockCycleFetch = async (id: number | string) => {
  const mockCycle = allMockCycles.find((c) => `${c.id}` === `${id}`)

  if (!mockCycle) {
    await mockError("fetchCycle", [id], new Error("Cycle not found"))
  } else {
    return await mockFetch("fetchCycle", [id], {
      evaluation_cycle: mockCycle,
    })
  }
}

export const fetchCycleForAdmin = async (
  id: number | string,
  isMocked = false
): Promise<{ evaluation_cycle: EvaluationCycle }> => {
  if (isMocked) {
    return await mockCycleFetch(id)
  }

  const { data } = await perfApiGet("/dashboard/evaluation_cycles/:id", {
    params: {
      id,
    },
  })
  return {
    evaluation_cycle: adaptCycle(data.evaluation_cycle),
  }
}

/**
 * This endpoint is similar to the fetchCycleForAdmin call, but instead of
 * `/dashboard/evaluation_cycles/:id`, it hits `/evaluation_cycles/:id` instead.
 * A problem here is that it sends more information than we need to the manager,
 * which is not great for performance, and possibly bad for privacy.
 *
 * WARNING WARNING!
 *
 * The return type of this endpoint *says* that it's an EvaluationCycle. This
 * is not entirely true. It's an EvaluationCycle, but only with the
 * peer and upward feedback module attached. Next cycle, this endpoint will
 * be updated to include the entire cycle. I wasn't going to go into the
 * type definitions creating a bunch of partial types, knowing that they would
 * be changing soon anyway. I apologise if this ends up confusing someone.
 */
export const fetchCycleForManager = async (
  id: number | string,
  isMocked = false
): Promise<{ evaluation_cycle: EvaluationCycle }> => {
  if (isMocked) {
    return await mockCycleFetch(id)
  }

  const { data } = await perfApiGet("/evaluation_cycles/:id", {
    params: {
      id,
    },
  })
  return {
    evaluation_cycle: adaptCycle(data.evaluation_cycle),
  }
}

export const scheduleCycle = async (id: number, isMocked = false) => {
  if (isMocked) {
    await mockFetch("scheduleCycle", [id], undefined)
    return
  }

  await perfApiPut("/dashboard/evaluation_cycles/:id/schedule", {
    params: {
      id,
    },
  })
}

export const fetchCycleListings = async (
  isMocked = false
): Promise<EvaluationCycleListingItem[]> => {
  if (isMocked) {
    // await mockError("fetchCycleListings", [], new Error("Server error"))

    return await mockFetch("fetchCycleListings", [], mockCycleListings)
  } else {
    const { data } = await perfApiGet("/dashboard/evaluation_cycles")
    return data.evaluation_cycles.map((item: EvaluationCycleListingItem) =>
      adaptCycle(item)
    )
  }
}
