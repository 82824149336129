/**
 * A list of every feature flag used in the app.
 * Note, these are for the new feature flag system that uses flipper. Not
 * the old feature flag system where we access database entries from the
 * companies table.
 *
 * More info: docs/featureFlags.md
 */
enum FeatureFlags {
  // aka. evaluation cycles. MRF cycles was the old term we were using for them
  mrfCycles = "mrf_cycles",
  // PMC-182: For the evaluation cycles, in our second shape-up cycle, we were unable
  // to finish every task in the backend. As so we could do a release asap,
  // we've disabled a few of the "edit cycle actions", and the `EcInProgressModal`
  // that shows up under "my direct reports -> feedback".
  ecIncompleteFeatures = "ec_incomplete_features",
  ecSelfReview = "ec_self_review",
  hideLeaderboard = "hide_leaderboard",
  topNavigation = "top_nav",
  // enables all accordion tasks EXCEPT ProvideAdhocFeedbackTask
  showAccordionTasks = "show_accordion_tasks",
  oneOnOneConversation = "one_on_one_conversation",
  // enables page on admin dashboard to delete survey responses and reviews
  allowAdminFeedbackDelete = "allow_admin_feedback_delete",
  useNewSkillsAssignmentModal = "use_new_skills_assignment_modal",
  zenHeader = "zen_header",
  managerLearning = "allow_manager_learning",
}

export default FeatureFlags
