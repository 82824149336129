import { JsonDecoder } from "ts.data.json"
import { TeamGoalPreview } from "../../../types/Goals"
import { teamGoalPreviewDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"

type ResponseType = {
  goals: TeamGoalPreview[]
  overallProgress: number
}

export const getTeamGoalsResponseDecoder = JsonDecoder.object(
  {
    team_goals: JsonDecoder.array(
      JsonDecoder.object<ResponseType>(
        {
          goals: JsonDecoder.array(teamGoalPreviewDecoder, "teamGoals"),
          overallProgress: JsonDecoder.number,
        },
        "teamGoal",
        {
          overallProgress: "team_overall_progress",
        }
      ),
      "teamGoals"
    ),
  },
  "getTeamGoalsResponse"
).map(({ team_goals }) =>
  team_goals[0] ? team_goals[0] : { goals: [], overallProgress: 0 }
)
