import { useContext, useEffect } from "react"
// eslint-disable-next-line import/no-named-as-default
import { SurveyResponsesContext } from "../../context/SurveyState"
import { completedSurveyResponseDecoder } from "../../api/decoders/SurveyResponsesDecoder/surveyResponsesDecoder"
import useDataAPI from "../performanceApi/useDataAPI"

const useCompleteSurveyResponses = (id?: number) => {
  const { dispatch } = useContext(SurveyResponsesContext)
  const completeAPI = useDataAPI(`/survey_responses/${id}`, "PUT")

  const completeSurveyResponse = (sharedWithSubject: boolean) => {
    if (id) {
      completeAPI.fire({
        survey_response: {
          complete: true,
          shared_with_subject: sharedWithSubject,
        },
      })
    }
  }

  useEffect(() => {
    if (completeAPI.data) {
      completedSurveyResponseDecoder
        .decodePromise(completeAPI.data)
        .then((response) => {
          dispatch({
            type: "COMPLETE_SURVEY_RESPONSE",
            payload: response,
          })
        })
        .catch((error) => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeAPI.data])

  return {
    completeSurveyResponse,
    loading: completeAPI.loading,
    error: completeAPI.error,
    data: completeAPI.data,
  }
}

export default useCompleteSurveyResponses
