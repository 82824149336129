import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, injectIntl } from "react-intl"
import { Map } from "immutable"
import _ from "lodash"
import { Heading } from "@kaizen/component-library"
import Button from "../../components/Button/Button"
import FeedbackReactionTypes, {
  discardLikeReactions,
} from "../../models/FeedbackReactionTypes"
import strings from "../../locale/strings"
import Checkbox from "../../components/Checkbox/Checkbox"
import "./FeedbackReactionsManager.less"

class FeedbackReactionsManager extends React.Component {
  static propTypes = {
    review: PropTypes.shape({
      id: PropTypes.number.isRequired,
      reactions: PropTypes.array.isRequired,
    }).isRequired,
    onUpdateReactions: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      reactionsMap: this.getInitialReactions(props.review.reactions),
    }
  }

  getInitialReactions(reviewReactions) {
    // Build a map of 'reaction type' -> 'reaction' to create the initial state
    const initialReactions = discardLikeReactions(
      reviewReactions
    ).map((reaction) => [
      reaction.reaction_type,
      _.pick(reaction, ["id", "reaction_type", "negative"]),
    ])

    return Map(initialReactions)
  }

  isUnchanged() {
    const { review } = this.props
    const { reactionsMap } = this.state
    const originalReactions = discardLikeReactions(review.reactions)
    const editedReactions = reactionsMap.valueSeq()

    // Compare a map of { reaction_type -> negative } for the original review and the edited
    // reactions. For the purposes of dirty checking, we don't care about `id` or other properties
    // of the reactions
    const createMapToCompare = (reactions) =>
      Map(reactions.map((r) => [r.reaction_type, r.negative]))

    return createMapToCompare(originalReactions).equals(
      createMapToCompare(editedReactions)
    )
  }

  handleSubmit = () => {
    const { reactionsMap } = this.state
    const { review, onUpdateReactions, onClose } = this.props
    const updatedReactions = [...reactionsMap.values()]
    const deletedReactions = discardLikeReactions(review.reactions)
      .filter(
        (reviewReaction) =>
          !reactionsMap.find((reaction) => reaction.id === reviewReaction.id)
      )
      .map((deletedReaction) => ({ id: deletedReaction.id, _destroy: true }))
    onUpdateReactions({
      review,
      updates: [...updatedReactions, ...deletedReactions],
    }).then(onClose)
  }

  handlePillClick = ({ reactionType, negative }) => {
    // Replace updated reaction in the map or remove it if the reaction is present
    this.setState((prevState) => {
      const prevReactionsMap = prevState.reactionsMap
      const prevReaction = prevReactionsMap.get(reactionType)
      return {
        reactionsMap:
          prevReaction && prevReaction.negative === negative
            ? prevReactionsMap.delete(reactionType)
            : // eslint-disable-next-line camelcase
              prevReactionsMap.set(reactionType, {
                reaction_type: reactionType,
                negative,
              }),
      }
    })
  }

  renderReactionsQuestion = ({ negative }) => {
    const { reactionsMap } = this.state
    const labelStrings =
      strings.feedbackReactions.labels[negative ? "negative" : "positive"]
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <div className="FeedbackReactionsManager--questions-container">
        <div className="FeedbackReactionsManager--question">
          <FormattedMessage {...labelStrings.question} />
        </div>
        <div className="FeedbackReactionsManager--options">
          {_.map(FeedbackReactionTypes, (reactionType) => {
            const reaction = reactionsMap.get(reactionType)
            const selected = reaction && reaction.negative === negative
            return (
              <Checkbox
                id={`${reactionType}-${negative}`}
                key={`${reactionType}-${negative}`}
                onChange={() =>
                  this.handlePillClick({ reactionType, negative })
                }
                checked={!!selected}
                text={formatMessage(labelStrings[reactionType])}
              />
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { review, onClose } = this.props
    const hasReactions =
      review.reactions && discardLikeReactions(review.reactions).length > 0
    return (
      <div className="FeedbackReactionsManager">
        <Heading variant="heading-3">
          <FormattedMessage {...strings.feedbackReactions.header} />
        </Heading>

        <div className="FeedbackReactionsManager--container">
          {this.renderReactionsQuestion({ negative: false })}
          {this.renderReactionsQuestion({ negative: true })}
          <div className="FeedbackReactionsManager--footer-message">
            <FormattedMessage
              {...strings.feedbackReactions.selectionNotShared}
            />
          </div>
          <div className="FeedbackReactionsManager--footer-message">
            <FormattedMessage
              {...strings.feedbackReactions.aggregateResponses}
            />
          </div>
        </div>

        <div className="FeedbackReactionsManager--buttons-container">
          <Button
            className="FeedbackReactionsManager--button-cancel"
            actionType="secondary"
            onClick={onClose}
          >
            <FormattedMessage {...strings.feedbackReactions.cancel} />
          </Button>
          <Button
            className="FeedbackReactionsManager--button-submit"
            actionType="primary"
            disabled={this.isUnchanged()}
            onClick={this.handleSubmit}
          >
            <FormattedMessage
              {...strings.feedbackReactions[hasReactions ? "update" : "submit"]}
            />
          </Button>
        </div>
      </div>
    )
  }
}

export default _.compose(injectIntl)(FeedbackReactionsManager)

export { FeedbackReactionsManager as RawFeedbackReactionsManager }
