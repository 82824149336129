import { useEffect, useContext } from "react"
import { TeamsContext } from "../../context/TeamsState"
import { ArchivedTeamsContext } from "../../context/ArchivedTeamsState"

/**
 * The hook is an unfortunate hack to help prevent a memory leak
 * from occuring when archiving/restoring teams. It forces the local
 * Reflux store to empty the currently "selected team", ensuring
 * there is no stale data sitting there when hitting /teams/:teamId
 * or /teams/archived/:teamId. This prevents the redirects used
 * on load for those two routes to not end up in a endless cycle
 * and memory leak that crashes the app.
 *
 * For more context, check the attached thread. There are issues
 * surrounded both choices in FE and BE archicteture that have
 * forced my hand a bit with this hack.
 *
 * @see https://cultureamp.slack.com/archives/CFSSXB50E/p1584659613434500
 */
const useClearSelectedTeam = () => {
  const { dispatch: dispatchArchived } = useContext(ArchivedTeamsContext)
  const { dispatch } = useContext(TeamsContext)

  useEffect(() => {
    dispatch({ type: "FETCH_TEAM", payload: null })
    dispatchArchived({ type: "FETCH_ARCHIVED_TEAM", payload: null })
    // we only want this to run once when called
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useClearSelectedTeam
