import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { hot } from "react-hot-loader"
import styles from "./styles.scss"
import { useIntl } from "../../hooks/locale/useIntl"
import strings from "../../locale/strings"
import { useRouter } from "../../hooks/routing/useRouter"
import FeedbackPageHeader from "./FeedbackPageHeader"
import ErrorState from "../../containers/ErrorState/ErrorState"
import Loading from "../../components/Loading/Loading"
import { formatDateToLocalTime } from "../../utils/date"
import { useAsyncCallback } from "../../hooks/async/async"
import * as paufCyclesApi from "../../api/paufCycles"
import { buildUrl } from "../../utils/url"
import { safeParseInt } from "../../utils/number"
import { EmployeeSuggestion } from "../../containers/ReviewerSelectionTypeahead/ReviewerSelectionTypeahead"
import { BasicEmployeeInfo, EmployeeV2 } from "../../types/Employee"
import { PageLayout } from "../../components/layout/PageLayout/PageLayout"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import {
  useBasicDataForRequestFeedbackPage,
  useRequestedReviews,
  useTypeaheadGroupData,
} from "./apiHooks"
import RequestFormSection from "./RequestFormSection"
import RequestedFeedbacksSection from "./RequestedReviewsSection"
import { scrollToElement } from "../../utils/dom"
import RequestsSentModal from "./RequestsSentModal"
import { useConfirmationDialog } from "../../hooks/dialogs/useConfirmationDialog"
import { getPathQueryHash } from "../../utils/routing"
import { UserSelectorDrawer } from "../../containers/UserSelectorDrawer/UserSelectorDrawer"
import { useDossierAndDrawer } from "../../hooks/useDossierAndDrawer"

const getSubjectId = (
  subjectIdFromQueryString: string | undefined | null,
  directSubjects: BasicEmployeeInfo[] | undefined
): number | null => {
  return (
    safeParseInt(subjectIdFromQueryString) || directSubjects?.[0]?.id || null
  )
}

type Employee = EmployeeV2 | EmployeeSuggestion

/**
 * Evaluation Cycles, Peer and Upward Feedback, Request Feedback page.
 *
 * You may be asking, "how do I get to here!?".
 *
 * You're going to need to create a new evaluation cycle
 * as an admin ("Switch to admin view", "Employee Evaluations" (or hopefully
 * they rename this to "Evaluation Cycles"), "Create cycle". Then make sure
 * that you assign users that are direct reports for the user that you're logged
 * in as. Also, make sure the "Reviewer Selection Start Date" is less than the
 * current time. Then, go back to the "Manager view", and to the home dashboard.
 * You should see a task in the task list saying that you need to request
 * feedback from some other employees. Click on that item, and it should take
 * you here.
 */
const EcPaufRequestFeedbackPage = () => {
  const router = useRouter()
  const { location } = router
  const { formatMessage } = useIntl()
  const { showNotification } = useNotifications()
  const cycleId = safeParseInt(router.params.id)
  const [selectedReviewers, setSelectedReviewers] = useState<Employee[]>([])
  const [isRequestsSentModalVisible, setIsRequestsSentModalVisible] = useState(
    false
  )
  const requestsSubmittedRef = useRef<HTMLElement>(null)

  const {
    cycle,
    directSubjects,
    isFetchingCycleOrSubjects,
    cycleOrSubjectsFetchError,
  } = useBasicDataForRequestFeedbackPage(cycleId)

  const { drawerIsOpen, toggleDrawer } = useDossierAndDrawer()

  const subjectId = getSubjectId(location.query.subjectId, directSubjects)
  const subject = directSubjects?.find((ds) => ds.id === subjectId)

  // Clear the entire form, when we change between reviewees (aka subjects). Or potentially
  // even between eval cycles, even though we don't have the ability to do
  // that in the UI at the moment.
  useEffect(() => {
    setSelectedReviewers([])
  }, [cycleId, subjectId])

  const {
    setRequestedReviews,
    requestedReviews,
    isFetchingRequestedReviews,
    requestedReviewsError,
  } = useRequestedReviews(cycleId, subjectId)

  const {
    subjectDirects,
    currentUserDirects,
    isFetchingTypeaheadGroupData,
    peerNominations,
  } = useTypeaheadGroupData(cycleId, subjectId)

  const handleSelectSubject = useCallback(
    (id) => {
      router.replace(
        buildUrl(getPathQueryHash(location), {
          subjectId: id,
        })
      )
    },
    [location, router]
  )

  const {
    fire: handleSendRequestsSubmit,
    isLoading: isSendingRequests,
  } = useAsyncCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      try {
        // These are just to make typescript happy. This situation should be impossible.
        if (!cycleId) throw new Error("No cycle id supplied")
        if (!subjectId) throw new Error("No subject is supplied")

        const { requested_reviews } = await paufCyclesApi.submitPaufRequests(
          cycleId,
          subjectId,
          selectedReviewers.map((r) => r.id)
        )
        setRequestedReviews(requested_reviews)
        setIsRequestsSentModalVisible(true)
        // We also would want to set the selectedReviewers state to an empty
        // array, but I moved that to the `handleViewRequestsClick` function.
        // That way we can keep track of the reviewers that were selected
        // for the "Feedback requests sent" modal.
      } catch (ex) {
        showNotification({
          message: ex.message,
          title: formatMessage(strings.toast.error),
          type: "negative",
        })
      }
    },
    [
      cycleId,
      subjectId,
      selectedReviewers,
      setRequestedReviews,
      showNotification,
      formatMessage,
    ]
  )

  const handleViewRequestsClick = useCallback(() => {
    setIsRequestsSentModalVisible(false)
    setSelectedReviewers([])
    scrollToElement(requestsSubmittedRef.current)
  }, [])

  const { confirmationDialog, showDialog } = useConfirmationDialog()

  const { fire: handleWithdrawRequestClick } = useAsyncCallback(
    async (requestId: number) => {
      try {
        if (!cycle?.id) throw new Error("No cycle found") // to make typescript happy, this would never happen
        const request = requestedReviews?.find((r) => r.id === requestId)

        if (
          !(await showDialog({
            type: "negative",
            title: formatMessage(
              strings.ecPaufRequestFeedback.withdrawRequestDialogTitle
            ),
            message: formatMessage(
              strings.ecPaufRequestFeedback.withdrawRequestDialogMessage,
              {
                name: request?.author.best_name,
              }
            ),
            confirmLabel: formatMessage(
              strings.ecPaufRequestFeedback.withdrawRequestDialogConfirmButton
            ),
          }))
        ) {
          return
        }

        const withdrawnRequestId = await paufCyclesApi.withdrawPaufRequest(
          requestId
        )

        const filteredReviews =
          requestedReviews?.filter((r) => r.id !== withdrawnRequestId) || null

        setRequestedReviews(filteredReviews)

        showNotification({
          message: formatMessage(
            strings.ecPaufRequestFeedback.requestWithdrawn
          ),
          title: formatMessage(strings.toast.success),
          type: "affirmative",
        })
      } catch (ex) {
        showNotification({
          message: ex.message,
          title: formatMessage(strings.toast.error),
          type: "negative",
        })
      }
    },
    [
      cycle,
      formatMessage,
      requestedReviews,
      setRequestedReviews,
      showDialog,
      showNotification,
    ]
  )

  if (cycleOrSubjectsFetchError && !isFetchingCycleOrSubjects) {
    return (
      <PageLayout>
        <FeedbackPageHeader
          cycleId={cycleId}
          isPreviewQuestionsButtonVisible={false}
          subject={subject}
        />
        <ErrorState errorMessage={cycleOrSubjectsFetchError.message} />
      </PageLayout>
    )
  }

  if (isFetchingCycleOrSubjects || !cycle) {
    // the `!cycle` is not needed, and is just to make typescript happy
    return (
      <PageLayout>
        <FeedbackPageHeader
          cycleId={cycleId}
          subject={subject}
          isPreviewQuestionsButtonVisible={false}
        />
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      </PageLayout>
    )
  }

  const paufModule = cycle.modules.peer_and_upward_feedback

  return (
    <PageLayout
      drawerSettings={
        directSubjects?.length
          ? {
              heading: formatMessage(strings.requestFeedback.drawerHeader),
              isOpen: drawerIsOpen,
              onToggle: () => toggleDrawer(),
              content: (
                <UserSelectorDrawer
                  users={directSubjects.map((ds: BasicEmployeeInfo) => ({
                    id: String(ds.id), // Type check issue
                    name: ds.full_name,
                    avatar: ds.profile_image_url,
                  }))}
                  activeUserId={String(subject?.id)} // Type check issue
                  onSelectUser={handleSelectSubject}
                />
              ),
            }
          : undefined
      }
    >
      <FeedbackPageHeader
        cycleId={cycleId}
        cycleTitle={cycle.name}
        cycleDueDate={
          paufModule.reviewer_selection_end_date
            ? formatMessage(strings.ecPaufRequestFeedback.dueOn, {
                date: formatDateToLocalTime(
                  paufModule.reviewer_selection_end_date
                ),
              })
            : undefined
        }
        subject={subject}
      />

      {directSubjects && !directSubjects.length ? (
        // I'm not sure if this could ever happen, but just in case there's something
        // weird in the server data.
        <ErrorState
          errorMessage={formatMessage(
            strings.ecPaufRequestFeedback.errorNoSubjectsToRequestFeedback
          )}
        />
      ) : isFetchingTypeaheadGroupData ? (
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      ) : (
        subject && ( // shouldn't ever be undefined, but to make typescript happy
          <>
            <RequestFormSection
              onSubmit={handleSendRequestsSubmit}
              subject={subject}
              peerNominations={peerNominations}
              onChangeReviewers={setSelectedReviewers}
              selectedReviewers={selectedReviewers}
              currentUserDirects={currentUserDirects}
              subjectDirects={subjectDirects}
              isSendingRequests={isSendingRequests}
              requestedReviews={requestedReviews}
            />
            {isFetchingRequestedReviews ? (
              <Loading />
            ) : requestedReviewsError ? (
              <ErrorState
                errorMessage={formatMessage(
                  strings.ecPaufRequestFeedback.errorFetchingRequestedReviews
                )}
              />
            ) : requestedReviews && peerNominations ? (
              <RequestedFeedbacksSection
                forwardRef={requestsSubmittedRef}
                requestedReviews={requestedReviews}
                cycle={cycle}
                onWithdrawClick={handleWithdrawRequestClick}
              />
            ) : null}
          </>
        )
      )}

      {confirmationDialog}

      {isRequestsSentModalVisible && (
        <RequestsSentModal
          handleViewRequestsClick={handleViewRequestsClick}
          cycle={cycle}
          selectedReviewers={selectedReviewers}
        />
      )}
    </PageLayout>
  )
}

export default hot(module)(memo(EcPaufRequestFeedbackPage))
