/**
 * Events for Tracking.
 *
 * Note: this first iteration still requires some alignment with
 * Team Analytics Capture and Performance PMs to define all the
 * attributes that we want to capture for each record.
 */
import { trackEvent } from "./treasureData"

const PERFORMANCE_TEAMS = "Performance Teams"
const PERFORMANCE_GOALS = "Performance Goals"

/**
 * Teams
 */
type TeamNameAdded = {
  team_goal_name: string
}
export const trackTeamNameAdded = (record: TeamNameAdded) =>
  trackEvent({
    event_name: "Team Name Added",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type TeamDescriptionAdded = {
  team_goal_description: string
}
export const trackTeamDescriptionAdded = (record: TeamDescriptionAdded) =>
  trackEvent({
    event_name: "Team Description Added",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamCreated = {}
export const trackTeamCreated = (record: TeamCreated = {}) =>
  trackEvent({
    event_name: "Team Created",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamLeadAdded = {}
export const trackTeamLeadAdded = (record: TeamLeadAdded = {}) =>
  trackEvent({
    event_name: "Team Lead Added",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamLeadRemoved = {}
export const trackTeamLeadRemoved = (record: TeamLeadRemoved = {}) =>
  trackEvent({
    event_name: "Team Lead Removed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamMemberAdded = {}
export const trackTeamMemberAdded = (record: TeamMemberAdded = {}) =>
  trackEvent({
    event_name: "Team Member Added",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamMemberRemoved = {}
export const trackTeamMemberRemoved = (record: TeamMemberRemoved = {}) =>
  trackEvent({
    event_name: "Team Member Removed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamCreatedSuccessModalViewed = {}
export const trackTeamCreatedSuccessModalViewed = (
  record: TeamCreatedSuccessModalViewed = {}
) =>
  trackEvent({
    event_name: "Team Created Success Modal Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: true,
      ...record,
    },
  })

/**
 * Goals
 */

// TODO - Tracking: USED WITHOUT PROPS
type AlignableGoalComponentSearched = {}
export const trackAlignableGoalComponentSearched = (
  record: AlignableGoalComponentSearched = {}
) =>
  trackEvent({
    event_name: "Aligned Goal Searched",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type IndividualGoalNameAdded = {}
export const trackIndividualGoalNameAdded = (
  record: IndividualGoalNameAdded = {}
) =>
  trackEvent({
    event_name: "Individual Goal Name Added",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type IndividualGoalDescriptionAdded = {}
export const trackIndividualGoalDescriptionAdded = (
  record: IndividualGoalDescriptionAdded = {}
) =>
  trackEvent({
    event_name: "Individual Goal Description Added",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type IndividualGoalAligned = {
  goals_aligned_id: number
}
export const trackIndividualGoalAligned = (record: IndividualGoalAligned) =>
  trackEvent({
    event_name: "Individual Goal Aligned",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type IndividualGoalCreated = {}
export const trackIndividualGoalCreated = (
  record: IndividualGoalCreated = {}
) =>
  trackEvent({
    event_name: "Individual Goal Created",
    event_properties: {
      event_category: PERFORMANCE_GOALS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type KeyResultsAdded = {}
export const trackKeyResultsAdded = (record: KeyResultsAdded = {}) =>
  trackEvent({
    event_name: "Key Results Added",
    event_properties: {
      event_category: PERFORMANCE_GOALS,
      non_interaction: false,
      ...record,
    },
  })

type TeamGoalAligned = {
  goals_aligned_id: number
}
export const trackTeamGoalAligned = (record: TeamGoalAligned) =>
  trackEvent({
    event_name: "Team Goal Aligned",
    event_properties: {
      event_category: PERFORMANCE_GOALS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamGoalNameAdded = {}
export const trackTeamGoalNameAdded = (record: TeamGoalNameAdded = {}) =>
  trackEvent({
    event_name: "Team Goal Name Added",
    event_properties: {
      event_category: PERFORMANCE_GOALS,
      non_interaction: false,
      ...record,
    },
  })

type TeamGoalDescriptionAdded = {
  team_goal_description: string
}
export const trackTeamGoalDescriptionAdded = (
  record: TeamGoalDescriptionAdded
) =>
  trackEvent({
    event_name: "Team Goal Description Added",
    event_properties: {
      event_category: PERFORMANCE_GOALS,
      non_interaction: false,
      ...record,
    },
  })

type TeamGoalOwnersAdded = {
  added_user_id?: string
}
export const trackTeamGoalOwnersAdded = (record: TeamGoalOwnersAdded) =>
  trackEvent({
    event_name: "Team Goal Owners Added",
    event_properties: {
      event_category: PERFORMANCE_GOALS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamGoalCreated = {}
export const trackTeamGoalCreated = (record: TeamGoalCreated = {}) =>
  trackEvent({
    event_name: "Team Goal Created",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: Do we need this?

/**
 * PAGE VIEWS
 */
// Does this route actually exist to be tracked? Is is the parent to all `new_goals`?
// @see src/lib/analytics/pageviews.ts/handlePageview line 95
type GoalSectionViewed = {}
export const trackGoalSectionViewed = (record: GoalSectionViewed = {}) =>
  trackEvent({
    event_name: "Goal Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type CreateIndividualGoalPageViewed = {}
export const trackCreateIndividualGoalPageViewed = (
  record: CreateIndividualGoalPageViewed = {}
) =>
  trackEvent({
    event_name: "Create Individual Goal Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type CreateTeamGoalPageViewed = {}
export const trackCreateTeamGoalPageViewed = (
  record: CreateTeamGoalPageViewed = {}
) =>
  trackEvent({
    event_name: "Create Team Goal Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type CreateDepartmentGoalPageViewed = {}
export const trackCreateDepartmentGoalPageViewed = (
  record: CreateDepartmentGoalPageViewed = {}
) =>
  trackEvent({
    event_name: "Create Department Goal Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type CreateCompanyPageViewed = {}
export const trackCreateCompanyPageViewed = (
  record: CreateCompanyPageViewed = {}
) =>
  trackEvent({
    event_name: "Create Company Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

// TODO - Tracking: USED WITHOUT PROPS
type TeamGoalsSectionViewed = {}
export const trackTeamGoalsSectionViewed = (
  record: TeamGoalsSectionViewed = {}
) =>
  trackEvent({
    event_name: "Team Goals Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type CompanyGoalsSectionViewed = {}
export const trackCompanyGoalsSectionViewed = (
  record: CompanyGoalsSectionViewed = {}
) =>
  trackEvent({
    event_name: "Company Goals Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type DepartmentGoalsSectionViewed = {}
export const trackDepartmentGoalsSectionViewed = (
  record: DepartmentGoalsSectionViewed = {}
) =>
  trackEvent({
    event_name: "Department Goals Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type IndividualGoalsSectionViewed = {}
export const trackIndividualGoalsSectionViewed = (
  record: IndividualGoalsSectionViewed = {}
) =>
  trackEvent({
    event_name: "Individual Goals Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type CreateTeamPageViewed = {}
export const trackCreateTeamPageViewed = (record: CreateTeamPageViewed = {}) =>
  trackEvent({
    event_name: "Create Team Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type SpecificTeamsPageViewed = {}
export const trackSpecificTeamsPageViewed = (
  record: SpecificTeamsPageViewed = {}
) =>
  trackEvent({
    event_name: "Specific Team Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type TeamsSectionViewed = {}
export const trackTeamsSectionViewed = (record: TeamsSectionViewed = {}) =>
  trackEvent({
    event_name: "Teams Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type ArchivedTeamsSectionViewed = {}
export const trackArchivedTeamsSectionViewed = (
  record: ArchivedTeamsSectionViewed = {}
) =>
  trackEvent({
    event_name: "Archived Teams Section Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type SpecificArchivedTeamsPageViewed = {}
export const trackSpecificArchivedTeamsPageViewed = (
  record: SpecificArchivedTeamsPageViewed = {}
) =>
  trackEvent({
    event_name: "Specific Archived Teams Page Viewed",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })

type TeamBasedFeedbackRequested = {}
export const trackTeamBasedFeedbackRequested = (
  record: TeamBasedFeedbackRequested = {}
) =>
  trackEvent({
    event_name: "Team Based Feedback Requested",
    event_properties: {
      event_category: PERFORMANCE_TEAMS,
      non_interaction: false,
      ...record,
    },
  })
