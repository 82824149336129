import { Location } from "history"
import { AUTOMATIC_REDIRECTS } from "../constants/logging"
import { isLogTagEnabled } from "./logging"

export const prependBasePath = (pathName: string, location: Location) => {
  // @ts-ignore: I'm not sure why the basename isn't showing here
  return location.basename + pathName
}

export const getPathQueryHash = (location: Location) => {
  return location.pathname + location.search + location.hash
}

// Provides a console warning, letting developers know the reason why the
// page redirected.
export const logRedirectWarning = (
  from: string,
  to: string,
  reason: string
) => {
  if (isLogTagEnabled(AUTOMATIC_REDIRECTS)) {
    // eslint-disable-next-line no-console
    console.warn(
      `AUTOMATIC REDIRECT\nFrom: ${from}\nTo: ${to}\nReason: ${reason}`
    )
  }
}
