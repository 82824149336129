import { useContext, useEffect } from "react"
// eslint-disable-next-line import/no-named-as-default
import { SurveyResponsesContext } from "../../context/SurveyState"
import { dismissedSurveyResponseDecoder } from "../../api/decoders/SurveyResponsesDecoder/surveyResponsesDecoder"
import useDataAPI from "../performanceApi/useDataAPI"

const useDismissSurveyResponses = (id?: number) => {
  const { dispatch } = useContext(SurveyResponsesContext)
  const dismissAPI = useDataAPI(`/survey_responses/${id}`, "PUT")

  const dismissSurveyResponse = (reason: string) => {
    if (id && reason) {
      dismissAPI.fire({ survey_response: { reject: reason } })
    }
  }

  useEffect(() => {
    if (dismissAPI.data) {
      dismissedSurveyResponseDecoder
        .decodePromise(dismissAPI.data)
        .then((response) => {
          dispatch({
            type: "DISMISS_SURVEY_RESPONSE",
            payload: response,
          })
        })
        .catch((error) => {
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissAPI.data])

  return {
    dismissSurveyResponse,
    loading: dismissAPI.loading,
  }
}

export default useDismissSurveyResponses
