import PropTypes from "prop-types"
import React, { isValidElement } from "react"
import { Link } from "react-router"
import _ from "lodash"
import Icon from "../Icon/Icon"
const INTERACTIVE_ELEMENT_TYPES = ["a", Link]
import "./Table.less"

// Deprecated in favour fo the kaizen Table
export default class Table extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    headerRows: PropTypes.arrayOf(PropTypes.element),
    rows: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)),
    ]),
    isRowInteractive: PropTypes.func,
    responsive: PropTypes.bool,
    isUsingExpandIndicator: PropTypes.bool,
    hasHover: PropTypes.bool,
  }

  static defaultProps = {
    className: "",
    isRowInteractive: _.noop,
    responsive: false,
    isUsingExpandIndicator: false,
    hasHover: true,
  }

  isRowInteractive(elem, sectionType, index) {
    const customIsInteractive = this.props.isRowInteractive(
      elem,
      sectionType,
      index
    )

    return customIsInteractive !== undefined
      ? !!customIsInteractive
      : // REVIEW
        !!elem.props.onClick ||
          INTERACTIVE_ELEMENT_TYPES.indexOf(elem.type) > -1
  }

  renderCell(sectionType, cellElem) {
    return isValidElement(cellElem)
      ? React.cloneElement(cellElem, {
          className: `
            Table--${sectionType}-cell Table--cell
            ${cellElem.props.className || ""}
          `,
        })
      : cellElem
  }

  renderRow(sectionType, rowElem, index) {
    if (!isValidElement(rowElem)) {
      return rowElem
    }
    const { isUsingExpandIndicator, hasHover } = this.props
    const { className = "", children } = rowElem.props
    const rowIsInteractive = this.isRowInteractive(rowElem, sectionType, index)
    return React.cloneElement(
      rowElem,
      {
        className: `
          Table--${sectionType}-row Table--row
          ${rowIsInteractive && hasHover ? "Table--row-interactive" : ""}
          ${className}
        `,
      },
      React.Children.map(children, (cellElem) =>
        this.renderCell(sectionType, cellElem)
      ),
      sectionType === "body" &&
        !isUsingExpandIndicator &&
        this.renderCell(
          "body",
          <div className="Table--row-interactive-indicator">
            {rowIsInteractive && <Icon iconName="chevronRight" />}
          </div>
        )
    )
  }

  renderRows(sectionType, rows) {
    return rows
      ? rows.map((row, i) => this.renderRow(sectionType, row, i))
      : null
  }

  render() {
    const { className, headerRows, rows, responsive } = this.props
    // We may want to add accessibility roles for these tables:
    //   https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Row_Role
    return (
      <div
        className={`Table ${className} ${
          responsive ? "Table--responsive" : ""
        }`}
      >
        <div className="Table--header">
          {this.renderRows("header", headerRows)}
        </div>
        <div className="Table--body">
          {this.renderRows("body", _.flatten(rows))}
        </div>
      </div>
    )
  }
}
