import _ from "lodash"
import momentTimezone from "moment-timezone"
import moment from "moment"
import React from "react"
import { List } from "immutable"
// @ts-ignore
import lazy from "../utils/lazy"
import {
  Bucket,
  CompletionStats,
  PerformanceCycleRaw,
  Question,
} from "../types/PerformanceCycle"
import User from "./User"
import { CurrentUser } from "../types/User"
import BaseModel from "./BaseModel"

export default class PerformanceCycle extends BaseModel
  implements PerformanceCycleRaw {
  id: number
  start_date: string
  end_date: string
  name: string
  completed_at: null | string
  performance_review_placeholder_text: null | string
  performance_review_text: null | string
  completed_copy_at: string
  created_at: string
  can_share_performance_review: boolean
  performance_buckets_position: null | number
  performance_buckets_shareable: boolean
  performance_buckets_question_title: null | string
  performance_buckets_question_description: null | string
  any_questions_shareable: boolean
  // I don't know where this comes from, but we're checking for it.
  // It's strange that the casing has changed...
  failedCreation?: boolean
  completion_stats: CompletionStats
  time_zone: string
  has_any_pending_adding_user_requests: boolean
  performance_reviews_total: number
  performance_question_in_cycles: Question[]
  performance_bucket_in_cycles: Bucket[]
  user_ids?: number[]
  is_draft: boolean

  constructor(props: PerformanceCycleRaw) {
    super(props || {})
    // For some reason, the _.assign didn't work when called to the BaseModel
    _.assign(this, props || {})

    // Convert the start and end dates from utc to the users timezone
    if (this.start_date) {
      this.start_date = momentTimezone
        .tz(this.start_date, this.time_zone)
        .format()
    }
    if (this.end_date) {
      this.end_date = momentTimezone.tz(this.end_date, this.time_zone).format()
    }

    this.performance_bucket_in_cycles = _.sortBy(
      props.performance_bucket_in_cycles,
      (b) => -b.value
    )
    if (this.performance_question_in_cycles) {
      this.performance_question_in_cycles = _.map(
        this.performance_question_in_cycles,
        PerformanceCycle.mapAnswersHashToChoices
      )
    }
  }

  /**
   * Returns a two-element array of `[startDate, endDate]` representing the earliest start date
   * and latest end date of the cycles in `cyclesById` identified in `cycleIds`. Returns `null`
   * if `cycleIds` is empty.
   *
   * @param {Object<number, PerformanceCycle>} cyclesById
   * @param {Array<number>} cycleIds
   * @return {Array<Date>} `[startDate, endDate]` or `null` if no cycles are selected
   */
  static getDateRangeOfSelection({
    cyclesById,
    cycleIds,
  }: {
    cyclesById: { [id: number]: PerformanceCycle }
    cycleIds: number[]
  }) {
    const selectedCycles = _(cyclesById).at(cycleIds).compact().value()

    if (_.isEmpty(selectedCycles)) {
      return null
    }

    const minDate = _(selectedCycles)
      .map((c: PerformanceCycle) => new Date(c.start_date))
      .min()
    const maxDate = _(selectedCycles)
      .map((c: PerformanceCycle) => (c ? new Date(c.end_date) : null))
      .max()

    return [minDate, maxDate]
  }

  get started() {
    return Boolean(
      !this.is_draft &&
        this.created_at &&
        this.completed_copy_at &&
        moment().isAfter(this.start_date)
    )
  }

  get ended() {
    return !!this.completed_at
  }

  get active() {
    return this.started && !this.ended && !this.is_draft
  }

  userCanEditQuestions(user: CurrentUser) {
    return User.isAdmin(user) && !this.started
  }

  /**
   * Provides a string name denoting the type of cycle.
   *
   * Used as a key in localization.
   * Previously this used the `name` property, but `name` cannot be used, because names are
   * mangled by the build process.
   */
  static get cycleTypeName() {
    return "PerformanceCycle"
  }

  // Converts the answers hash into a more sane format for usability
  static mapAnswersHashToChoices(question: Question): Question {
    type Tr = {
      text: string | undefined
      rating: string
    }
    return {
      ...question,
      // @ts-ignore: ignored as a dime boxed effort, please fix if you have time
      choices: _.map(question.answer_titles_hash, (text, rating) => ({
        text,
        rating,
      }))
        // @ts-ignore: ??
        .sort((choice1: Tr, choice2: Tr) => choice1.rating > choice2.rating)
        .map((choice: Tr) => choice.text),
    }
  }

  get hasSomethingToShare() {
    const questions = this.performance_question_in_cycles
    return (
      this.performance_buckets_shareable ||
      (!!questions && questions.some((q) => q.can_share))
    )
  }
  @lazy
  get status():
    | "error"
    | "draft"
    | "creating"
    | "active"
    | "closed"
    | "scheduled"
    | undefined {
    if (this.failedCreation) {
      return "error"
    } else if (this.is_draft) {
      return "draft"
    } else if (!this.completed_copy_at) {
      return "creating"
    } else if (this.active) {
      return "active"
    } else if (this.completed_at) {
      return "closed"
    } else if (moment(this.start_date).isAfter(moment())) {
      return "scheduled"
    } else {
      return undefined
    }
  }

  get defaultBucketId() {
    const buckets = this.performance_bucket_in_cycles
    return buckets.length === 1 ? buckets[0].id : null
  }

  mergeQuestionsAndBuckets(
    questions: React.ReactNode[],
    bucketsItem: React.ReactNode
  ) {
    const questionsAsList = List(questions)
    const bucketsPosition = this.performance_buckets_position

    const insertionPoint =
      typeof bucketsPosition === "number"
        ? bucketsPosition
        : questionsAsList.size

    return questionsAsList.insert(insertionPoint, bucketsItem)
  }
}
