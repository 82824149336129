import { Text } from "@kaizen/component-library"
import { FieldMessage } from "@kaizen/draft-form"
import { Label } from "@kaizen/draft-form"
import { Input } from "@kaizen/draft-form"
import { FieldGroup } from "@kaizen/draft-form"
import classnames from "classnames"
import * as React from "react"
import { useRef, useState } from "react"
import cx from "classnames"
import { SelectStatus } from "../SelectNew/Select"
import styles from "./SearchField.scss"
import useKeyboardFocus from "./useKeyboardFocus"
import useOnClickOutside from "./useOnClickOutside"

type Results = React.FunctionComponent<{
  emptyText: string
  results: Array<React.ReactNode>
  hideResults: () => void
  selectResult: (resultIndex: number) => void
}>

const Results: Results = ({
  results,
  selectResult,
  emptyText,
  hideResults,
}) => {
  const onSelect = (resultIndex: number) => {
    selectResult(resultIndex)
    hideResults()
  }
  const [focussedIndex, setFocussedIndex] = useKeyboardFocus(
    results.length,
    hideResults,
    onSelect
  )
  return (
    <div className={styles.results}>
      {results.length > 0 ? (
        results.map((result, index) => (
          <button
            key={index}
            onMouseOver={() => setFocussedIndex(index)}
            className={cx(styles.result, {
              [styles.focussed]: index === focussedIndex,
            })}
            type="button"
            onClick={(evt) => {
              evt.preventDefault()
              onSelect(index)
            }}
          >
            {result}
          </button>
        ))
      ) : (
        <div className={styles.empty}>
          <Text tag="p" style="body-bold" inheritBaseline>
            {emptyText}
          </Text>
        </div>
      )}
    </div>
  )
}

type SearchField = React.FunctionComponent<{
  id: string
  placeholder?: string
  labelText: string | React.ReactNode
  inputValue: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  reversed?: boolean
  disabled?: boolean
  inline?: boolean
  status?: SelectStatus
  description?: string
  emptyText: string
  children: Array<React.ReactNode>
  selectResult: (resultIndex: number) => void
  onBlur?: () => void
}>

const SearchField: SearchField = ({
  id,
  disabled = false,
  placeholder,
  labelText,
  inputValue,
  onChange,
  children,
  emptyText,
  description,
  selectResult,
  status,
  reversed = false,
  inline = false,
  onBlur,
}) => {
  const descriptionAria = description ? `${id}-field-description` : ""
  const ariaDescribedBy = descriptionAria
  const inputAndResultsRef = useRef<HTMLDivElement>(null)
  const [resultsVisible, setResultsVisible] = useState(false)
  useOnClickOutside(inputAndResultsRef, () => setResultsVisible(false))

  return (
    <FieldGroup
      id={`${id}-field-group`}
      automationId={`${id}-field-group`}
      inline={inline}
      className={classnames(styles.withLabel, {
        [styles.withDisabled]: disabled,
        [styles.withReversed]: reversed,
        [styles.withError]: status === "error",
      })}
    >
      <Label
        id={`${id}-field-label`}
        automationId={`${id}-field-label`}
        htmlFor={`${id}-field-input`}
        labelText={labelText}
        reversed={reversed}
      />
      <div className={styles.inputAndResults} ref={inputAndResultsRef}>
        <Input
          id={`${id}-field-input`}
          automationId={`${id}-field-input`}
          ariaDescribedBy={ariaDescribedBy}
          inputType="text"
          inputValue={inputValue}
          placeholder={placeholder}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            setResultsVisible(evt.target.value.length > 0)
            onChange(evt)
          }}
          disabled={disabled}
          reversed={reversed}
          status={status}
          onBlur={onBlur}
        />
        {resultsVisible && (
          <Results
            emptyText={emptyText}
            hideResults={() => setResultsVisible(false)}
            results={children}
            selectResult={selectResult}
          />
        )}
      </div>
      {description && (
        <div className={styles.message}>
          <FieldMessage
            id={`${id}-field-message`}
            automationId={`${id}-field-description`}
            message={description}
            reversed={reversed}
          />
        </div>
      )}
    </FieldGroup>
  )
}

export default SearchField
