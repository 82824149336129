import React from "react"
import { FormattedMessage } from "react-intl"
import FormattedListSentence from "../../components/FormattedListSentence/FormattedListSentence"
import strings from "../../locale/strings"

export default function GoalVisibilityIndicator({
  visibility,
  individuals = [],
}) {
  return (
    <div className="GoalVisibilityIndicator">
      {visibility === "individuals" ? (
        <FormattedListSentence
          base={strings.goals.individualsVisibility}
          items={individuals}
          options={{
            itemRenderer: ({ user }) => user.full_name,
          }}
        />
      ) : (
        <FormattedMessage
          {...strings.goals.visibilityLabel[
            visibility === "manager"
              ? "myManager"
              : visibility === "owner"
              ? "onlyMe"
              : "everyone"
          ]}
        />
      )}
    </div>
  )
}
