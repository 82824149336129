// Submits the nominations given by a subject
import { mockError, mockFetch, perfApiGet, perfApiPost } from "./utils"
import { BasicEmployeeInfo, EmployeeV2 } from "../types/Employee"
import {
  cycleToListingItem,
  mockCycleActive,
} from "./temporaryMocks/evaluationCycles"
import {
  EvaluationCycleListingItem,
  EvaluationCycleQuestion,
  RequestedReview,
} from "../types/EvaluationCycle"
import { PaginationRaw } from "../types/Pagination"
import {
  mockDirectSubjects,
  mockRequestedReviews,
} from "./temporaryMocks/paufCycles"

export const submitPeerNominations = async (
  peerNominationCycleId: number,
  nomineeIds: number[]
) => {
  await perfApiPost("/peer_nominations", {
    data: {
      peer_nomination: {
        nominee_ids: nomineeIds,
        peer_nomination_cycle_id: peerNominationCycleId,
      },
    },
  })
}

// Endpoint for the PaUF Request Feedback page, for a manager, to get the subjects
// in a cycle, which are also their direct reports.
export const fetchPaufSubjectsToRequestFeedback = async (
  cycleId: number | string,
  isMocked = false
): Promise<{
  subjects: BasicEmployeeInfo[]
}> => {
  if (isMocked) {
    if (`${cycleId}` === `${mockCycleActive.id}`) {
      return await mockFetch("fetchPaufSubjectsToRequestFeedback", [], {
        subjects: mockDirectSubjects,
      })
    } else {
      return await mockError(
        "fetchPaufSubjectsToRequestFeedback",
        [],
        new Error("Cycle not found")
      )
    }
  }

  const { data } = await perfApiGet(
    "/evaluation_cycles/:cycleId/direct_subjects",
    {
      params: {
        cycleId,
      },
    }
  )
  return data
}

// Gets the list of nominations given by a subject, for the manager to select
// in the peer and upward feedback page.
export const fetchPeerNominations = async (
  cycleId: number,
  subjectId: number
): Promise<{ peer_nominations: EmployeeV2[] }> => {
  const { data } = await perfApiGet(
    "/evaluation_cycles/:cycleId/modules/peer_and_upward_feedback/peer_nominations",
    {
      params: { cycleId, subject_id: subjectId },
    }
  )

  return data
}

export const fetchPaufRequests = async (
  cycleId: number,
  subjectId: number | string,
  isMocked = false
): Promise<{
  requested_reviews: RequestedReview[]
  meta: { pagination: PaginationRaw }
}> => {
  if (isMocked) {
    if (`${subjectId}` === `${15}`) {
      return await mockFetch("fetchPaufRequests", [cycleId, subjectId], {
        requested_reviews: mockRequestedReviews,
      })
    } else {
      return await mockFetch("fetchPaufRequests", [cycleId, subjectId], {
        requested_reviews: [],
      })
    }
  }

  const { data } = await perfApiGet(
    "/evaluation_cycles/:cycleId/modules/peer_and_upward_feedback/requests",
    {
      params: { cycleId, subject_id: subjectId },
    }
  )
  return data
}

export const submitPaufRequests = async (
  cycleId: number,
  subjectId: number,
  employeeIds: number[],
  isMocked = false
): Promise<{
  requested_reviews: RequestedReview[]
  meta: { pagination: PaginationRaw }
}> => {
  if (isMocked) {
    return await mockFetch(
      "sendPaufRequests",
      [cycleId, subjectId, employeeIds],
      {
        requested_reviews: mockRequestedReviews,
      }
    )
  }

  const { data } = await perfApiPost(
    "/evaluation_cycles/:cycleId/modules/peer_and_upward_feedback/request_batch",
    {
      params: { cycleId },
      data: { subject_id: subjectId, employee_ids: employeeIds },
    }
  )
  return data
}

export const withdrawPaufRequest = async (
  requestId: number,
  isMocked = false
): Promise<number> => {
  if (isMocked) {
    return await mockFetch("withdrawPaufRequest", [requestId], {
      id: requestId,
    })
  }

  const {
    data: {
      survey_response: { id },
    },
  } = await perfApiPost("/survey_responses/withdraw", {
    data: { id: requestId },
  })

  return id as number
}

/**
 * This endpoint is for the Pauf request feedback page, if you click on the
 * "preview questions" button, then it opens up a modal with the question
 * previews.
 */
export const fetchPaufQuestions = async (
  cycleId: number,
  isMocked = false
): Promise<{
  peer_feedback_questions: EvaluationCycleQuestion[]
  upward_feedback_questions: EvaluationCycleQuestion[]
}> => {
  if (isMocked) {
    return await mockFetch("fetchPaufQuestions", [cycleId], {
      peer_feedback_questions:
        mockCycleActive.modules.peer_and_upward_feedback
          .peer_feedback_questions,
      upward_feedback_questions:
        mockCycleActive.modules.peer_and_upward_feedback
          .upward_feedback_questions,
    })
  }

  const { data } = await perfApiGet(
    "/evaluation_cycles/:cycleId/modules/peer_and_upward_feedback/questions",
    {
      params: { cycleId },
    }
  )
  return data
}

/**
 * This is a temporary endpoint used for the old adhoc MRF page. Its purpose
 * is to show a modal, indicating whether there's a PaUF cycle in progress, where
 * the manager should be providing feedback.
 *
 * The reason why it's temporary, is because we may be removing this MRF page
 * soon.
 */
export const fetchPaufReviewerSelectionsInProgress = async (
  isMocked = false
): Promise<{
  evaluation_cycles: EvaluationCycleListingItem[]
}> => {
  if (isMocked) {
    return await mockFetch("fetchPaufReviewerSelectionsInProgress", [], {
      // I suppose a manager would never have more than one eval cycle in progress,
      // but just in case.
      evaluation_cycles: [cycleToListingItem(mockCycleActive)],
    })
  }

  const { data } = await perfApiGet("/evaluation_cycles/active_pauf_requests")

  return data
}
