import * as React from "react"
import styles from "./TeamGoalProgressCell.scss"

type TeamGoalProgressCellProps = {
  progress: number
}

const TeamGoalProgressCell = ({ progress }: TeamGoalProgressCellProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.progressBar}>
        <div className={styles.progress} style={{ width: `${progress}%` }} />
      </div>
    </div>
  )
}

export default TeamGoalProgressCell
