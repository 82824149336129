import Reflux from "reflux-core"
import _ from "lodash"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import SearchAgent from "./lib/SearchAgent"
import endpoints from "../constants/endpointsDeprecated"
import StatsDateRange from "../models/StatsDateRange"
import SurveyResponse from "../models/SurveyResponse"
import strings from "../locale/strings"

const { TEAMS_URLS } = endpoints
const {
  TEAMS_URL,
  DIRECTS_URL,
  INDIRECTS_URL,
  TEAMS_SKILLS_URL,
  MRF_SUGGESTIONS_URL,
  TEAM_SURVEY_RESPONSE_STATS_URL,
  TEAM_SURVEY_MRF_URL,
  SURVEY_RESPONSES_URL,
} = TEAMS_URLS

const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: TEAMS_URL,
  resourceName: "users",
  pageSize: 20,
})

const searchURLMapping = {
  directs: DIRECTS_URL,
  indirects: INDIRECTS_URL,
  team: TEAMS_URL,
}

const TeamActions = Reflux.createActions({
  search: { asyncResult: true },
  pageSearchResults: { asyncResult: true },

  getMemberPreview: { asyncResult: true },
  resetMemberPreview: {},

  getTeamCategories: { asyncResult: true },
  getCompanyCategories: { asyncResult: true },

  getTeamSkills: { asyncResult: true },
  getTeamSkillsBySkillDisplay: { asyncResult: true, children: ["forceLoad"] },
  getCompanySkillsBySkillDisplay: {
    asyncResult: true,
    children: ["forceLoad"],
  },
  clearTeamSkills: {},

  getReviewersBySkill: { asyncResult: true },
  getReviewersBySkillDisplay: { asyncResult: true },

  getSubManagers: { asyncResult: true },
  suggestSubManagers: { asyncResult: true },

  setCategoryIndex: {},
  nextCategory: {},
  previousCategory: {},

  getReviewersSuggestions: { asyncResult: true },

  getIndividualStats: { asyncResult: true },
  pageIndividualStats: { asyncResult: true },

  getInvitationsForUser: { asyncResult: true },
  getInvitation: { asyncResult: true },

  // TODO: should probably go elsewhere
  suggestEmployeeGroupings: { asyncResult: true },

  withdrawManagerFeedbackRequest: { asyncResult: true },
})

TeamActions.search.listenAndPromise((searchParams, { perPage = 20 } = {}) =>
  addLoadingState(
    [true, { light: true }],
    searchAgent.search({
      searchParams,
      fullResourcePath: searchURLMapping[_.get(searchParams, "group_type")],
      per_page: perPage,
    })
  )
)

TeamActions.pageSearchResults.listenAndPromise(({ page, searchParams }) =>
  searchAgent.search({
    page,
    searchParams,
    fullResourcePath: searchURLMapping[_.get(searchParams, "group_type")],
  })
)

// Also see api/users.ts, fetchDirectReports, which also fetches the directs
TeamActions.getMemberPreview.listenAndPromise(
  (
    {
      managerId = null,
      directsOnly = null,
      loadingState = false,
      perPage = 5,
    } = {},
    q = undefined
  ) =>
    addLoadingState(
      loadingState,
      extractResponseBody(
        agent.get(directsOnly ? DIRECTS_URL : TEAMS_URL).query({
          manager_id: managerId,
          order: "random",
          per_page: perPage,
          q,
        })
      )
        .then((body) => {
          const { users, meta } = body

          const teamNumberOfUsers =
            _.get(meta, "pagination.total_count") || users.length

          return { users, teamNumberOfUsers }
        })
        .catch(() => Promise.reject())
    )
)

TeamActions.getTeamSkills.listenAndPromise(
  ({ managerId = null, directsOnly = null } = {}) =>
    extractResponseKey(
      "team_scored_skills",
      agent.get(TEAMS_SKILLS_URL).query({
        only_skills: true,
        manager_id: managerId,
        directs_only: directsOnly,
      })
    )
)

TeamActions.getTeamCategories.listenAndPromise(
  ({ managerId = null, directsOnly = null } = {}) =>
    addLoadingState(
      true,
      extractResponseKey(
        "team_scored_skills",
        agent.get(TEAMS_SKILLS_URL).query({
          manager_id: managerId,
          directs_only: directsOnly,
        })
      )
    )
)

TeamActions.getCompanyCategories.listenAndPromise(() =>
  extractResponseKey(
    "team_scored_skills",
    agent.get(TEAMS_SKILLS_URL).query({ entire_company: true })
  )
)

TeamActions.getTeamSkillsBySkillDisplay.forceLoad.listen(
  ({ skillDisplayId, managerId = "" }) =>
    TeamActions.getTeamSkillsBySkillDisplay.promise(
      extractResponseKey(
        "team_scored_skills",
        agent.get(TEAMS_SKILLS_URL).query({
          skill_display_id: skillDisplayId,
          manager_id: managerId,
        })
      ).then((result) => ({ skillDisplayId, teamSkills: result }))
    )
)

TeamActions.getCompanySkillsBySkillDisplay.forceLoad.listen(
  ({ skillDisplayId }) =>
    TeamActions.getCompanySkillsBySkillDisplay.promise(
      extractResponseKey(
        "team_scored_skills",
        agent
          .get(TEAMS_SKILLS_URL)
          .query({
            entire_company: true,
            skill_display_id: skillDisplayId,
          })
          .then((result) => ({ skillDisplayId, companySkills: result }))
      )
    )
)

TeamActions.getReviewersBySkill.listenAndPromise((skillId) =>
  extractResponseBody(
    agent.get(`${TEAMS_SKILLS_URL}/named_reviewers`).query({
      skill_id: skillId,
      per_page: 5,
      include_deactivated: true,
    })
  ).then((result) => ({ skillId, result }))
)

TeamActions.getReviewersBySkillDisplay.listenAndPromise((skillDisplayId) =>
  extractResponseBody(
    agent.get(`${TEAMS_SKILLS_URL}/named_reviewers`).query({
      skill_display_id: skillDisplayId,
      per_page: 5,
      include_deactivated: true,
    })
  ).then((result) => ({ result, skillDisplayId }))
)

TeamActions.getSubManagers.listenAndPromise(({ q = "" } = {}) =>
  extractResponseBody(agent.get(TEAMS_URL).query({ q, managers_only: true }))
)

TeamActions.suggestSubManagers.listenAndPromise(({ q }) =>
  extractResponseKey(
    "users",
    agent.get(TEAMS_URL).query({ q, managers_only: true })
  )
)

TeamActions.getReviewersSuggestions.listenAndPromise(({ user }) =>
  extractResponseBody(agent.get(MRF_SUGGESTIONS_URL).query({ user_id: user }))
)

const getIndividualStats = ({
  dateRangeId,
  endDate,
  fromDate,
  toDate,
  page,
  kind,
}) => {
  const from = dateRangeId
    ? StatsDateRange.getStartDate(dateRangeId, endDate).toISOString()
    : fromDate.toISOString()

  const to = dateRangeId ? endDate.toISOString() : toDate.toISOString()

  return addToasts(
    {},
    extractResponseBody(
      agent.get(TEAM_SURVEY_RESPONSE_STATS_URL).query({
        kind,
        from,
        to,
        page,
      })
    ).then(({ direct_stats: stats, meta }) => ({
      results: stats,
      meta,
      clientMeta: { dateRangeId },
    }))
  )
}

TeamActions.getIndividualStats.listenAndPromise(
  ({ dateRangeId, endDate = new Date(), fromDate, toDate, kind }) =>
    addLoadingState(
      [true, { light: true }],
      getIndividualStats({ dateRangeId, endDate, fromDate, toDate, kind })
    )
)

TeamActions.pageIndividualStats.listenAndPromise(
  ({ dateRangeId, page, fromDate, toDate, endDate = new Date(), kind }) =>
    getIndividualStats({ dateRangeId, endDate, fromDate, toDate, page, kind })
)

TeamActions.getInvitationsForUser.listenAndPromise(
  ({ userId, dateRangeId, endDate = new Date() }) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {},
        extractResponseKey(
          "manager_requested_feedbacks",
          SurveyResponse.of,
          agent.get(TEAM_SURVEY_MRF_URL).query({
            user_id: userId,
            from: StatsDateRange.getStartDate(
              dateRangeId,
              endDate
            ).toISOString(),
          })
        ).then((invites) => ({ userId, invites }))
      )
    )
)

TeamActions.getInvitation.listenAndPromise((id) =>
  addLoadingState(
    [true, { light: true }],
    addToasts(
      {},
      extractResponseKey(
        "manager_requested_feedback",
        SurveyResponse.of,
        agent.get(`${TEAM_SURVEY_MRF_URL}/${id}`)
      )
    )
  )
)

TeamActions.withdrawManagerFeedbackRequest.listenAndPromise((id) =>
  addLoadingState(
    true,
    addToasts(
      {
        success: strings.survey.MRF.withdraw.success,
        defaultError: strings.survey.MRF.withdraw.error,
      },
      extractResponseKey(
        "survey_response",
        agent.post(`${SURVEY_RESPONSES_URL}/withdraw`).send({
          id,
        })
      )
    )
  )
)

export default TeamActions
