import _ from "lodash"
import { useCallback } from "react"
import { useWindowDimensions } from "../dimensions/dimensions"

// type GetResponsiveValue = ({ [minWidth: number]: any }) => any

/**
 * Hook that returns a function. Calling it will give back the responsive
 * value, given a set of "min width" keys. eg.
 *
 * const getResponsiveValue = useGetResponsiveValue()
 * const width = getResponsiveValue({
 *   0: '200px',
 *   780: '250px',
 *   1024: '400px',
 * })
 * return (<div style={{ width: getResponsiveValue() }})>Hello</div>)
 *
 * The key values passed, would be the equivalent to using:
 * ```
 * @media (min-width: 200px) { ... }
 * ```
 * If you'd like to use `max-width`, or ranges, feel free to extend this
 * function to support them.
 *
 * WARNING: I would advise against using this hook where possible. Although
 * I personally love the idea of scrapping css, and using javascript for
 * styling, there is one major downside. If we were to ever implement
 * server-side rendering, this function will not work, because the server will
 * not know what the user's browser width is. Please favour using css media
 * queries instead.
 */
const useGetResponsiveValue = () => {
  const { width } = useWindowDimensions()

  const getResponsiveValue = useCallback(
    (options) => {
      let res = _.pairs(options)
      res = _.sortBy(res, ([minWidth, val]) => minWidth * -1)
      const result = res.find(([minWidth, val]) => width >= minWidth)

      return result && result[1]
    },
    [width]
  )

  return getResponsiveValue
}

export default useGetResponsiveValue
