import "./GoalPriorityIndicator.less"
import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"

const GoalPriorityIndicator = ({ priority }) => (
  <div
    className={`GoalPriorityIndicator GoalPriorityIndicator--priority-${priority}`}
  >
    <FormattedMessage {...strings.general.priority[priority]} />
  </div>
)

GoalPriorityIndicator.propTypes = {
  priority: PropTypes.number.isRequired,
}

export default GoalPriorityIndicator
