import useDataAPI from "../performanceApi/useDataAPI"
import { Goal } from "../../types/Goals"
import { TeamGoalFields } from "../../containers/TeamGoalForm/TeamGoalForm"
import { updatedKeyResults } from "./goalKeyResults"

// TODO Update existing key results (by id) instead of deleting and
// re-adding

const useUpdateTeamGoal = (goalId: string, teamId: string) => {
  const fetchAPI = useDataAPI(`/goals/${goalId}`, "PUT")
  const updateTeamGoal = (fields: TeamGoalFields, goal: Goal) => {
    const keyResults = updatedKeyResults(fields.keyResults, goal.keyResults)

    const removedAlignedGoals = (goal.outgoingAlignmentAssociations || [])
      .filter(
        (alignedGoal) =>
          !fields.alignedGoalIds.includes(alignedGoal.alignedGoalId)
      )
      .map((alignedGoal) => ({
        incoming_alignment_goal_id: Number(alignedGoal.alignedGoalId),
        id: alignedGoal.id,
        _destroy: true,
      }))
    const addedAlignedGoals = fields.alignedGoalIds
      .filter(
        (alignedGoalId) =>
          !(goal.outgoingAlignmentAssociations || [])
            .map((alignedGoal) => alignedGoal.alignedGoalId.toString())
            .includes(alignedGoalId.toString())
      )
      .map((id) => ({
        incoming_alignment_goal_id: id,
      }))
    const goaldata = {
      type: "my_goal",
      team_id: teamId,
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      goal_owners: fields.owners.map((owner) => owner.aggregateId),
      key_results: keyResults.map((kr) => ({
        id: kr.isNew ? undefined : kr.id,
        title: kr.title,
        completion: kr.completion,
        _destroy: kr._destroy,
      })),
      outgoing_alignment_associations_attributes: [
        ...removedAlignedGoals,
        ...addedAlignedGoals,
      ],
    }
    fetchAPI.fire({ goal: goaldata })
  }
  return {
    updateTeamGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading,
  }
}

export default useUpdateTeamGoal
