import Reflux from "reflux-core"
import Actions from "../refluxActions/index"
import observeSearchActions from "./lib/observeSearchActions"

const SearchStore = Reflux.createStore({
  init() {
    this.initializeState()

    observeSearchActions(this, {
      getResults: () => this.data.people,
      searchAction: Actions.Search.searchPeople,
      pageAction: Actions.Search.pagePeopleSearchResults,
      onChange: (people, state) => {
        this.data = {
          ...this.data,
          people,
          pagination: state.pagination,
          query: state.searchParams.q,
        }
        this.trigger(this.data)
      },
    })
  },

  initializeState() {
    this.data = {
      pagination: null,
      people: [],
      query: null,
    }
  },

  getInitialState() {
    return this.data
  },
})

export default SearchStore
