import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
import User from "../../models/User"
import EmptyState from "../../components/EmptyState/EmptyState"
import emptySelfReflectionsImg from "../../static/images-2017/drawings/emptystates/self-reflection.png"

const ProfileSelfReflectionEmptyState = (
  { className, user },
  { user: currentUser }
) => {
  const rootClass = `ProfileSelfReflectionEmptyState ${className}`
  const userFirstName = User.getFirstName(user)

  if (User.equals(currentUser, user)) {
    return (
      <EmptyState
        className={rootClass}
        imgSrc={emptySelfReflectionsImg}
        header={
          <FormattedMessage
            {...strings.pastFeedback.emptySelfReflectionsBodyYourself}
          />
        }
      />
    )
  } else {
    return (
      <EmptyState
        className={rootClass}
        header={
          <FormattedMessage
            {...strings.pastFeedback.emptySelfReflectionsHeader}
            values={{ user: userFirstName }}
          />
        }
        body={
          <FormattedMessage
            {...strings.pastFeedback.emptySelfReflectionsBody}
            values={{ user: userFirstName }}
          />
        }
        imgSrc={emptySelfReflectionsImg}
      />
    )
  }
}

ProfileSelfReflectionEmptyState.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
}

ProfileSelfReflectionEmptyState.defaultProps = {
  className: "",
}

ProfileSelfReflectionEmptyState.contextTypes = {
  user: PropTypes.object.isRequired,
}

export default ProfileSelfReflectionEmptyState
