import { JsonDecoder } from "ts.data.json"
import { CurrentUser, JobTitle, Manager } from "../../../types/User"
import { Company } from "../../../types/Company"
import ConfigurationOptions from "../../../constants/configurationOptions"

const {
  object,
  number,
  failover,
  array,
  string,
  oneOf,
  isExactly,
  dictionary,
  boolean,
  lazy,
  nullable,
} = JsonDecoder

export const configurationOptionsDecoder = oneOf<string>(
  [
    isExactly("teams"),
    isExactly("team_goals"),
    isExactly("team_based_feedback"),
  ],
  "Configuration Options Decoder"
).map<ConfigurationOptions>((option: string) => {
  switch (option) {
    case "team_goals":
      return ConfigurationOptions.teamGoals
    case "team_based_feedback":
      return ConfigurationOptions.teamBasedFeedback
    default:
      return ConfigurationOptions.teams
  }
})

export const departmentDecoder = object(
  {
    id: number,
    title: string,
    job_title_type: string,
  },
  "Department Decoder"
)

export const departmentManagersDecoder = object(
  {
    id: number,
    user_id: number,
    department_id: number,
    created_at: string,
    updated_at: string,
    department: failover(null, departmentDecoder),
  },
  "Department Managers Decoder"
)

export const jobTitleDecoder: JsonDecoder.Decoder<JobTitle> = object<JobTitle>(
  {
    id: number,
    title: string,
    job_title_type: string,
    job_titles_count: nullable(number),
    department_managers: failover(
      [],
      array(departmentManagersDecoder, "Jobtitle decoder department_managers")
    ),
    hr_business_partners: failover(
      [],
      array(
        lazy(() => jobTitleDecoder),
        "JobTitle decoder hr_business_partners"
      )
    ),
    parent_job_title: nullable(lazy(() => jobTitleDecoder)),
  },
  "Jobtitle decoder"
)

export const importIntegrationsDecoder = object(
  { id: number, provider: string, integration_type: string },
  "Import integrations Decoder"
)

export const managerDecoder = object<Manager>(
  {
    ancestor_ids: failover([], array(number, "Manager decoder ancestor ids")),
    avatar_images: object(
      {
        thumb_url: failover("", string),
        small_url: failover("", string),
        medium_url: failover("", string),
        large_url: failover("", string),
      },
      "Manager decoder avatar_images"
    ),
    best_name: string,
    child_ids: failover([], array(number, "Manager User Decoder child ids")),
    deactivated_at: failover(null, string),
    department_id: failover(null, number),
    department_name: failover(null, string),
    email: string,
    employee_aggregate_id: string,
    employee_id: nullable(string),

    full_name: nullable(string),

    id: number,
    in_managed_department: failover(false, boolean),
    is_managed: failover(false, boolean),
    job_title_id: failover(null, number),
    job_title_name: failover(null, string),
    manager_avatar: nullable(
      object(
        {
          thumb_url: failover("", string),
          small_url: failover("", string),
          medium_url: failover("", string),
          large_url: failover("", string),
        },
        "Manager decoder avatar_images"
      )
    ),
    manager_email: nullable(string),
    manager_id: nullable(number),
    manager_name: nullable(string),
    manager_profile_image: nullable(string),
    on_team: failover(false, boolean),
    preferred_name: failover(null, string),
    profile_image_url: string,
    zuid: string,
  },
  "Manager decoder"
)

export const companyDecoder = object<Company>(
  {
    avatar_images: object(
      {
        thumb_url: failover("", string),
        small_url: failover("", string),
        medium_url: failover("", string),
        large_url: failover("", string),
      },
      "Company decoder avatar_images"
    ),
    account_id: string,
    named_reviewer: boolean,
    premium: boolean,
    anonymous_avatar_url: failover("", string),
    admin_emails: failover([], array(string, "Company decoder admin_emails")),
    disable_points: boolean,
    performance_review_text: nullable(string),
    allow_social_chart: boolean,
    up_vote_name: string,
    down_vote_name: string,
    goals_included: failover(
      [],
      array(string, "Company decoder goals_included")
    ),
    use_exchange_oauth: failover(true, boolean),
    exchange_app_id: nullable(string),
    allow_development_resources: boolean,
    allow_everyone_to_see_department_goals: failover(false, boolean),
    allow_goals: boolean,
    allow_new_goals_interface: boolean,
    authentication_integrations: failover(
      [],
      array(string, "Company decoder authentication_integrations")
    ),
    allow_peer_feedback_invites: boolean,
    allow_performance_cycles: boolean,
    allow_team_based_feedback: boolean,
    allow_praise: boolean,
    allow_reviews: boolean,
    allow_self_reflections: boolean,
    goal_cycle_day: number,
    goal_cycle_length: number,
    goal_cycle_month: number,
    allow_top_nav: boolean,
    id: number,
    name: failover("", string),
    subdomain: failover("", string),
    import_integrations: failover(
      [],
      array(importIntegrationsDecoder, "Company decoder import_integrations")
    ),
  },
  "Current User Decoder Company decoder"
)

const userDecoder: JsonDecoder.Decoder<CurrentUser> = object<CurrentUser>(
  {
    id: number,
    zuid: string,
    preferred_name: failover(null, string),
    full_name: nullable(string),
    employee_id: nullable(string),
    hris_id: failover(null, number),
    flags: object(
      {
        mrf_cycles: failover(false, boolean),
        ec_incomplete_edit_actions: failover(false, boolean),
        hide_leaderboard: failover(false, boolean),
        show_new_task_list: failover(false, boolean),
        top_nav: failover(false, boolean),
        show_accordion_tasks: failover(false, boolean),
        ec_self_review: failover(false, boolean),
        ec_incomplete_features: failover(false, boolean),
        one_on_one_conversation: failover(false, boolean),
        allow_admin_feedback_delete: failover(false, boolean),
        use_new_skills_assignment_modal: failover(false, boolean),
        zen_header: failover(false, boolean),
        allow_manager_learning: failover(true, boolean),
      },
      "feature flags"
    ),
    email: string,
    employee_aggregate_id: string,
    best_name: string,
    is_a_manager: failover(false, boolean),
    is_masquerading: failover(false, boolean),
    job_title_name: nullable(string),
    avatar_images: object(
      {
        thumb_url: string,
        small_url: string,
        medium_url: string,
        large_url: string,
      },
      "currentUserDecoder avatar_images"
    ),
    manager_id: nullable(number),
    manager_email: nullable(string),
    manager_name: nullable(string),
    zugi_points: number,
    email_collection: object(
      { best_status: string },
      "Current User Decoder email collection"
    ),
    time_zone: nullable(string),
    review_stats: object(
      {
        given_count: number,
        received_count: number,
        unpaid_count: number,
      },
      "Current User Decoder review stats"
    ),
    job_title_options: failover(
      [],
      array(string, "Current User Decoder job title options")
    ),
    role_names: failover(
      [],
      array(
        oneOf(
          [
            isExactly("admin"),
            isExactly("staff"),
            isExactly("super_staff"),
            isExactly("mystique"),
            isExactly("death_star"),
            isExactly("department_manager"),
            isExactly("hr_business_partner"),
            isExactly("hris_admin"),
          ],
          "Current User Decoder roles"
        ),
        "Current User Decoder role names"
      )
    ),
    sign_in_count: number,
    needs_to_reimport: boolean,
    pending_notifications_count: failover(0, number),
    has_performance_cycles: boolean,
    can_email_contacts: boolean,
    tos_accepted_at: nullable(string),
    is_managed_by_import: boolean,
    show_my_summary_at: nullable(string),
    intercom_key: nullable(string),
    country: nullable(string),
    location: nullable(string),
    locale: failover("en", string),
    child_ids: failover([], array(number, "Current User Decoder child ids")),
    ancestor_ids: failover(
      [],
      array(number, "Current User Decoder ancestor ids")
    ),
    profile_image_url: string,
    devices: failover([], array(string, "Current User Decoder devices")),
    last_email_collection: failover(
      null,
      object(
        { started_at: string },
        "Current User Decoder last email collection"
      )
    ),
    deleted_at: failover(null, string),
    deactivated_at: failover(null, string),
    company: companyDecoder,
    custom_terms: dictionary(string, "Current User Decoder custom_terms"),
    job_title: nullable(jobTitleDecoder),
    hr_business_partners: failover(
      [],
      array(jobTitleDecoder, "Current User Decoder hr_business_partners")
    ),
    department_managers: failover(
      [],
      array(
        departmentManagersDecoder,
        "Current User Decoder department_managers"
      )
    ),
    configuration_options: array(
      configurationOptionsDecoder,
      "Current User Decoder configuration_options"
    ),
    manager: nullable(managerDecoder),
  },
  "currentUserDecoder user"
)

const currentUserDecoder = object(
  {
    user: userDecoder,
  },
  "currentUserDecoder"
).map<CurrentUser>(({ user }) => {
  return user
})

export default currentUserDecoder
