import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, injectIntl } from "react-intl"
import _ from "lodash"
import Tooltip from "rc-tooltip"
import ReactDOM from "react-dom"
import AlignableGoalItemFormat from "./AlignableGoalItemFormat"
import PillList from "../../components/PillList/PillList"
import strings from "../../locale/strings"
import GoalActions from "../../refluxActions/GoalActions"
import SearchFieldDeprecated from "../../components/SearchFieldDeprecated/SearchFieldDeprecated"
import CollapsibleSection from "../../components/CollapsibleSection/CollapsibleSection"
import { debounce } from "../../utils/timers"
import Settings from "../../settings"
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll"
import Icon from "../../components/Icon/Icon"
import Checkbox from "../../components/Checkbox/Checkbox"
import "./GoalAlignmentSelector.less"

const { SEARCHFIELD_DEBOUNCE } = Settings

/**
 * Control used to define the alignment between:
 *  - individual goal ---> company or department goal
 *  - department goal ---> company goal
 */
class GoalAlignmentSelector extends React.Component {
  static contextTypes = {
    user: PropTypes.shape({
      company: PropTypes.shape({
        name: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  static propTypes = {
    goalType: PropTypes.oneOf(["my_goal", "department_goal"]).isRequired,
    initialSelectedGoals: PropTypes.array,
    onSelectionChange: PropTypes.func,
  }

  static defaultProps = {
    initialSelectedGoals: [],
  }

  constructor(props) {
    super(props)

    const { initialSelectedGoals } = props
    this.state = {
      selectedGoals: initialSelectedGoals,
      query: "",
      pagination: null,
      alignableGoals: [],
    }

    this._lastRequestMarker = null
  }

  componentDidMount() {
    this.search("")
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSelectionChange } = this.props
    const { query, selectedGoals } = this.state

    if (query !== prevState.query) {
      // Trigger api call, retrieve goals from that department or company
      this.search()
    }

    if (
      !_.isEqual(
        _.map(prevState.selectedGoals, "id"),
        _.map(selectedGoals, "id")
      )
    ) {
      onSelectionChange(selectedGoals)
    }
  }

  isGoalSelected = (goalId) => {
    const { selectedGoals } = this.state
    return _.includes(_.map(selectedGoals, "id"), goalId)
  }

  listAlignable(page, callback) {
    const { goalType } = this.props
    const { query } = this.state

    const requestMarker = new Object()
    this._lastRequestMarker = requestMarker

    return GoalActions.listAlignable({
      query,
      types: goalType === "department_goal" ? ["company_goal"] : undefined,
      page,
    }).then((response) => {
      if (requestMarker !== this._lastRequestMarker) {
        // Bail if this was not the last request we made
        return
      }

      this._lastRequestMarker = null
      callback(response)
    })
  }

  @debounce(SEARCHFIELD_DEBOUNCE)
  search() {
    this.listAlignable(1, ({ goals, meta: { pagination } }) =>
      this.setState({ alignableGoals: goals, pagination })
    )
  }

  handleNextPageNeeded = () => {
    const { pagination } = this.state
    const nextPage = pagination.current_page + 1

    this.listAlignable(
      nextPage,
      ({ goals: nextPageGoals, meta: { pagination } }) =>
        this.setState(({ alignableGoals }) => ({
          alignableGoals: [...alignableGoals, ...nextPageGoals],
          pagination,
        }))
    )
  }

  handleQueryChange = (newQuery) => {
    this.setState({ query: newQuery })
  }

  handleUnselectGoals = (selectedGoals) => {
    this.setState({ selectedGoals })
  }

  handleToggleAlignableGoal = (goalId) => {
    if (this.isGoalSelected(goalId)) {
      // Remove goal from selectedGoals
      this.setState((prevState) => ({
        selectedGoals: prevState.selectedGoals.filter(
          (selectedGoal) => selectedGoal.id !== goalId
        ),
      }))
    } else {
      // Add goal that has been selected to the array of selectedGoals in the state
      this.setState((prevState) => ({
        selectedGoals: [
          ...prevState.selectedGoals,
          _.find(prevState.alignableGoals, { id: goalId }),
        ],
      }))
    }
  }

  renderGoalTitle = (goal, shouldRenderIcon) => {
    const {
      user: { company },
    } = this.context

    return (
      <div className="GoalAlignmentSelector--alignable-goal-title truncate layout horizontal center">
        <Checkbox
          key={goal.id}
          id={`${goal.id}`}
          onClick={(e) => e.stopPropagation()}
          onChange={this.handleToggleAlignableGoal.bind(this, goal.id)}
          checked={this.isGoalSelected(goal.id)}
        />
        <div className="truncate">
          <AlignableGoalItemFormat goal={goal} companyName={company.name} />
        </div>
        {shouldRenderIcon && (
          <Tooltip
            placement="top"
            arrowContent={<div className="rc-tooltip-arrow-inner" />}
            overlay={
              <div className="GoalAlignmentSelector--overlay">
                <FormattedMessage
                  {...strings.goals.alignments
                    .alignedWithSelectedDeptGoalsTooltip}
                />
              </div>
            }
            // eslint-disable-next-line react/no-find-dom-node
            getTooltipContainer={() => ReactDOM.findDOMNode(this)}
          >
            <div className="GoalAlignmentSelector--icon">
              <Icon iconName="factors" />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  renderGoalInfo = (goal, shouldRenderSubtext, selectedDeptGoalsNames) => {
    if (!goal.description && !goal.key_results.length && !shouldRenderSubtext) {
      return null
    }

    return (
      <div className="GoalAlignmentSelector--alignable-goal-info">
        <div className="GoalAlignmentSelector--alignable-goal-info-inner">
          {goal.description && (
            <div className="GoalAlignmentSelector--alignable-goal-description">
              {goal.description}
            </div>
          )}
          {goal.key_results.length > 0 && (
            <div className="GoalAlignmentSelector--alignable-goal-key-results">
              <div className="GoalAlignmentSelector--alignable-goal-key-results-title">
                <FormattedMessage {...strings.goals.keyResults} />
              </div>
              <ul className="GoalAlignmentSelector--alignable-goal-key-results-list">
                {goal.key_results.map((keyResult) => (
                  <li key={keyResult.id}>{keyResult.title}</li>
                ))}
              </ul>
            </div>
          )}
          {shouldRenderSubtext && (
            <div className="GoalAlignmentSelector--alignable-goal-subtext">
              <FormattedMessage
                {...strings.goals.alignments.alignedWithSelectedDeptGoals}
                values={{
                  selectedDeptGoals: selectedDeptGoalsNames.join(", "),
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderAlignableGoals = () => {
    const { selectedGoals, alignableGoals, pagination } = this.state
    const { goalType: modalGoalType } = this.props

    return (
      <div className="GoalAlignmentSelector--alignable-goals">
        <InfiniteScroll
          items={alignableGoals}
          hasMore={!!_.get(pagination, "next_page")}
          onNextPageNeeded={this.handleNextPageNeeded}
        >
          {alignableGoals.length > 0 ? (
            alignableGoals.map((goal) => {
              const incomingGoalsIds = goal.incoming_alignment_goal_ids
              const selectedDeptGoalsNames = selectedGoals.reduce(
                (names, selectedGoal) =>
                  _.includes(incomingGoalsIds, selectedGoal.id)
                    ? names.concat(selectedGoal.name)
                    : names,
                []
              )

              const shouldRenderSubtextAndIcon =
                modalGoalType === "my_goal" && // Individual Goal on modal
                goal.goal_type === "company_goal" && // Company Goal on accordion
                selectedDeptGoalsNames.length > 0

              return (
                <CollapsibleSection
                  key={goal.id}
                  title={this.renderGoalTitle(goal, shouldRenderSubtextAndIcon)}
                  className="GoalAlignmentSelector--alignable-goal"
                >
                  {this.renderGoalInfo(
                    goal,
                    shouldRenderSubtextAndIcon,
                    selectedDeptGoalsNames
                  )}
                </CollapsibleSection>
              )
            })
          ) : (
            <div className="GoalAlignmentSelector--alignable-goals-empty-state">
              <FormattedMessage {...strings.goals.alignments.noGoals} />
            </div>
          )}
        </InfiniteScroll>
      </div>
    )
  }

  render() {
    const {
      user: { company },
    } = this.context
    const { selectedGoals, query } = this.state
    const {
      goalType,
      intl: { formatMessage },
    } = this.props

    return (
      <div className="GoalAlignmentSelector">
        {selectedGoals.length > 0 && (
          <PillList
            values={selectedGoals}
            valueMapper={(goal) => ({
              id: goal.id,
              text: (
                <AlignableGoalItemFormat
                  goal={goal}
                  companyName={company.name}
                />
              ),
            })}
            onChange={this.handleUnselectGoals}
          />
        )}

        {
          // Hide search field for Department Goals, since they can only be aligned with
          // Company Goals.
          goalType === "my_goal" && (
            <div className="GoalAlignmentSelector--search">
              <SearchFieldDeprecated
                fullWidth={true}
                query={query}
                placeholder={formatMessage(
                  strings.goals.alignments.selector.placeholder
                )}
                onQueryChange={this.handleQueryChange}
              />
            </div>
          )
        }

        {this.renderAlignableGoals()}
      </div>
    )
  }
}

export default injectIntl(GoalAlignmentSelector)
export { GoalAlignmentSelector as RawGoalAlignmentSelector }
