/* eslint-disable @typescript-eslint/no-explicit-any, no-console */
import _ from "lodash"
import moment from "moment"
import { getObjectDiff, simplifyImmutableJsObject } from "./object"
import { isLogTagEnabled } from "./logging"
import { groupCollapsed, groupEnd } from "./logging"
import { REFLUX_ACTIONS } from "../constants/logging"

type RefluxActions = { [actionName: string]: any }
type RefluxStores = { [storeName: string]: { data: any } }
type RefluxState = { [storeName: string]: any }

const extractDataFromStores = (refluxStores: RefluxStores) => {
  // The state for each of the reflux stores is stored in the `data` value.
  // Here we remove all the extra functions that we don't need to log.
  let ret = _.mapValues(refluxStores, (v) => v.data)
  // We use immutablejs for some of our reflux store. This requires us to
  // convert these objects back to js objects.
  ret = simplifyImmutableJsObject(
    ret,
    "<-- CIRCULAR REFERENCE, LOG NOT SHOWN -->"
  )
  return ret
}

const logAction = (
  actionNamePath: string[],
  args: any[] | undefined,
  previousState: RefluxState,
  newState: RefluxState
) => {
  groupCollapsed(
    "%caction %c" +
      actionNamePath.join(".") +
      "%c @ " +
      moment().format("HH:mm:ss.SSS"),
    "color: #888888; font-weight: normal",
    "color: inherit; font-weight: bold",
    "color: #888888; font-weight: normal"
  )
  console.log("args:", ...args)
  const diffs = getObjectDiff(previousState, newState)
  if (!diffs) {
    console.log("-- No changes --")
  } else {
    console.log("diff:", diffs.lhs, "→", diffs.rhs)
  }
  console.log("next state:", newState)
  groupEnd()
}

// This function is called only once from the root index.jsx
// It will log all actions and state changes to the console.
// Do not set this logging up in production, it will likely slow down
// performance.
export const initRefluxActionLogger = (
  refluxActions: RefluxActions,
  refluxStores: RefluxStores,
  path: string[] = []
) => {
  if (!isLogTagEnabled(REFLUX_ACTIONS)) return

  let previousState = extractDataFromStores(refluxStores)

  const makeOnActionCallback = (actionNamePath: string[]) => (...args: any) => {
    const newState = extractDataFromStores(refluxStores)
    logAction(actionNamePath, args, previousState, newState)
    previousState = newState
  }

  _.keys(refluxActions).forEach((actionName) => {
    const action = refluxActions[actionName]
    if (action.listen) {
      action.listen(makeOnActionCallback([...path, actionName]))

      // Child actions seem to be typically used for async requests, such
      // as showing when an action is completed or failed.
      if (action.children) {
        action.children.forEach((childActionName: string) => {
          // I should be able to just call initRefluxActionLogger again, but
          // it returns with a stackoverflow error
          action[childActionName].listen(
            makeOnActionCallback([...path, actionName, childActionName])
          )
        })
      }
    } else if (typeof action === "object") {
      // The reflux actions are deeply nested. Recursively call `logRefluxActions`
      // until we find the listenable reflux action.
      initRefluxActionLogger(action, refluxStores, [...path, actionName])
    }
  })
}
