import "../TypeaheadRowWithAvatar/TypeaheadRowWithAvatar.less"
import React from "react"
import "./TypeaheadRowGroup.less"

type Props = {
  count: number
  title: string | object
}

export default class TypeaheadRowGroup extends React.Component<Props> {
  static defaultProps = {
    showEmail: true,
  }

  render() {
    const { title, count } = this.props

    return (
      <div>
        <div className="TypeaheadRowGroup flex left-align layout horizontal center truncate needsclick">
          <div className="TypeaheadRowGroup--avatar layout vertical center-justified">
            {count}
          </div>
          <div className="TypeaheadRowGroup--title layout vertical center-justified">
            {title}
          </div>
        </div>
      </div>
    )
  }
}
