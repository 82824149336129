export const basePath = "/performance"

/**
 * A list of every route used in the perform website.
 *
 * Please do not use strings as routes, or use string concatenation
 * for deriving your route. Treat them as if their values were some random,
 * unknown number. The purpose of this enum is to make it
 * very easy to trace where and how a route is used. If you're asking "Where
 * is this route getting used?", you can simply call "find usages" in your
 * IDE on the enum value, or do a global search for R.myRouteName.
 *
 * Note: this list is not complete yet. There is a tech debt task to go through
 * the existing code here:
 *   https://trello.com/c/wbaKt7ze/27-use-a-constants-file-for-route-addresses
 */
enum R {
  adminEvaluation = "/admin/evaluation",
  adminFeedbackSettings = "/admin/manager_request_feedback_settings",
  adminFeedbackStats = "/admin/feedback/stats",
  adminGoals = "/admin/goals/individual",
  adminGoalsStats = "/admin/goals/stats",
  adminRequestFeedback = "/admin/request_feedback",
  adminSelfReflection = "/admin/self_reflection",
  adminSkills = "/admin/skills",
  adminTeamFeedback = "/admin/team_feedback/history",
  calibrationViewsAll = "/admin/evaluation/all",
  adminSearch = "/admin/search",
  companyDepartmentGoals = "/goals/department",
  companyDirectory = "/search",
  companyGoals = "/goals/company",
  companyPraise = "/public_praise",
  companyTeams = "/teams",
  dashboard = "/",
  ecListing = "/admin/evaluation_cycles",
  ecPaufRequestFeedback = "/evaluation_cycle/:id/peer_and_upward_feedback/request_feedback",
  // Legacy employee evaluations details page
  employeeEvaluationsDetails = "/admin/evaluation/:id/:slug/", // yes, that trailing slash is required for some reason
  // Legacy employee evaluations listing page
  employeeEvaluationsListing = "/admin/evaluation",
  goals = "/new_goals/personal",
  home = "/",
  inbox = "/notifications",
  leaderboard = "/leaderboard",
  managerLearning = "/app/manager-learning/?context=performance",
  meDevelopment = "/development",
  meFeedback = "/myfeedback",
  meGoals = "/goals/personal",
  meSelfReflection = "/self_reflections_overview",
  meSharedEvaluation = "/shared_evaluations_overview",
  meSummary = "/summary",
  myProfile = "/profile",
  teamEvaluation = "/evaluation",
  teamFeedback = "/team_feedback",
  teamGoals = "/goals/team",
  teamSelfReflection = "/team_self_reflection",
  teamSummary = "/team_summary",
  teamTeams = "/team_search",
  teamUsage = "/team_usage",
  oneOnOne = "/1-1",
  oneOnOneSpace = "/1-1/:oneOnOneUuid",
  performance = "/performance",
}

export default R
