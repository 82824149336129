import "./static/fonts/fonts.css"
// Prevent scroll bounce
// import iNoBounce from 'inobounce';
// if (/iPad/i.test(navigator.userAgent)) {
//   iNoBounce.disable(); // TODO enable and test -webkit scroll on body
// }
import * as Sentry from "@sentry/browser"
import "cropperjs/dist/cropper.css"
import "flatpickr/dist/themes/material_green.css"
import { createHistory } from "history"
import { default as jQuery } from "jquery"
import _ from "lodash"
import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap_white.css"
import React from "react"
import ReactDOM from "react-dom"
import { Router, useRouterHistory } from "react-router"
import "react-select/dist/react-select.css"
import { configureUrlQuery } from "react-url-query"
import { ApolloProvider } from "@apollo/react-hooks"
import Metadata from "../package.json"
import { initRefluxActionLogger } from "./utils/reflux"
import { refluxStores } from "./refluxStores"
import refluxActions from "./refluxActions/index" // need to import index to do impl setup
import ZugataIntlProvider from "./containers/ZugataIntlProvider/ZugataIntlProvider"
import "./index.less"
import Routes from "./routes"
import Settings from "./settings"
import { NotificationsProvider } from "./context/Notifications"
import "./static/vendor/css/JQDateRangeSliderTheme.css"
import { BrowserHistoryProvider } from "./context/BrowserHistory"
import { handlePageview } from "./utils/analytics/pageviews"
import { isLogTagEnabled } from "./utils/logging"
import { SENTRY_EVENTS } from "./constants/logging"
import { client } from "./apollo"
import { filterBreadcrumbs } from "./utils/errorHandling"

// treasure data analytics setup
import "./utils/analytics/treasureData"
import { basePath } from "./constants/routes"

window.$ = window.jQuery = jQuery

// Required to be after setting of window.jQuery
require("./static/vendor/jquery-ui.min")
require("./static/vendor/JQDateRangeSlider-withRuler-min")

// Required to be after setting of window.jQuery
// eslint-disable-next-line @typescript-eslint/no-var-requires
const toast = require("exports-loader?toast!./static/vendor/materialize.min")
window.toast = toast

if (!window.Intl) {
  require("intl")
  require("intl/locale-data/jsonp/en")
}

// Gives us the ability to see our Reflux store in any environment.
window._DEBUG = { refluxActions, refluxStores }

initRefluxActionLogger(refluxActions, refluxStores)

if (process.env.NODE_ENV !== "production") {
  // Debug the settings object if we're not in production environment
  // eslint-disable-next-line no-console
  console.info(
    "Version " +
      Metadata.version +
      ", settings for '" +
      process.env.NODE_ENV +
      "' environment:",
    Settings
  )
} else {
  // eslint-disable-next-line @typescript-eslint/no-empty-function,no-console
  console.log = function () {} // Disable logging
}

if (Settings.WHY_DID_YOU_RENDER_DEBUGGER) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render")
  whyDidYouRender(React, {
    logOnDifferentValues: false,
  })
}

// TODO: We should increase release version when mergin with master.
if (Settings.SENTRY_ENABLED) {
  Sentry.init({
    dsn: Settings.SENTRY_URL,
    environment: process.env.ENVIRONMENT,
    release: process.env.RELEASE,
    beforeSend(event, hint) {
      /**
       * This is a hack to avoid loggin Unsuccessfull HTTP request into Sentry.
       * The event object is not sending any particular info on how discrimnate HTTP requests.
       * Used the mechanism of the hint object instead. Noticed on Sentry error lists that all
       * unsuccessfull HTTP requests have onunhandledrejection as mechanism.
       * Probably not the best soltuon, but now we have a ton of logs on Sentry.
       * Decided to apply this solution until most of them are fixed on the API.
       */
      if (hint?.data?.stack?.mechanism === "onunhandledrejection") {
        return null
      }

      /**
       * The following two blocks are an interim prevention for chunk errors. So far, no customer complaints,
       * but that is not enough justification. The aim here is to log to the console for
       * now in the sandbox where errors have been shown before, and in the interim stop
       * chunking errors from clogging up Sentry. All the chunking errors are currently handled.
       */
      if (event?.environment === "sandbox") {
        if (isLogTagEnabled(SENTRY_EVENTS)) {
          // eslint-disable-next-line no-console
          console.log("@@@ Sentry event", event)
        }
        if (isLogTagEnabled(SENTRY_EVENTS)) {
          // eslint-disable-next-line no-console
          console.log("@@@ Sentry hint", hint)
        }
      }
      if (/loading.+chunk/i.test(hint?.originalException)) {
        return null
      }

      /**
       * This block handles case-by-case errors for Electron which we use with Cypress.
       * Consider alternatives to blacklist entire errors if we only expect them in Electron.
       */
      if (/electron/i.test(hint?.request?.headers?.["User-Agent"])) {
        // @see https://community.netlify.com/t/common-issue-why-do-i-see-uncaught-syntaxerror-unexpected-token-errors-how-can-i-use-chunking-or-versioning-for-my-assets-at-netlify/124
        if (/unexpected token/i.test(hint?.originalException)) {
          return null
        }
      }

      return event
    },
    beforeBreadcrumb(breadcrumb, hint) {
      /**
       * #Security: Ensure no private user information makes its way to Sentry,
       * by removing console logs entirely from the event prior to sending.
       */
      if (breadcrumb.category === "console") {
        return null
      }

      /**
       * Runs through composed set of predefined filters that MUST
       * return a breadcrumb
       */
      const filteredBreadcrumb = filterBreadcrumbs(breadcrumb)

      return filteredBreadcrumb
    },
  })
}

function init() {
  // HACK react-url-query's backwards compatibility for react-router v2/3 is essentially broken. So
  // we construct a history object that is close enough to react-router v4 to trigger non-compat mode
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const browserHistory = useRouterHistory(createHistory)({
    basename: basePath,
  })

  browserHistory.listen((location) => {
    // react-router v4 has `location` and `action` properties on the history that update to the
    // current values
    Object.assign(browserHistory, {
      // Transform the location to match react-router v4. Use whitelisted properties to ensure any
      // legacy data is not carried over.
      location: _.pick(location, "pathname", "search", "state", "key"),
      action: location.action,
    })
  })

  // track initial page load
  handlePageview(browserHistory.getCurrentLocation())
  // track page views
  browserHistory.listenBefore(handlePageview)

  configureUrlQuery({ history: browserHistory })

  /**
   * RUN THE ROUTER
   */

  ReactDOM.render(
    <ZugataIntlProvider>
      <NotificationsProvider>
        <BrowserHistoryProvider history={browserHistory}>
          <ApolloProvider client={client}>
            <Router routes={Routes} history={browserHistory} />
            {/* There's also the ModalRouter, which can be found in ./components/App.jsx */}
          </ApolloProvider>
        </BrowserHistoryProvider>
      </NotificationsProvider>
    </ZugataIntlProvider>,
    document.getElementById("app")
  )
}

/**
 * MICROSOFT EXCHANGE OAUTH
 * Had to get the oauth token before initializing react router, as the hash and string
 * it appends to the url would wipe out important authentication information that
 * exchange attaches to the url after a user successfully signs in
 */

const applicationConfig = {
  clientID: localStorage.getItem("exchange_app_id"),
  graphScopes: Settings.EXCHANGE_SCOPES,
}

// eslint-disable-next-line no-undef,@typescript-eslint/no-unused-vars
const myMSALObj = new Msal.UserAgentApplication(
  applicationConfig.clientID,
  null,
  () => {},
  { storeAuthStateInCookie: true, cacheLocation: "localStorage" }
)

init()
