import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
import Icon from "../../components/Icon/Icon"
import "./Ratings.less"

class Ratings extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  static propTypes = {
    ratings: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        rating: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        full_name: PropTypes.string, // eslint-disable-line camelcase
        description: PropTypes.string,
      })
    ).isRequired,

    className: PropTypes.string,
  }

  static defaultProps = {
    className: "",
  }

  constructor(props) {
    super(props)
  }

  renderRating(rating) {
    const label = rating.name

    return (
      <div key={rating.id} className="Ratings--rating">
        {rating.rating < 0 ? (
          <Icon iconName="star_border" />
        ) : rating.rating > 0 ? (
          <Icon iconName="star" style={{ color: "#ffce1e" }} />
        ) : null}
        <span className="Ratings--skill">{label}</span>
      </div>
    )
  }

  renderCategory(key, categoryName, ratings = []) {
    return (
      <div className="Ratings--column" key={key}>
        <div className="Ratings--column-header">{categoryName}</div>
        {ratings
          .sort((a, b) => a.rating < b.rating)
          .map(this.renderRating.bind(this))}
      </div>
    )
  }

  render() {
    const { user } = this.context

    const ratingLabels = {
      up: user.company.up_vote_name || (
        <FormattedMessage {...strings.general.rockingIt} />
      ),
      down: user.company.down_vote_name || (
        <FormattedMessage {...strings.general.gettingThere} />
      ),
    }

    // eslint-disable-next-line eqeqeq
    const ratings = this.props.ratings.filter((rating) => rating.rating != 0)
    const ratingGroups = _.groupBy(ratings, (r) => r.rating)

    return (
      <div>
        <div
          className={`Ratings--content ${this.props.className} layout horizontal wrap`}
        >
          {[
            this.renderCategory("up", ratingLabels.up, ratingGroups[1]),
            this.renderCategory("down", ratingLabels.down, ratingGroups[-1]),
          ]}
        </div>
      </div>
    )
  }
}

export default Ratings
