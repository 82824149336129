import PropTypes from "prop-types"
import React from "react"
import { List } from "immutable"
import _ from "lodash"
import { injectIntl } from "react-intl"
import EditableOpenEnded from "../customCycleQuestions/EditableOpenEnded"
import EditableMultipleChoice from "../customCycleQuestions/EditableMultipleChoice"
import EditableRatingScale from "../customCycleQuestions/EditableRatingScale"
import strings from "../../locale/strings"
import AddQuestion from "../customCycleQuestions/AddQuestion"
import ListEditorUncontrolled from "../ListEditorUncontrolled/ListEditorUncontrolled"
import "./SurveyQuestionsManager.less"
import Aid from "../../constants/automationId"

const MAX_RATING_CHOICES = 5
const DEFAULT_CHOICES = _.times(4, (index) => ({ title: "", value: index + 1 }))

class SurveyQuestionsManager extends React.PureComponent {
  static propTypes = {
    initialQuestions: PropTypes.instanceOf(List).isRequired,
    onUpdateQuestion: PropTypes.func.isRequired,
    showItemNumbers: PropTypes.bool,
    showPrompt: PropTypes.bool,
    showRequired: PropTypes.bool,
    allowQuestionDescriptions: PropTypes.bool,
    allowNonOpenEndedQuestions: PropTypes.bool,
    className: PropTypes.string,
    tooltipsEnabled: PropTypes.bool,
  }

  static defaultProps = {
    allowNonOpenEndedQuestions: true,
    showPrompt: false,
    allowQuestionDescriptions: true,
    showRequired: true,
    showItemNumbers: true,
    className: "",
    tooltipsEnabled: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      updatedQuestions: List([]),
    }
  }

  renderQuestions = ({ items, onChangeItem, onRemoveItem }) => {
    const {
      showPrompt,
      showRequired,
      allowQuestionDescriptions,
      tooltipsEnabled,
    } = this.props

    return items.map((item, index) => {
      const baseQuestionProps = {
        title: item.title,
        prompt: showPrompt ? item.discussion_prompt : item.manager_prompt,
        description: item.description,
        showPrompt: showPrompt,
        showDescription: allowQuestionDescriptions,
        showRequired: showRequired,
        required: showRequired ? item.required : true,
        promptMessages: strings.customizedQuestions.discussionPrompt,
        // eslint-disable-next-line camelcase
        onChangeTitle: (title) => onChangeItem(index, { title }),
        onChangeDescription: (description) =>
          onChangeItem(index, { description }),
        onChangePrompt: (prompt) =>
          onChangeItem(index, {
            [showPrompt ? "discussion_prompt" : "manager_prompt"]: prompt,
          }),
        onRequiredChange: (required) => onChangeItem(index, { required }),
        onDelete: () => onRemoveItem(index),
      }
      switch (item.type) {
        case "rating":
          return (
            <EditableRatingScale
              {...baseQuestionProps}
              choices={item.choices}
              showChoiceDescriptions={true}
              tooltipsEnabled={tooltipsEnabled}
              maxChoices={MAX_RATING_CHOICES}
              onChangeChoices={(choices) => onChangeItem(index, { choices })}
            />
          )
        case "multiple-choice":
          return (
            <EditableMultipleChoice
              {...baseQuestionProps}
              choices={item.choices}
              onChangeChoices={(choices) => onChangeItem(index, { choices })}
            />
          )
        default:
          return <EditableOpenEnded {...baseQuestionProps} />
      }
    })
  }

  renderQuestionAdder = ({ onAddItem }) => {
    const {
      allowNonOpenEndedQuestions,
      intl: { formatMessage },
    } = this.props

    return (
      <AddQuestion
        getDefaultItem={() => this.createQuestion("open-ended")}
        menuOptions={
          allowNonOpenEndedQuestions
            ? [
                {
                  key: "rating",
                  text: formatMessage(
                    strings.customizedQuestions.addQuestion.rating
                  ),
                  getItem: () => this.createQuestion("rating"),
                },
                {
                  text: formatMessage(
                    strings.customizedQuestions.addQuestion.multipleChoice
                  ),
                  getItem: () => this.createQuestion("multiple-choice"),
                },
                {
                  text: formatMessage(
                    strings.customizedQuestions.addQuestion.openEnded
                  ),
                  getItem: () => this.createQuestion("open-ended"),
                },
              ]
            : null
        }
        onAddItem={onAddItem}
      />
    )
  }

  createQuestion = (type) => {
    const { updatedQuestions } = this.state
    const hasQuestions = updatedQuestions.length > 0

    switch (type) {
      case "multiple-choice":
        return { type, title: "", choices: DEFAULT_CHOICES, required: true }

      case "rating": {
        const lastRatingQuestion =
          hasQuestions &&
          updatedQuestions.findLast(({ type }) => type === "rating")

        return {
          type,
          title: "",
          required: true,
          choices: lastRatingQuestion
            ? lastRatingQuestion.choices
            : DEFAULT_CHOICES,
        }
      }

      default:
        return { type, title: "", required: true }
    }
  }

  handleChange = (updatedQuestions) => {
    const { onUpdateQuestion } = this.props
    this.setState({ updatedQuestions })
    onUpdateQuestion(updatedQuestions)
  }

  render() {
    const { initialQuestions, className, showItemNumbers } = this.props

    return (
      <div className="SurveyQuestionsManager">
        <ListEditorUncontrolled
          className={className}
          initialItems={initialQuestions}
          renderItems={this.renderQuestions}
          onChange={this.handleChange}
          customItemAdder={this.renderQuestionAdder}
          showItemNumbers={showItemNumbers}
          itemAutomationId={Aid.surveyQuestion}
        />
      </div>
    )
  }
}

export default injectIntl(SurveyQuestionsManager)
export { SurveyQuestionsManager as RawSurveyQuestionsManager }
