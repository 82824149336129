import "./CompensationAnswersModal.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"
import { Map } from "immutable"
import { Heading } from "@kaizen/component-library"
import strings from "../../locale/strings"
import PerformanceReview from "../../models/PerformanceReview"
import PerformanceCycle from "../../models/PerformanceCycle"
import Actions from "../../refluxActions"
import modalDeprecated from "../../higherOrderComponents/modalDeprecated"
import ProfilePic from "../ProfilePic/ProfilePic"
import Button from "../../components/Button/Button"
import ActivityList from "../ActivityList/ActivityList"
import CompensationBucketPicker from "../../components/CompensationBucketPicker/CompensationBucketPicker"
import EvaluationQuestion from "../EvaluationQuestion/EvaluationQuestion"
import UIActions from "../../refluxActions/UIActions"
import User from "../../models/User"

class CompensationAnswersModal extends React.Component {
  static propTypes = {
    review: PropTypes.instanceOf(PerformanceReview),
    cycle: PropTypes.instanceOf(PerformanceCycle).isRequired,

    updateReviewAction: PropTypes.func,
    allowAnswerEditingOnNonDirectReport: PropTypes.bool,

    onClose: PropTypes.func,
  }

  static defaultProps = {
    allowAnswerEditingOnNonDirectReport: false,
    onClose: _.noop,
  }

  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    const { performance_answers: answers, assignedBucket } = props.review

    this.state = {
      answersByQuestionId: this.indexAnswersByQuestionId(answers),
      selectedBucketId: _.get(assignedBucket, "id"),
    }
  }

  componentDidMount() {
    this.loadReviewDetails()
  }

  loadReviewDetails(props = this.props) {
    const {
      cycle: { id: cycleId },
      review: { id: reviewId },
    } = props

    Actions.PerformanceReview.getReview({
      cycleId,
      reviewId,
      loadingState: false,
    })
  }

  saveReview = () => {
    const {
      review: { id, performance_cycle_id: cycleId, assignedBucket },
      cycle: { performance_question_in_cycles: questions, defaultBucketId },
      updateReviewAction,
      onDirtinessChange,
    } = this.props

    const { answersByQuestionId } = this.state
    const selectedBucketId = this.state.selectedBucketId || defaultBucketId

    const allQuestionsAnswered = questions
      .map(({ id }) => answersByQuestionId.get(id))
      .every((answer) => answer || answer === 0)

    return updateReviewAction({
      id,
      performance_cycle_id: cycleId, // eslint-disable-line camelcase
      performance_answers: answersByQuestionId.toJS(), // eslint-disable-line camelcase

      // eslint-disable-next-line camelcase
      performance_bucket_in_cycle_id:
        selectedBucketId !== _.get(assignedBucket, "id")
          ? selectedBucketId
          : null,

      complete: allQuestionsAnswered && !!selectedBucketId,
    }).then(() => {
      onDirtinessChange(false)
      UIActions.success(strings.cycle.updateSaveMessage)
    })
  }

  handleAnswerChange = ({ question }, value) => {
    this.props.onDirtinessChange(true)

    this.setState(({ answersByQuestionId }) => ({
      answersByQuestionId: answersByQuestionId.set(question.id, value),
    }))
  }

  handleSelectBucket = (bucketId) => {
    this.props.onDirtinessChange(true)
    this.setState({ selectedBucketId: bucketId })
  }

  indexAnswersByQuestionId = (answers) => {
    return Map(
      answers.map((answer) => [
        answer.performance_question_in_cycle.id,
        PerformanceReview.getAnswerValue(answer),
      ])
    )
  }

  renderBucketPicker = ({ cycle, review, editable, displayPosition }) => (
    <CompensationBucketPicker
      key="bucketPicker"
      buckets={cycle.performance_bucket_in_cycles}
      assignedBucket={review.assignedBucket}
      onSelect={this.handleSelectBucket}
      review={review}
      readOnly={!editable}
      shareable={cycle.performance_buckets_shareable}
      questionTitle={cycle.performance_buckets_question_title}
      questionDescription={cycle.performance_buckets_question_description}
      displayPosition={displayPosition}
    />
  )

  render() {
    const {
      review,
      cycle,
      updateReviewAction,
      allowAnswerEditingOnNonDirectReport,
    } = this.props

    const buckets = cycle.performance_bucket_in_cycles
    const questions = cycle.performance_question_in_cycles
    const bucketPosition = Number.isInteger(cycle.performance_buckets_position)
      ? cycle.performance_buckets_position
      : questions.length

    const { user, manager } = review

    const revieweeName = user.full_name || user.best_name

    const isDirectReport = _.get(manager, "id") === this.context.user.id
    const editable =
      updateReviewAction &&
      cycle.active &&
      (isDirectReport || allowAnswerEditingOnNonDirectReport)
    const shouldShowBuckets = buckets.length > 1

    return (
      <div className="CompensationAnswersModal--content flex center-align">
        <div className="CompensationAnswersModal--reviewee-name">
          <Heading variant="heading-2">{revieweeName}</Heading>
          {User.isDeactivated(user) && (
            <div className="CompensationAnswersModal--reviewee-name-deactivated">
              <FormattedMessage {...strings.user.deactivated} />
            </div>
          )}
        </div>

        <p className="subheading">{user.job_title_name}</p>

        <ProfilePic user={user} showHorizontalRule={true} />

        <div className="left-align">
          <fieldset
            key="questions"
            className="CompensationAnswersModal--questions"
          >
            {cycle.mergeQuestionsAndBuckets(
              questions.map((question, index) => {
                const indexWithBuckets =
                  bucketPosition <= index ? index + 2 : index + 1

                return (
                  <EvaluationQuestion
                    key={index}
                    question={question}
                    readOnly={!editable}
                    review={review}
                    index={indexWithBuckets}
                    onChange={this.handleAnswerChange}
                  />
                )
              }),
              shouldShowBuckets &&
                this.renderBucketPicker({
                  cycle,
                  review,
                  editable,
                  displayPosition: bucketPosition + 1,
                })
            )}
          </fieldset>

          <hr />

          {review.activities && review.activities.length > 0 && (
            <fieldset key="content">
              <Heading variant="heading-6">Activity</Heading>
              <ActivityList
                cycle={cycle}
                review={review}
                currentUser={this.context.user}
                bucketPosition={bucketPosition}
              />
            </fieldset>
          )}
        </div>

        {editable && (
          <div className="CompensationAnswersModal--button-row layout horizontal center-center">
            <Button actionType="primary" onClick={this.saveReview}>
              <FormattedMessage {...strings.general.save} />
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default _.compose(
  injectIntl,
  modalDeprecated({
    className: "CompensationAnswersModal",
    opened: (props) => !!props.review,
    key: (props) => props.review.id,
  })
)(CompensationAnswersModal)

export { CompensationAnswersModal as RawCompensationAnswersModal }
