import PropTypes from "prop-types"
import React from "react"
import ReactDOM from "react-dom"
import ReactSelect, { AsyncCreatable, Creatable } from "react-select"
import _ from "lodash"
import { injectIntl } from "react-intl"
import cx from "classnames"
import selectedValueLoader from "../../higherOrderComponents/selectedValueLoader"
import Icon from "../Icon/Icon"
import "./Typeahead.less"
import Settings from "../../settings"
import { debounce } from "../../utils/timers.js"

const MINIMUM_OPTIONS_FOR_SEARCH = 5
const { SEARCHFIELD_DEBOUNCE } = Settings

class Typeahead extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    labelKey: PropTypes.string,

    // HACK - temporary while the Zugata style for Typeahead is developed
    // and styles are still being standardized & unified
    zugataStyle: PropTypes.bool,
    drawerStyle: PropTypes.bool,
    padding: PropTypes.string,
    onBlurResetsInput: PropTypes.bool,
    hideArrow: PropTypes.bool,
    searchIconEnabled: PropTypes.bool,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,

    loadOptions: PropTypes.func,
    loadOptionsField: PropTypes.string,
    loadOptionsForValues: PropTypes.func,
    refreshOptionsOnFocus: PropTypes.bool,

    id: PropTypes.string,
    automationId: PropTypes.string,
  }

  static defaultProps = {
    className: "",
    labelKey: "label",
    // react-select (as of 1.0.0-rc.1) has buggy caching, where the cache is shared between
    // all instances if not explicitly supplied. So disable caching by default for now.
    cache: null,
    zugataStyle: false,
    onBlurResetsInput: false,
    onFocus: _.noop,
    onBlur: _.noop,
    onChange: _.noop,

    loadOptionsField: "results",
    refreshOptionsOnFocus: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      focused: false,
    }

    this.loadOptionsIntoInputField = this.loadOptionsIntoInputField.bind(this)
  }

  findInputNode() {
    // eslint-disable-next-line react/no-find-dom-node
    const selectNode = ReactDOM.findDOMNode(this.select)
    return selectNode && selectNode.querySelector("input")
  }

  focus() {
    this.select.focus()
  }

  handleFocus(e) {
    const { refreshOptionsOnFocus, loadOptions, onFocus } = this.props

    if (refreshOptionsOnFocus && loadOptions) {
      this.select.loadOptions(this.findInputNode().value)
    }

    this.setState({ focused: true })
    onFocus(e)
  }

  handleBlur(e) {
    this.setState({ focused: false })
    this.props.onBlur(e)
  }

  handleChange(value) {
    const {
      multi,
      loadOptionsForValues,
      onChange,
      loadOptions,
      selectedValueLoader: { cacheObjects },
    } = this.props

    if (!multi) {
      const input = this.findInputNode()
      if (input) {
        input.blur()
      }
    }

    // Cache selected options so we don't have to refetch them
    if (loadOptionsForValues) {
      cacheObjects(getValueAsArray({ multi, value }))
    }

    if (value && value.length === 0 && loadOptions) {
      this.select.loadOptions()
    }

    onChange(value)
  }

  renderOption(option) {
    const { labelKey, optionRenderer } = this.props

    const label =
      optionRenderer && !option.isPaginationInfo
        ? optionRenderer(option)
        : option[labelKey]

    // needsclick class disables FastClick, which conflicts with react-select
    return (
      <div style={{ height: "100%" }} className="needsclick">
        {label}
      </div>
    )
  }

  renderArrow({ isOpen }) {
    const { multi, hideArrow } = this.props
    return multi || hideArrow ? (
      <span />
    ) : (
      <div className="arrow-container">
        <Icon iconName={isOpen ? "chevronUp" : "chevronDown"} />
      </div>
    )
  }

  renderPlaceholder() {
    const { placeholder, searchIconEnabled } = this.props

    if (searchIconEnabled) {
      return (
        <div className="Typeahead--placeholder layout horizontal center">
          <Icon iconName="search" style={{ color: "#3e4543" }} />
          {placeholder}
        </div>
      )
    } else {
      return <div className="Typeahead--placeholder">{placeholder}</div>
    }
  }

  loadOptionsIntoInputField = (query, callback) => {
    const { loadOptions, loadOptionsField } = this.props
    return loadOptions(query).then((result) => {
      if (result[loadOptionsField] !== undefined) {
        const options = [...result[loadOptionsField]]
        return callback(null, { options: options })
      } else {
        // Fallback for legacy react-select loadOptions
        return callback(null, result)
      }
    })
  }

  @debounce(SEARCHFIELD_DEBOUNCE)
  debouncedLoadOptions(inputValue, callback) {
    return this.loadOptionsIntoInputField(inputValue, callback)
  }

  getLoadValues = (inputValue, callback) => {
    this.debouncedLoadOptions(inputValue, callback)
  }

  render() {
    const {
      className,
      zugataStyle,
      value,
      multi,
      cache,
      allowCreate,
      hideArrow,
      onBlurResetsInput,
      loadOptions,
      drawerStyle,
      padding,
      loadOptionsForValues,
      selectedValueLoader,
      searchable,
      options,
      automationId,
      id,
      ...props
    } = this.props
    const { focused } = this.state
    const isSearchable =
      options && !allowCreate
        ? options.length > MINIMUM_OPTIONS_FOR_SEARCH
        : // The searchable prop in react-select defaults to `true`, so we should only consider
          // explicit `false` to mean not searchable.
          searchable !== false

    const correctedValue =
      focused && !multi && isSearchable
        ? // Normally, react-select keeps the selected value around even when you click into it. For
          // single-value selects, this means you have to type "over" the selected value to search
          // for a new value. Setting the value to null while the control is focused fixed this. This
          // also avoids a related issue, where typing backspace tries to clear the value.
          null
        : value && loadOptionsForValues
        ? multi
          ? selectedValueLoader.values
          : selectedValueLoader.values[0]
        : value
    return React.createElement(
      allowCreate
        ? loadOptions
          ? AsyncCreatable
          : Creatable
        : loadOptions
        ? ReactSelect.Async
        : ReactSelect,
      {
        ref: (select) => (this.select = select),
        ...props,
        className: cx("Typeahead", className, {
          "Typeahead--zugata-style": zugataStyle,
          "Typeahead--drawer-style": drawerStyle,
        }),
        style: padding ? { padding } : undefined,
        searchable: isSearchable,
        autoload: false,
        options,
        optionRenderer: this.renderOption.bind(this),
        arrowRenderer:
          zugataStyle || hideArrow ? this.renderArrow.bind(this) : undefined,
        loadOptions: loadOptions ? this.getLoadValues : null,
        multi,
        value: correctedValue,
        onBlurResetsInput,
        inputProps: {
          "data-automation-id": automationId,
          id,
        },
        cache,
        placeholder: this.renderPlaceholder(),

        onFocus: this.handleFocus.bind(this),
        onBlur: this.handleBlur.bind(this),
        onChange: this.handleChange.bind(this),
      }
    )
  }
}

function getValueAsArray({ value, multi }) {
  // REVIEW: react-select itself has more complex logic and supports different formats for
  // `value`, but this should suit our use cases
  if (!value) {
    return []
  } else if (multi) {
    return value
  } else {
    return [value]
  }
}

export default selectedValueLoader({
  getValues: ({ value, multi }) => getValueAsArray({ value, multi }),
  getObjectId: ({ valueKey }, option) => option[valueKey],
  loadObjectsForIds: (
    { loadOptionsForValues, loadOptionsField = "results" },
    values
  ) =>
    loadOptionsForValues &&
    loadOptionsForValues(values).then((results) => results[loadOptionsField]),
})(injectIntl(Typeahead, { withRef: true }))

export { Typeahead as RawTypeahead }
