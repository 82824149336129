import { Icon } from "@kaizen/component-library"
import spinner from "@kaizen/component-library/icons/spinner.icon.svg"
import * as React from "react"
import styles from "./Loading.scss"

const Loading = () => (
  <div className={styles.wrapper}>
    <Icon icon={spinner} title="Loading" />
  </div>
)

export default Loading
