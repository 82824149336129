import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import MultipleChoice from "../../components/MultipleChoice/MultipleChoice"
import LongAnswer from "../../components/LongAnswer/LongAnswer"
import PerformanceReview from "../../models/PerformanceReview"
import CollaboratorCommentsList from "../../components/CollaboratorCommentsList/CollaboratorCommentsList"

import "./EvaluationQuestion.less"

export default class EvaluationQuestion extends React.Component {
  static propTypes = {
    question: PropTypes.shape({
      question_type: PropTypes.oneOf(["multiple-choice", "open-ended"]),
    }).isRequired,
    index: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    review: PropTypes.object.isRequired,
    hideAsterisk: PropTypes.bool,
    highlightedInitialValue: PropTypes.string,
    displayComments: PropTypes.bool,
  }

  static defaultProps = {
    onChange: _.noop,
    onBlur: _.noop,
    displayComments: false,
  }

  render() {
    const {
      question,
      readOnly,
      review,
      onChange,
      onBlur,
      index,
      hideAsterisk,
      highlightedInitialValue,
      displayComments,
    } = this.props

    const answer = _.find(
      review.performance_answers,
      (answer) => answer.performance_question_in_cycle.id === question.id
    )

    return (
      <div>
        {question &&
          (question.question_type === "multiple-choice" ? (
            <MultipleChoice
              question={question}
              selectedAnswerValue={PerformanceReview.getAnswerValue(answer)}
              onSelectAnswer={(value) => onChange({ question, answer }, value)}
              htmlId={`evalutaion-${review.id}-question-${question.id}`}
              index={index}
              readOnly={readOnly}
              orientation={"horizontal"}
              textAlign={"center-center"}
              disableColor={false}
              hideAsterisk={hideAsterisk}
            />
          ) : (
            <LongAnswer
              key={`${(answer && answer.id) || `${question.id}-${review.id}`}`}
              question={question}
              answer={answer}
              onTypeAnswer={(value) => onChange({ question, answer }, value)}
              onBlur={(_evt, value) => onBlur({ question, value })}
              index={index}
              readOnly={readOnly}
              hideAsterisk={hideAsterisk}
              highlightedInitialValue={highlightedInitialValue}
            />
          ))}
        {displayComments && _.get(answer, "commenters.length") > 0 && (
          <div className="EvaluationQuestion--comments">
            <CollaboratorCommentsList
              collapsable={true}
              initiallyExpanded={review.status === "incomplete"}
              commenters={answer.commenters}
              user={review.user}
            />
          </div>
        )}
      </div>
    )
  }
}
