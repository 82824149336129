import "./LongAnswer.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FormattedMessage, injectIntl } from "react-intl"
import { Tag } from "@kaizen/draft-tag"
import { Tooltip } from "@kaizen/draft-tooltip"
import TextEditor from "../TextEditor"
import MarkdownText from "../MarkdownText/MarkdownText"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"

@injectIntl
export default class LongAnswer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    question: PropTypes.object.isRequired,
    onTypeAnswer: PropTypes.func,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    answer: PropTypes.object,
    index: PropTypes.number,
    highlightedInitialValue: PropTypes.string,
    hideAsterisk: PropTypes.bool,
    hideQuestion: PropTypes.bool,
    automationId: PropTypes.string,
  }

  static defaultProps = {
    className: "",
    hideQuestion: false,
    onBlur: _.noop,
    onTypeAnswer: _.noop,
  }

  render() {
    const {
      className,
      question,
      index,
      answer,
      onTypeAnswer,
      readOnly,
      onBlur,
      highlightedInitialValue,
      hideAsterisk,
      hideQuestion,
      automationId,
      intl: { formatMessage },
    } = this.props

    const { can_share: canShare, title, description, id } = question
    const editorRef = `LongAnswer-${id}`

    const renderShareableIndicator = () =>
      !hideQuestion && canShare && !hideAsterisk ? (
        <div className="LongAnswer--question-shared">
          <Tooltip
            text={formatMessage(strings.customizedQuestions.sharedReminder)}
          >
            <Tag variant="validationInformative" inline>
              <FormattedMessage {...strings.customizedQuestions.shared} />
            </Tag>
          </Tooltip>
        </div>
      ) : null

    const renderOptionalIndicator = () =>
      // The assessment group question is a special snowflake and doesn’t have a “required” attribute
      question.required === false && (
        <span className="LongAnswer--skill-name-optional">
          <FormattedMessage
            {...strings.customizedQuestions.optionalIndicator}
          />
        </span>
      )

    return (
      <div
        className={`LongAnswer ${className}`}
        data-automation-id={automationId}
      >
        <div className="LongAnswer--question layout horizontal">
          {index && (
            <div className="LongAnswer--question-number flex none">
              {index + ". "}
            </div>
          )}
          {!hideQuestion && (
            <div className="LongAnswer--skill-name layout vertical flex one">
              <div className="LongAnswer--skill-name-title">
                <MarkdownText text={title} inline={true} />
                {renderOptionalIndicator()}
              </div>
              {description && (
                <div>
                  <MarkdownText
                    className="LongAnswer--skill-name-description"
                    text={description}
                    inline={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {renderShareableIndicator()}
        {!readOnly ? (
          <TextEditor
            forwardedRef={(editor) => (this[editorRef] = editor)}
            className="LongAnswer--text-editor flex one"
            initialValue={_.get(answer, "text")}
            saveState={_.get(answer, "saveState")}
            onChange={(value) => onTypeAnswer(value)}
            onBlur={(ev, value) => onBlur(ev, value)}
            hasEmojiPicker={false}
            highlightedInitialValue={highlightedInitialValue}
            automationId={Aid.inputFeedback}
          />
        ) : (
          <MarkdownText
            className="LongAnswer--read-only-text"
            text={_.get(answer, "text")}
          />
        )}
      </div>
    )
  }
}
