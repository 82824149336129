import React from "react"
import _ from "lodash"
import Tooltip from "rc-tooltip"
import { FormattedMessage, injectIntl, InjectedIntl } from "react-intl"
import strings from "../../locale/strings"
import BaseEditableMultipleChoice, {
  Props as BaseEditableMultipleChoiceProps,
} from "./BaseEditableMultipleChoice"
import "./EditableRatingScale.less"

const RATING_PLACEHOLDER_SETS: {
  [ratingCount: number]: (
    | "veryPoor"
    | "belowAverage"
    | "average"
    | "aboveAverage"
    | "excellent"
  )[]
} = {
  2: ["belowAverage", "aboveAverage"],
  3: ["belowAverage", "average", "aboveAverage"],
  4: ["veryPoor", "belowAverage", "aboveAverage", "excellent"],
  5: ["veryPoor", "belowAverage", "average", "aboveAverage", "excellent"],
}

export type Props = Omit<
  BaseEditableMultipleChoiceProps,
  | "getChoicePlaceholders"
  | "renderChoiceValue"
  | "funnelOnBlur"
  | "funnelOnFocus"
> & {
  intl: InjectedIntl
  onBlur?: () => void
  onFocus?: () => void
  funnelOnBlur?: (e: React.FocusEvent) => void
  funnelOnFocus?: (e: React.FocusEvent) => void
}

class EditableRatingScale extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
    tooltipsEnabled: true,
  }

  getChoicePlaceholders = ({
    index,
    totalChoices,
  }: {
    index: number
    totalChoices: 2 | 3 | 4 | 5
  }) => {
    const {
      intl: { formatMessage },
    } = this.props
    const placeholderType = RATING_PLACEHOLDER_SETS[totalChoices]?.[index]
    const titleMessage =
      strings.customizedQuestions.ratingScale.choicePlaceholders[
        placeholderType
      ]

    return {
      title: titleMessage ? formatMessage(titleMessage) : "",
      desc: formatMessage(
        strings.customizedQuestions.ratingScale.descriptionPlaceholder
      ),
    }
  }

  renderChoiceValue = ({
    index,
    totalChoices,
    getContainerRef,
  }: {
    index: number
    totalChoices: number
    getContainerRef: () => Element
  }) => {
    const { tooltipsEnabled } = this.props

    return tooltipsEnabled ? (
      <Tooltip
        placement="top"
        overlay={
          <div className="EditableRatingScale--tooltip-overlay">
            <div>
              <FormattedMessage
                {...strings.customizedQuestions.ratingScale.tooltip.title}
              />
            </div>
            <div>
              <FormattedMessage
                {...strings.customizedQuestions.ratingScale.tooltip.subtitle}
                values={{ minValue: 1, maxValue: totalChoices }}
              />
            </div>
          </div>
        }
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        getTooltipContainer={getContainerRef}
      >
        <div className="EditableRatingScale--choice-value layout vertical center-center">
          {index + 1}
        </div>
      </Tooltip>
    ) : (
      <div className="EditableRatingScale--no-tooltip layout vertical">
        <div className="EditableRatingScale--choice-value layout vertical center-center">
          {index + 1}
        </div>
      </div>
    )
  }

  render() {
    const { className, ...otherProps } = this.props

    return (
      <BaseEditableMultipleChoice
        className={`EditableRatingScale ${className}`}
        getChoicePlaceholders={this.getChoicePlaceholders}
        // @ts-ignore: Ignored due to time boxing. Please fix if you have the time.
        renderChoiceValue={(choices) => this.renderChoiceValue(choices)}
        {..._.omit(otherProps, "intl")}
      />
    )
  }
}

export default injectIntl(EditableRatingScale)
export { EditableRatingScale as RawEditableRatingScale }
