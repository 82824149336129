import React from "react"
import { FormattedMessage } from "react-intl"
import cx from "classnames"
import "./ReviewStatus.less"
import strings from "../../locale/strings"
import { useIntl } from "../../hooks/locale/useIntl"

type Status =
  // NOTE: This is for PaUF, it actually shows "Incomplete" as a translation
  | "accepted"
  | "completed"
  | "incomplete"
  | "expired"
  | "error"
  | "creating"
  // NOTE: This shows up as "Draft" in the translations for SR and EE cycles
  | "created"
  | "scheduled"
  | "ready"
  | "active"
  | "closed"
  | "draft"
  | "shared"
  | "dismissed"
  // NOTE: This is for PaUF, it actually shows "Dismissed" as a translation
  | "rejected"

type Props = {
  status: Status
  statusStrings?: Partial<
    {
      [status in Status]: FormattedMessage.MessageDescriptor
    }
  >
  startedFlag?: boolean
}

export default function ReviewStatus({
  status,
  statusStrings = strings.reviewStatus,
  startedFlag = false,
}: Props) {
  const { formatMessage } = useIntl()

  const message = statusStrings[status]

  return (
    <div
      className={cx("ReviewStatus", {
        "ReviewStatus--complete": status === "completed" || status === "active",
        "ReviewStatus--incomplete":
          status === "incomplete" ||
          status === "accepted" ||
          status === "scheduled" ||
          status === "ready" ||
          status === "creating" ||
          status === "dismissed" ||
          status === "created" ||
          status === "draft",
        "ReviewStatus--expired":
          status === "expired" || status === "closed" || status === "shared",
        "ReviewStatus--not-started": startedFlag && status === "incomplete",
      })}
    >
      <span>{message ? formatMessage(message) : null}</span>
    </div>
  )
}
