import axios, { AxiosRequestConfig } from "axios"
import { buildUrl } from "../utils/url"
import { delay } from "../utils/timeout"

const perfApiFetch = async <T>(config: Omit<AxiosRequestConfig, "baseURL">) => {
  try {
    // This adds the ability to pass in express-style pattern matching into the
    // url. It is recommended to pass parameters this way, rather than using
    // template strings, otherwise the url could be subject to bugs with
    // strings not getting properly escaped. Even if you're just passing down a
    // number, it's good to keep the consistency, as so you'll never forget.
    // eg. instead of:
    //   perfApiGet(`/foo/${bar}?hello=${hello}`)
    // do:
    //   perfApiGet("/foo/:bar", { bar, hello })
    const url = config.params
      ? buildUrl(config.url || "", config.params)
      : config.url

    return await axios.request<T>({
      ...config,
      baseURL: "/performance/api/v1/",
      url,
      params: undefined,
    })
  } catch (ex) {
    // Sample error response from performance-api
    // { "error": "Friendly message (sometimes)", "error_id": 123 }
    if (ex.response?.data?.error) {
      throw new Error(ex.response.data.error)
    }
    throw ex
  }
}

export const perfApiGet = async <T>(
  url: string,
  config?: Omit<AxiosRequestConfig, "method" | "url" | "baseURL">
) => {
  return await perfApiFetch<T>({
    ...config,
    url,
    method: "GET",
  })
}

export const perfApiPost = async <T>(
  url: string,
  config?: Omit<AxiosRequestConfig, "method" | "url" | "baseURL">
) => {
  return await perfApiFetch<T>({
    ...config,
    url,
    method: "POST",
  })
}

export const perfApiPut = async <T>(
  url: string,
  config?: Omit<AxiosRequestConfig, "method" | "url" | "baseURL">
) => {
  return await perfApiFetch<T>({
    ...config,
    url,
    method: "PUT",
  })
}

export const perfApiDelete = async <T>(
  url: string,
  config?: Omit<AxiosRequestConfig, "method" | "url" | "baseURL">
) => {
  return await perfApiFetch<T>({
    ...config,
    url,
    method: "DELETE",
  })
}

/* eslint-disable no-console */
export const mockFetch = async <T>(
  endpointName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  returnObj: any
) => {
  console.log(`${endpointName} mock api called with:`, ...args)
  await delay(500)
  console.log(`${endpointName} mock api responded with:`, returnObj)
  return returnObj
}

export const mockError = async (
  endpointName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any[],
  error: Error
) => {
  console.log(`${endpointName} mock api called with:`, ...args)
  await delay(500)
  console.log(`${endpointName} mock api failed with:`, error)
  throw error
}
/* eslint-enable no-console */
