import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import Tooltip from "rc-tooltip"
import cx from "classnames"
import strings from "../../locale/strings"
import ZugataFormattedRelativeDate from "../../components/ZugataFormattedDate/ZugataFormattedRelativeDate"
import MarkdownText from "../../components/MarkdownText/MarkdownText"
import "./ConversationMessage.less"

export const genericMessageProps = {
  // These props are used outside of this component
  id: PropTypes.number.isRequired,
  timestamp: PropTypes.string,
  sender: PropTypes.shape({
    email: PropTypes.string.isRequired,
    full_name: PropTypes.string,
  }),
  isPrivate: PropTypes.bool,
  comments: PropTypes.array,
}
export default class ConversationMessage extends React.Component {
  static propTypes = {
    ...genericMessageProps,
    tooltip: PropTypes.node,
    body: PropTypes.node.isRequired,
    isWhiteBg: PropTypes.bool,
    isPaddingZero: PropTypes.bool,
  }

  static defaultProps = {
    isPrivate: false,
    comments: [],
    isWhiteBg: false,
    isPaddingZero: false,
  }

  render() {
    const { body, timestamp, tooltip, isWhiteBg, isPaddingZero } = this.props
    const message = body ? (
      <div
        className={cx("ConversationMessage", {
          "ConversationMessage-whiteBg": isWhiteBg,
          "ConversationMessage-paddingZero": isPaddingZero,
        })}
        ref={(ref) => (this.container = ref)}
      >
        {timestamp && (
          <div className="ConversationMessage--timestamp">
            <ZugataFormattedRelativeDate value={timestamp} />
          </div>
        )}
        <div className="ConversationMessage--message">
          {typeof body === "string" ? <MarkdownText text={body} /> : body}
        </div>
      </div>
    ) : (
      <div className="faded">
        <em>
          <FormattedMessage {...strings.general.noFeedback} />
        </em>
      </div>
    )

    if (tooltip) {
      return (
        <Tooltip
          placement="top"
          overlay={
            <div className="ConversationMessage--tooltip">{tooltip}</div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
          getTooltipContainer={() => this.container}
        >
          {message}
        </Tooltip>
      )
    } else {
      return message
    }
  }
}
