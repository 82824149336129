import Reflux from "./ActionsInitializer"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import { addLoadingState, extractResponseKey } from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { TEMPLATES_URLS } = endpoints
const { TEMPLATES_URL } = TEMPLATES_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"],
}

const TemplateActions = Reflux.createActions({
  getTemplate: ASYNC_SETTINGS,
})

/* Template Actions
===============================*/

TemplateActions.getTemplate.listenAndPromise(
  ({ subjectId = null, authorIds = [], policyTypes = "" } = {}) =>
    addLoadingState(
      [true, { light: true }],
      extractResponseKey(
        "templates",
        agent.get(TEMPLATES_URL).query({
          subject_id: subjectId,
          author_ids: authorIds && authorIds.join(","),
          policy_types: policyTypes,
        })
      )
    )
)

export default TemplateActions
