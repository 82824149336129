import React, { useState, useMemo, useEffect, useCallback } from "react"
import { InjectedIntl } from "react-intl"
import { Location } from "history"
import { Text, Button } from "@kaizen/component-library"
import { List } from "immutable"
import { InjectedRouter } from "react-router/lib/Router"
import { WithRouterProps } from "react-router"
import { UserSelectorDrawer } from "../../containers/UserSelectorDrawer/UserSelectorDrawer"
import useTeam from "../../domainHooks/teams/useTeam"
import FeedbackRequestHeader from "../../containers/FeedbackRequestHeader/FeedbackRequestHeader"
import FeedbackRequestTypeahead, {
  FeedbackOptionUser,
} from "../../containers/FeedbackRequestTypeahead/FeedbackRequestTypeahead"
import strings from "../../locale/strings"
// @ts-ignore
import MarkdownFormattedMessage from "../../components/MarkdownFormattedMessage/MarkdownFormattedMessage"
// @ts-ignore
import SurveyQuestionsManager from "../../containers/SurveyQuestionsManager/SurveyQuestionsManager"
import { PageLayout } from "../../components/layout/PageLayout/PageLayout"
import { useDossierAndDrawer } from "../../hooks/useDossierAndDrawer"
import styles from "./TeamFeedbackRequest.scss"
import useTeamFeedbackReviewersSuggestions from "../../domainHooks/teamFeedback/useTeamFeedbackReviewersSuggestions"
import useRequestTeamBasedFeedback from "../../domainHooks/teamFeedback/useRequestTeamBasedFeedback"
import useClearSelectedTeam from "../../domainHooks/teams/useClearSelectedTeam"
import useFeedbackQuestionTemplates from "../../domainHooks/teamFeedback/useFeedbackQuestionTemplates"
import FeedbackRequestModal from "../../containers/FeedbackRequestModal/FeedbackRequestModal"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import { injectIntl } from "../../lib/react-intl-with-custom-terms"
import Aid from "../../constants/automationId"
import { Team } from "../../context/TeamsState"

type TeamFeedbackRequest = React.FC<
  {
    router: Pick<InjectedRouter, "push">
    intl: InjectedIntl
    location: Location
  } & WithRouterProps
>

type Question = {
  type: string
  title: string
  description?: string
  required: boolean
}

type TeamMemberId = string | undefined

/**
 * Helper function to set the clickable
 * users for the UserSelector component
 * based on union of all team leads and
 * team members.
 *
 * @param {Team} team Current team in focus
 */
const setUserSelectorUsers = (team: Team) =>
  [...team?.details?.teamMembers, ...team?.details?.teamLeads].map((user) => ({
    id: user.aggregateId,
    name: user.name,
    avatar: user.profileImage,
  })) || []

/**
 * Opts to find the current team member information
 * based on the order of checking team members, team leads
 * or finally returning just the first team member in the
 * scenario that the teamMemberId is not set.
 *
 * @param {Team} [team] Current team in focus
 * @param {string} [teamMemberId] Selected team member set by UserSelector
 */
const getTeamMember = (team?: Team, teamMemberId?: string) =>
  team?.details?.teamMembers.find((t) => t.aggregateId === teamMemberId) ||
  team?.details?.teamLeads.find((t) => t.aggregateId === teamMemberId) ||
  team?.details?.teamMembers[0]

const TeamFeedbackRequest: TeamFeedbackRequest = ({ router, intl, params }) => {
  const { formatMessage } = intl
  const { toggleDrawer, drawerIsOpen } = useDossierAndDrawer()
  const { teamId } = params

  // re-route if no team provided to /teams
  if (!teamId) {
    router.push("teams")
  }

  const { team, loading } = useTeam(teamId)
  const [teamMemberId, setTeamMemberId] = useState<TeamMemberId>(
    team?.details?.teamMembers[0]?.aggregateId
  )
  const [reviewers, setReviewers] = useState<FeedbackOptionUser[]>([])
  const [
    showSuccessConfirmationModal,
    setShowSuccessConfirmationModal,
  ] = useState(false)
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const { showNotification } = useNotifications()
  useClearSelectedTeam()
  // used to keep nullifiable Team type happy
  const teamMember = team ? getTeamMember(team, teamMemberId) : undefined

  const { suggestions } = useTeamFeedbackReviewersSuggestions(
    teamMemberId,
    teamId
  )

  const { template } = useFeedbackQuestionTemplates(
    useMemo(() => reviewers.map((r) => r.id), [reviewers]),
    teamMember && teamMember.id
  )

  const [questions, setQuestions] = useState<Question[]>(
    (template && template.questions) || []
  )

  const { requestFeedback } = useRequestTeamBasedFeedback(
    teamId,
    questions,
    reviewers,
    teamMember
  )

  useEffect(() => {
    const reviewersWithoutSelectedTeamMember = reviewers.filter(
      (reviewer) => reviewer.aggregateId !== teamMemberId
    )
    setReviewers(reviewersWithoutSelectedTeamMember)
    // Don't need to update reviewers if reviwers change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMemberId])

  useEffect(() => {
    if (template) {
      setQuestions(template.questions)
    }
  }, [template])

  const usingCustomQuestions = template?.questions !== questions

  // isDiff returns true if there are unsaved changes on the form
  const isDiff = usingCustomQuestions || reviewers.length > 0

  const handleResetQuestions = useCallback(() => {
    if (template) {
      setQuestions(template.questions)
    }
  }, [template])

  const handleRequestTbf = () => {
    setReviewers([])
    setShowSuccessConfirmationModal(false)
  }

  /**
   * Shows a success/failure notification
   * based on server response to submission
   */
  const submitFeedbackRequest = () =>
    requestFeedback()
      .then((res) => {
        if (res.status !== 200) {
          throw new Error()
        }
        setShowSuccessConfirmationModal(true)
      })
      // Noted during refactor: the error itself here is not used
      // other than to show a notification. Unsure if caught
      // and submitted to Sentry
      .catch((_) => {
        showNotification({
          type: "negative",
          title: "Error",
          message: formatMessage(
            strings.teamLeadRequestFeedback.feedbackRequestError
          ),
        })
      })

  const cancelFeedbackSubmission = () =>
    isDiff ? setShowUnsavedChangesModal(true) : router.push(`/teams/${teamId}`)

  if (!team || !teamMember) return null
  if (loading) return <div className={styles.content}>Loading ...</div>

  const { unsavedChangesModal, successModal } = strings?.teamLeadRequestFeedback

  return (
    <PageLayout
      drawerSettings={{
        isOpen: drawerIsOpen,
        heading: formatMessage(strings.general.teamMembers),
        onToggle: () => toggleDrawer(),
        content: (
          <UserSelectorDrawer
            users={setUserSelectorUsers(team)}
            activeUserId={teamMemberId || teamMember?.aggregateId}
            onSelectUser={setTeamMemberId}
          />
        ),
      }}
    >
      {showUnsavedChangesModal && (
        <FeedbackRequestModal
          type="cautionary"
          title={formatMessage(unsavedChangesModal.title)}
          confirmLabel={formatMessage(unsavedChangesModal.confirmLabel)}
          dismissLabel={formatMessage(unsavedChangesModal.cancel)}
          onDismiss={() => setShowUnsavedChangesModal(false)}
          onConfirm={() => router.push(`/teams/${teamId}`)}
          body={formatMessage(unsavedChangesModal.modalContent)}
        />
      )}
      {showSuccessConfirmationModal && (
        <FeedbackRequestModal
          type="positive"
          title={formatMessage(successModal.title)}
          confirmLabel={formatMessage(successModal.anotherRequest)}
          dismissLabel={formatMessage(successModal.back)}
          onDismiss={() => router.push(`/teams/${teamId}`)}
          onConfirm={handleRequestTbf}
          body={formatMessage(successModal.content)}
        />
      )}
      <div className={styles.content}>
        <FeedbackRequestHeader
          title={formatMessage(strings.teamLeadRequestFeedback.requestFeedback)}
          body={strings.teamLeadRequestFeedback.feedbackAbout}
          teamMember={teamMember}
        />
        <div className={styles.reviewersSelector}>
          <Text tag="p" style="body-bold">
            {formatMessage(strings.teamLeadRequestFeedback.requestFrom)}
          </Text>
        </div>
        <div className={styles.typeaheadContainer}>
          <FeedbackRequestTypeahead
            suggestions={suggestions}
            reviewers={reviewers}
            setReviewers={setReviewers}
            placeholder={formatMessage(
              strings.teamLeadRequestFeedback.selectFromDropdown
            )}
            title={formatMessage(
              strings.teamLeadRequestFeedback.requestFromOtherTeamMembers,
              { team: team?.name }
            )}
          />
        </div>
        <div className={styles.questions}>
          <Text tag="p" style="body-bold">
            {formatMessage(
              strings.teamLeadRequestFeedback.questionsWillBeAsked
            )}
          </Text>
          {template && !usingCustomQuestions && (
            <MarkdownFormattedMessage
              {...strings.teamLeadRequestFeedback.templates[template.type]
                .title}
            />
          )}
          {usingCustomQuestions && (
            <Button
              onClick={handleResetQuestions}
              label={formatMessage(strings.requestFeedback.resetToDefault)}
              secondary
            />
          )}
          <div className={styles.surveyQuestions}>
            <SurveyQuestionsManager
              className="SurveyFeedbackRequests--template-wrapper-questions"
              key={(!usingCustomQuestions && template?.type) || "custom"}
              initialQuestions={List(questions)}
              tooltipsEnabled={false}
              onUpdateQuestion={(questions: List<Question>) =>
                setQuestions(questions.toArray())
              }
            />
          </div>
          <div className={styles.actionButtonsContainer}>
            <div className={styles.cancelButtonContainer}>
              <Button
                onClick={cancelFeedbackSubmission}
                label={formatMessage(strings.feedbackRequests.cancelRequest)}
                automationId={Aid.teamBasedFeedbackCancelRequestButton}
                secondary
              />
            </div>
            <Button
              disabled={questions.length === 0 || reviewers.length === 0}
              onClick={submitFeedbackRequest}
              label={formatMessage(strings.feedbackRequests.sendRequest)}
              automationId={Aid.teamBasedFeedbackSendRequestButton}
              primary
            />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default injectIntl(TeamFeedbackRequest)
