import { JsonDecoder } from "ts.data.json"
import paginationDecoder from "../PaginationDecoder/paginationDecoder"
import { individualSurveyItemDecoder } from "../SurveyItemDecoder/surveyItemDecoder"
import { User } from "../../../types/User"
import {
  SurveyResponse,
  SurveyResponseCreator,
} from "../../../context/SurveyState"
const { array, object, number, string, failover } = JsonDecoder

const subjectDecoder = object<User>(
  {
    name: string,
    id: number,
    aggregateId: failover("", string),
    role: failover("", string),
    avatar: JsonDecoder.object(
      {
        medium_url: JsonDecoder.string,
      },
      "avatar"
    ).map((avatarObj) => avatarObj.medium_url),
    profileImage: string,
    email: string,
  },
  "subjectDecoder",
  {
    name: "best_name",
    role: "job_title_name",
    avatar: "avatar_images",
    aggregateId: "employee_aggregate_id",
    profileImage: "profile_image_url",
  }
)

const creatorDecoder = object<SurveyResponseCreator>(
  {
    name: string,
    fullName: string,
    email: string,
    id: number,
    aggregateId: failover(undefined, string),
  },
  "creatorDecooder",
  {
    fullName: "full_name",
    name: "best_name",
    aggregateId: "employee_aggregate_id",
  }
)

const individualSurveyResponseDecoder = object<SurveyResponse>(
  {
    id: number,
    kind: string,
    state: string,
    subject: subjectDecoder,
    creatorId: failover(undefined, number),
    createdAt: failover(undefined, string),
    acceptedAt: failover(undefined, string),
    completedAt: failover(undefined, string),
    deletedAt: failover(undefined, string),
    updatedAt: failover(undefined, string),
    creator: failover(undefined, creatorDecoder),
    surveyItems: array(
      individualSurveyItemDecoder,
      "survey_response_decoder_individual_survey_item"
    ),
  },
  "individualSurveyResponseDecoder surveyResponse",
  {
    createdAt: "created_at",
    acceptedAt: "accepted_at",
    completedAt: "completed_at",
    deletedAt: "deleted_at",
    updatedAt: "updated_at",
    surveyItems: "survey_items",
    creatorId: "creator_id",
  }
)

export const completedSurveyResponseDecoder = object(
  {
    survey_response: individualSurveyResponseDecoder,
  },
  "completed survey response decoder"
).map<SurveyResponse>((res) => {
  return res.survey_response
})

export const dismissedSurveyResponseDecoder = object(
  {
    survey_response: object(
      {
        id: number,
      },
      "dismissed survey response decoder id"
    ),
  },
  "dismissed survey response decoder"
).map<number>((res) => {
  return res.survey_response.id
})

export const surveyResponseDecoder = object(
  {
    survey_response: individualSurveyResponseDecoder,
  },
  "survey response decoder"
).map<SurveyResponse>((res) => res.survey_response)

export const surveyResponsesDecoder = object(
  {
    survey_responses: array(
      individualSurveyResponseDecoder,
      "surveyResponseDecoder surveyResponse"
    ),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder,
      },
      "meta"
    ),
  },
  "surveyResponsesDecoder"
).map(({ survey_responses, meta: { pagination } }) => ({
  surveyResponses: survey_responses,
  pagination,
}))

export default surveyResponsesDecoder
