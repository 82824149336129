import "./UserNotes.less"
import PropTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import strings from "../../locale/strings"
import TextEditor from "../../components/TextEditor"
import UserActions from "../../refluxActions/UserActions"
import { debounce } from "../../utils/timers"
import connect from "../../higherOrderComponents/connect"
import Settings from "../../settings"
import UserStore from "../../refluxStores/UserStore"

const { AUTOSAVE_DELAY } = Settings

@connect(UserStore, "userData")
@injectIntl
export default class UserNotes extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    toolbarButtonsToUse: PropTypes.arrayOf(
      PropTypes.oneOf(["bold", "ul", "ol", "link"])
    ),
    useRTE: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: "",
  }

  handleNoteChange = (noteBody) => {
    const { user } = this.props

    UserActions.notifyNoteEditing({ noteBody, userId: user.id })
    this.saveNote(noteBody)
  }

  @debounce(AUTOSAVE_DELAY)
  saveNote(noteBody) {
    const { user } = this.props

    UserActions.saveNote({ noteBody, userId: user.id })
  }

  render() {
    const {
      userData: { noteByUserId },
      user,
      intl: { formatMessage },
      toolbarButtonsToUse,
      useRTE,
      className,
    } = this.props

    const note = noteByUserId.get(user.id)
    const name = user.best_name || user.full_name
    return (
      <div className={`UserNotes ${className}`}>
        {note && (
          <TextEditor
            ref={(ref) => (this.notes = ref)}
            initialValue={note.body}
            placeholder={formatMessage(strings.profile.addNote, { name })}
            lastSavedAt={note.updated_at}
            saveState={note.saveState}
            onChange={this.handleNoteChange}
            toolbarButtonsToUse={toolbarButtonsToUse}
            useRTE={useRTE}
          />
        )}
      </div>
    )
  }
}
