import "./ActionsInitializer"
import * as Admin from "../admin/refluxActions"
import User from "./UserActions"
import Review from "./ReviewActions"
import Feedback from "./FeedbackActions"
import Session from "./SessionActions"
import UI from "./UIActions"
import Import from "./ImportActions"
import Contact from "./ContactActions"
import Ontology from "./OntologyActions"
import Search from "./SearchActions"
import Experiment from "./ExperimentActions"
import Stats from "./StatsActions"
import Notification from "./NotificationActions"
import PerformanceReview from "./PerformanceReviewActions"
import SelfReview from "./SelfReviewActions"
import Email from "./EmailActions"
import DevelopmentResource from "./DevelopmentResourceActions"
import Team from "./TeamActions"
import UserSummary from "./UserSummaryActions"
import FeedbackRequests from "./FeedbackRequestsActions"
import ZugiPoints from "./ZugiPointsActions"
import Comments from "./CommentsAction"
import Goal from "./GoalActions"
import Company from "./CompanyActions"
import Draft from "./DraftActions"
import Survey from "./SurveyActions"
import SurveyResponse from "./SurveyResponseActions"
import IncompleteSurveyResponse from "./IncompleteSurveyResponseActions"
import SavedView from "./SavedViewActions"
import Locale from "./LocaleActions"
import Template from "./TemplateActions"

// Needs an implementation file to prevent
// circular dependency between SessionStore and
// SteadyfootAgent
import "./SessionActionsImpl"

export default {
  Admin,
  User,
  Session,
  Review,
  UI,
  Feedback,
  Import,
  Contact,
  Search,
  Experiment,
  Ontology,
  Notification,
  Stats,
  PerformanceReview,
  Email,
  DevelopmentResource,
  Team,
  UserSummary,
  FeedbackRequests,
  SelfReview,
  ZugiPoints,
  Comments,
  Goal,
  Company,
  Draft,
  Survey,
  SurveyResponse,
  IncompleteSurveyResponse,
  SavedView,
  Locale,
  Template,
}
