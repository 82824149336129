// eslint-disable-next-line import/no-named-as-default
import teamsDecoder from "../../api/decoders/TeamsDecoder/teamsDecoder"
import useDataAPI from "../performanceApi/useDataAPI"

interface Filters {
  all_teams?: boolean
  q?: string
  archived_teams?: boolean
}

const perPage = 20

const useTeamsAsync = () => {
  const fetchAPI = useDataAPI("/teams", "GET")

  const fetchTeams = async (
    filters: Filters = { all_teams: false, archived_teams: false }
  ) => {
    const result = await fetchAPI.fire({
      ...filters,
      page: 1,
      per_page: perPage,
    })

    const decodedTeams = await teamsDecoder.decodePromise(result.data)
    return decodedTeams?.teams.map((team) => team)
  }

  return { fetchTeams }
}

export default useTeamsAsync
