import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import ModalDeprecated from "../containers/ModalDeprecated/ModalDeprecated"

/**
 * DEPRECATED - decorator is no longer required. Please create modals by adding
 * to the configuration under src/constants/modals.ts
 *
 * Class decorator for wrapping a component with `<Modal />`. In other words,
 * the resulting class renders a `<Modal />` and the decorated class becomes the
 * content inside the modal. In addition to being a bit more concise, this decorator
 * helps ensure that state is cleared at the right times. When the modal closes
 * and, if configured, when the object viewed in the modal changes, the
 * decorated component is remounted or unmounted as necessary.
 *
 * @param {Object=} options:
 *
 *    - `opened`: optional. If provided, should be a function of props.
 *      It should return `true` if the modal should be shown with the given props,
 *      `false` otherwise. This function will be consulted during render if an
 *      explicit `opened` prop is not passed in.
 *
 *    - `key`: optional. If provided, should be a function of props. When the modal
 *      is open, this will be called to determine the key of the **inner
 *      component** (i.e., the original, decorated class, which renders the
 *      content inside the modal). In this way, a carefully chosen key will ensure
 *      that the content inside the modal is remounted and that state is cleared
 *      when the identity of the object shown by the modal changes.
 *
 *    - `className`: optional. May be a string or a function `className(props, user)`. If it's a
 *      function, it will be called to generate the className for the modal.
 *
 *    - any other values are used as default props for both the `<Modal />` element
 *      and the inner, decorated component. When rendering, any props provided
 *      to the resulting component are merged into these default props.
 *
 * @example
 *
 *    @modal({
 *      className: 'UserModal',
 *      opened: props => !!props.user,
 *      key: props => props.user.id,
 *   })
 *   class UserModal extends React.Component {
 *     render() {
 *       return <div>
 *         <h5>{user.full_name}</h5>
 *         <p>{user.job_title_name}</p>
 *       </div>;
 *     }
 *   }
 *
 *   class UserPage extends React.Component {
 *     render() {
 *       const {userToShow} = this.state;
 *
 *       return <div>
 *         {...}
 *         <UserModal user={userToShow} />
 *       </div>;
 *     }
 *   }
 *
 */
export default function modalDeprecated(optionsOrClass) {
  return typeof optionsOrClass === "function"
    ? modalDecorator(optionsOrClass, {})
    : (InnerComponent) => modalDecorator(InnerComponent, optionsOrClass)
}

function modalDecorator(
  InnerComponent,
  {
    opened: openedPredicate = () => false,
    key: keyFunction = () => "key",
    ...defaultProps
  }
) {
  if (
    typeof openedPredicate !== "function" ||
    typeof keyFunction !== "function"
  ) {
    throw new Error(
      "@modal: the 'opened' and 'key' options, if provided, must be functions of props"
    )
  }

  return class ModalComponentWrapper extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        showConfirmationModal: false,
      }
    }

    static contextTypes = {
      user: PropTypes.object,
    }

    static propTypes = {
      onClose: PropTypes.func.isRequired,
    }

    componentWillReceiveProps(nextProps) {
      if (this.opened() && !this.opened(nextProps)) {
        this.setState({ dirty: false })
      }
    }

    opened(props = this.props) {
      const { opened } = props
      return !!(opened !== undefined
        ? opened
        : openedPredicate(this.getMergedProps(props)))
    }

    getClassName() {
      const defaultClass =
        typeof defaultProps.className === "function"
          ? defaultProps.className(this.getMergedProps(), this.context.user)
          : defaultProps.className

      return cx(defaultClass, this.props.className)
    }

    getMergedProps(props = this.props) {
      return { ...defaultProps, ...props }
    }

    handleDirtinessChange = (dirty, callback) => {
      this.setState({ dirty }, callback ? () => callback() : null)
    }

    handleClose = (...args) => {
      if (this.state.dirty) {
        this.setState({ showConfirmationModal: true })
      } else {
        this.handleConfirmedClose(...args)
      }
    }

    handleConfirmedClose = (...args) => {
      this.props.onClose(...args)
    }

    handleCancelClose = () => {
      this.setState({ showConfirmationModal: false })
    }

    render() {
      const opened = this.opened()
      const mergedProps = this.getMergedProps()
      const className = this.getClassName()

      return (
        <ModalDeprecated
          {...mergedProps}
          className={className}
          opened={opened}
          onClose={this.handleClose}
          onNavigationClose={this.handleConfirmedClose}
          onConfirmationYes={this.handleConfirmedClose}
          onConfirmationNo={this.handleCancelClose}
          showConfirmationModal={this.state.showConfirmationModal}
        >
          {opened && (
            <InnerComponent
              {...mergedProps}
              onDirtinessChange={this.handleDirtinessChange}
              onClose={this.handleClose}
              onConfirmedClose={this.handleConfirmedClose}
              key={keyFunction(mergedProps)}
            />
          )}
        </ModalDeprecated>
      )
    }
  }
}
