import * as _ from "lodash"
import { compile, Key, parse } from "path-to-regexp"
import * as qs from "query-string"
import { parseUrl } from "query-string"
import { QueryStringParams, QueryStringValue } from "../types/url"

export const stringifyQueryParams = (params: QueryStringParams) => {
  if (!params) return ""

  // Remove any null or undefined param values
  let val = _.pick(params, (val) => val != null)
  // Sort the values, as so we get predictable results.
  // Remember, the ordering of object key/values is never guaranteed.
  val = _.zipObject(_.pairs(val).sort())
  return qs.stringify(val)
}

const getParamNames = (pattern: string): string[] => {
  return parse(pattern)
    .filter((m) => typeof m !== "string")
    .map((m) => `${(m as Key).name}`)
}

/**
 * Builds a url with express-style pattern matching. eg. /user/:name
 * @param url - the url
 * @param params - The params to be added. Any param that cannot be added
 * will be added as a query string value instead.
 */
export const buildUrl = (url: string, params: QueryStringParams = {}) => {
  const formattedUrl = compile(url)(params)

  if (!params || !Object.keys(params).length) return formattedUrl

  const paramsInUrl = getParamNames(url)

  let queryStringParams = _.omit(params, (val: QueryStringValue, key: string) =>
    paramsInUrl.includes(key)
  )
  queryStringParams = {
    ...parseUrl(formattedUrl).query,
    ...queryStringParams,
  }

  const queryString = stringifyQueryParams(queryStringParams)

  return !queryString.length
    ? formattedUrl
    : `${parseUrl(formattedUrl).url}?${queryString}`
}
