import * as React from "react"
import cx from "classnames"
import chevronLeftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import { useIntl } from "../../hooks/locale/useIntl"
import strings from "../../locale/strings"
import Button, { Props as ButtonProps } from "../Button/Button"
import styles from "./BackButton.scss"

export type Props = Partial<ButtonProps> & {
  compensateForLeftMargin?: boolean
}

const BackButton = ({ compensateForLeftMargin = false, ...rest }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <span className={cx(compensateForLeftMargin && styles.marginCompensation)}>
      <Button icon={chevronLeftIcon} actionType="secondary" {...rest}>
        {formatMessage(strings.general.back)}
      </Button>
    </span>
  )
}

export default BackButton
