import { useEffect, useState } from "react"

// Debounces the changes of a value.
// If you're looking for the ability to debounce a function, see the
// 'use-debounce' package: https://github.com/xnimorz/use-debounce

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
    // This was disabled to get the linting to pass. It would be good to fix this properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return debouncedValue
}

export default useDebounce
