import { perfApiGet } from "./utils"
import { PaginationRaw } from "../types/Pagination"
import { EmployeeV2 } from "../types/Employee"

// Also see actions/TeamActions.ts, getMemberPreview, which also fetches
// from the same endpoint.
export const fetchDirectReports = async ({
  managerId,
  order,
  groupType,
  perPage,
  page,
}: {
  managerId?: number // If not supplied, it will retrieve the directs for the currently logged in user
  order?: "random" // I'm not sure what other options are available
  groupType?: "directs" // There may be other group types
  page?: number
  perPage?: number
}): Promise<{
  users: EmployeeV2[]
  meta: { pagination: PaginationRaw }
}> => {
  const { data } = await perfApiGet("/users/directs", {
    params: {
      manager_id: managerId,
      order,
      group_type: groupType,
      per_page: perPage,
      page,
    },
  })

  return data
}
