import { FormattedMessage } from "react-intl"
import {
  EvaluationState,
  ModuleMap,
  PaufReviewKind,
  QuestionsListType,
} from "../types/EvaluationCycle"
import strings from "../locale/strings"

type ModuleToStringMapping = {
  [K in keyof ModuleMap]: FormattedMessage.MessageDescriptor
}

export const moduleTitles: ModuleToStringMapping = {
  peer_and_upward_feedback: strings.adminEc.peerAndUpwardFeedback,
  manager_evaluation: strings.adminEc.managerEvaluation,
  self_review: strings.adminEc.selfReview,
}

type QuestionToStringMapping = {
  [K in QuestionsListType]: FormattedMessage.MessageDescriptor
}

export const questionTitles: QuestionToStringMapping = {
  self_review: strings.adminEc.selfReview,
  peer_feedback: strings.adminEc.peerFeedback,
  upward_feedback: strings.adminEc.upwardFeedback,
  manager_evaluation: strings.adminEc.managerEvaluation,
}

export const statusTitles: {
  [state in EvaluationState]: FormattedMessage.MessageDescriptor
} = strings.adminEc.state

export const paufReviewKindTitles: {
  [kind in PaufReviewKind]: FormattedMessage.MessageDescriptor
} = {
  PeerFeedback: strings.ecPaufRequestFeedback.peer,
  UpwardFeedback: strings.ecPaufRequestFeedback.upward,
}
