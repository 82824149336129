import Reflux from "reflux-core"
import * as AdminActions from "../refluxActions"
import BaseIncompleteSurveyResponseStore from "../../refluxStores/BaseIncompleteSurveyResponseStore"

const AdminIncompleteSurveyResponseStore = Reflux.createStore({
  ...BaseIncompleteSurveyResponseStore,

  init() {
    BaseIncompleteSurveyResponseStore.init.call(this, {
      actions: AdminActions.IncompleteSurveyResponse,
    })
  },
})

export default AdminIncompleteSurveyResponseStore
