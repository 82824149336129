import * as React from "react"
import { createContext, useContext, useReducer } from "react"
import { Pagination } from "../api/decoders/PaginationDecoder/paginationDecoder"
import { CurrentUser, User } from "../types/User"
import { getPaginationState, PaginatedResults } from "./GoalsState"

export interface TeamMember extends User {
  membershipId: string
}

// NOTE: there are state issues between network requests
// between Team responses with preview vs details
// this has caused annoying, intermittent and hard to
// debug issues.
export interface Team {
  id: string
  name: string
  description: string
  currentUserCanEdit?: boolean
  preview?: {
    goalCount: number
    memberCount: number
  }
  details?: {
    teamLeads: TeamMember[]
    teamMembers: TeamMember[]
    archivedAt: string
  }
}

interface State {
  teamsById: Record<string, Team>
  myTeams: PaginatedResults<string>
  allTeams: PaginatedResults<string>
  myTeamsSearchResults: PaginatedResults<string>
  allTeamsSearchResults: PaginatedResults<string>
  selectedTeam?: Team | null
}

type Action =
  // null allows us to empty selected team from dispatch
  // @see useClearSelectedTeam.ts
  | { type: "FETCH_TEAM"; payload: Team | null }
  | {
      type: "FETCH_ALL_TEAMS"
      payload: { teams: Team[]; pagination: Pagination }
    }
  | {
      type: "FETCH_MY_TEAMS"
      payload: { teams: Team[]; pagination: Pagination }
    }
  | {
      type: "FETCH_ALL_TEAMS_SEARCH"
      payload: { teams: Team[]; pagination: Pagination }
    }
  | {
      type: "FETCH_MY_TEAMS_SEARCH"
      payload: { teams: Team[]; pagination: Pagination }
    }

const INITIAL_STATE: State = {
  teamsById: {},
  myTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  allTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  myTeamsSearchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  allTeamsSearchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
}

export const teamsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "FETCH_MY_TEAMS": {
      const teamsById: Record<string, Team> = {}
      const myTeams: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        myTeams.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        myTeams: getPaginationState(state.myTeams, pagination, myTeams),
      }
    }

    case "FETCH_ALL_TEAMS": {
      const teamsById: Record<string, Team> = {}
      const allTeams: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        allTeams.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        allTeams: getPaginationState(state.allTeams, pagination, allTeams),
      }
    }

    case "FETCH_ALL_TEAMS_SEARCH": {
      const teamsById: Record<string, Team> = {}
      const searchResults: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        searchResults.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        allTeamsSearchResults: getPaginationState(
          state.allTeamsSearchResults,
          pagination,
          searchResults
        ),
      }
    }

    case "FETCH_MY_TEAMS_SEARCH": {
      const teamsById: Record<string, Team> = {}
      const searchResults: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        searchResults.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        myTeamsSearchResults: getPaginationState(
          state.myTeamsSearchResults,
          pagination,
          searchResults
        ),
      }
    }

    case "FETCH_TEAM": {
      const team = action.payload
      return {
        ...state,
        selectedTeam: team,
      }
    }
  }
}

export const TeamsContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: INITIAL_STATE,
  dispatch: (state) => state,
})

export const TeamsProvider = ({ children }: { children: React.ReactNode }) => {
  const teamsContext = useContext(TeamsContext)
  const [state, dispatch] = useReducer(teamsReducer, teamsContext.state)

  return (
    <TeamsContext.Provider value={{ state, dispatch }}>
      {children}
    </TeamsContext.Provider>
  )
}

export const userBelongsToTeam = (user: CurrentUser, team: Team) =>
  team.details &&
  [...team.details.teamMembers, ...team.details.teamLeads].some(
    (m) => m.aggregateId === user.employee_aggregate_id
  )
