import { Button, Paragraph, Heading, Box } from "@kaizen/component-library"
import { Select } from "@kaizen/draft-select"
import editIcon from "@kaizen/component-library/icons/edit.icon.svg"
import deleteIcon from "@kaizen/component-library/icons/trash.icon.svg"
import moment from "moment"
import * as React from "react"
import { useCallback, useEffect, useState, useContext } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
// @ts-ignore
import FormattedListSentence from "../../components/FormattedListSentence/FormattedListSentence"
// @ts-ignore
import ProgressBar from "../../components/ProgressBar/ProgressBar"
import strings from "../../locale/strings"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import ConfigurationOptions from "../../constants/configurationOptions"
import useDebounce from "../../hooks/timeout/useDebounce"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import useTeam from "../../domainHooks/teams/useTeam"
import useUpdateGoal from "../../domainHooks/goals/useUpdateGoal"
import { userBelongsToTeam } from "../../context/TeamsState"
import { Goal, GoalKeyResult, UpdatingGoal } from "../../types/Goals"
import { CurrentUser as User } from "../../types/User"
import { hasConfigOption, isAdmin } from "../../domain/user/user"
import GoalKeyResults from "./GoalKeyResults"
import ProgressSlider from "../../components/ProgressSlider/ProgressSlider"
import GoalDeletionModal from "./GoalDeletionModal"
import GoalOwnersDetails from "./GoalOwnersDetails"
import OutgoingAlignedGoals from "../AlignedGoals/OutgoingAlignedGoals"
import IncomingAlignedGoals from "../AlignedGoals/IncomingAlignedGoals"
import { CommentsProvider } from "../../context/Comments"
import GoalComments from "./GoalComments"
import styles from "./GoalDetails.scss"
import { useRouter } from "../../hooks/routing/useRouter"
import { TeamSummariesContext } from "../../context/TeamSummariesState"

interface GoalDetailsProps {
  goal: Goal
  intl: InjectedIntl
}

type Option = {
  value: string
  label: string
}

const GoalDetails = ({ goal, intl }: GoalDetailsProps) => {
  const { formatMessage } = intl
  const goalId = goal.id
  const user = useCurrentUser()
  const router = useRouter()
  const { team } = useTeam((goal.type === "team" && goal.teamId) || undefined)
  const {
    updateGoal,
    success: updateSuccess,
    error: updateError,
  } = useUpdateGoal(goalId)
  const { close: closeDossier } = useGoalDossier(router, router?.location)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { showNotification } = useNotifications()
  const [progress, setProgress] = useState<number | null>(null)
  const [keyResultsProgress, setKeyResultsProgress] = useState<
    Record<string, number>
  >({})

  const statusLabels = {
    // Goals with a status of 'Created' should be displayed as being 'In progress' in the UI
    ongoing: strings.goals.status.ongoing,
    created: strings.goals.status.ongoing,
    blocked: strings.goals.status.blocked,
    accomplished: strings.goals.status.accomplished,
  }

  const [selectedStatus, setSelectedStatus] = useState<Option>({
    value: goal.status,
    label: formatMessage(
      statusLabels[goal.status as keyof typeof statusLabels]
    ),
  })

  const debouncedProgress = useDebounce(progress, 500)
  const debouncedKeyResultsProgress = useDebounce(keyResultsProgress, 500)
  const { dispatch } = useContext(TeamSummariesContext)

  const getKeyResults = useCallback(
    (): GoalKeyResult[] | undefined =>
      goal.keyResults.map((kr) => ({
        ...kr,
        completion:
          keyResultsProgress[kr.id] !== undefined
            ? keyResultsProgress[kr.id]
            : kr.completion,
      })),
    [goal, keyResultsProgress]
  )

  const keyResults = getKeyResults()

  const shouldShowGoalComments = (goal: Goal) => {
    if (goal.type === "department" || goal.type === "company") {
      return false
    } else if (goal.visibility === "owner") {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (debouncedProgress !== null) {
      // @ts-ignore
      const updatedGoal: UpdatingGoal = {
        completion: debouncedProgress / 100,
      }
      updateGoal(updatedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProgress])

  useEffect(() => {
    if (Object.keys(debouncedKeyResultsProgress).length > 0) {
      // @ts-ignore
      const updatedGoal: UpdatingGoal = {
        keyResults: getKeyResults(),
      }
      updateGoal(updatedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyResultsProgress])

  useEffect(() => {
    if (selectedStatus.value !== goal.status) {
      // @ts-ignore
      const updatedGoal: UpdatingGoal = {
        status: selectedStatus.value,
      }
      updateGoal(updatedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus])

  useEffect(() => {
    // update status in UI to 'accomplished' if progress is %100
    if (goal.completion === 1 && selectedStatus.value !== "accomplished") {
      setSelectedStatus({
        value: "accomplished",
        label: formatMessage(
          statusLabels["accomplished" as keyof typeof statusLabels]
        ),
      })
    }
  }, [formatMessage, goal.completion, selectedStatus.value, statusLabels])

  useEffect(() => {
    if (updateSuccess) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.goalsPage.goalsDossier.successTitle),
        message: formatMessage(
          strings.goalsPage.goalsDossier.successNotification
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess])

  useEffect(() => {
    if (updateError) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.goalsPage.goalsDossier.errorTitle),
        message: formatMessage(
          strings.goalsPage.goalsDossier.errorNotification
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError])

  useEffect(() => {
    setProgress(null)
    setKeyResultsProgress({})
  }, [goalId])

  const handleDeleteGoal = useCallback(() => {
    setShowDeleteConfirmation(true)
  }, [])

  const handleKeyResultChange = useCallback(
    (keyResultId: string, progress: number) => {
      setKeyResultsProgress((kr) => ({
        ...kr,
        [keyResultId]: progress / 100,
      }))
    },
    []
  )

  const renderVisibilityText = (goal: Goal) => {
    switch (goal.type) {
      case "personal":
        if (goal.visibility === "individuals") {
          return (
            <FormattedListSentence
              base={strings.goals.individualsVisibility}
              items={goal.individuals}
              options={{
                itemRenderer: ({ name }: { name: string }) => name,
              }}
            />
          )
        } else {
          return formatMessage({
            ...strings.goals.visibilityLabel[
              goal.visibility === "manager"
                ? "myManager"
                : goal.visibility === "owner"
                ? "onlyMe"
                : "everyone"
            ],
          })
        }
      case "team":
        return formatMessage({
          ...strings.goals.visibilityLabel[
            goal.visibility === "team_only" ? "teamOnly" : "everyone"
          ],
        })
      default:
        return ""
    }
  }

  // User department permissions ---------------------------------------------

  const userHrbPartners = (user: User) =>
    user.hr_business_partners
      ? user.hr_business_partners.map((department) => department.id)
      : []

  const userManagedDepartments = (user: User) =>
    user.department_managers
      ? user.department_managers.map((department) => department.department_id)
      : []

  const userCanEditDepartmentGoal = (user: User, goal: Goal) =>
    isAdmin(user) ||
    (goal.type === "department" &&
      userHrbPartners(user).includes(goal.departmentId)) ||
    (goal.type === "department" &&
      userManagedDepartments(user).includes(goal.departmentId))

  // ------------------------------------------------------------------------

  const userCanEdit =
    goal.type === "personal" ||
    (goal.type === "team" && team && userBelongsToTeam(user, team)) ||
    (goal.type === "department" && userCanEditDepartmentGoal(user, goal)) ||
    (goal.type === "company" && isAdmin(user))

  const handleEditGoal = () => {
    const redirectTo =
      goal.type === "personal"
        ? `/new_goals/personal/edit/${goalId}`
        : goal.type === "team"
        ? `/new_goals/team/${goal.teamId}/edit/${goalId}`
        : goal.type === "department"
        ? `/new_goals/department/${goal.departmentId}/edit/${goalId}`
        : goal.type === "company"
        ? `/new_goals/company/edit/${goalId}`
        : ""

    router.push(redirectTo)
  }

  return (
    <div className={styles.container}>
      <React.Fragment>
        {showDeleteConfirmation && (
          <GoalDeletionModal
            goalId={goalId}
            onGoalDeleted={() => {
              if (team && team.id) {
                dispatch({
                  type: "UPDATE_TEAM_SUMMARIES",
                  payload: { teamId: team.id },
                })
              }
              closeDossier()
            }}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}

        <Heading variant="heading-4">{goal.name}</Heading>
        <Box mt={1} mb={1}>
          <Paragraph variant="body">{goal.description}</Paragraph>
        </Box>

        <div className={styles.statusInfoContainer}>
          <div>
            <Heading variant="heading-5">
              {formatMessage(strings.goalsPage.goalsDossier.dueDate)}
            </Heading>
            <Paragraph variant="body">{`${moment(goal.dueDate).format(
              "MMMM DD, YYYY"
            )}`}</Paragraph>
          </div>
          <div className={styles.status}>
            <Heading variant="heading-5">
              {formatMessage(strings.goalsPage.goalsDossier.status)}
            </Heading>
            {userCanEdit ? (
              <Select
                id="status"
                key="status"
                value={selectedStatus}
                options={[
                  {
                    value: "ongoing",
                    label: formatMessage(strings.goals.status.ongoing),
                  },
                  {
                    value: "blocked",
                    label: formatMessage(strings.goals.status.blocked),
                  },
                  {
                    value: "accomplished",
                    label: formatMessage(strings.goals.status.accomplished),
                  },
                ]}
                // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
                onChange={(value: Option) => {
                  setSelectedStatus(value)
                }}
                isSearchable={false}
                disabled={true}
              />
            ) : (
              <Paragraph variant="body">{selectedStatus.label}</Paragraph>
            )}
          </div>
        </div>
        {goal.type === "team" && goal.owners && (
          <div className={styles.owners}>
            <GoalOwnersDetails owners={goal.owners} />
          </div>
        )}
        {keyResults && keyResults.length > 0 ? (
          <div
            data-automation-id="goal-details-progress-label"
            className={styles.progress}
          >
            <Heading variant="heading-4">
              {formatMessage(strings.goalsPage.goalsDossier.goalProgress)}
            </Heading>
            <Heading variant="heading-5">
              {`${Math.round(goal.completion * 100)}% ${formatMessage(
                strings.goalsPage.goalsDossier.complete
              )}`}
            </Heading>
          </div>
        ) : (
          <React.Fragment>
            {userCanEdit ? (
              <div className={styles.progressSlider}>
                <Heading variant="heading-4">
                  {formatMessage(strings.goalsPage.goalsDossier.goalProgress)}
                </Heading>
                <div
                  data-automation-id="goal-details-progress-slider"
                  className={styles.slider}
                >
                  <ProgressSlider
                    step={5}
                    progress={
                      progress !== null ? progress : goal.completion * 100
                    }
                    onProgressChange={setProgress}
                  />
                </div>
              </div>
            ) : (
              <div data-automation-id="goal-details-progress-bar">
                <ProgressBar value={goal.completion} />
              </div>
            )}
          </React.Fragment>
        )}
        <GoalKeyResults
          keyResults={keyResults || []}
          onProgressChange={handleKeyResultChange}
          readOnly={!userCanEdit}
        />
        <div className={styles.goalAlignmentsLabel}>
          <Heading variant="heading-4">
            {formatMessage(
              strings.goalsPage.goalsDossier.goalAlignments.goalAlignmentLabel
            )}
          </Heading>
        </div>
        {goal.type !== "company" && (
          <OutgoingAlignedGoals
            goal={goal}
            hasTeamGoalsEnabled={hasConfigOption(
              user,
              ConfigurationOptions.teamGoals
            )}
          />
        )}
        {goal.type !== "personal" && (
          <IncomingAlignedGoals
            goalType={goal.type}
            incomingAlignedGoalsCounts={goal.incomingAlignedGoalsCount}
            hasTeamGoalsEnabled={hasConfigOption(
              user,
              ConfigurationOptions.teamGoals
            )}
          />
        )}

        {shouldShowGoalComments(goal) && (
          <div className={styles.comments}>
            <Heading variant="heading-6">
              {formatMessage(strings.goalsPage.goalsDossier.comments)}
            </Heading>
            <CommentsProvider>
              <GoalComments
                goalId={String(goalId)}
                currentUserCanComment={goal.visibility !== "owner"}
              />
            </CommentsProvider>
          </div>
        )}
        <div
          data-automation-id="goal-details-action-buttons"
          className={styles.actionsContainer}
        >
          <Paragraph variant="small">{renderVisibilityText(goal)}</Paragraph>
          {userCanEdit && (
            <div className={styles.actions}>
              <Button
                label={formatMessage(strings.teamGoals.delete)}
                icon={deleteIcon}
                secondary
                onClick={handleDeleteGoal}
              />
              <Button
                label={formatMessage(strings.general.edit)} // replace with strings.teamGoals.edit when translations arrive
                icon={editIcon}
                secondary
                onClick={() => handleEditGoal()}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  )
}

export default React.memo(injectIntl(GoalDetails))
