import React, { memo, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderRowCell,
  TableRowCell,
} from "@kaizen/draft-table"
import {
  Box,
  Heading,
  IconButton,
  InlineNotification,
} from "@kaizen/component-library"
import clearIcon from "@kaizen/component-library/icons/clear.icon.svg"
import {
  EvaluationCycle,
  RequestedReview,
  RequestedReviewState,
} from "../../types/EvaluationCycle"
import strings from "../../locale/strings"
import { useIntl } from "../../hooks/locale/useIntl"
import TableCardRow from "../../components/TableCardRow/TableCardRow"
import useGetResponsiveValue from "../../hooks/dom/useGetResponsiveValue"
import ReviewStatus from "../../containers/ReviewStatus/ReviewStatus"
import AvatarDetails from "../../components/AvatarDetails/AvatarDetails"
import { formatDateToLocalDate } from "../../utils/date"
import { getHasPaufCompletionStarted } from "../../domain/evaluationCycles/evaluationCycles"
import { getCanManagerWithdrawReview } from "../../domain/evaluationCycles/paufReviewerSelection"
import { paufReviewKindTitles } from "../../constants/evaluationCycles"
import User from "../../models/User"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import FeatureFlags from "../../constants/featureFlags"

type Props = {
  cycle: EvaluationCycle
  requestedReviews: RequestedReview[]
  onWithdrawClick: (id: number) => void
  forwardRef: React.RefObject<HTMLElement>
}

const statusStrings: {
  [state in RequestedReviewState]: FormattedMessage.MessageDescriptor
} & { scheduled: FormattedMessage.MessageDescriptor } =
  strings.ecPaufRequestFeedback.status

// This is for when the reviewer selection stage has started, but the
// review completion has not yet started. During user testing, there
// was some confusion when the status of every requested review was marked as
// "Incomplete". It implied that the reviewee has received a notification, and
// we're waiting for them to complete the review. But no. That stage had
// not yet started.
// So the fix is to replace all status values with "Scheduled".
// Ideally, this should come from perf-api instead, however, due to time
// constraints, we're doing the quick fix on the frontend.
export const _getRequestedReviewStatus = (
  requestedReview: RequestedReview,
  makeStatusScheduled: boolean
) => (makeStatusScheduled ? "scheduled" : requestedReview.state)

const RequestedReviewRow = ({
  requestedReview,
  columns,
  onWithdrawClick,
  makeStatusScheduled,
}: {
  requestedReview: RequestedReview
  columns: {
    heading: string
    flexWidth: string
  }[]
  onWithdrawClick: (id: number) => void
  makeStatusScheduled: boolean
}) => {
  const { formatMessage } = useIntl()
  const { id, author, kind } = requestedReview
  const state = _getRequestedReviewStatus(requestedReview, makeStatusScheduled)

  const handleWithdrawClick = useCallback(() => {
    onWithdrawClick(id)
  }, [id, onWithdrawClick])

  return (
    <TableCardRow>
      <TableRowCell flex={columns[0].flexWidth}>
        <AvatarDetails
          bestName={author.best_name}
          avatarURL={author.profile_image_url}
          avatarSize="small"
        />
      </TableRowCell>
      <TableRowCell flex={columns[1].flexWidth}>
        {author.job_title}
      </TableRowCell>
      <TableRowCell flex={columns[2].flexWidth}>
        {paufReviewKindTitles[kind] &&
          formatMessage(paufReviewKindTitles[kind])}
      </TableRowCell>
      <TableRowCell flex={columns[3].flexWidth}>
        <ReviewStatus status={state} statusStrings={statusStrings} />
      </TableRowCell>
      <TableRowCell flex={columns[4].flexWidth}>
        {getCanManagerWithdrawReview(requestedReview) && (
          <IconButton
            label={formatMessage(strings.ecPaufRequestFeedback.withdrawRequest)}
            icon={clearIcon}
            onClick={handleWithdrawClick}
          />
        )}
      </TableRowCell>
    </TableCardRow>
  )
}

const RequestedReviewsSection = ({
  cycle,
  requestedReviews,
  onWithdrawClick,
  forwardRef,
}: Props) => {
  const { formatMessage } = useIntl()
  const currentUser = useCurrentUser()

  const getResponsiveValue = useGetResponsiveValue()

  if (!requestedReviews.length) return null

  // Currently we're in a transition, where some users have the new top navigation,
  // and some have the legacy sidebar on the left. Those who have the legacy sidebar,
  // have a very small amount of room for this table, so the columns must be much narrower.
  const hasLegacySidebar = !User.hasFlag(
    currentUser,
    FeatureFlags.topNavigation
  )
  const columns = [
    {
      heading: strings.ecPaufRequestFeedback.requestsHeaders.feedbackProvider,
      flexWidth: { 0: "1 1 auto" },
    },
    {
      heading: strings.ecPaufRequestFeedback.requestsHeaders.role,
      flexWidth: hasLegacySidebar
        ? { 0: "0 0 120px", 900: "0 0 170px", 1100: "0 0 170px" }
        : { 0: "0 0 120px", 800: "0 0 150px", 1060: "0 0 200px" },
    },
    {
      heading: strings.ecPaufRequestFeedback.requestsHeaders.feedbackType,
      flexWidth: hasLegacySidebar
        ? { 0: "0 0 100px" }
        : { 0: "0 0 100px", 950: "0 0 130px", 1060: "0 0 180px" },
    },
    {
      heading: strings.ecPaufRequestFeedback.requestsHeaders.status,
      flexWidth: hasLegacySidebar
        ? { 0: "0 0 130px" }
        : { 0: "0 0 130px", 950: "0 0 170px", 1060: "0 0 200px" },
    },
    // The "withdraw request" column
    { heading: null, flexWidth: { 0: "0 0 86px" } },
  ].map((c) => ({
    ...c,
    heading: c.heading ? formatMessage(c.heading) : "",
    flexWidth: getResponsiveValue(c.flexWidth),
  }))

  const hasPaufCompletionStarted = !getHasPaufCompletionStarted(
    cycle,
    new Date()
  )

  return (
    <section ref={forwardRef}>
      <Box mb={1} mt={1}>
        <Heading variant="heading-3">
          {formatMessage(strings.ecPaufRequestFeedback.requestsSent)}
        </Heading>
      </Box>
      {hasPaufCompletionStarted && (
        <InlineNotification
          type="informative"
          title={formatMessage(strings.ecPaufRequestFeedback.requestsScheduled)}
          autohide={false}
          hideCloseIcon={true}
        >
          {formatMessage(
            strings.ecPaufRequestFeedback.requestsScheduledMessage,
            {
              date: formatDateToLocalDate(
                cycle.modules.peer_and_upward_feedback.completion_start_date
              ),
            }
          )}
        </InlineNotification>
      )}
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            {columns.map((c, i) => (
              <TableHeaderRowCell
                key={i}
                labelText={c.heading}
                flex={c.flexWidth}
              />
            ))}
          </TableHeaderRow>
        </TableHeader>
        {requestedReviews.map((r) => (
          <RequestedReviewRow
            key={r.id}
            requestedReview={r}
            columns={columns}
            onWithdrawClick={onWithdrawClick}
            makeStatusScheduled={hasPaufCompletionStarted}
          />
        ))}
      </TableContainer>
    </section>
  )
}

export default memo(RequestedReviewsSection)
