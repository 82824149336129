/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from "lodash"
import { diff as deepDiff } from "deep-diff"
import { DeepPartial } from "../types/utils"

/**
 * Returns a new object, that includes only the items properties that
 * have changed.
 */
export const getObjectDiff = (objBefore: any, objAfter: any) => {
  const diff = deepDiff(objBefore, objAfter)

  if (diff) {
    const lhs = {}
    const rhs = {}
    diff.forEach((elem) => {
      // I'm not sure the situation where `path` will be undefined, but
      // the type definition says that it's possible
      if (elem.kind === "N" && elem.path) {
        // New
        _.set(rhs, elem.path, elem.rhs)
      } else if (elem.kind === "E" && elem.path) {
        // Edited
        _.set(rhs, elem.path, elem.rhs)
        _.set(lhs, elem.path, elem.lhs)
      } else if (elem.kind === "D" && elem.path) {
        // Deleted
        _.set(lhs, elem.path, elem.lhs)
      } else if (elem.kind === "A" && elem.path) {
        // In an array - just show the entire array for a quick implementation.
        // Maybe later this can be enhanced we can return just the specific
        // array updates
        _.set(lhs, elem.path, _.get(objBefore, elem.path))
        _.set(rhs, elem.path, _.get(objAfter, elem.path))
      }
    })

    return { lhs, rhs }
  } else {
    return null
  }
}

const mapArrayOrObject = (arrOrObj: any[] | object, fn: (val: any) => any) => {
  if (_.isArray(arrOrObj)) {
    return arrOrObj.map(fn)
  } else {
    return _.mapValues(arrOrObj, fn)
  }
}

// Takes a nested object, and converts any ImmutableJS object into regular
// js objects
// No need to pass the `objectReferencePath` parameter, that's for internal
// recursive purposes.
export const simplifyImmutableJsObject = (
  obj: any,
  circularReferenceReplacement: any = null,
  objectReferencePath: object[] = []
): any => {
  if (!obj) return obj
  if (obj.toJS) return obj.toJS()
  if (typeof obj !== "object") return obj

  // If the object has a cyclic object reference, this recursive function will
  // run to infinity.
  if (objectReferencePath.includes(obj)) return circularReferenceReplacement

  return mapArrayOrObject(obj, (val) => {
    if (val && val.toJS) {
      return val.toJS()
    } else if (typeof val === "object") {
      return simplifyImmutableJsObject(val, circularReferenceReplacement, [
        ...objectReferencePath,
        obj,
      ])
    }
    return val
  })
}

// See `removeEmptyValues` below
const removeEmptyValuesRecursive = <T>(val: T, level: number): any => {
  if (_.isArray(val)) {
    const newArr = val.map((v) => removeEmptyValuesRecursive(v, level + 1))
    if (newArr.every((v) => v == null)) {
      return level === 0 ? [] : undefined
    } else {
      return newArr
    }
  } else if (_.isObject(val)) {
    let newObj = _.mapValues(val, (v) =>
      removeEmptyValuesRecursive(v, level + 1)
    )
    newObj = _.omit(newObj, (v) => v == null)

    if (_.isEqual({}, newObj)) {
      return level === 0 ? {} : undefined
    } else {
      return newObj
    }
  } else {
    // eg. strings, numbers, booleans
    return val
  }
}

/**
 * Takes in an object or array, and removes any value that is `undefined`, `null`, `[]`, or `{}`
 * It will also remove an object/array, if every value in that object/array is empty.
 *
 * Use case: for our validation, we want to return an object that shows the
 * appropriate errors. It isn't a huge deal if we have a bunch of undefined
 * entries - the validations will still function fine. It just make the unit
 * tests a bit messy.
 *
 * Note: this function was designed for plain old data structures. If you pass in classes,
 * or anything that plays with `prototype`, expect unexpected results.
 */
export const removeEmptyValues = <T>(
  val: T & (object | any[])
): DeepPartial<T> => {
  return removeEmptyValuesRecursive(val, 0) as DeepPartial<T>
}
