import Reflux from "reflux-core"
import _ from "lodash"
import PerformanceReviewAdminActions from "../admin/refluxActions/PerformanceReviewAdminActions"
import AdminSurveyResponseActions from "../admin/refluxActions/AdminSurveyResponseActions"

export const createFiltersStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        optionalAttributesById: {},
      }

      this.listenTo(
        PerformanceReviewAdminActions.loadFilterOptions.completed,
        this.onLoadFilters
      )
      this.listenTo(
        AdminSurveyResponseActions.loadFilterOptions.completed,
        this.onLoadFilters
      )
    },

    getInitialState() {
      return this.data
    },

    onLoadFilters({ results }) {
      // We only care about optional attributes
      const optionalAttributes = _.filter(
        results,
        (f) => f.attribute_type !== undefined
      )
      const attributesById = _.indexBy(optionalAttributes, "id")

      this.data = {
        ...this.data,
        optionalAttributesById: {
          ...this.data.optionalAttributesById,
          ...attributesById,
        },
      }
      this.trigger(this.data)
    },
  })

export default createFiltersStore()
