import { useContext, useEffect } from "react"
import getCommentsDecoder from "../../api/decoders/GetCommentsDecoder/getCommentsDecoder"
import { Comment, CommentsContext } from "../../context/Comments"
import useDataAPI from "../performanceApi/useDataAPI"

export type CommentSourceType =
  | "goal"
  | "evaluation"
  | "self_reflection"
  | "mrf"

const useComments = (
  sourceId: number,
  sourceType: CommentSourceType
): { comments: Comment[]; loading: boolean } => {
  const { state: comments, dispatch } = useContext(CommentsContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading, error, fire } = useDataAPI("/comments", "GET")

  useEffect(() => {
    fire({
      source_obj_id: sourceId,
      source_obj_type: sourceType,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, sourceType])

  useEffect(() => {
    if (data) {
      const comments = getCommentsDecoder(data)
      dispatch({ type: "FETCH_COMMENTS", payload: { sourceId, comments } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return { comments: comments.commentsBySourceId[sourceId] || [], loading }
}

export default useComments
