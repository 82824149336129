import moment from "moment-timezone"

/**
 * Takes a timezone as a string, and returns a new date, with the same
 * year, month, date, hour, minute and second values, with the timezone set
 * to the user's local timezone.
 *
 * NOTE: THIS IS NOT THE SAME AS CONVERTING A DATE TO LOCAL TIME. We are basically
 * just setting the timezone value, not converting the date to a corresponding
 * timezone.
 *
 * The purpose of this function was to get around an issue with the
 * `react-flatpickr` library, as it only accepted dates in local
 * time.
 */
export const replaceTimezoneAsLocal = (
  date: string | undefined | null,
  oldTimezone: string
) => {
  if (!date) return date
  if (!oldTimezone) throw Error("Timezone not supplied")

  const d = moment(date).tz(oldTimezone)

  // convert the date/time to local time
  return moment([
    d.year(),
    d.month(),
    d.date(),
    d.hour(),
    d.minute(),
    d.second(),
    d.millisecond(),
  ]).format()
}

/**
 * Same as `replaceTimezoneAsLocal` above, but the opposite
 */
export const replaceLocalDateWithTimezone = (
  date: string,
  timezone: string
) => {
  if (!timezone) throw Error("Timezone not supplied")
  if (!date) return date

  const d = moment(date)

  return moment
    .tz(
      [
        d.year(),
        d.month(),
        d.date(),
        d.hour(),
        d.minute(),
        d.second(),
        d.millisecond(),
      ],
      timezone
    )
    .format()
}

/**
 * Same as `replaceTimezoneAsLocal`, you can specify the timezone to replace
 */
export const replaceTimezone = (
  date: string | undefined | null,
  oldTimezone: string,
  newTimezone: string
) => {
  if (!oldTimezone) throw Error("Old timezone not supplied")
  if (!newTimezone) throw Error("New timezone not supplied")
  if (!date) return date

  const d = moment(date).tz(oldTimezone)

  return moment
    .tz(
      [
        d.year(),
        d.month(),
        d.date(),
        d.hour(),
        d.minute(),
        d.second(),
        d.millisecond(),
      ],
      newTimezone
    )
    .format()
}

export const getLocalTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone ||
  moment.tz.guess() /* required for ie11 */

export const convertToUtc = (date: string | undefined | null) =>
  date ? moment(date).utc().format() : date

export const formatDateToLocalTime = (date: string | null | undefined) => {
  if (!date) return date
  return moment(date).format("LLL")
}

export const formatDateToLocalDate = (date: string | null | undefined) => {
  if (!date) return date
  return moment(date).format("LL")
}

/**
 * Returns the passed in date (usually will be the current date), and converts
 * it to the passed in timezone. Then, it sets the time to 00:00:00 (ie. midnight).
 * Use case - say you have a date picker, and you'd like to disable all dates
 * prior to today. You could just get the current date, but, you also have
 * a timezone selection. If you're in Melbourne, it's 9am, and you've selected
 * the New York timezone, the minimum start date should actually be yesterday,
 * not today.
 *
 * Then...the returned result is in local time. The reason for this is because
 * our date picker library requires the date in that format.
 */
export const getStartOfDayAsLocalTime = (date: Date, timezone?: string) => {
  const convertedDate = timezone ? moment(date).tz(timezone) : moment(date)

  return moment([
    convertedDate.year(),
    convertedDate.month(),
    convertedDate.date(),
  ]).format()
}
