import React from "react"
import { FormattedMessage } from "react-intl"
import strings from "../../locale/strings"
import "./NoManagerText.less"

const NoManagerText = () => (
  <div className="NoManagerText">
    <FormattedMessage {...strings.general.noManager} />
  </div>
)

export default NoManagerText
