import { Button } from "@kaizen/component-library"
import * as React from "react"
import { useState } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import useComments from "../../domainHooks/goals/useComments"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import Comment from "../../components/Comment/Comment"
import GoalCommentTextField from "./GoalCommentTextField"
import styles from "./GoalComments.scss"
import strings from "../../locale/strings"

type GoalCommentsProps = {
  goalId: string
  currentUserCanComment: boolean
  intl: InjectedIntl
}

const GoalComments = ({
  goalId,
  currentUserCanComment,
  intl,
}: GoalCommentsProps) => {
  const [showReply, setShowReply] = useState<number[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { comments, loading: loadingComments } = useComments(
    Number(goalId),
    "goal"
  )
  const currentUser = useCurrentUser()

  const renderReply = (commentId: number) => {
    return (
      <React.Fragment>
        {showReply.find((s) => s === commentId) ? (
          <div className={styles.textFieldContainer}>
            <GoalCommentTextField
              goalId={Number(goalId)}
              placeholder={intl.formatMessage(
                strings.general.commentPlaceholder
              )}
              avatarURL={currentUser.profile_image_url}
              parentCommentId={commentId}
            />
          </div>
        ) : (
          currentUserCanComment && (
            <div className={styles.commentActions}>
              <Button
                label={intl.formatMessage(strings.goals.conversations.reply)}
                onClick={() => setShowReply([...showReply, commentId])}
                secondary
              />
            </div>
          )
        )}
      </React.Fragment>
    )
  }

  return (
    <div className={styles.commentsContainer}>
      <div className={styles.comments}>
        {comments.map((comment) => (
          <div className={styles.commentThread} key={comment.id}>
            <Comment
              sender={comment.author.name}
              avatarURL={comment.author.profileImage}
              message={comment.body}
              sent={comment.dateSent}
            >
              {comment.replies &&
                comment.replies.map((replyComment) => (
                  <Comment
                    key={replyComment.id}
                    sender={replyComment.author.name}
                    avatarURL={comment.author.profileImage}
                    message={replyComment.body}
                    sent={replyComment.dateSent}
                  />
                ))}
              {renderReply(comment.id)}
            </Comment>
          </div>
        ))}
      </div>
      {currentUserCanComment && (
        <div className={styles.mainMessage}>
          <GoalCommentTextField
            goalId={Number(goalId)}
            placeholder={intl.formatMessage(strings.general.commentPlaceholder)}
            avatarURL={currentUser.profile_image_url}
          />
        </div>
      )}
    </div>
  )
}
export default injectIntl(GoalComments)
