import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import _ from "lodash"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import DepartmentGoalForm, {
  DepartmentGoalFields,
} from "../DepartmentGoalForm/DepartmentGoalForm"
import useCreateDepartmentGoal from "../../domainHooks/goals/useCreateDepartmentGoal"
import useDepartments from "../../domainHooks/goals/useDepartments"
import strings from "../../locale/strings"
import { getGoalCycle, isAdmin } from "../../domain/user/user"
import { newKeyResult } from "../../domainHooks/goals/goalKeyResults"
import { TeamGoalOwner } from "../../types/Goals"
import { Department } from "../../types/User"

type NewDepartmentGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
  params: {
    departmentId: string
  }
}>

export const formDisabled = (loading: boolean, departments: Department[]) =>
  loading || departments.length === 0

const NewDepartmentGoal: NewDepartmentGoal = ({ intl, router, params }) => {
  const { departmentId } = params
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const currentUser = useCurrentUser()
  // NOTE: Do we need to have a search term here?
  const { departments } = useDepartments()
  const {
    createDepartmentGoal,
    loading,
    data,
    error,
  } = useCreateDepartmentGoal()

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalSuccessTitle
        ),
        message: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalSuccessNotification
        ),
      })
      router.push({
        pathname: `/new_goals/department`,
        query: {
          // @ts-ignore
          goalId: data.goal.id,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalErrorTitle
        ),
        message: formatMessage(
          strings.newDepartmentGoal.newDepartmentGoalErrorNotification
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  /**
   * Notice here that we are sending the parameters expected from the new current user.
   * This should be fixed when we update our new current user to be camel case and we can
   * transform different types as TeamLead, TeamGoalOwner, etc to be a partial from the CurrentUser.
   */
  const initOwners: TeamGoalOwner[] = [
    {
      aggregateId: currentUser.employee_aggregate_id,
      role: currentUser.job_title_name || "",
      profileImage: currentUser.profile_image_url,
      name: currentUser.best_name,
      avatar: "",
    },
  ]
  const newGoal: DepartmentGoalFields = {
    name: "",
    departmentId: "",
    description: "",
    dueDate: getGoalCycle(currentUser).end,
    priority: 1,
    visibility: "everyone",
    owners: initOwners,
    keyResults: [newKeyResult()],
    alignedGoalIds: [],
  }

  const initDepartment = () => {
    const department = departments.filter(
      (department) => department.id.toString() === departmentId
    )
    return department.length > 0
      ? { value: department[0].id.toString(), label: department[0].title }
      : undefined
  }

  /**
   * To have access to create a department goal means that the user is admin or is hrb department or manage a department.
   * If the user is an admin we allow to create a goal for any department.
   * If the user is non an admin we display hrbp departments and user's managed departments.
   * https://zugata.atlassian.net/browse/FOW-621
   * Should this be done in the API?
   */

  const currentUserHrbPartners = currentUser.hr_business_partners.map(
    (department) => {
      return { title: department.title, id: department.id }
    }
  )

  const currentUserManagedDepartments = currentUser.department_managers.map(
    (department) => {
      return {
        title: department.department?.title || "",
        id: department.department_id,
      }
    }
  )
  const currentUserDepartments = currentUserHrbPartners.concat(
    currentUserManagedDepartments
  )

  const unique: Partial<Department>[] = _.uniq(
    currentUserDepartments,
    (department) => department.id
  )

  return (
    <DepartmentGoalForm
      title={formatMessage(strings.newDepartmentGoal.title)}
      submitLabel={formatMessage(strings.newDepartmentGoal.submitLabel)}
      defaultFields={newGoal}
      departments={isAdmin(currentUser) ? departments : unique}
      initDepartment={departmentId ? initDepartment() : undefined}
      onSubmit={createDepartmentGoal}
      onLeavePage={() => router.push("/new_goals/department")}
      disabled={formDisabled(loading, departments)}
      initOwners={initOwners}
    />
  )
}

export default injectIntl(NewDepartmentGoal)
