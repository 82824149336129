import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/draft-modal"
import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import strings from "../../locale/strings"
import useDeleteGoal from "../../domainHooks/goals/useDeleteGoal"
import styles from "./GoalDeletionModal.scss"

type GoalDeletionModal = React.FunctionComponent<{
  goalId: number
  onGoalDeleted: () => void
  onCancel: () => void
  intl: InjectedIntl
}>

const GoalDeletionModal: GoalDeletionModal = ({
  goalId,
  onGoalDeleted,
  onCancel,
  intl,
}) => {
  const { formatMessage } = intl
  const {
    deleteGoal,
    success: goalWasDeleted,
    loading: deleteLoading,
  } = useDeleteGoal(goalId)

  useEffect(() => {
    if (goalWasDeleted) {
      onGoalDeleted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalWasDeleted])

  return (
    <ConfirmationModal
      isOpen
      type="negative"
      title={formatMessage(
        strings.teamGoals[deleteLoading ? "deletingGoal" : "deleteGoal"]
      )}
      confirmLabel={formatMessage(strings.teamGoals.delete)}
      dismissLabel={formatMessage(strings.teamGoals.cancel)}
      onDismiss={() => {
        onCancel()
      }}
      onConfirm={() => {
        deleteGoal()
      }}
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          {formatMessage(strings.teamGoals.deleteConfirmation)}
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default injectIntl(GoalDeletionModal)
