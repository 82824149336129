import _ from "lodash"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import endpoints from "../../constants/endpointsDeprecated"
import SessionStorage from "../../refluxStores/lib/SessionStorage"
import Actions from "../../refluxActions"
import browserSubdomainRedirector from "../SubdomainRedirector"

const { SESSION_URLS, USERS_URLS } = endpoints
const { CURRENT_USER_URL } = USERS_URLS
const { SESSION_VALID_URL } = SESSION_URLS
const agent = SteadyfootAgent.defaultInstance

const userStore = new SessionStorage({ key: "UserStore" })

export default async function ensureCultureAmpAuthed(
  nextState,
  replace,
  callback
) {
  try {
    // Attempt to navigate to a stored subdomain before checking for authentication.
    browserSubdomainRedirector.navigateToStoredSubdomain()

    const isAuthed = await agent
      .get(SESSION_VALID_URL)
      .then((res) => res.status === 200)
    if (isAuthed) {
      try {
        const user = await agent
          .get(CURRENT_USER_URL)
          .then((res) => _.get(res, "body.user"))
        userStore.updateData(user)
        // Update locale
        Actions.Locale.loadLocaleData(user.locale)
        callback()
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Error getting user data", err)
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("Error getting auth data", err)
  }
}
