import "./TeamFilterBar.less"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { injectIntl } from "react-intl"
import { Select } from "@kaizen/draft-select"
import { Label } from "@kaizen/draft-form"
import TypeaheadKaizen from "../../components/Typeahead/TypeaheadKaizen"
import {
  getDeptLabeledJobTitle,
  createJobTitleIdWithDept,
  destructJobTitleIdWithDept,
} from "../../models/EmployeeGrouping"
import strings from "../../locale/strings"
import SearchFieldDeprecated from "../../components/SearchFieldDeprecated/SearchFieldDeprecated"
import { DatePicker } from "../../components/DatePicker"

const DEFAULT_GROUP_TYPE = "team"
const GROUP_TYPES = [
  { value: "team", label: strings.teamSearch.wholeTeam },
  { value: "directs", label: strings.teamSearch.directReports },
  { value: "indirects", label: strings.teamSearch.indirectReports },
]

@injectIntl
export default class TeamFilterBar extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    managers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        full_name: PropTypes.string,
        best_name: PropTypes.string,
      }).isRequired
    ),

    supportsGroupType: PropTypes.bool,
    requireManagerForGroupType: PropTypes.bool,
    forceSingleLine: PropTypes.bool,
    defaultGroupType: PropTypes.string,

    loadJobTitles: PropTypes.func,
    loadDepartments: PropTypes.func,
    loadManagers: PropTypes.func,
    loadJobTitleById: PropTypes.func,
    loadDepartmentById: PropTypes.func,
    loadManagerById: PropTypes.func,
    enableHiredBeforeDateFilter: PropTypes.bool,
    onChange: PropTypes.func,
    filters: PropTypes.object,
  }

  static defaultProps = {
    className: "",
    supportsGroupType: false,
    requireManagerForGroupType: false,
    forceSingleLine: false,
    defaultGroupType: DEFAULT_GROUP_TYPE,
    enableHiredBeforeDateFilter: false,
    onChange: _.noop,
    filters: { groupType: DEFAULT_GROUP_TYPE },
  }

  setQuery = (query) => {
    query = query || null
    this.props.onChange({ query })
  }

  setJobTitle = (jobTitleIdWithDept) => {
    if (jobTitleIdWithDept) {
      const [jobTitle, jobTitleId] = destructJobTitleIdWithDept({
        jobTitleIdWithDept: jobTitleIdWithDept,
      })
      this.props.onChange({ jobTitle, jobTitleId })
    } else this.props.onChange({ jobTitle: null, jobTitleId: null })
  }

  setManagerId = (managerId) => {
    managerId = managerId || null
    this.setManager({ managerId })
  }

  setHiredBeforeDate = (hiredBeforeDate) => {
    hiredBeforeDate = hiredBeforeDate || null
    this.props.onChange({ hiredBeforeDate })
  }

  setManager(stateUpdate) {
    const { requireManagerForGroupType, onChange } = this.props

    const { managerId } = stateUpdate

    if (!managerId && requireManagerForGroupType) {
      onChange({ ...stateUpdate, groupType: null })
    } else {
      onChange(stateUpdate)
    }
  }

  setGroupType = (groupType) => {
    this.props.onChange({ groupType: groupType.value })
  }

  setDepartment = (departmentId) => {
    this.props.onChange({
      departmentId,

      // REVIEW: since job title is a refinement of department, clear any job title selection if
      // dept changes
      jobTitle: null,
    })
  }

  sortDropdownOptions(values) {
    return _.sortByOrder(values, ["label", "value"])
  }
  renderSelect({
    displayName,
    isClearable = true,
    defaultValue,
    placeholder = strings.general.all,
    onChange,
    options,
    key = displayName,
  }) {
    const { formatMessage } = this.props.intl
    return (
      <div key={key}>
        <Label labelText={displayName} />
        <Select
          options={options}
          isClearable={isClearable}
          defaultValue={defaultValue}
          placeholder={formatMessage(placeholder)}
          onChange={onChange}
        />
      </div>
    )
  }
  renderTypeaheadKaizenSelect({
    displayName,
    options,
    loadOptions,
    selectedValue,
    filterOption = () => true,
    key = displayName,
    defaultValue = undefined,
    placeholder = strings.general.all,
    onChange,
    optionMapper = (opt) => opt,
    loadOptionByValue = null,
  }) {
    const transformLoadedOptions = (loadedOptions) =>
      this.sortDropdownOptions(
        (loadedOptions.results || loadedOptions).map(optionMapper)
      )
    const { formatMessage } = this.props.intl
    return (
      <div key={key}>
        <Label labelText={displayName} />
        <TypeaheadKaizen
          value={selectedValue ? selectedValue : undefined}
          defaultValue={defaultValue || selectedValue}
          onChange={(opt) => onChange(opt && opt.value)}
          placeholder={formatMessage(placeholder)}
          allowCreate={false}
          searchable={true}
          filterOption={loadOptions ? filterOption : null}
          options={
            options &&
            this.sortDropdownOptions(
              options.map(optionMapper).filter(filterOption)
            )
          }
          loadOptions={
            loadOptions &&
            ((query) => loadOptions(query).then(transformLoadedOptions))
          }
          loadOptionsForValues={
            loadOptionByValue &&
            ((values) =>
              // Since this is a single-select typeahead, only one value will ever be passed to
              // loadOptionsForValues
              loadOptionByValue(values[0]).then((option) =>
                transformLoadedOptions([option])
              ))
          }
          labelKey="label"
          valueKey="value"
        />
      </div>
    )
  }

  render() {
    const {
      loadJobTitles,
      loadManagers,
      loadDepartments,
      loadJobTitleById,
      loadManagerById,
      loadDepartmentById,
      enableHiredBeforeDateFilter,
      managers,
      supportsGroupType,
      requireManagerForGroupType,
      defaultGroupType,
      forceSingleLine,
      intl: { formatMessage },
      filters: {
        query,
        jobTitle,
        managerId,
        groupType,
        departmentId,
        hiredBeforeDate,
      },
    } = this.props
    const hasManagers = loadManagers || (managers && managers.length)
    const hasGroupType =
      supportsGroupType && (!requireManagerForGroupType || managerId)
    const formattedGroupTypes = GROUP_TYPES.map((item) => ({
      ...item,
      label: formatMessage(item.label),
    }))
    return (
      <div className="TeamFilterBar">
        <div className="TeamFilterBar__group">
          {loadDepartments && (
            <div className="TeamFilterBar__item">
              {this.renderTypeaheadKaizenSelect({
                displayName: formatMessage(
                  strings.teamSearch.departmentPlaceholder
                ),
                loadOptions: loadDepartments,
                loadOptionByValue: loadDepartmentById,
                optionMapper: (d) => ({ value: d.id, label: d.title }),
                selectedValue: departmentId,
                onChange: this.setDepartment,
              })}
            </div>
          )}
          {hasManagers && (
            <div className="TeamFilterBar__item">
              {this.renderTypeaheadKaizenSelect({
                displayName: formatMessage(
                  strings.teamSearch.managerPlaceholder
                ),
                options: managers,
                loadOptions: loadManagers,
                loadOptionByValue: loadManagerById,
                optionMapper: (manager) => ({
                  label: manager.full_name || manager.best_name,
                  value: manager.id,
                  department_id: manager.department_id,
                }),
                selectedValue: managerId,
                onChange: this.setManagerId,
              })}
            </div>
          )}

          {hasManagers && hasGroupType && (
            <div className="TeamFilterBar__item">
              {this.renderSelect({
                displayName: formatMessage(strings.general.groups),
                isClearable: false,
                options: formattedGroupTypes,
                defaultValue: formattedGroupTypes.find(
                  (item) => item.value === (groupType || defaultGroupType)
                ),
                onChange: this.setGroupType,
              })}
            </div>
          )}
          <div className="TeamFilterBar__item">
            {this.renderTypeaheadKaizenSelect({
              displayName: formatMessage(
                strings.teamSearch.jobTitlePlaceholder
              ),
              filterOption: (jobTitle) =>
                !departmentId ||
                _.get(jobTitle.data, "parent_job_title.id") === departmentId,

              loadOptions: loadJobTitles,
              loadOptionByValue: loadJobTitleById,
              optionMapper: (jobTitle) => ({
                value: createJobTitleIdWithDept({
                  jobTitle: jobTitle,
                }),
                label: getDeptLabeledJobTitle(jobTitle),
                parent_job_title: {
                  id: _.get(jobTitle, "parent_job_title.id"),
                },
              }),
              selectedValue: jobTitle,
              onChange: this.setJobTitle,
            })}
          </div>
          {enableHiredBeforeDateFilter && (
            <div className="TeamFilterBar__item">
              <DatePicker
                id="hire-date"
                allowDateRange={false}
                initialDate={hiredBeforeDate ? [new Date(hiredBeforeDate)] : []}
                onChange={(selectedDates) => {
                  const d = selectedDates[0]

                  if (d === undefined) {
                    this.setHiredBeforeDate(null)
                  } else {
                    // Convert Date object to "YYYY-MM-DD" format
                    const year = d.getFullYear()
                    const month = d.getMonth() + 1
                    const day = d.getDate()

                    this.setHiredBeforeDate(`${year}-${month}-${day}`)
                  }
                }}
                label={formatMessage(strings.teamSearch.startedBefore)}
              />
            </div>
          )}
        </div>
        <SearchFieldDeprecated
          fullWidth={!forceSingleLine && !!(hasManagers || loadDepartments)}
          onQueryChange={this.setQuery}
          query={query}
          placeholder={formatMessage(strings.typeahead.search)}
        />
      </div>
    )
  }
}
