import "./LabeledEmployeePic.less"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Paragraph } from "@kaizen/component-library"
import strings from "../../locale/strings"
import ProfilePic from "../../containers/ProfilePic/ProfilePic"
import User from "../../models/User"

export default class LabeledEmployeePic extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    header: PropTypes.node,
    detailLine: PropTypes.node,
    small: PropTypes.bool,
  }

  render() {
    const { user, header, detailLine, small } = this.props

    return (
      <div
        className={`LabeledEmployeePic${
          small ? "--small" : ""
        } layout horizontal center`}
      >
        <ProfilePic user={user} size={small ? "small" : "medium"} />

        <div className="flex left-align">
          {header}

          <div className="LabeledEmployeePic--name">
            {user ? (
              <Paragraph variant="body">
                <strong>{user.full_name || user.first_name}</strong>
              </Paragraph>
            ) : (
              <Paragraph variant="body">
                <FormattedMessage {...strings.user.deleted} />
              </Paragraph>
            )}
            {user && User.isDeactivated(user) && (
              <div className="LabeledEmployeePic--deactivated">
                <Paragraph variant="body">
                  <FormattedMessage {...strings.user.deactivated} />
                </Paragraph>
              </div>
            )}
          </div>
          <div className="LabeledEmployeePic--date">
            <Paragraph variant="body">
              {user && (detailLine || User.getJobTitle(user))}
            </Paragraph>
          </div>
        </div>
      </div>
    )
  }
}
