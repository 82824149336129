import React, { createContext, useContext, useEffect, useState } from "react"
import { setUserProperty } from "@cultureamp/amplitude"
import Loading from "../components/Loading/Loading"
import useDataAPI from "../domainHooks/performanceApi/useDataAPI"
import ZugiPointsStore from "../refluxStores/ZugiPointsStore"
import { CurrentUser } from "../types/User"
import currentUserDecoder from "../api/decoders/CurrentUserDecoder/currentUserDecoder"
import FeatureFlags from "../../src/constants/featureFlags"

type Flags = Partial<{ [key in FeatureFlags]: boolean }>

const setUserFeatureFlagsInAmplitude = (flags: Flags) => {
  const enabledFlags = Object.values(FeatureFlags).filter(
    (value) => flags[value]
  )
  //@ts-ignore
  setUserProperty("User feature flag enabled", enabledFlags)
}

export const CurrentUserContext = createContext<CurrentUser | null>(null)

export const CurrentUserProvider: React.FunctionComponent = ({ children }) => {
  const currentUser = useContext(CurrentUserContext)
  const [currentUserState, setCurrentUserState] = useState(currentUser)
  const { fire, data, loading } = useDataAPI("/users/me", "GET")
  const { sessionStorage } = window
  const userSessionStore = sessionStorage.getItem("UserStore")

  useEffect(() => {
    if (!userSessionStore || !ZugiPointsStore.data) {
      fire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const userDataFromLocalStorage = () => ({
      user: JSON.parse(userSessionStore as string),
      meta: ZugiPointsStore.data,
    })
    const userForDecode = data || userDataFromLocalStorage()
    if (userForDecode) {
      currentUserDecoder
        .decodePromise(userForDecode)
        .then((decoded) => {
          setCurrentUserState(decoded)
        })
        .catch((error: Error) => {
          throw error
        })
    }
  }, [data, userSessionStore])

  useEffect(() => {
    if (currentUserState) setUserFeatureFlagsInAmplitude(currentUserState.flags)
  }, [currentUserState])

  return currentUserState === null || loading ? (
    <Loading />
  ) : (
    <CurrentUserContext.Provider value={currentUserState}>
      {children}
    </CurrentUserContext.Provider>
  )
}
