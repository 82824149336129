import { Text } from "@kaizen/component-library"
import moment from "moment"
import * as React from "react"
// @ts-ignore
import MarkdownText from "../MarkdownText/MarkdownText"
import Avatar from "../Avatar/Avatar"
import styles from "./Comment.scss"

type CommentProps = {
  sender: string
  avatarURL: string
  message: string
  sent: Date
  children?: React.ReactNode
  canReply?: boolean
}

const Comment = ({
  sender,
  avatarURL,
  sent,
  message,
  children,
}: CommentProps) => {
  return (
    <div className={styles.container}>
      <Avatar imageURL={avatarURL} />
      <div className={styles.content}>
        <Text tag="div" style="body-bold">
          {sender}
        </Text>
        <div className={styles.messageContainer}>
          <Text tag="div" style="small">
            {moment(sent).format("MMM DD, YYYY")}
          </Text>
          <div className={styles.message}>
            <MarkdownText text={message} inline />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Comment
