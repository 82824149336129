import strings from "../../../locale/strings"

const STATUS_OPTIONS = [
  {
    text: strings.goals.status.created,
    value: "created",
  },
  {
    text: strings.goals.status.ongoing,
    value: "ongoing",
  },
  {
    text: strings.goals.status.blocked,
    value: "blocked",
  },
  {
    text: strings.goals.status.accomplished,
    value: "accomplished",
  },
]

export default STATUS_OPTIONS
