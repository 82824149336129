import { useEffect, useState } from "react"
import {
  AlignableGoal,
  alignableGoalsDecoder,
} from "../../api/decoders/AlignableGoalsDecoder/alignableGoalsDecoder"
import useDataAPI from "../performanceApi/useDataAPI"
import currentUser from "../auth/useCurrentUser"

export type AlignableGoalsFilter = {
  targetGoalType?: string
  sourceGoalId?: number
  all_values?: boolean
  query?: string
}

const useAlignableGoals = (filter: AlignableGoalsFilter) => {
  const { fire, loading, data, error } = useDataAPI(
    "/goals/new_interface_alignable",
    "GET"
  )

  const currentUserId = currentUser().id

  /**
   * We receive the goal type and if should get ALL values making reference to ALL departments and ALL teams.
   * Depending on those values we send the filter parameter to the request that expect:
   * all_teams | my_team | all_departments | my_department
   */

  useEffect(() => {
    if (filter.targetGoalType && currentUserId)
      fire({
        target_goal_type: filter.targetGoalType,
        source_goal_id: filter.sourceGoalId,
        current_user: currentUserId,
        filter:
          filter.targetGoalType === "Goals::TeamGoal"
            ? filter.all_values
              ? "all_teams"
              : "my_team"
            : filter.targetGoalType === "Goals::DepartmentGoal"
            ? filter.all_values
              ? "all_departments"
              : "my_department"
            : undefined,
        q: filter.query,
      })
  }, [
    filter.all_values,
    filter.query,
    fire,
    filter.targetGoalType,
    currentUserId,
    filter.sourceGoalId,
  ])

  const [results, setResults] = useState<AlignableGoal[]>([])

  useEffect(() => {
    if (error) {
      setResults([])
    }
  }, [error])

  useEffect(() => {
    if (data) {
      alignableGoalsDecoder.decodePromise(data).then(setResults)
    }
  }, [data])

  return { goals: results, loading, error }
}

export default useAlignableGoals
