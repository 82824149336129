import { useState } from "react"

type DossierAndDrawerState = {
  dossierIsOpen: boolean
  drawerIsOpen: boolean
}

const initialState = {
  drawerIsOpen: false,
  dossierIsOpen: true,
}

export const useDossierAndDrawer = (
  initialDrawerState?: DossierAndDrawerState
) => {
  const [state, setState] = useState<DossierAndDrawerState>(
    initialDrawerState || initialState
  )

  const toggleDrawer = (shouldOpen?: boolean) => {
    setState((s) => ({
      ...s,
      drawerIsOpen: shouldOpen || !s.drawerIsOpen,
    }))
  }

  const toggleDossier = (shouldOpen?: boolean) => {
    setState((s) => ({
      ...s,
      dossierIsOpen: shouldOpen || !s.dossierIsOpen,
    }))
  }

  return {
    ...state,
    toggleDrawer,
    toggleDossier,
  }
}
