export default class SurveyItem {
  static isChoiceType(surveyItem) {
    if (
      surveyItem.question_type === "rating" ||
      surveyItem.question_type === "multiple-choice"
    ) {
      return true
    }
  }

  static getAnswer(surveyItem) {
    if (this.isChoiceType(surveyItem)) {
      return this.getMultipleChoiceAnswer(surveyItem)
    } else {
      return this.getLongAnswer(surveyItem)
    }
  }

  // TODO deprecate text and content once we move to surveys completely
  static getLongAnswer(surveyItem) {
    return (
      surveyItem && (surveyItem.text || surveyItem.content || surveyItem.answer)
    )
  }

  // TODO deprecate value once we move to surveys completely
  static getMultipleChoiceAnswer(surveyItem) {
    return surveyItem && (surveyItem.value || surveyItem.choice)
  }
}
