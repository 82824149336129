/* eslint-disable import/no-named-as-default-member, import/default, import/named */
import { Location } from "history"
import qs from "query-string"
import { useCallback } from "react"
import { InjectedRouter } from "react-router"

const useGoalDossier = (router: InjectedRouter, location: Location) => {
  const { goalId } = qs.parse(location.search)

  const open = useCallback(
    (goalId: string) => {
      router.push({
        pathname: location.pathname,
        query: {
          ...location.query,
          goalId,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  const close = useCallback(() => {
    const { goalId, ...query } = location.query
    router.push({
      pathname: location.pathname,
      query,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return { isOpen: !!goalId, open, close, goalId }
}

export default useGoalDossier
