import * as React from "react"
import { useEffect, useState } from "react"
import { InjectedRouter } from "react-router"
import { injectIntl, InjectedIntl } from "react-intl"
import strings from "../../locale/strings"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import TeamForm, { TeamFields } from "../TeamForm/TeamForm"
import { trackTeamCreated } from "../../utils/analytics"
import useCreateTeam from "../../domainHooks/teams/useCreateTeam"

interface NewTeamProps {
  router: InjectedRouter
  params: { teamId: string }
  intl: InjectedIntl
}

const NewTeam = ({ router, params, intl }: NewTeamProps) => {
  const currentUser = useCurrentUser()
  const { teamId } = params
  const [notification, setNotification] = useState()
  const { createTeam, loading, data, error } = useCreateTeam(
    teamId,
    currentUser.company.account_id
  )
  const { formatMessage } = intl
  useEffect(() => {
    // @ts-ignore
    if (data && data.status === "ok") {
      trackTeamCreated()
      router.push({
        pathname: `/teams/${teamId}`,
        state: {
          newTeam: true,
        },
      })
    }
    if (error) {
      // @ts-ignore
      const message = error.response ? error.response.data.error : error.message
      setNotification(message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])
  /**
   * Notice here that we are sending the parameters expected from the new current user.
   * This should be fixed when we update our new current user to be camel case and we can
   * transform different types as TeamLead, TeamMember, etc to be a partial from the CurrentUser
   */
  const teamFields: TeamFields = {
    name: "",
    description: "",
    teamLeads: [
      {
        aggregateId: currentUser.employee_aggregate_id,
        role: currentUser.job_title_name || "",
        profileImage: currentUser.profile_image_url,
        name: currentUser.best_name,
        avatar: "",
      },
    ],
    teamMembers: [],
  }
  return (
    <TeamForm
      title={formatMessage(strings.teams.newTeamForm.createNewTeam)}
      notification={notification}
      defaultFields={teamFields}
      onSubmit={createTeam}
      onCancel={router.goBack}
      disabled={loading}
      submitLabel={formatMessage(strings.teams.newTeamForm.create)}
    />
  )
}

export default injectIntl(NewTeam)
