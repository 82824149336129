import useDataAPI from "../performanceApi/useDataAPI"
import { TeamGoalFields } from "../../containers/TeamGoalForm/TeamGoalForm"
import logCreateGoalEvent from "../../api/analytics/logCreateGoalEvent"
import { TeamGoalOwner } from "../../types/Goals"

const useCreateTeamGoal = () => {
  const fetchAPI = useDataAPI("/goals", "POST")
  const createTeamGoal = (fields: TeamGoalFields) => {
    const goaldata = {
      type: "team_goal",
      team_id: fields.teamId,
      due_at: fields.dueDate.toISOString(),
      name: fields.name,
      description: fields.description,
      priority: fields.priority,
      visibility: fields.visibility,
      goal_owners: fields.owners.map(
        (owner: TeamGoalOwner) => owner.aggregateId
      ),
      key_results: fields.keyResults.map(({ title }) => ({
        title,
        completion: 0,
      })),
      outgoing_alignment_associations_attributes: fields.alignedGoalIds.map(
        (id: number) => ({
          incoming_alignment_goal_id: id,
        })
      ),
    }
    fetchAPI.fire({ goal: goaldata }).then((res) => {
      logCreateGoalEvent("Team", res.data.goal)
    })
  }
  return {
    createTeamGoal,
    data: fetchAPI.data,
    error: fetchAPI.error,
    loading: fetchAPI.loading,
  }
}

export default useCreateTeamGoal
