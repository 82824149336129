import { Paragraph } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import strings from "../../locale/strings"
import styles from "./IncomingAlignedGoals.scss"
import { GoalType, IncomingAlignedGoalsCount } from "../../types/Goals"
import Aid from "../../constants/automationId"

type IncomingAlignedGoals = {
  goalType: GoalType
  incomingAlignedGoalsCounts: IncomingAlignedGoalsCount
  intl: InjectedIntl
  hasTeamGoalsEnabled: boolean
}

const IncomingAlignedGoals = ({
  goalType,
  incomingAlignedGoalsCounts,
  intl,
  hasTeamGoalsEnabled,
}: IncomingAlignedGoals) => {
  const { formatMessage } = intl

  return (
    <div data-automation-id={Aid.incomingGoalAlignmentCounts}>
      <div className={styles.incomingAlignmentsLabel}>
        <Paragraph variant="body">
          <strong>
            {formatMessage(
              strings.goalsPage.goalsDossier.goalAlignments.alignedFrom
            )}
          </strong>
        </Paragraph>
      </div>
      <div className={styles.incomingAlignmentsContainer}>
        <div className={styles.incomingAlignmentCount}>
          <Paragraph variant="small">
            <strong>
              {formatMessage(
                strings.goalsPage.goalsDossier.goalAlignments.individual
              )}
            </strong>
          </Paragraph>
          <Paragraph variant="body">{`${incomingAlignedGoalsCounts.individualGoalsCount}`}</Paragraph>
        </div>
        {hasTeamGoalsEnabled && (
          <div className={styles.incomingAlignmentCount}>
            <Paragraph variant="small">
              <strong>
                {formatMessage(
                  strings.goalsPage.goalsDossier.goalAlignments.team
                )}
              </strong>
            </Paragraph>
            <Paragraph variant="body">{`${incomingAlignedGoalsCounts.teamGoalsCount}`}</Paragraph>
          </div>
        )}
        {goalType !== "team" && goalType !== "department" && (
          <div className={styles.incomingAlignmentCount}>
            <Paragraph variant="small">
              <strong>
                {formatMessage(
                  strings.goalsPage.goalsDossier.goalAlignments.department
                )}
              </strong>
            </Paragraph>
            <Paragraph variant="body">{`${incomingAlignedGoalsCounts.departmentGoalsCount}`}</Paragraph>
          </div>
        )}
      </div>
    </div>
  )
}

export default injectIntl(IncomingAlignedGoals)
