import React, { useEffect } from "react"
import _ from "lodash"
import { withRouter, WithRouterProps } from "react-router"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import useDepartmentGoals from "../../domainHooks/goals/useDepartmentGoals"
import { DepartmentSummary } from "../../context/DepartmentSummariesState"
import GoalsTable from "../GoalsTable/GoalsTable"

type Filters = {
  from?: Date
  to?: Date
}

type DepartmentGoalsList = React.FunctionComponent<
  {
    department: DepartmentSummary
    filters: Filters
    onSelectDepartment?: () => void
  } & WithRouterProps
>

const DepartmentGoalsList: DepartmentGoalsList = ({
  department,
  filters,
  onSelectDepartment,
  router,
  location,
}) => {
  const {
    departmentGoals,
    goalsCount,
    fetch: fetchDepartmentGoals,
    loading,
  } = useDepartmentGoals(department.id, filters)

  useEffect(() => {
    fetchDepartmentGoals()
  }, [department.id, fetchDepartmentGoals])

  const { open: openGoalDossier } = useGoalDossier(router, location)

  return (
    <React.Fragment>
      <GoalsTable
        goals={departmentGoals}
        goalsCount={goalsCount}
        displayOwners={true}
        titleName={department.name}
        goalProgress={Math.floor(department.overallProgress * 100)}
        openGoal={(goalId) => {
          openGoalDossier(goalId)
        }}
        onClose={onSelectDepartment ? () => onSelectDepartment() : undefined}
        loading={loading}
      />
    </React.Fragment>
  )
}

export default _.compose(withRouter)(DepartmentGoalsList)
