import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import Icon from "../../components/Icon/Icon"
import "./Row.less"

export default class Row extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string,
    automationId: PropTypes.string,
  }

  render() {
    const { children, className, onClick, name, automationId } = this.props
    const classNames = cx(className, "Row layout horizontal center", {
      "Row--clickable": !!onClick,
    })

    return (
      <div
        name={name}
        className={classNames}
        onClick={() => onClick()}
        data-automation-id={automationId}
      >
        <div className="Row--content flex layout center horizontal">
          {children}
        </div>

        {onClick ? (
          <div className="Row--arrow flex none">
            <Icon iconName="chevronRight" />
          </div>
        ) : null}
      </div>
    )
  }
}
