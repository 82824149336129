import Reflux from "reflux-core"
import Actions from "../refluxActions"

export default Reflux.createStore({
  init() {
    this.initializeData()
    this.listenTo(Actions.UI.setLoadingState, this.onSetLoadingState)
  },

  initializeData() {
    this.data = {
      loadCount: 0,
    }
  },

  getInitialState() {
    return this.data
  },

  onSetLoadingState(loading) {
    const loadDelta = loading ? 1 : -1
    const loadCount = this.data.loadCount + loadDelta

    if (loadCount < 1) {
      this.initializeData()
    } else {
      this.data = {
        ...this.data,
        loadCount,
      }
    }

    this.trigger(this.data)
  },
})
