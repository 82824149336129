import Reflux from "reflux-core"
import _ from "lodash"
import * as AdminActions from "../refluxActions"
import BaseSavedViewStore from "../../refluxStores/lib/BaseSavedViewStore"

export default Reflux.createStore({
  ...BaseSavedViewStore,

  init() {
    BaseSavedViewStore.init.call(this, { actions: AdminActions.SavedView })

    this.listenTo(AdminActions.SavedView.create.completed, (savedView) => {
      const { savedViewsByType, savedViewsById } = this.data
      const { id, type } = savedView

      this.data = {
        ...this.data,
        savedViewsByType: {
          ...savedViewsByType,
          [type]: [savedView, ...(savedViewsByType[type] || [])],
        },
        savedViewsById: {
          ...savedViewsById,
          [id]: savedView,
        },
      }

      this.trigger(this.data)
    })

    this.listenTo(AdminActions.SavedView.update.completed, (savedView) => {
      this.addOrReplaceSavedView(savedView)
      this.trigger(this.data)
    })

    this.listenTo(AdminActions.SavedView.delete.completed, (savedView) => {
      const { savedViewsByType, savedViewsById } = this.data
      const { id, type } = savedView

      this.data = {
        ...this.data,
        savedViewsByType: {
          ...savedViewsByType,
          [type]:
            savedViewsByType[type] && _.reject(savedViewsByType[type], { id }),
        },
        savedViewsById: _.omit(savedViewsById, id),
      }

      this.trigger(this.data)
    })

    this.listenTo(AdminActions.SavedView.share.completed, (savedView) => {
      this.addOrReplaceSavedView(savedView)
      this.trigger(this.data)
    })
  },
})
