/**
 * @param {Array<number> | Uint8Array} bytes
 */
export default function bytesToBinaryString(bytes) {
  let str = ""
  for (let i = 0, len = bytes.length; i < len; i++) {
    str += String.fromCharCode(bytes[i])
  }
  return str
}
