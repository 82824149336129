import * as React from "react"
import { Button } from "@kaizen/component-library"
import { injectIntl, InjectedIntl } from "react-intl"
import { EmptyState } from "@kaizen/draft-empty-state"
import styles from "./GoalsEmptyState.scss"
import strings from "../../locale/strings"

type GoalsEmptyState = React.FunctionComponent<{
  intl: InjectedIntl
  headingText: ReactIntl.FormattedMessage.MessageDescriptor
  bodyText: ReactIntl.FormattedMessage.MessageDescriptor
  illustrationType?: IllustrationType
  createGoalAction?: () => void
}>

type IllustrationType =
  | "positive"
  | "neutral"
  | "negative"
  | "informative"
  | "action"

const GoalsEmptyState: GoalsEmptyState = ({
  headingText,
  bodyText,
  illustrationType,
  createGoalAction,
  intl,
}) => {
  return (
    <div className={styles.container}>
      <EmptyState
        headingText={intl.formatMessage(headingText)}
        bodyText={intl.formatMessage(bodyText)}
        illustrationType={illustrationType}
      >
        {createGoalAction && (
          <div className={styles.buttonContainer}>
            <Button
              label={intl.formatMessage(
                strings.personalGoalsList.emptyState.button.label
              )}
              iconPosition="end"
              onClick={createGoalAction}
              primary
              fullWidth
            />
          </div>
        )}
      </EmptyState>
    </div>
  )
}

export default injectIntl(GoalsEmptyState)
