import { Button, Text } from "@kaizen/component-library"
import { TextField } from "@kaizen/draft-form"
import { Select } from "@kaizen/draft-select"
import leftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import cx from "classnames"
import * as React from "react"
import { useState, useEffect, useRef } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { debounce } from "lodash"
import strings from "../../locale/strings"
import GoalUnsavedChangesModal from "../GoalUnsavedChangesModal/GoalUnsavedChangesModal"
import KeyResultList from "../../components/KeyResultList/KeyResultList"
import GoalOwnerSelect from "../DepartmentGoalOwnerSelect/GoalOwnerSelect"
import styles from "./DepartmentGoalForm.scss"
import PrioritySelector from "../../components/PrioritySelector/PrioritySelector"
import DatePicker from "../../components/DatePicker/DatePicker"
import AlignableGoal from "../AlignableGoals/AlignableGoals"
import { KeyResult } from "../../components/KeyResultList/KeyResultListItem"
import { newKeyResult } from "../../domainHooks/goals/goalKeyResults"
import { TeamGoalOwner } from "../../types/Goals"
import { Department } from "../../types/User"
import { FeatureSwitch } from "../../components/FeatureSwitch/FeatureSwitch"
import FeatureFlags from "../../constants/featureFlags"
// we set this ourselves for UI sake - the real max is 65535
const SQL_TEXT_MAX_LENGTH = 1000
const { departmentGoalForm } = strings
type Priority = 0 | 1 | 2

export type Visibility = "everyone"

export interface DepartmentGoalFields {
  name: string
  description: string
  departmentId: string
  dueDate: Date
  priority: Priority
  visibility: Visibility
  owners: TeamGoalOwner[]
  keyResults: KeyResult[]
  alignedGoalIds: number[]
}

type DepartmentGoalForm = React.FunctionComponent<{
  title: React.ReactNode
  disabled: boolean
  submitLabel: string
  departments: Partial<Department>[]
  initDepartment?: Option
  goalId?: string
  defaultFields: DepartmentGoalFields
  onSubmit: (fields: DepartmentGoalFields) => void
  onLeavePage: () => void
  intl: InjectedIntl
  initOwners: TeamGoalOwner[]
}>

type Option = {
  value: string
  label: string
}

type GoalOwnerOption = {
  avatar: string
} & Option

type TextFieldValidation = {
  status: string
  validationMessage?: string
}

const DepartmentGoalForm: DepartmentGoalForm = ({
  title,
  disabled,
  goalId,
  submitLabel,
  defaultFields,
  departments,
  initDepartment,
  initOwners,
  onSubmit,
  onLeavePage,
  intl,
}) => {
  const { formatMessage } = intl
  const [fields, setFields] = useState<DepartmentGoalFields>(defaultFields)
  const [selectedDepartment, setSelectedDepartment] = useState<
    Option | undefined
  >(initDepartment)

  const [nameTextField, setNameTextField] = useState<TextFieldValidation>({
    status: "default",
    validationMessage: undefined,
  })
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [descriptionTextField, setDescriptionTextField] = useState<
    TextFieldValidation
  >({
    status: "default",
    validationMessage: undefined,
  })

  const { name, description, keyResults, alignedGoalIds, owners } = fields

  const keys = Object.keys(fields) as Array<keyof typeof fields>
  // isDiff returns true if there are unsaved changes on the form
  const isDiff = keys.some(
    (key) => JSON.stringify(fields[key]) !== JSON.stringify(defaultFields[key])
  )
  const departmentOptions = () =>
    departments
      ? departments.map((department) => ({
          value: department.id ? department.id.toString() : "",
          label: department.title ? department.title : "",
        }))
      : []

  // this is to be used to set a default value
  const updateSelect = (value: Option) => {
    setSelectedDepartment(value)
    setFields((fields) => ({
      ...fields,
      // @ts-ignore
      departmentId: value.value,
      owners: initOwners,
    }))
  }

  // Fire immediate use of useEffect for initDepartment
  // but then push off any other effects for the next 5s.
  // This prevents the deletion of an owner being overriden
  // when initDepartment fires again during inital component mounting.
  const throttleSelectedDepartment = useRef(
    debounce(
      (initDepartment: Option) => {
        updateSelect(initDepartment)
      },
      5000,
      { leading: true, trailing: false }
    )
  )
  useEffect(() => {
    if (!disabled && initDepartment) {
      throttleSelectedDepartment.current(initDepartment)
    }
  }, [initDepartment, disabled])

  const handleUnsavedChanges = React.useCallback(() => {
    setShowUnsavedChangesModal(true)
  }, [])

  const addKeyResult = () =>
    setFields({
      ...fields,
      keyResults: [...fields.keyResults, newKeyResult()],
    })

  const removeKeyResult = (id: string) => {
    const keyResults = fields.keyResults.filter((kr) => kr.id !== id)
    setFields({
      ...fields,
      keyResults,
    })
  }

  const updateKeyResult = (id: string, title: string) => {
    const keyResults = fields.keyResults.map((kr) =>
      id === kr.id ? { ...kr, title } : kr
    )
    setFields({
      ...fields,
      keyResults,
    })
  }

  // validations
  const hasInvalidNameStringLen = (value: string) =>
    !value || value.trim().length === 0 || value.length > SQL_TEXT_MAX_LENGTH
  const hasInvalidDescriptionStringLen = (value: string) =>
    value.length > SQL_TEXT_MAX_LENGTH
  const validateGoalInputLength = (
    isInvalid: boolean,
    validationMessage: string,
    setValidationField: (obj: TextFieldValidation) => void
  ) => {
    if (isInvalid) {
      setValidationField({
        status: "error",
        validationMessage: validationMessage,
      })
    } else {
      setValidationField({
        status: "default",
        validationMessage: undefined,
      })
    }
  }

  // check the required fields and ensure they store valid values
  const isFormDisabled =
    !selectedDepartment || hasInvalidNameStringLen(name) || !fields.dueDate

  return (
    <div className={styles.container}>
      <FeatureSwitch
        flag={[FeatureFlags.zenHeader, FeatureFlags.topNavigation]}
      >
        <FeatureSwitch.Disabled>
          <Button
            secondary
            label={formatMessage(strings.general.back)}
            icon={leftIcon}
            onClick={onLeavePage}
          />
          <Text tag="h1" style="zen-heading-2">
            {title}
          </Text>
        </FeatureSwitch.Disabled>
      </FeatureSwitch>
      {showUnsavedChangesModal && (
        <GoalUnsavedChangesModal
          onLeavePage={onLeavePage}
          onCancel={() => setShowUnsavedChangesModal(false)}
        />
      )}
      {/* including "search" in the id stops Lastpass icon from appearing in field */}
      <form id="search-form">
        <div className={cx(styles.labelText)}>
          <Text tag="h3" style="label">
            {formatMessage(strings.departmentGoalForm.departmentListLabel)}
          </Text>
        </div>
        <div className={styles.section}>
          <Select
            value={selectedDepartment}
            options={departmentOptions()}
            // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
            onChange={updateSelect}
            placeholder={formatMessage(
              departmentGoalForm.departmentListPlaceholder
            )}
            isDisabled={
              !departments || departments.length === 0
                ? true
                : initDepartment
                ? true
                : false
            }
            noOptionsMessage={() =>
              formatMessage(departmentGoalForm.noOptionsMessage)
            }
          />
        </div>
        <TextField
          id="name"
          inputValue={name}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            const invalidNameLength = value.length > SQL_TEXT_MAX_LENGTH
            validateGoalInputLength(
              invalidNameLength,
              formatMessage(
                departmentGoalForm.nameField.error.validationMessage
              ),
              setNameTextField
            )
            setFields((fields) => ({
              ...fields,
              name: (evt.target as HTMLInputElement).value,
            }))
          }}
          type="text"
          labelText={formatMessage(departmentGoalForm.nameField.labelText)}
          placeholder={formatMessage(departmentGoalForm.nameField.placeholder)}
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          status={nameTextField.status}
          validationMessage={nameTextField.validationMessage}
          required
          disabled={disabled}
        />
        <TextField
          id="description"
          inputValue={description}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            const value = (evt.target as HTMLInputElement).value
            validateGoalInputLength(
              hasInvalidDescriptionStringLen(value),
              formatMessage(
                departmentGoalForm.descriptionField.error.validationMessage
              ),
              setDescriptionTextField
            )
            setFields((fields) => ({
              ...fields,
              description: (evt.target as HTMLInputElement).value,
            }))
          }}
          type="text"
          labelText={formatMessage(
            departmentGoalForm.descriptionField.labelText
          )}
          placeholder={formatMessage(
            departmentGoalForm.descriptionField.placeholder
          )}
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          status={descriptionTextField.status}
          validationMessage={descriptionTextField.validationMessage}
          required
          disabled={disabled}
        />

        <div className={styles.section}>
          <KeyResultList
            title={formatMessage(
              strings.departmentGoalForm.keyResultsListTitle
            )}
            keyResults={fields.keyResults}
            addKeyResult={addKeyResult}
            removeKeyResult={removeKeyResult}
            updateKeyResult={updateKeyResult}
          />
        </div>

        <div className={styles.section}>
          <GoalOwnerSelect
            setFields={(owners: TeamGoalOwner[]) => {
              setFields({
                ...fields,
                owners,
              })
            }}
            owners={owners}
            title={formatMessage(strings.departmentGoalForm.goalOwnerTitle)}
            placeholder={formatMessage(
              strings.departmentGoalForm.goalOwnerPlaceholder
            )}
          />
        </div>

        <div className={styles.row}>
          <div
            className={cx(styles.field, styles.small, styles.priorityContainer)}
          >
            <PrioritySelector
              onChange={(evt) => {
                evt.persist()
                setFields((fields) => ({
                  ...fields,
                  // @ts-ignore
                  priority: parseInt(evt.target.value) as Priority,
                }))
              }}
              selectedValue={fields.priority}
            />
          </div>
          <div className={cx(styles.field, styles.small)}>
            <div className={cx(styles.labelText)}>
              <Text tag="h3" style="label">
                {formatMessage(strings.departmentGoalForm.dueDateLabel)}
              </Text>
            </div>
            <DatePicker
              id={"new-department-goal-date-picker"}
              initialDate={[fields.dueDate]}
              onChange={([dueDate]) => {
                setFields((fields) => ({
                  ...fields,
                  dueDate,
                }))
              }}
            />
          </div>
          <div className={cx(styles.field, styles.small)}></div>
        </div>
        <AlignableGoal
          currentGoalId={goalId ? Number(goalId) : undefined}
          alignedGoalIds={alignedGoalIds}
          onAdd={(id) => {
            setFields((fields) => ({
              ...fields,
              alignedGoalIds: [...fields.alignedGoalIds, id],
            }))
          }}
          onRemove={(id) => {
            setFields((fields) => ({
              ...fields,
              alignedGoalIds: fields.alignedGoalIds.filter(
                (goalId) => id !== goalId
              ),
            }))
          }}
          disabled={disabled}
          initGoalType={"companyGoal"}
          hideTeams
          hideDepartments
        />
        <div className={styles.actions}>
          <div className={styles.action}>
            <Button
              disabled={disabled}
              automationId="cancel-department-goal"
              secondary
              label={formatMessage(
                strings.departmentGoalForm.cancelButtonLabel
              )}
              onClick={isDiff ? handleUnsavedChanges : onLeavePage}
            />
          </div>
          <div className={styles.action}>
            <Button
              automationId="create-department-goal"
              disabled={isFormDisabled}
              primary
              label={submitLabel}
              onClick={() => {
                onSubmit({
                  ...fields,
                  keyResults: keyResults.filter(
                    ({ title }) => title.length > 0
                  ),
                })
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(DepartmentGoalForm)
