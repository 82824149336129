import React, { SVGAttributes } from "react"
import { useRef, useCallback, useEffect } from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Button } from "@kaizen/component-library"
import { Popover } from "@kaizen/draft-popover"
import strings from "../../locale/strings"
import styles from "./DismissRequestFeedbackButton.scss"
import { useDimensions } from "../../hooks/dimensions/dimensions"
import { calculatePopoverOffset } from "../../utils/popover"
import Aid from "../../constants/automationId"

type option = {
  text: ReactIntl.FormattedMessage.MessageDescriptor | string
  value: string
}

type Props = {
  skipOptions: option[]
  onSkipOptionSelect: Function
  openTooltip: boolean
  toggleTooltip: () => void
  icon?: SVGAttributes<SVGSymbolElement>
  iconPosition?: "end" | "start"
}

export type RawProps = Props & {
  intl: InjectedIntl
}

enum KeyboardEvent {
  esc = "Esc",
  escape = "Escape",
}

const DismissRequestFeedbackButton = ({
  skipOptions,
  onSkipOptionSelect,
  icon,
  iconPosition = "start",
  openTooltip = false,
  intl: { formatMessage },
  toggleTooltip,
}: RawProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const rootDims = useDimensions(rootRef)

  const popoverRef = useRef<HTMLElement>(null)
  const popoverDims = useDimensions(popoverRef, [openTooltip])

  const boxOffset = calculatePopoverOffset(rootDims, popoverDims)
  const handleToggleTooltip = useCallback(() => {
    return toggleTooltip()
  }, [toggleTooltip])

  /**
   * When clicking outside the area of the button, ensure
   * that the popover closes.
   *
   * @param {MouseEvent} e
   */
  const handleClick = useCallback(
    (e: MouseEvent) => {
      // Base case: handle empty reference.
      if (!rootRef.current) {
        return
      }

      if (openTooltip && !rootRef.current.contains(e.target as Node)) {
        toggleTooltip()
      }
    },
    [openTooltip, toggleTooltip]
  )

  /**
   * When the tooltip is open, enable a handle for clicking the
   * rest of the DOM that will close the tooltip.
   */
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false)

    return () => {
      document.removeEventListener("mousedown", handleClick, false)
    }
  }, [openTooltip, handleClick])

  /**
   * This function will close the Tooltip used in
   * once focus returns to the "Dismiss Request" button.
   */
  const handleFocus = useCallback(() => {
    if (openTooltip) {
      toggleTooltip()
    }
  }, [openTooltip, toggleTooltip])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      // Base case: return early if tooltip is closed.
      if (!openTooltip) {
        return
      }

      switch (e.key) {
        case KeyboardEvent.esc:
        case KeyboardEvent.escape:
          return toggleTooltip()
      }
    },
    [openTooltip, toggleTooltip]
  )

  return (
    // @ts-ignore
    <div
      className={styles.DismissButton}
      ref={rootRef}
      onKeyDown={handleKeyPress}
    >
      <span
        // Note: I hate this hack, but currently Kaizen is not
        // supporting onFocus and onBlur events for buttons.
        onFocus={handleFocus}
      >
        <Button
          label={formatMessage(strings.peerReview.dismissRequest)}
          secondary
          icon={icon}
          iconPosition={iconPosition}
          automationId={Aid.dismissRequestFeedbackButton}
          onClick={(e) => {
            handleToggleTooltip()
            e.stopPropagation()
          }}
        />
      </span>
      {openTooltip && (
        <div
          className={styles.Popover}
          data-automation-id={Aid.dismissRequestFeedbackPopover}
        >
          <Popover
            side="top"
            boxOffset={boxOffset}
            // @ts-ignore
            ref={popoverRef}
          >
            <div className={styles.PopoverMenu}>
              {skipOptions.map(({ text, value }) => (
                <Button
                  key={value}
                  secondary
                  onClick={(e) => {
                    onSkipOptionSelect(value)
                    handleToggleTooltip()
                    e.stopPropagation()
                  }}
                  label={typeof text === "string" ? text : formatMessage(text)}
                />
              ))}
            </div>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default injectIntl(DismissRequestFeedbackButton)
