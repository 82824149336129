import { JsonDecoder } from "ts.data.json"
import { TeamSummary } from "../../../context/TeamSummariesState"
import paginationDecoder, {
  Pagination,
} from "../PaginationDecoder/paginationDecoder"
import { teamSummaryDecoder } from "../TeamSummary/teamSummary"

type ResponseType = {
  team_goals: TeamSummary[]
  meta: {
    pagination: Pagination
  }
}

export const getTeamSummaryResponseDecoder = JsonDecoder.object<ResponseType>(
  {
    team_goals: JsonDecoder.array(teamSummaryDecoder, "teamSummaries"),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder,
      },
      "meta"
    ),
  },
  "getTeamSummariesResponse"
).map(({ team_goals, meta: { pagination } }) => ({
  teamSummary: team_goals[0],
  pagination,
}))
