import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import { Company } from "../../context/CompanyState"
import Loading from "../../components/Loading/Loading"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import CompanyGoalForm, {
  CompanyGoalFields,
  Visibility,
} from "../CompanyGoalForm/CompanyGoalForm"
import useUpdateCompanyGoal from "../../domainHooks/goals/useUpdateCompanyGoal"
import useGoalDetails from "../../domainHooks/goals/useGoalDetails"
import { CompanyGoal, TeamGoalOwner } from "../../types/Goals"
import strings from "../../locale/strings"

type EditCompanyGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
  params: {
    goalId: string
  }
}>

export const formDisabled = (loading: boolean, companies: Company[]) =>
  loading || companies.length === 0

const EditCompanyGoal: EditCompanyGoal = ({ intl, router, params }) => {
  const { goalId } = params
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const { goal } = useGoalDetails(Number(goalId))
  const currentUser = useCurrentUser()
  // NOTE: Do we need to have a search term here?
  const { company } = currentUser
  const { id: companyId } = company

  // NOTE: company should never be null
  const companies = [
    { id: companyId ? companyId.toString() : "", name: company.name },
  ]
  const { updateCompanyGoal, loading, data, error } = useUpdateCompanyGoal(
    goalId,
    companyId ? companyId.toString() : ""
  )

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.editGoalNotifications.successTitle),
        message: formatMessage(
          strings.editGoalNotifications.successNotification
        ),
      })
      router.push({
        pathname: `/new_goals/company`,
        query: {
          // @ts-ignore
          goalId: data.goal.id,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.editGoalNotifications.errorTitle),
        message: formatMessage(strings.editGoalNotifications.errorNotification),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (!goal) return <Loading />
  let priority
  switch (goal.priority) {
    case "low":
      priority = 0
      break
    case "medium":
      priority = 1
      break
    case "high":
      priority = 2
      break
  }

  const initOwners: TeamGoalOwner[] = (goal as CompanyGoal).owners
  const goalFields: CompanyGoalFields = {
    name: goal.name,
    companyId: companyId ? companyId.toString() : "",
    description: goal.description ? goal.description : "",
    dueDate: goal.dueDate,
    priority: priority as 0 | 1 | 2,
    visibility: goal.visibility as Visibility,
    keyResults: goal.keyResults,
    alignedGoalIds: goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.map((goal) => goal.id)
      : [],
    owners: initOwners,
  }

  const initCompany = () =>
    company
      ? { value: companyId ? companyId.toString() : "", label: company.name }
      : undefined

  return (
    <CompanyGoalForm
      title={formatMessage(strings.editCompanyGoal.title)}
      submitLabel={formatMessage(strings.editCompanyGoal.submitLabel)}
      defaultFields={goalFields}
      companies={companies}
      initCompany={companyId ? initCompany() : undefined}
      onSubmit={(fields) => updateCompanyGoal(fields, goal)}
      onLeavePage={() => router.push("/new_goals/company")}
      disabled={formDisabled(loading, companies)}
      initOwners={initOwners}
      goalId={goalId}
    />
  )
}

export default injectIntl(EditCompanyGoal)
