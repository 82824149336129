import React from "react"
import addIcon from "@kaizen/component-library/icons/add.icon.svg"
import strings from "../../locale/strings"
import Button from "../../components/Button/Button"
import "./AddAnswerChoiceButton.less"
import { useIntl } from "../../hooks/locale/useIntl"

type Props = {
  onClick: (e: React.MouseEvent) => void
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
}

const AddAnswerChoiceButton = ({ onClick, onBlur, onFocus }: Props) => {
  const { formatMessage } = useIntl()
  return (
    <Button
      actionType="secondary"
      icon={addIcon}
      className="AddAnswerChoiceButton"
      // @ts-ignore: I don't understand this error
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {/*
      // @ts-ignore: this should be a string, but it still works... */}
      <div className="layout horizontal center-center">
        {formatMessage(strings.customizedQuestions.addChoice)}
      </div>
    </Button>
  )
}

export default AddAnswerChoiceButton
