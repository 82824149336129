import Reflux from "reflux-core"
import { List } from "immutable"
import _ from "lodash"
import Actions from "../refluxActions"

export default Reflux.createStore({
  init() {
    this.data = {
      template: null,
      templateType: null,
      defaultTemplate: null,
    }

    Actions.Template.getTemplate.completed.listen((templates) => {
      this.data = {
        ...this.data,
        defaultTemplate: this.getDefaultTemplate(templates),
        template: templates[0] ? templates[0].questions : List([]),
        // TemplateType could either be "directs" (upwards feedback), "other" (all users)
        // or "noDefault"
        templateType: this.getTemplateType(templates),
      }
      this.trigger(this.data)
    })
  },

  getTemplateType(templates) {
    if (templates[0]) {
      return templates[0].user_policies[0].author_subject_relationship
    } else if (this.data.defaultTemplate) {
      return this.data.defaultTemplate.user_policies[0]
        .author_subject_relationship
    } else {
      return "noDefault"
    }
  },

  getDefaultTemplate(templates) {
    const defaultTemplate = _.find(templates, (template) => {
      return template.user_policies[0].author_subject_relationship === "other"
    })
    return defaultTemplate ? defaultTemplate : this.data.defaultTemplate
  },

  getInitialState() {
    return this.data
  },
})
