import { useContext, useEffect, useCallback } from "react"
import { getCompanyGoalsResponseDecoder } from "../../api/decoders/getCompanyGoalsDecoder/getCompanyGoalsResponseDecoder"
import { GoalsContext } from "../../context/GoalsState"
import { CompanyGoalPreview } from "../../types/Goals"
import useDataAPI from "../performanceApi/useDataAPI"

type Filters = {
  from?: string
  to?: string
}

const useCompanyGoals = (companyId?: number, filters?: Filters) => {
  const { state: goals, dispatch } = useContext(GoalsContext)
  const { fire, loading, data } = useDataAPI(`goals/company_goals_index`, "GET")

  const fetch = useCallback(() => {
    if (companyId) {
      fire({ ...filters })
    }
  }, [companyId, filters, fire])

  useEffect(() => {
    if (data && companyId) {
      getCompanyGoalsResponseDecoder
        .decodePromise(data)
        .then(({ goals }) => {
          dispatch({
            type: "FETCH_COMPANY_GOALS",
            payload: { companyId: companyId, goals },
          })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          throw error
        })
    }
  }, [companyId, data, dispatch])

  const companyGoalIds =
    (companyId && goals.goalsIdsByCompanyId[companyId]) || []
  const companyGoals = companyGoalIds.reduce<CompanyGoalPreview[]>(
    (companyGoals, goalId) => {
      const goalPreview = goals.goalsPreviewsById[goalId] as CompanyGoalPreview
      if (goalPreview) {
        return [...companyGoals, goalPreview]
      }
      return companyGoals
    },
    []
  )

  return {
    fetch,
    companyGoals,
    loading: loading,
  }
}

export default useCompanyGoals
