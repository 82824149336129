import * as React from "react"
import { Button, Text } from "@kaizen/component-library"
import { InjectedIntl, injectIntl } from "react-intl"
import addIcon from "@kaizen/component-library/icons/add.icon.svg"
import { default as KeyResultListItem, KeyResult } from "./KeyResultListItem"
import strings from "../../locale/strings"
import styles from "./KeyResultList.scss"

type KeyResultList = React.FunctionComponent<{
  intl: InjectedIntl
  title: string
  keyResults: KeyResult[]
  addKeyResult: () => void
  updateKeyResult: (id: string, title: string) => void
  removeKeyResult: (id: string) => void
}>

// note: look to abstract keyResults and functions from file
const KeyResultList: KeyResultList = ({
  title,
  keyResults,
  addKeyResult,
  updateKeyResult,
  removeKeyResult,
  intl,
}) => (
  <div>
    <div className={styles.titleContainer}>
      <Text tag="span" style="label">
        {title}
      </Text>
    </div>
    {keyResults.map((kr, index) => (
      <KeyResultListItem
        key={`key-result-${index}`}
        keyResult={kr}
        index={index}
        updateKeyResult={updateKeyResult}
        removeKeyResult={removeKeyResult}
      />
    ))}
    <div className={styles.iconContainer}>
      <Button
        onClick={addKeyResult}
        icon={addIcon}
        label={intl.formatMessage(
          strings.keyResultsListItem.addKeyResultButton
        )}
        automationId="demo-button"
      />
    </div>
  </div>
)

export default injectIntl(KeyResultList)
