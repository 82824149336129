import * as React from "react"
import { hot } from "react-hot-loader/root"
import { IndexRoute, Route } from "react-router"
// @ts-ignore
import { checkConfigurationOptions } from "../../utils/auth/Authenticate"
import { SurveyResponsesProvider } from "../../context/SurveyState"
import IncompleteTBFPage from "../../entry/IncompleteTBFPage/IncompleteTBFPage"
import TeamFeedbackRequest from "../../entry/TeamFeedbackRequest/TeamFeedbackRequest"
import ConfigurationOptions from "../../constants/configurationOptions"

type SurveysResponsesRoutes = React.FunctionComponent

const SurveysResponsesRoutes: SurveysResponsesRoutes = ({ children }) => (
  <SurveyResponsesProvider>{children}</SurveyResponsesProvider>
)

export const routes = (
  <Route
    path="team_based_feedback"
    component={hot(SurveysResponsesRoutes)}
    onEnter={checkConfigurationOptions(ConfigurationOptions.teamBasedFeedback)}
  >
    <IndexRoute component={hot(IncompleteTBFPage)} />
    <Route
      path="team_based_feedback/:teamId"
      component={TeamFeedbackRequest}
      onEnter={checkConfigurationOptions(
        ConfigurationOptions.teamBasedFeedback
      )}
      /* TODO - add team lead authentication*/
    />
  </Route>
)
