import React from "react"
import { Link } from "react-router"
// @ts-ignore
import Icon from "../Icon/Icon"
import "./Breadcrumb.less"

type Props = {
  to: string | object
  query?: object
  className: string
}

export default class Breadcrumb extends React.Component<Props> {
  static defaultProps = {
    className: "",
  }

  render() {
    const { children, className, ...linkProps } = this.props

    return (
      <Link
        className={`Breadcrumb layout horizontal center ${className}`}
        {...linkProps}
      >
        <Icon iconName="chevronLeft" />
        <span className="left-align">{children}</span>
      </Link>
    )
  }
}
