import { Paragraph, Box } from "@kaizen/component-library"
import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import strings from "../../locale/strings"
import styles from "./OutgoingAlignedGoals.scss"
import { Goal } from "../../types/Goals"
import AlignedGoalsSection from "./AlignedGoalsSection"
import Aid from "../../constants/automationId"
import { useRouter } from "../../hooks/routing/useRouter"

interface OutgoingAlignedGoals {
  goal: Goal
  intl: InjectedIntl
  hasTeamGoalsEnabled: boolean
}

const OutgoingAlignedGoals = ({
  goal,
  intl,
  hasTeamGoalsEnabled,
}: OutgoingAlignedGoals) => {
  const { formatMessage } = intl

  const getAlignedGoals = (goalType: string) =>
    goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.filter(
          (alignedGoal) => alignedGoal.goalType === goalType
        )
      : []

  const router = useRouter()

  const alignedTeamGoals = getAlignedGoals("team")
  const alignedDepartmentGoals = getAlignedGoals("department")
  const alignedCompanyGoals = getAlignedGoals("company")

  const onGoalClick = (goalId: number) => {
    router.push({
      pathname: router.location.pathname,
      query: {
        ...router.location.query,
        goalId: goalId,
      },
    })
  }

  return (
    <div data-automation-id={Aid.outgoingGoalAlignments}>
      <div className={styles.outgoingAlignmentsLabel}>
        <Box mt={1}>
          <Paragraph variant="body">
            {formatMessage(
              strings.goalsPage.goalsDossier.goalAlignments.alignedTo
            )}
          </Paragraph>
        </Box>
      </div>
      {!!(alignedTeamGoals.length && hasTeamGoalsEnabled) ||
      !!alignedDepartmentGoals.length ||
      !!alignedCompanyGoals.length ? (
        <div>
          {!!(alignedTeamGoals.length && hasTeamGoalsEnabled) && (
            <AlignedGoalsSection
              alignedGoals={alignedTeamGoals}
              goalType="team"
              onGoalClick={onGoalClick}
            />
          )}
          {alignedDepartmentGoals.length > 0 && (
            <AlignedGoalsSection
              alignedGoals={alignedDepartmentGoals}
              goalType="department"
              onGoalClick={onGoalClick}
            />
          )}
          {alignedCompanyGoals.length > 0 && (
            <AlignedGoalsSection
              alignedGoals={alignedCompanyGoals}
              goalType="company"
              onGoalClick={onGoalClick}
            />
          )}
        </div>
      ) : (
        <div className={styles.emptyState}>
          <Paragraph variant="small">
            {formatMessage(
              strings.goalsPage.goalsDossier.goalAlignments.emptyState
            )}
          </Paragraph>
        </div>
      )}
    </div>
  )
}

export default injectIntl(OutgoingAlignedGoals)
