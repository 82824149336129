import { FormattedMessage, FormattedNumber } from "react-intl"
import PropTypes from "prop-types"
import React from "react"
import Tooltip from "rc-tooltip"
import cx from "classnames"
import strings from "../../locale/strings"
import { safeDivide } from "../../utils/math"
import Button from "../Button/Button"
import User from "../../models/User"
import "./BucketSummary.less"

const messageDescriptorType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  description: PropTypes.string,
})

export const bucketSummaryItemType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
  description: PropTypes.string,
  color: PropTypes.string,
})

export const sectionSummaryType = PropTypes.shape({
  buckets: PropTypes.arrayOf(bucketSummaryItemType).isRequired,
  totalCount: PropTypes.number,
  denominationMsgDescriptor: messageDescriptorType,
  showRawNumbers: PropTypes.bool,
})

export default class BucketSummary extends React.Component {
  static propTypes = {
    selectedBucketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelectBucket: PropTypes.func,
    sections: PropTypes.arrayOf(sectionSummaryType).isRequired,
    startingBucketSection: PropTypes.string,
  }

  static contextTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const currentIndex = props.sections.findIndex(
      ({ key }) => key === props.startingBucketSection
    )
    this.state = {
      currentIndex: currentIndex === -1 ? 0 : currentIndex,
    }
  }

  handleSelectBucket(bucket) {
    const { onSelectBucket, selectedBucketId } = this.props

    onSelectBucket(selectedBucketId === bucket.id ? null : bucket)
  }

  handleStatusSelect() {
    const { currentIndex } = this.state
    currentIndex === 0 && this.setState({ currentIndex: 1 })
  }

  handleAssessmentSelect() {
    const { currentIndex } = this.state
    currentIndex === 1 && this.setState({ currentIndex: 0 })
  }

  renderBucket(bucket, section) {
    const { id, description, title, count, color } = bucket
    const { denominationMsgDescriptor, showRawNumbers, totalCount } = section
    const { selectedBucketId, onSelectBucket, sections } = this.props
    const selected = selectedBucketId === id
    const valueToDisplay = !showRawNumbers ? (
      <FormattedNumber
        key={`${id}-valueToDisplay`}
        value={safeDivide(count, totalCount)}
        style="percent"
        minimumFractionDigits={1}
        maximumFractionDigits={1}
      />
    ) : (
      count
    )

    return (
      <Tooltip
        key={`${id}-tooltip`}
        placement="top"
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        mouseEnterDelay={0.1}
        trigger={description ? ["hover"] : []}
        overlay={<div className="BucketSummary--tooltip">{description}</div>}
      >
        <div
          key={`${id}-bucket`}
          onClick={onSelectBucket && this.handleSelectBucket.bind(this, bucket)}
          style={selected ? { backgroundColor: color } : null}
          className={cx(
            {
              "BucketSummary--bucket-clickable": onSelectBucket,
              "BucketSummary--bucket-selected": selected,
              "BucketSummary--bucket-single": sections.length === 1,
            },
            "BucketSummary--bucket flex layout vertical center"
          )}
        >
          <div
            key={`${id}-title`}
            className="BucketSummary--title flex layout horizontal center-center"
          >
            {title}
          </div>

          <div
            key={`${id}-value`}
            style={selected ? null : { color }}
            className="BucketSummary--value"
          >
            {valueToDisplay}
          </div>

          {denominationMsgDescriptor && (
            <div key={`${id}-count`} className="BucketSummary--count">
              <FormattedMessage
                {...denominationMsgDescriptor}
                values={{ count }}
              />
            </div>
          )}
        </div>
      </Tooltip>
    )
  }

  renderSection(section, index) {
    const { buckets } = section
    const { currentIndex } = this.state

    return (
      <div
        key={`${index}-section`}
        className="BucketSummary--section layout vertical"
        style={{ transform: `translateX(${-currentIndex * 100}%)` }}
      >
        {section.sectionTitle && (
          <div
            key={`${index}-title`}
            className="BucketSummary--section-title layout horizontal center-center flex none"
          >
            <strong
              key={`${index}-text`}
              className="BucketSummary--section-title-text"
            >
              <FormattedMessage {...section.sectionTitle} />
            </strong>
          </div>
        )}
        <div
          key={`${index}-data`}
          className="BucketSummary--section-data layout horizontal wrap flex one"
        >
          {buckets.map((bucket) => this.renderBucket(bucket, section))}
        </div>
      </div>
    )
  }

  renderStepsButtons() {
    const { currentIndex } = this.state
    const cantSeeAssessmentStats =
      !User.isAdmin() &&
      User.hasFlag(this.context.user, "disable_assessment_group_filters")
    return (
      <div className="BucketSummary--buttons-steps-btn-wrapper">
        {!cantSeeAssessmentStats && (
          <div
            className={`BucketSummary--buttons-steps-btn ${
              currentIndex === 0 ? "active" : ""
            }`}
          >
            <Button
              actionType="plain"
              onClick={() => this.handleAssessmentSelect()}
            >
              <FormattedMessage {...strings.adminPerformanceReview.groups} />
            </Button>
          </div>
        )}
        <div
          className={`BucketSummary--buttons-steps-btn ${
            currentIndex === 1 ? "active" : ""
          }`}
        >
          <Button actionType="plain" onClick={() => this.handleStatusSelect()}>
            {<FormattedMessage {...strings.adminPerformanceReview.status} />}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { sections } = this.props
    const sectionCount = sections.length

    return (
      <div className="BucketSummary layout vertical">
        <div className="BucketSummary--main-body flex layout horizontal">
          <div className="BucketSummary--sections layout horizontal flex one">
            {sections.map((section, index) =>
              this.renderSection(section, index)
            )}
          </div>
        </div>
        {sectionCount > 1 && (
          <div className="BucketSummary--buttons-steps layout horizontal center-center flex none">
            {this.renderStepsButtons()}
          </div>
        )}
      </div>
    )
  }
}
