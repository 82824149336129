import Reflux from "reflux-core"
import Immutable, { Map, Record, List } from "immutable"
import _ from "lodash"
import * as AdminActions from "../refluxActions"
import observeSearchActions from "../../refluxStores/lib/observeSearchActions"
import Company from "../../models/Company"

export const GoalSource = Record({ goalType: "my_goal", sourceId: -1 })

export const sourceForGoal = (goal) => {
  return GoalSource({
    goalType: goal.goal_type,
    sourceId:
      goal.goal_type === "company_goal"
        ? Company.CURRENT_COMPANY_ID
        : goal.department_id || goal.user_id || null,
  })
}

const AdminGoalStore = Reflux.createStore({
  init() {
    this.data = {
      ...this.data,
      goalSearchResults: null,
      goalPagination: null,
      goalStats: null,
      statisticsForCompany: null,
      statisticsByDepartmentIds: Map(),
      teamStatisticsByDepartmentIds: Map(),
      departmentStats: null,
      goalsBySourceAdminView: Map(),
    }

    this.listenTo(AdminActions.Goal.load.completed, this.updateGoal)

    this.listenTo(
      AdminActions.Goal.loadStatsOverview.completed,
      this.onStatsOverviewLoaded
    )
    this.listenTo(
      AdminActions.Goal.loadDepartmentStats.completed,
      this.onDepartmentStatsLoaded
    )
    this.listenTo(
      AdminActions.Goal.loadTeamStats.completed,
      this.onTeamStatsLoaded
    )

    this.listenTo(
      AdminActions.Goal.list.completed,
      ({ goals, departmentId, userId, couldIncludeCompanyGoals }) => {
        this.setGoalsForSources({
          goals,
          sources: _.compact([
            userId && GoalSource({ goalType: "my_goal", sourceId: userId }),

            departmentId &&
              GoalSource({
                goalType: "department_goal",
                sourceId: departmentId,
              }),

            couldIncludeCompanyGoals &&
              GoalSource({
                goalType: "company_goal",
                sourceId: Company.CURRENT_COMPANY_ID,
              }),
          ]),
        })
        this.trigger(this.data)
      }
    )

    observeSearchActions(this, {
      getResults: () => this.data.goalSearchResults,
      searchAction: AdminActions.Goal.searchGoals,
      pageAction: AdminActions.Goal.pageGoals,
      onChange: (goalSearchResults, { pagination, stats }) => {
        this.data = {
          ...this.data,
          goalSearchResults,
          goalPagination: pagination,
          goalStats: stats,
        }

        this.trigger(this.data)
      },
    })
  },

  getInitialState() {
    return this.data
  },

  setGoalsForSources({ goals, sources }) {
    const newGoalsBySourceEntries = sources.map((source) => [
      source,
      List(goals.filter((g) => Immutable.is(sourceForGoal(g), source))),
    ])

    this.data = {
      ...this.data,
      goalsBySourceAdminView: this.data.goalsBySourceAdminView.merge(
        newGoalsBySourceEntries
      ),
    }
  },

  updateGoal(updatedGoal) {
    const { goalSearchResults } = this.data

    this.data = {
      ...this.data,
      goalSearchResults:
        goalSearchResults &&
        goalSearchResults.map((goal) =>
          goal.id === updatedGoal.id ? updatedGoal : goal
        ),
    }

    this.trigger(this.data)
  },

  onStatsOverviewLoaded({ stats, departmentId }) {
    const { statisticsForCompany, statisticsByDepartmentIds } = this.data

    this.data = {
      ...this.data,
      statisticsForCompany: !departmentId ? stats : statisticsForCompany,
      statisticsByDepartmentIds: departmentId
        ? this.data.statisticsByDepartmentIds.set(departmentId, stats)
        : statisticsByDepartmentIds,
    }

    this.trigger(this.data)
  },

  onDepartmentStatsLoaded(departmentStats) {
    this.data = { ...this.data, departmentStats }
    this.trigger(this.data)
  },

  onTeamStatsLoaded({ stats, departmentId }) {
    this.data = {
      ...this.data,
      teamStatisticsByDepartmentIds: this.data.teamStatisticsByDepartmentIds.set(
        departmentId,
        stats
      ),
    }

    this.trigger(this.data)
  },
})

export default AdminGoalStore
