import Reflux from "./ActionsInitializer"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { SELF_REFLECTIONS_URLS } = endpoints
const { SELF_REFLECTIONS_URL } = SELF_REFLECTIONS_URLS
const agent = SteadyfootAgent.defaultInstance

const SelfReviewActions = Reflux.createActions({
  loadReviewById: { asyncResult: true },
  markAsManagerSeen: { asyncResult: true },
})

SelfReviewActions.loadReviewById.listenAndPromise(
  ({ id, forCompletion }, { loadingState = false } = {}) =>
    addLoadingState(
      loadingState,
      addToasts(
        {},
        extractResponseKey(
          "self_reflection",
          agent.get(`${SELF_REFLECTIONS_URL}/${id}`)
        )
      )
    ).then((result) => ({ selfReflection: result, forCompletion }))
)

SelfReviewActions.markAsManagerSeen.listenAndPromise((reviewId) =>
  extractResponseKey(
    "self_reflection",
    agent
      .put(`${SELF_REFLECTIONS_URL}/${reviewId}`)
      .send({ self_reflection: { manager_seen: true } })
  )
)

export default SelfReviewActions
