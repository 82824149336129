import * as React from "react"
import { Location } from "history"
import { logEvent } from "@cultureamp/amplitude"
import { hot } from "react-hot-loader/root"
import { IndexRoute, Route, InjectedRouter } from "react-router"
// @ts-ignore
import { checkConfigurationOptions } from "../../utils/auth/Authenticate"
import EditTeam from "../EditTeam/EditTeam"
import NewTeam from "../NewTeam/NewTeam"
import Team from "../Team/Team"
import TeamsList from "../TeamsList/TeamsList"
import ArchivedTeamsList from "../ArchivedTeamsList/ArchivedTeamsList"
import ArchivedTeam from "../../entry/ArchivedTeam/ArchivedTeam"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import ConfigurationOptions from "../../constants/configurationOptions"
import { PageLayout } from "../../components/layout/PageLayout/PageLayout"
import useGoalDetails from "../../domainHooks/goals/useGoalDetails"
import GoalDetails from "../GoalDossier/GoalDetails"
import Loading from "../../components/Loading/Loading"

type TeamsApp = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
}>

const TeamsApp: TeamsApp = ({ children, location, router }) => {
  const {
    isOpen: dossierIsOpen,
    close: closeDossier,
    goalId = "",
  } = useGoalDossier(router, location)
  const { goal } = useGoalDetails(Number(goalId))

  React.useEffect(() => {
    if (location.pathname === "/teams") {
      // @ts-ignore
      logEvent("My Teams Index Viewed")
    }
  }, [location.pathname])

  return (
    <PageLayout
      dossierSettings={{
        isOpen: dossierIsOpen,
        content: goal ? <GoalDetails goal={goal} /> : <Loading />,
        onClose: closeDossier,
      }}
    >
      {children}
    </PageLayout>
  )
}

export const routes = (
  <Route
    path="teams"
    component={hot(TeamsApp)}
    onEnter={checkConfigurationOptions(ConfigurationOptions.teams)}
  >
    <IndexRoute component={hot(TeamsList)} />
    <Route path="archived" component={hot(ArchivedTeamsList)} />
    <Route path="archived/:teamId" component={hot(ArchivedTeam)} />
    <Route path=":teamId" component={hot(Team)} />
    <Route path="new/:teamId" component={hot(NewTeam)} />
    <Route path="edit/:teamId" component={hot(EditTeam)} />
  </Route>
)
