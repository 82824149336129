import gql from "graphql-tag"

export const GET_EMPLOYEE_PROFILE = gql`
  query GetEmployeeProfile($userId: ID) {
    employee(id: $userId) {
      id
      fullName
      email
      isActive
      jobTitle {
        title
      }
      profileImageUrl
    }
  }
`
