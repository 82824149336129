enum UserFilterType {
  Departments = "DEPARTMENTS",
  Managers = "MANAGERS",
  JobTitles = "JOB_TITLES",
  Levels = "LEVELS",
  Users = "USERS",
  More = "MORE",
  OptionalAttributes = "OPTIONAL_ATTRIBUTES",
}

export default UserFilterType
