import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../refluxActions"
import handleLazyLoadAction from ".//lib/handleLazyLoadAction"

const UserSummaryStore = Reflux.createStore({
  init() {
    this.initializeData()

    this.listenTo(Actions.UserSummary.getCategories.completed, (categories) => {
      this.data = {
        ...this.data,
        categories,
        categoryIndex: this.data.categoryIndex ? 0 : null,
      }
      this.trigger(this.data)
    })

    this.listenTo(
      Actions.UserSummary.getCompanyCategories.completed,
      (companyCategories) => {
        this.data = { ...this.data, companyCategories }
        this.trigger(this.data)
      }
    )

    this.listenTo(Actions.UserSummary.getAllSkills.completed, (skills) => {
      this.data = {
        ...this.data,
        topStrengths: _.filter(
          skills,
          (skill) => (skill.score > 0 || skill.score === 0) && skill.sum_pos > 0
        ).slice(0, 3),
        topAreasToImprove: _.filter(
          skills,
          (skill) => (skill.score > 0 || skill.score === 0) && skill.sum_neg > 0
        ).slice(-3),
      }
      this.trigger(this.data)
    })

    this.listenTo(Actions.UserSummary.setCategoryIndex, (categoryIndex) => {
      this.data = { ...this.data, categoryIndex }
      this.trigger(this.data)
    })

    this.listenTo(Actions.UserSummary.nextCategory, () => {
      this.data = { ...this.data, categoryIndex: this.data.categoryIndex + 1 }
      this.trigger(this.data)
    })

    this.listenTo(Actions.UserSummary.previousCategory, () => {
      this.data = { ...this.data, categoryIndex: this.data.categoryIndex - 1 }
      this.trigger(this.data)
    })

    handleLazyLoadAction(this, Actions.UserSummary.getSkillsBySkillDisplay, {
      getLocalData: (parentSkillId) => {
        const skills = this.data.skillsBySkillDisplay[parentSkillId]
        return skills ? { parentSkillId, skills } : null
      },
      handleCompletion: ({ parentSkillId, skills }) => {
        this.data = {
          ...this.data,
          skillsBySkillDisplay: {
            ...this.data.skillsBySkillDisplay,
            [parentSkillId]: skills,
          },
        }
        this.trigger(this.data)
      },
    })

    handleLazyLoadAction(
      this,
      Actions.UserSummary.getCompanySkillsBySkillDisplay,
      {
        getLocalData: (parentSkillId) => {
          const skills = this.data.companySkillsBySkillDisplay[parentSkillId]
          return skills ? { parentSkillId, skills } : null
        },
        handleCompletion: ({ parentSkillId, skills }) => {
          this.data = {
            ...this.data,
            companySkillsBySkillDisplay: {
              ...this.data.companySkillsBySkillDisplay,
              [parentSkillId]: skills,
            },
          }
          this.trigger(this.data)
        },
      }
    )

    this.listenTo(
      Actions.UserSummary.getAllCategoryProgress.completed,
      (skillsProgress) => {
        this.data = {
          ...this.data,
          categoryProgressById: _.groupBy(skillsProgress, "skill_id"),
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      Actions.UserSummary.getReviewersBySkill.completed,
      ({ skillId, result = {} }) => {
        this.data = {
          ...this.data,
          reviewersBySkill: {
            ...this.data.reviewersBySkill,
            [skillId]: result,
          },
        }
        this.trigger(this.data)
      }
    )

    handleLazyLoadAction(this, Actions.UserSummary.getCompanyCategoryProgress, {
      getLocalData: (categoryId) => {
        const categoryProgress = this.data.companyCategoryProgressById[
          categoryId
        ]
        return categoryProgress ? { categoryId, categoryProgress } : null
      },
      handleCompletion: ({ categoryId, categoryProgress = {} }) => {
        this.data = {
          ...this.data,
          companyCategoryProgressById: {
            ...this.data.companyCategoryProgressById,
            [categoryId]: categoryProgress,
          },
        }
        this.trigger(this.data)
      },
    })

    this.listenTo(Actions.UserSummary.localClear, () => {
      this.initializeData()
      this.trigger(this.data)
    })
  },

  initializeData() {
    this.data = {
      categories: null,
      categoryProgressById: null,
      companyCategoryProgressById: {},
      companyCategories: null,
      skillsBySkillDisplay: {},
      companySkillsBySkillDisplay: {},
      reviewersBySkill: {},
      categoryIndex: null,
      topStrengths: null,
      topAreasToImprove: null,
    }
  },

  getInitialState() {
    return this.data
  },
})

export default UserSummaryStore
