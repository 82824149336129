import React from "react"
import "./Tooltip.less"

type Props = {
  appearAbove?: boolean
  showPointerCutout?: boolean
  className?: string
  children: React.ReactNode
}

function Tooltip({
  className = "",
  appearAbove,
  showPointerCutout,
  children,
}: Props) {
  return (
    <div
      className={`Tooltip ${className} ${
        appearAbove ? "Tooltip--above" : "Tooltip--right"
      }`}
    >
      <div className="Tooltip--pointer" />
      {showPointerCutout && <div className="Tooltip--pointer-cutout" />}
      {children}
    </div>
  )
}

export default Tooltip
