import React from "react"
import FlatPickr from "react-flatpickr"
// @ts-ignore
import connect from "../../higherOrderComponents/connect"
// @ts-ignore
import LocaleStore from "../../refluxStores/LocaleStore"
import Aid from "../../constants/automationId"

type Props = {
  localeData: { flatpickr: { flatpickrLocale: string } }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any
  displayTime: boolean
  automationId: Aid
}

/**
 * DEPRECATED - Please use ../IntlFlatPickr instead
 * This version of the component has a bug where if you pass in a date of
 * a different timezone, incorrect values get sent back up from the onChange
 * event.
 */
function IntlFlatPickrDeprecated({
  localeData: { flatpickr: flatpickrLocale },
  options,
  displayTime = false,
  automationId,
  ...props
}: Props) {
  return (
    <FlatPickr
      data-enable-time={displayTime}
      options={{
        locale: flatpickrLocale, // English is the implicit default
        ...options,
      }}
      data-automation-id={automationId}
      {...props}
    />
  )
}

export default connect(LocaleStore, "localeData")(IntlFlatPickrDeprecated)
