import { Comment } from "../../../context/Comments"
import { User } from "../../../types/User"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userDecoder: (data: any) => User = (data) => ({
  id: data.id,
  aggregateId: "",
  name: data.best_name,
  avatar: data.avatar_images.thumb_url,
  role: data.job_title_name,
  profileImage: data.profile_image_url,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const newCommentDecoder: (data: any) => Comment = (data) => {
  const { comment: commentData } = data

  return {
    id: commentData.id,
    body: commentData.body,
    dateSent: new Date(commentData.created_at),
    author: userDecoder(commentData.author.user),
  }
}

export default newCommentDecoder
