import * as React from "react"
import { createContext, useContext } from "react"
import {
  withRouter,
  InjectedRouter,
  RouterState,
  RouteConfig,
} from "react-router"
import { History, Location } from "history"

export interface RouterInterface extends History {
  location: Location
  setRouteLeaveHook: InjectedRouter["setRouteLeaveHook"]
  isActive: InjectedRouter["isActive"]
  params: RouterState["params"]
  routes: RouteConfig
}

// @ts-ignore: Provided that a RouterProvider is supplied, the router will never be null
const RouterContext = createContext<RouterInterface>(null)

// Added at App.js
export const RouterProvider = withRouter(({ router, children }) => {
  return (
    <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
  )
})

// Hook version of the withRouter hoc. When we upgrade to react router v5, we'll
// no longer need this wrapper.
export const useRouter = (): RouterInterface => {
  return useContext(RouterContext)
}
