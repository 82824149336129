import React, { createContext, useContext, useReducer } from "react"

export type DraftSourceType = "goal"

export interface Draft {
  body: string
}

// The key could be either the recipientId or "original" (no recipient id)
type DraftByRecipientId = Record<number, Draft>

interface State {
  draftBySourceType: Record<DraftSourceType, Record<number, DraftByRecipientId>>
}

type Action = {
  type: "FETCH_DRAFT"
  payload: {
    sourceType: DraftSourceType
    sourceId: number
    recipientId?: number
    draft: Draft
  }
}

export const draftsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "FETCH_DRAFT": {
      const { sourceType, sourceId, recipientId, draft } = action.payload
      return {
        ...state,
        draftBySourceType: {
          ...state.draftBySourceType,
          [sourceType]: {
            ...state.draftBySourceType[sourceType],
            [sourceId]: {
              ...state.draftBySourceType[sourceType][sourceId],
              [recipientId || 0]: draft,
            },
          },
        },
      }
    }
  }

  return state
}

export const DraftsContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: {
    draftBySourceType: {
      goal: {},
    },
  },
  dispatch: (state) => state,
})

export const DraftsProvider = ({ children }: { children: React.ReactNode }) => {
  const context = useContext(DraftsContext)
  const [state, dispatch] = useReducer(draftsReducer, context.state)

  return (
    <DraftsContext.Provider value={{ state, dispatch }}>
      {children}
    </DraftsContext.Provider>
  )
}
