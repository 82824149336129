import _ from "lodash"

export default function shallowEquals(
  obj: object | undefined,
  other: object | undefined,
  customizer?: (
    a: object | undefined,
    b: object | undefined,
    key: string | number
  ) => boolean
) {
  // @ts-ignore
  return _.isEqual(obj, other, (a, b, key) => {
    if (key !== undefined) {
      const customIsEqual = customizer ? customizer(a, b, key) : undefined
      return customIsEqual !== undefined ? customIsEqual : a === b
    }
  })
}
