import { default as React, useCallback, useRef, useState } from "react"
import ConfirmationModal, {
  Props as ConfirmationModalProps,
} from "../../containers/modals/ConfirmationModal"
import { useIsMounted } from "../react/useIsMounted"

type Options = Omit<
  ConfirmationModalProps,
  "isOpen" | "onConfirm" | "onDismiss"
>

/**
 * Usually with confirmation dialogs, it's just easier to show that modal
 * imperatively. This hook allows for this, by wrapping the `ConfirmationModal`
 * component.
 *
 * eg.
 *
 * const { confirmationDialog, showDialog } = useConfirmationDialog()
 * const handleClick = async () => {
 *   if (await showDialog({
 *     title: "Are you sure?"
 *   })) {
 *     alert("Message deleted")
 *   }
 * }
 * return (
 *   <>
 *     { confirmationDialog }
 *     <Button onClick={handleClick} />
 *   </>
 * )
 */
export const useConfirmationDialog = (): {
  confirmationDialog: React.ReactNode
  showDialog: (options: Options) => Promise<boolean>
} => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<Options | null>(null)
  const callback = useRef<(isAffirmative: boolean) => void>()
  const isMountedRef = useIsMounted()

  const showDialog = useCallback(
    (options: Options): Promise<boolean> =>
      new Promise((resolve, reject) => {
        setIsOpen(true)
        setOptions(options)

        callback.current = (isAffirmative: boolean) => {
          if (!isMountedRef.current) return

          setIsOpen(false)
          resolve(isAffirmative)
        }
      }),
    [isMountedRef]
  )

  const handleConfirm = useCallback(() => {
    if (callback.current) callback.current(true)
  }, [])

  const handleDismiss = useCallback(() => {
    if (callback.current) callback.current(false)
  }, [])

  return {
    confirmationDialog: options ? (
      <ConfirmationModal
        {...options}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onDismiss={handleDismiss}
      />
    ) : null,
    showDialog,
  }
}
