import * as React from "react"
import { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { WithRouterProps } from "react-router"
import { IconButton, Text } from "@kaizen/component-library"
import clearIcon from "@kaizen/component-library/icons/clear.icon.svg"
import userIcon from "@kaizen/component-library/icons/user.icon.svg"
import useSurveyResponses from "../../domainHooks/teamFeedback/useSurveyResponses"
import useSurveyResponseDetail from "../../domainHooks/teamFeedback/useSurveyResponseDetail"
import useCompleteSurveyResponses from "../../domainHooks/teamFeedback/useCompleteSurveyResponses"
import useDismissSurveyResponse from "../../domainHooks/teamFeedback/useDismissSurveyResponses"
import useDebounce from "../../hooks/timeout/useDebounce"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import { encodeSurveyItem } from "../../context/SurveyState"
import useUpdateSurveyItems from "../../domainHooks/teamFeedback/useUpdateSurveyItems"
import strings from "../../locale/strings"
import Loading from "../../components/Loading/Loading"
import styles from "./IncompleteTBFPage.scss"
import Avatar from "../../components/Avatar/Avatar"
import SurveyVisibility from "../../containers/SurveyForm/SurveyFormVisibility"
import DismissRequestFeedbackButton from "../../components/DismissRequestFeedbackButton/DismissRequestFeedbackButton"
//@ts-ignore
import ZugataFormattedDate from "../../components/ZugataFormattedDate/ZugataFormattedDate"
//@ts-ignore
import SurveyForm from "../../containers/SurveyForm/SurveyForm"
import { PageLayout } from "../../components/layout/PageLayout/PageLayout"
import { UserSelectorDrawer } from "../../containers/UserSelectorDrawer/UserSelectorDrawer"
//@ts-ignore
import ProfileContent from "../../containers/ProfileContent/ProfileContent"
import { useDossierAndDrawer } from "../../hooks/useDossierAndDrawer"

export const DISMISS_REQUEST_OPTIONS = [
  {
    text: strings.review.skipPerson.noFeedbackNow,
    value: "no_feedback",
  },
  {
    text: strings.review.skipPerson.notWorkWith,
    value: "have_not_worked_with_recently",
  },
]

/**
 * This page is available when completing Team based feedback.
 * Could be opened from in-app notifications or email.
 *
 * TODO: Load survey from invitationToken logic.
 *
 * TODO: Update kind of survey responses fetched to "TBF"
 *
 * @param {IncompleteTBFPageProps}
 *
 * @returns {IncompleteTBFPage} Page component
 */

const IncompleteTBFPage: React.FC<WithRouterProps> = ({ router, location }) => {
  const { showNotification } = useNotifications()
  const { surveyId } = location.query
  const {
    toggleDrawer,
    drawerIsOpen,
    toggleDossier,
    dossierIsOpen,
  } = useDossierAndDrawer()

  const { surveyResponses: tbfSurveyResponses, loading } = useSurveyResponses({
    kind: "TBF",
    mine: true,
    completed: false,
  })

  const [activeReviewId, setActiveReviewId] = useState<number | undefined>(
    undefined
  )

  const { surveyResponse: tbfSurvey } = useSurveyResponseDetail(activeReviewId)

  const [updatedAnswerObject, setUpdatedAnswerObject] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any | undefined
  >(undefined)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeToDebounce = (answerObject: any) => {
    // Debounce false is when SurveyForm is calling onFocus, so we don't need to debounce.
    // We only debounce open-ended questions
    return !answerObject
      ? 0
      : answerObject.debounce
      ? answerObject.answer.question_type === "open-ended"
        ? 300
        : 0
      : 0
  }

  const debouncedUpdatedAnswerObject = useDebounce(
    updatedAnswerObject,
    timeToDebounce(updatedAnswerObject)
  )

  const { updateSurveyItem } = useUpdateSurveyItems(
    tbfSurvey && tbfSurvey.id,
    debouncedUpdatedAnswerObject &&
      debouncedUpdatedAnswerObject.answer &&
      debouncedUpdatedAnswerObject.answer.id
  )

  // Updates the survey item remotely calling the API
  useEffect(() => {
    if (debouncedUpdatedAnswerObject) {
      const isMultipleChoiceQuestion =
        debouncedUpdatedAnswerObject.answer.question_type ===
          "multiple-choice" ||
        debouncedUpdatedAnswerObject.answer.question_type === "rating"
      const answerObject = isMultipleChoiceQuestion
        ? { choice: debouncedUpdatedAnswerObject.content }
        : { answer: debouncedUpdatedAnswerObject.content }

      const surveyItem = encodeSurveyItem(debouncedUpdatedAnswerObject.answer)
      const surveyItemUpdated = { ...surveyItem, ...answerObject }

      updateSurveyItem(surveyItemUpdated, answerObject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdatedAnswerObject])

  useEffect(() => {
    // If there is no activeReviewId return the first one from the list
    // If thre is an activeReviewId but is not on the list, return the first one from list
    if (
      tbfSurveyResponses.length > 0 &&
      (!activeReviewId ||
        !tbfSurveyResponses.find((tbf) => tbf.id === activeReviewId))
    ) {
      // if surveyId passed as param, set that as the active id
      setActiveReviewId(surveyId ? surveyId : tbfSurveyResponses[0].id)
    } else if (tbfSurveyResponses.length === 0 && activeReviewId) {
      router.push("/notifications")
    }
  }, [activeReviewId, router, surveyId, tbfSurveyResponses])

  const [shareWithReviewee, setShareWithReviewee] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const { dismissSurveyResponse } = useDismissSurveyResponse(
    tbfSurvey && tbfSurvey.id
  )

  const {
    data: completeAPIData,
    error: completeAPIError,
    completeSurveyResponse,
  } = useCompleteSurveyResponses(tbfSurvey && tbfSurvey.id)

  useEffect(() => {
    if (completeAPIData) {
      setShareWithReviewee(false)
      showNotification({
        type: "affirmative",
        title: "Feedback submitted!",
        message: "",
      })
    }
  }, [completeAPIData, showNotification])

  useEffect(() => {
    if (completeAPIError) {
      showNotification({
        type: "negative",
        title: "Something went wrong submitting your review, please try again!",
        message: "",
      })
    }
  }, [completeAPIError, showNotification])

  const cleanRoute = () => {
    if (surveyId) {
      router.replace({
        pathname: location.pathname,
      })
    }
  }

  return (
    <PageLayout
      dossierSettings={{
        isOpen: dossierIsOpen,
        onClose: () => toggleDossier(false),
        content: tbfSurvey ? (
          <ProfileContent
            iconTabStyle={true}
            dossierDrawer={true}
            user={tbfSurvey?.subject}
            hideActionButtons
            isOnAdminDashboard={false}
          />
        ) : (
          <Loading />
        ),
      }}
      drawerSettings={
        tbfSurvey &&
        tbfSurveyResponses && {
          isOpen: drawerIsOpen,
          onToggle: () => toggleDrawer(),
          heading: (
            <FormattedMessage {...strings.peerReview.giveFeedbackAbout} />
          ),
          content: (
            <UserSelectorDrawer
              activeUserId={tbfSurvey?.id.toString()}
              users={tbfSurveyResponses.map((tbf) => ({
                id: tbf.id.toString(),
                name: tbf.subject.name,
                avatar: tbf.subject.profileImage,
              }))}
              onSelectUser={(tbfId) => {
                cleanRoute()
                setActiveReviewId(Number(tbfId))
              }}
            />
          ),
        }
      }
    >
      {tbfSurvey && (
        <>
          <div className={styles.formContainer}></div>
          <div className={styles.surveyContainer}>
            <div className={styles.userInfo}>
              <Avatar
                key={tbfSurvey.subject.id}
                imageURL={tbfSurvey.subject.profileImage}
                size="large"
              />
              <Text tag="h2" style="zen-heading-2">
                {tbfSurvey.subject.name}
              </Text>
            </div>
            <div className={styles.requestInfo}>
              <FormattedMessage
                {...strings.review["requestedOnByTeamLead"]}
                values={{
                  requester: tbfSurvey.creator && tbfSurvey.creator.name,
                  reviewee: tbfSurvey.subject.name,
                  formattedDate: (
                    <ZugataFormattedDate
                      value={tbfSurvey.createdAt}
                      month="short"
                    />
                  ),
                }}
              />
            </div>
            {!dossierIsOpen && (
              <span className={styles.profileToggle}>
                <IconButton
                  label=""
                  onClick={() => toggleDossier()}
                  icon={userIcon}
                />
              </span>
            )}
            <div className={styles.dismissButton}>
              <DismissRequestFeedbackButton
                skipOptions={DISMISS_REQUEST_OPTIONS}
                onSkipOptionSelect={(reason: string) => {
                  setIsTooltipOpen(false)
                  dismissSurveyResponse(reason)
                }}
                icon={clearIcon}
                openTooltip={isTooltipOpen}
                toggleTooltip={() => setIsTooltipOpen(!isTooltipOpen)}
              />
            </div>
            <div className={styles.form}>
              {tbfSurvey.surveyItems && (
                <SurveyForm
                  data-testid={"survey-form"}
                  surveyId={tbfSurvey.subject.id}
                  reviewee={{
                    best_name: tbfSurvey.subject.name,
                  }}
                  visibility={SurveyVisibility.tbf}
                  allowShareWithReviewee={true}
                  answers={tbfSurvey.surveyItems.map((surveyItem) => {
                    return {
                      question_title: surveyItem.questionTitle,
                      question_description: surveyItem.questionDescription,
                      question_type: surveyItem.questionType,
                      answer: surveyItem.answer,
                      required: surveyItem.required,
                      choices: surveyItem.choices,
                      choice: surveyItem.choice,
                      id: surveyItem.id,
                      idx: surveyItem.idx,
                      state: surveyItem.state,
                      saveState: surveyItem.saveState,
                    }
                  })}
                  submitButtonText={
                    <FormattedMessage
                      {...strings.teamLeadFeedback.submitFeedback}
                      values={{ name: tbfSurvey.subject.name }}
                    />
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onAnswerChange={(answerInfo: any) => {
                    setUpdatedAnswerObject(answerInfo)
                  }}
                  onShareWithRevieweeChange={() =>
                    setShareWithReviewee(!shareWithReviewee)
                  }
                  shareWithReviewee={shareWithReviewee}
                  onSubmit={() => {
                    cleanRoute()
                    completeSurveyResponse(shareWithReviewee)
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
      {loading && <Loading />}
    </PageLayout>
  )
}

export default IncompleteTBFPage
