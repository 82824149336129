import { IconButton } from "@kaizen/component-library"
import removeIcon from "@kaizen/component-library/icons/close.icon.svg"
import * as React from "react"
import UserAvatarNameAndRole from "../UserAvatarNameAndRole/UserAvatarNameAndRole"
import styles from "./UserCard.scss"
import { User } from "../../types/User"

interface UserCardProps {
  user: User
  onRemove: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const UserCard = ({ user, onRemove, disabled }: UserCardProps) => (
  <article className={styles.card}>
    <UserAvatarNameAndRole user={user} />
    <IconButton
      disabled={disabled ? disabled : false}
      icon={removeIcon}
      // @ts-ignore: MouseEvent / React.MouseEvent incompatibility. I think kaizen needs updating maybe?
      onClick={onRemove}
    >
      Remove
    </IconButton>
  </article>
)

export default UserCard
