import _ from "lodash"
import lazy from "../utils/lazy"
import BaseModel from "./BaseModel"

const CATEGORY_SKILL_TYPE = "category"
const SKILL_SKILL_TYPE = "attribute"

export default class OntologyItem extends BaseModel {
  /**
   * Creates either a `Skill` or `Category` instance from `props`.
   */
  static of(props) {
    // Disabling because this was before my time, but really a parent shouldn't
    // know anything about its children.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const Class = props.skill_type === CATEGORY_SKILL_TYPE ? Category : Skill
    return new Class(props)
  }

  /**
   * @param {Object|Array} skill
   */
  static hasScoreData(skill) {
    return Array.isArray(skill)
      ? skill.some(OntologyItem.hasScoreData)
      : skill.score || skill.score === 0
  }

  static hasEnoughReviewees(skill) {
    return (
      skill.skill_defaults_count === undefined ||
      skill.skill_defaults_count === null ||
      skill.skill_defaults_count >= skill.minimum_reviewee_count
    )
  }

  get isNew() {
    return !this.first_published_at
  }

  get archivePlanned() {
    return !!this.staged_deleted_at && !this.deleted_at
  }
}

export class Skill extends OntologyItem {
  skill_type = SKILL_SKILL_TYPE

  @lazy
  get stagedSuggestions() {
    return _.reject(this.suggestions, "staged_deleted_at")
  }

  /**
   * @type {
   *    Array<{
   *      action: 'add' | 'edit' | 'delete',
   *      targetType: 'skill' | 'skill-description' | 'suggestion',
   *      targetName: string
   *    }>
   * }
   */
  @lazy
  get changes() {
    if (this.isNew) {
      return this.archivePlanned
        ? []
        : [{ action: "add", targetType: "skill", target: this.staged_name }]
    } else if (this.archivePlanned) {
      return [{ action: "delete", targetType: "skill", target: this.name }]
    } else {
      return _([
        this.description !== this.staged_description && {
          action: "edit",
          targetType: "skill-description",
          target: this.name,
        },
      ])
        .concat(
          _.map(this.suggestions, (s) => this._computeSuggestionChange(s))
        )
        .compact()
        .value()
    }
  }

  _computeSuggestionChange(s) {
    if (!s.first_published_at) {
      return s.staged_deleted_at
        ? null
        : { action: "add", targetType: "suggestion", target: s.staged_body }
    } else if (s.staged_deleted_at) {
      return { action: "delete", targetType: "suggestion", target: s.body }
    } else if (s.body !== s.staged_body) {
      // REVIEW: should the target here be s.body instead?
      return { action: "edit", targetType: "suggestion", target: s.staged_body }
    }
  }
}

export class Category extends OntologyItem {
  skill_type = CATEGORY_SKILL_TYPE

  createSkill(props) {
    const skill = new Skill(props)
    skill.categoryName = this.name
    skill.color = this.color
    skill.parent_skill_id = this.id
    return skill
  }

  @lazy
  get skillDefaultsByJobTitleId() {
    return _.indexBy(this.skill_defaults, "job_title.id")
  }
}
