import * as React from "react"
import { useEffect, useState } from "react"
import { WithRouterProps } from "react-router"
import Loading from "../../components/Loading/Loading"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import useCompanyGoals from "../../domainHooks/goals/useCompanyGoals"
import ScrollContainer from "../../components/ScrollContainer/ScrollContainer"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import GoalsTableHeader from "../../components/GoalsTableHeader/GoalsTableHeader"
import GoalsTable from "../../containers/GoalsTable/GoalsTable"
import GoalsEmptyState from "../../containers/GoalsEmptyState/GoalsEmptyState"
import strings from "../../locale/strings"
import FiltersBar, { Filters } from "../../components/FiltersBar/FiltersBar"
import styles from "./CompanySummariesList.scss"

type CompanySummariesListProps = React.FunctionComponent<{} & WithRouterProps>

const datePickerFilterName = (index: number) => (index === 0 ? "from" : "to")

const CompanySummariesList: CompanySummariesListProps = ({
  router,
  location,
}) => {
  const currentUser = useCurrentUser()
  const { open: openGoalDossier } = useGoalDossier(router, location)
  const companyId = currentUser.company.id
  const defaultFilters = {
    from: undefined,
    to: undefined,
  }
  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const { companyGoals, loading, fetch: fetchCompanyGoals } = useCompanyGoals(
    companyId,
    filters
  )

  useEffect(() => {
    fetchCompanyGoals()
  }, [companyId, fetchCompanyGoals, filters])

  return (
    <>
      <FiltersBar onFiltersChange={setFilters} defaultFilters={defaultFilters}>
        <div className={styles.filter}>
          <FiltersBar.DatePicker name={datePickerFilterName} allowDateRange />
        </div>
      </FiltersBar>
      <ScrollContainer onScrolledToBottom={() => {}}>
        {loading ? (
          <Loading />
        ) : companyGoals.length > 0 ? (
          <React.Fragment>
            <GoalsTableHeader goalType={"company"} />
            <GoalsTable
              titleName={currentUser.company.name}
              goals={companyGoals}
              displayOwners={true}
              goalProgress={Math.floor(
                (companyGoals.reduce(
                  (progress, goal) => progress + goal.completion,
                  0
                ) /
                  companyGoals.length) *
                  100
              )}
              openGoal={(goalId) => openGoalDossier(goalId)}
            />
          </React.Fragment>
        ) : (
          <GoalsEmptyState
            headingText={strings.companySummariesList.emptyStateHeadingText}
            bodyText={strings.companySummariesList.emptyStateBodyText}
            illustrationType={"neutral"}
          />
        )}
      </ScrollContainer>
    </>
  )
}

export default CompanySummariesList
