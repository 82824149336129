/*! jQuery UI - v1.11.4 - 2015-06-09
 * http://jqueryui.com
 * Includes: core.js, widget.js, mouse.js
 * Copyright 2015 jQuery Foundation and other contributors; Licensed MIT */

;(function(e) {
  "function" == typeof define && define.amd ? define(["jquery"], e) : e(jQuery)
})(function(e) {
  function t(t, s) {
    var n,
      a,
      o,
      r = t.nodeName.toLowerCase()
    return "area" === r
      ? ((n = t.parentNode),
        (a = n.name),
        t.href && a && "map" === n.nodeName.toLowerCase()
          ? ((o = e("img[usemap='#" + a + "']")[0]), !!o && i(o))
          : !1)
      : (/^(input|select|textarea|button|object)$/.test(r)
          ? !t.disabled
          : "a" === r
          ? t.href || s
          : s) && i(t)
  }
  function i(t) {
    return (
      e.expr.filters.visible(t) &&
      !e(t)
        .parents()
        .addBack()
        .filter(function() {
          return "hidden" === e.css(this, "visibility")
        }).length
    )
  }
  ;(e.ui = e.ui || {}),
    e.extend(e.ui, {
      version: "1.11.4",
      keyCode: {
        BACKSPACE: 8,
        COMMA: 188,
        DELETE: 46,
        DOWN: 40,
        END: 35,
        ENTER: 13,
        ESCAPE: 27,
        HOME: 36,
        LEFT: 37,
        PAGE_DOWN: 34,
        PAGE_UP: 33,
        PERIOD: 190,
        RIGHT: 39,
        SPACE: 32,
        TAB: 9,
        UP: 38
      }
    }),
    e.fn.extend({
      scrollParent: function(t) {
        var i = this.css("position"),
          s = "absolute" === i,
          n = t ? /(auto|scroll|hidden)/ : /(auto|scroll)/,
          a = this.parents()
            .filter(function() {
              var t = e(this)
              return s && "static" === t.css("position")
                ? !1
                : n.test(
                    t.css("overflow") +
                      t.css("overflow-y") +
                      t.css("overflow-x")
                  )
            })
            .eq(0)
        return "fixed" !== i && a.length
          ? a
          : e(this[0].ownerDocument || document)
      },
      uniqueId: (function() {
        var e = 0
        return function() {
          return this.each(function() {
            this.id || (this.id = "ui-id-" + ++e)
          })
        }
      })(),
      removeUniqueId: function() {
        return this.each(function() {
          ;/^ui-id-\d+$/.test(this.id) && e(this).removeAttr("id")
        })
      }
    }),
    e.extend(e.expr[":"], {
      data: e.expr.createPseudo
        ? e.expr.createPseudo(function(t) {
            return function(i) {
              return !!e.data(i, t)
            }
          })
        : function(t, i, s) {
            return !!e.data(t, s[3])
          },
      focusable: function(i) {
        return t(i, !isNaN(e.attr(i, "tabindex")))
      },
      tabbable: function(i) {
        var s = e.attr(i, "tabindex"),
          n = isNaN(s)
        return (n || s >= 0) && t(i, !n)
      }
    }),
    e("<a>").outerWidth(1).jquery ||
      e.each(["Width", "Height"], function(t, i) {
        function s(t, i, s, a) {
          return (
            e.each(n, function() {
              ;(i -= parseFloat(e.css(t, "padding" + this)) || 0),
                s &&
                  (i -= parseFloat(e.css(t, "border" + this + "Width")) || 0),
                a && (i -= parseFloat(e.css(t, "margin" + this)) || 0)
            }),
            i
          )
        }
        var n = "Width" === i ? ["Left", "Right"] : ["Top", "Bottom"],
          a = i.toLowerCase(),
          o = {
            innerWidth: e.fn.innerWidth,
            innerHeight: e.fn.innerHeight,
            outerWidth: e.fn.outerWidth,
            outerHeight: e.fn.outerHeight
          }
        ;(e.fn["inner" + i] = function(t) {
          return void 0 === t
            ? o["inner" + i].call(this)
            : this.each(function() {
                e(this).css(a, s(this, t) + "px")
              })
        }),
          (e.fn["outer" + i] = function(t, n) {
            return "number" != typeof t
              ? o["outer" + i].call(this, t)
              : this.each(function() {
                  e(this).css(a, s(this, t, !0, n) + "px")
                })
          })
      }),
    e.fn.addBack ||
      (e.fn.addBack = function(e) {
        return this.add(null == e ? this.prevObject : this.prevObject.filter(e))
      }),
    e("<a>")
      .data("a-b", "a")
      .removeData("a-b")
      .data("a-b") &&
      (e.fn.removeData = (function(t) {
        return function(i) {
          return arguments.length ? t.call(this, e.camelCase(i)) : t.call(this)
        }
      })(e.fn.removeData)),
    (e.ui.ie = !!/msie [\w.]+/.exec(navigator.userAgent.toLowerCase())),
    e.fn.extend({
      focus: (function(t) {
        return function(i, s) {
          return "number" == typeof i
            ? this.each(function() {
                var t = this
                setTimeout(function() {
                  e(t).focus(), s && s.call(t)
                }, i)
              })
            : t.apply(this, arguments)
        }
      })(e.fn.focus),
      disableSelection: (function() {
        var e =
          "onselectstart" in document.createElement("div")
            ? "selectstart"
            : "mousedown"
        return function() {
          return this.bind(e + ".ui-disableSelection", function(e) {
            e.preventDefault()
          })
        }
      })(),
      enableSelection: function() {
        return this.unbind(".ui-disableSelection")
      },
      zIndex: function(t) {
        if (void 0 !== t) return this.css("zIndex", t)
        if (this.length)
          for (var i, s, n = e(this[0]); n.length && n[0] !== document; ) {
            if (
              ((i = n.css("position")),
              ("absolute" === i || "relative" === i || "fixed" === i) &&
                ((s = parseInt(n.css("zIndex"), 10)), !isNaN(s) && 0 !== s))
            )
              return s
            n = n.parent()
          }
        return 0
      }
    }),
    (e.ui.plugin = {
      add: function(t, i, s) {
        var n,
          a = e.ui[t].prototype
        for (n in s)
          (a.plugins[n] = a.plugins[n] || []), a.plugins[n].push([i, s[n]])
      },
      call: function(e, t, i, s) {
        var n,
          a = e.plugins[t]
        if (
          a &&
          (s ||
            (e.element[0].parentNode &&
              11 !== e.element[0].parentNode.nodeType))
        )
          for (n = 0; a.length > n; n++)
            e.options[a[n][0]] && a[n][1].apply(e.element, i)
      }
    })
  var s = 0,
    n = Array.prototype.slice
  ;(e.cleanData = (function(t) {
    return function(i) {
      var s, n, a
      for (a = 0; null != (n = i[a]); a++)
        try {
          ;(s = e._data(n, "events")),
            s && s.remove && e(n).triggerHandler("remove")
        } catch (o) {}
      t(i)
    }
  })(e.cleanData)),
    (e.widget = function(t, i, s) {
      var n,
        a,
        o,
        r,
        h = {},
        l = t.split(".")[0]
      return (
        (t = t.split(".")[1]),
        (n = l + "-" + t),
        s || ((s = i), (i = e.Widget)),
        (e.expr[":"][n.toLowerCase()] = function(t) {
          return !!e.data(t, n)
        }),
        (e[l] = e[l] || {}),
        (a = e[l][t]),
        (o = e[l][t] = function(e, t) {
          return this._createWidget
            ? (arguments.length && this._createWidget(e, t), void 0)
            : new o(e, t)
        }),
        e.extend(o, a, {
          version: s.version,
          _proto: e.extend({}, s),
          _childConstructors: []
        }),
        (r = new i()),
        (r.options = e.widget.extend({}, r.options)),
        e.each(s, function(t, s) {
          return e.isFunction(s)
            ? ((h[t] = (function() {
                var e = function() {
                    return i.prototype[t].apply(this, arguments)
                  },
                  n = function(e) {
                    return i.prototype[t].apply(this, e)
                  }
                return function() {
                  var t,
                    i = this._super,
                    a = this._superApply
                  return (
                    (this._super = e),
                    (this._superApply = n),
                    (t = s.apply(this, arguments)),
                    (this._super = i),
                    (this._superApply = a),
                    t
                  )
                }
              })()),
              void 0)
            : ((h[t] = s), void 0)
        }),
        (o.prototype = e.widget.extend(
          r,
          { widgetEventPrefix: a ? r.widgetEventPrefix || t : t },
          h,
          { constructor: o, namespace: l, widgetName: t, widgetFullName: n }
        )),
        a
          ? (e.each(a._childConstructors, function(t, i) {
              var s = i.prototype
              e.widget(s.namespace + "." + s.widgetName, o, i._proto)
            }),
            delete a._childConstructors)
          : i._childConstructors.push(o),
        e.widget.bridge(t, o),
        o
      )
    }),
    (e.widget.extend = function(t) {
      for (var i, s, a = n.call(arguments, 1), o = 0, r = a.length; r > o; o++)
        for (i in a[o])
          (s = a[o][i]),
            a[o].hasOwnProperty(i) &&
              void 0 !== s &&
              (t[i] = e.isPlainObject(s)
                ? e.isPlainObject(t[i])
                  ? e.widget.extend({}, t[i], s)
                  : e.widget.extend({}, s)
                : s)
      return t
    }),
    (e.widget.bridge = function(t, i) {
      var s = i.prototype.widgetFullName || t
      e.fn[t] = function(a) {
        var o = "string" == typeof a,
          r = n.call(arguments, 1),
          h = this
        return (
          o
            ? this.each(function() {
                var i,
                  n = e.data(this, s)
                return "instance" === a
                  ? ((h = n), !1)
                  : n
                  ? e.isFunction(n[a]) && "_" !== a.charAt(0)
                    ? ((i = n[a].apply(n, r)),
                      i !== n && void 0 !== i
                        ? ((h = i && i.jquery ? h.pushStack(i.get()) : i), !1)
                        : void 0)
                    : e.error(
                        "no such method '" +
                          a +
                          "' for " +
                          t +
                          " widget instance"
                      )
                  : e.error(
                      "cannot call methods on " +
                        t +
                        " prior to initialization; " +
                        "attempted to call method '" +
                        a +
                        "'"
                    )
              })
            : (r.length && (a = e.widget.extend.apply(null, [a].concat(r))),
              this.each(function() {
                var t = e.data(this, s)
                t
                  ? (t.option(a || {}), t._init && t._init())
                  : e.data(this, s, new i(a, this))
              })),
          h
        )
      }
    }),
    (e.Widget = function() {}),
    (e.Widget._childConstructors = []),
    (e.Widget.prototype = {
      widgetName: "widget",
      widgetEventPrefix: "",
      defaultElement: "<div>",
      options: { disabled: !1, create: null },
      _createWidget: function(t, i) {
        ;(i = e(i || this.defaultElement || this)[0]),
          (this.element = e(i)),
          (this.uuid = s++),
          (this.eventNamespace = "." + this.widgetName + this.uuid),
          (this.bindings = e()),
          (this.hoverable = e()),
          (this.focusable = e()),
          i !== this &&
            (e.data(i, this.widgetFullName, this),
            this._on(!0, this.element, {
              remove: function(e) {
                e.target === i && this.destroy()
              }
            }),
            (this.document = e(i.style ? i.ownerDocument : i.document || i)),
            (this.window = e(
              this.document[0].defaultView || this.document[0].parentWindow
            ))),
          (this.options = e.widget.extend(
            {},
            this.options,
            this._getCreateOptions(),
            t
          )),
          this._create(),
          this._trigger("create", null, this._getCreateEventData()),
          this._init()
      },
      _getCreateOptions: e.noop,
      _getCreateEventData: e.noop,
      _create: e.noop,
      _init: e.noop,
      destroy: function() {
        this._destroy(),
          this.element
            .unbind(this.eventNamespace)
            .removeData(this.widgetFullName)
            .removeData(e.camelCase(this.widgetFullName)),
          this.widget()
            .unbind(this.eventNamespace)
            .removeAttr("aria-disabled")
            .removeClass(
              this.widgetFullName + "-disabled " + "ui-state-disabled"
            ),
          this.bindings.unbind(this.eventNamespace),
          this.hoverable.removeClass("ui-state-hover"),
          this.focusable.removeClass("ui-state-focus")
      },
      _destroy: e.noop,
      widget: function() {
        return this.element
      },
      option: function(t, i) {
        var s,
          n,
          a,
          o = t
        if (0 === arguments.length) return e.widget.extend({}, this.options)
        if ("string" == typeof t)
          if (((o = {}), (s = t.split(".")), (t = s.shift()), s.length)) {
            for (
              n = o[t] = e.widget.extend({}, this.options[t]), a = 0;
              s.length - 1 > a;
              a++
            )
              (n[s[a]] = n[s[a]] || {}), (n = n[s[a]])
            if (((t = s.pop()), 1 === arguments.length))
              return void 0 === n[t] ? null : n[t]
            n[t] = i
          } else {
            if (1 === arguments.length)
              return void 0 === this.options[t] ? null : this.options[t]
            o[t] = i
          }
        return this._setOptions(o), this
      },
      _setOptions: function(e) {
        var t
        for (t in e) this._setOption(t, e[t])
        return this
      },
      _setOption: function(e, t) {
        return (
          (this.options[e] = t),
          "disabled" === e &&
            (this.widget().toggleClass(this.widgetFullName + "-disabled", !!t),
            t &&
              (this.hoverable.removeClass("ui-state-hover"),
              this.focusable.removeClass("ui-state-focus"))),
          this
        )
      },
      enable: function() {
        return this._setOptions({ disabled: !1 })
      },
      disable: function() {
        return this._setOptions({ disabled: !0 })
      },
      _on: function(t, i, s) {
        var n,
          a = this
        "boolean" != typeof t && ((s = i), (i = t), (t = !1)),
          s
            ? ((i = n = e(i)), (this.bindings = this.bindings.add(i)))
            : ((s = i), (i = this.element), (n = this.widget())),
          e.each(s, function(s, o) {
            function r() {
              return t ||
                (a.options.disabled !== !0 &&
                  !e(this).hasClass("ui-state-disabled"))
                ? ("string" == typeof o ? a[o] : o).apply(a, arguments)
                : void 0
            }
            "string" != typeof o &&
              (r.guid = o.guid = o.guid || r.guid || e.guid++)
            var h = s.match(/^([\w:-]*)\s*(.*)$/),
              l = h[1] + a.eventNamespace,
              u = h[2]
            u ? n.delegate(u, l, r) : i.bind(l, r)
          })
      },
      _off: function(t, i) {
        ;(i =
          (i || "").split(" ").join(this.eventNamespace + " ") +
          this.eventNamespace),
          t.unbind(i).undelegate(i),
          (this.bindings = e(this.bindings.not(t).get())),
          (this.focusable = e(this.focusable.not(t).get())),
          (this.hoverable = e(this.hoverable.not(t).get()))
      },
      _delay: function(e, t) {
        function i() {
          return ("string" == typeof e ? s[e] : e).apply(s, arguments)
        }
        var s = this
        return setTimeout(i, t || 0)
      },
      _hoverable: function(t) {
        ;(this.hoverable = this.hoverable.add(t)),
          this._on(t, {
            mouseenter: function(t) {
              e(t.currentTarget).addClass("ui-state-hover")
            },
            mouseleave: function(t) {
              e(t.currentTarget).removeClass("ui-state-hover")
            }
          })
      },
      _focusable: function(t) {
        ;(this.focusable = this.focusable.add(t)),
          this._on(t, {
            focusin: function(t) {
              e(t.currentTarget).addClass("ui-state-focus")
            },
            focusout: function(t) {
              e(t.currentTarget).removeClass("ui-state-focus")
            }
          })
      },
      _trigger: function(t, i, s) {
        var n,
          a,
          o = this.options[t]
        if (
          ((s = s || {}),
          (i = e.Event(i)),
          (i.type = (t === this.widgetEventPrefix
            ? t
            : this.widgetEventPrefix + t
          ).toLowerCase()),
          (i.target = this.element[0]),
          (a = i.originalEvent))
        )
          for (n in a) n in i || (i[n] = a[n])
        return (
          this.element.trigger(i, s),
          !(
            (e.isFunction(o) &&
              o.apply(this.element[0], [i].concat(s)) === !1) ||
            i.isDefaultPrevented()
          )
        )
      }
    }),
    e.each({ show: "fadeIn", hide: "fadeOut" }, function(t, i) {
      e.Widget.prototype["_" + t] = function(s, n, a) {
        "string" == typeof n && (n = { effect: n })
        var o,
          r = n ? (n === !0 || "number" == typeof n ? i : n.effect || i) : t
        ;(n = n || {}),
          "number" == typeof n && (n = { duration: n }),
          (o = !e.isEmptyObject(n)),
          (n.complete = a),
          n.delay && s.delay(n.delay),
          o && e.effects && e.effects.effect[r]
            ? s[t](n)
            : r !== t && s[r]
            ? s[r](n.duration, n.easing, a)
            : s.queue(function(i) {
                e(this)[t](), a && a.call(s[0]), i()
              })
      }
    }),
    e.widget
  var a = !1
  e(document).mouseup(function() {
    a = !1
  }),
    e.widget("ui.mouse", {
      version: "1.11.4",
      options: {
        cancel: "input,textarea,button,select,option",
        distance: 1,
        delay: 0
      },
      _mouseInit: function() {
        var t = this
        this.element
          .bind("mousedown." + this.widgetName, function(e) {
            return t._mouseDown(e)
          })
          .bind("click." + this.widgetName, function(i) {
            return !0 === e.data(i.target, t.widgetName + ".preventClickEvent")
              ? (e.removeData(i.target, t.widgetName + ".preventClickEvent"),
                i.stopImmediatePropagation(),
                !1)
              : void 0
          }),
          (this.started = !1)
      },
      _mouseDestroy: function() {
        this.element.unbind("." + this.widgetName),
          this._mouseMoveDelegate &&
            this.document
              .unbind("mousemove." + this.widgetName, this._mouseMoveDelegate)
              .unbind("mouseup." + this.widgetName, this._mouseUpDelegate)
      },
      _mouseDown: function(t) {
        if (!a) {
          ;(this._mouseMoved = !1),
            this._mouseStarted && this._mouseUp(t),
            (this._mouseDownEvent = t)
          var i = this,
            s = 1 === t.which,
            n =
              "string" == typeof this.options.cancel && t.target.nodeName
                ? e(t.target).closest(this.options.cancel).length
                : !1
          return s && !n && this._mouseCapture(t)
            ? ((this.mouseDelayMet = !this.options.delay),
              this.mouseDelayMet ||
                (this._mouseDelayTimer = setTimeout(function() {
                  i.mouseDelayMet = !0
                }, this.options.delay)),
              this._mouseDistanceMet(t) &&
              this._mouseDelayMet(t) &&
              ((this._mouseStarted = this._mouseStart(t) !== !1),
              !this._mouseStarted)
                ? (t.preventDefault(), !0)
                : (!0 ===
                    e.data(t.target, this.widgetName + ".preventClickEvent") &&
                    e.removeData(
                      t.target,
                      this.widgetName + ".preventClickEvent"
                    ),
                  (this._mouseMoveDelegate = function(e) {
                    return i._mouseMove(e)
                  }),
                  (this._mouseUpDelegate = function(e) {
                    return i._mouseUp(e)
                  }),
                  this.document
                    .bind(
                      "mousemove." + this.widgetName,
                      this._mouseMoveDelegate
                    )
                    .bind("mouseup." + this.widgetName, this._mouseUpDelegate),
                  t.preventDefault(),
                  (a = !0),
                  !0))
            : !0
        }
      },
      _mouseMove: function(t) {
        if (this._mouseMoved) {
          if (
            e.ui.ie &&
            (!document.documentMode || 9 > document.documentMode) &&
            !t.button
          )
            return this._mouseUp(t)
          if (!t.which) return this._mouseUp(t)
        }
        return (
          (t.which || t.button) && (this._mouseMoved = !0),
          this._mouseStarted
            ? (this._mouseDrag(t), t.preventDefault())
            : (this._mouseDistanceMet(t) &&
                this._mouseDelayMet(t) &&
                ((this._mouseStarted =
                  this._mouseStart(this._mouseDownEvent, t) !== !1),
                this._mouseStarted ? this._mouseDrag(t) : this._mouseUp(t)),
              !this._mouseStarted)
        )
      },
      _mouseUp: function(t) {
        return (
          this.document
            .unbind("mousemove." + this.widgetName, this._mouseMoveDelegate)
            .unbind("mouseup." + this.widgetName, this._mouseUpDelegate),
          this._mouseStarted &&
            ((this._mouseStarted = !1),
            t.target === this._mouseDownEvent.target &&
              e.data(t.target, this.widgetName + ".preventClickEvent", !0),
            this._mouseStop(t)),
          (a = !1),
          !1
        )
      },
      _mouseDistanceMet: function(e) {
        return (
          Math.max(
            Math.abs(this._mouseDownEvent.pageX - e.pageX),
            Math.abs(this._mouseDownEvent.pageY - e.pageY)
          ) >= this.options.distance
        )
      },
      _mouseDelayMet: function() {
        return this.mouseDelayMet
      },
      _mouseStart: function() {},
      _mouseDrag: function() {},
      _mouseStop: function() {},
      _mouseCapture: function() {
        return !0
      }
    })
})
