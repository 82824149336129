import { JsonDecoder } from "ts.data.json"

export interface Pagination {
  currentPage: number
  nextPage?: number
  previousPage?: number
  totalCount: number
  totalPages: number
}

const paginationDecoder = JsonDecoder.object<Pagination>(
  {
    currentPage: JsonDecoder.number,
    nextPage: JsonDecoder.failover(undefined, JsonDecoder.number),
    previousPage: JsonDecoder.failover(undefined, JsonDecoder.number),
    totalCount: JsonDecoder.number,
    totalPages: JsonDecoder.number,
  },
  "pagination",
  {
    currentPage: "current_page",
    nextPage: "next_page",
    previousPage: "prev_page",
    totalCount: "total_count",
    totalPages: "total_pages",
  }
)

export default paginationDecoder
