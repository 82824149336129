import React from "react"
import PropTypes from "prop-types"
import { FormattedNumber } from "react-intl"
import Slider from "../../components/Slider/Slider"
import "./GoalProgressSlider.less"

/**
 * Customizes the `Slider` element for the Goals module. See the `Slider` docs for more information
 * on the props, in particular, the difference between `onChange` and `onAfterChange`.
 */
export default class GoalProgressSlider extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
  }

  static defaultProps = {
    className: "",
  }

  renderLabel = (value) => <FormattedNumber value={value} style="percent" />

  render() {
    const { className, value, onChange, onAfterChange } = this.props

    return (
      <Slider
        className={`GoalProgressSlider ${className}`}
        min={0}
        max={1}
        step={0.1}
        value={value}
        renderLabel={this.renderLabel}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
    )
  }
}
