import "./ProgressBar.less"
import React from "react"
import { FormattedNumber } from "react-intl"
import PropTypes from "prop-types"
import cx from "classnames"

export default class ProgressBar extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    completionClassName: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    className: "",
    completionClassName: "",
  }

  render() {
    const { value, color, className, children } = this.props
    const completionWidth = `${Math.round(value * 100)}%`
    const completionClass = cx(
      "ProgressBar--completion",
      this.props.completionClassName,
      {
        "ProgressBar--completion-empty": value === 0,
        "ProgressBar--completion-fill": value > 0 && value <= 0.15,
        "ProgressBar--completion-full": value > 0.15, // If value is grather than 33% of the progress we display the number on the left.
      }
    )

    return (
      <div className={`ProgressBar ${className}`}>
        <div className="ProgressBar--completion-wrapper">
          <div
            className={completionClass}
            style={{ backgroundColor: color, width: completionWidth }}
          >
            <span className="ProgressBar--text">
              <FormattedNumber value={value} style="percent" />
            </span>
          </div>
        </div>
        {children}
      </div>
    )
  }
}
