import { parseUrl, stringify } from "query-string"
import _ from "lodash"
import { modalQsKey, Modals, paramQsPrefix } from "../constants/modals"
import { buildUrl } from "./url"
import { QueryStringParams } from "../types/url"

/**
 * More information about the modals system found under docs/modals.md
 */

export const getModalHref = (
  modalKey: Modals,
  params: QueryStringParams = {},
  currentUrl: string
) => {
  const parsed = parseUrl(currentUrl)
  // Prefix the params with `m_`
  const prefixedModalParams = _.mapKeys(
    params,
    (value, key) => `${paramQsPrefix}${key}`
  )
  // Clear any existing modal parameters if they existed
  const filteredExistingQuery = _.omit(parsed.query, (val, key: string) =>
    key.startsWith(paramQsPrefix)
  )
  const newQueryString = stringify({
    ...filteredExistingQuery,
    ...prefixedModalParams,
    [modalQsKey]: modalKey,
  })

  const hash = currentUrl.split("#")[1]

  return `${parsed.url}${newQueryString ? "?" : ""}${newQueryString}${
    hash ? "#" : ""
  }${hash || ""}`
}

export const getModalCloseHref = (currentUrl: string) => {
  const parsed = parseUrl(currentUrl)
  let newQuery = { ...parsed.query, [modalQsKey]: undefined }
  newQuery = _.omit(
    newQuery,
    (value, key) => key && key.startsWith(paramQsPrefix)
  )
  const newQueryString = stringify(newQuery)

  const hash = currentUrl.split("#")[1]

  return `${parsed.url}${newQueryString ? "?" : ""}${newQueryString}${
    hash ? "#" : ""
  }${hash || ""}`
}

/**
 * Gets the modal params from the supplied url
 */
export const getModalParams = (url: string): QueryStringParams => {
  const parsed = parseUrl(url)

  const modalParams = _.pick(parsed.query, (val, key: string) =>
    key.startsWith(paramQsPrefix)
  )

  return _.mapKeys(modalParams, (value: string, key: string) =>
    key.replace(paramQsPrefix, "")
  )
}

const preixModalParamKeys = (params: QueryStringParams): QueryStringParams => {
  return _.mapKeys(params, (value, key) => `${paramQsPrefix}${key}`)
}

export const addOrReplaceModalParams = (
  url: string,
  additionalParams: QueryStringParams
) => {
  const currentParams = getModalParams(url)
  return buildUrl(url, {
    ...preixModalParamKeys(currentParams),
    ...preixModalParamKeys(additionalParams),
  })
}
