import React from "react"
import ResponsiveWrapper from "../../components/ResponsiveWrapper/ResponsiveWrapper"
import EmptyState from "../../components/EmptyState/EmptyState"
import negativeImage from "../../static/images-2017/drawings/emptystates/negative.png"
import strings from "../../locale/strings"
import { useIntl } from "../../hooks/locale/useIntl"

type Props = {
  // You can supply either an error object, or an errorMessage string for convenience.
  // It's up to you.
  error?: Error
  errorMessage?: string
  ctaText?: string
  ctaOnClick?: () => void
}

const ErrorState = ({ errorMessage, error, ctaText, ctaOnClick }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <ResponsiveWrapper className="ErrorPage">
      <div className="ErrorPage--notification-container flex layout vertical center-center">
        <EmptyState
          imgSrc={negativeImage}
          header={formatMessage(strings.errorPage.header)}
          body={
            errorMessage ||
            error?.message ||
            formatMessage(strings.errorPage.body)
          }
          ctaText={ctaText}
          ctaProps={ctaOnClick ? { onClick: ctaOnClick } : undefined}
        />
      </div>
    </ResponsiveWrapper>
  )
}

export default ErrorState
