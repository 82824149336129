import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import * as React from "react"
import styles from "./ProgressSlider.scss"

type ProgressSliderProps = {
  progress: number
  min?: number
  max?: number
  step?: number
  onProgressChange: (progress: number) => void
}

const ProgressSlider = ({
  progress,
  min = 0,
  max = 100,
  step = 1,
  onProgressChange,
}: ProgressSliderProps) => {
  const progressContainer = useRef<HTMLDivElement>(null)
  const initialPageX = useRef<number>(0)
  const [isDragging, setIsDragging] = useState(false)

  useLayoutEffect(() => {
    const onWindowResized = () => {
      if (progressContainer.current) {
        initialPageX.current = progressContainer.current.getBoundingClientRect().left
      }
    }

    window.addEventListener("resize", onWindowResized)

    return () => {
      window.removeEventListener("resize", onWindowResized)
    }
  }, [])

  const handleMouseDown = () => {
    if (progressContainer.current) {
      initialPageX.current = progressContainer.current.getBoundingClientRect().left
    }

    setIsDragging(true)
  }
  const handleMouseUp = () => setIsDragging(false)

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp)
    progressContainer.current &&
      progressContainer.current.addEventListener("mousedown", handleMouseDown)

    return () => {
      progressContainer.current &&
        // eslint-disable-next-line react-hooks/exhaustive-deps
        progressContainer.current.removeEventListener(
          "mousedown",
          handleMouseDown
        )
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [])

  const handleDrag = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !e.pageX) return
      const movement = e.pageX - initialPageX.current

      const normalizedMovement =
        progressContainer.current &&
        (movement / progressContainer.current.clientWidth) * (max - min)

      if (normalizedMovement) {
        const progressWithHelpStep = normalizedMovement + step * 0.7
        const stepProgress =
          progressWithHelpStep - (progressWithHelpStep % step) + min
        const newProgress = Math.max(min, Math.min(stepProgress, max))
        if (newProgress !== progress) {
          onProgressChange(newProgress)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [progress, isDragging]
  )

  useEffect(() => {
    document.addEventListener("mousemove", handleDrag)
    return () => {
      document.removeEventListener("mousemove", handleDrag)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, isDragging])

  const progressToPercentage = ((progress - min) / (max - min)) * 100
  return (
    <div className={styles.container} ref={progressContainer}>
      <div
        className={styles.progress}
        style={{ width: `${progressToPercentage}%` }}
      >
        <div className={styles.progressHandler}>
          <span>{Math.trunc(progress)}%</span>
        </div>
      </div>
    </div>
  )
}

export default ProgressSlider
