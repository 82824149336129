/**
 * More information about the modals system found under docs/modals.md
 */

// The query string param that determines which modal to open.
// eg. /foo/bar?modal=myModal
export const modalQsKey = "modal"
// If we need to supply additional params to the modal, we can do so via querystring params.
// The only condition is that they have this "m_" prefixed for each value. This
// assures that there are no conflicts.
export const paramQsPrefix = "modal_"

export enum Modals {
  ecPaufPreviewQuestions = "ecPaufPreviewQuestions",
  ecCreateCycle = "ecCreateCycle",
  ecEditSettings = "ecEditSettings",
  ecEditQuestionsAndSharing = "ecEditQuestionsAndSharing",
  ecPreview = "ecPreview",
  ecEditSharing = "ecEditSharing",
  ecEditEmployees = "ecEditEmployees",
  ecEditSchedule = "ecEditSchedule",
  nominateFeedback = "nominateFeedback",
  completedFeedbackModal = "completedFeedbackModal",
}
