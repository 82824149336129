import PropTypes from "prop-types"
import React from "react"
import { Box, Heading } from "@kaizen/component-library"
import "./ConversationModalWrapper.less"
export default class ConversationModalWrapper extends React.Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
  }

  render() {
    const { title, children } = this.props

    return (
      <div className="ConversationModalWrapper layout vertical flex">
        <div className="ConversationModalWrapper--header">
          <Box mb={1}>
            <Heading variant="heading-2">{title}</Heading>
          </Box>
        </div>

        {children}
      </div>
    )
  }
}
