import { Dispatch, SetStateAction, useEffect, useState } from "react"
import useKeyPress from "./useKeyPress"

const useKeyboardFocus = (
  count: number,
  onEscape: () => void,
  onEnter: (focussedIndex: number) => void
): [number, Dispatch<SetStateAction<number>>] => {
  const [focussedIndex, setFocussedIndex] = useState(0)
  const escapePressed = useKeyPress("Escape")
  const enterPressed = useKeyPress("Enter")
  const downPressed = useKeyPress("ArrowDown")
  const tabPressed = useKeyPress("Tab")
  const upPressed = useKeyPress("ArrowUp")
  useEffect(() => {
    if (escapePressed || tabPressed) {
      onEscape()
    }
    if (enterPressed) {
      onEnter(focussedIndex)
    }
    if (downPressed || upPressed) {
      const direction = upPressed ? -1 : 1
      const newFocussedIndex = focussedIndex + direction
      const normalisedIndex = ((newFocussedIndex % count) + count) % count
      setFocussedIndex(normalisedIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed, escapePressed, downPressed, upPressed, tabPressed])

  return [focussedIndex, setFocussedIndex]
}

export default useKeyboardFocus
