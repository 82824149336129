import Reflux from "reflux-core"
import _ from "lodash"
import Actions from "../refluxActions/index"
import * as AdminActions from "../admin/refluxActions"

export const createConversationStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        sourceObjectsByType: {},
        commentsByTypeSourceAndRecipient: {},
      }

      this.listenTo(
        Actions.SelfReview.loadReviewById.completed,
        ({ selfReflection }) => {
          this.handleSourceObjectLoaded({
            sourceObject: selfReflection,
            type: "self_reflection",
          })
        }
      )

      this.listenTo(
        Actions.SurveyResponse.getSurveyResponse.completed,
        (sourceObject) => {
          this.handleSourceObjectLoaded({
            sourceObject,
            type: "survey_response",
          })
        }
      )

      this.listenTo(
        AdminActions.SurveyResponse.getSurveyResponse.completed,
        (completeSurveyResponse) => {
          this.handleSourceObjectLoaded({
            sourceObject: completeSurveyResponse,
            type: "survey_response",
          })
        }
      )

      this.listenTo(Actions.Goal.load.completed, (sourceObject) => {
        this.handleSourceObjectLoaded({ sourceObject, type: "goal" })
      })

      this.listenTo(Actions.Goal.update.completed, (sourceObject) => {
        if (_.get(this.data.sourceObjectsByType.goal, sourceObject.id)) {
          this.handleSourceObjectLoaded({ sourceObject, type: "goal" })
        }
      })

      this.listenTo(Actions.Feedback.loadShare.completed, (sourceObject) => {
        this.handleSourceObjectLoaded({ sourceObject, type: "review_share" })
      })

      this.listenTo(
        Actions.Comments.list.completed,
        ({ sourceObjectId, type, recipientId, comments }) => {
          if (type === "goal") {
            this.updateSourceObject({
              id: sourceObjectId,
              transform: (sourceObject) => ({
                ...sourceObject,
                loadingComments: false,
                comments: comments,
              }),
              type,
            })
          } else {
            this.updateCommentsByRecipient({
              sourceObjectId,
              type,
              recipientId,
              transform: () => comments,
            })
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        Actions.Comments.comment,
        ({
          sourceObject,
          parentSourceObject,
          type,
          parentType,
          collectionName,
        }) => {
          if (parentType === "comments") {
            this.updateCommentSourceObject({
              id: sourceObject.id,
              type,
              parentId: _.get(parentSourceObject, "id"),
              transform: (sourceObject) => ({
                ...sourceObject,
                loadingComments: true,
              }),
              parentType,
            })
          } else {
            this.updateSourceObject({
              id: sourceObject.id,
              transform: (sourceObject) => ({
                ...sourceObject,
                loadingComments: true,
              }),
              parentId: _.get(parentSourceObject, "id"),
              type,
              parentType,
              collectionName,
            })
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        Actions.Comments.comment.completed,
        ({
          comment,
          parentSourceObject,
          type,
          collectionName,
          parentType,
          recipientId,
        }) => {
          if (parentType === "comments") {
            this.updateCommentSourceObject({
              id: comment.source_obj_id,
              type,
              parentId: _.get(parentSourceObject, "id"),
              transform: (sourceObject) => ({
                ...sourceObject,
                loadingComments: false,
                lastSavedCommentId: comment.id,
                child_comments: [
                  ...(sourceObject.child_comments || []),
                  comment,
                ],
              }),
              parentType,
            })
          } else {
            this.updateSourceObject({
              id: comment.source_obj_id,
              transform: (sourceObject) => ({
                ...sourceObject,
                loadingComments: false,
                lastSavedCommentId: comment.id,
                comments: [...(sourceObject.comments || []), comment],
              }),
              parentId: _.get(parentSourceObject, "id"),
              type,
              parentType,
              collectionName,
            })
          }

          if (recipientId) {
            this.updateCommentsByRecipient({
              sourceObjectId: comment.source_obj_id,
              type,
              recipientId,
              transform: (comments) => [...comments, comment],
            })
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        Actions.Comments.comment.failed,
        ({ sourceObject, type }) => {
          this.updateSourceObject({
            id: sourceObject.id,
            transform: (sourceObject) => ({
              ...sourceObject,
              loadingComments: false,
            }),
            type,
          })
          this.trigger(this.data)
        }
      )
    },

    getInitialState() {
      return this.data
    },

    handleSourceObjectLoaded({ sourceObject, type }) {
      const { id } = sourceObject
      const { sourceObjectsByType } = this.data
      const sourceObjects = sourceObjectsByType[type] || {}

      this.data = {
        ...this.data,
        sourceObjectsByType: {
          ...sourceObjectsByType,

          [type]: {
            ...sourceObjects,
            [id]: { ...sourceObjects[id], ...sourceObject },
          },
        },
      }

      this.trigger(this.data)
    },

    updateCommentSourceObject({ id, type, parentId, transform, parentType }) {
      const { sourceObjectsByType } = this.data
      const sourceObjects = sourceObjectsByType[type]
      const sourceObject = sourceObjects && sourceObjects[id]
      const sourceObjectComments = _.get(sourceObject, parentType)

      if (!sourceObject) {
        return
      }
      this.data = {
        ...this.data,
        sourceObjectsByType: {
          ...sourceObjectsByType,
          [type]: {
            ...sourceObjects,
            [id]: {
              ...sourceObject,
              [parentType]: sourceObjectComments.map((c) =>
                c.id === parentId ? transform(c) : c
              ),
            },
          },
        },
      }
    },

    updateSourceObject({
      id,
      parentId,
      transform,
      type,
      collectionName,
      parentType,
    }) {
      const { sourceObjectsByType } = this.data
      const sourceObjects = sourceObjectsByType[parentType || type]
      const sourceObject = parentId
        ? sourceObjects[parentId]
        : sourceObjects[id]

      if (parentType) {
        const childCollection = sourceObject[collectionName]

        this.data = {
          ...this.data,
          sourceObjectsByType: {
            ...sourceObjectsByType,

            [parentType]: {
              ...sourceObjects,

              [parentId]: {
                ...sourceObject,

                [collectionName]: childCollection.map((c) =>
                  c.id === id ? transform(c) : c
                ),
              },
            },
          },
        }
      } else {
        this.data = {
          ...this.data,
          sourceObjectsByType: {
            ...sourceObjectsByType,

            [type]: {
              ...sourceObjects,
              [id]: transform(sourceObject),
            },
          },
        }
      }
    },

    updateCommentsByRecipient({
      sourceObjectId,
      type,
      recipientId,
      transform,
    }) {
      const { commentsByTypeSourceAndRecipient } = this.data
      const commentsBySourceAndRecipient =
        commentsByTypeSourceAndRecipient[type]
      const commentsByRecipient = _.get(
        commentsBySourceAndRecipient,
        sourceObjectId
      )
      this.data = {
        ...this.data,
        commentsByTypeSourceAndRecipient: {
          ...commentsByTypeSourceAndRecipient,
          [type]: {
            ...commentsBySourceAndRecipient,
            [sourceObjectId]: {
              ...commentsByRecipient,
              [recipientId]: transform(_.get(commentsByRecipient, recipientId)),
            },
          },
        },
      }
    },
  })

export default createConversationStore()
