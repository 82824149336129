import { JsonDecoder } from "ts.data.json"
import { PersonalGoalPreview } from "../../../types/Goals"
import { outgoingAlignedGoalDecoder } from "../AlignedGoalDecoder/alignedGoalDecoder"
import { goalPriorityDecoder } from "../BaseGoalDecoder/baseGoalDecoder"

export const personalGoalPreviewDecoder: JsonDecoder.Decoder<PersonalGoalPreview> = JsonDecoder.object<
  PersonalGoalPreview
>(
  {
    type: JsonDecoder.isExactly("my_goal").map((): "personal" => "personal"),
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    completion: JsonDecoder.number,
    commentsCount: JsonDecoder.number,
    dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
    priority: goalPriorityDecoder,
    status: JsonDecoder.string,
    outgoingAlignedGoals: JsonDecoder.failover(
      [],
      JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
    ),
  },
  "personalGoalPreview",
  {
    commentsCount: "number_of_comments",
    dueDate: "due_at",
    priority: "priority_name",
    type: "goal_type",
    outgoingAlignedGoals: "outgoing_aligned_goals",
  }
)
