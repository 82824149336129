/* eslint react/jsx-key: 0 */
import React from "react"
import { IndexRedirect, Redirect, IndexRoute, Route } from "react-router"
// Hooks for onEnter
import Authenticate from "./utils/auth/Authenticate"
import ensureCultureAmpAuthed from "./utils/auth/ensureCultureAmpAuthed"
import chromePlugin from "./utils/chromePlugin"
import { lazyLoader } from "./utils/lazyLoading"
import Company from "./models/Company"
import User from "./models/User"
import R from "./constants/routes"
import { routes as GoalsAppRoutes } from "./containers/GoalsApp/GoalsApp"
import { routes as TeamsAppRoutes } from "./containers/TeamsApp/TeamsApp"
import { routes as SurveysResponsesRoutes } from "./containers/SurveysResponsesRoutes/SurveysResponsesRoutes"
import ErrorPage404 from "./entry/ErrorPage404/ErrorPage404"
import { HELP_REDIRECT_QUERY_KEY } from "./containers/HelpCenter/HelpCenter"
import FeatureFlags from "./constants/featureFlags"
import { featureToggle } from "./containers/FeatureToggle/FeatureToggle"
// @ts-ignore
import { checkConfigurationOptions } from "./utils/auth/Authenticate.js"
import ConfigurationOptions from "./constants/configurationOptions"
import EcPaufRequestFeedbackPage from "./entry/EcPaufRequestFeedbackPage"
import { allowForNewEvaluationCyclesAdminPages } from "./domain/evaluationCycles/evaluationCycles"

/* eslint-disable prettier/prettier */
// Admin pages
const AdminApp = lazyLoader(() => import(/* webpackChunkName: "AdminApp" */ "./admin/entry/AdminApp/AdminApp"))
const AdminCreateCalibrationViewPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminCalibrationViewPage/AdminCalibrationViewPage"))
const AdminCompletedSelfReflectionResponsePage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminCompletedSelfReflectionSurveyResponsePage/AdminCompletedSelfReflectionSurveyResponsePage"))
const AdminEvaluationListingPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationListingPage/AdminEvaluationListingPage"))
const AdminEvaluationQuestionsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationQuestionsPage/AdminEvaluationQuestionsPage"))
const AdminEvaluationRootPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationRootPage/AdminEvaluationRootPage"))
const AdminEvaluationSummaryPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminEvaluationSummaryPage/AdminEvaluationSummaryPage"))
const AdminFeedbackStatsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminFeedbackStatsPage/AdminFeedbackStatsPage"))
const AdminGoalsStatsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminGoalsStatsPage/AdminGoalsStatsPage"))
const AdminIncompleteSelfReflectionSurveyResponsePage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminIncompleteSelfReflectionSurveyResponsePage/AdminIncompleteSelfReflectionSurveyResponsePage"))
const AdminIndividualGoalsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminIndividualGoalsPage/AdminIndividualGoalsPage"))
const AdminIndividualManagerRequestHistoryPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminIndividualManagerRequestHistoryPage/AdminIndividualManagerRequestHistoryPage"))
const AdminManagerRequestedFeedbackPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminManagerRequestedFeedbackPage/AdminManagerRequestedFeedbackPage"))
const AdminMRFSettingsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminMRFSettingsPage/AdminMRFSettingsPage"))
const AdminSelfReflectionListingPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminSelfReflectionListingPage/AdminSelfReflectionListingPage"))
const AdminSelfReflectionSummaryPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminSelfReflectionSurveySummaryPage/AdminSelfReflectionSummaryPage"))
const AdminSkills = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminSkills/AdminSkills"))
const AdminSurveyFeedbackRequestsPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminSurveyFeedbackRequestsPage/AdminSurveyFeedbackRequestsPage"))
const AdminWelcomePage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminWelcomePage/AdminWelcomePage"))
const AdminEcListingPage = lazyLoader(() => import(/* webpackChunkName: "adminPages" */ "./admin/entry/AdminEcListingPage/AdminEcListingPage"))
const AdminSearchPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./admin/entry/AdminSearchPage/AdminSearchPage"))


// Main pages
const BrowserPluginGiveFeedback = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/BrowserPluginGiveFeedback/BrowserPluginGiveFeedback"))
const CollaboratorEvaluationPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/CollaboratorEvaluationPage/CollaboratorEvaluationPage"))
const CompletedSelfReflectionSurveyPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/CompletedSelfReflectionSurveyPage/CompletedSelfReflectionSurveyPage"))
const Development = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Development/Development"))
const Emails = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Emails/Emails"))
const EmailUnsubscribe = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/EmailUnsubscribe/EmailUnsubscribe"))
const EvaluationListingPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/EvaluationListingPage/EvaluationListingPage"))
const EvaluationQuestionsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/EvaluationQuestionsPage/EvaluationQuestionsPage"))
const EvaluationRedirectPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/EvaluationRedirectPage/EvaluationRedirectPage"))
const EvaluationRootPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/EvaluationRootPage/EvaluationRootPage"))
const EvaluationSummaryPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/EvaluationSummaryPage/EvaluationSummaryPage"))
const FeedbackList = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/FeedbackList/FeedbackList"))
const FeedbackRequestsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/FeedbackRequestsPage/FeedbackRequestsPage"))
const IncompleteMRFSurveyPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/IncompleteMRFSurveyPage/IncompleteMRFSurveyPage"))
const IncompleteSelfReflectionSurveyPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/IncompleteSelfReflectionSurveyPage/IncompleteSelfReflectionSurveyPage"))
const Leaderboard = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Leaderboard/Leaderboard"))
const MySelfReflectionSurveyResponsesPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/MySelfReflectionSurveyResponsesPage/MySelfReflectionSurveyResponsesPage"))
const MySummary = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/MySummary/MySummary"))
const Notifications = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Notifications/Notifications"))
const PerformanceReviewCommentsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PerformanceReviewCommentsPage/PerformanceReviewCommentsPage"))
const PrinterFriendlyEvaluationPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlyEvaluationPage/PrinterFriendlyEvaluationPage"))
const PrinterFriendlyPeerReviewPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlyPeerReviewPage/PrinterFriendlyPeerReviewPage"))
const PrinterFriendlySelfReflectionPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlySelfReflectionPage/PrinterFriendlySelfReflectionPage"))
const PrinterFriendlySharedEvaluationPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PrinterFriendlySharedEvaluationPage/PrinterFriendlySharedEvaluationPage"))
const Profile = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Profile/Profile"))
const PublicPraise = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PublicPraise/PublicPraise"))
const QuickComment = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/QuickComment/QuickComment"))
const Review = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Review/Review"))
const PeerFeedback = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PeerFeedback/PeerFeedback"))
const PeerFeedbackRequest = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/PeerFeedbackRequest/PeerFeedbackRequest"))
const Search = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/Search/Search"))
const SharedCalibrationViewPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/SharedCalibrationViewPage/SharedCalibrationViewPage"))
const SharedCalibrationViewsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/SharedCalibrationViewsPage/SharedCalibrationViewsPage"))
const SharedPerformanceReviewOverview = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/SharedPerformanceReviewOverview/SharedPerformanceReviewOverview"))
const TeamGoalsPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamGoalsPage/TeamGoalsPage"))
const TeamMembers = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamMembers/TeamMembers"))
const TeamSelfReflectionListingPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamSelfReflectionListingPage/TeamSelfReflectionListingPage"))
const TeamSelfReflectionSummaryPage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamSelfReflectionSummaryPage/TeamSelfReflectionSummaryPage"))
const TeamSummary = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamSummary/TeamSummary"))
const TeamUsage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamUsage/TeamUsage"))
const TeamFeedbackRequest = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/TeamFeedbackRequest/TeamFeedbackRequest"))
const OneOnOneHomepage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/OneOnOneHomepage/OneOnOneHomepage"))
const OneOnOneSpacePage = lazyLoader(() => import(/* webpackChunkName: "mainPages" */ "./entry/OneOnOneSpacePage/OneOnOneSpacePage"))

const AppRaw = lazyLoader(() => import(/* webpackChunkName: "App" */ "./entry/App/App"))

// Converts the App component into a factory function. Allows us to easilly create different
// variants of the App wrapper.
// otherProps - usually just the props passed down from react-router, like location and router
// eslint-disable-next-line react/display-name
const App = props => (otherProps) => <AppRaw {...otherProps} {...props} />
/* eslint-enable prettier/prettier */

const cultureAmpAuthAndAuthorization = async (nextState, replace, callback) => {
  await ensureCultureAmpAuthed(nextState, replace, callback)
  return Authenticate()
}

const routes = [
  <Route path="unsubscribe" component={EmailUnsubscribe} />,

  <Route path="admin" component={AdminApp} onEnter={ensureCultureAmpAuthed}>
    <IndexRoute
      component={AdminWelcomePage}
      onEnter={Authenticate({
        authorizedRoles: ["admin"],
        routeOnDeny: "admin/evaluation"
      })}
    />

    <Route
      onEnter={Authenticate({
        authorizedRoles: ["admin", "hris_admin"],
        routeOnDeny: "admin/evaluation"
      })}
    >
      <Route
        path="skills"
        onEnter={Authenticate({ authorizedRoles: ["admin"] })}
      >
        <IndexRoute component={AdminSkills} />
        <Route path=":categoryId/(:categoryName)" component={AdminSkills} />
      </Route>
    </Route>

    <Route
      onEnter={Authenticate({
        authorizedRoles: ["admin", "hr_business_partner"]
      })}
    >
      <Route path="search"
      onEnter={Authenticate({
        authorizedRoles: ["admin"]
      })}>
        <IndexRoute
          component={featureToggle({
            flag: FeatureFlags.allowAdminFeedbackDelete,
            On: AdminSearchPage,
            offRedirect: R.dashboard,
          })}
        />
      </Route>
      
      {/* <Route path="search">
        <IndexRoute
          component={AdminSearch}
        />
      </Route> */}
      
      <IndexRedirect to="evaluation" />

      <Route path="evaluation_cycles">
        <IndexRoute
          component={featureToggle({
            predicate: allowForNewEvaluationCyclesAdminPages,
            // The new, Evaluation Cycles page, with added Peer and Upward feedback
            On: AdminEcListingPage,
            // The old, Employee Evaluation page
            offRedirect: R.employeeEvaluationsListing,
          })}
        />
      </Route>

      <Route path="evaluation">
        <IndexRoute
          component={featureToggle({
            predicate: allowForNewEvaluationCyclesAdminPages,
            // The new, Evaluation Cycles page, with added Peer and Upward feedback
            onRedirect: R.ecListing,
            // The old, Employee Evaluation page
            Off: AdminEvaluationListingPage
          })}
        />
        <Route path="all">
          <IndexRoute component={AdminCreateCalibrationViewPage} />

          <Route
            path=":cycleId(/:cycleName/)"
            component={AdminEvaluationRootPage}
          >
            <Route
              path="review/:reviewId"
              component={AdminEvaluationQuestionsPage}
            />
          </Route>
        </Route>
        <Route path="saved_view/:savedViewId">
          <IndexRoute component={AdminCreateCalibrationViewPage} />

          <Route
            path=":cycleId(/:cycleName/)"
            component={AdminEvaluationRootPage}
          >
            <Route
              path="review/:reviewId"
              component={AdminEvaluationQuestionsPage}
            />
          </Route>
        </Route>
        <Route
          path=":cycleId(/:cycleName/)"
          component={AdminEvaluationRootPage}
        >
          <Route
            path="review/:reviewId"
            component={AdminEvaluationQuestionsPage}
          />
          <IndexRoute component={AdminEvaluationSummaryPage} />
        </Route>
      </Route>

      <Route path="self_reflection">
        <IndexRoute component={AdminSelfReflectionListingPage} />

        <Route path=":cycleId/(:cycleName)">
          <IndexRoute component={AdminSelfReflectionSummaryPage} />
        </Route>
      </Route>

      <Route
        path="completed_self_reflection"
        component={AdminCompletedSelfReflectionResponsePage}
      />

      <Route
        path="incomplete_self_reflection"
        component={AdminIncompleteSelfReflectionSurveyResponsePage}
      />

      <Route path="goals">
        <IndexRedirect to="individual" />
        <Route
          path="individual"
          onEnter={Authenticate({
            predicate: user => Company.isGoalsEnabled(user.company)
          })}
          component={AdminIndividualGoalsPage}
        />
        <Route
          path="stats"
          onEnter={Authenticate({
            predicate: user => Company.isGoalsEnabled(user.company)
          })}
          component={AdminGoalsStatsPage}
        />
      </Route>

      <Route path="team_feedback">
        <IndexRoute component={AdminManagerRequestedFeedbackPage} />
        <Route
          path="managers/:managerId"
          component={AdminIndividualManagerRequestHistoryPage}
        />
        <Route path="history" component={AdminManagerRequestedFeedbackPage} />
      </Route>

      <Route
        path="manager_request_feedback_settings"
        onEnter={Authenticate({ predicate: user => User.isAdmin(user) })}
        component={AdminMRFSettingsPage}
      />

      <Route
        path="request_feedback"
        component={AdminSurveyFeedbackRequestsPage}
      />

      <Route path="feedback/stats" component={AdminFeedbackStatsPage} />
    </Route>
  </Route>,

  <Route
    path="browser_plugin"
    component={App({ showMenu: false, pollNotifications: false })}
    onEnter={cultureAmpAuthAndAuthorization}
  >
    <IndexRoute component={BrowserPluginGiveFeedback} />
    <Route path="redirect" onEnter={chromePlugin} />
    <Route path="variants/:variant" component={BrowserPluginGiveFeedback} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_peer_review"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlyPeerReviewPage} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_self_reflection"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlySelfReflectionPage} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_evaluation"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlyEvaluationPage} />
  </Route>,

  <Route
    onEnter={cultureAmpAuthAndAuthorization}
    path="printer_friendly_shared_evaluation"
    component={App({ showMenu: false })}
  >
    <IndexRoute component={PrinterFriendlySharedEvaluationPage} />
  </Route>,

  <Route path="/" component={App()} onEnter={ensureCultureAmpAuthed}>
    <Route path="emails" component={Emails} />

    <Redirect from="help" to="/" query={{ [HELP_REDIRECT_QUERY_KEY]: true }} />

    <Route onEnter={Authenticate()}>
      <IndexRoute component={Notifications} />
      {GoalsAppRoutes}
      {TeamsAppRoutes}
      <Route path="notifications" component={Notifications} />

      <Route path="profile" component={Profile} />
      <Route path="review" component={Review} />

      <Route
        path="manager_requested_feedback"
        component={IncompleteMRFSurveyPage}
      />
      
      <Route
        path="team_self_reflection"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
      >
        <IndexRoute component={TeamSelfReflectionListingPage} />
        <Route
          path=":cycleId/(:cycleName)"
          component={TeamSelfReflectionSummaryPage}
        />
      </Route>

      <Route
        path="evaluation"
        onEnter={Authenticate({
          predicate: user => user.company.allow_performance_cycles
        })}
      >
        <Route path="saved_views">
          <IndexRoute component={SharedCalibrationViewsPage} />

          <Route path=":savedViewId">
            <IndexRoute component={SharedCalibrationViewPage} />

            <Route path=":cycleId(/:cycleName/)" component={EvaluationRootPage}>
              <Route
                path="review/:reviewId"
                component={EvaluationQuestionsPage}
              />
            </Route>
          </Route>
        </Route>
        <IndexRoute component={EvaluationListingPage} />
        <Route path=":cycleId(/:cycleName/)" component={EvaluationRootPage}>
          <Route path="redirect" component={EvaluationRedirectPage} />
          <Route
            path="review(/:reviewId)"
            component={EvaluationQuestionsPage}
          />
          <IndexRoute component={EvaluationSummaryPage} />
        </Route>
      </Route>

      <Route path="search" component={Search} />
      <Route path="myfeedback" component={FeedbackList} />
      <Route
        path="self_reflections_overview"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
        component={MySelfReflectionSurveyResponsesPage}
      />
      <Route
        path="shared_evaluations_overview"
        onEnter={Authenticate({
          predicate: user => user.company.allow_performance_cycles
        })}
      >
        <IndexRoute component={SharedPerformanceReviewOverview} />
        <Route path="review/:reviewId">
          <IndexRoute component={PerformanceReviewCommentsPage} />
        </Route>
      </Route>
      <Route
        path="leaderboard"
        onEnter={Authenticate({
          predicate: user => user.company.allow_reviews
        })}
        component={Leaderboard}
      />
      <Route path="requests" component={FeedbackRequestsPage} />
      <Route path="development" component={Development} />
      <Route
        path="self_review"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
        component={IncompleteSelfReflectionSurveyPage}
      />
      <Route
        path="completed_self_reflection"
        onEnter={Authenticate({
          predicate: user => user.company.allow_self_reflections
        })}
        component={CompletedSelfReflectionSurveyPage}
      />
      <Route path="quick_comment" component={QuickComment} />
      <Route
        path="public_praise"
        onEnter={Authenticate({
          predicate: user => Company.publicPraiseEnable(user.company)
        })}
        component={PublicPraise}
      />

      <Route
        path="goals"
        onEnter={Authenticate({
          predicate: user => Company.isGoalsEnabled(user.company)
        })}
      >
        <Route path="team" component={TeamGoalsPage} />
        <IndexRedirect to="team" />
      </Route>

      <Route path="summary(/:section)" component={MySummary} />

      <Route
        path="team_search"
        component={TeamMembers}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      <Route
        path="team_summary(/:section)"
        component={TeamSummary}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      <Route path="team_usage" component={TeamUsage} />
      <Route
        path="team_feedback"
        component={PeerFeedback}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      <Route
        path="team_feedback_request"
        component={PeerFeedbackRequest}
        onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })}
      />
      <Route
        path={R.ecPaufRequestFeedback}
        component={featureToggle({
          flag: FeatureFlags.mrfCycles,
          On: EcPaufRequestFeedbackPage,
          offRedirect: R.dashboard,
        })}
      />
      {/* New Team Based Feedback (TBF) routes */}
      <Route
        path="team_feedback/:teamMemberId/:teamId"
        component={TeamFeedbackRequest}
        /*onEnter={Authenticate({
          predicate: user => user.is_a_manager
        })} - TODO - add team lead authentication*/
      />
      <Route
        path={R.oneOnOne}
        component={featureToggle({
          flag: FeatureFlags.oneOnOneConversation,
          On: OneOnOneHomepage,
          offRedirect: R.home,
        })}
      />
      <Route
        path={R.oneOnOneSpace}
        component={OneOnOneSpacePage}
      />
      {SurveysResponsesRoutes}
    </Route>
    <Route
      path="team_based_feedback/:teamId"
      component={TeamFeedbackRequest}
      onEnter={checkConfigurationOptions(
        ConfigurationOptions.teamBasedFeedback
      )}
      /* TODO - add team lead authentication*/
    />

    <Route
      onEnter={Authenticate({
        predicate: user => user.company.allow_performance_cycles
      })}
      path="collaborator_evaluation/:reviewId"
      component={CollaboratorEvaluationPage}
    />
  </Route>,

  <Route path="*" component={ErrorPage404} />
]

export default routes
