import { useCallback, useEffect, useRef, useState } from "react"
import { useAsyncCallback, useAsyncEffect } from "../../../hooks/async/async"
import * as evaluationCyclesApi from "../../../api/evaluationCycles"
import { EvaluationCycle } from "../../../types/EvaluationCycle"
import { LocalBucket } from "../../../types/PerformanceCycle"

export const useCycleForAdmin = (id: number | string | undefined | null) => {
  const [cycle, setCycle] = useState<EvaluationCycle | null>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { isLoading, error } = useAsyncEffect(async () => {
    if (!id) throw new Error("Cycle not found") // This could only ever happen if the user manually cleared the modal_id query param from the url

    const { evaluation_cycle } = await evaluationCyclesApi.fetchCycleForAdmin(
      id
    )

    setCycle(evaluation_cycle)
  }, [id])

  return { isFetchingCycle: isLoading, fetchCycleError: error, cycle, setCycle }
}

export const useCycleForManager = (id: number | string | undefined | null) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { isLoading, error, data } = useAsyncEffect(async () => {
    if (!id) throw new Error("Cycle not found") // This could only ever happen if the user manually cleared the modal_id query param from the url

    const { evaluation_cycle } = await evaluationCyclesApi.fetchCycleForManager(
      id
    )

    return evaluation_cycle
  }, [id])

  return { isFetchingCycle: isLoading, fetchCycleError: error, cycle: data }
}

const noBuckets: LocalBucket[] = []

/**
 * The "default buckets" are the default options that appear when the admin
 * adds an assessment group question under the questions step, under the
 * manager evaluations accordion item.
 *
 * This hook is responsible for pre-fetching the responses on load. There's also
 * a fetchDefaultBucketsIfNeeded function that gets returned. When called, if
 * the pre-fetch failed (or is still fetching), we try again. If that fails,
 * then we swallow the error, and just return an empty array instead.
 */
export const useDefaultBuckets = (
  cycle: EvaluationCycle | null
): {
  defaultBuckets: LocalBucket[]
  fetchDefaultBucketsIfNeeded: () => Promise<LocalBucket[] | null | undefined>
} => {
  const isCycleReady = Boolean(cycle && cycle.id)
  const isManagerEvalIncluded = cycle?.modules.manager_evaluation.is_included
  const fetchedBuckets = useRef<LocalBucket[]>(null)
  const cycleId: number | undefined = cycle?.id

  const { fire, data } = useAsyncCallback(
    async (cycleId: number | undefined): Promise<LocalBucket[] | null> => {
      // If we've already fetched the data, then no need to do it again
      if (fetchedBuckets.current) return fetchedBuckets.current
      // No need to fetch the default buckets if the manager evaluation
      // module is not selected.
      if (!isManagerEvalIncluded) return null

      if (!cycleId) return null

      const {
        default_performance_buckets,
      } = await evaluationCyclesApi.fetchDefaultPerformanceBuckets(cycleId)

      // @ts-ignore: I think the type definition is incorrect here.
      fetchedBuckets.current = default_performance_buckets

      return default_performance_buckets
    },
    [isManagerEvalIncluded]
  )

  useEffect(() => {
    if (!isCycleReady) return
    fire(cycleId)
  }, [cycleId, fire, isCycleReady])

  const fetchDefaultBucketsIfNeeded = useCallback(async () => {
    try {
      return await fire(cycleId)
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error("There was a problem fetching the default buckets", ex)
    }
  }, [cycleId, fire])

  // If for whatever reason this request fails, it's not a show stopper,
  // so we just return an empty array instead.
  return {
    defaultBuckets: data || noBuckets,
    fetchDefaultBucketsIfNeeded,
  }
}
