import Reflux from "../../refluxActions/ActionsInitializer"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "../../refluxActions/lib/apiActionHelpers"
import SearchAgent from "../../refluxActions/lib/SearchAgent"
import Survey from "../../models/Survey"
import SurveyTemplate from "../../models/SurveyTemplate"
import endpoints from "../../constants/endpointsDeprecated"
import strings from "../../locale/strings"
import { showCsvConfirmation } from "../../refluxActions/lib/csvExportation"
import { adaptSurveyResponseSearchParams } from "../../refluxActions/lib/surveyHelpers"

const {
  ADMIN_SURVEYS_URLS: {
    ADMIN_SURVEYS_URL,
    ADMIN_SURVEY_TEMPLATES_URL,
    ADMIN_SURVEY_AUDIENCES_URL,
    ADMIN_SURVEY_EXPORT_REQUESTS_URL,
  },
} = endpoints
const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: ADMIN_SURVEYS_URL,
  resourceName: "surveys",
  pageSize: 25,
  modelWrapper: Survey.of,
})

const AdminSurveyActions = Reflux.createActions({
  searchSurveys: { asyncResult: true },
  pageSurveys: { asyncResult: true },
  loadSurvey: { asyncResult: true },
  clearSurveys: {},
  getStarterTemplate: { asyncResult: true },
  createSurvey: { asyncResult: true },
  updateSurvey: { asyncResult: true },
  deleteSurvey: { asyncResult: true },
  createSurveyTemplate: { asyncResult: true },
  updateSurveyTemplate: { asyncResult: true },
  getSurveyAudienceUsers: { asyncResult: true },
  createAudience: { asyncResult: true },
  updateAudience: { asyncResult: true },
  exportSurvey: { asyncResult: true },
})

AdminSurveyActions.searchSurveys.listenAndPromise(({ kind, include_drafts }) =>
  addLoadingState(
    [true, { light: true }],
    searchAgent.search({ searchParams: { kind, include_drafts } })
  )
)

AdminSurveyActions.pageSurveys.listenAndPromise(
  ({ page, kind, include_drafts }) =>
    searchAgent.search({ page, searchParams: { kind, include_drafts } })
)

AdminSurveyActions.loadSurvey.listenAndPromise(({ id, loadingState = true }) =>
  addLoadingState(
    [loadingState, { light: true }],
    extractResponseKey(
      "survey",
      Survey.of,
      agent.get(`${ADMIN_SURVEYS_URL}/${id}`)
    )
  )
)

AdminSurveyActions.getStarterTemplate.listenAndPromise(
  ({ kind, surveyToDuplicateId }) =>
    addLoadingState(
      [true, { light: true }],
      extractResponseKey(
        "survey",
        agent
          .get(`${ADMIN_SURVEYS_URL}/new`)
          .query({ kind, survey_to_duplicate_id: surveyToDuplicateId })
      ).then((survey) =>
        survey.templates.length ? SurveyTemplate.of(survey.templates[0]) : null
      )
    )
)

AdminSurveyActions.createSurvey.listenAndPromise(
  ({ survey, questions, userIds, kind, successMessage, hideSuccessToast }) => {
    const template = questions?.size
      ? [
          SurveyTemplate.transformToBackendTemplate({
            name: getDefaultTemplateName(survey),
            questions,
          }),
        ]
      : []
    const body = {
      survey: {
        ...survey,
        kind,
        templates_attributes: template,
        user_queries: userIds
          ? [{ user_ids: Array.from(new Set(userIds)) }]
          : [],
      },
    }

    return addLoadingState(
      true,
      addToasts(
        {
          success: successMessage,
          defaultError: strings.adminSelfReflection.errors.cycle,
        },
        extractResponseKey(
          "survey",
          Survey.of,
          agent.post(ADMIN_SURVEYS_URL).send(body)
        ),
        hideSuccessToast
      )
    )
  }
)

AdminSurveyActions.updateSurvey.listenAndPromise(
  ({
    survey,
    questions,
    kind,
    successMessage,
    errorMessage,
    hideSuccessToast,
  }) => {
    const template = questions?.size
      ? [
          SurveyTemplate.transformToBackendTemplate({
            name: getDefaultTemplateName(survey),
            questions,
          }),
        ]
      : []
    const body = {
      survey: {
        ...survey,
        kind,
        templates_attributes: template,
      },
    }
    return addLoadingState(
      true,
      addToasts(
        { success: successMessage, defaultError: errorMessage },
        extractResponseKey(
          "survey",
          Survey.of,
          agent.put(`${ADMIN_SURVEYS_URL}/${survey.id}`).send(body)
        ),
        hideSuccessToast
      )
    )
  }
)

AdminSurveyActions.deleteSurvey.listenAndPromise(
  ({ surveyId, successMessage, errorMessage }) =>
    addToasts(
      { defaultError: errorMessage, success: successMessage },
      extractResponseKey(
        "survey",
        Survey.of,
        agent.del(`${ADMIN_SURVEYS_URL}/${surveyId}`)
      )
    )
)

AdminSurveyActions.createSurveyTemplate.listenAndPromise(
  ({ survey, questions, hideSuccessToast }) => {
    const template = SurveyTemplate.transformToBackendTemplate({
      survey_id: survey.id,
      name: getDefaultTemplateName(survey),
      questions,
    })

    return addLoadingState(
      true,
      addToasts(
        {
          success: strings.cycle.updateSaveMessage,
          defaultError: strings.adminSelfReflection.errors.questions,
        },

        agent
          .post(ADMIN_SURVEY_TEMPLATES_URL)
          .send({ template })
          .then(({ body: { template } }) => {
            return {
              template: new SurveyTemplate(template),
              surveyId: survey.id,
            }
          }),
        hideSuccessToast
      )
    )
  }
)

AdminSurveyActions.updateSurveyTemplate.listenAndPromise(
  ({ surveyId, templateId, questions, hideSuccessToast }) =>
    addLoadingState(
      true,
      addToasts(
        {
          success: strings.cycle.updateSaveMessage,
          defaultError: strings.adminSelfReflection.errors.questions,
        },
        agent
          .put(`${ADMIN_SURVEY_TEMPLATES_URL}/${templateId}`)
          .send(SurveyTemplate.transformToBackendTemplate({ questions }))
          .then(({ body: { template } }) => {
            return {
              template: new SurveyTemplate(template),
              surveyId: surveyId,
            }
          }),
        hideSuccessToast
      )
    )
)

AdminSurveyActions.getSurveyAudienceUsers.listenAndPromise((surveyId) =>
  extractResponseKey(
    "survey",
    Survey.of,
    agent.get(`${ADMIN_SURVEYS_URL}/${surveyId}`)
  )
    // Extract the survey_audience from the Survey and look for the user ids
    .then((survey) => ({
      survey,
      userIds: survey.survey_audiences.length
        ? survey.survey_audiences[0].user_ids
        : [],
    }))
)

AdminSurveyActions.createAudience.listenAndPromise(
  ({
    surveyId,
    userIds,
    kind,
    templateId,
    successMessage,
    errorMessage,
    hideSuccessToast,
  }) => {
    return addLoadingState(
      true,
      addToasts(
        { success: successMessage, defaultError: errorMessage },
        extractResponseKey(
          "survey_audience",
          agent.post(ADMIN_SURVEY_AUDIENCES_URL).send({
            survey_audience: {
              user_query: { user_ids: Array.from(new Set(userIds)) },
              survey_id: surveyId,
              kind,
              template_ids: templateId,
            },
          })
        ),
        hideSuccessToast
      )
    )
  }
)

AdminSurveyActions.updateAudience.listenAndPromise(
  ({
    userIds,
    surveyAudienceId,
    successMessage,
    errorMessage,
    hideSuccessToast,
  }) =>
    addLoadingState(
      true,
      addToasts(
        { success: successMessage, defaultError: errorMessage },
        extractResponseKey(
          "survey_audience",
          agent.put(`${ADMIN_SURVEY_AUDIENCES_URL}/${surveyAudienceId}`).send({
            survey_audience: {
              user_query: { user_ids: Array.from(new Set(userIds)) },
            },
          })
        ),
        hideSuccessToast
      )
    )
)

/**
 * @param {Survey} survey
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds, userIds, bucketId}=} filters
 */
AdminSurveyActions.exportSurvey.listenAndPromise(({ survey, filters }) =>
  addToasts(
    { defaultError: strings.cycle.exportFailed },
    extractResponseKey(
      "survey_export_request",
      agent.post(ADMIN_SURVEY_EXPORT_REQUESTS_URL).send({
        survey_export_request: {
          survey_id: survey.id,
          options: adaptSurveyResponseSearchParams(filters, {
            stringifyArrays: false,
          }),
        },
      })
    ).then(showCsvConfirmation)
  )
)

function getDefaultTemplateName(survey) {
  return `Template for ${survey.name}` // SF requires the template to be named
}

export default AdminSurveyActions
