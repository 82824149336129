import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import strings from "../../locale/strings"
import Loading from "../../components/Loading/Loading"
import useGoalDetails from "../../domainHooks/goals/useGoalDetails"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import PersonalGoalForm, {
  PersonalGoalFields,
} from "../PersonalGoalForm/PersonalGoalForm"
import useUpdatePersonalGoal from "../../domainHooks/goals/useUpdatePersonalGoal"
import { PersonalGoal, GoalIndividual } from "../../types/Goals"

type EditPersonalGoal = React.FunctionComponent<{
  router: InjectedRouter
  intl: InjectedIntl
  params: {
    goalId: string
  }
}>

const EditPersonalGoal: EditPersonalGoal = ({ router, params, intl }) => {
  const { goalId } = params
  const { showNotification } = useNotifications()
  const { formatMessage } = intl
  const { goal } = useGoalDetails(Number(goalId))
  const { updatePersonalGoal, loading, data, error } = useUpdatePersonalGoal(
    goalId
  )
  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.editGoalNotifications.successTitle),
        message: formatMessage(
          strings.editGoalNotifications.successNotification
        ),
      })
      router.push({
        pathname: `/new_goals/personal`,
        query: {
          goalId,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.editGoalNotifications.errorTitle),
        message: formatMessage(strings.editGoalNotifications.errorNotification),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  if (!goal) return <Loading />
  let priority
  switch (goal.priority) {
    case "low":
      priority = 0
      break
    case "medium":
      priority = 1
      break
    case "high":
      priority = 2
      break
  }
  const goalFields: PersonalGoalFields = {
    name: goal.name,
    description: goal.description ? goal.description : "",
    dueDate: goal.dueDate,
    priority: priority as 0 | 1 | 2,
    visibility: goal.visibility as "owner",
    keyResults: goal.keyResults,
    alignedGoalIds: goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.map((goal) => goal.id)
      : [],
    viewers: (goal as PersonalGoal).individuals as GoalIndividual[],
  }
  return (
    <PersonalGoalForm
      title={formatMessage(strings.editIndividualGoal.title)}
      submitLabel={formatMessage(strings.editIndividualGoal.submitLabel)}
      defaultFields={goalFields}
      onSubmit={(fields) => updatePersonalGoal(fields, goal)}
      onCancel={() => router.goBack()}
      disabled={loading}
      goalId={goalId}
    />
  )
}

export default injectIntl(EditPersonalGoal)
