import PropTypes from "prop-types"
import React from "react"
import KaizenButton from "@kaizen/component-library/components/Button/Button"
import { ButtonProps } from "@kaizen/component-library/components/Button/components/GenericButton"
import "./Button.less"

export type ButtonActionType = "primary" | "secondary" | "plain" | "link"

export type Props = Partial<ButtonProps> & {
  actionType: ButtonActionType
  to?: object | string
  href?: string
  openLinkInNewWindow?: boolean
  children?: string
  onClick?:
    | ((e: React.MouseEvent) => void)
    // Passing a promise, will automatically disable the button until the
    // promise is resolved.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((e: React.MouseEvent) => Promise<any>)
  disabled?: boolean
}

type State = {
  disabledOverride: boolean
}

export default class Button extends React.Component<Props, State> {
  _unmounted = false

  static contextTypes = {
    router: PropTypes.object,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      disabledOverride: false,
    }
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  handleClick = (e: React.MouseEvent) => {
    const { onClick, to, disabled } = this.props
    const { router } = this.context

    if (!onClick && to) {
      router.push(to)
      return
    }

    if (!onClick || disabled || this.state.disabledOverride) {
      return
    }

    const clickResult = onClick(e)

    // If the onClick callback returns a promise, disable the button until
    // the promise resolves.
    if (clickResult && clickResult.then) {
      this.setState(
        () => ({ disabledOverride: true }),
        async () => {
          await clickResult
          if (!this._unmounted) {
            this.setState({ disabledOverride: false })
          }
        }
      )
    } else {
      return clickResult
    }
  }

  render() {
    const {
      actionType,
      children,
      href,
      openLinkInNewWindow,
      onClick,
      disabled,
      to,
      ...extraProps
    } = this.props
    const { disabledOverride } = this.state

    const classes = [`Button--${actionType}`]

    switch (actionType) {
      case "primary":
        classes.push("btn-large", "btn-light")
        break

      case "secondary":
        classes.push("btn-flat", "btn-bordered")
        break

      case "link":
        classes.push("btn-flat", "btn-light")
        break

      case "plain":
        classes.push("btn-flat")
        break
    }

    const hrefProps = {
      href,
      ...(openLinkInNewWindow && {
        target: "_blank",
        rel: "noopener noreferrer",
      }),
    }

    return (
      <KaizenButton
        {...hrefProps}
        {...extraProps}
        disabled={disabled || disabledOverride}
        // I'm not sure why this `href` condition is here. There's nothing
        // that should prevent you from having an onclick event, even on
        // anchor links. The onClick condition IS required however! For some
        // reason if the button type is `submit`, the form will not submit
        // if `this.handleClick` is passed in. If you wish to change this line,
        // please double check the Admin => Employee Evaluations => Create Cycle
        // screen, and make sure you can navigate to the next step.
        //
        // @ts-ignore: The kaizen button type is (e: MouseEvent) => void, but
        // we might return a promise, in which case the typing breaks here.
        onClick={!to && (href || !onClick) ? undefined : this.handleClick}
        primary={actionType === "primary"}
        secondary={actionType === "secondary"}
        label={children || ""}
      />
    )
  }
}
