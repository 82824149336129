import React from "react"
import cx from "classnames"
import styles from "./UserSelectorDrawer.scss"
import Avatar from "../../components/Avatar/Avatar"
import Aid from "../../constants/automationId"

type User = {
  id: string
  name: string
  avatar: string
  message?: React.ReactNode | string
}

type UserSelectorSection = {
  user: User
  active?: boolean
  onSelect?: (key: string) => void
}

type UserSelectorDrawerProps = {
  users: User[]
  activeUserId: string | undefined
  onSelectUser?: (key: string) => void
}

const UserSelectorSection: React.FunctionComponent<UserSelectorSection> = ({
  user,
  onSelect,
  active,
}) => {
  return (
    <button
      onClick={() => onSelect && onSelect(user.id)}
      className={cx(styles.section, {
        [styles.activeSection]: active,
      })}
    >
      <div className={styles.sectionPeek}>
        <Avatar imageURL={user.avatar} />
      </div>
      <div className={styles.sectionOverflow}>
        <span
          data-automation-id={`${Aid.userSelectorName}-${user.id}`}
          className={styles.truncatedName}
        >
          {user.name}
        </span>
        {user.message && <span className={styles.message}>{user.message}</span>}
      </div>
    </button>
  )
}

export const UserSelectorDrawer: React.FunctionComponent<UserSelectorDrawerProps> = ({
  users,
  activeUserId,
  onSelectUser,
}) => {
  return (
    <div>
      {users.map((user) => (
        <UserSelectorSection
          key={user.id}
          user={user}
          active={activeUserId === user.id}
          onSelect={onSelectUser}
        />
      ))}
    </div>
  )
}
