import "./ResponsiveWrapper.less"
import React from "react"

type Props = {
  children: React.ReactNode
  className?: string
  containerClass?: string
}

export default function ResponsiveWrapper({
  children,
  className = "",
  containerClass = "",
  ...rest
}: Props) {
  return (
    <div
      {...rest}
      className={`ResponsiveWrapper layout vertical flex ${className}`}
    >
      <div
        className={`ResponsiveWrapper--container layout vertical flex ${containerClass}`}
      >
        {children}
      </div>
    </div>
  )
}
