import { Text } from "@kaizen/component-library"
import { ConfirmationModal } from "@kaizen/draft-modal"
import * as React from "react"
import styles from "./FeedbackRequestModal.scss"

type FeedbackRequestModal = React.FunctionComponent<{
  onConfirm: () => void
  onDismiss: () => void
  type: "positive" | "cautionary"
  title: string
  confirmLabel: string
  dismissLabel: string
  body: string
}>

/**
 * This file is an adapter that locks down the styles and child components
 * used for the Feedback Requests modal for MRF, Admin MRF and TRF. Expects
 * to be handed the translated strings.
 *
 * @see TeamFeedbackRequest
 * @see SurveyFeedbackRequests
 * @see AdminSurveyFeedbackRequests
 *
 * @param {props} {
 *   onConfirm,
 *   onDismiss,
 *   type,
 *   title,
 *   confirmLabel,
 *   dismissLabel,
 *   body
 * }
 * @returns {FeedbackRequestModal} Adapted Kaizen component
 */
const FeedbackRequestModal: FeedbackRequestModal = ({
  onConfirm,
  onDismiss,
  type,
  title,
  confirmLabel,
  dismissLabel,
  body,
}) => {
  return (
    <ConfirmationModal
      isOpen
      type={type}
      title={title}
      confirmLabel={confirmLabel}
      dismissLabel={dismissLabel}
      onDismiss={onDismiss}
      onConfirm={onConfirm}
    >
      <div
        data-automation-id="team-feedback-confirmation-modal"
        className={styles.modalContent}
      >
        <Text tag="p" style="lede" inline>
          {body}
        </Text>
      </div>
    </ConfirmationModal>
  )
}

export default FeedbackRequestModal
