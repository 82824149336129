import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import cx from "classnames"
import { TableHeaderRowCell } from "@kaizen/draft-table"
import styles from "./GoalsTableHeader.scss"
import strings from "../../locale/strings"

type GoalType = "personal" | "department" | "team" | "company"

type GoalsTableHeaderProps = React.FunctionComponent<{
  intl: InjectedIntl
  goalType: GoalType
}>

const GoalsTableHeader: GoalsTableHeaderProps = ({ goalType, intl }) => {
  const mediumWidth = 3 / 12
  const largeWidth = 7 / 12
  const smallWidth = 2 / 12
  return (
    <div className={cx(styles.header, styles.hideMobile)}>
      <TableHeaderRowCell
        labelText={intl.formatMessage(
          strings.goalsPage.goalsTableHeader[goalType]
        )}
        width={mediumWidth}
      />
      <TableHeaderRowCell
        labelText={intl.formatMessage(
          strings.goalsPage.teamGoals.overallProgress
        )}
        width={largeWidth}
      />
      <TableHeaderRowCell
        labelText={intl.formatMessage(
          strings.goalsPage.teamGoals.numberOfGoals
        )}
        width={smallWidth}
      />
    </div>
  )
}

export default injectIntl(GoalsTableHeader)
