function toast(t, e, i, n) {
  function a(t) {
    var e = $("<div class='toast'></div>")
      .addClass(i)
      .text(t)
    return (
      e
        .hammer({ prevent_default: !1 })
        .bind("pan", function(t) {
          var i = t.gesture.deltaX,
            n = 80
          e.hasClass("panning") || e.addClass("panning")
          var a = 1 - Math.abs(i / n)
          0 > a && (a = 0),
            e.velocity(
              { left: i, opacity: a },
              { duration: 50, queue: !1, easing: "easeOutQuad" }
            )
        })
        .bind("panend", function(t) {
          var i = t.gesture.deltaX,
            a = 80
          Math.abs(i) > a
            ? e.velocity(
                { marginTop: "-40px" },
                {
                  duration: 375,
                  easing: "easeOutExpo",
                  queue: !1,
                  complete: function() {
                    "function" == typeof n && n(), e.remove()
                  }
                }
              )
            : (e.removeClass("panning"),
              e.velocity(
                { left: 0, opacity: 1 },
                { duration: 300, easing: "easeOutExpo", queue: !1 }
              ))
        }),
      e
    )
  }
  if (((i = i || ""), 0 == $("#toast-container").length)) {
    var r = $("<div></div>").attr("id", "toast-container")
    $("body").append(r)
  }
  var r = $("#toast-container"),
    o = a(t)
  r.append(o),
    o.css({ top: parseFloat(o.css("top")) + 35 + "px", opacity: 0 }),
    o.velocity(
      { top: "0px", opacity: 1 },
      { duration: 300, easing: "easeOutCubic", queue: !1 }
    )
  var s = e,
    l = setInterval(function() {
      0 === o.parent().length && window.clearInterval(l),
        o.hasClass("panning") || (s -= 100),
        0 >= s &&
          (o.velocity(
            { opacity: 0, marginTop: "-40px" },
            {
              duration: 375,
              easing: "easeOutExpo",
              queue: !1,
              complete: function() {
                "function" == typeof n && n(), $(this).remove()
              }
            }
          ),
          window.clearInterval(l))
    }, 100)
}
;(jQuery.easing.jswing = jQuery.easing.swing),
  jQuery.extend(jQuery.easing, {
    def: "easeOutQuad",
    swing: function(t, e, i, n, a) {
      return jQuery.easing[jQuery.easing.def](t, e, i, n, a)
    },
    easeInQuad: function(t, e, i, n, a) {
      return n * (e /= a) * e + i
    },
    easeOutQuad: function(t, e, i, n, a) {
      return -n * (e /= a) * (e - 2) + i
    },
    easeInOutQuad: function(t, e, i, n, a) {
      return (e /= a / 2) < 1
        ? (n / 2) * e * e + i
        : (-n / 2) * (--e * (e - 2) - 1) + i
    },
    easeInCubic: function(t, e, i, n, a) {
      return n * (e /= a) * e * e + i
    },
    easeOutCubic: function(t, e, i, n, a) {
      return n * ((e = e / a - 1) * e * e + 1) + i
    },
    easeInOutCubic: function(t, e, i, n, a) {
      return (e /= a / 2) < 1
        ? (n / 2) * e * e * e + i
        : (n / 2) * ((e -= 2) * e * e + 2) + i
    },
    easeInQuart: function(t, e, i, n, a) {
      return n * (e /= a) * e * e * e + i
    },
    easeOutQuart: function(t, e, i, n, a) {
      return -n * ((e = e / a - 1) * e * e * e - 1) + i
    },
    easeInOutQuart: function(t, e, i, n, a) {
      return (e /= a / 2) < 1
        ? (n / 2) * e * e * e * e + i
        : (-n / 2) * ((e -= 2) * e * e * e - 2) + i
    },
    easeInQuint: function(t, e, i, n, a) {
      return n * (e /= a) * e * e * e * e + i
    },
    easeOutQuint: function(t, e, i, n, a) {
      return n * ((e = e / a - 1) * e * e * e * e + 1) + i
    },
    easeInOutQuint: function(t, e, i, n, a) {
      return (e /= a / 2) < 1
        ? (n / 2) * e * e * e * e * e + i
        : (n / 2) * ((e -= 2) * e * e * e * e + 2) + i
    },
    easeInSine: function(t, e, i, n, a) {
      return -n * Math.cos((e / a) * (Math.PI / 2)) + n + i
    },
    easeOutSine: function(t, e, i, n, a) {
      return n * Math.sin((e / a) * (Math.PI / 2)) + i
    },
    easeInOutSine: function(t, e, i, n, a) {
      return (-n / 2) * (Math.cos((Math.PI * e) / a) - 1) + i
    },
    easeInExpo: function(t, e, i, n, a) {
      return 0 == e ? i : n * Math.pow(2, 10 * (e / a - 1)) + i
    },
    easeOutExpo: function(t, e, i, n, a) {
      return e == a ? i + n : n * (-Math.pow(2, (-10 * e) / a) + 1) + i
    },
    easeInOutExpo: function(t, e, i, n, a) {
      return 0 == e
        ? i
        : e == a
        ? i + n
        : (e /= a / 2) < 1
        ? (n / 2) * Math.pow(2, 10 * (e - 1)) + i
        : (n / 2) * (-Math.pow(2, -10 * --e) + 2) + i
    },
    easeInCirc: function(t, e, i, n, a) {
      return -n * (Math.sqrt(1 - (e /= a) * e) - 1) + i
    },
    easeOutCirc: function(t, e, i, n, a) {
      return n * Math.sqrt(1 - (e = e / a - 1) * e) + i
    },
    easeInOutCirc: function(t, e, i, n, a) {
      return (e /= a / 2) < 1
        ? (-n / 2) * (Math.sqrt(1 - e * e) - 1) + i
        : (n / 2) * (Math.sqrt(1 - (e -= 2) * e) + 1) + i
    },
    easeInElastic: function(t, e, i, n, a) {
      var r = 1.70158,
        o = 0,
        s = n
      if (0 == e) return i
      if (1 == (e /= a)) return i + n
      if ((o || (o = 0.3 * a), s < Math.abs(n))) {
        s = n
        var r = o / 4
      } else var r = (o / (2 * Math.PI)) * Math.asin(n / s)
      return (
        -(
          s *
          Math.pow(2, 10 * (e -= 1)) *
          Math.sin((2 * (e * a - r) * Math.PI) / o)
        ) + i
      )
    },
    easeOutElastic: function(t, e, i, n, a) {
      var r = 1.70158,
        o = 0,
        s = n
      if (0 == e) return i
      if (1 == (e /= a)) return i + n
      if ((o || (o = 0.3 * a), s < Math.abs(n))) {
        s = n
        var r = o / 4
      } else var r = (o / (2 * Math.PI)) * Math.asin(n / s)
      return (
        s * Math.pow(2, -10 * e) * Math.sin((2 * (e * a - r) * Math.PI) / o) +
        n +
        i
      )
    },
    easeInOutElastic: function(t, e, i, n, a) {
      var r = 1.70158,
        o = 0,
        s = n
      if (0 == e) return i
      if (2 == (e /= a / 2)) return i + n
      if ((o || (o = 0.3 * a * 1.5), s < Math.abs(n))) {
        s = n
        var r = o / 4
      } else var r = (o / (2 * Math.PI)) * Math.asin(n / s)
      return 1 > e
        ? -0.5 *
            s *
            Math.pow(2, 10 * (e -= 1)) *
            Math.sin((2 * (e * a - r) * Math.PI) / o) +
            i
        : s *
            Math.pow(2, -10 * (e -= 1)) *
            Math.sin((2 * (e * a - r) * Math.PI) / o) *
            0.5 +
            n +
            i
    },
    easeInBack: function(t, e, i, n, a, r) {
      return (
        void 0 == r && (r = 1.70158), n * (e /= a) * e * ((r + 1) * e - r) + i
      )
    },
    easeOutBack: function(t, e, i, n, a, r) {
      return (
        void 0 == r && (r = 1.70158),
        n * ((e = e / a - 1) * e * ((r + 1) * e + r) + 1) + i
      )
    },
    easeInOutBack: function(t, e, i, n, a, r) {
      return (
        void 0 == r && (r = 1.70158),
        (e /= a / 2) < 1
          ? (n / 2) * e * e * (((r *= 1.525) + 1) * e - r) + i
          : (n / 2) * ((e -= 2) * e * (((r *= 1.525) + 1) * e + r) + 2) + i
      )
    },
    easeInBounce: function(t, e, i, n, a) {
      return n - jQuery.easing.easeOutBounce(t, a - e, 0, n, a) + i
    },
    easeOutBounce: function(t, e, i, n, a) {
      return (e /= a) < 1 / 2.75
        ? 7.5625 * n * e * e + i
        : 2 / 2.75 > e
        ? n * (7.5625 * (e -= 1.5 / 2.75) * e + 0.75) + i
        : 2.5 / 2.75 > e
        ? n * (7.5625 * (e -= 2.25 / 2.75) * e + 0.9375) + i
        : n * (7.5625 * (e -= 2.625 / 2.75) * e + 0.984375) + i
    },
    easeInOutBounce: function(t, e, i, n, a) {
      return a / 2 > e
        ? 0.5 * jQuery.easing.easeInBounce(t, 2 * e, 0, n, a) + i
        : 0.5 * jQuery.easing.easeOutBounce(t, 2 * e - a, 0, n, a) + 0.5 * n + i
    }
  }),
  jQuery.extend(jQuery.easing, {
    easeInOutMaterial: function(t, e, i, n, a) {
      return (e /= a / 2) < 1
        ? (n / 2) * e * e + i
        : (n / 4) * ((e -= 2) * e * e + 2) + i
    }
  }),
  !(function(t) {
    function e(t) {
      var e = t.length,
        i = $.type(t)
      return "function" === i || $.isWindow(t)
        ? !1
        : 1 === t.nodeType && e
        ? !0
        : "array" === i ||
          0 === e ||
          ("number" == typeof e && e > 0 && e - 1 in t)
    }
    if (!t.jQuery) {
      var $ = function(t, e) {
        return new $.fn.init(t, e)
      }
      ;($.isWindow = function(t) {
        return null != t && t == t.window
      }),
        ($.type = function(t) {
          return null == t
            ? t + ""
            : "object" == typeof t || "function" == typeof t
            ? n[r.call(t)] || "object"
            : typeof t
        }),
        ($.isArray =
          Array.isArray ||
          function(t) {
            return "array" === $.type(t)
          }),
        ($.isPlainObject = function(t) {
          var e
          if (!t || "object" !== $.type(t) || t.nodeType || $.isWindow(t))
            return !1
          try {
            if (
              t.constructor &&
              !a.call(t, "constructor") &&
              !a.call(t.constructor.prototype, "isPrototypeOf")
            )
              return !1
          } catch (i) {
            return !1
          }
          for (e in t);
          return void 0 === e || a.call(t, e)
        }),
        ($.each = function(t, i, n) {
          var a,
            r = 0,
            o = t.length,
            s = e(t)
          if (n) {
            if (s) for (; o > r && ((a = i.apply(t[r], n)), a !== !1); r++);
            else for (r in t) if (((a = i.apply(t[r], n)), a === !1)) break
          } else if (s)
            for (; o > r && ((a = i.call(t[r], r, t[r])), a !== !1); r++);
          else for (r in t) if (((a = i.call(t[r], r, t[r])), a === !1)) break
          return t
        }),
        ($.data = function(t, e, n) {
          if (void 0 === n) {
            var a = t[$.expando],
              r = a && i[a]
            if (void 0 === e) return r
            if (r && e in r) return r[e]
          } else if (void 0 !== e) {
            var a = t[$.expando] || (t[$.expando] = ++$.uuid)
            return (i[a] = i[a] || {}), (i[a][e] = n), n
          }
        }),
        ($.removeData = function(t, e) {
          var n = t[$.expando],
            a = n && i[n]
          a &&
            $.each(e, function(t, e) {
              delete a[e]
            })
        }),
        ($.extend = function() {
          var t,
            e,
            i,
            n,
            a,
            r,
            o = arguments[0] || {},
            s = 1,
            l = arguments.length,
            u = !1
          for (
            "boolean" == typeof o && ((u = o), (o = arguments[s] || {}), s++),
              "object" != typeof o && "function" !== $.type(o) && (o = {}),
              s === l && ((o = this), s--);
            l > s;
            s++
          )
            if (null != (a = arguments[s]))
              for (n in a)
                (t = o[n]),
                  (i = a[n]),
                  o !== i &&
                    (u && i && ($.isPlainObject(i) || (e = $.isArray(i)))
                      ? (e
                          ? ((e = !1), (r = t && $.isArray(t) ? t : []))
                          : (r = t && $.isPlainObject(t) ? t : {}),
                        (o[n] = $.extend(u, r, i)))
                      : void 0 !== i && (o[n] = i))
          return o
        }),
        ($.queue = function(t, i, n) {
          function a(t, i) {
            var n = i || []
            return (
              null != t &&
                (e(Object(t))
                  ? !(function(t, e) {
                      for (var i = +e.length, n = 0, a = t.length; i > n; )
                        t[a++] = e[n++]
                      if (i !== i) for (; void 0 !== e[n]; ) t[a++] = e[n++]
                      return (t.length = a), t
                    })(n, "string" == typeof t ? [t] : t)
                  : [].push.call(n, t)),
              n
            )
          }
          if (t) {
            i = (i || "fx") + "queue"
            var r = $.data(t, i)
            return n
              ? (!r || $.isArray(n) ? (r = $.data(t, i, a(n))) : r.push(n), r)
              : r || []
          }
        }),
        ($.dequeue = function(t, e) {
          $.each(t.nodeType ? [t] : t, function(t, i) {
            e = e || "fx"
            var n = $.queue(i, e),
              a = n.shift()
            "inprogress" === a && (a = n.shift()),
              a &&
                ("fx" === e && n.unshift("inprogress"),
                a.call(i, function() {
                  $.dequeue(i, e)
                }))
          })
        }),
        ($.fn = $.prototype = {
          init: function(t) {
            if (t.nodeType) return (this[0] = t), this
            throw new Error("Not a DOM node.")
          },
          offset: function() {
            var e = this[0].getBoundingClientRect
              ? this[0].getBoundingClientRect()
              : { top: 0, left: 0 }
            return {
              top:
                e.top +
                (t.pageYOffset || document.scrollTop || 0) -
                (document.clientTop || 0),
              left:
                e.left +
                (t.pageXOffset || document.scrollLeft || 0) -
                (document.clientLeft || 0)
            }
          },
          position: function() {
            function t() {
              for (
                var t = this.offsetParent || document;
                t &&
                "html" === !t.nodeType.toLowerCase &&
                "static" === t.style.position;

              )
                t = t.offsetParent
              return t || document
            }
            var e = this[0],
              t = t.apply(e),
              i = this.offset(),
              n = /^(?:body|html)$/i.test(t.nodeName)
                ? { top: 0, left: 0 }
                : $(t).offset()
            return (
              (i.top -= parseFloat(e.style.marginTop) || 0),
              (i.left -= parseFloat(e.style.marginLeft) || 0),
              t.style &&
                ((n.top += parseFloat(t.style.borderTopWidth) || 0),
                (n.left += parseFloat(t.style.borderLeftWidth) || 0)),
              { top: i.top - n.top, left: i.left - n.left }
            )
          }
        })
      var i = {}
      ;($.expando = "velocity" + new Date().getTime()), ($.uuid = 0)
      for (
        var n = {},
          a = n.hasOwnProperty,
          r = n.toString,
          o = "Boolean Number String Function Array Date RegExp Object Error".split(
            " "
          ),
          s = 0;
        s < o.length;
        s++
      )
        n["[object " + o[s] + "]"] = o[s].toLowerCase()
      ;($.fn.init.prototype = $.fn), (t.Velocity = { Utilities: $ })
    }
  })(window),
  (function(t) {
    "object" == typeof module && "object" == typeof module.exports
      ? (module.exports = t())
      : "function" == typeof define && define.amd
      ? define(t)
      : t()
  })(function() {
    return (function(t, e, i, n) {
      function a(t) {
        for (var e = -1, i = t ? t.length : 0, n = []; ++e < i; ) {
          var a = t[e]
          a && n.push(a)
        }
        return n
      }
      function r(t) {
        return (
          h.isWrapped(t) ? (t = [].slice.call(t)) : h.isNode(t) && (t = [t]), t
        )
      }
      function o(t) {
        var e = $.data(t, "velocity")
        return null === e ? n : e
      }
      function s(t) {
        return function(e) {
          return Math.round(e * t) * (1 / t)
        }
      }
      function l(t, i, n, a) {
        function r(t, e) {
          return 1 - 3 * e + 3 * t
        }
        function o(t, e) {
          return 3 * e - 6 * t
        }
        function s(t) {
          return 3 * t
        }
        function l(t, e, i) {
          return ((r(e, i) * t + o(e, i)) * t + s(e)) * t
        }
        function u(t, e, i) {
          return 3 * r(e, i) * t * t + 2 * o(e, i) * t + s(e)
        }
        function c(e, i) {
          for (var a = 0; g > a; ++a) {
            var r = u(i, t, n)
            if (0 === r) return i
            var o = l(i, t, n) - e
            i -= o / r
          }
          return i
        }
        function d() {
          for (var e = 0; b > e; ++e) C[e] = l(e * w, t, n)
        }
        function f(e, i, a) {
          var r,
            o,
            s = 0
          do
            (o = i + (a - i) / 2),
              (r = l(o, t, n) - e),
              r > 0 ? (a = o) : (i = o)
          while (Math.abs(r) > m && ++s < y)
          return o
        }
        function p(e) {
          for (var i = 0, a = 1, r = b - 1; a != r && C[a] <= e; ++a) i += w
          --a
          var o = (e - C[a]) / (C[a + 1] - C[a]),
            s = i + o * w,
            l = u(s, t, n)
          return l >= v ? c(e, s) : 0 == l ? s : f(e, i, i + w)
        }
        function h() {
          ;(S = !0), (t != i || n != a) && d()
        }
        var g = 4,
          v = 0.001,
          m = 1e-7,
          y = 10,
          b = 11,
          w = 1 / (b - 1),
          x = "Float32Array" in e
        if (4 !== arguments.length) return !1
        for (var k = 0; 4 > k; ++k)
          if (
            "number" != typeof arguments[k] ||
            isNaN(arguments[k]) ||
            !isFinite(arguments[k])
          )
            return !1
        ;(t = Math.min(t, 1)),
          (n = Math.min(n, 1)),
          (t = Math.max(t, 0)),
          (n = Math.max(n, 0))
        var C = x ? new Float32Array(b) : new Array(b),
          S = !1,
          T = function(e) {
            return (
              S || h(),
              t === i && n === a ? e : 0 === e ? 0 : 1 === e ? 1 : l(p(e), i, a)
            )
          }
        T.getControlPoints = function() {
          return [{ x: t, y: i }, { x: n, y: a }]
        }
        var O = "generateBezier(" + [t, i, n, a] + ")"
        return (
          (T.toString = function() {
            return O
          }),
          T
        )
      }
      function u(t, e) {
        var i = t
        return (
          h.isString(t)
            ? y.Easings[t] || (i = !1)
            : (i =
                h.isArray(t) && 1 === t.length
                  ? s.apply(null, t)
                  : h.isArray(t) && 2 === t.length
                  ? b.apply(null, t.concat([e]))
                  : h.isArray(t) && 4 === t.length
                  ? l.apply(null, t)
                  : !1),
          i === !1 &&
            (i = y.Easings[y.defaults.easing] ? y.defaults.easing : m),
          i
        )
      }
      function c(t) {
        if (t)
          for (
            var e = new Date().getTime(), i = 0, a = y.State.calls.length;
            a > i;
            i++
          )
            if (y.State.calls[i]) {
              var r = y.State.calls[i],
                s = r[0],
                l = r[2],
                u = r[3],
                f = !!u
              u || (u = y.State.calls[i][3] = e - 16)
              for (
                var p = Math.min((e - u) / l.duration, 1), g = 0, v = s.length;
                v > g;
                g++
              ) {
                var m = s[g],
                  b = m.element
                if (o(b)) {
                  var x = !1
                  if (
                    l.display !== n &&
                    null !== l.display &&
                    "none" !== l.display
                  ) {
                    if ("flex" === l.display) {
                      var C = [
                        "-webkit-box",
                        "-moz-box",
                        "-ms-flexbox",
                        "-webkit-flex"
                      ]
                      $.each(C, function(t, e) {
                        w.setPropertyValue(b, "display", e)
                      })
                    }
                    w.setPropertyValue(b, "display", l.display)
                  }
                  l.visibility !== n &&
                    "hidden" !== l.visibility &&
                    w.setPropertyValue(b, "visibility", l.visibility)
                  for (var S in m)
                    if ("element" !== S) {
                      var T,
                        O = m[S],
                        A = h.isString(O.easing)
                          ? y.Easings[O.easing]
                          : O.easing
                      if (1 === p) T = O.endValue
                      else if (
                        ((T =
                          O.startValue + (O.endValue - O.startValue) * A(p)),
                        !f && T === O.currentValue)
                      )
                        continue
                      if (((O.currentValue = T), w.Hooks.registered[S])) {
                        var P = w.Hooks.getRoot(S),
                          E = o(b).rootPropertyValueCache[P]
                        E && (O.rootPropertyValue = E)
                      }
                      var q = w.setPropertyValue(
                        b,
                        S,
                        O.currentValue +
                          (0 === parseFloat(T) ? "" : O.unitType),
                        O.rootPropertyValue,
                        O.scrollData
                      )
                      w.Hooks.registered[S] &&
                        (o(b).rootPropertyValueCache[P] = w.Normalizations
                          .registered[P]
                          ? w.Normalizations.registered[P](
                              "extract",
                              null,
                              q[1]
                            )
                          : q[1]),
                        "transform" === q[0] && (x = !0)
                    }
                  l.mobileHA &&
                    o(b).transformCache.translate3d === n &&
                    ((o(b).transformCache.translate3d = "(0px, 0px, 0px)"),
                    (x = !0)),
                    x && w.flushTransformCache(b)
                }
              }
              l.display !== n &&
                "none" !== l.display &&
                (y.State.calls[i][2].display = !1),
                l.visibility !== n &&
                  "hidden" !== l.visibility &&
                  (y.State.calls[i][2].visibility = !1),
                l.progress &&
                  l.progress.call(
                    r[1],
                    r[1],
                    p,
                    Math.max(0, u + l.duration - e),
                    u
                  ),
                1 === p && d(i)
            }
        y.State.isTicking && k(c)
      }
      function d(t, e) {
        if (!y.State.calls[t]) return !1
        for (
          var i = y.State.calls[t][0],
            a = y.State.calls[t][1],
            r = y.State.calls[t][2],
            s = y.State.calls[t][4],
            l = !1,
            u = 0,
            c = i.length;
          c > u;
          u++
        ) {
          var d = i[u].element
          if (
            (e ||
              r.loop ||
              ("none" === r.display &&
                w.setPropertyValue(d, "display", r.display),
              "hidden" === r.visibility &&
                w.setPropertyValue(d, "visibility", r.visibility)),
            r.loop !== !0 &&
              ($.queue(d)[1] === n ||
                !/\.velocityQueueEntryFlag/i.test($.queue(d)[1])) &&
              o(d))
          ) {
            ;(o(d).isAnimating = !1), (o(d).rootPropertyValueCache = {})
            var f = !1
            $.each(w.Lists.transforms3D, function(t, e) {
              var i = /^scale/.test(e) ? 1 : 0,
                a = o(d).transformCache[e]
              o(d).transformCache[e] !== n &&
                new RegExp("^\\(" + i + "[^.]").test(a) &&
                ((f = !0), delete o(d).transformCache[e])
            }),
              r.mobileHA && ((f = !0), delete o(d).transformCache.translate3d),
              f && w.flushTransformCache(d),
              w.Values.removeClass(d, "velocity-animating")
          }
          if (!e && r.complete && !r.loop && u === c - 1)
            try {
              r.complete.call(a, a)
            } catch (p) {
              setTimeout(function() {
                throw p
              }, 1)
            }
          s && r.loop !== !0 && s(a),
            r.loop !== !0 ||
              e ||
              ($.each(o(d).tweensContainer, function(t, e) {
                ;/^rotate/.test(t) &&
                  360 === parseFloat(e.endValue) &&
                  ((e.endValue = 0), (e.startValue = 360))
              }),
              y(d, "reverse", { loop: !0, delay: r.delay })),
            r.queue !== !1 && $.dequeue(d, r.queue)
        }
        y.State.calls[t] = !1
        for (var h = 0, g = y.State.calls.length; g > h; h++)
          if (y.State.calls[h] !== !1) {
            l = !0
            break
          }
        l === !1 &&
          ((y.State.isTicking = !1), delete y.State.calls, (y.State.calls = []))
      }
      var $,
        f = (function() {
          if (i.documentMode) return i.documentMode
          for (var t = 7; t > 4; t--) {
            var e = i.createElement("div")
            if (
              ((e.innerHTML =
                "<!--[if IE " + t + "]><span></span><![endif]-->"),
              e.getElementsByTagName("span").length)
            )
              return (e = null), t
          }
          return n
        })(),
        p = (function() {
          var t = 0
          return (
            e.webkitRequestAnimationFrame ||
            e.mozRequestAnimationFrame ||
            function(e) {
              var i,
                n = new Date().getTime()
              return (
                (i = Math.max(0, 16 - (n - t))),
                (t = n + i),
                setTimeout(function() {
                  e(n + i)
                }, i)
              )
            }
          )
        })(),
        h = {
          isString: function(t) {
            return "string" == typeof t
          },
          isArray:
            Array.isArray ||
            function(t) {
              return "[object Array]" === Object.prototype.toString.call(t)
            },
          isFunction: function(t) {
            return "[object Function]" === Object.prototype.toString.call(t)
          },
          isNode: function(t) {
            return t && t.nodeType
          },
          isNodeList: function(t) {
            return (
              "object" == typeof t &&
              /^\[object (HTMLCollection|NodeList|Object)\]$/.test(
                Object.prototype.toString.call(t)
              ) &&
              t.length !== n &&
              (0 === t.length || ("object" == typeof t[0] && t[0].nodeType > 0))
            )
          },
          isWrapped: function(t) {
            return t && (t.jquery || (e.Zepto && e.Zepto.zepto.isZ(t)))
          },
          isSVG: function(t) {
            return e.SVGElement && t instanceof e.SVGElement
          },
          isEmptyObject: function(t) {
            for (var e in t) return !1
            return !0
          }
        },
        g = !1
      if (
        (t.fn && t.fn.jquery ? (($ = t), (g = !0)) : ($ = e.Velocity.Utilities),
        8 >= f && !g)
      )
        throw new Error(
          "Velocity: IE8 and below require jQuery to be loaded before Velocity."
        )
      if (7 >= f) return void (jQuery.fn.velocity = jQuery.fn.animate)
      var v = 400,
        m = "swing",
        y = {
          State: {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            ),
            isAndroid: /Android/i.test(navigator.userAgent),
            isGingerbread: /Android 2\.3\.[3-7]/i.test(navigator.userAgent),
            isChrome: e.chrome,
            isFirefox: /Firefox/i.test(navigator.userAgent),
            prefixElement: i.createElement("div"),
            prefixMatches: {},
            scrollAnchor: null,
            scrollPropertyLeft: null,
            scrollPropertyTop: null,
            isTicking: !1,
            calls: []
          },
          CSS: {},
          Utilities: $,
          Redirects: {},
          Easings: {},
          Promise: e.Promise,
          defaults: {
            queue: "",
            duration: v,
            easing: m,
            begin: n,
            complete: n,
            progress: n,
            display: n,
            visibility: n,
            loop: !1,
            delay: !1,
            mobileHA: !0,
            _cacheValues: !0
          },
          init: function(t) {
            $.data(t, "velocity", {
              isSVG: h.isSVG(t),
              isAnimating: !1,
              computedStyle: null,
              tweensContainer: null,
              rootPropertyValueCache: {},
              transformCache: {}
            })
          },
          hook: null,
          mock: !1,
          version: { major: 1, minor: 1, patch: 0 },
          debug: !1
        }
      e.pageYOffset !== n
        ? ((y.State.scrollAnchor = e),
          (y.State.scrollPropertyLeft = "pageXOffset"),
          (y.State.scrollPropertyTop = "pageYOffset"))
        : ((y.State.scrollAnchor =
            i.documentElement || i.body.parentNode || i.body),
          (y.State.scrollPropertyLeft = "scrollLeft"),
          (y.State.scrollPropertyTop = "scrollTop"))
      var b = (function() {
        function t(t) {
          return -t.tension * t.x - t.friction * t.v
        }
        function e(e, i, n) {
          var a = {
            x: e.x + n.dx * i,
            v: e.v + n.dv * i,
            tension: e.tension,
            friction: e.friction
          }
          return { dx: a.v, dv: t(a) }
        }
        function i(i, n) {
          var a = { dx: i.v, dv: t(i) },
            r = e(i, 0.5 * n, a),
            o = e(i, 0.5 * n, r),
            s = e(i, n, o),
            l = (1 / 6) * (a.dx + 2 * (r.dx + o.dx) + s.dx),
            u = (1 / 6) * (a.dv + 2 * (r.dv + o.dv) + s.dv)
          return (i.x = i.x + l * n), (i.v = i.v + u * n), i
        }
        return function n(t, e, a) {
          var r,
            o,
            s,
            l = { x: -1, v: 0, tension: null, friction: null },
            u = [0],
            c = 0,
            d = 1e-4,
            f = 0.016
          for (
            t = parseFloat(t) || 500,
              e = parseFloat(e) || 20,
              a = a || null,
              l.tension = t,
              l.friction = e,
              r = null !== a,
              r ? ((c = n(t, e)), (o = (c / a) * f)) : (o = f);
            (s = i(s || l, o)),
              u.push(1 + s.x),
              (c += 16),
              Math.abs(s.x) > d && Math.abs(s.v) > d;

          );
          return r
            ? function(t) {
                return u[(t * (u.length - 1)) | 0]
              }
            : c
        }
      })()
      ;(y.Easings = {
        linear: function(t) {
          return t
        },
        swing: function(t) {
          return 0.5 - Math.cos(t * Math.PI) / 2
        },
        spring: function(t) {
          return 1 - Math.cos(4.5 * t * Math.PI) * Math.exp(6 * -t)
        }
      }),
        $.each(
          [
            ["ease", [0.25, 0.1, 0.25, 1]],
            ["ease-in", [0.42, 0, 1, 1]],
            ["ease-out", [0, 0, 0.58, 1]],
            ["ease-in-out", [0.42, 0, 0.58, 1]],
            ["easeInSine", [0.47, 0, 0.745, 0.715]],
            ["easeOutSine", [0.39, 0.575, 0.565, 1]],
            ["easeInOutSine", [0.445, 0.05, 0.55, 0.95]],
            ["easeInQuad", [0.55, 0.085, 0.68, 0.53]],
            ["easeOutQuad", [0.25, 0.46, 0.45, 0.94]],
            ["easeInOutQuad", [0.455, 0.03, 0.515, 0.955]],
            ["easeInCubic", [0.55, 0.055, 0.675, 0.19]],
            ["easeOutCubic", [0.215, 0.61, 0.355, 1]],
            ["easeInOutCubic", [0.645, 0.045, 0.355, 1]],
            ["easeInQuart", [0.895, 0.03, 0.685, 0.22]],
            ["easeOutQuart", [0.165, 0.84, 0.44, 1]],
            ["easeInOutQuart", [0.77, 0, 0.175, 1]],
            ["easeInQuint", [0.755, 0.05, 0.855, 0.06]],
            ["easeOutQuint", [0.23, 1, 0.32, 1]],
            ["easeInOutQuint", [0.86, 0, 0.07, 1]],
            ["easeInExpo", [0.95, 0.05, 0.795, 0.035]],
            ["easeOutExpo", [0.19, 1, 0.22, 1]],
            ["easeInOutExpo", [1, 0, 0, 1]],
            ["easeInCirc", [0.6, 0.04, 0.98, 0.335]],
            ["easeOutCirc", [0.075, 0.82, 0.165, 1]],
            ["easeInOutCirc", [0.785, 0.135, 0.15, 0.86]]
          ],
          function(t, e) {
            y.Easings[e[0]] = l.apply(null, e[1])
          }
        )
      var w = (y.CSS = {
        RegEx: {
          isHex: /^#([A-f\d]{3}){1,2}$/i,
          valueUnwrap: /^[A-z]+\((.*)\)$/i,
          wrappedValueAlreadyExtracted: /[0-9.]+ [0-9.]+ [0-9.]+( [0-9.]+)?/,
          valueSplit: /([A-z]+\(.+\))|(([A-z0-9#-.]+?)(?=\s|$))/gi
        },
        Lists: {
          colors: [
            "fill",
            "stroke",
            "stopColor",
            "color",
            "backgroundColor",
            "borderColor",
            "borderTopColor",
            "borderRightColor",
            "borderBottomColor",
            "borderLeftColor",
            "outlineColor"
          ],
          transformsBase: [
            "translateX",
            "translateY",
            "scale",
            "scaleX",
            "scaleY",
            "skewX",
            "skewY",
            "rotateZ"
          ],
          transforms3D: [
            "transformPerspective",
            "translateZ",
            "scaleZ",
            "rotateX",
            "rotateY"
          ]
        },
        Hooks: {
          templates: {
            textShadow: ["Color X Y Blur", "black 0px 0px 0px"],
            boxShadow: ["Color X Y Blur Spread", "black 0px 0px 0px 0px"],
            clip: ["Top Right Bottom Left", "0px 0px 0px 0px"],
            backgroundPosition: ["X Y", "0% 0%"],
            transformOrigin: ["X Y Z", "50% 50% 0px"],
            perspectiveOrigin: ["X Y", "50% 50%"]
          },
          registered: {},
          register: function() {
            for (var t = 0; t < w.Lists.colors.length; t++) {
              var e =
                "color" === w.Lists.colors[t] ? "0 0 0 1" : "255 255 255 1"
              w.Hooks.templates[w.Lists.colors[t]] = ["Red Green Blue Alpha", e]
            }
            var i, n, a
            if (f)
              for (i in w.Hooks.templates) {
                ;(n = w.Hooks.templates[i]), (a = n[0].split(" "))
                var r = n[1].match(w.RegEx.valueSplit)
                "Color" === a[0] &&
                  (a.push(a.shift()),
                  r.push(r.shift()),
                  (w.Hooks.templates[i] = [a.join(" "), r.join(" ")]))
              }
            for (i in w.Hooks.templates) {
              ;(n = w.Hooks.templates[i]), (a = n[0].split(" "))
              for (var t in a) {
                var o = i + a[t],
                  s = t
                w.Hooks.registered[o] = [i, s]
              }
            }
          },
          getRoot: function(t) {
            var e = w.Hooks.registered[t]
            return e ? e[0] : t
          },
          cleanRootPropertyValue: function(t, e) {
            return (
              w.RegEx.valueUnwrap.test(e) &&
                (e = e.match(w.RegEx.valueUnwrap)[1]),
              w.Values.isCSSNullValue(e) && (e = w.Hooks.templates[t][1]),
              e
            )
          },
          extractValue: function(t, e) {
            var i = w.Hooks.registered[t]
            if (i) {
              var n = i[0],
                a = i[1]
              return (
                (e = w.Hooks.cleanRootPropertyValue(n, e)),
                e.toString().match(w.RegEx.valueSplit)[a]
              )
            }
            return e
          },
          injectValue: function(t, e, i) {
            var n = w.Hooks.registered[t]
            if (n) {
              var a,
                r,
                o = n[0],
                s = n[1]
              return (
                (i = w.Hooks.cleanRootPropertyValue(o, i)),
                (a = i.toString().match(w.RegEx.valueSplit)),
                (a[s] = e),
                (r = a.join(" "))
              )
            }
            return i
          }
        },
        Normalizations: {
          registered: {
            clip: function(t, e, i) {
              switch (t) {
                case "name":
                  return "clip"
                case "extract":
                  var n
                  return (
                    w.RegEx.wrappedValueAlreadyExtracted.test(i)
                      ? (n = i)
                      : ((n = i.toString().match(w.RegEx.valueUnwrap)),
                        (n = n ? n[1].replace(/,(\s+)?/g, " ") : i)),
                    n
                  )
                case "inject":
                  return "rect(" + i + ")"
              }
            },
            blur: function(t, e, i) {
              switch (t) {
                case "name":
                  return "-webkit-filter"
                case "extract":
                  var n = parseFloat(i)
                  if (!n && 0 !== n) {
                    var a = i.toString().match(/blur\(([0-9]+[A-z]+)\)/i)
                    n = a ? a[1] : 0
                  }
                  return n
                case "inject":
                  return parseFloat(i) ? "blur(" + i + ")" : "none"
              }
            },
            opacity: function(t, e, i) {
              if (8 >= f)
                switch (t) {
                  case "name":
                    return "filter"
                  case "extract":
                    var n = i.toString().match(/alpha\(opacity=(.*)\)/i)
                    return (i = n ? n[1] / 100 : 1)
                  case "inject":
                    return (
                      (e.style.zoom = 1),
                      parseFloat(i) >= 1
                        ? ""
                        : "alpha(opacity=" +
                          parseInt(100 * parseFloat(i), 10) +
                          ")"
                    )
                }
              else
                switch (t) {
                  case "name":
                    return "opacity"
                  case "extract":
                    return i
                  case "inject":
                    return i
                }
            }
          },
          register: function() {
            9 >= f ||
              y.State.isGingerbread ||
              (w.Lists.transformsBase = w.Lists.transformsBase.concat(
                w.Lists.transforms3D
              ))
            for (var t = 0; t < w.Lists.transformsBase.length; t++)
              !(function() {
                var e = w.Lists.transformsBase[t]
                w.Normalizations.registered[e] = function(t, i, a) {
                  switch (t) {
                    case "name":
                      return "transform"
                    case "extract":
                      return o(i) === n || o(i).transformCache[e] === n
                        ? /^scale/i.test(e)
                          ? 1
                          : 0
                        : o(i).transformCache[e].replace(/[()]/g, "")
                    case "inject":
                      var r = !1
                      switch (e.substr(0, e.length - 1)) {
                        case "translate":
                          r = !/(%|px|em|rem|vw|vh|\d)$/i.test(a)
                          break
                        case "scal":
                        case "scale":
                          y.State.isAndroid &&
                            o(i).transformCache[e] === n &&
                            1 > a &&
                            (a = 1),
                            (r = !/(\d)$/i.test(a))
                          break
                        case "skew":
                          r = !/(deg|\d)$/i.test(a)
                          break
                        case "rotate":
                          r = !/(deg|\d)$/i.test(a)
                      }
                      return (
                        r || (o(i).transformCache[e] = "(" + a + ")"),
                        o(i).transformCache[e]
                      )
                  }
                }
              })()
            for (var t = 0; t < w.Lists.colors.length; t++)
              !(function() {
                var e = w.Lists.colors[t]
                w.Normalizations.registered[e] = function(t, i, a) {
                  switch (t) {
                    case "name":
                      return e
                    case "extract":
                      var r
                      if (w.RegEx.wrappedValueAlreadyExtracted.test(a)) r = a
                      else {
                        var o,
                          s = {
                            black: "rgb(0, 0, 0)",
                            blue: "rgb(0, 0, 255)",
                            gray: "rgb(128, 128, 128)",
                            green: "rgb(0, 128, 0)",
                            red: "rgb(255, 0, 0)",
                            white: "rgb(255, 255, 255)"
                          }
                        ;/^[A-z]+$/i.test(a)
                          ? (o = s[a] !== n ? s[a] : s.black)
                          : w.RegEx.isHex.test(a)
                          ? (o = "rgb(" + w.Values.hexToRgb(a).join(" ") + ")")
                          : /^rgba?\(/i.test(a) || (o = s.black),
                          (r = (o || a)
                            .toString()
                            .match(w.RegEx.valueUnwrap)[1]
                            .replace(/,(\s+)?/g, " "))
                      }
                      return (
                        8 >= f || 3 !== r.split(" ").length || (r += " 1"), r
                      )
                    case "inject":
                      return (
                        8 >= f
                          ? 4 === a.split(" ").length &&
                            (a = a
                              .split(/\s+/)
                              .slice(0, 3)
                              .join(" "))
                          : 3 === a.split(" ").length && (a += " 1"),
                        (8 >= f ? "rgb" : "rgba") +
                          "(" +
                          a.replace(/\s+/g, ",").replace(/\.(\d)+(?=,)/g, "") +
                          ")"
                      )
                  }
                }
              })()
          }
        },
        Names: {
          camelCase: function(t) {
            return t.replace(/-(\w)/g, function(t, e) {
              return e.toUpperCase()
            })
          },
          SVGAttribute: function(t) {
            var e = "width|height|x|y|cx|cy|r|rx|ry|x1|x2|y1|y2"
            return (
              (f || (y.State.isAndroid && !y.State.isChrome)) &&
                (e += "|transform"),
              new RegExp("^(" + e + ")$", "i").test(t)
            )
          },
          prefixCheck: function(t) {
            if (y.State.prefixMatches[t]) return [y.State.prefixMatches[t], !0]
            for (
              var e = ["", "Webkit", "Moz", "ms", "O"], i = 0, n = e.length;
              n > i;
              i++
            ) {
              var a
              if (
                ((a =
                  0 === i
                    ? t
                    : e[i] +
                      t.replace(/^\w/, function(t) {
                        return t.toUpperCase()
                      })),
                h.isString(y.State.prefixElement.style[a]))
              )
                return (y.State.prefixMatches[t] = a), [a, !0]
            }
            return [t, !1]
          }
        },
        Values: {
          hexToRgb: function(t) {
            var e,
              i = /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
              n = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
            return (
              (t = t.replace(i, function(t, e, i, n) {
                return e + e + i + i + n + n
              })),
              (e = n.exec(t)),
              e
                ? [parseInt(e[1], 16), parseInt(e[2], 16), parseInt(e[3], 16)]
                : [0, 0, 0]
            )
          },
          isCSSNullValue: function(t) {
            return (
              0 == t ||
              /^(none|auto|transparent|(rgba\(0, ?0, ?0, ?0\)))$/i.test(t)
            )
          },
          getUnitType: function(t) {
            return /^(rotate|skew)/i.test(t)
              ? "deg"
              : /(^(scale|scaleX|scaleY|scaleZ|alpha|flexGrow|flexHeight|zIndex|fontWeight)$)|((opacity|red|green|blue|alpha)$)/i.test(
                  t
                )
              ? ""
              : "px"
          },
          getDisplayType: function(t) {
            var e = t && t.tagName.toString().toLowerCase()
            return /^(b|big|i|small|tt|abbr|acronym|cite|code|dfn|em|kbd|strong|samp|var|a|bdo|br|img|map|object|q|script|span|sub|sup|button|input|label|select|textarea)$/i.test(
              e
            )
              ? "inline"
              : /^(li)$/i.test(e)
              ? "list-item"
              : /^(tr)$/i.test(e)
              ? "table-row"
              : "block"
          },
          addClass: function(t, e) {
            t.classList
              ? t.classList.add(e)
              : (t.className += (t.className.length ? " " : "") + e)
          },
          removeClass: function(t, e) {
            t.classList
              ? t.classList.remove(e)
              : (t.className = t.className
                  .toString()
                  .replace(
                    new RegExp(
                      "(^|\\s)" + e.split(" ").join("|") + "(\\s|$)",
                      "gi"
                    ),
                    " "
                  ))
          }
        },
        getPropertyValue: function(t, i, a, r) {
          function s(t, i) {
            function a() {
              u && w.setPropertyValue(t, "display", "none")
            }
            var l = 0
            if (8 >= f) l = $.css(t, i)
            else {
              var u = !1
              if (
                (/^(width|height)$/.test(i) &&
                  0 === w.getPropertyValue(t, "display") &&
                  ((u = !0),
                  w.setPropertyValue(t, "display", w.Values.getDisplayType(t))),
                !r)
              ) {
                if (
                  "height" === i &&
                  "border-box" !==
                    w
                      .getPropertyValue(t, "boxSizing")
                      .toString()
                      .toLowerCase()
                ) {
                  var c =
                    t.offsetHeight -
                    (parseFloat(w.getPropertyValue(t, "borderTopWidth")) || 0) -
                    (parseFloat(w.getPropertyValue(t, "borderBottomWidth")) ||
                      0) -
                    (parseFloat(w.getPropertyValue(t, "paddingTop")) || 0) -
                    (parseFloat(w.getPropertyValue(t, "paddingBottom")) || 0)
                  return a(), c
                }
                if (
                  "width" === i &&
                  "border-box" !==
                    w
                      .getPropertyValue(t, "boxSizing")
                      .toString()
                      .toLowerCase()
                ) {
                  var d =
                    t.offsetWidth -
                    (parseFloat(w.getPropertyValue(t, "borderLeftWidth")) ||
                      0) -
                    (parseFloat(w.getPropertyValue(t, "borderRightWidth")) ||
                      0) -
                    (parseFloat(w.getPropertyValue(t, "paddingLeft")) || 0) -
                    (parseFloat(w.getPropertyValue(t, "paddingRight")) || 0)
                  return a(), d
                }
              }
              var p
              ;(p =
                o(t) === n
                  ? e.getComputedStyle(t, null)
                  : o(t).computedStyle
                  ? o(t).computedStyle
                  : (o(t).computedStyle = e.getComputedStyle(t, null))),
                (f || y.State.isFirefox) &&
                  "borderColor" === i &&
                  (i = "borderTopColor"),
                (l = 9 === f && "filter" === i ? p.getPropertyValue(i) : p[i]),
                ("" === l || null === l) && (l = t.style[i]),
                a()
            }
            if ("auto" === l && /^(top|right|bottom|left)$/i.test(i)) {
              var h = s(t, "position")
              ;("fixed" === h || ("absolute" === h && /top|left/i.test(i))) &&
                (l = $(t).position()[i] + "px")
            }
            return l
          }
          var l
          if (w.Hooks.registered[i]) {
            var u = i,
              c = w.Hooks.getRoot(u)
            a === n && (a = w.getPropertyValue(t, w.Names.prefixCheck(c)[0])),
              w.Normalizations.registered[c] &&
                (a = w.Normalizations.registered[c]("extract", t, a)),
              (l = w.Hooks.extractValue(u, a))
          } else if (w.Normalizations.registered[i]) {
            var d, p
            ;(d = w.Normalizations.registered[i]("name", t)),
              "transform" !== d &&
                ((p = s(t, w.Names.prefixCheck(d)[0])),
                w.Values.isCSSNullValue(p) &&
                  w.Hooks.templates[i] &&
                  (p = w.Hooks.templates[i][1])),
              (l = w.Normalizations.registered[i]("extract", t, p))
          }
          return (
            /^[\d-]/.test(l) ||
              (l =
                o(t) && o(t).isSVG && w.Names.SVGAttribute(i)
                  ? /^(height|width)$/i.test(i)
                    ? t.getBBox()[i]
                    : t.getAttribute(i)
                  : s(t, w.Names.prefixCheck(i)[0])),
            w.Values.isCSSNullValue(l) && (l = 0),
            y.debug >= 2 && console.log("Get " + i + ": " + l),
            l
          )
        },
        setPropertyValue: function(t, i, n, a, r) {
          var s = i
          if ("scroll" === i)
            r.container
              ? (r.container["scroll" + r.direction] = n)
              : "Left" === r.direction
              ? e.scrollTo(n, r.alternateValue)
              : e.scrollTo(r.alternateValue, n)
          else if (
            w.Normalizations.registered[i] &&
            "transform" === w.Normalizations.registered[i]("name", t)
          )
            w.Normalizations.registered[i]("inject", t, n),
              (s = "transform"),
              (n = o(t).transformCache[i])
          else {
            if (w.Hooks.registered[i]) {
              var l = i,
                u = w.Hooks.getRoot(i)
              ;(a = a || w.getPropertyValue(t, u)),
                (n = w.Hooks.injectValue(l, n, a)),
                (i = u)
            }
            if (
              (w.Normalizations.registered[i] &&
                ((n = w.Normalizations.registered[i]("inject", t, n)),
                (i = w.Normalizations.registered[i]("name", t))),
              (s = w.Names.prefixCheck(i)[0]),
              8 >= f)
            )
              try {
                t.style[s] = n
              } catch (c) {
                y.debug &&
                  console.log(
                    "Browser does not support [" + n + "] for [" + s + "]"
                  )
              }
            else
              o(t) && o(t).isSVG && w.Names.SVGAttribute(i)
                ? t.setAttribute(i, n)
                : (t.style[s] = n)
            y.debug >= 2 && console.log("Set " + i + " (" + s + "): " + n)
          }
          return [s, n]
        },
        flushTransformCache: function(t) {
          function e(e) {
            return parseFloat(w.getPropertyValue(t, e))
          }
          var i = ""
          if ((f || (y.State.isAndroid && !y.State.isChrome)) && o(t).isSVG) {
            var n = {
              translate: [e("translateX"), e("translateY")],
              skewX: [e("skewX")],
              skewY: [e("skewY")],
              scale:
                1 !== e("scale")
                  ? [e("scale"), e("scale")]
                  : [e("scaleX"), e("scaleY")],
              rotate: [e("rotateZ"), 0, 0]
            }
            $.each(o(t).transformCache, function(t) {
              ;/^translate/i.test(t)
                ? (t = "translate")
                : /^scale/i.test(t)
                ? (t = "scale")
                : /^rotate/i.test(t) && (t = "rotate"),
                n[t] && ((i += t + "(" + n[t].join(" ") + ") "), delete n[t])
            })
          } else {
            var a, r
            $.each(o(t).transformCache, function(e) {
              return (
                (a = o(t).transformCache[e]),
                "transformPerspective" === e
                  ? ((r = a), !0)
                  : (9 === f && "rotateZ" === e && (e = "rotate"),
                    void (i += e + a + " "))
              )
            }),
              r && (i = "perspective" + r + " " + i)
          }
          w.setPropertyValue(t, "transform", i)
        }
      })
      w.Hooks.register(),
        w.Normalizations.register(),
        (y.hook = function(t, e, i) {
          var a = n
          return (
            (t = r(t)),
            $.each(t, function(t, r) {
              if ((o(r) === n && y.init(r), i === n))
                a === n && (a = y.CSS.getPropertyValue(r, e))
              else {
                var s = y.CSS.setPropertyValue(r, e, i)
                "transform" === s[0] && y.CSS.flushTransformCache(r), (a = s)
              }
            }),
            a
          )
        })
      var x = function() {
        function t() {
          return l ? A.promise || null : f
        }
        function s() {
          function t(t) {
            function f(t, e) {
              var i = n,
                a = n,
                r = n
              return (
                h.isArray(t)
                  ? ((i = t[0]),
                    (!h.isArray(t[1]) && /^[\d-]/.test(t[1])) ||
                    h.isFunction(t[1]) ||
                    w.RegEx.isHex.test(t[1])
                      ? (r = t[1])
                      : ((h.isString(t[1]) && !w.RegEx.isHex.test(t[1])) ||
                          h.isArray(t[1])) &&
                        ((a = e ? t[1] : u(t[1], l.duration)),
                        t[2] !== n && (r = t[2])))
                  : (i = t),
                e || (a = a || l.easing),
                h.isFunction(i) && (i = i.call(s, S, C)),
                h.isFunction(r) && (r = r.call(s, S, C)),
                [i || 0, a, r]
              )
            }
            function p(t, e) {
              var i, n
              return (
                (n = (e || "0")
                  .toString()
                  .toLowerCase()
                  .replace(/[%A-z]+$/, function(t) {
                    return (i = t), ""
                  })),
                i || (i = w.Values.getUnitType(t)),
                [n, i]
              )
            }
            function v() {
              var t = {
                  myParent: s.parentNode || i.body,
                  position: w.getPropertyValue(s, "position"),
                  fontSize: w.getPropertyValue(s, "fontSize")
                },
                n =
                  t.position === V.lastPosition && t.myParent === V.lastParent,
                a = t.fontSize === V.lastFontSize
              ;(V.lastParent = t.myParent),
                (V.lastPosition = t.position),
                (V.lastFontSize = t.fontSize)
              var r = 100,
                l = {}
              if (a && n)
                (l.emToPx = V.lastEmToPx),
                  (l.percentToPxWidth = V.lastPercentToPxWidth),
                  (l.percentToPxHeight = V.lastPercentToPxHeight)
              else {
                var u = o(s).isSVG
                  ? i.createElementNS("http://www.w3.org/2000/svg", "rect")
                  : i.createElement("div")
                y.init(u),
                  t.myParent.appendChild(u),
                  $.each(["overflow", "overflowX", "overflowY"], function(
                    t,
                    e
                  ) {
                    y.CSS.setPropertyValue(u, e, "hidden")
                  }),
                  y.CSS.setPropertyValue(u, "position", t.position),
                  y.CSS.setPropertyValue(u, "fontSize", t.fontSize),
                  y.CSS.setPropertyValue(u, "boxSizing", "content-box"),
                  $.each(
                    [
                      "minWidth",
                      "maxWidth",
                      "width",
                      "minHeight",
                      "maxHeight",
                      "height"
                    ],
                    function(t, e) {
                      y.CSS.setPropertyValue(u, e, r + "%")
                    }
                  ),
                  y.CSS.setPropertyValue(u, "paddingLeft", r + "em"),
                  (l.percentToPxWidth = V.lastPercentToPxWidth =
                    (parseFloat(w.getPropertyValue(u, "width", null, !0)) ||
                      1) / r),
                  (l.percentToPxHeight = V.lastPercentToPxHeight =
                    (parseFloat(w.getPropertyValue(u, "height", null, !0)) ||
                      1) / r),
                  (l.emToPx = V.lastEmToPx =
                    (parseFloat(w.getPropertyValue(u, "paddingLeft")) || 1) /
                    r),
                  t.myParent.removeChild(u)
              }
              return (
                null === V.remToPx &&
                  (V.remToPx =
                    parseFloat(w.getPropertyValue(i.body, "fontSize")) || 16),
                null === V.vwToPx &&
                  ((V.vwToPx = parseFloat(e.innerWidth) / 100),
                  (V.vhToPx = parseFloat(e.innerHeight) / 100)),
                (l.remToPx = V.remToPx),
                (l.vwToPx = V.vwToPx),
                (l.vhToPx = V.vhToPx),
                y.debug >= 1 &&
                  console.log("Unit ratios: " + JSON.stringify(l), s),
                l
              )
            }
            if (l.begin && 0 === S)
              try {
                l.begin.call(g, g)
              } catch (x) {
                setTimeout(function() {
                  throw x
                }, 1)
              }
            if ("scroll" === P) {
              var k,
                T,
                O,
                E = /^x$/i.test(l.axis) ? "Left" : "Top",
                q = parseFloat(l.offset) || 0
              l.container
                ? h.isWrapped(l.container) || h.isNode(l.container)
                  ? ((l.container = l.container[0] || l.container),
                    (k = l.container["scroll" + E]),
                    (O = k + $(s).position()[E.toLowerCase()] + q))
                  : (l.container = null)
                : ((k = y.State.scrollAnchor[y.State["scrollProperty" + E]]),
                  (T =
                    y.State.scrollAnchor[
                      y.State[
                        "scrollProperty" + ("Left" === E ? "Top" : "Left")
                      ]
                    ]),
                  (O = $(s).offset()[E.toLowerCase()] + q)),
                (d = {
                  scroll: {
                    rootPropertyValue: !1,
                    startValue: k,
                    currentValue: k,
                    endValue: O,
                    unitType: "",
                    easing: l.easing,
                    scrollData: {
                      container: l.container,
                      direction: E,
                      alternateValue: T
                    }
                  },
                  element: s
                }),
                y.debug &&
                  console.log("tweensContainer (scroll): ", d.scroll, s)
            } else if ("reverse" === P) {
              if (!o(s).tweensContainer) return void $.dequeue(s, l.queue)
              "none" === o(s).opts.display && (o(s).opts.display = "auto"),
                "hidden" === o(s).opts.visibility &&
                  (o(s).opts.visibility = "visible"),
                (o(s).opts.loop = !1),
                (o(s).opts.begin = null),
                (o(s).opts.complete = null),
                b.easing || delete l.easing,
                b.duration || delete l.duration,
                (l = $.extend({}, o(s).opts, l))
              var _ = $.extend(!0, {}, o(s).tweensContainer)
              for (var I in _)
                if ("element" !== I) {
                  var M = _[I].startValue
                  ;(_[I].startValue = _[I].currentValue = _[I].endValue),
                    (_[I].endValue = M),
                    h.isEmptyObject(b) || (_[I].easing = l.easing),
                    y.debug &&
                      console.log(
                        "reverse tweensContainer (" +
                          I +
                          "): " +
                          JSON.stringify(_[I]),
                        s
                      )
                }
              d = _
            } else if ("start" === P) {
              var _
              o(s).tweensContainer &&
                o(s).isAnimating === !0 &&
                (_ = o(s).tweensContainer),
                $.each(m, function(t, e) {
                  if (RegExp("^" + w.Lists.colors.join("$|^") + "$").test(t)) {
                    var i = f(e, !0),
                      a = i[0],
                      r = i[1],
                      o = i[2]
                    if (w.RegEx.isHex.test(a)) {
                      for (
                        var s = ["Red", "Green", "Blue"],
                          l = w.Values.hexToRgb(a),
                          u = o ? w.Values.hexToRgb(o) : n,
                          c = 0;
                        c < s.length;
                        c++
                      ) {
                        var d = [l[c]]
                        r && d.push(r),
                          u !== n && d.push(u[c]),
                          (m[t + s[c]] = d)
                      }
                      delete m[t]
                    }
                  }
                })
              for (var D in m) {
                var Q = f(m[D]),
                  F = Q[0],
                  N = Q[1],
                  L = Q[2]
                D = w.Names.camelCase(D)
                var z = w.Hooks.getRoot(D),
                  R = !1
                if (
                  o(s).isSVG ||
                  w.Names.prefixCheck(z)[1] !== !1 ||
                  w.Normalizations.registered[z] !== n
                ) {
                  ;((l.display !== n &&
                    null !== l.display &&
                    "none" !== l.display) ||
                    (l.visibility !== n && "hidden" !== l.visibility)) &&
                    /opacity|filter/.test(D) &&
                    !L &&
                    0 !== F &&
                    (L = 0),
                    l._cacheValues && _ && _[D]
                      ? (L === n && (L = _[D].endValue + _[D].unitType),
                        (R = o(s).rootPropertyValueCache[z]))
                      : w.Hooks.registered[D]
                      ? L === n
                        ? ((R = w.getPropertyValue(s, z)),
                          (L = w.getPropertyValue(s, D, R)))
                        : (R = w.Hooks.templates[z][1])
                      : L === n && (L = w.getPropertyValue(s, D))
                  var W,
                    H,
                    X,
                    Y = !1
                  if (
                    ((W = p(D, L)),
                    (L = W[0]),
                    (X = W[1]),
                    (W = p(D, F)),
                    (F = W[0].replace(/^([+-\/*])=/, function(t, e) {
                      return (Y = e), ""
                    })),
                    (H = W[1]),
                    (L = parseFloat(L) || 0),
                    (F = parseFloat(F) || 0),
                    "%" === H &&
                      (/^(fontSize|lineHeight)$/.test(D)
                        ? ((F /= 100), (H = "em"))
                        : /^scale/.test(D)
                        ? ((F /= 100), (H = ""))
                        : /(Red|Green|Blue)$/i.test(D) &&
                          ((F = (F / 100) * 255), (H = ""))),
                    /[\/*]/.test(Y))
                  )
                    H = X
                  else if (X !== H && 0 !== L)
                    if (0 === F) H = X
                    else {
                      r = r || v()
                      var B =
                        /margin|padding|left|right|width|text|word|letter/i.test(
                          D
                        ) ||
                        /X$/.test(D) ||
                        "x" === D
                          ? "x"
                          : "y"
                      switch (X) {
                        case "%":
                          L *=
                            "x" === B ? r.percentToPxWidth : r.percentToPxHeight
                          break
                        case "px":
                          break
                        default:
                          L *= r[X + "ToPx"]
                      }
                      switch (H) {
                        case "%":
                          L *=
                            1 /
                            ("x" === B
                              ? r.percentToPxWidth
                              : r.percentToPxHeight)
                          break
                        case "px":
                          break
                        default:
                          L *= 1 / r[H + "ToPx"]
                      }
                    }
                  switch (Y) {
                    case "+":
                      F = L + F
                      break
                    case "-":
                      F = L - F
                      break
                    case "*":
                      F = L * F
                      break
                    case "/":
                      F = L / F
                  }
                  ;(d[D] = {
                    rootPropertyValue: R,
                    startValue: L,
                    currentValue: L,
                    endValue: F,
                    unitType: H,
                    easing: N
                  }),
                    y.debug &&
                      console.log(
                        "tweensContainer (" + D + "): " + JSON.stringify(d[D]),
                        s
                      )
                } else
                  y.debug &&
                    console.log(
                      "Skipping [" + z + "] due to a lack of browser support."
                    )
              }
              d.element = s
            }
            d.element &&
              (w.Values.addClass(s, "velocity-animating"),
              j.push(d),
              "" === l.queue && ((o(s).tweensContainer = d), (o(s).opts = l)),
              (o(s).isAnimating = !0),
              S === C - 1
                ? (y.State.calls.length > 1e4 &&
                    (y.State.calls = a(y.State.calls)),
                  y.State.calls.push([j, g, l, null, A.resolver]),
                  y.State.isTicking === !1 && ((y.State.isTicking = !0), c()))
                : S++)
          }
          var r,
            s = this,
            l = $.extend({}, y.defaults, b),
            d = {}
          switch (
            (o(s) === n && y.init(s),
            parseFloat(l.delay) &&
              l.queue !== !1 &&
              $.queue(s, l.queue, function(t) {
                ;(y.velocityQueueEntryFlag = !0),
                  (o(s).delayTimer = {
                    setTimeout: setTimeout(t, parseFloat(l.delay)),
                    next: t
                  })
              }),
            l.duration.toString().toLowerCase())
          ) {
            case "fast":
              l.duration = 200
              break
            case "normal":
              l.duration = v
              break
            case "slow":
              l.duration = 600
              break
            default:
              l.duration = parseFloat(l.duration) || 1
          }
          y.mock !== !1 &&
            (y.mock === !0
              ? (l.duration = l.delay = 1)
              : ((l.duration *= parseFloat(y.mock) || 1),
                (l.delay *= parseFloat(y.mock) || 1))),
            (l.easing = u(l.easing, l.duration)),
            l.begin && !h.isFunction(l.begin) && (l.begin = null),
            l.progress && !h.isFunction(l.progress) && (l.progress = null),
            l.complete && !h.isFunction(l.complete) && (l.complete = null),
            l.display !== n &&
              null !== l.display &&
              ((l.display = l.display.toString().toLowerCase()),
              "auto" === l.display &&
                (l.display = y.CSS.Values.getDisplayType(s))),
            l.visibility !== n &&
              null !== l.visibility &&
              (l.visibility = l.visibility.toString().toLowerCase()),
            (l.mobileHA =
              l.mobileHA && y.State.isMobile && !y.State.isGingerbread),
            l.queue === !1
              ? l.delay
                ? setTimeout(t, l.delay)
                : t()
              : $.queue(s, l.queue, function(e, i) {
                  return i === !0
                    ? (A.promise && A.resolver(g), !0)
                    : ((y.velocityQueueEntryFlag = !0), void t(e))
                }),
            ("" !== l.queue && "fx" !== l.queue) ||
              "inprogress" === $.queue(s)[0] ||
              $.dequeue(s)
        }
        var l,
          f,
          p,
          g,
          m,
          b,
          k =
            arguments[0] &&
            (($.isPlainObject(arguments[0].properties) &&
              !arguments[0].properties.names) ||
              h.isString(arguments[0].properties))
        if (
          (h.isWrapped(this)
            ? ((l = !1), (p = 0), (g = this), (f = this))
            : ((l = !0),
              (p = 1),
              (g = k ? arguments[0].elements : arguments[0])),
          (g = r(g)))
        ) {
          k
            ? ((m = arguments[0].properties), (b = arguments[0].options))
            : ((m = arguments[p]), (b = arguments[p + 1]))
          var C = g.length,
            S = 0
          if ("stop" !== m && !$.isPlainObject(b)) {
            var T = p + 1
            b = {}
            for (var O = T; O < arguments.length; O++)
              h.isArray(arguments[O]) ||
              (!/^(fast|normal|slow)$/i.test(arguments[O]) &&
                !/^\d/.test(arguments[O]))
                ? h.isString(arguments[O]) || h.isArray(arguments[O])
                  ? (b.easing = arguments[O])
                  : h.isFunction(arguments[O]) && (b.complete = arguments[O])
                : (b.duration = arguments[O])
          }
          var A = { promise: null, resolver: null, rejecter: null }
          l &&
            y.Promise &&
            (A.promise = new y.Promise(function(t, e) {
              ;(A.resolver = t), (A.rejecter = e)
            }))
          var P
          switch (m) {
            case "scroll":
              P = "scroll"
              break
            case "reverse":
              P = "reverse"
              break
            case "stop":
              $.each(g, function(t, e) {
                o(e) &&
                  o(e).delayTimer &&
                  (clearTimeout(o(e).delayTimer.setTimeout),
                  o(e).delayTimer.next && o(e).delayTimer.next(),
                  delete o(e).delayTimer)
              })
              var E = []
              return (
                $.each(y.State.calls, function(t, e) {
                  e &&
                    $.each(e[1], function(i, a) {
                      var r = h.isString(b) ? b : ""
                      return b !== n && e[2].queue !== r
                        ? !0
                        : void $.each(g, function(e, i) {
                            i === a &&
                              (b !== n &&
                                ($.each($.queue(i, r), function(t, e) {
                                  h.isFunction(e) && e(null, !0)
                                }),
                                $.queue(i, r, [])),
                              o(i) &&
                                "" === r &&
                                $.each(o(i).tweensContainer, function(t, e) {
                                  e.endValue = e.currentValue
                                }),
                              E.push(t))
                          })
                    })
                }),
                $.each(E, function(t, e) {
                  d(e, !0)
                }),
                A.promise && A.resolver(g),
                t()
              )
            default:
              if (!$.isPlainObject(m) || h.isEmptyObject(m)) {
                if (h.isString(m) && y.Redirects[m]) {
                  var q = $.extend({}, b),
                    _ = q.duration,
                    I = q.delay || 0
                  return (
                    q.backwards === !0 && (g = $.extend(!0, [], g).reverse()),
                    $.each(g, function(t, e) {
                      parseFloat(q.stagger)
                        ? (q.delay = I + parseFloat(q.stagger) * t)
                        : h.isFunction(q.stagger) &&
                          (q.delay = I + q.stagger.call(e, t, C)),
                        q.drag &&
                          ((q.duration =
                            parseFloat(_) ||
                            (/^(callout|transition)/.test(m) ? 1e3 : v)),
                          (q.duration = Math.max(
                            q.duration *
                              (q.backwards ? 1 - t / C : (t + 1) / C),
                            0.75 * q.duration,
                            200
                          ))),
                        y.Redirects[m].call(
                          e,
                          e,
                          q || {},
                          t,
                          C,
                          g,
                          A.promise ? A : n
                        )
                    }),
                    t()
                  )
                }
                var M =
                  "Velocity: First argument (" +
                  m +
                  ") was not a property map, a known action, or a registered redirect. Aborting."
                return (
                  A.promise ? A.rejecter(new Error(M)) : console.log(M), t()
                )
              }
              P = "start"
          }
          var V = {
              lastParent: null,
              lastPosition: null,
              lastFontSize: null,
              lastPercentToPxWidth: null,
              lastPercentToPxHeight: null,
              lastEmToPx: null,
              remToPx: null,
              vwToPx: null,
              vhToPx: null
            },
            j = []
          $.each(g, function(t, e) {
            h.isNode(e) && s.call(e)
          })
          var D,
            q = $.extend({}, y.defaults, b)
          if (((q.loop = parseInt(q.loop)), (D = 2 * q.loop - 1), q.loop))
            for (var Q = 0; D > Q; Q++) {
              var F = { delay: q.delay, progress: q.progress }
              Q === D - 1 &&
                ((F.display = q.display),
                (F.visibility = q.visibility),
                (F.complete = q.complete)),
                x(g, "reverse", F)
            }
          return t()
        }
      }
      ;(y = $.extend(x, y)), (y.animate = x)
      var k = e.requestAnimationFrame || p
      return (
        y.State.isMobile ||
          i.hidden === n ||
          i.addEventListener("visibilitychange", function() {
            i.hidden
              ? ((k = function(t) {
                  return setTimeout(function() {
                    t(!0)
                  }, 16)
                }),
                c())
              : (k = e.requestAnimationFrame || p)
          }),
        (t.Velocity = y),
        t !== e && ((t.fn.velocity = x), (t.fn.velocity.defaults = y.defaults)),
        $.each(["Down", "Up"], function(t, e) {
          y.Redirects["slide" + e] = function(t, i, a, r, o, s) {
            var l = $.extend({}, i),
              u = l.begin,
              c = l.complete,
              d = {
                height: "",
                marginTop: "",
                marginBottom: "",
                paddingTop: "",
                paddingBottom: ""
              },
              f = {}
            l.display === n &&
              (l.display =
                "Down" === e
                  ? "inline" === y.CSS.Values.getDisplayType(t)
                    ? "inline-block"
                    : "block"
                  : "none"),
              (l.begin = function() {
                u && u.call(o, o)
                for (var i in d) {
                  f[i] = t.style[i]
                  var n = y.CSS.getPropertyValue(t, i)
                  d[i] = "Down" === e ? [n, 0] : [0, n]
                }
                ;(f.overflow = t.style.overflow), (t.style.overflow = "hidden")
              }),
              (l.complete = function() {
                for (var e in f) t.style[e] = f[e]
                c && c.call(o, o), s && s.resolver(o)
              }),
              y(t, d, l)
          }
        }),
        $.each(["In", "Out"], function(t, e) {
          y.Redirects["fade" + e] = function(t, i, a, r, o, s) {
            var l = $.extend({}, i),
              u = { opacity: "In" === e ? 1 : 0 },
              c = l.complete
            ;(l.complete =
              a !== r - 1
                ? (l.begin = null)
                : function() {
                    c && c.call(o, o), s && s.resolver(o)
                  }),
              l.display === n && (l.display = "In" === e ? "auto" : "none"),
              y(this, u, l)
          }
        }),
        y
      )
    })(window.jQuery || window.Zepto || window, window, document)
  }),
  !(function(t, e, i, n) {
    "use strict"
    function a(t, e, i) {
      return setTimeout(c(t, i), e)
    }
    function r(t, e, i) {
      return Array.isArray(t) ? (o(t, i[e], i), !0) : !1
    }
    function o(t, e, i) {
      var a
      if (t)
        if (t.forEach) t.forEach(e, i)
        else if (t.length !== n)
          for (a = 0; a < t.length; ) e.call(i, t[a], a, t), a++
        else for (a in t) t.hasOwnProperty(a) && e.call(i, t[a], a, t)
    }
    function s(t, e, i) {
      for (var a = Object.keys(e), r = 0; r < a.length; )
        (!i || (i && t[a[r]] === n)) && (t[a[r]] = e[a[r]]), r++
      return t
    }
    function l(t, e) {
      return s(t, e, !0)
    }
    function u(t, e, i) {
      var n,
        a = e.prototype
      ;(n = t.prototype = Object.create(a)),
        (n.constructor = t),
        (n._super = a),
        i && s(n, i)
    }
    function c(t, e) {
      return function() {
        return t.apply(e, arguments)
      }
    }
    function d(t, e) {
      return typeof t == dt ? t.apply(e ? e[0] || n : n, e) : t
    }
    function f(t, e) {
      return t === n ? e : t
    }
    function p(t, e, i) {
      o(m(e), function(e) {
        t.addEventListener(e, i, !1)
      })
    }
    function h(t, e, i) {
      o(m(e), function(e) {
        t.removeEventListener(e, i, !1)
      })
    }
    function g(t, e) {
      for (; t; ) {
        if (t == e) return !0
        t = t.parentNode
      }
      return !1
    }
    function v(t, e) {
      return t.indexOf(e) > -1
    }
    function m(t) {
      return t.trim().split(/\s+/g)
    }
    function y(t, e, i) {
      if (t.indexOf && !i) return t.indexOf(e)
      for (var n = 0; n < t.length; ) {
        if ((i && t[n][i] == e) || (!i && t[n] === e)) return n
        n++
      }
      return -1
    }
    function b(t) {
      return Array.prototype.slice.call(t, 0)
    }
    function w(t, e, i) {
      for (var n = [], a = [], r = 0; r < t.length; ) {
        var o = e ? t[r][e] : t[r]
        y(a, o) < 0 && n.push(t[r]), (a[r] = o), r++
      }
      return (
        i &&
          (n = e
            ? n.sort(function(t, i) {
                return t[e] > i[e]
              })
            : n.sort()),
        n
      )
    }
    function x(t, e) {
      for (
        var i, a, r = e[0].toUpperCase() + e.slice(1), o = 0;
        o < ut.length;

      ) {
        if (((i = ut[o]), (a = i ? i + r : e), a in t)) return a
        o++
      }
      return n
    }
    function k() {
      return gt++
    }
    function C(t) {
      var e = t.ownerDocument
      return e.defaultView || e.parentWindow
    }
    function S(t, e) {
      var i = this
      ;(this.manager = t),
        (this.callback = e),
        (this.element = t.element),
        (this.target = t.options.inputTarget),
        (this.domHandler = function(e) {
          d(t.options.enable, [t]) && i.handler(e)
        }),
        this.init()
    }
    function T(t) {
      var e,
        i = t.options.inputClass
      return new (e = i ? i : yt ? N : bt ? R : mt ? H : F)(t, O)
    }
    function O(t, e, i) {
      var n = i.pointers.length,
        a = i.changedPointers.length,
        r = e & Tt && 0 === n - a,
        o = e & (At | Pt) && 0 === n - a
      ;(i.isFirst = !!r),
        (i.isFinal = !!o),
        r && (t.session = {}),
        (i.eventType = e),
        A(t, i),
        t.emit("hammer.input", i),
        t.recognize(i),
        (t.session.prevInput = i)
    }
    function A(t, e) {
      var i = t.session,
        n = e.pointers,
        a = n.length
      i.firstInput || (i.firstInput = q(e)),
        a > 1 && !i.firstMultiple
          ? (i.firstMultiple = q(e))
          : 1 === a && (i.firstMultiple = !1)
      var r = i.firstInput,
        o = i.firstMultiple,
        s = o ? o.center : r.center,
        l = (e.center = _(n))
      ;(e.timeStamp = ht()),
        (e.deltaTime = e.timeStamp - r.timeStamp),
        (e.angle = j(s, l)),
        (e.distance = V(s, l)),
        P(i, e),
        (e.offsetDirection = M(e.deltaX, e.deltaY)),
        (e.scale = o ? Q(o.pointers, n) : 1),
        (e.rotation = o ? D(o.pointers, n) : 0),
        E(i, e)
      var u = t.element
      g(e.srcEvent.target, u) && (u = e.srcEvent.target), (e.target = u)
    }
    function P(t, e) {
      var i = e.center,
        n = t.offsetDelta || {},
        a = t.prevDelta || {},
        r = t.prevInput || {}
      ;(e.eventType === Tt || r.eventType === At) &&
        ((a = t.prevDelta = { x: r.deltaX || 0, y: r.deltaY || 0 }),
        (n = t.offsetDelta = { x: i.x, y: i.y })),
        (e.deltaX = a.x + (i.x - n.x)),
        (e.deltaY = a.y + (i.y - n.y))
    }
    function E(t, e) {
      var i,
        a,
        r,
        o,
        s = t.lastInterval || e,
        l = e.timeStamp - s.timeStamp
      if (e.eventType != Pt && (l > St || s.velocity === n)) {
        var u = s.deltaX - e.deltaX,
          c = s.deltaY - e.deltaY,
          d = I(l, u, c)
        ;(a = d.x),
          (r = d.y),
          (i = pt(d.x) > pt(d.y) ? d.x : d.y),
          (o = M(u, c)),
          (t.lastInterval = e)
      } else
        (i = s.velocity),
          (a = s.velocityX),
          (r = s.velocityY),
          (o = s.direction)
      ;(e.velocity = i), (e.velocityX = a), (e.velocityY = r), (e.direction = o)
    }
    function q(t) {
      for (var e = [], i = 0; i < t.pointers.length; )
        (e[i] = {
          clientX: ft(t.pointers[i].clientX),
          clientY: ft(t.pointers[i].clientY)
        }),
          i++
      return {
        timeStamp: ht(),
        pointers: e,
        center: _(e),
        deltaX: t.deltaX,
        deltaY: t.deltaY
      }
    }
    function _(t) {
      var e = t.length
      if (1 === e) return { x: ft(t[0].clientX), y: ft(t[0].clientY) }
      for (var i = 0, n = 0, a = 0; e > a; )
        (i += t[a].clientX), (n += t[a].clientY), a++
      return { x: ft(i / e), y: ft(n / e) }
    }
    function I(t, e, i) {
      return { x: e / t || 0, y: i / t || 0 }
    }
    function M(t, e) {
      return t === e ? Et : pt(t) >= pt(e) ? (t > 0 ? qt : _t) : e > 0 ? It : Mt
    }
    function V(t, e, i) {
      i || (i = $)
      var n = e[i[0]] - t[i[0]],
        a = e[i[1]] - t[i[1]]
      return Math.sqrt(n * n + a * a)
    }
    function j(t, e, i) {
      i || (i = $)
      var n = e[i[0]] - t[i[0]],
        a = e[i[1]] - t[i[1]]
      return (180 * Math.atan2(a, n)) / Math.PI
    }
    function D(t, e) {
      return j(e[1], e[0], $t) - j(t[1], t[0], $t)
    }
    function Q(t, e) {
      return V(e[0], e[1], $t) / V(t[0], t[1], $t)
    }
    function F() {
      ;(this.evEl = Ft),
        (this.evWin = Nt),
        (this.allow = !0),
        (this.pressed = !1),
        S.apply(this, arguments)
    }
    function N() {
      ;(this.evEl = Rt),
        (this.evWin = Wt),
        S.apply(this, arguments),
        (this.store = this.manager.session.pointerEvents = [])
    }
    function L() {
      ;(this.evTarget = Xt),
        (this.evWin = Yt),
        (this.started = !1),
        S.apply(this, arguments)
    }
    function z(t, e) {
      var i = b(t.touches),
        n = b(t.changedTouches)
      return e & (At | Pt) && (i = w(i.concat(n), "identifier", !0)), [i, n]
    }
    function R() {
      ;(this.evTarget = Gt), (this.targetIds = {}), S.apply(this, arguments)
    }
    function W(t, e) {
      var i = b(t.touches),
        n = this.targetIds
      if (e & (Tt | Ot) && 1 === i.length)
        return (n[i[0].identifier] = !0), [i, i]
      var a,
        r,
        o = b(t.changedTouches),
        s = [],
        l = this.target
      if (
        ((r = i.filter(function(t) {
          return g(t.target, l)
        })),
        e === Tt)
      )
        for (a = 0; a < r.length; ) (n[r[a].identifier] = !0), a++
      for (a = 0; a < o.length; )
        n[o[a].identifier] && s.push(o[a]),
          e & (At | Pt) && delete n[o[a].identifier],
          a++
      return s.length ? [w(r.concat(s), "identifier", !0), s] : void 0
    }
    function H() {
      S.apply(this, arguments)
      var t = c(this.handler, this)
      ;(this.touch = new R(this.manager, t)),
        (this.mouse = new F(this.manager, t))
    }
    function X(t, e) {
      ;(this.manager = t), this.set(e)
    }
    function Y(t) {
      if (v(t, ee)) return ee
      var e = v(t, ie),
        i = v(t, ne)
      return e && i
        ? ie + " " + ne
        : e || i
        ? e
          ? ie
          : ne
        : v(t, te)
        ? te
        : Kt
    }
    function B(t) {
      ;(this.id = k()),
        (this.manager = null),
        (this.options = l(t || {}, this.defaults)),
        (this.options.enable = f(this.options.enable, !0)),
        (this.state = ae),
        (this.simultaneous = {}),
        (this.requireFail = [])
    }
    function G(t) {
      return t & ue
        ? "cancel"
        : t & se
        ? "end"
        : t & oe
        ? "move"
        : t & re
        ? "start"
        : ""
    }
    function U(t) {
      return t == Mt
        ? "down"
        : t == It
        ? "up"
        : t == qt
        ? "left"
        : t == _t
        ? "right"
        : ""
    }
    function Z(t, e) {
      var i = e.manager
      return i ? i.get(t) : t
    }
    function J() {
      B.apply(this, arguments)
    }
    function K() {
      J.apply(this, arguments), (this.pX = null), (this.pY = null)
    }
    function tt() {
      J.apply(this, arguments)
    }
    function et() {
      B.apply(this, arguments), (this._timer = null), (this._input = null)
    }
    function it() {
      J.apply(this, arguments)
    }
    function nt() {
      J.apply(this, arguments)
    }
    function at() {
      B.apply(this, arguments),
        (this.pTime = !1),
        (this.pCenter = !1),
        (this._timer = null),
        (this._input = null),
        (this.count = 0)
    }
    function rt(t, e) {
      return (
        (e = e || {}),
        (e.recognizers = f(e.recognizers, rt.defaults.preset)),
        new ot(t, e)
      )
    }
    function ot(t, e) {
      ;(e = e || {}),
        (this.options = l(e, rt.defaults)),
        (this.options.inputTarget = this.options.inputTarget || t),
        (this.handlers = {}),
        (this.session = {}),
        (this.recognizers = []),
        (this.element = t),
        (this.input = T(this)),
        (this.touchAction = new X(this, this.options.touchAction)),
        st(this, !0),
        o(
          e.recognizers,
          function(t) {
            var e = this.add(new t[0](t[1]))
            t[2] && e.recognizeWith(t[2]), t[3] && e.requireFailure(t[3])
          },
          this
        )
    }
    function st(t, e) {
      var i = t.element
      o(t.options.cssProps, function(t, n) {
        i.style[x(i.style, n)] = e ? t : ""
      })
    }
    function lt(t, i) {
      var n = e.createEvent("Event")
      n.initEvent(t, !0, !0), (n.gesture = i), i.target.dispatchEvent(n)
    }
    var ut = ["", "webkit", "moz", "MS", "ms", "o"],
      ct = e.createElement("div"),
      dt = "function",
      ft = Math.round,
      pt = Math.abs,
      ht = Date.now,
      gt = 1,
      vt = /mobile|tablet|ip(ad|hone|od)|android/i,
      mt = "ontouchstart" in t,
      yt = x(t, "PointerEvent") !== n,
      bt = mt && vt.test(navigator.userAgent),
      wt = "touch",
      xt = "pen",
      kt = "mouse",
      Ct = "kinect",
      St = 25,
      Tt = 1,
      Ot = 2,
      At = 4,
      Pt = 8,
      Et = 1,
      qt = 2,
      _t = 4,
      It = 8,
      Mt = 16,
      Vt = qt | _t,
      jt = It | Mt,
      Dt = Vt | jt,
      $ = ["x", "y"],
      $t = ["clientX", "clientY"]
    S.prototype = {
      handler: function() {},
      init: function() {
        this.evEl && p(this.element, this.evEl, this.domHandler),
          this.evTarget && p(this.target, this.evTarget, this.domHandler),
          this.evWin && p(C(this.element), this.evWin, this.domHandler)
      },
      destroy: function() {
        this.evEl && h(this.element, this.evEl, this.domHandler),
          this.evTarget && h(this.target, this.evTarget, this.domHandler),
          this.evWin && h(C(this.element), this.evWin, this.domHandler)
      }
    }
    var Qt = { mousedown: Tt, mousemove: Ot, mouseup: At },
      Ft = "mousedown",
      Nt = "mousemove mouseup"
    u(F, S, {
      handler: function(t) {
        var e = Qt[t.type]
        e & Tt && 0 === t.button && (this.pressed = !0),
          e & Ot && 1 !== t.which && (e = At),
          this.pressed &&
            this.allow &&
            (e & At && (this.pressed = !1),
            this.callback(this.manager, e, {
              pointers: [t],
              changedPointers: [t],
              pointerType: kt,
              srcEvent: t
            }))
      }
    })
    var Lt = {
        pointerdown: Tt,
        pointermove: Ot,
        pointerup: At,
        pointercancel: Pt,
        pointerout: Pt
      },
      zt = { 2: wt, 3: xt, 4: kt, 5: Ct },
      Rt = "pointerdown",
      Wt = "pointermove pointerup pointercancel"
    t.MSPointerEvent &&
      ((Rt = "MSPointerDown"),
      (Wt = "MSPointerMove MSPointerUp MSPointerCancel")),
      u(N, S, {
        handler: function(t) {
          var e = this.store,
            i = !1,
            n = t.type.toLowerCase().replace("ms", ""),
            a = Lt[n],
            r = zt[t.pointerType] || t.pointerType,
            o = r == wt,
            s = y(e, t.pointerId, "pointerId")
          a & Tt && (0 === t.button || o)
            ? 0 > s && (e.push(t), (s = e.length - 1))
            : a & (At | Pt) && (i = !0),
            0 > s ||
              ((e[s] = t),
              this.callback(this.manager, a, {
                pointers: e,
                changedPointers: [t],
                pointerType: r,
                srcEvent: t
              }),
              i && e.splice(s, 1))
        }
      })
    var Ht = { touchstart: Tt, touchmove: Ot, touchend: At, touchcancel: Pt },
      Xt = "touchstart",
      Yt = "touchstart touchmove touchend touchcancel"
    u(L, S, {
      handler: function(t) {
        var e = Ht[t.type]
        if ((e === Tt && (this.started = !0), this.started)) {
          var i = z.call(this, t, e)
          e & (At | Pt) &&
            0 === i[0].length - i[1].length &&
            (this.started = !1),
            this.callback(this.manager, e, {
              pointers: i[0],
              changedPointers: i[1],
              pointerType: wt,
              srcEvent: t
            })
        }
      }
    })
    var Bt = { touchstart: Tt, touchmove: Ot, touchend: At, touchcancel: Pt },
      Gt = "touchstart touchmove touchend touchcancel"
    u(R, S, {
      handler: function(t) {
        var e = Bt[t.type],
          i = W.call(this, t, e)
        i &&
          this.callback(this.manager, e, {
            pointers: i[0],
            changedPointers: i[1],
            pointerType: wt,
            srcEvent: t
          })
      }
    }),
      u(H, S, {
        handler: function(t, e, i) {
          var n = i.pointerType == wt,
            a = i.pointerType == kt
          if (n) this.mouse.allow = !1
          else if (a && !this.mouse.allow) return
          e & (At | Pt) && (this.mouse.allow = !0), this.callback(t, e, i)
        },
        destroy: function() {
          this.touch.destroy(), this.mouse.destroy()
        }
      })
    var Ut = x(ct.style, "touchAction"),
      Zt = Ut !== n,
      Jt = "compute",
      Kt = "auto",
      te = "manipulation",
      ee = "none",
      ie = "pan-x",
      ne = "pan-y"
    X.prototype = {
      set: function(t) {
        t == Jt && (t = this.compute()),
          Zt && (this.manager.element.style[Ut] = t),
          (this.actions = t.toLowerCase().trim())
      },
      update: function() {
        this.set(this.manager.options.touchAction)
      },
      compute: function() {
        var t = []
        return (
          o(this.manager.recognizers, function(e) {
            d(e.options.enable, [e]) && (t = t.concat(e.getTouchAction()))
          }),
          Y(t.join(" "))
        )
      },
      preventDefaults: function(t) {
        if (!Zt) {
          var e = t.srcEvent,
            i = t.offsetDirection
          if (this.manager.session.prevented) return void e.preventDefault()
          var n = this.actions,
            a = v(n, ee),
            r = v(n, ne),
            o = v(n, ie)
          return a || (r && i & Vt) || (o && i & jt)
            ? this.preventSrc(e)
            : void 0
        }
      },
      preventSrc: function(t) {
        ;(this.manager.session.prevented = !0), t.preventDefault()
      }
    }
    var ae = 1,
      re = 2,
      oe = 4,
      se = 8,
      le = se,
      ue = 16,
      ce = 32
    ;(B.prototype = {
      defaults: {},
      set: function(t) {
        return (
          s(this.options, t),
          this.manager && this.manager.touchAction.update(),
          this
        )
      },
      recognizeWith: function(t) {
        if (r(t, "recognizeWith", this)) return this
        var e = this.simultaneous
        return (
          (t = Z(t, this)),
          e[t.id] || ((e[t.id] = t), t.recognizeWith(this)),
          this
        )
      },
      dropRecognizeWith: function(t) {
        return r(t, "dropRecognizeWith", this)
          ? this
          : ((t = Z(t, this)), delete this.simultaneous[t.id], this)
      },
      requireFailure: function(t) {
        if (r(t, "requireFailure", this)) return this
        var e = this.requireFail
        return (
          (t = Z(t, this)),
          -1 === y(e, t) && (e.push(t), t.requireFailure(this)),
          this
        )
      },
      dropRequireFailure: function(t) {
        if (r(t, "dropRequireFailure", this)) return this
        t = Z(t, this)
        var e = y(this.requireFail, t)
        return e > -1 && this.requireFail.splice(e, 1), this
      },
      hasRequireFailures: function() {
        return this.requireFail.length > 0
      },
      canRecognizeWith: function(t) {
        return !!this.simultaneous[t.id]
      },
      emit: function(t) {
        function e(e) {
          i.manager.emit(i.options.event + (e ? G(n) : ""), t)
        }
        var i = this,
          n = this.state
        se > n && e(!0), e(), n >= se && e(!0)
      },
      tryEmit: function(t) {
        return this.canEmit() ? this.emit(t) : void (this.state = ce)
      },
      canEmit: function() {
        for (var t = 0; t < this.requireFail.length; ) {
          if (!(this.requireFail[t].state & (ce | ae))) return !1
          t++
        }
        return !0
      },
      recognize: function(t) {
        var e = s({}, t)
        return d(this.options.enable, [this, e])
          ? (this.state & (le | ue | ce) && (this.state = ae),
            (this.state = this.process(e)),
            void (this.state & (re | oe | se | ue) && this.tryEmit(e)))
          : (this.reset(), void (this.state = ce))
      },
      process: function() {},
      getTouchAction: function() {},
      reset: function() {}
    }),
      u(J, B, {
        defaults: { pointers: 1 },
        attrTest: function(t) {
          var e = this.options.pointers
          return 0 === e || t.pointers.length === e
        },
        process: function(t) {
          var e = this.state,
            i = t.eventType,
            n = e & (re | oe),
            a = this.attrTest(t)
          return n && (i & Pt || !a)
            ? e | ue
            : n || a
            ? i & At
              ? e | se
              : e & re
              ? e | oe
              : re
            : ce
        }
      }),
      u(K, J, {
        defaults: { event: "pan", threshold: 10, pointers: 1, direction: Dt },
        getTouchAction: function() {
          var t = this.options.direction,
            e = []
          return t & Vt && e.push(ne), t & jt && e.push(ie), e
        },
        directionTest: function(t) {
          var e = this.options,
            i = !0,
            n = t.distance,
            a = t.direction,
            r = t.deltaX,
            o = t.deltaY
          return (
            a & e.direction ||
              (e.direction & Vt
                ? ((a = 0 === r ? Et : 0 > r ? qt : _t),
                  (i = r != this.pX),
                  (n = Math.abs(t.deltaX)))
                : ((a = 0 === o ? Et : 0 > o ? It : Mt),
                  (i = o != this.pY),
                  (n = Math.abs(t.deltaY)))),
            (t.direction = a),
            i && n > e.threshold && a & e.direction
          )
        },
        attrTest: function(t) {
          return (
            J.prototype.attrTest.call(this, t) &&
            (this.state & re || (!(this.state & re) && this.directionTest(t)))
          )
        },
        emit: function(t) {
          ;(this.pX = t.deltaX), (this.pY = t.deltaY)
          var e = U(t.direction)
          e && this.manager.emit(this.options.event + e, t),
            this._super.emit.call(this, t)
        }
      }),
      u(tt, J, {
        defaults: { event: "pinch", threshold: 0, pointers: 2 },
        getTouchAction: function() {
          return [ee]
        },
        attrTest: function(t) {
          return (
            this._super.attrTest.call(this, t) &&
            (Math.abs(t.scale - 1) > this.options.threshold || this.state & re)
          )
        },
        emit: function(t) {
          if ((this._super.emit.call(this, t), 1 !== t.scale)) {
            var e = t.scale < 1 ? "in" : "out"
            this.manager.emit(this.options.event + e, t)
          }
        }
      }),
      u(et, B, {
        defaults: { event: "press", pointers: 1, time: 500, threshold: 5 },
        getTouchAction: function() {
          return [Kt]
        },
        process: function(t) {
          var e = this.options,
            i = t.pointers.length === e.pointers,
            n = t.distance < e.threshold,
            r = t.deltaTime > e.time
          if (((this._input = t), !n || !i || (t.eventType & (At | Pt) && !r)))
            this.reset()
          else if (t.eventType & Tt)
            this.reset(),
              (this._timer = a(
                function() {
                  ;(this.state = le), this.tryEmit()
                },
                e.time,
                this
              ))
          else if (t.eventType & At) return le
          return ce
        },
        reset: function() {
          clearTimeout(this._timer)
        },
        emit: function(t) {
          this.state === le &&
            (t && t.eventType & At
              ? this.manager.emit(this.options.event + "up", t)
              : ((this._input.timeStamp = ht()),
                this.manager.emit(this.options.event, this._input)))
        }
      }),
      u(it, J, {
        defaults: { event: "rotate", threshold: 0, pointers: 2 },
        getTouchAction: function() {
          return [ee]
        },
        attrTest: function(t) {
          return (
            this._super.attrTest.call(this, t) &&
            (Math.abs(t.rotation) > this.options.threshold || this.state & re)
          )
        }
      }),
      u(nt, J, {
        defaults: {
          event: "swipe",
          threshold: 10,
          velocity: 0.65,
          direction: Vt | jt,
          pointers: 1
        },
        getTouchAction: function() {
          return K.prototype.getTouchAction.call(this)
        },
        attrTest: function(t) {
          var e,
            i = this.options.direction
          return (
            i & (Vt | jt)
              ? (e = t.velocity)
              : i & Vt
              ? (e = t.velocityX)
              : i & jt && (e = t.velocityY),
            this._super.attrTest.call(this, t) &&
              i & t.direction &&
              t.distance > this.options.threshold &&
              pt(e) > this.options.velocity &&
              t.eventType & At
          )
        },
        emit: function(t) {
          var e = U(t.direction)
          e && this.manager.emit(this.options.event + e, t),
            this.manager.emit(this.options.event, t)
        }
      }),
      u(at, B, {
        defaults: {
          event: "tap",
          pointers: 1,
          taps: 1,
          interval: 300,
          time: 250,
          threshold: 2,
          posThreshold: 10
        },
        getTouchAction: function() {
          return [te]
        },
        process: function(t) {
          var e = this.options,
            i = t.pointers.length === e.pointers,
            n = t.distance < e.threshold,
            r = t.deltaTime < e.time
          if ((this.reset(), t.eventType & Tt && 0 === this.count))
            return this.failTimeout()
          if (n && r && i) {
            if (t.eventType != At) return this.failTimeout()
            var o = this.pTime ? t.timeStamp - this.pTime < e.interval : !0,
              s = !this.pCenter || V(this.pCenter, t.center) < e.posThreshold
            ;(this.pTime = t.timeStamp),
              (this.pCenter = t.center),
              s && o ? (this.count += 1) : (this.count = 1),
              (this._input = t)
            var l = this.count % e.taps
            if (0 === l)
              return this.hasRequireFailures()
                ? ((this._timer = a(
                    function() {
                      ;(this.state = le), this.tryEmit()
                    },
                    e.interval,
                    this
                  )),
                  re)
                : le
          }
          return ce
        },
        failTimeout: function() {
          return (
            (this._timer = a(
              function() {
                this.state = ce
              },
              this.options.interval,
              this
            )),
            ce
          )
        },
        reset: function() {
          clearTimeout(this._timer)
        },
        emit: function() {
          this.state == le &&
            ((this._input.tapCount = this.count),
            this.manager.emit(this.options.event, this._input))
        }
      }),
      (rt.VERSION = "2.0.4"),
      (rt.defaults = {
        domEvents: !1,
        touchAction: Jt,
        enable: !0,
        inputTarget: null,
        inputClass: null,
        preset: [
          [it, { enable: !1 }],
          [tt, { enable: !1 }, ["rotate"]],
          [nt, { direction: Vt }],
          [K, { direction: Vt }, ["swipe"]],
          [at],
          [at, { event: "doubletap", taps: 2 }, ["tap"]],
          [et]
        ],
        cssProps: {
          userSelect: "default",
          touchSelect: "none",
          touchCallout: "none",
          contentZooming: "none",
          userDrag: "none",
          tapHighlightColor: "rgba(0,0,0,0)"
        }
      })
    var de = 1,
      fe = 2
    ;(ot.prototype = {
      set: function(t) {
        return (
          s(this.options, t),
          t.touchAction && this.touchAction.update(),
          t.inputTarget &&
            (this.input.destroy(),
            (this.input.target = t.inputTarget),
            this.input.init()),
          this
        )
      },
      stop: function(t) {
        this.session.stopped = t ? fe : de
      },
      recognize: function(t) {
        var e = this.session
        if (!e.stopped) {
          this.touchAction.preventDefaults(t)
          var i,
            n = this.recognizers,
            a = e.curRecognizer
          ;(!a || (a && a.state & le)) && (a = e.curRecognizer = null)
          for (var r = 0; r < n.length; )
            (i = n[r]),
              e.stopped === fe || (a && i != a && !i.canRecognizeWith(a))
                ? i.reset()
                : i.recognize(t),
              !a && i.state & (re | oe | se) && (a = e.curRecognizer = i),
              r++
        }
      },
      get: function(t) {
        if (t instanceof B) return t
        for (var e = this.recognizers, i = 0; i < e.length; i++)
          if (e[i].options.event == t) return e[i]
        return null
      },
      add: function(t) {
        if (r(t, "add", this)) return this
        var e = this.get(t.options.event)
        return (
          e && this.remove(e),
          this.recognizers.push(t),
          (t.manager = this),
          this.touchAction.update(),
          t
        )
      },
      remove: function(t) {
        if (r(t, "remove", this)) return this
        var e = this.recognizers
        return (
          (t = this.get(t)),
          e.splice(y(e, t), 1),
          this.touchAction.update(),
          this
        )
      },
      on: function(t, e) {
        var i = this.handlers
        return (
          o(m(t), function(t) {
            ;(i[t] = i[t] || []), i[t].push(e)
          }),
          this
        )
      },
      off: function(t, e) {
        var i = this.handlers
        return (
          o(m(t), function(t) {
            e ? i[t].splice(y(i[t], e), 1) : delete i[t]
          }),
          this
        )
      },
      emit: function(t, e) {
        this.options.domEvents && lt(t, e)
        var i = this.handlers[t] && this.handlers[t].slice()
        if (i && i.length) {
          ;(e.type = t),
            (e.preventDefault = function() {
              e.srcEvent.preventDefault()
            })
          for (var n = 0; n < i.length; ) i[n](e), n++
        }
      },
      destroy: function() {
        this.element && st(this, !1),
          (this.handlers = {}),
          (this.session = {}),
          this.input.destroy(),
          (this.element = null)
      }
    }),
      s(rt, {
        INPUT_START: Tt,
        INPUT_MOVE: Ot,
        INPUT_END: At,
        INPUT_CANCEL: Pt,
        STATE_POSSIBLE: ae,
        STATE_BEGAN: re,
        STATE_CHANGED: oe,
        STATE_ENDED: se,
        STATE_RECOGNIZED: le,
        STATE_CANCELLED: ue,
        STATE_FAILED: ce,
        DIRECTION_NONE: Et,
        DIRECTION_LEFT: qt,
        DIRECTION_RIGHT: _t,
        DIRECTION_UP: It,
        DIRECTION_DOWN: Mt,
        DIRECTION_HORIZONTAL: Vt,
        DIRECTION_VERTICAL: jt,
        DIRECTION_ALL: Dt,
        Manager: ot,
        Input: S,
        TouchAction: X,
        TouchInput: R,
        MouseInput: F,
        PointerEventInput: N,
        TouchMouseInput: H,
        SingleTouchInput: L,
        Recognizer: B,
        AttrRecognizer: J,
        Tap: at,
        Pan: K,
        Swipe: nt,
        Pinch: tt,
        Rotate: it,
        Press: et,
        on: p,
        off: h,
        each: o,
        merge: l,
        extend: s,
        inherit: u,
        bindFn: c,
        prefixed: x
      }),
      typeof define == dt && define.amd
        ? define(function() {
            return rt
          })
        : "undefined" != typeof module && module.exports
        ? (module.exports = rt)
        : (t[i] = rt)
  })(window, document, "Hammer"),
  (function(t) {
    "function" == typeof define && define.amd
      ? define(["jquery", "hammerjs"], t)
      : "object" == typeof exports
      ? t(require("jquery"), require("hammerjs"))
      : t(jQuery, Hammer)
  })(function($, Hammer) {
    function t(t, e) {
      var i = $(t)
      i.data("hammer") || i.data("hammer", new Hammer(i[0], e))
    }
    ;($.fn.hammer = function(e) {
      return this.each(function() {
        t(this, e)
      })
    }),
      (Hammer.Manager.prototype.emit = (function(t) {
        return function(e, i) {
          t.call(this, e, i), $(this.element).trigger({ type: e, gesture: i })
        }
      })(Hammer.Manager.prototype.emit))
  }),
  (function($) {
    ;($.fn.collapsible = function(t) {
      var e = { accordion: void 0 }
      return (
        (t = $.extend(e, t)),
        this.each(function() {
          function e(t) {
            ;(a = n.find(".collapsible-header")),
              t.parent().toggleClass("active"),
              t.parent().hasClass("active")
                ? t
                    .siblings(".collapsible-body")
                    .stop(!0, !1)
                    .slideDown({
                      duration: 350,
                      easing: "easeOutQuart",
                      queue: !1
                    })
                : t
                    .siblings(".collapsible-body")
                    .stop(!0, !1)
                    .slideUp({
                      duration: 350,
                      easing: "easeOutQuart",
                      queue: !1
                    }),
              a
                .not(t)
                .parent()
                .removeClass("active"),
              a
                .not(t)
                .parent()
                .children(".collapsible-body")
                .stop(!0, !1)
                .slideUp({ duration: 350, easing: "easeOutQuart", queue: !1 })
          }
          function i(t) {
            t.parent().toggleClass("active"),
              t.parent().hasClass("active")
                ? t
                    .siblings(".collapsible-body")
                    .stop(!0, !1)
                    .slideDown({
                      duration: 350,
                      easing: "easeOutQuart",
                      queue: !1
                    })
                : t
                    .siblings(".collapsible-body")
                    .stop(!0, !1)
                    .slideUp({
                      duration: 350,
                      easing: "easeOutQuart",
                      queue: !1
                    })
          }
          var n = $(this),
            a = $(this).find(".collapsible-header"),
            r = n.data("collapsible")
          n.off("click.collapse", ".collapsible-header"),
            a.off("click.collapse"),
            t.accordion || "accordion" == r || void 0 == r
              ? (n.on("click.collapse", ".collapsible-header", function(t) {
                  e($(t.currentTarget))
                }),
                e(a.filter(".active").first()))
              : a.each(function() {
                  $(this).on("click.collapse", function(t) {
                    i($(t.currentTarget))
                  }),
                    $(this).hasClass("active") && i($(this))
                })
        })
      )
    }),
      $(document).ready(function() {
        $(".collapsible").collapsible()
      })
  })(jQuery),
  (function($) {
    ;($.fn.scrollTo = function(t) {
      return (
        $(this).scrollTop(
          $(this).scrollTop() - $(this).offset().top + $(t).offset().top
        ),
        this
      )
    }),
      ($.fn.dropdown = function(t) {
        var e = {
          inDuration: 300,
          outDuration: 225,
          constrain_width: !0,
          hover: !0,
          alignment: "left",
          gutter: 0,
          belowOrigin: !1
        }
        ;(t = $.extend(e, t)),
          this.each(function() {
            function e() {
              void 0 != r.data("inDuration") &&
                (t.inDuration = r.data("inDuration")),
                void 0 != r.data("outDuration") &&
                  (t.outDuration = r.data("outDuration")),
                void 0 != r.data("constrainwidth") &&
                  (t.constrain_width = r.data("constrainwidth")),
                void 0 != r.data("hover") && (t.hover = r.data("hover")),
                void 0 != r.data("alignment") &&
                  (t.alignment = r.data("alignment")),
                void 0 != r.data("gutter") && (t.gutter = r.data("gutter")),
                void 0 != r.data("beloworigin") &&
                  (t.belowOrigin = r.data("beloworigin"))
            }
            function i() {
              e(), 1 == t.constrain_width && o.css("width", r.outerWidth())
              var i = 0
              1 == t.belowOrigin && (i = r.height())
              var a = 0,
                l = t.gutter
              "right" == t.alignment &&
                ((a = r.innerWidth() - o.innerWidth()), (l = -1 * l)),
                o.css(
                  n(r[0])
                    ? {
                        display: "block",
                        position: "fixed",
                        height: 0,
                        top: r.offset().top - $(window).scrollTop() + i,
                        left: r.offset().left + a + l
                      }
                    : {
                        display: "block",
                        top: r.offset().top + i,
                        left: r.offset().left + a + l,
                        height: 0
                      }
                ),
                o
                  .velocity(
                    { opacity: 1 },
                    { duration: t.inDuration, queue: !1, easing: "easeOutQuad" }
                  )
                  .velocity(
                    { height: s },
                    {
                      duration: t.inDuration,
                      queue: !1,
                      easing: "easeOutCubic",
                      complete: function() {
                        o.css("overflow-y", "auto")
                      }
                    }
                  )
            }
            function n(t) {
              var e = $(t),
                i = e.add(e.parents()),
                n = !1
              return (
                i.each(function() {
                  return "fixed" === $(this).css("position")
                    ? ((n = !0), !1)
                    : void 0
                }),
                n
              )
            }
            function a() {
              o.velocity(
                { opacity: 0 },
                {
                  duration: t.outDuration,
                  easing: "easeOutQuad",
                  complete: function() {
                    o.css({ display: "none", "overflow-y": "" })
                  }
                }
              )
            }
            var r = $(this),
              o = $("#" + r.attr("data-activates"))
            e(), o.parent().is($("body")) || (o.detach(), $("body").append(o))
            var s = o.height()
            if (t.hover)
              r.on("mouseover", function(t) {
                i()
              }),
                o.on("mouseleave", function(t) {
                  a()
                })
            else {
              r.unbind("click." + r.attr("id")),
                r.bind("click." + r.attr("id"), function(t) {
                  r[0] == t.currentTarget && (t.preventDefault(), i()),
                    $(document).bind("click." + o.attr("id"), function(t) {
                      !o.is(t.target) &&
                        !r.is(t.target) &&
                        !r.find(t.target).length > 0 &&
                        (a(), $(document).unbind("click." + o.attr("id")))
                    })
                })
            }
            r.on("open", i), r.on("close", a)
          })
      })
  })(jQuery),
  (function($) {
    $.fn.extend({
      openModal: function(t) {
        var e = this,
          i = $('<div id="lean-overlay"></div>')
        $("body").append(i)
        var n = {
          opacity: 0.5,
          in_duration: 300,
          out_duration: 200,
          ready: void 0,
          complete: void 0,
          dismissible: !0
        }
        ;(t = $.extend(n, t)),
          t.dismissible &&
            ($("#lean-overlay").click(function() {
              $(e).closeModal(t)
            }),
            $(document).keyup(function(i) {
              27 === i.keyCode && ($(e).closeModal(t), $(this).off())
            })),
          $(e)
            .find(".modal-close")
            .click(function(i) {
              i.preventDefault(), $(e).closeModal(t)
            }),
          $("#lean-overlay").css({ display: "block", opacity: 0 }),
          $(e).css({ display: "block", top: "4%", opacity: 0 }),
          $("#lean-overlay").velocity(
            { opacity: t.opacity },
            { duration: t.in_duration, queue: !1, ease: "easeOutCubic" }
          ),
          $(e).velocity(
            { top: "10%", opacity: 1 },
            {
              duration: t.in_duration,
              queue: !1,
              ease: "easeOutCubic",
              complete: function() {
                "function" == typeof t.ready && t.ready()
              }
            }
          )
      }
    }),
      $.fn.extend({
        closeModal: function(t) {
          var e = { out_duration: 200, complete: void 0 },
            t = $.extend(e, t)
          $(".modal-close").off(),
            $("#lean-overlay").velocity(
              { opacity: 0 },
              { duration: t.out_duration, queue: !1, ease: "easeOutQuart" }
            ),
            $(this).fadeOut(t.out_duration, function() {
              $(this).css({ top: 0 }),
                $("#lean-overlay").css({ display: "none" }),
                "function" == typeof t.complete && t.complete(),
                $("#lean-overlay").remove()
            })
        }
      }),
      $.fn.extend({
        leanModal: function(t) {
          return this.each(function() {
            $(this).click(function(e) {
              var i = $(this).attr("href")
              $(i).openModal(t), e.preventDefault()
            })
          })
        }
      })
  })(jQuery),
  (function($) {
    ;($.fn.materialbox = function() {
      return this.each(function() {
        function t() {
          i = !1
          var t = r.parent(".material-placeholder"),
            n = (window.innerWidth, window.innerHeight, r.data("width")),
            o = r.data("height")
          $("#materialbox-overlay").fadeOut(a, function() {
            ;(e = !1), $(this).remove()
          }),
            r.velocity(
              { width: n, height: o, left: 0, top: 0 },
              { duration: a, queue: !1, easing: "easeOutQuad" }
            ),
            $(".materialbox-caption").velocity(
              { opacity: 0 },
              {
                duration: a + 200,
                queue: !1,
                easing: "easeOutQuad",
                complete: function() {
                  t.css({
                    height: "",
                    width: "",
                    position: "",
                    top: "",
                    left: ""
                  }),
                    r.css({
                      height: "",
                      top: "",
                      left: "",
                      width: "",
                      "max-width": "",
                      position: "",
                      "z-index": ""
                    }),
                    r.removeClass("active"),
                    (i = !0),
                    $(this).remove()
                }
              }
            )
        }
        if (!$(this).hasClass("intialized")) {
          $(this).addClass("intialized")
          var e = !1,
            i = !0,
            n = 275,
            a = 200,
            r = $(this),
            o = $("<div></div>").addClass("material-placeholder")
          r.wrap(o),
            r.on("click", function() {
              var a = r.parent(".material-placeholder"),
                o = window.innerWidth,
                s = window.innerHeight,
                l = r.width(),
                u = r.height()
              if (i === !1) return !1
              if (e && i === !0) return t(), !1
              ;(i = !1),
                r.addClass("active"),
                (e = !0),
                a.css({
                  width: a[0].getBoundingClientRect().width,
                  height: a[0].getBoundingClientRect().height,
                  position: "relative",
                  top: 0,
                  left: 0
                }),
                r
                  .css({ position: "absolute", "z-index": 1e3 })
                  .data("width", l)
                  .data("height", u)
              var c = $('<div id="materialbox-overlay"></div>')
                .css({ opacity: 0 })
                .click(function() {
                  i === !0 && t()
                })
              if (
                ($("body").append(c),
                c.velocity(
                  { opacity: 1 },
                  { duration: n, queue: !1, easing: "easeOutQuad" }
                ),
                "" !== r.data("caption"))
              ) {
                var d = $('<div class="materialbox-caption"></div>')
                d.text(r.data("caption")),
                  $("body").append(d),
                  d.css({ display: "inline" }),
                  d.velocity(
                    { opacity: 1 },
                    { duration: n, queue: !1, easing: "easeOutQuad" }
                  )
              }
              var f = 0,
                p = l / o,
                h = u / s,
                g = 0,
                v = 0

              p > h
                ? ((f = u / l), (g = 0.9 * o), (v = 0.9 * o * f))
                : ((f = l / u), (g = 0.9 * s * f), (v = 0.9 * s)),
                r.hasClass("responsive-img")
                  ? r.velocity(
                      { "max-width": g, width: l },
                      {
                        duration: 0,
                        queue: !1,
                        complete: function() {
                          r.css({ left: 0, top: 0 }).velocity(
                            {
                              height: v,
                              width: g,
                              left:
                                $(document).scrollLeft() +
                                o / 2 -
                                r.parent(".material-placeholder").offset()
                                  .left -
                                g / 2,
                              top:
                                $(document).scrollTop() +
                                s / 2 -
                                r.parent(".material-placeholder").offset().top -
                                v / 2
                            },
                            {
                              duration: n,
                              queue: !1,
                              easing: "easeOutQuad",
                              complete: function() {
                                i = !0
                              }
                            }
                          )
                        }
                      }
                    )
                  : r
                      .css("left", 0)
                      .css("top", 0)
                      .velocity(
                        {
                          height: v,
                          width: g,
                          left:
                            $(document).scrollLeft() +
                            o / 2 -
                            r.parent(".material-placeholder").offset().left -
                            g / 2,
                          top:
                            $(document).scrollTop() +
                            s / 2 -
                            r.parent(".material-placeholder").offset().top -
                            v / 2
                        },
                        {
                          duration: n,
                          queue: !1,
                          easing: "easeOutQuad",
                          complete: function() {
                            i = !0
                          }
                        }
                      )
            }),
            $(window).scroll(function() {
              e && t()
            }),
            $(document).keyup(function(n) {
              27 === n.keyCode && i === !0 && e && t()
            })
        }
      })
    }),
      $(document).ready(function() {
        $(".materialboxed").materialbox()
      })
  })(jQuery),
  (function($) {
    $.fn.parallax = function() {
      var t = $(window).width()
      return this.each(function(e) {
        function i(e) {
          var i
          i =
            992 > t
              ? n.height() > 0
                ? n.height()
                : n.children("img").height()
              : n.height() > 0
              ? n.height()
              : 500
          var a = n.children("img").height(),
            r = a - i,
            o = n.offset().top + i,
            s = n.offset().top,
            l = $(window).scrollTop(),
            u = window.innerHeight,
            c = l + u,
            d = (c - s) / (i + u),
            f = -1 * r * d
          o > l &&
            l + u > s &&
            n
              .children("img")
              .first()
              .css("bottom", f + "px"),
            e &&
              n
                .children("img")
                .first()
                .css("display", "block")
        }
        var n = $(this)
        n.addClass("parallax"),
          n.find("img").each(function() {
            $(this).css("background-image", "url(" + $(this).attr("src") + ")"),
              $(this).attr(
                "src",
                "data:image/gif;base64,R0lGODlhAQABAIABAP///wAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              )
          }),
          n
            .children("img")
            .one("load", function() {
              i(!0)
            })
            .each(function() {
              this.complete && $(this).load()
            }),
          $(window).scroll(function() {
            ;(t = $(window).width()), i(!1)
          }),
          $(window).resize(function() {
            ;(t = $(window).width()), i(!1)
          })
      })
    }
  })(jQuery),
  (function($) {
    var t = {
      init: function() {
        return this.each(function() {
          {
            var t = $(this)
            $(window).width()
          }
          t.width("100%")
          var e = $(this).children("li").length
          t.children("li").each(function() {
            $(this).width(100 / e + "%")
          })
          var i,
            n,
            a = t.find("li.tab a"),
            r = t.width(),
            o = t
              .find("li")
              .first()
              .outerWidth(),
            s = 0
          ;(i = $(a.filter('[href="' + location.hash + '"]'))),
            0 === i.length &&
              (i = $(this)
                .find("li.tab a.active")
                .first()),
            0 === i.length &&
              (i = $(this)
                .find("li.tab a")
                .first()),
            i.addClass("active"),
            (s = a.index(i)),
            0 > s && (s = 0),
            (n = $(i[0].hash)),
            t.append('<div class="indicator"></div>')
          var l = t.find(".indicator")
          t.is(":visible") &&
            (l.css({ right: r - (s + 1) * o }), l.css({ left: s * o })),
            $(window).resize(function() {
              ;(r = t.width()),
                (o = t
                  .find("li")
                  .first()
                  .outerWidth()),
                0 > s && (s = 0),
                0 !== o &&
                  0 !== r &&
                  (l.css({ right: r - (s + 1) * o }), l.css({ left: s * o }))
            }),
            a.not(i).each(function() {
              $(this.hash).hide()
            }),
            t.on("click", "a", function(e) {
              ;(r = t.width()),
                (o = t
                  .find("li")
                  .first()
                  .outerWidth()),
                i.removeClass("active"),
                n.hide(),
                (i = $(this)),
                (n = $(this.hash)),
                (a = t.find("li.tab a")),
                i.addClass("active")
              var u = s
              ;(s = a.index($(this))),
                0 > s && (s = 0),
                n.show(),
                s - u >= 0
                  ? (l.velocity(
                      { right: r - (s + 1) * o },
                      { duration: 300, queue: !1, easing: "easeOutQuad" }
                    ),
                    l.velocity(
                      { left: s * o },
                      {
                        duration: 300,
                        queue: !1,
                        easing: "easeOutQuad",
                        delay: 90
                      }
                    ))
                  : (l.velocity(
                      { left: s * o },
                      { duration: 300, queue: !1, easing: "easeOutQuad" }
                    ),
                    l.velocity(
                      { right: r - (s + 1) * o },
                      {
                        duration: 300,
                        queue: !1,
                        easing: "easeOutQuad",
                        delay: 90
                      }
                    )),
                e.preventDefault()
            })
        })
      },
      select_tab: function(t) {
        this.find('a[href="#' + t + '"]').trigger("click")
      }
    }
    ;($.fn.tabs = function(e) {
      return t[e]
        ? t[e].apply(this, Array.prototype.slice.call(arguments, 1))
        : "object" != typeof e && e
        ? void $.error("Method " + e + " does not exist on jQuery.tooltip")
        : t.init.apply(this, arguments)
    }),
      $(document).ready(function() {
        $("ul.tabs").tabs()
      })
  })(jQuery),
  (function($) {
    ;($.fn.tooltip = function(t) {
      var e = null,
        i = !1,
        n = null,
        a = 5,
        r = { delay: 350 }
      return (
        (t = $.extend(r, t)),
        $(".material-tooltip").remove(),
        this.each(function() {
          var r = $(this),
            o = $("<span></span>").text(r.attr("data-tooltip")),
            s = $("<div></div>")
          s.addClass("material-tooltip").append(o), s.appendTo($("body"))
          var l = $("<div></div>").addClass("backdrop")
          l.appendTo(s),
            l.css({ top: 0, left: 0 }),
            $(this).off("mouseenter mouseleave"),
            $(this).on({
              mouseenter: function(o) {
                var u = r.data("delay")
                ;(u = void 0 == u || "" == u ? t.delay : u),
                  (e = 0),
                  (n = setInterval(function() {
                    if (((e += 10), e >= u && 0 == i)) {
                      ;(i = !0),
                        s.css({ display: "block", left: "0px", top: "0px" }),
                        s.children("span").text(r.attr("data-tooltip"))
                      var t = r.outerWidth(),
                        n = r.outerHeight(),
                        o = r.attr("data-position"),
                        c = s.outerHeight(),
                        d = s.outerWidth(),
                        f = "0px",
                        p = "0px",
                        h = 8
                      "top" === o
                        ? (s.css({
                            top: r.offset().top - c - a,
                            left: r.offset().left + t / 2 - d / 2
                          }),
                          (f = "-10px"),
                          l.css({
                            borderRadius: "14px 14px 0 0",
                            transformOrigin: "50% 90%",
                            marginTop: c,
                            marginLeft: d / 2 - l.width() / 2
                          }))
                        : "left" === o
                        ? (s.css({
                            top: r.offset().top + n / 2 - c / 2,
                            left: r.offset().left - d - a
                          }),
                          (p = "-10px"),
                          l.css({
                            width: "14px",
                            height: "14px",
                            borderRadius: "14px 0 0 14px",
                            transformOrigin: "95% 50%",
                            marginTop: c / 2,
                            marginLeft: d
                          }))
                        : "right" === o
                        ? (s.css({
                            top: r.offset().top + n / 2 - c / 2,
                            left: r.offset().left + t + a
                          }),
                          (p = "+10px"),
                          l.css({
                            width: "14px",
                            height: "14px",
                            borderRadius: "0 14px 14px 0",
                            transformOrigin: "5% 50%",
                            marginTop: c / 2,
                            marginLeft: "0px"
                          }))
                        : (s.css({
                            top: r.offset().top + r.outerHeight() + a,
                            left: r.offset().left + t / 2 - d / 2
                          }),
                          (f = "+10px"),
                          l.css({ marginLeft: d / 2 - l.width() / 2 })),
                        (h = d / 8),
                        8 > h && (h = 8),
                        ("right" === o || "left" === o) &&
                          ((h = d / 10), 6 > h && (h = 6)),
                        s.velocity(
                          { opacity: 1, marginTop: f, marginLeft: p },
                          { duration: 350, queue: !1 }
                        ),
                        l
                          .css({ display: "block" })
                          .velocity(
                            { opacity: 1 },
                            { duration: 55, delay: 0, queue: !1 }
                          )
                          .velocity(
                            { scale: h },
                            {
                              duration: 300,
                              delay: 0,
                              queue: !1,
                              easing: "easeInOutQuad"
                            }
                          )
                    }
                  }, 10))
              },
              mouseleave: function() {
                clearInterval(n),
                  (e = 0),
                  s.velocity(
                    { opacity: 0, marginTop: 0, marginLeft: 0 },
                    { duration: 225, queue: !1, delay: 275 }
                  ),
                  l.velocity(
                    { opacity: 0, scale: 1 },
                    {
                      duration: 225,
                      delay: 275,
                      queue: !1,
                      complete: function() {
                        l.css("display", "none"),
                          s.css("display", "none"),
                          (i = !1)
                      }
                    }
                  )
              }
            })
        })
      )
    }),
      $(document).ready(function() {
        $(".tooltipped").tooltip()
      })
  })(jQuery),
  (function(t) {
    "use strict"
    function e(t) {
      return null !== t && t === t.window
    }
    function i(t) {
      return e(t) ? t : 9 === t.nodeType && t.defaultView
    }
    function n(t) {
      var e,
        n,
        a = { top: 0, left: 0 },
        r = t && t.ownerDocument
      return (
        (e = r.documentElement),
        "undefined" != typeof t.getBoundingClientRect &&
          (a = t.getBoundingClientRect()),
        (n = i(r)),
        {
          top: a.top + n.pageYOffset - e.clientTop,
          left: a.left + n.pageXOffset - e.clientLeft
        }
      )
    }
    function a(t) {
      var e = ""
      for (var i in t) t.hasOwnProperty(i) && (e += i + ":" + t[i] + ";")
      return e
    }
    function r(t) {
      if (c.allowEvent(t) === !1) return null
      for (
        var e = null, i = t.target || t.srcElement;
        null !== i.parentElement;

      ) {
        if (
          "function" == typeof i.className.indexOf &&
          -1 !== i.className.indexOf("waves-effect")
        ) {
          e = i
          break
        }
        i = i.parentElement
      }
      return e
    }
    function o(e) {
      var i = r(e)
      null !== i &&
        (u.show(e, i),
        "ontouchstart" in t &&
          (i.addEventListener("touchend", u.hide, !1),
          i.addEventListener("touchcancel", u.hide, !1)),
        i.addEventListener("mouseup", u.hide, !1),
        i.addEventListener("mouseleave", u.hide, !1))
    }
    var s = s || {},
      l = document.querySelectorAll.bind(document),
      u = {
        duration: 750,
        show: function(t, e) {
          if (2 === t.button) return !1
          var i = e || this,
            r = document.createElement("div")
          ;(r.className = "waves-ripple"), i.appendChild(r)
          var o = n(i),
            s = t.pageY - o.top,
            l = t.pageX - o.left,
            c = "scale(" + (i.clientWidth / 100) * 10 + ")"
          "touches" in t &&
            ((s = t.touches[0].pageY - o.top),
            (l = t.touches[0].pageX - o.left)),
            r.setAttribute("data-hold", Date.now()),
            r.setAttribute("data-scale", c),
            r.setAttribute("data-x", l),
            r.setAttribute("data-y", s)
          var d = { top: s + "px", left: l + "px" }
          ;(r.className = r.className + " waves-notransition"),
            r.setAttribute("style", a(d)),
            (r.className = r.className.replace("waves-notransition", "")),
            (d["-webkit-transform"] = c),
            (d["-moz-transform"] = c),
            (d["-ms-transform"] = c),
            (d["-o-transform"] = c),
            (d.transform = c),
            (d.opacity = "1"),
            (d["-webkit-transition-duration"] = u.duration + "ms"),
            (d["-moz-transition-duration"] = u.duration + "ms"),
            (d["-o-transition-duration"] = u.duration + "ms"),
            (d["transition-duration"] = u.duration + "ms"),
            (d["-webkit-transition-timing-function"] =
              "cubic-bezier(0.250, 0.460, 0.450, 0.940)"),
            (d["-moz-transition-timing-function"] =
              "cubic-bezier(0.250, 0.460, 0.450, 0.940)"),
            (d["-o-transition-timing-function"] =
              "cubic-bezier(0.250, 0.460, 0.450, 0.940)"),
            (d["transition-timing-function"] =
              "cubic-bezier(0.250, 0.460, 0.450, 0.940)"),
            r.setAttribute("style", a(d))
        },
        hide: function(t) {
          c.touchup(t)
          var e = this,
            i = (1.4 * e.clientWidth, null),
            n = e.getElementsByClassName("waves-ripple")
          if (!(n.length > 0)) return !1
          i = n[n.length - 1]
          var r = i.getAttribute("data-x"),
            o = i.getAttribute("data-y"),
            s = i.getAttribute("data-scale"),
            l = Date.now() - Number(i.getAttribute("data-hold")),
            d = 350 - l
          0 > d && (d = 0),
            setTimeout(function() {
              var t = {
                top: o + "px",
                left: r + "px",
                opacity: "0",
                "-webkit-transition-duration": u.duration + "ms",
                "-moz-transition-duration": u.duration + "ms",
                "-o-transition-duration": u.duration + "ms",
                "transition-duration": u.duration + "ms",
                "-webkit-transform": s,
                "-moz-transform": s,
                "-ms-transform": s,
                "-o-transform": s,
                transform: s
              }
              i.setAttribute("style", a(t)),
                setTimeout(function() {
                  try {
                    e.removeChild(i)
                  } catch (t) {
                    return !1
                  }
                }, u.duration)
            }, d)
        },
        wrapInput: function(t) {
          for (var e = 0; e < t.length; e++) {
            var i = t[e]
            if ("input" === i.tagName.toLowerCase()) {
              var n = i.parentNode
              if (
                "i" === n.tagName.toLowerCase() &&
                -1 !== n.className.indexOf("waves-effect")
              )
                continue
              var a = document.createElement("i")
              a.className = i.className + " waves-input-wrapper"
              var r = i.getAttribute("style")
              r || (r = ""),
                a.setAttribute("style", r),
                (i.className = "waves-button-input"),
                i.removeAttribute("style"),
                n.replaceChild(a, i),
                a.appendChild(i)
            }
          }
        }
      },
      c = {
        touches: 0,
        allowEvent: function(t) {
          var e = !0
          return (
            "touchstart" === t.type
              ? (c.touches += 1)
              : "touchend" === t.type || "touchcancel" === t.type
              ? setTimeout(function() {
                  c.touches > 0 && (c.touches -= 1)
                }, 500)
              : "mousedown" === t.type && c.touches > 0 && (e = !1),
            e
          )
        },
        touchup: function(t) {
          c.allowEvent(t)
        }
      }
    ;(s.displayEffect = function(e) {
      ;(e = e || {}),
        "duration" in e && (u.duration = e.duration),
        u.wrapInput(l(".waves-effect")),
        "ontouchstart" in t &&
          document.body.addEventListener("touchstart", o, !1),
        document.body.addEventListener("mousedown", o, !1)
    }),
      (s.attach = function(e) {
        "input" === e.tagName.toLowerCase() &&
          (u.wrapInput([e]), (e = e.parentElement)),
          "ontouchstart" in t && e.addEventListener("touchstart", o, !1),
          e.addEventListener("mousedown", o, !1)
      }),
      (t.Waves = s),
      document.addEventListener(
        "DOMContentLoaded",
        function() {
          s.displayEffect()
        },
        !1
      )
  })(window),
  (function($) {
    var t = {
      init: function(t) {
        var e = { menuWidth: 240, edge: "left", closeOnClick: !1 }
        ;(t = $.extend(e, t)),
          $(this).each(function() {
            function e() {
              ;(a = !1),
                (r = !1),
                $("#sidenav-overlay").velocity(
                  { opacity: 0 },
                  {
                    duration: 200,
                    queue: !1,
                    easing: "easeOutQuad",
                    complete: function() {
                      $(this).remove()
                    }
                  }
                ),
                "left" === t.edge
                  ? ($(".drag-target").css({ width: "", right: "", left: "0" }),
                    n.velocity(
                      { left: -1 * (t.menuWidth + 10) },
                      { duration: 200, queue: !1, easing: "easeOutCubic" }
                    ))
                  : ($(".drag-target").css({ width: "", right: "0", left: "" }),
                    n.velocity(
                      { right: -1 * (t.menuWidth + 10) },
                      { duration: 200, queue: !1, easing: "easeOutCubic" }
                    ))
            }
            var i = $(this),
              n = $("#" + i.attr("data-activates"))
            240 != t.menuWidth &&
              (n.css("width", t.menuWidth),
              n.hasClass("fixed") || n.css("left", -1 * (t.menuWidth + 10))),
              "left" != t.edge && n.addClass("right-aligned"),
              $("body").append($('<div class="drag-target"></div>')),
              $(".drag-target").css(
                "left" === t.edge ? { left: 0 } : { right: 0 }
              ),
              n.hasClass("fixed") &&
                $(window).resize(function() {
                  $(window).width() > 1200 &&
                    n.attr("style") &&
                    (n.removeAttr("style"), n.css("width", t.menuWidth)),
                    0 != $("#sidenav-overlay").css("opacity") &&
                      r &&
                      $("#sidenav-overlay").trigger("click")
                }),
              1 == t.closeOnClick &&
                n.on(
                  "click.itemclick",
                  "a:not(.collapsible-header)",
                  function() {
                    e()
                  }
                )
            var a = !1,
              r = !1
            $(".drag-target")
              .hammer({ prevent_default: !1 })
              .bind("tap", function(t) {
                $("#sidenav-overlay").trigger("click")
              })
              .bind("pan", function(i) {
                if ("touch" === i.gesture.pointerType) {
                  {
                    var a = (i.gesture.direction, i.gesture.center.x)
                    i.gesture.center.y, i.gesture.velocityX
                  }
                  if (!$("#sidenav-overlay").length) {
                    var o = $('<div id="sidenav-overlay"></div>')
                    o.css("opacity", 0).click(function() {
                      e()
                    }),
                      $("body").append(o)
                  }
                  if (
                    ("left" === t.edge
                      ? a > t.menuWidth
                        ? (a = t.menuWidth)
                        : 0 > a && (a = 0)
                      : a < $(window).width() - t.menuWidth &&
                        (a = $(window).width() - t.menuWidth),
                    "left" === t.edge
                      ? (a < t.menuWidth / 2
                          ? (r = !1)
                          : a >= t.menuWidth / 2 && (r = !0),
                        n.css("left", a - t.menuWidth))
                      : (a < $(window).width() - t.menuWidth / 2
                          ? (r = !0)
                          : a >= $(window).width() - t.menuWidth / 2 &&
                            (r = !1),
                        n.css("right", -1 * (a - t.menuWidth / 2))),
                    "left" === t.edge)
                  ) {
                    var s = a / t.menuWidth
                    $("#sidenav-overlay").velocity(
                      { opacity: s },
                      { duration: 50, queue: !1, easing: "easeOutQuad" }
                    )
                  } else {
                    var s = Math.abs((a - $(window).width()) / t.menuWidth)
                    $("#sidenav-overlay").velocity(
                      { opacity: s },
                      { duration: 50, queue: !1, easing: "easeOutQuad" }
                    )
                  }
                }
              })
              .bind("panend", function(e) {
                if ("touch" === e.gesture.pointerType) {
                  var i = e.gesture.velocityX
                  ;(a = !1),
                    "left" === t.edge
                      ? (r && 0.3 >= i) || -0.5 > i
                        ? (n.velocity(
                            { left: 0 },
                            { duration: 300, queue: !1, easing: "easeOutQuad" }
                          ),
                          $("#sidenav-overlay").velocity(
                            { opacity: 1 },
                            { duration: 50, queue: !1, easing: "easeOutQuad" }
                          ),
                          $(".drag-target").css({
                            width: "50%",
                            right: 0,
                            left: ""
                          }))
                        : (!r || i > 0.3) &&
                          (n.velocity(
                            { left: -240 },
                            { duration: 300, queue: !1, easing: "easeOutQuad" }
                          ),
                          $("#sidenav-overlay").velocity(
                            { opacity: 0 },
                            {
                              duration: 50,
                              queue: !1,
                              easing: "easeOutQuad",
                              complete: function() {
                                $(this).remove()
                              }
                            }
                          ),
                          $(".drag-target").css({
                            width: "10%",
                            right: "",
                            left: 0
                          }))
                      : (r && i >= -0.3) || i > 0.5
                      ? (n.velocity(
                          { right: 0 },
                          { duration: 300, queue: !1, easing: "easeOutQuad" }
                        ),
                        $("#sidenav-overlay").velocity(
                          { opacity: 1 },
                          { duration: 50, queue: !1, easing: "easeOutQuad" }
                        ),
                        $(".drag-target").css({
                          width: "50%",
                          right: "",
                          left: 0
                        }))
                      : (!r || -0.3 > i) &&
                        (n.velocity(
                          { right: -240 },
                          { duration: 300, queue: !1, easing: "easeOutQuad" }
                        ),
                        $("#sidenav-overlay").velocity(
                          { opacity: 0 },
                          {
                            duration: 50,
                            queue: !1,
                            easing: "easeOutQuad",
                            complete: function() {
                              $(this).remove()
                            }
                          }
                        ),
                        $(".drag-target").css({
                          width: "10%",
                          right: 0,
                          left: ""
                        }))
                }
              }),
              i.click(function() {
                if (1 == r) (r = !1), (a = !1), e()
                else {
                  "left" === t.edge
                    ? ($(".drag-target").css({
                        width: "50%",
                        right: 0,
                        left: ""
                      }),
                      n.velocity(
                        { left: 0 },
                        { duration: 300, queue: !1, easing: "easeOutQuad" }
                      ))
                    : ($(".drag-target").css({
                        width: "50%",
                        right: "",
                        left: 0
                      }),
                      n.velocity(
                        { right: 0 },
                        { duration: 300, queue: !1, easing: "easeOutQuad" }
                      ),
                      n.css("left", ""))
                  var i = $('<div id="sidenav-overlay"></div>')
                  i.css("opacity", 0).click(function() {
                    ;(r = !1),
                      (a = !1),
                      e(),
                      i.animate(
                        { opacity: 0 },
                        {
                          duration: 300,
                          queue: !1,
                          easing: "easeOutQuad",
                          complete: function() {
                            $(this).remove()
                          }
                        }
                      )
                  }),
                    $("body").append(i),
                    i.animate(
                      { opacity: 1 },
                      {
                        duration: 300,
                        queue: !1,
                        easing: "easeOutQuad",
                        complete: function() {
                          ;(r = !0), (a = !1)
                        }
                      }
                    )
                }
                return !1
              })
          })
      },
      show: function() {
        this.trigger("click")
      },
      hide: function() {
        $("#sidenav-overlay").trigger("click")
      }
    }
    $.fn.sideNav = function(e) {
      return t[e]
        ? t[e].apply(this, Array.prototype.slice.call(arguments, 1))
        : "object" != typeof e && e
        ? void $.error("Method " + e + " does not exist on jQuery.tooltip")
        : t.init.apply(this, arguments)
    }
  })(jQuery),
  (function($) {
    function t(t, e, i, n) {
      var a = $()
      return (
        $.each(r, function(r, o) {
          if (o.height() > 0) {
            var s = o.offset().top,
              l = o.offset().left,
              u = l + o.width(),
              c = s + o.height(),
              d = !(l > e || n > u || s > i || t > c)
            d && a.push(o)
          }
        }),
        a
      )
    }
    function e() {
      ++l
      var e = a.scrollTop(),
        i = a.scrollLeft(),
        n = i + a.width(),
        r = e + a.height(),
        s = t(e + u.top + 200, n + u.right, r + u.bottom, i + u.left)
      $.each(s, function(t, e) {
        var i = e.data("scrollSpy:ticks")
        "number" != typeof i && e.triggerHandler("scrollSpy:enter"),
          e.data("scrollSpy:ticks", l)
      }),
        $.each(o, function(t, e) {
          var i = e.data("scrollSpy:ticks")
          "number" == typeof i &&
            i !== l &&
            (e.triggerHandler("scrollSpy:exit"),
            e.data("scrollSpy:ticks", null))
        }),
        (o = s)
    }
    function i() {
      a.trigger("scrollSpy:winSize")
    }
    function n(t, e, i) {
      var n,
        a,
        r,
        o = null,
        s = 0
      i || (i = {})
      var l = function() {
        ;(s = i.leading === !1 ? 0 : c()),
          (o = null),
          (r = t.apply(n, a)),
          (n = a = null)
      }
      return function() {
        var u = c()
        s || i.leading !== !1 || (s = u)
        var d = e - (u - s)
        return (
          (n = this),
          (a = arguments),
          0 >= d
            ? (clearTimeout(o),
              (o = null),
              (s = u),
              (r = t.apply(n, a)),
              (n = a = null))
            : o || i.trailing === !1 || (o = setTimeout(l, d)),
          r
        )
      }
    }
    var a = $(window),
      r = [],
      o = [],
      s = !1,
      l = 0,
      u = { top: 0, right: 0, bottom: 0, left: 0 },
      c =
        Date.now ||
        function() {
          return new Date().getTime()
        }
    ;($.scrollSpy = function(t, i) {
      var o = []
      ;(t = $(t)),
        t.each(function(t, e) {
          r.push($(e)),
            $(e).data("scrollSpy:id", t),
            $("a[href=#" + $(e).attr("id") + "]").click(function(t) {
              t.preventDefault()
              var e = $(this.hash).offset().top + 1
              $(".tabs-wrapper").length
                ? $("html, body").animate(
                    { scrollTop: e - 60 },
                    { duration: 400, easing: "easeOutCubic" }
                  )
                : $("html, body").animate(
                    { scrollTop: e },
                    { duration: 400, easing: "easeOutCubic" }
                  )
            })
        }),
        (i = i || { throttle: 100 }),
        (u.top = i.offsetTop || 0),
        (u.right = i.offsetRight || 0),
        (u.bottom = i.offsetBottom || 0),
        (u.left = i.offsetLeft || 0)
      var l = n(e, i.throttle || 100),
        c = function() {
          $(document).ready(l)
        }
      return (
        s || (a.on("scroll", c), a.on("resize", c), (s = !0)),
        setTimeout(c, 0),
        t.on("scrollSpy:enter", function() {
          o = $.grep(o, function(t) {
            return 0 != t.height()
          })
          var t = $(this)
          o[0]
            ? ($("a[href=#" + o[0].attr("id") + "]").removeClass("active"),
              t.data("scrollSpy:id") < o[0].data("scrollSpy:id")
                ? o.unshift($(this))
                : o.push($(this)))
            : o.push($(this)),
            $("a[href=#" + o[0].attr("id") + "]").addClass("active")
        }),
        t.on("scrollSpy:exit", function() {
          if (
            ((o = $.grep(o, function(t) {
              return 0 != t.height()
            })),
            o[0])
          ) {
            $("a[href=#" + o[0].attr("id") + "]").removeClass("active")
            var t = $(this)
            ;(o = $.grep(o, function(e) {
              return e.attr("id") != t.attr("id")
            })),
              o[0] && $("a[href=#" + o[0].attr("id") + "]").addClass("active")
          }
        }),
        t
      )
    }),
      ($.winSizeSpy = function(t) {
        return (
          ($.winSizeSpy = function() {
            return a
          }),
          (t = t || { throttle: 100 }),
          a.on("resize", n(i, t.throttle || 100))
        )
      }),
      ($.fn.scrollSpy = function(t) {
        return $.scrollSpy($(this), t)
      })
  })(jQuery),
  (function($) {
    $(document).ready(function() {
      var t =
        "input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], textarea"
      if (
        ($(document).on("change", t, function() {
          0 !== $(this).val().length &&
            $(this)
              .siblings("label, i")
              .addClass("active"),
            validate_field($(this))
        }),
        $(document).ready(function() {
          $(t).each(function(t, e) {
            $(e).val().length > 0 &&
              $(this)
                .siblings("label, i")
                .addClass("active")
          })
        }),
        $(document).on("reset", function(e) {
          $(e.target).is("form") &&
            ($(this)
              .find(t)
              .removeClass("valid")
              .removeClass("invalid"),
            $(this)
              .find("select.initialized")
              .each(function() {
                var t = $(this)
                  .find("option[selected]")
                  .text()
                $(this)
                  .siblings("input.select-dropdown")
                  .val(t)
              }))
        }),
        $(document).on("focus", t, function() {
          $(this)
            .siblings("label, i")
            .addClass("active")
        }),
        $(document).on("blur", t, function() {
          0 === $(this).val().length &&
            $(this)
              .siblings("label, i")
              .removeClass("active"),
            validate_field($(this))
        }),
        (validate_field = function(t) {
          0 === t.val().length
            ? t.hasClass("validate") &&
              (t.removeClass("valid"), t.removeClass("invalid"))
            : t.hasClass("validate") &&
              (t.is(":valid")
                ? (t.removeClass("invalid"), t.addClass("valid"))
                : (t.removeClass("valid"), t.addClass("invalid")))
        }),
        0 === $(".hiddendiv").length)
      ) {
        var e = $('<div class="hiddendiv common"></div>'),
          i = null
        $("body").append(e)
      }
      var n = ".materialize-textarea"
      $(".hiddendiv").css("width", $(n).width()),
        $(n).each(function() {
          $(this).val().length &&
            ((i = $(this).val()),
            (i = i.replace(/\n/g, "<br>")),
            e.html(i + "<br>"),
            $(this).css("height", e.height()))
        }),
        $("body").on("keyup keydown", n, function() {
          ;(i = $(this).val()),
            (i = i.replace(/\n/g, "<br>")),
            e.html(i + "<br>"),
            $(this).css("height", e.height())
        }),
        $(".file-field").each(function() {
          var t = $(this).find("input.file-path")
          $(this)
            .find('input[type="file"]')
            .change(function() {
              t.val($(this).val()), t.trigger("change")
            })
        })
      var a = "input[type=range]",
        r = !1
      $(a).each(function() {
        var t = $('<span class="thumb"><span class="value"></span></span>')
        $(this).after(t)
      })
      var o = ".range-field"
      $(document).on("mousedown", o, function(t) {
        var e = $(this).children(".thumb")
        e.length <= 0 &&
          ((e = $('<span class="thumb"><span class="value"></span></span>')),
          $(this).append(e)),
          (r = !0),
          $(this).addClass("active"),
          e.hasClass("active") ||
            e.velocity(
              {
                height: "30px",
                width: "30px",
                top: "-20px",
                marginLeft: "-15px"
              },
              { duration: 300, easing: "easeOutExpo" }
            )
        var i = t.pageX - $(this).offset().left,
          n = $(this).outerWidth()
        0 > i ? (i = 0) : i > n && (i = n),
          e.addClass("active").css("left", i),
          e.find(".value").html(
            $(this)
              .children("input[type=range]")
              .val()
          )
      }),
        $(document).on("mouseup", o, function() {
          ;(r = !1), $(this).removeClass("active")
        }),
        $(document).on("mousemove", o, function(t) {
          var e = $(this).children(".thumb")
          if (r) {
            e.hasClass("active") ||
              e.velocity(
                {
                  height: "30px",
                  width: "30px",
                  top: "-20px",
                  marginLeft: "-15px"
                },
                { duration: 300, easing: "easeOutExpo" }
              )
            var i = t.pageX - $(this).offset().left,
              n = $(this).outerWidth()
            0 > i ? (i = 0) : i > n && (i = n),
              e.addClass("active").css("left", i),
              e.find(".value").html(
                $(this)
                  .children("input[type=range]")
                  .val()
              )
          }
        }),
        $(document).on("mouseout", o, function() {
          if (!r) {
            var t = $(this).children(".thumb")
            t.hasClass("active") &&
              t.velocity(
                { height: "0", width: "0", top: "10px", marginLeft: "-6px" },
                { duration: 100 }
              ),
              t.removeClass("active")
          }
        }),
        ($.fn.material_select = function(t) {
          $(this).each(function() {
            if (
              (($select = $(this)),
              !$select.hasClass("browser-default") &&
                !$select.hasClass("initialized"))
            ) {
              var e = s(),
                i = $('<div class="select-wrapper"></div>'),
                n = $(
                  '<ul id="select-options-' +
                    e +
                    '" class="dropdown-content select-dropdown"></ul>'
                ),
                a = $select.children("option")
              if (void 0 !== $select.find("option:selected"))
                var r = $select.find("option:selected")
              else var r = n.first()
              a.each(function() {
                n.append(
                  $(
                    '<li class="' +
                      ($(this).is(":disabled") ? "disabled" : "") +
                      '"><span>' +
                      $(this).html() +
                      "</span></li>"
                  )
                )
              }),
                n.find("li").each(function(e) {
                  var i = $select
                  $(this).click(function() {
                    $(this).hasClass("disabled") ||
                      (i
                        .find("option")
                        .eq(e)
                        .prop("selected", !0),
                      i.trigger("change"),
                      i.siblings("input.select-dropdown").val($(this).text()),
                      "undefined" != typeof t && t())
                  })
                }),
                $select.wrap(i)
              var o = $(
                '<input type="text" class="select-dropdown" readonly="true" ' +
                  ($select.is(":disabled") ? "disabled" : "") +
                  ' data-activates="select-options-' +
                  e +
                  '" value="' +
                  r.html() +
                  '"/><i class="mdi-navigation-arrow-drop-down">'
              )
              $select.before(o),
                $("body").append(n),
                $select.is(":disabled") || o.dropdown({ hover: !1 }),
                $select.addClass("initialized"),
                o.on("focus", function() {
                  $(this).trigger("open"),
                    (r = $(this).val()),
                    (selectedOption = n.find("li").filter(function() {
                      return (
                        $(this)
                          .text()
                          .toLowerCase() === r.toLowerCase()
                      )
                    })[0]),
                    activateOption(n, selectedOption)
                }),
                o.on("blur", function() {
                  $(this).trigger("close")
                }),
                (activateOption = function(t, e) {
                  t.find("li.active").removeClass("active"),
                    $(e).addClass("active"),
                    t.scrollTo(e)
                }),
                (filterQuery = []),
                (onKeyDown = function(t) {
                  return 9 == t.which
                    ? void o.trigger("close")
                    : 40 != t.which || n.is(":visible")
                    ? void (
                        (13 != t.which || n.is(":visible")) &&
                        (t.preventDefault(),
                        (letter = String.fromCharCode(t.which).toLowerCase()),
                        letter &&
                          (filterQuery.push(letter),
                          (string = filterQuery.join("")),
                          (newOption = n.find("li").filter(function() {
                            return (
                              0 ===
                              $(this)
                                .text()
                                .toLowerCase()
                                .indexOf(string)
                            )
                          })[0]),
                          newOption && activateOption(n, newOption)),
                        13 == t.which &&
                          ((activeOption = n.find(
                            "li.active:not(.disabled)"
                          )[0]),
                          activeOption &&
                            ($(activeOption).trigger("click"),
                            o.trigger("close"))),
                        40 == t.which &&
                          ((newOption = n
                            .find("li.active")
                            .next("li:not(.disabled)")[0]),
                          newOption && activateOption(n, newOption)),
                        27 == t.which && o.trigger("close"),
                        38 == t.which &&
                          ((newOption = n
                            .find("li.active")
                            .prev("li:not(.disabled)")[0]),
                          newOption && activateOption(n, newOption)),
                        setTimeout(function() {
                          filterQuery = []
                        }, 1e3))
                      )
                    : void o.trigger("open")
                }),
                o.on("keydown", onKeyDown)
            }
          })
        })
      var s = (function() {
        function t() {
          return Math.floor(65536 * (1 + Math.random()))
            .toString(16)
            .substring(1)
        }
        return function() {
          return (
            t() +
            t() +
            "-" +
            t() +
            "-" +
            t() +
            "-" +
            t() +
            "-" +
            t() +
            t() +
            t()
          )
        }
      })()
    })
  })(jQuery),
  (function($) {
    $.fn.slider = function(t) {
      var e = { indicators: !0, height: 400, transition: 500, interval: 6e4 }
      return (
        (t = $.extend(e, t)),
        this.each(function() {
          function e(t, e) {
            t.hasClass("center-align")
              ? t.velocity(
                  { opacity: 0, translateY: -100 },
                  { duration: e, queue: !1 }
                )
              : t.hasClass("right-align")
              ? t.velocity(
                  { opacity: 0, translateX: 100 },
                  { duration: e, queue: !1 }
                )
              : t.hasClass("left-align") &&
                t.velocity(
                  { opacity: 0, translateX: -100 },
                  { duration: e, queue: !1 }
                )
          }
          function i(i) {
            i >= o.length ? (i = 0) : 0 > i && (i = o.length - 1),
              (s = r.find(".active").index()),
              s != i &&
                ((n = o.eq(s)),
                ($caption = n.find(".caption")),
                n.removeClass("active"),
                n.velocity(
                  { opacity: 0 },
                  {
                    duration: t.transition,
                    queue: !1,
                    easing: "easeOutQuad",
                    complete: function() {
                      o.not(".active").velocity(
                        { opacity: 0, translateX: 0, translateY: 0 },
                        { duration: 0, queue: !1 }
                      )
                    }
                  }
                ),
                e($caption, t.transition),
                t.indicators && l.eq(s).removeClass("active"),
                o
                  .eq(i)
                  .velocity(
                    { opacity: 1 },
                    { duration: t.transition, queue: !1, easing: "easeOutQuad" }
                  ),
                o
                  .eq(i)
                  .find(".caption")
                  .velocity(
                    { opacity: 1, translateX: 0, translateY: 0 },
                    {
                      duration: t.transition,
                      delay: t.transition,
                      queue: !1,
                      easing: "easeOutQuad"
                    }
                  ),
                o.eq(i).addClass("active"),
                t.indicators && l.eq(i).addClass("active"))
          }
          var n,
            a = $(this),
            r = a.find("ul.slides").first(),
            o = r.find("li"),
            s = r.find(".active").index()
          if (
            (-1 != s && (n = o.eq(s)),
            400 != t.height && (a.height(t.height + 40), r.height(t.height)),
            o.find(".caption").each(function() {
              e($(this), 0)
            }),
            o.find("img").each(function() {
              $(this).css(
                "background-image",
                "url(" + $(this).attr("src") + ")"
              ),
                $(this).attr(
                  "src",
                  "data:image/gif;base64,R0lGODlhAQABAIABAP///wAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                )
            }),
            t.indicators)
          ) {
            var l = $('<ul class="indicators"></ul>')
            o.each(function(e) {
              var n = $('<li class="indicator-item"></li>')
              n.click(function() {
                var e = r.parent(),
                  n = e.find($(this)).index()
                i(n),
                  clearInterval($interval),
                  ($interval = setInterval(function() {
                    ;(s = r.find(".active").index()),
                      o.length == s + 1 ? (s = 0) : (s += 1),
                      i(s)
                  }, t.transition + t.interval))
              }),
                l.append(n)
            }),
              a.append(l),
              (l = a.find("ul.indicators").find("li.indicator-item"))
          }
          n
            ? n.show()
            : (o
                .first()
                .addClass("active")
                .velocity(
                  { opacity: 1 },
                  { duration: t.transition, queue: !1, easing: "easeOutQuad" }
                ),
              (s = 0),
              (n = o.eq(s)),
              t.indicators && l.eq(s).addClass("active")),
            n.find("img").each(function() {
              n.find(".caption").velocity(
                { opacity: 1, translateX: 0, translateY: 0 },
                { duration: t.transition, queue: !1, easing: "easeOutQuad" }
              )
            }),
            ($interval = setInterval(function() {
              ;(s = r.find(".active").index()), i(s + 1)
            }, t.transition + t.interval))
          var u = !1,
            c = !1,
            d = !1
          a.hammer({ prevent_default: !1 })
            .bind("pan", function(t) {
              if ("touch" === t.gesture.pointerType) {
                clearInterval($interval)
                var e = t.gesture.direction,
                  i = t.gesture.deltaX,
                  n = t.gesture.velocityX
                ;($curr_slide = r.find(".active")),
                  $curr_slide.velocity(
                    { translateX: i },
                    { duration: 50, queue: !1, easing: "easeOutQuad" }
                  ),
                  4 === e && (i > a.innerWidth() / 2 || -0.65 > n)
                    ? (d = !0)
                    : 2 === e &&
                      (i < (-1 * a.innerWidth()) / 2 || n > 0.65) &&
                      (c = !0)
                var s
                c &&
                  ((s = $curr_slide.next()),
                  0 === s.length && (s = o.first()),
                  s.velocity(
                    { opacity: 1 },
                    { duration: 300, queue: !1, easing: "easeOutQuad" }
                  )),
                  d &&
                    ((s = $curr_slide.prev()),
                    0 === s.length && (s = o.last()),
                    s.velocity(
                      { opacity: 1 },
                      { duration: 300, queue: !1, easing: "easeOutQuad" }
                    ))
              }
            })
            .bind("panend", function(e) {
              "touch" === e.gesture.pointerType &&
                (($curr_slide = r.find(".active")),
                (u = !1),
                (curr_index = r.find(".active").index()),
                d || c
                  ? c
                    ? (i(curr_index + 1),
                      $curr_slide.velocity(
                        { translateX: -1 * a.innerWidth() },
                        {
                          duration: 300,
                          queue: !1,
                          easing: "easeOutQuad",
                          complete: function() {
                            $curr_slide.velocity(
                              { opacity: 0, translateX: 0 },
                              { duration: 0, queue: !1 }
                            )
                          }
                        }
                      ))
                    : d &&
                      (i(curr_index - 1),
                      $curr_slide.velocity(
                        { translateX: a.innerWidth() },
                        {
                          duration: 300,
                          queue: !1,
                          easing: "easeOutQuad",
                          complete: function() {
                            $curr_slide.velocity(
                              { opacity: 0, translateX: 0 },
                              { duration: 0, queue: !1 }
                            )
                          }
                        }
                      ))
                  : $curr_slide.velocity(
                      { translateX: 0 },
                      { duration: 300, queue: !1, easing: "easeOutQuad" }
                    ),
                (c = !1),
                (d = !1),
                clearInterval($interval),
                ($interval = setInterval(function() {
                  ;(s = r.find(".active").index()),
                    o.length == s + 1 ? (s = 0) : (s += 1),
                    i(s)
                }, t.transition + t.interval)))
            })
        })
      )
    }
  })(jQuery),
  (function($) {
    $(document).ready(function() {
      $(document).on("click.card", ".card", function(t) {
        $(this).find(".card-reveal").length &&
          ($(t.target).is($(".card-reveal .card-title")) ||
          $(t.target).is($(".card-reveal .card-title i"))
            ? $(this)
                .find(".card-reveal")
                .velocity(
                  { translateY: 0 },
                  { duration: 225, queue: !1, easing: "easeInOutQuad" }
                )
            : ($(t.target).is($(".card .activator")) ||
                $(t.target).is($(".card .activator i"))) &&
              $(this)
                .find(".card-reveal")
                .velocity(
                  { translateY: "-100%" },
                  { duration: 300, queue: !1, easing: "easeInOutQuad" }
                ))
      })
    })
  })(jQuery),
  (function($) {
    $(document).ready(function() {
      var t = (function() {
        function t() {
          return Math.floor(65536 * (1 + Math.random()))
            .toString(16)
            .substring(1)
        }
        return function() {
          return (
            t() +
            t() +
            "-" +
            t() +
            "-" +
            t() +
            "-" +
            t() +
            "-" +
            t() +
            t() +
            t()
          )
        }
      })()
      $.fn.pushpin = function(e) {
        var i = { top: 0, bottom: 1 / 0, offset: 0 }
        return (
          (e = $.extend(i, e)),
          ($index = 0),
          this.each(function() {
            function i(t) {
              t.removeClass("pin-top"),
                t.removeClass("pinned"),
                t.removeClass("pin-bottom")
            }
            function n(t, n) {
              t.each(function() {
                e.top <= n &&
                  e.bottom >= n &&
                  !$(this).hasClass("pinned") &&
                  (i($(this)),
                  $(this).css("top", e.offset),
                  $(this).addClass("pinned")),
                  n < e.top &&
                    !$(this).hasClass("pin-top") &&
                    (i($(this)),
                    $(this).css("top", 0),
                    $(this).addClass("pin-top")),
                  n > e.bottom &&
                    !$(this).hasClass("pin-bottom") &&
                    (i($(this)),
                    $(this).addClass("pin-bottom"),
                    $(this).css("top", e.bottom - o))
              })
            }
            var a = t(),
              r = $(this),
              o = $(this).offset().top
            n(r, $(window).scrollTop()),
              $(window).on("scroll." + a, function() {
                var t = $(window).scrollTop() + e.offset
                n(r, t)
              })
          })
        )
      }
    })
  })(jQuery),
  (function($) {
    $(document).ready(function() {
      ;($.fn.reverse = [].reverse),
        $(document).on(
          "mouseenter.fixedActionBtn",
          ".fixed-action-btn",
          function(t) {
            var e = $(this)
            e.find("ul a.btn-floating").velocity(
              { scaleY: ".4", scaleX: ".4", translateY: "40px" },
              { duration: 0 }
            )
            var i = 0
            e.find("ul a.btn-floating")
              .reverse()
              .each(function() {
                $(this).velocity(
                  { opacity: "1", scaleX: "1", scaleY: "1", translateY: "0" },
                  { duration: 80, delay: i }
                ),
                  (i += 40)
              })
          }
        ),
        $(document).on(
          "mouseleave.fixedActionBtn",
          ".fixed-action-btn",
          function(t) {
            var e = $(this)
            e.find("ul a.btn-floating").velocity("stop", !0),
              e.find("ul a.btn-floating").velocity(
                {
                  opacity: "0",
                  scaleX: ".4",
                  scaleY: ".4",
                  translateY: "40px"
                },
                { duration: 80 }
              )
          }
        )
    })
  })(jQuery),
  (function($) {
    $(document).ready(function() {
      showStaggeredList = function(t) {
        var e = 0
        $(t)
          .find("li")
          .velocity({ translateX: "-100px" }, { duration: 0 }),
          $(t)
            .find("li")
            .each(function() {
              $(this).velocity(
                { opacity: "1", translateX: "0" },
                { duration: 800, delay: e, easing: [60, 10] }
              ),
                (e += 120)
            })
      }
      var t = []
      $("ul.staggered-list").each(function(e) {
        var i = "scrollFire-" + e
        $(this).addClass(i),
          t.push({
            selector: "ul.staggered-list." + i,
            offset: 200,
            callback: 'showStaggeredList("ul.staggered-list.' + i + '")'
          })
      }),
        scrollFire(t)
      var e = !1,
        i = !1
      $(".dismissable").each(function() {
        $(this)
          .hammer({ prevent_default: !1 })
          .bind("pan", function(t) {
            if ("touch" === t.gesture.pointerType) {
              var n = $(this),
                a = t.gesture.direction,
                r = t.gesture.deltaX,
                o = t.gesture.velocityX
              n.velocity(
                { translateX: r },
                { duration: 50, queue: !1, easing: "easeOutQuad" }
              ),
                4 === a && (r > n.innerWidth() / 2 || -0.75 > o)
                  ? (e = !0)
                  : 2 === a &&
                    (r < (-1 * n.innerWidth()) / 2 || o > 0.75) &&
                    (i = !0)
            }
          })
          .bind("panend", function(t) {
            if ("touch" === t.gesture.pointerType) {
              var n = $(this)
              if (e || i) {
                var a
                ;(a = e ? n.innerWidth() : -1 * n.innerWidth()),
                  n.velocity(
                    { translateX: a },
                    {
                      duration: 100,
                      queue: !1,
                      easing: "easeOutQuad",
                      complete: function() {
                        n.css("border", "none"),
                          n.velocity(
                            { height: 0, padding: 0 },
                            {
                              duration: 200,
                              queue: !1,
                              easing: "easeOutQuad",
                              complete: function() {
                                n.remove()
                              }
                            }
                          )
                      }
                    }
                  )
              } else
                n.velocity(
                  { translateX: 0 },
                  { duration: 100, queue: !1, easing: "easeOutQuad" }
                )
              ;(e = !1), (i = !1)
            }
          })
      }),
        (fadeInImage = function(t) {
          var e = $(t)
          e.css({ opacity: 0 }),
            $(e).velocity(
              { opacity: 1 },
              { duration: 650, queue: !1, easing: "easeOutSine" }
            ),
            $(e).animate(
              { opacity: 1 },
              {
                duration: 1300,
                queue: !1,
                easing: "swing",
                step: function(t, e) {
                  e.start = 100
                  var i = t / 100,
                    n = 150 - (100 - t) / 1.75
                  100 > n && (n = 100),
                    t >= 0 &&
                      $(this).css({
                        "-webkit-filter":
                          "grayscale(" + i + ")brightness(" + n + "%)",
                        filter: "grayscale(" + i + ")brightness(" + n + "%)"
                      })
                }
              }
            )
        })
    })
  })(jQuery),
  (function($) {
    scrollFire = function(t) {
      $(window).scroll(function() {
        var e = $(window).scrollTop() + $(window).height()
        $.each(t, function(t, i) {
          var n = i.selector,
            a = i.offset,
            r = i.callback,
            o = $(n).offset().top
          if (e > o + a && 1 != i.done) {
            var s = new Function(r)
            s(), (i.done = !0)
          }
        })
      })
    }
  })(jQuery),
  (function(t) {
    "function" == typeof define && define.amd
      ? define("picker", ["jquery"], t)
      : "object" == typeof exports
      ? (module.exports = t(require("jquery")))
      : (this.Picker = t(jQuery))
  })(function($) {
    function t(a, r, s, c) {
      function d() {
        return t._.node(
          "div",
          t._.node(
            "div",
            t._.node(
              "div",
              t._.node("div", C.component.nodes(y.open), w.box),
              w.wrap
            ),
            w.frame
          ),
          w.holder
        )
      }
      function f() {
        x
          .data(r, C)
          .addClass(w.input)
          .attr("tabindex", -1)
          .val(x.data("value") ? C.get("select", b.format) : a.value),
          b.editable ||
            x
              .on("focus." + y.id + " click." + y.id, function(t) {
                t.preventDefault(), C.$root[0].focus()
              })
              .on("keydown." + y.id, g),
          n(a, {
            haspopup: !0,
            expanded: !1,
            readonly: !1,
            owns: a.id + "_root"
          })
      }
      function p() {
        C.$root
          .on({
            keydown: g,
            focusin: function(t) {
              C.$root.removeClass(w.focused), t.stopPropagation()
            },
            "mousedown click": function(t) {
              var e = t.target
              e != C.$root.children()[0] &&
                (t.stopPropagation(),
                "mousedown" != t.type ||
                  $(e).is("input, select, textarea, button, option") ||
                  (t.preventDefault(), C.$root[0].focus()))
            }
          })
          .on({
            focus: function() {
              x.addClass(w.target)
            },
            blur: function() {
              x.removeClass(w.target)
            }
          })
          .on("focus.toOpen", v)
          .on(
            "click",
            "[data-pick], [data-nav], [data-clear], [data-close]",
            function() {
              var t = $(this),
                e = t.data(),
                i = t.hasClass(w.navDisabled) || t.hasClass(w.disabled),
                n = o()
              ;(n = n && (n.type || n.href)),
                (i || (n && !$.contains(C.$root[0], n))) && C.$root[0].focus(),
                !i && e.nav
                  ? C.set("highlight", C.component.item.highlight, {
                      nav: e.nav
                    })
                  : !i && "pick" in e
                  ? C.set("select", e.pick)
                  : e.clear
                  ? C.clear().close(!0)
                  : e.close && C.close(!0)
            }
          ),
          n(C.$root[0], "hidden", !0)
      }
      function h() {
        var t
        b.hiddenName === !0
          ? ((t = a.name), (a.name = ""))
          : ((t = [
              "string" == typeof b.hiddenPrefix ? b.hiddenPrefix : "",
              "string" == typeof b.hiddenSuffix ? b.hiddenSuffix : "_submit"
            ]),
            (t = t[0] + a.name + t[1])),
          (C._hidden = $(
            '<input type=hidden name="' +
              t +
              '"' +
              (x.data("value") || a.value
                ? ' value="' + C.get("select", b.formatSubmit) + '"'
                : "") +
              ">"
          )[0]),
          x.on("change." + y.id, function() {
            C._hidden.value = a.value ? C.get("select", b.formatSubmit) : ""
          }),
          b.container ? $(b.container).append(C._hidden) : x.after(C._hidden)
      }
      function g(t) {
        var e = t.keyCode,
          i = /^(8|46)$/.test(e)
        return 27 == e
          ? (C.close(), !1)
          : void (
              (32 == e || i || (!y.open && C.component.key[e])) &&
              (t.preventDefault(),
              t.stopPropagation(),
              i ? C.clear().close() : C.open())
            )
      }
      function v(t) {
        t.stopPropagation(),
          "focus" == t.type && C.$root.addClass(w.focused),
          C.open()
      }
      if (!a) return t
      var m = !1,
        y = { id: a.id || "P" + Math.abs(~~(Math.random() * new Date())) },
        b = s ? $.extend(!0, {}, s.defaults, c) : c || {},
        w = $.extend({}, t.klasses(), b.klass),
        x = $(a),
        k = function() {
          return this.start()
        },
        C = (k.prototype = {
          constructor: k,
          $node: x,
          start: function() {
            return y && y.start
              ? C
              : ((y.methods = {}),
                (y.start = !0),
                (y.open = !1),
                (y.type = a.type),
                (a.autofocus = a == o()),
                (a.readOnly = !b.editable),
                (a.id = a.id || y.id),
                "text" != a.type && (a.type = "text"),
                (C.component = new s(C, b)),
                (C.$root = $(
                  t._.node(
                    "div",
                    d(),
                    w.picker,
                    'id="' + a.id + '_root" tabindex="0"'
                  )
                )),
                p(),
                b.formatSubmit && h(),
                f(),
                b.container ? $(b.container).append(C.$root) : x.after(C.$root),
                C.on({
                  start: C.component.onStart,
                  render: C.component.onRender,
                  stop: C.component.onStop,
                  open: C.component.onOpen,
                  close: C.component.onClose,
                  set: C.component.onSet
                }).on({
                  start: b.onStart,
                  render: b.onRender,
                  stop: b.onStop,
                  open: b.onOpen,
                  close: b.onClose,
                  set: b.onSet
                }),
                (m = e(C.$root.children()[0])),
                a.autofocus && C.open(),
                C.trigger("start").trigger("render"))
          },
          render: function(t) {
            return (
              t
                ? C.$root.html(d())
                : C.$root.find("." + w.box).html(C.component.nodes(y.open)),
              C.trigger("render")
            )
          },
          stop: function() {
            return y.start
              ? (C.close(),
                C._hidden && C._hidden.parentNode.removeChild(C._hidden),
                C.$root.remove(),
                x.removeClass(w.input).removeData(r),
                setTimeout(function() {
                  x.off("." + y.id)
                }, 0),
                (a.type = y.type),
                (a.readOnly = !1),
                C.trigger("stop"),
                (y.methods = {}),
                (y.start = !1),
                C)
              : C
          },
          open: function(e) {
            return y.open
              ? C
              : (x.addClass(w.active),
                n(a, "expanded", !0),
                setTimeout(function() {
                  C.$root.addClass(w.opened), n(C.$root[0], "hidden", !1)
                }, 0),
                e !== !1 &&
                  ((y.open = !0),
                  m &&
                    u
                      .css("overflow", "hidden")
                      .css("padding-right", "+=" + i()),
                  C.$root[0].focus(),
                  l
                    .on("click." + y.id + " focusin." + y.id, function(t) {
                      var e = t.target
                      e != a &&
                        e != document &&
                        3 != t.which &&
                        C.close(e === C.$root.children()[0])
                    })
                    .on("keydown." + y.id, function(e) {
                      var i = e.keyCode,
                        n = C.component.key[i],
                        a = e.target
                      27 == i
                        ? C.close(!0)
                        : a != C.$root[0] || (!n && 13 != i)
                        ? $.contains(C.$root[0], a) &&
                          13 == i &&
                          (e.preventDefault(), a.click())
                        : (e.preventDefault(),
                          n
                            ? t._.trigger(C.component.key.go, C, [
                                t._.trigger(n)
                              ])
                            : C.$root
                                .find("." + w.highlighted)
                                .hasClass(w.disabled) ||
                              C.set(
                                "select",
                                C.component.item.highlight
                              ).close())
                    })),
                C.trigger("open"))
          },
          close: function(t) {
            return (
              t &&
                (C.$root.off("focus.toOpen")[0].focus(),
                setTimeout(function() {
                  C.$root.on("focus.toOpen", v)
                }, 0)),
              x.removeClass(w.active),
              n(a, "expanded", !1),
              setTimeout(function() {
                C.$root.removeClass(w.opened + " " + w.focused),
                  n(C.$root[0], "hidden", !0)
              }, 0),
              y.open
                ? ((y.open = !1),
                  m && u.css("overflow", "").css("padding-right", "-=" + i()),
                  l.off("." + y.id),
                  C.trigger("close"))
                : C
            )
          },
          clear: function(t) {
            return C.set("clear", null, t)
          },
          set: function(t, e, i) {
            var n,
              a,
              r = $.isPlainObject(t),
              o = r ? t : {}
            if (((i = r && $.isPlainObject(e) ? e : i || {}), t)) {
              r || (o[t] = e)
              for (n in o)
                (a = o[n]),
                  n in C.component.item &&
                    (void 0 === a && (a = null), C.component.set(n, a, i)),
                  ("select" == n || "clear" == n) &&
                    x
                      .val("clear" == n ? "" : C.get(n, b.format))
                      .trigger("change")
              C.render()
            }
            return i.muted ? C : C.trigger("set", o)
          },
          get: function(e, i) {
            if (((e = e || "value"), null != y[e])) return y[e]
            if ("valueSubmit" == e) {
              if (C._hidden) return C._hidden.value
              e = "value"
            }
            if ("value" == e) return a.value
            if (e in C.component.item) {
              if ("string" == typeof i) {
                var n = C.component.get(e)
                return n
                  ? t._.trigger(C.component.formats.toString, C.component, [
                      i,
                      n
                    ])
                  : ""
              }
              return C.component.get(e)
            }
          },
          on: function(t, e, i) {
            var n,
              a,
              r = $.isPlainObject(t),
              o = r ? t : {}
            if (t) {
              r || (o[t] = e)
              for (n in o)
                (a = o[n]),
                  i && (n = "_" + n),
                  (y.methods[n] = y.methods[n] || []),
                  y.methods[n].push(a)
            }
            return C
          },
          off: function() {
            var t,
              e,
              i = arguments
            for (t = 0, namesCount = i.length; t < namesCount; t += 1)
              (e = i[t]), e in y.methods && delete y.methods[e]
            return C
          },
          trigger: function(e, i) {
            var n = function(e) {
              var n = y.methods[e]
              n &&
                n.map(function(e) {
                  t._.trigger(e, C, [i])
                })
            }
            return n("_" + e), n(e), C
          }
        })
      return new k()
    }
    function e(t) {
      var e,
        i = "position"
      return (
        t.currentStyle
          ? (e = t.currentStyle[i])
          : window.getComputedStyle && (e = getComputedStyle(t)[i]),
        "fixed" == e
      )
    }
    function i() {
      if (u.height() <= s.height()) return 0
      var t = $('<div style="visibility:hidden;width:100px" />').appendTo(
          "body"
        ),
        e = t[0].offsetWidth
      t.css("overflow", "scroll")
      var i = $('<div style="width:100%" />').appendTo(t),
        n = i[0].offsetWidth
      return t.remove(), e - n
    }
    function n(t, e, i) {
      if ($.isPlainObject(e)) for (var n in e) a(t, n, e[n])
      else a(t, e, i)
    }
    function a(t, e, i) {
      t.setAttribute(("role" == e ? "" : "aria-") + e, i)
    }
    function r(t, e) {
      $.isPlainObject(t) || (t = { attribute: e }), (e = "")
      for (var i in t) {
        var n = ("role" == i ? "" : "aria-") + i,
          a = t[i]
        e += null == a ? "" : n + '="' + t[i] + '"'
      }
      return e
    }
    function o() {
      try {
        return document.activeElement
      } catch (t) {}
    }
    var s = $(window),
      l = $(document),
      u = $(document.documentElement)
    return (
      (t.klasses = function(t) {
        return (
          (t = t || "picker"),
          {
            picker: t,
            opened: t + "--opened",
            focused: t + "--focused",
            input: t + "__input",
            active: t + "__input--active",
            target: t + "__input--target",
            holder: t + "__holder",
            frame: t + "__frame",
            wrap: t + "__wrap",
            box: t + "__box"
          }
        )
      }),
      (t._ = {
        group: function(e) {
          for (
            var i, n = "", a = t._.trigger(e.min, e);
            a <= t._.trigger(e.max, e, [a]);
            a += e.i
          )
            (i = t._.trigger(e.item, e, [a])),
              (n += t._.node(e.node, i[0], i[1], i[2]))
          return n
        },
        node: function(t, e, i, n) {
          return e
            ? ((e = $.isArray(e) ? e.join("") : e),
              (i = i ? ' class="' + i + '"' : ""),
              (n = n ? " " + n : ""),
              "<" + t + i + n + ">" + e + "</" + t + ">")
            : ""
        },
        lead: function(t) {
          return (10 > t ? "0" : "") + t
        },
        trigger: function(t, e, i) {
          return "function" == typeof t ? t.apply(e, i || []) : t
        },
        digits: function(t) {
          return /\d/.test(t[1]) ? 2 : 1
        },
        isDate: function(t) {
          return (
            {}.toString.call(t).indexOf("Date") > -1 &&
            this.isInteger(t.getDate())
          )
        },
        isInteger: function(t) {
          return {}.toString.call(t).indexOf("Number") > -1 && t % 1 === 0
        },
        ariaAttr: r
      }),
      (t.extend = function(e, i) {
        ;($.fn[e] = function(n, a) {
          var r = this.data(e)
          return "picker" == n
            ? r
            : r && "string" == typeof n
            ? t._.trigger(r[n], r, [a])
            : this.each(function() {
                var a = $(this)
                a.data(e) || new t(this, e, i, n)
              })
        }),
          ($.fn[e].defaults = i.defaults)
      }),
      t
    )
  }),
  (function(t) {
    "function" == typeof define && define.amd
      ? define(["picker", "jquery"], t)
      : "object" == typeof exports
      ? (module.exports = t(require("./picker.js"), require("jquery")))
      : t(Picker, jQuery)
  })(function(t, $) {
    function e(t, e) {
      var i = this,
        n = t.$node[0],
        a = n.value,
        r = t.$node.data("value"),
        o = r || a,
        s = r ? e.formatSubmit : e.format,
        l = function() {
          return n.currentStyle
            ? "rtl" == n.currentStyle.direction
            : "rtl" == getComputedStyle(t.$root[0]).direction
        }
      ;(i.settings = e),
        (i.$node = t.$node),
        (i.queue = {
          min: "measure create",
          max: "measure create",
          now: "now create",
          select: "parse create validate",
          highlight: "parse navigate create validate",
          view: "parse create validate viewset",
          disable: "deactivate",
          enable: "activate"
        }),
        (i.item = {}),
        (i.item.clear = null),
        (i.item.disable = (e.disable || []).slice(0)),
        (i.item.enable = -(function(t) {
          return t[0] === !0 ? t.shift() : -1
        })(i.item.disable)),
        i
          .set("min", e.min)
          .set("max", e.max)
          .set("now"),
        o
          ? i.set("select", o, { format: s })
          : i.set("select", null).set("highlight", i.item.now),
        (i.key = {
          40: 7,
          38: -7,
          39: function() {
            return l() ? -1 : 1
          },
          37: function() {
            return l() ? 1 : -1
          },
          go: function(t) {
            var e = i.item.highlight,
              n = new Date(e.year, e.month, e.date + t)
            i.set("highlight", n, { interval: t }), this.render()
          }
        }),
        t
          .on(
            "render",
            function() {
              t.$root.find("." + e.klass.selectMonth).on("change", function() {
                var i = this.value
                i &&
                  (t.set("highlight", [
                    t.get("view").year,
                    i,
                    t.get("highlight").date
                  ]),
                  t.$root.find("." + e.klass.selectMonth).trigger("focus"))
              }),
                t.$root.find("." + e.klass.selectYear).on("change", function() {
                  var i = this.value
                  i &&
                    (t.set("highlight", [
                      i,
                      t.get("view").month,
                      t.get("highlight").date
                    ]),
                    t.$root.find("." + e.klass.selectYear).trigger("focus"))
                })
            },
            1
          )
          .on(
            "open",
            function() {
              var n = ""
              i.disabled(i.get("now")) &&
                (n = ":not(." + e.klass.buttonToday + ")"),
                t.$root.find("button" + n + ", select").attr("disabled", !1)
            },
            1
          )
          .on(
            "close",
            function() {
              t.$root.find("button, select").attr("disabled", !0)
            },
            1
          )
    }
    var i = 7,
      n = 6,
      a = t._
    ;(e.prototype.set = function(t, e, i) {
      var n = this,
        a = n.item
      return null === e
        ? ("clear" == t && (t = "select"), (a[t] = e), n)
        : ((a["enable" == t ? "disable" : "flip" == t ? "enable" : t] = n.queue[
            t
          ]
            .split(" ")
            .map(function(a) {
              return (e = n[a](t, e, i))
            })
            .pop()),
          "select" == t
            ? n.set("highlight", a.select, i)
            : "highlight" == t
            ? n.set("view", a.highlight, i)
            : t.match(/^(flip|min|max|disable|enable)$/) &&
              (a.select && n.disabled(a.select) && n.set("select", a.select, i),
              a.highlight &&
                n.disabled(a.highlight) &&
                n.set("highlight", a.highlight, i)),
          n)
    }),
      (e.prototype.get = function(t) {
        return this.item[t]
      }),
      (e.prototype.create = function(t, e, i) {
        var n,
          r = this
        return (
          (e = void 0 === e ? t : e),
          e == -(1 / 0) || e == 1 / 0
            ? (n = e)
            : $.isPlainObject(e) && a.isInteger(e.pick)
            ? (e = e.obj)
            : $.isArray(e)
            ? ((e = new Date(e[0], e[1], e[2])),
              (e = a.isDate(e) ? e : r.create().obj))
            : (e =
                a.isInteger(e) || a.isDate(e)
                  ? r.normalize(new Date(e), i)
                  : r.now(t, e, i)),
          {
            year: n || e.getFullYear(),
            month: n || e.getMonth(),
            date: n || e.getDate(),
            day: n || e.getDay(),
            obj: n || e,
            pick: n || e.getTime()
          }
        )
      }),
      (e.prototype.createRange = function(t, e) {
        var i = this,
          n = function(t) {
            return t === !0 || $.isArray(t) || a.isDate(t) ? i.create(t) : t
          }
        return (
          a.isInteger(t) || (t = n(t)),
          a.isInteger(e) || (e = n(e)),
          a.isInteger(t) && $.isPlainObject(e)
            ? (t = [e.year, e.month, e.date + t])
            : a.isInteger(e) &&
              $.isPlainObject(t) &&
              (e = [t.year, t.month, t.date + e]),
          { from: n(t), to: n(e) }
        )
      }),
      (e.prototype.withinRange = function(t, e) {
        return (
          (t = this.createRange(t.from, t.to)),
          e.pick >= t.from.pick && e.pick <= t.to.pick
        )
      }),
      (e.prototype.overlapRanges = function(t, e) {
        var i = this
        return (
          (t = i.createRange(t.from, t.to)),
          (e = i.createRange(e.from, e.to)),
          i.withinRange(t, e.from) ||
            i.withinRange(t, e.to) ||
            i.withinRange(e, t.from) ||
            i.withinRange(e, t.to)
        )
      }),
      (e.prototype.now = function(t, e, i) {
        return (
          (e = new Date()),
          i && i.rel && e.setDate(e.getDate() + i.rel),
          this.normalize(e, i)
        )
      }),
      (e.prototype.navigate = function(t, e, i) {
        var n,
          a,
          r,
          o,
          s = $.isArray(e),
          l = $.isPlainObject(e),
          u = this.item.view
        if (s || l) {
          for (
            l
              ? ((a = e.year), (r = e.month), (o = e.date))
              : ((a = +e[0]), (r = +e[1]), (o = +e[2])),
              i && i.nav && u && u.month !== r && ((a = u.year), (r = u.month)),
              n = new Date(a, r + (i && i.nav ? i.nav : 0), 1),
              a = n.getFullYear(),
              r = n.getMonth();
            new Date(a, r, o).getMonth() !== r;

          )
            o -= 1
          e = [a, r, o]
        }
        return e
      }),
      (e.prototype.normalize = function(t) {
        return t.setHours(0, 0, 0, 0), t
      }),
      (e.prototype.measure = function(t, e) {
        var i = this
        return (
          e
            ? "string" == typeof e
              ? (e = i.parse(t, e))
              : a.isInteger(e) && (e = i.now(t, e, { rel: e }))
            : (e = "min" == t ? -(1 / 0) : 1 / 0),
          e
        )
      }),
      (e.prototype.viewset = function(t, e) {
        return this.create([e.year, e.month, 1])
      }),
      (e.prototype.validate = function(t, e, i) {
        var n,
          r,
          o,
          s,
          l = this,
          u = e,
          c = i && i.interval ? i.interval : 1,
          d = -1 === l.item.enable,
          f = l.item.min,
          p = l.item.max,
          h =
            d &&
            l.item.disable.filter(function(t) {
              if ($.isArray(t)) {
                var i = l.create(t).pick
                i < e.pick ? (n = !0) : i > e.pick && (r = !0)
              }
              return a.isInteger(t)
            }).length
        if (
          (!i || !i.nav) &&
          ((!d && l.disabled(e)) ||
            (d && l.disabled(e) && (h || n || r)) ||
            (!d && (e.pick <= f.pick || e.pick >= p.pick)))
        )
          for (
            d && !h && ((!r && c > 0) || (!n && 0 > c)) && (c *= -1);
            l.disabled(e) &&
            (Math.abs(c) > 1 &&
              (e.month < u.month || e.month > u.month) &&
              ((e = u), (c = c > 0 ? 1 : -1)),
            e.pick <= f.pick
              ? ((o = !0),
                (c = 1),
                (e = l.create([
                  f.year,
                  f.month,
                  f.date + (e.pick === f.pick ? 0 : -1)
                ])))
              : e.pick >= p.pick &&
                ((s = !0),
                (c = -1),
                (e = l.create([
                  p.year,
                  p.month,
                  p.date + (e.pick === p.pick ? 0 : 1)
                ]))),
            !o || !s);

          )
            e = l.create([e.year, e.month, e.date + c])
        return e
      }),
      (e.prototype.disabled = function(t) {
        var e = this,
          i = e.item.disable.filter(function(i) {
            return a.isInteger(i)
              ? t.day === (e.settings.firstDay ? i : i - 1) % 7
              : $.isArray(i) || a.isDate(i)
              ? t.pick === e.create(i).pick
              : $.isPlainObject(i)
              ? e.withinRange(i, t)
              : void 0
          })
        return (
          (i =
            i.length &&
            !i.filter(function(t) {
              return (
                ($.isArray(t) && "inverted" == t[3]) ||
                ($.isPlainObject(t) && t.inverted)
              )
            }).length),
          -1 === e.item.enable
            ? !i
            : i || t.pick < e.item.min.pick || t.pick > e.item.max.pick
        )
      }),
      (e.prototype.parse = function(t, e, i) {
        var n = this,
          r = {}
        return e && "string" == typeof e
          ? ((i && i.format) || ((i = i || {}), (i.format = n.settings.format)),
            n.formats.toArray(i.format).map(function(t) {
              var i = n.formats[t],
                o = i ? a.trigger(i, n, [e, r]) : t.replace(/^!/, "").length
              i && (r[t] = e.substr(0, o)), (e = e.substr(o))
            }),
            [r.yyyy || r.yy, +(r.mm || r.m) - 1, r.dd || r.d])
          : e
      }),
      (e.prototype.formats = (function() {
        function t(t, e, i) {
          var n = t.match(/\w+/)[0]
          return i.mm || i.m || (i.m = e.indexOf(n) + 1), n.length
        }
        function e(t) {
          return t.match(/\w+/)[0].length
        }
        return {
          d: function(t, e) {
            return t ? a.digits(t) : e.date
          },
          dd: function(t, e) {
            return t ? 2 : a.lead(e.date)
          },
          ddd: function(t, i) {
            return t ? e(t) : this.settings.weekdaysShort[i.day]
          },
          dddd: function(t, i) {
            return t ? e(t) : this.settings.weekdaysFull[i.day]
          },
          m: function(t, e) {
            return t ? a.digits(t) : e.month + 1
          },
          mm: function(t, e) {
            return t ? 2 : a.lead(e.month + 1)
          },
          mmm: function(e, i) {
            var n = this.settings.monthsShort
            return e ? t(e, n, i) : n[i.month]
          },
          mmmm: function(e, i) {
            var n = this.settings.monthsFull
            return e ? t(e, n, i) : n[i.month]
          },
          yy: function(t, e) {
            return t ? 2 : ("" + e.year).slice(2)
          },
          yyyy: function(t, e) {
            return t ? 4 : e.year
          },
          toArray: function(t) {
            return t.split(/(d{1,4}|m{1,4}|y{4}|yy|!.)/g)
          },
          toString: function(t, e) {
            var i = this
            return i.formats
              .toArray(t)
              .map(function(t) {
                return a.trigger(i.formats[t], i, [0, e]) || t.replace(/^!/, "")
              })
              .join("")
          }
        }
      })()),
      (e.prototype.isDateExact = function(t, e) {
        var i = this
        return (a.isInteger(t) && a.isInteger(e)) ||
          ("boolean" == typeof t && "boolean" == typeof e)
          ? t === e
          : (a.isDate(t) || $.isArray(t)) && (a.isDate(e) || $.isArray(e))
          ? i.create(t).pick === i.create(e).pick
          : $.isPlainObject(t) && $.isPlainObject(e)
          ? i.isDateExact(t.from, e.from) && i.isDateExact(t.to, e.to)
          : !1
      }),
      (e.prototype.isDateOverlap = function(t, e) {
        var i = this,
          n = i.settings.firstDay ? 1 : 0
        return a.isInteger(t) && (a.isDate(e) || $.isArray(e))
          ? ((t = (t % 7) + n), t === i.create(e).day + 1)
          : a.isInteger(e) && (a.isDate(t) || $.isArray(t))
          ? ((e = (e % 7) + n), e === i.create(t).day + 1)
          : $.isPlainObject(t) && $.isPlainObject(e)
          ? i.overlapRanges(t, e)
          : !1
      }),
      (e.prototype.flipEnable = function(t) {
        var e = this.item
        e.enable = t || (-1 == e.enable ? 1 : -1)
      }),
      (e.prototype.deactivate = function(t, e) {
        var i = this,
          n = i.item.disable.slice(0)
        return (
          "flip" == e
            ? i.flipEnable()
            : e === !1
            ? (i.flipEnable(1), (n = []))
            : e === !0
            ? (i.flipEnable(-1), (n = []))
            : e.map(function(t) {
                for (var e, r = 0; r < n.length; r += 1)
                  if (i.isDateExact(t, n[r])) {
                    e = !0
                    break
                  }
                e ||
                  ((a.isInteger(t) ||
                    a.isDate(t) ||
                    $.isArray(t) ||
                    ($.isPlainObject(t) && t.from && t.to)) &&
                    n.push(t))
              }),
          n
        )
      }),
      (e.prototype.activate = function(t, e) {
        var i = this,
          n = i.item.disable,
          r = n.length
        return (
          "flip" == e
            ? i.flipEnable()
            : e === !0
            ? (i.flipEnable(1), (n = []))
            : e === !1
            ? (i.flipEnable(-1), (n = []))
            : e.map(function(t) {
                var e, o, s, l
                for (s = 0; r > s; s += 1) {
                  if (((o = n[s]), i.isDateExact(o, t))) {
                    ;(e = n[s] = null), (l = !0)
                    break
                  }
                  if (i.isDateOverlap(o, t)) {
                    $.isPlainObject(t)
                      ? ((t.inverted = !0), (e = t))
                      : $.isArray(t)
                      ? ((e = t), e[3] || e.push("inverted"))
                      : a.isDate(t) &&
                        (e = [
                          t.getFullYear(),
                          t.getMonth(),
                          t.getDate(),
                          "inverted"
                        ])
                    break
                  }
                }
                if (e)
                  for (s = 0; r > s; s += 1)
                    if (i.isDateExact(n[s], t)) {
                      n[s] = null
                      break
                    }
                if (l)
                  for (s = 0; r > s; s += 1)
                    if (i.isDateOverlap(n[s], t)) {
                      n[s] = null
                      break
                    }
                e && n.push(e)
              }),
          n.filter(function(t) {
            return null != t
          })
        )
      }),
      (e.prototype.nodes = function(t) {
        var e = this,
          r = e.settings,
          o = e.item,
          s = o.now,
          l = o.select,
          u = o.highlight,
          c = o.view,
          d = o.disable,
          f = o.min,
          p = o.max,
          h = (function(t, e) {
            return (
              r.firstDay && (t.push(t.shift()), e.push(e.shift())),
              a.node(
                "thead",
                a.node(
                  "tr",
                  a.group({
                    min: 0,
                    max: i - 1,
                    i: 1,
                    node: "th",
                    item: function(i) {
                      return [
                        t[i],
                        r.klass.weekdays,
                        'scope=col title="' + e[i] + '"'
                      ]
                    }
                  })
                )
              )
            )
          })(
            (r.showWeekdaysFull ? r.weekdaysFull : r.weekdaysLetter).slice(0),
            r.weekdaysFull.slice(0)
          ),
          g = function(t) {
            return a.node(
              "div",
              " ",
              r.klass["nav" + (t ? "Next" : "Prev")] +
                ((t && c.year >= p.year && c.month >= p.month) ||
                (!t && c.year <= f.year && c.month <= f.month)
                  ? " " + r.klass.navDisabled
                  : ""),
              "data-nav=" +
                (t || -1) +
                " " +
                a.ariaAttr({
                  role: "button",
                  controls: e.$node[0].id + "_table"
                }) +
                ' title="' +
                (t ? r.labelMonthNext : r.labelMonthPrev) +
                '"'
            )
          },
          v = function(i) {
            var n = r.showMonthsShort ? r.monthsShort : r.monthsFull
            return (
              "short_months" == i && (n = r.monthsShort),
              r.selectMonths && void 0 == i
                ? a.node(
                    "select",
                    a.group({
                      min: 0,
                      max: 11,
                      i: 1,
                      node: "option",
                      item: function(t) {
                        return [
                          n[t],
                          0,
                          "value=" +
                            t +
                            (c.month == t ? " selected" : "") +
                            ((c.year == f.year && t < f.month) ||
                            (c.year == p.year && t > p.month)
                              ? " disabled"
                              : "")
                        ]
                      }
                    }),
                    r.klass.selectMonth + " browser-default",
                    (t ? "" : "disabled") +
                      " " +
                      a.ariaAttr({ controls: e.$node[0].id + "_table" }) +
                      ' title="' +
                      r.labelMonthSelect +
                      '"'
                  )
                : "short_months" == i
                ? null != l
                  ? a.node("div", n[l.month])
                  : a.node("div", n[c.month])
                : a.node("div", n[c.month], r.klass.month)
            )
          },
          m = function(i) {
            var n = c.year,
              o = r.selectYears === !0 ? 5 : ~~(r.selectYears / 2)
            if (o) {
              var s = f.year,
                l = p.year,
                u = n - o,
                d = n + o
              if ((s > u && ((d += s - u), (u = s)), d > l)) {
                var h = u - s,
                  g = d - l
                ;(u -= h > g ? g : h), (d = l)
              }
              if (r.selectYears && void 0 == i)
                return a.node(
                  "select",
                  a.group({
                    min: u,
                    max: d,
                    i: 1,
                    node: "option",
                    item: function(t) {
                      return [t, 0, "value=" + t + (n == t ? " selected" : "")]
                    }
                  }),
                  r.klass.selectYear + " browser-default",
                  (t ? "" : "disabled") +
                    " " +
                    a.ariaAttr({ controls: e.$node[0].id + "_table" }) +
                    ' title="' +
                    r.labelYearSelect +
                    '"'
                )
            }
            return "raw" == i
              ? a.node("div", n)
              : a.node("div", n, r.klass.year)
          }
        return (
          (createDayLabel = function() {
            return null != l ? a.node("div", l.date) : a.node("div", s.date)
          }),
          (createWeekdayLabel = function() {
            var t
            t = null != l ? l.day : s.day
            var e = r.weekdaysFull[t]
            return e
          }),
          a.node(
            "div",
            a.node("div", createWeekdayLabel(), "picker__weekday-display") +
              a.node("div", v("short_months"), r.klass.month_display) +
              a.node("div", createDayLabel(), r.klass.day_display) +
              a.node("div", m("raw"), r.klass.year_display),
            r.klass.date_display
          ) +
            a.node(
              "div",
              a.node(
                "div",
                (r.selectYears ? v() + m() : v() + m()) + g() + g(1),
                r.klass.header
              ) +
                a.node(
                  "table",
                  h +
                    a.node(
                      "tbody",
                      a.group({
                        min: 0,
                        max: n - 1,
                        i: 1,
                        node: "tr",
                        item: function(t) {
                          var n =
                            r.firstDay &&
                            0 === e.create([c.year, c.month, 1]).day
                              ? -7
                              : 0
                          return [
                            a.group({
                              min: i * t - c.day + n + 1,
                              max: function() {
                                return this.min + i - 1
                              },
                              i: 1,
                              node: "td",
                              item: function(t) {
                                t = e.create([
                                  c.year,
                                  c.month,
                                  t + (r.firstDay ? 1 : 0)
                                ])
                                var i = l && l.pick == t.pick,
                                  n = u && u.pick == t.pick,
                                  o =
                                    (d && e.disabled(t)) ||
                                    t.pick < f.pick ||
                                    t.pick > p.pick,
                                  h = a.trigger(e.formats.toString, e, [
                                    r.format,
                                    t
                                  ])
                                return [
                                  a.node(
                                    "div",
                                    t.date,
                                    (function(e) {
                                      return (
                                        e.push(
                                          c.month == t.month
                                            ? r.klass.infocus
                                            : r.klass.outfocus
                                        ),
                                        s.pick == t.pick && e.push(r.klass.now),
                                        i && e.push(r.klass.selected),
                                        n && e.push(r.klass.highlighted),
                                        o && e.push(r.klass.disabled),
                                        e.join(" ")
                                      )
                                    })([r.klass.day]),
                                    "data-pick=" +
                                      t.pick +
                                      " " +
                                      a.ariaAttr({
                                        role: "gridcell",
                                        label: h,
                                        selected:
                                          i && e.$node.val() === h ? !0 : null,
                                        activedescendant: n ? !0 : null,
                                        disabled: o ? !0 : null
                                      })
                                  ),
                                  "",
                                  a.ariaAttr({ role: "presentation" })
                                ]
                              }
                            })
                          ]
                        }
                      })
                    ),
                  r.klass.table,
                  'id="' +
                    e.$node[0].id +
                    '_table" ' +
                    a.ariaAttr({
                      role: "grid",
                      controls: e.$node[0].id,
                      readonly: !0
                    })
                ),
              r.klass.calendar_container
            ) +
            a.node(
              "div",
              a.node(
                "button",
                r.today,
                "btn-flat picker__today",
                "type=button data-pick=" +
                  s.pick +
                  (t && !e.disabled(s) ? "" : " disabled") +
                  " " +
                  a.ariaAttr({ controls: e.$node[0].id })
              ) +
                a.node(
                  "button",
                  r.clear,
                  "btn-flat picker__clear",
                  "type=button data-clear=1" +
                    (t ? "" : " disabled") +
                    " " +
                    a.ariaAttr({ controls: e.$node[0].id })
                ) +
                a.node(
                  "button",
                  r.close,
                  "btn-flat picker__close",
                  "type=button data-close=true " +
                    (t ? "" : " disabled") +
                    " " +
                    a.ariaAttr({ controls: e.$node[0].id })
                ),
              r.klass.footer
            )
        )
      }),
      (e.defaults = (function(t) {
        return {
          labelMonthNext: "Next month",
          labelMonthPrev: "Previous month",
          labelMonthSelect: "Select a month",
          labelYearSelect: "Select a year",
          monthsFull: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          monthsShort: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          weekdaysFull: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          weekdaysLetter: ["S", "M", "T", "W", "T", "F", "S"],
          today: "Today",
          clear: "Clear",
          close: "Close",
          format: "d mmmm, yyyy",
          klass: {
            table: t + "table",
            header: t + "header",
            date_display: t + "date-display",
            day_display: t + "day-display",
            month_display: t + "month-display",
            year_display: t + "year-display",
            calendar_container: t + "calendar-container",
            navPrev: t + "nav--prev",
            navNext: t + "nav--next",
            navDisabled: t + "nav--disabled",
            month: t + "month",
            year: t + "year",
            selectMonth: t + "select--month",
            selectYear: t + "select--year",
            weekdays: t + "weekday",
            day: t + "day",
            disabled: t + "day--disabled",
            selected: t + "day--selected",
            highlighted: t + "day--highlighted",
            now: t + "day--today",
            infocus: t + "day--infocus",
            outfocus: t + "day--outfocus",
            footer: t + "footer",
            buttonClear: t + "button--clear",
            buttonToday: t + "button--today",
            buttonClose: t + "button--close"
          }
        }
      })(t.klasses().picker + "__")),
      t.extend("pickadate", e)
  })
