import {
  Goal,
  GoalPriority,
  GoalType,
  UpdatingGoal,
  UpdatingTeamGoal,
} from "../../types/Goals"

export const encodeGoalPriority = (priority: GoalPriority): number => {
  switch (priority) {
    case "low":
      return 0
    case "medium":
      return 1
    case "high":
      return 2
  }
}

export const encodeGoalType = (goalType: GoalType): string => {
  switch (goalType) {
    case "personal":
      return "my_goal"
    case "team":
      return "team_goal"
  }
  return ""
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeGoal = (goal: Goal): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const goalData: any = {
    type: encodeGoalType(goal.type),
    due_at: goal.dueDate.toISOString(),
    name: goal.name,
    description: goal.description || "",
    priority: encodeGoalPriority(goal.priority),
    visibility: goal.visibility,
  }
  goalData.key_results = goal.keyResults.map((kr) => ({
    id: kr.id,
    title: kr.title,
    completion: kr.completion,
  }))

  if (goal.type === "personal" && goal.visibility === "individuals") {
    goalData.access_permission = goal.individuals
      ? { user_ids: goal.individuals.map((u) => u.id), permission: "read" }
      : undefined
  }

  if (goal.keyResults.length === 0) {
    goalData.completion = goal.completion
  }

  if (goal.type === "team") {
    goalData.goal_owners = goal.owners && goal.owners.map((owner) => owner.id)
    goalData.team_id = goal.teamId
    goalData.status = "created"
    goalData.goals_skills_attributes = []
    goalData.outgoing_alignment_associations_attributes = []
  }

  return { goal: goalData }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeGoalToUpdate = (goal: UpdatingGoal): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const goalData: any = {
    name: goal.name,
    priority: goal.priority ? encodeGoalPriority(goal.priority) : undefined,
    description: goal.description,
    due_at: goal.dueDate && goal.dueDate.toISOString(),
    visibility: goal.visibility,
    key_results:
      goal.keyResults &&
      goal.keyResults.map((kr) => ({
        id: kr.id,
        title: kr.title,
        _destroy: kr.destroy,
        completion: kr.completion,
      })),
    completion: goal.keyResults ? undefined : goal.completion,
    status: goal.status,
    goal_owners: (goal as UpdatingTeamGoal).owners,
    outgoing_alignment_associations_attributes: goal.alignedGoals,
    access_permission:
      // @ts-ignore
      goal.visibility === "individuals" && goal.individuals
        ? {
            // @ts-ignore
            user_ids: goal.individuals.map((u) => u.id),
            permission: "read",
          }
        : undefined,
  }
  return { goal: goalData }
}
