import { JsonDecoder } from "ts.data.json"
import paginationDecoder from "../PaginationDecoder/paginationDecoder"
import { Department } from "../../../types/User"
const { array, object, number, string, failover } = JsonDecoder

const departmentDecoder = object<Department>(
  {
    id: number,
    title: string,
    job_title_type: failover("", string),
  },
  "departmentsDecoder department",
  { title: "name" }
)

export const departmentsDecoder = object(
  {
    departments: array(departmentDecoder, "departmentsDecoder departments"),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder,
      },
      "meta"
    ),
  },
  "departmentsDecoder"
).map(({ departments, meta }) => ({
  departments: departments.map((department) => ({
    id: department.id,
    title: department.title,
    job_title_type: department.job_title_type,
  })),
  pagination: meta.pagination,
}))

export default departmentsDecoder
