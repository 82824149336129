import * as React from "react"
import { useEffect } from "react"
import { InjectedIntl, injectIntl } from "react-intl"
import { WithRouterProps } from "react-router"
import { Team } from "../../context/TeamsState"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import useNotifications from "../../hooks/toastNotifications/useNotifications"
import TeamGoalForm, {
  TeamGoalFields,
} from "../../containers/TeamGoalForm/TeamGoalForm"
import useCreateTeamGoal from "../../domainHooks/goals/useCreateTeamGoal"
import strings from "../../locale/strings"
import { getGoalCycle } from "../../domain/user/user"
import { newKeyResult } from "../../domainHooks/goals/goalKeyResults"
import { trackTeamGoalCreated } from "../../utils/analytics"
import { TeamGoalOwner } from "../../types/Goals"

type NewTeamGoal = React.FunctionComponent<
  {
    intl: InjectedIntl
  } & WithRouterProps
>

export const formDisabled = (loading: boolean, teams: Team[]) =>
  loading || teams.length === 0

const NewTeamGoal: NewTeamGoal = ({ intl, router, location }) => {
  const { formatMessage } = intl
  const { showNotification } = useNotifications()
  const currentUser = useCurrentUser()
  // Linting thinks initialTeam is used before defined
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const initialTeam = location?.state?.initialTeam

  const { createTeamGoal, loading, data, error } = useCreateTeamGoal()

  useEffect(() => {
    if (data) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.newTeamGoal.newTeamGoalSuccessTitle),
        message: formatMessage(
          strings.newTeamGoal.newTeamGoalSuccessNotification
        ),
      })

      trackTeamGoalCreated()

      router.push({
        pathname: `/new_goals/team`,
        query: {
          // @ts-ignore
          goalId: data.goal.id,
        },
      })
    }
  }, [data, formatMessage, router, showNotification])

  useEffect(() => {
    if (error) {
      showNotification({
        type: "negative",
        title: formatMessage(strings.newTeamGoal.newTeamGoalErrorTitle),
        message: formatMessage(
          strings.newTeamGoal.newTeamGoalErrorNotification
        ),
      })
    }
  }, [error, formatMessage, showNotification])

  const initOwners: TeamGoalOwner[] = [
    {
      id: currentUser.id,
      aggregateId: currentUser.employee_aggregate_id,
      name: currentUser.best_name,
      profileImage: currentUser.profile_image_url,
      role: currentUser.job_title_name ? currentUser.job_title_name : "",
      email: currentUser.email,
    },
  ]
  const newGoal: TeamGoalFields = {
    name: "",
    teamId: "",
    description: "",
    dueDate: getGoalCycle(currentUser).end,
    priority: 1,
    owners: initOwners,
    keyResults: [newKeyResult()],
    alignedGoalIds: [],
  }

  return (
    <TeamGoalForm
      title={formatMessage(strings.newTeamGoal.title)}
      submitLabel={formatMessage(strings.newTeamGoal.submitLabel)}
      defaultFields={newGoal}
      onSubmit={createTeamGoal}
      onLeavePage={() => router.push("/new_goals/team")}
      disabled={loading}
      initOwners={initOwners}
      initialTeam={initialTeam}
    />
  )
}

export default injectIntl(NewTeamGoal)
