import _ from "lodash"
import * as logging from "./constants/logging"

const CHROME_EXTENSION_ID_DEVELOPMENT = "beldojngkknecoijbmeeboebiolebiag"
const CHROME_EXTENSION_ID = "pjlfgiceggnffgieobmgpmibpcidmadf"

const CONSTANTS = {
  AUTH: {
    PROVIDERS: {
      BASIC: "basic",
      SAML: "saml",
    },
    DEFAULT_INTEGRATIONS: [
      {
        provider: "basic",
      },
    ],
    PROVIDER_ORDERING: _.invert(["saml", "basic"]),
  },

  IMPORT: {
    PROVIDERS: {
      EXCHANGE: "exchange",
      SLACK: "slack",
      BASIC: "basic", // TODO deprecate in Steadyfoot
    },
  },

  PROVIDER_COLORS: {},

  SLACK_REDIRECT_URI: `https://${window.location.host}/performance/profile?to=setup&redirect=true`,
  SLACK_SCOPES: [
    "im:read",
    "groups:read",
    "team:read",
    "users:read",
    "users:read.email",
    "search:read",
  ],

  EXCHANGE_SCOPES: ["EWS.AccessAsUser.All"],

  EXCHANGE_AUTHORITY: "https://login.microsoftonline.com/common",
  EXCHANGE_GRAPH_ENDPOINT: "https://graph.microsoft.com/v1.0/me",

  // Colors for arbitrary values are determined by scaling this scale proportionally
  // and interpolating values
  RATING_COLOR_SCALE: {
    0: "#e1e2ea",
    1: "#c4c5d4",
    2: "#a7a9c1",
    3: "#898ba9",
    4: "#6b6e94",
  },

  // Keep in sync with CSS
  MOBILE_WIDTH: 500,
  SMALL_SCREEN_WIDTH: 800,

  COLORS: {
    MANATEE: "#9898AD",
    LIGHT_BLUE: "#229793",
    GREEN_BRIGHT: "#4CAF50",
    SEEDLING: "#21A38B",
    TEXT_COLOR: "#31315C",
    TEXT_COLOR_FADED: "rgba(49, 49, 92, 0.45)",
    // TEXT_COLOR_SECONDARY: '#9898AD',
    BLOCKED_CONTENT_COLOR: "#E9EBEC",
    STATS_PROGRESS_COLOR: "#5176C9",
    COMPLETED_COLOR: "#7EA763",

    CREATED: "#9898AD",
    IN_PROGRESS: "#E1AA11",
    BLOCKED: "#DF5A49",
    ACCOMPLISHED: "#7EA763",

    TOOLTIP_BACKGROUND: "#FFFFFF",
    TOOLTIP_FONT: "#31315C",
    TOOLTIP_BORDER: "#35374A",

    FEEDBACK_STATS: {
      SENT: "#5176C9",
      READ: "#E1AA11",
      LIKED: "#C95191",
      COMMENTS: "#7EA763",
      REQUESTS: "#6E41CF",
    },
  },

  EXPERIMENTS: {
    DEFAULT_GROUP: "0",
    FIRST_VARIATION: "1",
  },

  POINTS: {
    FEEDBACK_COMPLETE: 10,
    FEEDBACK_OPENED: -10,
  },

  CSV_EXPORT_POLL_INTERVAL: 1000,
  USER_CSV_IMPORT_POLL_INTERVAL: 3000,
  CYCLE_POLL_TIMER_INTERVAL: 60000,
  NOTIFICATION_POLL_INTERVAL: 1000 * 60,
  AUTOSAVE_DELAY: 1500,
  SEARCHFIELD_DEBOUNCE: 300,

  GOALS: {
    DUE_AT_HOUR: 17,
  },

  TOS_LINK: "https://www.cultureamp.com/terms/",
  PRIVACY_POLICY_LINK: "https://www.cultureamp.com/privacy.html",
  FAQ_LINK:
    "https://academy.cultureamp.com/hc/en-us/sections/360001253640-Performance",
  PERMISSIONS_LINK:
    "https://academy.cultureamp.com/hc/en-us/articles/360004126480",
  OUTLOOK_ARTICLE_LINK:
    "https://help.zugata.com/workflow-integrations/give-real-time-feedback-with-microsoft-outlook-add-in-beta",
  CHANGE_PASSWORD_LINK: "/my/password",
  CHANGE_PROFILE_PIC_LINK: "/my/profile",

  NUM_MONTHS_OF_PROGRESS: 6,
  IMPORT_CONTACTS_INTEGRATIONS: true,

  FEATURE_FLAGS: {
    ADMIN_USER_UPLOAD: true,
    UPDATE_MANAGER_REVIEWER: false,
  },

  BEACONSTAR_URL: "/performance",
  STEADYFOOT_URL: "/performance/api/v1",
  PERFORMANCE_API_GQL_URL: "/performance/api/v2/graphql",
  PERFORMANCE_API_WS_URL: "/performance/api/cable",
  CULTUREAMP_JURISDICTION_DOMAIN: "cultureamp.com",

  LOG_TAGS: [],

  USE_SUBDOMAINS: false,

  TREASURE_DATA_DATABASE: "incoming_frontend",
  TREASURE_DATA_PATHNAME: "/",
  TREASURE_DATA_DEVELOPMENT: false,
  TREASURE_DATA_HOST: "analytics.usw2.dev-us.cultureamp.io",
  TREASURE_DATA_WRITE_KEY: "zymfzj68Ubr4c7qA8r5qVFVzZXNY639BYKthqUhc",

  CHROME_EXTENSION_ID: CHROME_EXTENSION_ID_DEVELOPMENT,
  SENTRY_ENABLED: false,
  // default to main US Sentry project (overriden by EU)
  SENTRY_URL: "https://be0953292126452b928c7602a5d8a3e7@sentry.io/2255089",

  GMAIL_ORIGIN: "https://mail.google.com",

  WHY_DID_YOU_RENDER_DEBUGGER: false,
}
/**
 * DEVELOPMENT SETTINGS
 */
const development = _.assign({}, CONSTANTS, {
  DISABLE_INTERCOM: true,
  EXCHANGE_OAUTH_CLIENT_ID: "dff99cea-2831-4ffd-8b1e-c42b648ec4a4",

  SLACK_CLIENT_ID: "32704674261.844084224612",

  INTERCOM_ID: "l4097a7j",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  LOG_TAGS: [
    logging.REFLUX_ACTIONS,
    logging.SENTRY_EVENTS,
    logging.AUTOMATIC_REDIRECTS,
    logging.CURRENT_USER_STORE,
  ],
  TREASURE_DATA_DEVELOPMENT: process.env.LOG_TREASURE_DATA,
})

/**
 * SANDBOX SETTINGS extends DEVELOPMENT
 */
const sandbox = _.assign({}, development, {
  DISABLE_INTERCOM: false,
  EXCHANGE_OAUTH_CLIENT_ID: "49557ef9-f8de-4af7-b925-a576693ae84e",

  SLACK_CLIENT_ID: "32704674261.844084224612",

  ZUGATA_CLIENT_ID:
    "14632d28940f60b2cb6f308acbfd5c040295365b634576d8c574f6f1945ffbe4",

  INTERCOM_ID: "l4097a7j",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  SENTRY_ENABLED: true,
})

/**
 * TEST SETTINGS extends DEVELOPMENT
 */
const test = _.assign({}, development, {
  // Don't hit actual server!
  DISABLE_INTERCOM: false,
  STEADYFOOT_URL: "http://steadyfoot_url/",
  BEACONSTAR_URL: "http://beaconstar_url/",
  TREASURE_DATA_DEVELOPMENT: true,
})

/**
 * DEMO SETTINGS extends DEVELOPMENT
 */
const demo = _.assign({}, development, {
  DISABLE_INTERCOM: false,
  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,

  IMPORT_CONTACTS_INTEGRATIONS: false,
})

const dogfood = {
  ...demo,
  BEACONSTAR_URL: "https://zugata.zugata.me/",
}

/**
 * PRODUCTION SETTINGS
 */
const production = _.assign({}, CONSTANTS, {
  // TODO: Need to update after we create one for production
  EXCHANGE_OAUTH_CLIENT_ID: "",

  SENTRY_ENABLED: true,

  SLACK_REDIRECT_URI:
    "https://identity.cultureamp.com/performance/profile?to=setup&redirect=true",
  SLACK_CLIENT_ID: "2891252727.582446413717",

  ZUGATA_CLIENT_ID:
    "6da3fb928c72ea8ff9d94475a86c1f63ae75e972a245430758b1d52b09899a30",

  INTERCOM_ID: "m6btd41m",

  FEATURE_FLAGS: CONSTANTS.FEATURE_FLAGS,
  USE_SUBDOMAINS: true,

  TREASURE_DATA_HOST: "in.analytics.cultureamp.com",
  TREASURE_DATA_WRITE_KEY: "hbq3mkhC9MP7jkuCf6e9nhF2CYzrKpzdjCDK3MEy",

  CHROME_EXTENSION_ID: CHROME_EXTENSION_ID,
})

/**
 * PRODUCTION-EU SETTINGS
 */
const productionEu = _.assign({}, production, {
  LOGO_PATH: "/images-2017/CA-logo.svg",
  SLACK_REDIRECT_URI:
    "https://identity.eu.cultureamp.com/performance/profile?to=setup&redirect=true",
  SENTRY_URL: "https://240a983a37e6470cba9a4184cbbab6c6@sentry.io/1851539",
})

// Use process.env to select the environment
let Settings = {
  development,
  test,
  sandbox,
  production,
  demo,
  dogfood,
  production_eu: productionEu, // eslint-disable-line camelcase
  // @ts-ignore
}[process.env.ENVIRONMENT]

if (!Settings) {
  throw new Error(
    "no Settings found for node environment: " + process.env.ENVIRONMENT
  )
}

// Support settings overrides from env. Don't do this for the test env, though,
// because that is meant to run in a fixed environment.
if (process.env.ENVIRONMENT !== "test") {
  Settings = _.defaults(
    {
      BEACONSTAR_URL:
        process.env.BEACONSTAR_EXTERNAL_URL || CONSTANTS.BEACONSTAR_URL,
      STEADYFOOT_URL:
        process.env.STEADYFOOT_EXTERNAL_URL || CONSTANTS.STEADYFOOT_URL,
      PERFORMANCE_API_GQL_URL:
        process.env.PERFORMANCE_API_GQL_URL ||
        CONSTANTS.PERFORMANCE_API_GQL_URL,
      // Comma separated string, which gets split into an array by webpack.config.js
      // For a list of possible log tags, see src/constants/logging.ts
      LOG_TAGS: process.env.LOG_TAGS,
      WHY_DID_YOU_RENDER_DEBUGGER: process.env.WHY_DID_YOU_RENDER_DEBUGGER,
      SLACK_CLIENT_ID: process.env.BEACONSTAR_SLACK_CLIENT_ID,
      CULTUREAMP_JURISDICTION_DOMAIN:
        process.env.CULTUREAMP_JURISDICTION_DOMAIN,
      TREASURE_DATA_DEVELOPMENT: process.env.LOG_TREASURE_DATA
        ? process.env.LOG_TREASURE_DATA === "1"
        : CONSTANTS.TREASURE_DATA_DEVELOPMENT,
    },
    Settings
  )
}

export default Settings
