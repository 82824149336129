import { useCallback, useContext } from "react"
import { Omit } from "yargs"
import {
  LocalNotification,
  NotificationsContext,
} from "../../context/Notifications"

const useNotifications = () => {
  const { state, dispatch } = useContext(NotificationsContext)

  const showNotification = useCallback(
    (notification: Omit<LocalNotification, "id">) => {
      dispatch({ type: "SHOW", payload: notification })
    },
    [dispatch]
  )

  const hideNotification = useCallback(
    (notificationId: string) => {
      dispatch({ type: "HIDE", payload: notificationId })
    },
    [dispatch]
  )

  const notifications = Object.values(state.notificationsById)

  return {
    notifications,
    showNotification,
    hideNotification,
  }
}

export default useNotifications
