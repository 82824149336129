import { Text } from "@kaizen/component-library"
import { ConfirmationModal as ConfirmationModalKaizen } from "@kaizen/draft-modal"
import * as React from "react"
import styles from "./ConfirmationModal.scss"

type modalType = "positive" | "informative" | "negative" | "cautionary"

// These props are the same as the kaizen modal props, with the one exception
// being that we accept a message, instead of children for the modal body.
export type Props = {
  isOpen?: boolean
  onConfirm: () => void
  onDismiss: () => void
  title: string
  message: string
  confirmLabel?: string
  dismissLabel?: string
  type?: modalType
}

const ConfirmationModal = ({
  isOpen = true,
  onConfirm,
  onDismiss,
  title,
  message,
  confirmLabel,
  dismissLabel,
  type = "negative",
}: Props) => {
  return (
    <ConfirmationModalKaizen
      isOpen={isOpen}
      type={type}
      title={title}
      confirmLabel={confirmLabel}
      dismissLabel={dismissLabel}
      onDismiss={onDismiss}
      onConfirm={onConfirm}
    >
      <div className={styles.modalContent}>
        <Text tag="p" style="lede" inline>
          {message}
        </Text>
      </div>
    </ConfirmationModalKaizen>
  )
}

export default ConfirmationModal
