import { TableCard, TableContainer } from "@kaizen/draft-table"
import * as React from "react"
import { useState, useEffect, useCallback } from "react"
import { InjectedRouter } from "react-router"
import classnames from "classnames"
import { injectIntl, InjectedIntl } from "react-intl"
import { parse } from "query-string"
import { Location } from "history"
import Loading from "../../components/Loading/Loading"
import useDepartmentSummaries from "../../domainHooks/goals/useDepartmentSummaries"
import GoalsSummaryCell from "../../components/GoalsSummaryCell/GoalsSummaryCell"
import ScrollContainer from "../../components/ScrollContainer/ScrollContainer"
import DepartmentGoalsList from "./DepartmentGoalsList"
import styles from "./DepartmentSummariesList.scss"
import GoalsTableHeader from "../../components/GoalsTableHeader/GoalsTableHeader"
import strings from "../../locale/strings"
import FiltersBar, { Filters } from "../../components/FiltersBar/FiltersBar"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import GoalsEmptyState from "../GoalsEmptyState/GoalsEmptyState"
// @ts-ignore
import ErrorState from "../ErrorState/ErrorState"

const datePickerFilterName = (index: number) => (index === 0 ? "from" : "to")

type DepartmentSummariesList = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
  intl: InjectedIntl
}>

const DepartmentSummariesList: DepartmentSummariesList = ({
  router,
  location,
  intl,
}) => {
  const currentUser = useCurrentUser()

  const defaultFilters = {
    my_department: true,
    q: "",
  }
  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const {
    departments,
    fetchMore: fetchMoreDepartments,
    loading: loadingDepartments,
    hasMore: hasMoreDepartments,
    error,
  } = useDepartmentSummaries(filters)

  const setSelectedDepartmentId = useCallback(
    (selectedDepartmentId: number | undefined) => {
      router.push({
        pathname: location.pathname,
        query: {
          ...location.query,
          selectedDepartmentId,
        },
      })
    },
    [location.pathname, location.query, router]
  )
  const { selectedDepartmentId } = parse(location.search)

  const { my_department: myDepartment } = filters

  useEffect(() => {
    if (myDepartment && selectedDepartmentId) {
      setSelectedDepartmentId(undefined)
    }
  }, [myDepartment, selectedDepartmentId, setSelectedDepartmentId])

  const departmentWithGoals = departments.filter(
    (department) => department.goalsCount > 0
  )

  return (
    <div>
      {currentUser.company.allow_everyone_to_see_department_goals && (
        <FiltersBar
          onFiltersChange={setFilters}
          defaultFilters={defaultFilters}
        >
          <div className={classnames([styles.filter])}>
            <FiltersBar.Select
              name="my_department"
              options={[
                {
                  value: true,
                  label: intl.formatMessage(
                    strings.goalsPage.departmentGoals.myDepartment
                  ),
                },
                {
                  value: false,
                  label: intl.formatMessage(
                    strings.goalsPage.departmentGoals.allDepartments
                  ),
                },
              ]}
            />
          </div>
          <div className={styles.filter}>
            <FiltersBar.DatePicker name={datePickerFilterName} allowDateRange />
          </div>
          <div className={classnames([styles.filter, styles.filterRight])}>
            <FiltersBar.Search name="q" />
          </div>
        </FiltersBar>
      )}

      <ScrollContainer
        onScrolledToBottom={
          myDepartment
            ? () => {}
            : () => hasMoreDepartments && fetchMoreDepartments()
        }
      >
        {loadingDepartments && !hasMoreDepartments ? (
          <Loading />
        ) : departmentWithGoals.length > 0 ? (
          myDepartment ? (
            /**
             * Display my departments
             */
            <React.Fragment>
              <GoalsTableHeader goalType={"department"} />
              <DepartmentGoalsList
                department={departmentWithGoals[0]}
                filters={filters}
              />
            </React.Fragment>
          ) : (
            /**
             * Display all departments
             */
            <React.Fragment>
              <GoalsTableHeader goalType={"department"} />
              <TableContainer>
                {departmentWithGoals.map((department) => {
                  const departmentSelected =
                    typeof selectedDepartmentId === "string" &&
                    department.id === parseInt(selectedDepartmentId)
                  return (
                    <TableCard
                      key={department.id}
                      expanded={departmentSelected}
                      onClick={
                        departmentSelected
                          ? undefined
                          : () => setSelectedDepartmentId(department.id)
                      }
                    >
                      {departmentSelected ? (
                        <React.Fragment>
                          <div className={styles.topSpace} />
                          <DepartmentGoalsList
                            filters={filters}
                            department={department}
                            onSelectDepartment={() =>
                              setSelectedDepartmentId(undefined)
                            }
                          />
                        </React.Fragment>
                      ) : (
                        <div className={styles.department}>
                          <GoalsSummaryCell
                            ownerName={department.name}
                            progress={Math.floor(
                              department.overallProgress * 100
                            )}
                            goalsCount={department.goalsCount}
                            expandable
                            open={false}
                          />
                        </div>
                      )}
                    </TableCard>
                  )
                })}
              </TableContainer>
              {loadingDepartments && hasMoreDepartments && <Loading />}
            </React.Fragment>
          )
        ) : error ? (
          <ErrorState />
        ) : (
          <GoalsEmptyState
            headingText={strings.departmentSummariesList.emptyStateHeadingText}
            bodyText={strings.departmentSummariesList.emptyStateBodyText}
            illustrationType={"neutral"}
          />
        )}
      </ScrollContainer>
    </div>
  )
}

export default injectIntl(DepartmentSummariesList)
