import {
  useState,
  useLayoutEffect,
  useCallback,
  RefObject,
  DependencyList,
} from "react"
import { useDebouncedCallback } from "use-debounce"
import { Size } from "../../types/Positioning"
import { getViewportDimensions } from "../../utils/dom"

/**
 * Hook to return the browser viewport dimensions.
 *
 * WARNING: I would advise against using this hook where possible. If we were
 * to ever implement server-side rendering, this function will not work, because
 * the server will not know what the user's browser width is. Please favour
 * using css media queries instead.
 */
export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState<Size>({
    width: 0,
    height: 0,
  })

  const measure = useCallback(() => {
    setDimensions(getViewportDimensions())
  }, [])
  const [measureDb] = useDebouncedCallback(measure, 200)

  useLayoutEffect(() => {
    measure()

    window.addEventListener("resize", measureDb)

    return () => {
      window.removeEventListener("resize", measureDb)
    }
  }, [measure, measureDb])

  return dimensions
}

/**
 * Hook to get the dimensions of a dom element.
 * Note, this implementation is fairly basic, and won't catch all scenarios.
 * It will remeasure when the window is resized.
 * To force a remeasure, pass in values to the `deps` array.
 */
export const useDimensions = (
  domRef: RefObject<HTMLElement>,
  deps: DependencyList = []
) => {
  const [dimensions, setDimensions] = useState<Size | null>(null)
  const measure = useCallback(() => {
    if (!domRef.current) {
      setDimensions(null)
    } else {
      setDimensions({
        width: domRef.current.offsetWidth,
        height: domRef.current.offsetHeight,
      })
    }
    // I'm not sure how else to solve it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domRef, ...deps])
  const [measureDb] = useDebouncedCallback(measure, 200)

  useLayoutEffect(() => {
    measure()

    window.addEventListener("resize", measureDb)

    return () => {
      window.removeEventListener("resize", measureDb)
    }
  }, [domRef, measure, measureDb])

  return dimensions
}
