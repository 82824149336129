import React, { useEffect } from "react"
import _ from "lodash"
import { withRouter, WithRouterProps } from "react-router"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import useTeamGoals from "../../domainHooks/goals/useTeamGoals"
import { TeamPreview } from "../../context/TeamSummariesState"
import GoalsTable from "../GoalsTable/GoalsTable"

type Filters = {
  from?: Date
  to?: Date
}

type TeamGoalsList = React.FunctionComponent<
  {
    team: TeamPreview
    filters: Filters
    onSelectTeam?: () => void
  } & WithRouterProps
>

const TeamGoalsList: TeamGoalsList = ({
  team,
  filters,
  onSelectTeam,
  router,
  location,
}) => {
  const {
    teamGoals,
    goalsCount,
    fetch: fetchTeamGoals,
    loading,
  } = useTeamGoals(team.id, filters)
  useEffect(() => {
    fetchTeamGoals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team.id])
  const { open: openGoalDossier } = useGoalDossier(router, location)
  return (
    <React.Fragment>
      <GoalsTable
        goals={teamGoals}
        goalsCount={goalsCount}
        displayOwners={true}
        titleName={team.name}
        goalProgress={Math.floor(team.overallProgress * 100)}
        openGoal={(goalId) => {
          openGoalDossier(goalId)
        }}
        onClose={onSelectTeam ? () => onSelectTeam() : undefined}
        loading={loading}
      />
    </React.Fragment>
  )
}

export default _.compose(withRouter)(TeamGoalsList)
