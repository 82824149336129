import * as React from "react"
import { createContext, useReducer } from "react"
import { Pagination } from "../api/decoders/PaginationDecoder/paginationDecoder"
import { GoalStats } from "../api/decoders/GoalStatsDecoder/goalStatsDecoder"
import {
  Goal,
  GoalPreview,
  PersonalGoalPreview,
  TeamGoalPreview,
  DepartmentGoalPreview,
  CompanyGoalPreview,
} from "../types/Goals"

const INITIAL_STATE: GoalsState = {
  goalsById: {},
  goalsPreviewsById: {},
  goalsIdsByTeamId: {},
  goalsIdsByDepartmentId: {},
  goalsIdsByCompanyId: {},
  personalGoals: {
    totalPages: 0,
    totalCount: 0,
    pages: {},
  },
  goalStats: {
    goalCount: 0,
    overallProgress: 0,
  },
}

export interface PaginatedResults<T> {
  totalPages: number
  totalCount: number
  pages: Record<number, T[]>
  currentPage?: number
  nextPage?: number
  previousPage?: number
}

type GoalsState = {
  goalsById: Record<number, Goal>
  goalsPreviewsById: Record<number, GoalPreview>
  goalsIdsByTeamId: Record<string, number[]>
  goalsIdsByDepartmentId: Record<number, number[]>
  goalsIdsByCompanyId: Record<number, number[]>
  personalGoals: PaginatedResults<number>
  goalStats: GoalStats
}

type GoalsAction =
  | FetchGoalDetailsAction
  | FetchPersonalGoalsAction
  | FetchTeamGoalsAction
  | FetchDepartmentGoalsAction
  | FetchCompanyGoalsAction
  | DeleteAction
  | CreateAction
  | UpdateAction

type FetchGoalDetailsAction = {
  type: "FETCH_GOAL_DETAILS"
  payload: Goal
}

type FetchPersonalGoalsAction = {
  type: "FETCH_PERSONAL"
  payload: {
    goals: PersonalGoalPreview[]
    pagination: Pagination
    goalStats: GoalStats
  }
}

type FetchTeamGoalsAction = {
  type: "FETCH_TEAM"
  payload: {
    teamId: string
    goals: TeamGoalPreview[]
  }
}

type FetchDepartmentGoalsAction = {
  type: "FETCH_DEPARTMENT"
  payload: {
    departmentId: number
    goals: DepartmentGoalPreview[]
  }
}

type FetchCompanyGoalsAction = {
  type: "FETCH_COMPANY_GOALS"
  payload: {
    companyId: number
    goals: CompanyGoalPreview[]
  }
}

type DeleteAction = {
  type: "DELETE"
  payload: Goal
}

type CreateAction = {
  type: "CREATE"
  payload: Goal
}

type UpdateAction = {
  type: "UPDATE"
  payload: Goal
}

export const getPaginationState = <T extends unknown>(
  state: PaginatedResults<T>,
  pagination: Pagination,
  elements: T[]
): PaginatedResults<T> => ({
  totalPages: pagination.totalPages,
  totalCount: pagination.totalCount,
  pages: {
    ...state.pages,
    [pagination.currentPage]: elements,
  },
  currentPage: pagination.currentPage,
  nextPage: pagination.nextPage,
  previousPage: pagination.previousPage,
})

export const goalsReducer = (
  state: GoalsState,
  action: GoalsAction
): GoalsState => {
  switch (action.type) {
    case "FETCH_GOAL_DETAILS": {
      const fetchedGoal = action.payload
      const goalPreview = fetchedGoal as GoalPreview

      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          [goalPreview.id]: goalPreview,
        },
        goalsById: {
          ...state.goalsById,
          [fetchedGoal.id]: fetchedGoal,
        },
      }
    }
    case "FETCH_PERSONAL": {
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      const personalGoals: number[] = []
      const { goals, goalStats, pagination } = action.payload
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
        personalGoals.push(goal.id)
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        personalGoals: getPaginationState(
          state.personalGoals,
          pagination,
          personalGoals
        ),
        goalStats: goalStats,
      }
    }
    case "FETCH_TEAM": {
      const { teamId, goals } = action.payload
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        goalsIdsByTeamId: {
          ...state.goalsIdsByTeamId,
          [teamId]: goals.map((goal) => goal.id),
        },
      }
    }
    case "FETCH_DEPARTMENT": {
      const { departmentId, goals } = action.payload
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        goalsIdsByDepartmentId: {
          ...state.goalsIdsByTeamId,
          [departmentId]: goals.map((goal) => goal.id),
        },
      }
    }

    case "FETCH_COMPANY_GOALS": {
      const goalsPreviewsById: Record<number, GoalPreview> = {}
      const companyGoals: number[] = []
      const { goals, companyId } = action.payload
      goals.map((goal) => {
        goalsPreviewsById[goal.id] = goal
        companyGoals.push(goal.id)
      })
      return {
        ...state,
        goalsPreviewsById: {
          ...state.goalsPreviewsById,
          ...goalsPreviewsById,
        },
        goalsIdsByCompanyId: {
          ...state.goalsIdsByCompanyId,
          [companyId]: goals.map((goal) => goal.id),
        },
      }
    }

    case "DELETE": {
      const goal = action.payload
      const filter = (id: number) => id !== goal.id

      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [goal.id]: removedPreview,
        ...goalsPreviewsById
      } = state.goalsPreviewsById
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [goal.id]: removedGoal, ...goalsById } = state.goalsById

      if (goal.type === "team") {
        return {
          ...state,
          goalsById,
          goalsPreviewsById,
          goalsIdsByTeamId: {
            ...state.goalsIdsByTeamId,
            [goal.teamId]: state.goalsIdsByTeamId[goal.teamId].filter(filter),
          },
        }
      } else {
        const personalGoals: PaginatedResults<number> = {
          ...state.personalGoals,
          pages: Object.assign(
            {},
            ...Object.keys(state.personalGoals.pages).map((page) => ({
              [Number(page)]: state.personalGoals.pages[Number(page)].filter(
                filter
              ),
            }))
          ),
        }
        return {
          ...state,
          goalsById,
          goalsPreviewsById,
          personalGoals,
        }
      }
    }
  }
  return { ...state }
}

export const GoalsContext: React.Context<{
  state: GoalsState
  dispatch: React.Dispatch<GoalsAction>
}> = createContext({
  state: INITIAL_STATE,
  dispatch: (action: GoalsAction) => {},
})

export const GoalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(goalsReducer, INITIAL_STATE)

  return (
    <GoalsContext.Provider value={{ state, dispatch }}>
      {children}
    </GoalsContext.Provider>
  )
}
