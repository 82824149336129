import { logEvent } from "@cultureamp/amplitude"

type Visibility = "everyone" | "manager" | "owner" | "individuals"

type Priority = 0 | 1 | 2

type GoalData = {
  due_at: string
  id: number
  priority: Priority
  key_results: []
  visibility: Visibility
  goal_owners?: []
  outgoing_aligned_goals: []
}

const visibilityForAmplitude = (visibility: Visibility) => {
  switch (visibility) {
    case "everyone":
      return "Everyone"
    case "manager":
      return "Only my manager"
    case "owner":
      return "Only me"
    case "individuals":
      return "Specify users"
  }
}

const goalPriorityForAmplitude = (priority: Priority) => {
  switch (priority) {
    case 0:
      return "Low"
    case 1:
      return "Medium"
    case 2:
      return "High"
  }
}

export default (goalType: string, goalData: GoalData) => {
  const endDate = new Date(goalData.due_at).getTime()
  const currentDate = new Date().getTime()
  const daysUntilDue = (endDate - currentDate) / (1000 * 3600 * 24)

  logEvent("Performance Goal Created", {
    "Due date days from now": Math.round(daysUntilDue),
    "Goal due date": goalData.due_at,
    "Goal ID": goalData.id.toString(),
    "Goal priority": goalPriorityForAmplitude(goalData.priority),
    "Goal type": goalType,
    "Number of goal owners": goalData.goal_owners
      ? goalData.goal_owners.length
      : null,
    "Number of key results set": goalData.key_results.length,
    Visibility: visibilityForAmplitude(goalData.visibility),
    "Is aligned": goalData.outgoing_aligned_goals.length > 0 ? "Yes" : "No",
  })
}
