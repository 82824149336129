/*
 * This file is a wrapper around react-intl which allows us to replace custom
 * terminology placeholders with the appropriate custom terms provided by the
 * CurrentUser object. The API exposed by this libray should be identical to
 * that provided by react-intl.
 *
 * Note: This library can only be used within the CurrentUserProvider. If you
 * are working on a page where CurrentUserProvider is not available (e.g. a
 * page where the user is not logged in), you should not use this library and
 * instead import react-intl directly.
 *
 * For more information about custom terminology, see:
 * https://cultureamp.atlassian.net/wiki/spaces/CP/pages/972719572/Custom+Terminology+Navigation+Strings
 */

///<reference types="react-intl" />

import React from "react"
import * as reactIntl from "react-intl-without-custom-terms"
import { InjectedIntl as InjectedIntlOrig } from "react-intl-without-custom-terms"
import useCurrentUser from "../domainHooks/auth/useCurrentUser"

export const FormattedMessage = ({
  values,
  ...props
}: ReactIntl.FormattedMessage.Props) => {
  const terms = useCurrentUser().custom_terms
  // We are transitioning from customTerms having names like "skills|lowercase" to "skills__lowercase".
  // This change will improve the support of ICU formatted translation strings with our translation provider.
  // Until we update all usage and migrate the database, we are supporting both formats side by side.
  const termsInBothFormats = Object.keys(terms).reduce(
    (newTerms, termKeyWithPipe) => {
      const termKeyWithUnderscores = termKeyWithPipe.replace("|", "__")
      return {
        ...newTerms,
        [termKeyWithPipe]: terms[termKeyWithPipe],
        [termKeyWithUnderscores]: terms[termKeyWithPipe],
      }
    },
    {}
  )
  return (
    <reactIntl.FormattedMessage
      {...props}
      values={{ ...values, ...termsInBothFormats }}
    />
  )
}

function getDisplayName<P>(Component: React.ComponentType<P>) {
  return Component.displayName || Component.name || "Component"
}

export function injectIntl<P>(
  WrappedComponent: React.ComponentType<P & ReactIntl.InjectedIntlProps>,
  options?: ReactIntl.InjectIntlConfig
) {
  const WithCustomTerms = React.forwardRef(
    (props: P & ReactIntl.InjectedIntlProps, ref) => {
      const terms = useCurrentUser().custom_terms
      const myIntl = {
        ...props.intl,
        formatMessage: (
          descriptor: ReactIntl.FormattedMessage.MessageDescriptor,
          values?: { [key: string]: ReactIntl.MessageValue }
        ) => props.intl.formatMessage(descriptor, { ...values, ...terms }),
      }
      return <WrappedComponent {...props} ref={ref} intl={myIntl} />
    }
  )
  WithCustomTerms.displayName = `WithCustomTerms(${getDisplayName(
    WrappedComponent
  )})`

  return reactIntl.injectIntl(WithCustomTerms, options)
}

export type InjectedIntl = InjectedIntlOrig

export {
  FormattedDate,
  FormattedNumber,
  FormattedRelative,
  addLocaleData,
  IntlProvider,
} from "react-intl-without-custom-terms"
