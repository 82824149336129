import { Heading } from "@kaizen/component-library"
import { Location } from "history"
import React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { InjectedRouter } from "react-router"
import DropdownButton from "../../components/DropdownButton/DropdownButton"
import Tabs from "../Tabs/Tabs"
import styles from "./GoalsList.scss"
import strings from "../../locale/strings"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import {
  hasConfigOption,
  isAdmin,
  getManagedDepartments,
  isHrbp,
} from "../../domain/user/user"
import useTeams from "../../domainHooks/teams/useTeams"
import ConfigurationOptions from "../../constants/configurationOptions"
import { FeatureSwitch } from "../../components/FeatureSwitch/FeatureSwitch"
import FeatureFlags from "../../constants/featureFlags"

type GoalsList = React.FunctionComponent<{
  router: InjectedRouter
  location: Location
  intl: InjectedIntl
}>

const GOAL_TYPES = {
  PERSONAL: {
    type: "personal",
    createLabel: strings.goalsPage.goalType.individual.createGoalButton,
    tabLabel: strings.goalsPage.goalType.individual.tabLabel,
    path: "/new_goals/personal",
    createGoalPath: "/new_goals/personal/new",
  },
  TEAM: {
    type: "team",
    createLabel: strings.goalsPage.goalType.team.createGoalButton,
    tabLabel: strings.goalsPage.goalType.team.tabLabel,
    path: "/new_goals/team",
    createGoalPath: "/new_goals/team/new",
  },
  DEPARTMENT: {
    type: "department",
    createLabel: strings.goalsPage.goalType.department.createGoalButton,
    tabLabel: strings.goalsPage.goalType.department.tabLabel,
    path: "/new_goals/department",
    createGoalPath: "/new_goals/department/new",
  },
  COMPANY: {
    type: "company",
    createLabel: strings.goalsPage.goalType.company.createGoalButton,
    tabLabel: strings.goalsPage.goalType.company.tabLabel,
    path: "/new_goals/company",
    createGoalPath: "/new_goals/company/new",
  },
}

const GoalsList: GoalsList = ({ children, router, location, intl }) => {
  const { formatMessage } = intl
  const currentUser = useCurrentUser()
  const { teams } = useTeams({ all_teams: false })
  const handleCreateButtonClick = (path: string, type?: string) => {
    router.push(path)
  }

  const teamGoalCreationEnabled = teams.length > 0

  return (
    <div className={styles.container}>
      <FeatureSwitch
        flag={[FeatureFlags.zenHeader, FeatureFlags.topNavigation]}
      >
        <FeatureSwitch.Disabled>
          <div className={styles.header}>
            <Heading variant="heading-2">
              {formatMessage(strings.goalsPage.header)}
            </Heading>

            <DropdownButton
              label={formatMessage(strings.goalsPage.createGoalButton)}
              options={[
                {
                  label: formatMessage(GOAL_TYPES.PERSONAL.createLabel),
                  disabled: false,
                  action: () => {
                    handleCreateButtonClick(GOAL_TYPES.PERSONAL.createGoalPath)
                  },
                },
                {
                  label: formatMessage(GOAL_TYPES.TEAM.createLabel),
                  disabled: !(
                    hasConfigOption(
                      currentUser,
                      ConfigurationOptions.teamGoals
                    ) && teamGoalCreationEnabled
                  ),
                  action: () => {
                    teamGoalCreationEnabled &&
                      handleCreateButtonClick(
                        GOAL_TYPES.TEAM.createGoalPath,
                        GOAL_TYPES.TEAM.type
                      )
                  },
                },
                {
                  label: formatMessage(GOAL_TYPES.DEPARTMENT.createLabel),
                  disabled: !(
                    isAdmin(currentUser) ||
                    isHrbp(currentUser) ||
                    getManagedDepartments(currentUser).length > 0
                  ),
                  action: () => {
                    handleCreateButtonClick(
                      GOAL_TYPES.DEPARTMENT.createGoalPath,
                      GOAL_TYPES.DEPARTMENT.type
                    )
                  },
                },
                {
                  label: formatMessage(GOAL_TYPES.COMPANY.createLabel),
                  disabled: !isAdmin(currentUser),
                  action: () => {
                    handleCreateButtonClick(
                      GOAL_TYPES.COMPANY.createGoalPath,
                      GOAL_TYPES.COMPANY.type
                    )
                  },
                },
              ]}
            />
          </div>
          <>
            <Tabs
              tabs={[
                {
                  label: formatMessage(GOAL_TYPES.PERSONAL.tabLabel),
                  href: GOAL_TYPES.PERSONAL.path,
                  active:
                    location.pathname.match(/\/new_goals\/personal/) !== null,
                },

                hasConfigOption(
                  currentUser,
                  ConfigurationOptions.teamGoals
                ) && {
                  label: formatMessage(GOAL_TYPES.TEAM.tabLabel),
                  href: GOAL_TYPES.TEAM.path,
                  active: location.pathname.match(/\/new_goals\/team/) !== null,
                },
                {
                  label: formatMessage(GOAL_TYPES.DEPARTMENT.tabLabel),
                  href: "new_goals/department",
                  active:
                    location.pathname.match(/\/new_goals\/department/) !== null,
                },
                {
                  label: formatMessage(GOAL_TYPES.COMPANY.tabLabel),
                  href: "new_goals/company",
                  active:
                    location.pathname.match(/\/new_goals\/company/) !== null,
                },
              ]}
            />
          </>
        </FeatureSwitch.Disabled>
      </FeatureSwitch>
      {children}
    </div>
  )
}

export default injectIntl(GoalsList)
