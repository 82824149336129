import _ from "lodash"

export default function createTimerDecorator(name, factory) {
  return function decorator(target, key, descriptor) {
    if (typeof descriptor.value !== "function") {
      throw new Error(`Only functions can be passed to ${name}`)
    }

    const timerKey = `__${key}__${name}`
    const callback = descriptor.value

    const newDescriptor = _.omit(descriptor, "value", "writable")

    newDescriptor.get = function () {
      if (!hasOwnProperty.call(this, timerKey)) {
        this[timerKey] = factory(this, callback)
      }
      return this[timerKey]
    }

    newDescriptor.set = function (value) {
      this[timerKey] = value
    }

    return newDescriptor
  }
}
