import * as React from "react"
import { Button } from "@kaizen/component-library"
import classNames from "classnames"
import chevronDownIcon from "@kaizen/component-library/icons/chevron-down.icon.svg"
import DropdownMenu from "./DropdownMenu"
import styles from "./styles.scss"

type DropdownButtonOptions = {
  label: string
  disabled: boolean
  action: () => void
}

type DropdownButtonProps = {
  label?: string
  icon?: React.SVGAttributes<SVGSymbolElement>
  options: DropdownButtonOptions[]
  automationId?: string
}

type DropdownButton = React.FunctionComponent<DropdownButtonProps>

const DropdownButton: DropdownButton = ({
  label = "options",
  icon = chevronDownIcon,
  options,
  automationId,
}) => {
  const [isMenuVisible, setIsMenuVisible] = React.useState<boolean>(false)

  const dropdownButtonsContainerRef = React.useRef<HTMLDivElement>(null)

  const getButtonsBoundingRect = () => {
    return dropdownButtonsContainerRef.current
      ? dropdownButtonsContainerRef.current.getBoundingClientRect()
      : null
  }

  const toggleDropdownMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }
  return (
    <div className={styles.container} data-automation-id={automationId}>
      <Button
        label={label}
        icon={icon}
        iconPosition="end"
        primary
        onClick={toggleDropdownMenu}
        automationId="dropdown-button-id"
      />
      {isMenuVisible && (
        <DropdownMenu
          hideDropdownMenu={toggleDropdownMenu}
          buttonsBoundingRect={getButtonsBoundingRect()}
        >
          {options.map((option) => {
            const className = classNames({
              [styles.option]: true,
              [styles.disabled]: option.disabled,
            })
            return (
              <div
                onClick={!option.disabled ? option.action : undefined}
                key={option.label}
                className={className}
              >
                {option.label}
              </div>
            )
          })}
        </DropdownMenu>
      )}
    </div>
  )
}

export default DropdownButton
