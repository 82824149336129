import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, injectIntl } from "react-intl"
import { Link } from "react-router"
import _ from "lodash"
import { Button, Icon } from "@kaizen/component-library"
import { Tooltip } from "@kaizen/draft-tooltip"
import externalIcon from "@kaizen/component-library/icons/external-link.icon.svg"
import trashIcon from "@kaizen/component-library/icons/trash.icon.svg"
import userIcon from "@kaizen/component-library/icons/user.icon.svg"
import ProfilePic from "../ProfilePic/ProfilePic"
import FeedbackItemTitle from "../FeedbackItemTitle/FeedbackItemTitle"
import CompletedMRFSurveyResponse from "../surveys/manager-feedback/CompletedMRFSurveyResponse"
import MarkdownText from "../../components/MarkdownText/MarkdownText"
import User from "../../models/User"
import { copyToClipboard } from "../clipboard/clipboard"
import emojify from "../../components/emojify/emojify"
import strings from "../../locale/strings"
import "./PastFeedbackItem.less"

@injectIntl
export default class PastFeedbackItem extends React.Component {
  static defaultProps = {
    showProfileItemVisibilityIndicator: true,
    sharedMRF: false,
  }

  static propTypes = {
    review: PropTypes.object.isRequired,
    answers: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    typeText: PropTypes.node,
    showProfileItemVisibilityIndicator: PropTypes.bool,
    sharedMRF: PropTypes.bool,
  }

  handleCopyToClipboardClick = (e) => {
    e.stopPropagation()

    copyToClipboard({
      node: this.feedbackContentNode,
      successMessage: strings.profile.copiedToClipboard,
      clearAfter: true,
    })
  }

  getTooltipContainer = () => this.container

  getReviewer() {
    const { review } = this.props
    return review.reviewer || review.author || review.user
  }

  renderContent() {
    const {
      review,
      type,
      typeText,
      answers,
      showProfileItemVisibilityIndicator,
      sharedMRF,
    } = this.props

    // HACK - SF currently doesn't expose the review type, so we check if there are any
    // rated skills. Unfortunately, this would miss reviews where all the skills were skipped.
    const isSkillsReview =
      review.ratings && review.ratings.some((r) => r.rating !== 0)

    return (
      <div className="flex one PastFeedbackItem--content">
        <FeedbackItemTitle
          review={review}
          type={type}
          typeText={typeText}
          reviewer={this.getReviewer()}
          getTooltipContainer={this.getTooltipContainer}
          showProfileItemVisibilityIndicator={
            showProfileItemVisibilityIndicator
          }
          showDate={true}
        />

        {review.kind === "TBF" && (
          <div
            className="PastFeedbackItem--team-name"
            onClick={(e) => e.stopPropagation()}
          >
            <Link to={`teams/${review.team_id}`}>{review.team_name}</Link>
          </div>
        )}

        {type === "survey_response" ? (
          // Assume it's MRF, because that's the only kind of survey response that shows up in
          // past feedback
          <div>
            {!!_.get(review, "shared_with_subject_at") && !sharedMRF && (
              <div className="PastFeedbackItem--shared-description">
                <FormattedMessage
                  {...strings.profile.sharedDescription}
                  values={{
                    author: User.getFirstName(review.author),
                    sharedValue: (
                      <FormattedMessage
                        {...strings.profile.sharedWithSubject}
                        values={{
                          subject: User.getFirstName(review.subject),
                        }}
                      />
                    ),
                  }}
                />
              </div>
            )}

            {sharedMRF && (
              <div className="PastFeedbackItem--sharedMRF-description">
                <FormattedMessage
                  {...strings.profile.sharedDescription}
                  values={{
                    author: User.getFirstName(review.author),
                    sharedValue: (
                      <FormattedMessage
                        {...strings.profile.sharedWithManager}
                      />
                    ),
                  }}
                />
              </div>
            )}

            <CompletedMRFSurveyResponse
              surveyResponse={review}
              enableCopyToClipboard={false}
            />
          </div>
        ) : (
          <div ref={(node) => (this.feedbackContentNode = node)}>
            {review.question ? (
              <div className="PastFeedbackItem--question-answer">
                <p className="PastFeedbackItem--question">
                  <MarkdownText text={review.question} inline={true} />
                </p>
                {isSkillsReview ? (
                  <p className="PastFeedbackItem--answer">{review.notes}</p>
                ) : (
                  <MarkdownText
                    className="PastFeedbackItem--answer"
                    text={review.notes}
                  />
                )}
              </div>
            ) : answers ? (
              answers.map((answer) => (
                <div
                  key={`PastFeedbackItem--answer-${answer.id}`}
                  className="PastFeedbackItem--question-answer"
                >
                  <p
                    className="
                         PastFeedbackItem--question PastFeedbackItem--question-with-breaks"
                  >
                    <MarkdownText
                      text={answer.question_content}
                      inline={true}
                    />
                  </p>
                  <MarkdownText
                    className="PastFeedbackItem--answer"
                    text={answer.content}
                  />
                </div>
              ))
            ) : isSkillsReview ? (
              <p className="PastFeedbackItem--notes">{emojify(review.notes)}</p>
            ) : (
              <MarkdownText
                className="PastFeedbackItem--notes"
                text={review.notes}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      onClick,
      onDeleteClick,
      intl: { formatMessage },
    } = this.props
    const reviewer = this.getReviewer()

    return (
      <div
        ref={(ref) => (this.container = ref)}
        className="PastFeedbackItem layout horizontal"
      >
        <div className="PastFeedbackItem--avatar-container flex none">
          {reviewer ? (
            <ProfilePic user={reviewer} size="thumb" />
          ) : (
            <div className="PastFeedbackItem--no-reviewer-avatar">
              <Icon icon={userIcon} />
            </div>
          )}
        </div>

        {this.renderContent()}
        {onDeleteClick && (
          <div className="PastFeedbackItem--deleteButton-container">
            <Tooltip text={"Delete feedback"}>
              <Button
                onClick={onDeleteClick}
                label=""
                secondary
                icon={trashIcon}
              />
            </Tooltip>
          </div>
        )}

        {onClick && (
          <div className="PastFeedbackItem--shareButton-container">
            <Tooltip text={formatMessage(strings.profile.openFeedback)}>
              <Button
                onClick={onClick}
                label=""
                secondary
                icon={externalIcon}
              />
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
}
