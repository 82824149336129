import React, { createContext, useContext, useReducer } from "react"
import { getPaginationState, PaginatedResults } from "./GoalsState"
import { Pagination } from "../api/decoders/PaginationDecoder/paginationDecoder"
import { User } from "../types/User"

export interface SurveyResponseCreator {
  name: string
  email: string
  fullName: string
  id: number
  aggregateId?: string
}

export interface SurveyResponse {
  acceptedAt?: string
  completedAt?: string
  createdAt?: string
  deletedAt?: string
  updatedAt?: string
  id: number
  kind: string
  state: string
  subject: User
  surveyItems?: SurveyItem[]
  creatorId?: number
  creator?: SurveyResponseCreator
  // author: User
  // closed: boolean
  // subjectId: number
  // questions: [SurveyQuestion]
  // dueAt?: string
  // invitationToken?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeSurveyItem = (surveyObject: any) => {
  const surveyItem: SurveyItem = {
    answer: surveyObject.answer,
    choice: surveyObject.choice,
    choices: surveyObject.choices,
    comments: surveyObject.comments,
    id: surveyObject.id,
    questionDescription: surveyObject.question_description,
    questionTitle: surveyObject.question_title,
    questionType: surveyObject.question_type,
    required: surveyObject.required,
    idx: surveyObject.idx,
    state: surveyObject.state,
  }
  return surveyItem
}

export interface SurveyItem {
  answer?: string
  choice?: number
  choices?: SurveyItemChoice[]
  comments?: string[]
  createdAt?: string
  deletedAt?: string
  discussionPrompt?: string
  editedAt?: string
  id: number
  idx: number
  questionDescription?: string
  questionTitle: string
  questionType: string
  required: boolean
  shareable?: boolean
  state: string
  stateChangedAt?: string
  updatedAt?: string
  saveState?: string
}

export interface SurveyItemChoice {
  value: number
  title: string
  description?: string
}

export default interface SurveyQuestion {
  idx: number
  required: boolean
  title: string
  type: string
  choices?: []
}

interface State {
  surveyResponsesById: Record<number, SurveyResponse>
  mySurveyResponses: PaginatedResults<number>
  allSurveyResponses: PaginatedResults<number>
}

type Action =
  | {
      type: "FETCH_MY_SURVEY_RESPONSES"
      payload: { surveyResponses: SurveyResponse[]; pagination: Pagination }
    }
  | {
      type: "FETCH_SURVEY_RESPONSES"
      payload: { surveyResponses: SurveyResponse[]; pagination: Pagination }
    }
  | { type: "FETCH_SURVEY_RESPONSE"; payload: SurveyResponse }
  | {
      type: "DISMISS_SURVEY_RESPONSE"
      payload: number
    }
  | {
      type: "UPDATE_SURVEY_ITEM"
      payload: { updatedSurveyItem: SurveyItem; surveyResponseId: number }
    }
  | {
      type: "COMPLETE_SURVEY_RESPONSE"
      payload: SurveyResponse
    }

const INITIAL_STATE: State = {
  surveyResponsesById: {},
  mySurveyResponses: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  allSurveyResponses: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
}

const removeSurveyResponseFromState = (
  surveyResponseIdToRemove: number,
  surveyResponsesArray: PaginatedResults<number>
) => {
  const filter = (id: number) => id !== surveyResponseIdToRemove
  const surveyResponses: PaginatedResults<number> = {
    ...surveyResponsesArray,
    pages: Object.assign(
      {},
      ...Object.keys(surveyResponsesArray.pages).map((page) => ({
        [Number(page)]: surveyResponsesArray.pages[Number(page)].filter(filter),
      }))
    ),
  }
  return surveyResponses
}

export const surveyResponsesReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "FETCH_MY_SURVEY_RESPONSES": {
      const surveyResponsesById: Record<string, SurveyResponse> = {}
      const mySurveyResponses: number[] = []
      const { surveyResponses, pagination } = action.payload
      surveyResponses.map((survey) => {
        surveyResponsesById[survey.id] = survey
        mySurveyResponses.push(survey.id)
      })
      return {
        ...state,
        surveyResponsesById: {
          ...state.surveyResponsesById,
          ...surveyResponsesById,
        },
        mySurveyResponses: getPaginationState(
          state.mySurveyResponses,
          pagination,
          mySurveyResponses
        ),
      }
    }

    case "FETCH_SURVEY_RESPONSE": {
      const surveyResponse = action.payload
      return {
        ...state,
        surveyResponsesById: {
          ...state.surveyResponsesById,
          [surveyResponse.id]: surveyResponse,
        },
      }
    }

    case "UPDATE_SURVEY_ITEM": {
      const { updatedSurveyItem, surveyResponseId } = action.payload
      const surveyResponseToUpdate = state.surveyResponsesById[surveyResponseId]
      if (surveyResponseToUpdate) {
        surveyResponseToUpdate.surveyItems =
          surveyResponseToUpdate.surveyItems &&
          surveyResponseToUpdate.surveyItems.map((surveyItem: SurveyItem) => {
            if (surveyItem.id === updatedSurveyItem.id) {
              return updatedSurveyItem
            } else {
              return surveyItem
            }
          })
      }
      return {
        ...state,
        surveyResponsesById: {
          ...state.surveyResponsesById,
          [surveyResponseId]: surveyResponseToUpdate,
        },
      }
    }

    case "FETCH_SURVEY_RESPONSES": {
      const surveyResponsesById: Record<string, SurveyResponse> = {}
      const allSurveyResponses: number[] = []
      const { surveyResponses, pagination } = action.payload
      surveyResponses.map((surveyResponse) => {
        surveyResponsesById[surveyResponse.id] = surveyResponse
        allSurveyResponses.push(surveyResponse.id)
      })
      return {
        ...state,
        surveyResponsesById: {
          ...state.surveyResponsesById,
          ...surveyResponsesById,
        },
        allSurveyResponses: getPaginationState(
          state.allSurveyResponses,
          pagination,
          allSurveyResponses
        ),
      }
    }

    case "COMPLETE_SURVEY_RESPONSE": {
      const surveyResponsesUpdated = removeSurveyResponseFromState(
        action.payload.id,
        state.mySurveyResponses
      )
      return {
        ...state,
        mySurveyResponses: surveyResponsesUpdated,
      }
    }

    case "DISMISS_SURVEY_RESPONSE": {
      const surveyResponsesUpdated = removeSurveyResponseFromState(
        action.payload,
        state.mySurveyResponses
      )
      return {
        ...state,
        mySurveyResponses: surveyResponsesUpdated,
      }
    }
  }
}

export const SurveyResponsesContext = createContext<{
  state: State
  dispatch: React.Dispatch<Action>
}>({
  state: INITIAL_STATE,
  dispatch: (state) => state,
})

export const SurveyResponsesProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const surveyResponsesContext = useContext(SurveyResponsesContext)
  const [state, dispatch] = useReducer(
    surveyResponsesReducer,
    surveyResponsesContext.state
  )

  return (
    <SurveyResponsesContext.Provider value={{ state, dispatch }}>
      {children}
    </SurveyResponsesContext.Provider>
  )
}
