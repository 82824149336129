import _ from "lodash"
import { Location, History } from "history"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { setAtIndex } from "../utils/array"

export type BrowserHistoryStack = Location[]

const getIndex = (stack: BrowserHistoryStack, key: string) => {
  return _.findIndex(stack, (l) => {
    return l.key === key
  })
}

// Take the latest browser action, and store it appropriately in the history
// stack
const updateStack = (
  location: Location,
  stack: BrowserHistoryStack,
  currentKey: string
) => {
  const index = getIndex(stack, currentKey)

  if (index === -1) {
    // This should never happen, but just in case.
    return {
      stack: [location],
      currentKey: location.key,
    }
  } else if (location.action === "PUSH") {
    return {
      stack: [..._.take(stack, index + 1), location],
      currentKey: location.key,
    }
  } else if (location.action === "REPLACE") {
    return {
      stack: setAtIndex(stack, location, index),
      currentKey: location.key,
    }
  } else if (location.action === "POP") {
    const newIndex = getIndex(stack, location.key)

    // The user may have refreshed the page, and our mirrored history was lost.
    // At this point, we need to just clear the history, and start from scratch.
    if (newIndex === -1) {
      return {
        stack: [location],
        currentKey: location.key,
      }
    }

    // One of the annoyances with the native browser history api, is that `POP`
    // is not what you would expect! Instead of removing the last item from the
    // stack, it simply changes the pointer.
    return {
      currentKey: location.key,
      stack,
    }
  }

  // this shouldn't happen
  return { currentKey, stack }
}

const BrowserHistoryContext = React.createContext<{
  browserHistoryStack: BrowserHistoryStack
  currentKey: string | null
  currentIndex: number
}>({
  browserHistoryStack: [],
  currentKey: null,
  currentIndex: -1,
})

/**
 * The purpose of this code is to attempt to mirror the current browser history
 * into a local array, as so we can query the browser history at will.
 * Unfortunately this is not something the browser history api supplies to us,
 * so we need to do this tracking ourselves.
 * WARNING: this function is not perfect. There are edge cases, like when
 * the user reloads the page, all saved history will be lost.
 */
export const BrowserHistoryProvider = ({
  history,
  children,
}: {
  history: History
  children: React.ReactNode
}) => {
  const [browserHistoryStack, setStack] = useState([
    history.getCurrentLocation(),
  ])
  const [currentKey, setCurrentKey] = useState<string>(
    history.getCurrentLocation().key
  )

  useEffect(() => {
    return history.listen((location) => {
      const result = updateStack(location, browserHistoryStack, currentKey)
      setStack(result.stack)
      setCurrentKey(result.currentKey)
    })
  }, [browserHistoryStack, currentKey, history])

  const value = useMemo(() => {
    const currentIndex = getIndex(browserHistoryStack, currentKey)
    return {
      browserHistoryStack,
      currentKey,
      currentIndex,
    }
  }, [browserHistoryStack, currentKey])

  return (
    <BrowserHistoryContext.Provider value={value}>
      {children}
    </BrowserHistoryContext.Provider>
  )
}

export const useBrowserHistoryStack = () => {
  return useContext(BrowserHistoryContext)
}
