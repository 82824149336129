import _ from "lodash"

function addMessageTranslations(messages, id, obj) {
  if (!(obj && typeof obj === "object")) {
    messages[id] = obj !== null && obj !== undefined ? String(obj) : null
  } else {
    _.forEach(obj, (value, key) =>
      addMessageTranslations(messages, id ? `${id}-${key}` : key, value)
    )
  }
}

export function convertToMessageTranslations(obj) {
  const messages = {}
  addMessageTranslations(messages, "", obj)
  return messages
}
