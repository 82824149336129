import React from "react"
import PropTypes from "prop-types"
import Tooltip from "rc-tooltip"
import { FormattedMessage } from "react-intl"
import Button from "../Button/Button"
import Icon from "../Icon/Icon"
import strings from "../../locale/strings"
import "./CopyToClipboardButton.less"

const CopyToClipboardButton = ({ className, getTooltipContainer, onClick }) => (
  <Tooltip
    placement="top"
    arrowContent={<div className="rc-tooltip-arrow-inner" />}
    overlay={
      <div className="CopyToClipboardButton--tooltip">
        <FormattedMessage {...strings.copyToClipboard.tooltip} />
      </div>
    }
    getTooltipContainer={getTooltipContainer}
  >
    <div>
      <Button
        actionType="secondary"
        className={`CopyToClipboardButton ${className}`}
        onClick={onClick}
      >
        <Icon iconName="duplicate" style={{ color: "#0168B3", opacity: 0.7 }} />
      </Button>
    </div>
  </Tooltip>
)

CopyToClipboardButton.propTypes = {
  className: PropTypes.string,
  getTooltipContainer: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

CopyToClipboardButton.defaultProps = {
  className: "",
}

export default CopyToClipboardButton
