import React from "react"
import cx from "classnames"
import _ from "lodash"
import Avatar from "../../components/Avatar/Avatar"
import Icon from "../../components/Icon/Icon"
import "./TypeaheadRowWithAvatar.less"

type Props = {
  user: {
    disabled?: boolean
    // Either full_name or best_name must be supplied
    full_name?: string
    best_name?: string
    profile_image_url: string
    email: string
  }
  showEmail?: boolean
  message?: string | React.ReactNode
  clearable?: boolean
  onClear?: () => void
  single?: boolean
  padding?: string
}

export default class TypeaheadRowWithAvatar extends React.Component<Props> {
  static defaultProps = {
    showEmail: true,
    onClear: _.noop,
  }

  render() {
    const {
      user,
      showEmail,
      message,
      clearable,
      onClear,
      single,
      padding,
    } = this.props

    return (
      <div
        className={cx(
          "TypeaheadRowWithAvatar",
          "layout",
          "horizontal",
          "center",
          "needsclick",
          {
            "TypeaheadRowWithAvatar--disabled": user.disabled,
            "TypeaheadRowWithAvatar--clearable": clearable,
          }
        )}
        style={padding ? { padding } : undefined}
      >
        <Avatar size="small" imageURL={user.profile_image_url} />

        <div className="flex left-align layout vertical center-justified truncate needsclick">
          <div className="TypeaheadRowWithAvatar--name needsclick">
            {user.full_name || user.best_name}
          </div>

          {showEmail && (
            <div className="TypeaheadRowWithAvatar--email truncate needsclick">
              {user.email}
            </div>
          )}
        </div>

        {clearable && (
          <div
            className="TypeaheadRowWithAvatar--icon needsclick"
            onClick={() => {
              if (onClear) onClear()
            }}
          >
            {single ? (
              <Icon
                className="TypeaheadRowWithAvatar--single-close"
                iconName="close"
                style={{
                  width: "20px",
                  height: "100%",
                  zIndex: 100,
                  position: "absolute",
                  top: "0",
                  right: "12px",
                }}
              />
            ) : (
              <Icon
                iconName="clear"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </div>
        )}

        {message && (
          <div className="TypeaheadRowWithAvatar--message needsclick">
            {message}
          </div>
        )}
      </div>
    )
  }
}
