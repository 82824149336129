import _ from "lodash"
import React, { memo, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useIntl } from "../../hooks/locale/useIntl"
import strings from "../../locale/strings"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import { isAdmin } from "../../domain/user/user"
import TypeaheadRowWithAvatar from "../TypeaheadRowWithAvatar/TypeaheadRowWithAvatar"
import TypeaheadRowGroup from "../TypeaheadRowGroup/TypeaheadRowGroup"
// @ts-ignore
import Typeahead from "../../components/Typeahead/Typeahead"
// @ts-ignore
import ZugataFormattedRelativeDate from "../../components/ZugataFormattedDate/ZugataFormattedRelativeDate"
import User from "../../models/User"
// @ts-ignore
import Actions from "../../refluxActions/index"
import {
  AvatarImages,
  BasicEmployeeInfo,
  EmployeeV2,
} from "../../types/Employee"

// A more trimmed version of the EmployeeV2, which seems to get returned
// from the automatic suggestions. I could be wrong, I am retrofitting types
// into a non-typed codebase, so there may be inconsistencies
export type EmployeeSuggestion = {
  id: number
  email: string
  full_name: string
  best_name: string
  avatar_images: AvatarImages
  last_feedback_request_received_at: string | null
  profile_image_url: string
  requested_by: {
    id: number
    email: string
    full_name: string
    best_name: string
    avatar_images: AvatarImages
    manager_email: string | null
  }
  suggestion?: boolean
}

type Group = {
  group: true
  count: number
  title: {
    key: null
    ref: null
    props: FormattedMessage.MessageDescriptor
    _owner: null
    _store: {}
  }
  users: EmployeeSuggestion[]
  // Sometimes this isn't always the email, if the result is of a group type.
  // eg. it could equal "revieweeDirects"
  email: string
}

type Item = EmployeeSuggestion | Group | EmployeeV2
type Employee = EmployeeSuggestion | EmployeeV2

export type Props = {
  id?: string
  className?: string
  reviewee: BasicEmployeeInfo
  revieweeId: number
  reviewers: Employee[]
  onChangeReviewers: (reviewers: Employee[]) => void
  // Yes, these there props (peerNominations, revieweeDirects, currentUserDirects)
  // should be in a `groups` array prop instead. I'm only going off of
  // the old legacy code for now. There is actually a new design for this typeahead.
  // When this gets implemented, I suggest that we rewrite this component entirely.
  peerNominations?: EmployeeV2[] | null | undefined
  currentUserDirects: EmployeeV2[] | null | undefined
  revieweeDirects: EmployeeV2[] | null | undefined
  // Once the typeahead results have been fetched, provide a way that
  // the parent can filter the results. I'm sorry, I know this is a hack,
  // we plan on recreating this typahead soon anyway.
  filterResults: (users: EmployeeV2[]) => EmployeeV2[]
}

/**
 * Render the typeahead search for users to requests feedback from.
 * From @okeeffed: Was hoping to abstract this function and use FeedbackRequestTypeahead.tsx
 * but there are still some more abstractions from that component that needs
 * to happen because of the small differences between the two requests types.
 */
const ReviewerSelectionTypeahead = ({
  id,
  reviewee,
  reviewers,
  className,
  onChangeReviewers,
  revieweeId,
  peerNominations,
  currentUserDirects,
  revieweeDirects,
  filterResults,
}: Props) => {
  const { formatMessage } = useIntl()
  const currentUser = useCurrentUser()

  const handleLoadOptions = useCallback(
    async (query: string) => {
      let { users: reviewers } = query
        ? await Actions.FeedbackRequests.loadReviewers({
            query,
            revieweeId: revieweeId,
            includeCurrentUser:
              isAdmin(currentUser) || currentUser.hr_business_partners.length,
          })
        : revieweeId &&
          (await Actions.Team.getReviewersSuggestions({ user: revieweeId }))

      if (!query && reviewers) {
        reviewers = reviewers.map((r: EmployeeV2) => ({
          ...r,
          suggestion: true,
        }))
      }

      const filteredUserDirects = _.filter(
        currentUserDirects || [],
        (direct) => direct.id !== revieweeId
      )

      let results = reviewers

      if (filterResults) {
        results = filterResults(results)
      }

      if (revieweeDirects && revieweeDirects.length > 0) {
        const alreadyIncluded = revieweeDirects.every((obj) =>
          reviewers.includes(obj)
        )
        if (!alreadyIncluded) {
          results = [
            {
              group: true,
              count: revieweeDirects.length,
              title: formatMessage(
                strings.requestFeedback.requestFromManagerDirects,
                { name: User.getFirstName(reviewee) }
              ),
              users: revieweeDirects,
              email: "managerDirects", // Typeahead component indexes results using email key
            },
            ...results,
          ]
        }
      }

      if (filteredUserDirects && filteredUserDirects.length > 0) {
        const alreadyIncluded = filteredUserDirects.every((obj) =>
          reviewers.includes(obj)
        )
        if (!alreadyIncluded) {
          results = [
            {
              group: true,
              count: filteredUserDirects.length,
              title: formatMessage(strings.requestFeedback.requestFromDirects),
              users: filteredUserDirects,
              email: "revieweeDirects", // Typeahead component indexes results using email key
            },
            ...results,
          ]
        }
      }

      // Only applicable to eval cycles peer and upward feedback
      if (peerNominations && peerNominations.length > 0) {
        results = [
          {
            group: true,
            count: peerNominations.length,
            title: formatMessage(
              strings.requestFeedback.requestFromNominations,
              { name: User.getFirstName(reviewee) }
            ),
            users: peerNominations,
            email: "peerNominations", // Typeahead component indexes results using email key
          },
          ...results,
        ]
      }

      return { results }
    },
    [
      reviewee,
      currentUser,
      revieweeId,
      currentUserDirects,
      filterResults,
      revieweeDirects,
      peerNominations,
      formatMessage,
    ]
  )

  const getLastRequestedTimestamp = (
    timestamp: string | null,
    requestedBy: { email: string }
  ) => {
    const dayCountCutoff = 30

    if (!timestamp) {
      return null
    }

    const preposition = formatMessage(
      requestedBy.email === currentUser.email
        ? strings.feedbackRequests.byYou
        : strings.feedbackRequests.forYou
    )

    return (
      <FormattedMessage
        {...strings.feedbackRequests.lastRequested}
        values={{
          preposition,
          time: (
            <ZugataFormattedRelativeDate
              value={timestamp}
              dayCountCutoff={dayCountCutoff}
            />
          ),
        }}
      />
    )
  }

  const handleReviewersChange = useCallback(
    (results: Item[]) => {
      const reviewers = _.uniq(
        _.flatten(
          _.map(results, (result) => (result as Group).users || result)
        ),
        (r) => r.id
      ) as EmployeeSuggestion[]
      onChangeReviewers(reviewers)
    },
    [onChangeReviewers]
  )

  return (
    <Typeahead
      id={id}
      className={className}
      zugataStyle={true}
      value={reviewers}
      placeholder={formatMessage(
        strings.admin.managerRequestedFeedback.requestFeedback.placeholder.from
      )}
      valueComponent={(config: {
        value: EmployeeV2
        onRemove: (value: EmployeeV2) => void
      }) => {
        const { value, onRemove } = config
        return (
          <TypeaheadRowWithAvatar
            user={value}
            showEmail={false}
            clearable={true}
            onClear={() => onRemove(value)}
          />
        )
      }}
      optionRenderer={(option: Item) =>
        (option as Group).group ? (
          <TypeaheadRowGroup
            count={(option as Group).count}
            title={(option as Group).title}
          />
        ) : (
          <TypeaheadRowWithAvatar
            message={
              (option as EmployeeSuggestion).suggestion
                ? formatMessage(strings.requestFeedback.zugataSuggestion)
                : getLastRequestedTimestamp(
                    (option as EmployeeSuggestion)
                      .last_feedback_request_received_at,
                    (option as EmployeeSuggestion).requested_by
                  )
            }
            user={option as EmployeeSuggestion}
          />
        )
      }
      loadOptions={handleLoadOptions}
      loadOptionsField="results"
      refreshOptionsOnFocus={true}
      clearable={false}
      allowCreate={false}
      searchable={true}
      multi={true}
      onChange={handleReviewersChange}
      labelKey="full_name"
      valueKey="email"
    />
  )
}

export default memo(ReviewerSelectionTypeahead)
