import _ from "lodash"
// @ts-ignore
import lazy from "../utils/lazy"
import BaseModel from "./BaseModel"
import PerformanceCycle from "./PerformanceCycle"
import {
  Answer,
  PerformanceReview as PerformanceReviewRaw,
} from "../types/PerformanceReview"
import { Bucket } from "../types/PerformanceCycle"

export default class PerformanceReview extends BaseModel
  implements
    // Without performance_cycle, because in this PerformanceReview Model, the raw
    // type is converted to a PerformanceCycle Model
    Omit<PerformanceReviewRaw, "performance_cycle"> {
  id: number
  performance_cycle_id: number
  shared_at: string // Date string, eg "2020-01-09T05:16:08.000Z"
  manager_completed_at: string // Date string, eg "2020-01-09T05:16:08.000Z"
  performance_answers: Answer[]
  performance_cycle_name: string
  performance_bucket_in_cycles: null | Bucket
  performance_buckets_question_title: null | string
  performance_buckets_question_description: null | string
  performance_buckets_position: null | null
  manager: null // ??
  latest_bucket_assignment: null // ??

  // Additional fields
  complete: boolean
  performance_cycle: PerformanceCycle | undefined

  constructor(props: PerformanceReviewRaw) {
    super(props)
    _.assign(this, props)

    /* eslint-disable prettier/prettier */
    this.complete =
      // @ts-ignore
      props.complete !== undefined
        // @ts-ignore
        ? props.complete
        : !!props.manager_completed_at
    /* eslint-enable prettier/prettier */

    // @ts-ignore
    this.performance_cycle = props.performance_cycle
      ? PerformanceCycle.of(props.performance_cycle)
      : undefined
  }

  static getAnswerValue(answer: Answer) {
    return answer && (answer.rating || answer.choice || answer.text)
  }

  static getProgress({
    questionCount,
    review,
  }: {
    questionCount: number
    review: PerformanceReview
  }) {
    const { numAnswersGiven, complete, assignedBucket } = review
    const completedAnswerFraction =
      questionCount === 0 ? 1 : numAnswersGiven / questionCount

    // This is a totally arbitrary calculation of review progress, intended just
    // as a rough guide for the UI
    // NB: add ints and divide by 10 at the end since `0.1 + 0.2 !== 0.3`
    return (
      (8 * completedAnswerFraction +
        (assignedBucket ? 1 : 0) +
        (complete ? 1 : 0)) /
      10
    )
  }

  get assignedBucket() {
    return _.get(this.latest_bucket_assignment, "performance_bucket_in_cycle")
  }

  @lazy
  get numAnswersGiven() {
    return _(this.performance_answers)
      .filter((answer) => {
        const answerValue = PerformanceReview.getAnswerValue(answer)
        return (
          (answerValue || answerValue === 0) &&
          answer.performance_question_in_cycle.required
        )
      })
      .size()
  }

  get incomplete() {
    return !this.complete
  }
}
