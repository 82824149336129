import { Heading } from "@kaizen/component-library"
import cx from "classnames"
import * as React from "react"
import { HeadingVariants } from "@kaizen/component-library/components/Heading"
import Aid from "../../constants/automationId"
import styles from "./HeadingWithAvatar.scss"
import Avatar from "../Avatar/Avatar"

type Props = {
  avatarAutomationId?: Aid
  automationId?: Aid
  imageUrl: string
  headingTag?: HeadingVariants
  title: React.ReactNode
  noMargin?: boolean
}

// Also see AvatarDetails for a similar component, but with a heading font.
// Maybe these should be merged...
const HeadingWithAvatar = ({
  automationId,
  avatarAutomationId,
  headingTag = "heading-3",
  imageUrl,
  title,
  noMargin,
}: Props) => (
  <div
    className={cx(styles.root, noMargin && styles.noMargin)}
    data-automation-id={automationId}
  >
    <div className={styles.avatarContainer}>
      <Avatar imageURL={imageUrl} automationId={avatarAutomationId} />
    </div>
    <Heading variant={headingTag}>{title}</Heading>
  </div>
)

export default HeadingWithAvatar
