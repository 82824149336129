// From survey.rb and survey_response.rb in steadyfoot
export enum SurveyKind {
  SelfEval = "SelfEval",
  PerfEval = "PerfEval",
  CheckIn = "CheckIn",
  CheckUp = "CheckUp",
  MRF = "MRF",
  PeerFeedback = "PeerFeedback",
  UpwardFeedback = "UpwardFeedback",
}
