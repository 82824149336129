import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Team } from "../../context/TeamsState"
import Tiles from "../Tiles/Tiles"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"

const { tile } = strings.teams.teamCard

type TeamCard = React.FunctionComponent<{
  team: Team
  onActionClick: () => void
  intl: InjectedIntl
}>

const TeamCard: TeamCard = ({ team, intl, onActionClick }) => {
  const { name, preview, description } = team
  const { formatMessage } = intl
  let subtitle = formatMessage(tile.loading)
  if (preview) {
    const { goalCount, memberCount } = preview
    const goalStr =
      goalCount === 1
        ? formatMessage(tile.goalSingular)
        : formatMessage(tile.goalPlural)
    const memberStr =
      memberCount === 1
        ? formatMessage(tile.memberSingular)
        : formatMessage(tile.memberPlural)
    subtitle = `${goalCount} ${goalStr} | ${memberCount} ${memberStr}`
  }

  return (
    <Tiles.Tile
      title={name}
      subtitle={subtitle}
      description={description}
      background="seedling"
      actionLabel={formatMessage(tile.actionLabel)}
      onActionClick={onActionClick}
      automationId={Aid.viewTeamButton}
    />
  )
}

export default injectIntl(TeamCard)
