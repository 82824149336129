/* eslint-disable @typescript-eslint/no-use-before-define */

import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import modalDeprecated from "../../higherOrderComponents/modalDeprecated"
import connect from "../../higherOrderComponents/connect"
import Actions from "../../refluxActions"
import Goal from "../../models/Goal"
import GoalStore, { sourceForGoal } from "../../refluxStores/GoalStore"
import ConversationStore from "../../refluxStores/ConversationStore"
import BaseViewGoalModal, {
  BASE_VIEW_GOAL_MODAL_PUBLIC_PROPS,
} from "./BaseViewGoalModal"

@modalDeprecated({
  opened: (props) => !!props.goal,
  key: (props) => props.goal.id,
})
@connect(GoalStore, "goalData")
@connect(ConversationStore, "conversationData")
export default class ViewGoalModal extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  static propTypes = {
    goal: PropTypes.object.isRequired,

    ...BASE_VIEW_GOAL_MODAL_PUBLIC_PROPS,
  }

  constructor(props) {
    super(props)

    this.state = {
      goalSource: null,
    }
  }

  componentDidMount() {
    const { goal, onClose } = this.props
    const { user } = this.context

    Actions.Goal.load({ id: goal.id, userId: user.id })
      .then(this.handleGoal)
      .catch(() => {
        onClose()
      })
  }

  componentWillReceiveProps(nextProps) {
    const nextGoal = this.getGoal(nextProps)
    const goal = this.getGoal(this.props)

    if (nextGoal && nextGoal !== goal) {
      this.handleGoal(nextGoal)
    }
  }

  getGoal(props = this.props) {
    const {
      goal: { id },
      goalData: { goalsBySource },
    } = props
    const { goalSource } = this.state
    const goals = goalsBySource.get(goalSource)

    return goals && goals.find((goal) => goal.id === id)
  }

  handleGoal = (goal) => {
    this.setState({
      goalSource: sourceForGoal(goal),
    })
  }

  handleTeamGoal = (goalToShow) => {
    const { router } = this.context

    router.push({
      pathname: `new_goals/team`,
      query: { goalId: goalToShow.id },
    })
  }

  getComments(goal) {
    const {
      conversationData: { sourceObjectsByType },
    } = this.props

    if (!goal) {
      return null
    }
    const { goal_type: goalType, visibility } = goal
    const goalComments = _.get(sourceObjectsByType, ["goal", goal.id])
    const viewingOwnGoal = Goal.isOwnedByUser(goal, this.context.user)
    /*
    Conditions for disabling conversations:
     - if the goal is a department or company goal
     - if viewing your own goal, and the visibility is set to yourself,
       and have no existing conversations
    */
    if (
      goalType !== "my_goal" ||
      (viewingOwnGoal &&
        visibility === "owner" &&
        !_.get(goalComments, "length"))
    ) {
      return null
    }
    return goalComments && goalComments.comments ? goalComments.comments : []
  }

  render() {
    const goal = this.getGoal()

    return (
      <BaseViewGoalModal
        {...this.props}
        goal={goal}
        handleTeamGoal={this.handleTeamGoal}
        NestedGoalModalComponent={ViewGoalModalRef}
        comments={this.getComments(goal)}
        createAction={Actions.Goal.create}
        updateAction={Actions.Goal.update}
        deleteAction={Actions.Goal.delete}
        markAsSeenAction={Actions.Goal.markAsManagerSeen}
      />
    )
  }
}

// Aliased like this because there's an issue with referencing a class inside itself with our
// current decorators transform
const ViewGoalModalRef = ViewGoalModal
