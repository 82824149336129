import * as React from "react"
import { injectIntl, InjectedIntl } from "react-intl"
import { Button, InlineNotification, Text } from "@kaizen/component-library"
import { TextField } from "@kaizen/draft-form"
import leftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import cx from "classnames"
import { useState } from "react"
import strings from "../../locale/strings"
import { User } from "../../types/User"
import UserCard from "../UserCard/UserCard"
import UserSearch from "../UserSearch/UserSearch"
import styles from "./TeamForm.scss"
import {
  trackTeamNameAdded,
  trackTeamDescriptionAdded,
  trackTeamLeadAdded,
  trackTeamLeadRemoved,
  trackTeamMemberAdded,
  trackTeamMemberRemoved,
} from "../../utils/analytics/events"
import Aid from "../../constants/automationId"

export interface TeamFields {
  name: string
  description: string
  teamLeads: User[]
  teamMembers: User[]
}

type TeamFormProps = {
  defaultFields: TeamFields
  notification?: string
  onSubmit: (team: TeamFields) => void
  onCancel: () => void
  disabled: boolean
  title: string
  submitLabel: string
  intl: InjectedIntl
}

const TeamForm = ({
  defaultFields,
  notification,
  onSubmit,
  onCancel,
  disabled,
  title,
  submitLabel,
  intl,
}: TeamFormProps) => {
  const [teamFields, setTeamFields] = useState<TeamFields>(defaultFields)
  const { name, description, teamLeads, teamMembers } = teamFields
  const { formatMessage } = intl
  return (
    <div>
      <Button
        secondary
        label={formatMessage(strings.general.back)}
        icon={leftIcon}
        onClick={onCancel}
      />
      <Text tag="h1" style="zen-heading-2">
        {title}
      </Text>
      <form>
        {notification && (
          // @ts-ignore: Quick fix to get the typescript build to pass. If you are reading this line, please fix it.
          <InlineNotification type="negative">
            {notification}
          </InlineNotification>
        )}
        <div className={styles.row}>
          <div className={cx(styles.field, styles.large)}>
            <TextField
              id={Aid.teamNameInput}
              inputValue={name}
              onChange={(evt: React.FormEvent<HTMLInputElement>) => {
                evt.persist()
                setTeamFields((fields) => ({
                  ...fields,
                  name: (evt.target as HTMLInputElement).value,
                }))
              }}
              onBlur={() => {
                trackTeamNameAdded({ team_goal_name: teamFields.name })
              }}
              inputType="text"
              labelText={formatMessage(strings.teams.newTeamForm.nameLabelText)}
              required
            />
          </div>
        </div>
        <TextField
          id={Aid.teamDescriptionInput}
          inputValue={description}
          onChange={(evt: React.FormEvent<HTMLInputElement>) => {
            evt.persist()
            setTeamFields((fields) => ({
              ...fields,
              description: (evt.target as HTMLInputElement).value,
            }))
          }}
          onBlur={() =>
            trackTeamDescriptionAdded({
              team_goal_description: teamFields.description,
            })
          }
          inputType="text"
          labelText={formatMessage(
            strings.teams.newTeamForm.descriptionLabelText
          )}
        />
        <UserSearch
          id={Aid.teamLeadsSearchInput}
          labelText={formatMessage(
            strings.teams.newTeamForm.teamLeads.labelText
          )}
          description={formatMessage(
            strings.teams.newTeamForm.teamLeads.teamLeadsDescription
          )}
          onUserSelect={(user) => {
            setTeamFields((fields) => ({
              ...fields,
              teamLeads: [...fields.teamLeads, ...fields.teamMembers].some(
                (userInTeam) => userInTeam.aggregateId === user.aggregateId
              )
                ? fields.teamLeads
                : [...fields.teamLeads, user],
            }))
          }}
          onBlur={() => {
            trackTeamLeadAdded()
          }}
          disabled={disabled}
        />
        <div>
          {teamLeads.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              onRemove={(evt) => {
                evt.preventDefault()
                setTeamFields((fields) => ({
                  ...fields,
                  teamLeads: fields.teamLeads.filter(
                    (teamLead) => teamLead.id !== user.id
                  ),
                }))
                trackTeamLeadRemoved()
              }}
            />
          ))}
        </div>
        <UserSearch
          id={Aid.teamMembersSearchInput}
          labelText={formatMessage(
            strings.teams.newTeamForm.teamMembers.labelText
          )}
          description={formatMessage(
            strings.teams.newTeamForm.teamMembers.teamMembersDescription
          )}
          onUserSelect={(user) => {
            setTeamFields((fields) => ({
              ...fields,
              teamMembers: [...fields.teamLeads, ...fields.teamMembers].some(
                (userInTeam) => userInTeam.aggregateId === user.aggregateId
              )
                ? fields.teamMembers
                : [...fields.teamMembers, user],
            }))
            trackTeamMemberAdded()
          }}
          disabled={disabled}
        />
        <div>
          {teamMembers.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              onRemove={(evt) => {
                evt.preventDefault()
                trackTeamMemberRemoved()
                setTeamFields((fields) => ({
                  ...fields,
                  teamMembers: fields.teamMembers.filter(
                    (teamMember) => teamMember.id !== user.id
                  ),
                }))
              }}
            />
          ))}
        </div>
        <div className={styles.actions}>
          <div className={styles.action}>
            <Button
              disabled={disabled}
              secondary
              label={formatMessage(strings.teams.newTeamForm.cancel)}
              onClick={onCancel}
            />
          </div>
          <div className={styles.action}>
            <Button
              automationId={Aid.teamFormCreateNewTeamButton}
              disabled={disabled}
              primary
              label={submitLabel}
              onClick={() => onSubmit(teamFields)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(TeamForm)
