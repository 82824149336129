import { Button } from "@kaizen/component-library"
import editIcon from "@kaizen/component-library/icons/edit.icon.svg"
import deleteIcon from "@kaizen/component-library/icons/trash.icon.svg"
import archivedIcon from "@kaizen/component-library/icons/archived.icon.svg"
import leftIcon from "@kaizen/component-library/icons/chevron-left.icon.svg"
import requestFeedbackIcon from "@kaizen/component-library/icons/feedback-review.icon.svg"
import * as React from "react"
import { useState, useEffect } from "react"
import { WithRouterProps } from "react-router"
import { injectIntl, InjectedIntl } from "react-intl"
import { compact } from "lodash"
import strings from "../../locale/strings"
import useCurrentUser from "../../domainHooks/auth/useCurrentUser"
import { hasConfigOption, isAdmin } from "../../domain/user/user"
import useTeam from "../../domainHooks/teams/useTeam"
// went with C# interface naming convention due to naming conflicts with Team and TeamType
import { Team as ITeam, userBelongsToTeam } from "../../context/TeamsState"
import Tabs from "../Tabs/Tabs"
import Loading from "../../components/Loading/Loading"
import NewTeamModal from "../NewTeamModal/NewTeamModal"
import styles from "./Team.scss"
import TeamDeleteConfirmation from "../TeamDeleteConfirmation/TeamDeleteConfirmation"
import TeamMemberCard from "../TeamMemberCard/TeamMemberCard"
import ArchiveTeamModal from "./ArchiveTeamModal"
import TeamSummaryDetail from "../TeamSummaryDetail/TeamSummaryDetail"
import Aid from "../../constants/automationId"
import { trackTeamBasedFeedbackRequested } from "../../utils/analytics/events"
import { ActionItems } from "../../components/ActionItems/ActionItems"
import { MenuItem } from "../../types/MenuItem"
import useNotifications from "../../hooks/toastNotifications/useNotifications"

// @ts-ignore
import BreadcrumbAndMenuHeader from "../../components/BreadcrumbAndMenuHeader/BreadcrumbAndMenuHeader"
import ConfigurationOptions from "../../constants/configurationOptions"

type TeamType = React.FunctionComponent<
  {
    intl: InjectedIntl
  } & WithRouterProps
>

type Tab = {
  label: string
  active: boolean
  onClick?: () => void
}

/*
 * Used to determine if a team has enough members to request peer feedback.
 * Abstracted for unit testing. This function requires a team that adheres
 * to having a details property.
 *
 * @param {Team} team
 * @returns {boolean} sufficient team member count for peer feedback
 */
export const hasMoreThanOnePeer = (team: Pick<ITeam, "details">) =>
  Boolean(
    (team?.details?.teamLeads?.length || 0) +
      (team?.details?.teamMembers?.length || 0) >
      1
  )

/**
 * Returns Active Teams page
 *
 * @param {Team} props { router, params, location, intl }
 * @returns {Team}
 */
const Team: TeamType = (props) => {
  const { router, params, location, intl } = props
  const { formatMessage } = intl
  const { teamId } = params
  const { team } = useTeam(teamId)
  const currentUser = useCurrentUser()
  const company = currentUser.company
  const { showNotification } = useNotifications()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showArchiveTeamModal, setShowArchiveTeamModal] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const showNewTeamModal =
    location.state &&
    location.state.newTeam &&
    team &&
    userBelongsToTeam(currentUser, team)

  useEffect(() => {
    if (
      team &&
      useNotifications &&
      showNewTeamModal &&
      !hasConfigOption(currentUser, ConfigurationOptions.teamGoals)
    ) {
      showNotification({
        type: "affirmative",
        title: formatMessage(strings.teamGoals.confirmationModal.title),
        message: "",
      })
    }
  }, [team, formatMessage, showNewTeamModal, currentUser, showNotification])

  // if team preloaded, can force broken, endless redirect
  if (!team) return <Loading />
  // archivedAt values always empty string or timestamp,
  // hence the unusual comparison
  if (team?.details?.archivedAt !== "") {
    router.push(`/teams/archived/${teamId}`)
  }

  const currentUserIsTeamLead =
    team.details &&
    team.details.teamLeads
      .map(({ aggregateId }) => aggregateId)
      .includes(currentUser.employee_aggregate_id)
  const isEditable = isAdmin(currentUser) || currentUserIsTeamLead

  const teamDetails = () => (
    <>
      {team.details && (
        <div className={styles.cards}>
          {team.details.teamLeads.map((teamMember) => (
            <TeamMemberCard
              key={teamMember.aggregateId}
              lead={true}
              teamMember={teamMember}
            />
          ))}
          {team.details.teamMembers.map((teamMember) => (
            <TeamMemberCard
              key={teamMember.aggregateId}
              teamMember={teamMember}
            />
          ))}
        </div>
      )}
    </>
  )

  const canRequestPeerFeedback =
    hasConfigOption(currentUser, ConfigurationOptions.teamBasedFeedback) &&
    company.allow_reviews &&
    currentUserIsTeamLead &&
    hasMoreThanOnePeer(team)

  const menuItems = compact([
    canRequestPeerFeedback && {
      automationId: Aid.requestTeamBasedFeedback,
      text: strings.teams.newTeamForm.requestPeerFeedback,
      icon: requestFeedbackIcon,
      primary: true,
      priorityAction: true,
      onClick: () => {
        trackTeamBasedFeedbackRequested()
        router.push(`team_based_feedback/${teamId}`)
      },
    },
    isEditable && {
      text: strings.teams.options.editTeam,
      icon: editIcon,
      onClick: () => router.push(`teams/edit/${teamId}`),
      priorityAction: true,
    },
    isEditable && {
      text: strings.teams.options.archive,
      icon: archivedIcon,
      onClick: () => setShowArchiveTeamModal(true),
      priorityAction: true,
    },
    isEditable && {
      text: strings.teams.options.delete,
      icon: deleteIcon,
      onClick: () => setShowDeleteConfirmation(true),
      priorityAction: true,
    },
  ]) as MenuItem[]

  return (
    <div>
      {showDeleteConfirmation && (
        <TeamDeleteConfirmation
          teamId={teamId}
          onTeamDeleted={() => router.push("/teams")}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
      {showNewTeamModal &&
        hasConfigOption(currentUser, ConfigurationOptions.teamGoals) && (
          <NewTeamModal router={router} teamId={teamId} />
        )}
      {showArchiveTeamModal && (
        <ArchiveTeamModal
          teamId={teamId}
          onArchiveTeam={() => router.push("/teams")}
          onCancel={() => setShowArchiveTeamModal(false)}
        />
      )}
      <div className={styles.navigation}>
        <Button
          secondary
          label={formatMessage(strings.teams.newTeamForm.back)}
          icon={leftIcon}
          onClick={() => router.push(`teams`)}
        />
      </div>

      <BreadcrumbAndMenuHeader
        headerText={team.name}
        subheaderText={team.description}
        leftAligned
        hasMenu
      >
        <ActionItems menuItems={menuItems} />
      </BreadcrumbAndMenuHeader>

      <div className={styles.tabContainer}>
        <Tabs
          tabs={[
            {
              label: formatMessage(strings.teams.teamTabs.members),
              onClick: () => setActiveTab(0),
              active: activeTab === 0,
            },
            hasConfigOption(currentUser, ConfigurationOptions.teamGoals) && {
              label: formatMessage(strings.teams.teamTabs.goals),
              onClick: () => setActiveTab(1),
              active: activeTab === 1,
            },
          ]}
        />
      </div>
      {activeTab === 0 ? teamDetails() : <TeamSummaryDetail {...props} />}
    </div>
  )
}

export default injectIntl(Team)
