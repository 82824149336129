import Reflux from "reflux-core"
import Actions from "../refluxActions"
import observeSearchActions from "./lib/observeSearchActions"

const GivenFeedbackStore = Reflux.createStore({
  init() {
    this.data = {
      reviewsGivenByRevieweeId: {},
      reviewsGivenSearchResults: null,
      reviewsGivenPagination: null,
    }

    this.listenTo(
      Actions.Feedback.localClearReviewsGivenByRevieweeId,
      this.onLocalClearReviewsGivenByRevieweeId
    )

    this.listenTo(
      Actions.Feedback.loadReviewsGivenByRevieweeId.completed,
      this.onLoadReviewsGivenByRevieweeId
    )

    this.listenTo(Actions.Review.complete.completed, this.onReviewCompleted)

    observeSearchActions(this, {
      getResults: () => this.data.reviewsGivenSearchResults,
      searchAction: Actions.Feedback.loadReviewsGiven,
      pageAction: Actions.Feedback.pageReviewsGiven,
      onChange: (reviewsGivenSearchResults, { pagination }) => {
        this.data = {
          ...this.data,
          reviewsGivenSearchResults,
          reviewsGivenPagination: pagination,
        }
        this.trigger(this.data)
      },
    })
  },

  getInitialState() {
    return this.data
  },

  appendReviewsToReviewsGivenByRevieweeId({
    currentReviewData,
    reviewsToAppend,
  }) {
    const reviews = currentReviewData && currentReviewData.reviews

    if (!reviewsToAppend) {
      return { reviews, hasMore: false }
    } else if (!reviews) {
      return { reviews: reviewsToAppend, hasMore: !!reviewsToAppend.length }
    } else {
      return {
        reviews: [...reviews, ...reviewsToAppend],
        hasMore: !!reviewsToAppend.length,
      }
    }
  },

  _replaceReviewsGivenByRevieweeId({ revieweeId, reviews }) {
    this.data = {
      ...this.data,
      reviewsGivenByRevieweeId: {
        ...this.data.reviewsGivenByRevieweeId,
        [revieweeId]: this.appendReviewsToReviewsGivenByRevieweeId({
          currentReviewData: this.data.reviewsGivenByRevieweeId[revieweeId],
          reviewsToAppend: reviews,
        }),
      },
    }
  },

  onLoadReviewsGivenByRevieweeId({ revieweeId, reviews }) {
    this._replaceReviewsGivenByRevieweeId({ revieweeId, reviews })
    this.trigger(this.data)
  },

  onLocalClearReviewsGivenByRevieweeId({ revieweeId }) {
    this.data = {
      ...this.data,
      reviewsGivenByRevieweeId: {
        ...this.data.reviewsGivenByRevieweeId,
        [revieweeId]: null,
      },
    }
    this.trigger(this.data)
  },

  onReviewCompleted(review) {
    const revieweeId = review.reviewee.id
    const existingReviews = this.data.reviewsGivenByRevieweeId[revieweeId] || []

    this._replaceReviewsGivenByRevieweeId(revieweeId, [
      ...existingReviews,
      { source_obj: { review, type: "review" } },
    ])

    this.trigger(this.data)
  },
})

export default GivenFeedbackStore
