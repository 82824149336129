import { useEffect, useRef } from "react"

// Keep track of when the component is still mounted, as so we can cancel
// our async hooks.
export const useIsMounted = () => {
  const isMountedRef = useRef(true)
  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )
  return isMountedRef
}
