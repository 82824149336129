import _ from "lodash"
import Reflux from "./ActionsInitializer"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import { adaptGoalsParams } from "./lib/goalsHelpers"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import SearchAgent from "./lib/SearchAgent"
import strings from "../locale/strings"
import endpoints from "../constants/endpointsDeprecated"

const { GOALS_URLS, TEAMS_URLS } = endpoints
const { GOALS_URL } = GOALS_URLS
const { TEAM_GOALS_URL } = TEAMS_URLS
const NUM_MENTIONS_TO_LOAD = 5

const agent = SteadyfootAgent.defaultInstance

const teamSearchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: TEAM_GOALS_URL,
  resourceName: "goals",
  pageSize: 25,
})

const GoalActions = Reflux.createActions({
  list: { asyncResult: true },
  listAlignable: { asyncResult: true },
  load: { asyncResult: true },
  loadAccomplishedGoals: { asyncResult: true },
  create: { asyncResult: true },
  update: { asyncResult: true },
  delete: { asyncResult: true },
  markAsManagerSeen: { asyncResult: true },
  searchTeamGoals: { asyncResult: true },
  pageTeamGoals: { asyncResult: true },
  setLastSelectedDeptId: { sync: true },
  loadUsersForMention: { asyncResult: true },
  clearGoals: {},
})

GoalActions.list.listenAndPromise(
  ({
    userId = null,
    usersDepartmentId = null,
    departmentId = null,
    type = null,
    showLoadingState = true,
    from = null,
    to = null,
    sortBy,
    sortOrder = "asc",
  } = {}) => {
    if (!(departmentId || userId)) {
      throw new Error("You must either supply departmentId or userId")
    }

    if (departmentId && (userId || usersDepartmentId)) {
      throw new Error(
        "If departmentId is provided, userId and usersDepartmentId must not \
      be provided"
      )
    }

    return addLoadingState(
      showLoadingState,
      addToasts(
        { defaultError: strings.goals.errorMessages.loadingGoals },
        extractResponseKey(
          "goals",
          agent.get(GOALS_URL).query({
            per_page: 1e9,
            user_id: userId || undefined,
            type: type || undefined,
            // NB: usersDepartmentId isn't used here because SF automatically includes a user's
            // department's goals in the user goal load
            department_id: userId ? undefined : departmentId,
            sort_by: sortBy,
            sort_order: sortBy ? sortOrder : undefined,

            from: from ? from.toISOString() : undefined,
            to: to ? to.toISOString() : undefined,
          })
        ).then((goals) => ({
          goals,
          userId,
          departmentId: usersDepartmentId || departmentId,
          couldIncludeCompanyGoals: !!userId,
        }))
      )
    )
  }
)

GoalActions.listAlignable.listenAndPromise(({ query, types, page }) => {
  return addToasts(
    { defaultError: strings.goals.errorMessages.loadingGoals },
    extractResponseBody(
      agent.get(`${GOALS_URL}/alignable`).query({
        q: query,
        types,
        page,
      })
    )
  )
})

GoalActions.load.listenAndPromise(({ id, userId, shouldAddToasts = true }) => {
  const goalPromise = extractResponseKey(
    "goal",
    agent.get(`${GOALS_URL}/${id}`).query({ user_id: userId })
  )
  return shouldAddToasts
    ? addToasts(
        { defaultError: strings.goals.errorMessages.loadingGoal },
        goalPromise
      )
    : goalPromise
})

GoalActions.loadAccomplishedGoals.listenAndPromise(() =>
  addLoadingState(
    [true],
    addToasts(
      { defaultError: strings.goals.errorMessages.loadingGoals },
      extractResponseKey(
        "goals",
        agent.get(GOALS_URL).query({
          type: "my_goal",
          status: "accomplished",
        })
      ).then((goals) => ({ goals }))
    )
  )
)

function getGoalAPIParams(goal, originalGoal = null) {
  const goalsSkillsDeletions = _(_.get(originalGoal, "goals_skills"))
    .map("id")
    .difference(_.map(goal.goals_skills, "id"))
    .map((id) => ({ id, _destroy: true }))
    .value()

  const params = {
    ..._.omit(
      goal,
      "id",
      "goals_skills",
      "completed_at",
      "individuals",
      "access_permissions"
    ),

    goals_skills_attributes: _(goal.goals_skills)
      .map((gs) => _.pick(gs, "id", "skill_id"))
      .concat(goalsSkillsDeletions)
      .value(),

    type: goal.goal_type,
    access_permission:
      goal.visibility === "individuals" && goal.individuals
        ? { user_ids: _.map(goal.individuals, "id"), permission: "read" }
        : undefined,
  }

  // "completion" can't be set if key results are present
  return _.isEmpty(params.key_results) ? params : _.omit(params, "completion")
}

GoalActions.create.listenAndPromise(({ goal, targetRange }) =>
  addLoadingState(
    true,
    addToasts(
      {
        success: strings.goals.successMessages.publishingGoal,
        defaultError: strings.goals.errorMessages.publishingGoal,
      },
      extractResponseKey(
        "goal",
        agent.post(GOALS_URL).send({ goal: getGoalAPIParams(goal) })
      ).then((newGoal) => ({ newGoal, targetRange }))
    )
  )
)

GoalActions.update.listenAndPromise(
  ({
    originalGoal,
    updatedGoal,
    successMessage = strings.goals.successMessages.updatingGoal,
  }) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {
          success: successMessage,
          defaultError: strings.goals.errorMessages.savingGoal,
        },
        extractResponseKey(
          "goal",
          agent
            .put(`${GOALS_URL}/${updatedGoal.id}`)
            .send({ goal: getGoalAPIParams(updatedGoal, originalGoal) })
        )
      )
    )
)

GoalActions.delete.listenAndPromise((goal) =>
  addLoadingState(
    true,
    addToasts(
      {
        success: strings.goals.successMessages.deletingGoal,
        defaultError: strings.goals.errorMessages.deletingGoal,
      },
      extractResponseKey("goal", agent.del(`${GOALS_URL}/${goal.id}`))
    )
  )
)

GoalActions.markAsManagerSeen.listenAndPromise((goalId) =>
  extractResponseKey(
    "goal",
    agent.put(`${GOALS_URL}/${goalId}`).send({ goal: { manager_seen: true } })
  )
)

/**
 * @param {string=} query
 * @param {string=} managerId
 * @param {number=} jobTitleId
 * @param {string=} status
 * @param {Date=} startDate
 * @param {Date=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name | completion
 * @param {string=} sortOrder - asc | desc
 */
GoalActions.searchTeamGoals.listenAndPromise((searchParams = {}) =>
  addLoadingState(
    [true, { light: true }],
    teamSearchAgent.search(adaptGoalsParams(searchParams))
  )
)

/**
 * @param {number} page
 * @param {string=} query
 * @param {string=} managerId
 * @param {number=} jobTitleId
 * @param {string=} status
 * @param {Date=} startDate
 * @param {Date=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name | completion
 * @param {string=} sortOrder - asc | desc
 */
GoalActions.pageTeamGoals.listenAndPromise((searchParams) =>
  teamSearchAgent.search(adaptGoalsParams(searchParams))
)

GoalActions.loadUsersForMention.listenAndPromise(({ goalId, query }) =>
  extractResponseBody(
    agent.get(`${GOALS_URL}/${goalId}/mention_users`).query({
      per_page: NUM_MENTIONS_TO_LOAD,
      q: query,
    })
  )
)

export default GoalActions
