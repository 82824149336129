// React
import React, { useEffect, useState } from "react"

// External Modules
import { HelpCenter, SuggestedArticle } from "@cultureamp/help-center"
import { Location } from "history"
import { parse, ParsedQuery } from "query-string"

// Modules
import getSuggestedArticles from "./getSuggestedArticles"

// Hooks
import { useRouter, RouterInterface } from "../../hooks/routing/useRouter"

// Constants
export const HELP_REDIRECT_QUERY_KEY = "helpRedirect"
const HELP_HASH = "#help"

// Types
type HelpCenterState = "open" | "closed"

const isLegacyRedirect = (search: string): boolean => {
  const parsedSearchObject: ParsedQuery = parse(search)
  return (
    !!parsedSearchObject[HELP_REDIRECT_QUERY_KEY] &&
    parsedSearchObject[HELP_REDIRECT_QUERY_KEY] === "true"
  )
}

export const toggleHelpHash = (
  router: RouterInterface,
  state: HelpCenterState,
  preserveQueryString = true
) => {
  const { pathname, search } = router.location
  router[state === "open" ? "push" : "replace"]({
    pathname,
    ...(preserveQueryString && { search }),
    ...(state === "open" && { hash: HELP_HASH }),
  })
}

const HelpCenterWrapper = () => {
  // Hooks
  const [currentHash, updateCurrentHash] = useState<string>("")
  const [suggestedArticles, setSuggestedArticles] = useState<
    Array<SuggestedArticle>
  >([])
  const router: RouterInterface = useRouter()

  // Lifecycle
  useEffect(() => {
    if (isLegacyRedirect(router.location.search))
      toggleHelpHash(router, "open", false)
    updateCurrentHash(router.location.hash)
  }, [router])

  useEffect(() => {
    getSuggestedArticles(router.location.pathname).then((result) =>
      setSuggestedArticles(result.suggested_articles)
    )
  }, [router.location.pathname, setSuggestedArticles])

  useEffect(() => {
    const unlisten = router.listen((location: Location) =>
      updateCurrentHash(location.hash)
    )
    return () => unlisten()
  }, [router])

  return (
    <HelpCenter
      isOpen={currentHash === HELP_HASH}
      close={() => {
        toggleHelpHash(router, "closed")
      }}
      {...{ suggestedArticles }}
    />
  )
}

export default HelpCenterWrapper
