import Settings from "../settings"

/**
 * Return true if user is on a mobile browser
 */
export function isMobileBrowser() {
  return window.matchMedia(`(max-width: ${Settings.MOBILE_WIDTH}px)`).matches
}

export function isSmallScreenBrowser() {
  return window.matchMedia(`(max-width: ${Settings.SMALL_SCREEN_WIDTH}px)`)
    .matches
}
