import { useCallback, useEffect, useState } from "react"
import { useIsMounted } from "../react/useIsMounted"

// See docs/apiFetching.md, for some related documentation.

/**
 * Wrapper that takes any promise, and supplies the loading, error, and data
 * values in an object array.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncCallback = <T, Args extends any[]>(
  cb: (...arg: Args) => Promise<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[]
): {
  isLoading: boolean
  data: T | undefined
  error: Error | undefined
  fire: (...args: Args) => Promise<T>
} => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const isMountedRef = useIsMounted()

  const fire = useCallback(
    async (...args: Args) => {
      setIsLoading(true)
      try {
        const data: T = await cb(...args)
        if (isMountedRef.current) {
          setError(undefined)
          setData(data)
        }
        return data
      } catch (ex) {
        if (isMountedRef.current) {
          setData(undefined)
          setError(ex)
        }
        throw ex
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  return {
    isLoading,
    data,
    error,
    fire,
  }
}

// Wrapper around the `useAsyncCallback` hook. Convenient for when you just
// want to fetch some data and display it on a page.
// Unlike the regular `useEffect` hook, this version of the callback does not
// take in a cleanup return. Therefore you can pass promises directly into the
// function.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncEffect = <T, Args extends any[]>(
  cb: () => Promise<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[]
): {
  isLoading: boolean
  data: T | undefined
  error: Error | undefined
  fire: (...args: Args) => Promise<T>
} => {
  const { isLoading, data, error, fire } = useAsyncCallback(cb, deps)

  useEffect(() => {
    fire()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return {
    isLoading,
    data,
    error,
    fire,
  }
}
