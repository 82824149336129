import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import _ from "lodash"
import cx from "classnames"
import favoriteOn from "@kaizen/component-library/icons/favorite-on.icon.svg"
import favoriteOff from "@kaizen/component-library/icons/favorite-off.icon.svg"
import sharing from "@kaizen/component-library/icons/report-sharing.icon.svg"
import starOn from "@kaizen/component-library/icons/star-on.icon.svg"
import starOff from "@kaizen/component-library/icons/star-off.icon.svg"
import ShareModal from "../modals/ShareModal"
import User from "../../models/User"
import Button from "../../components/Button/Button"
import MessageGroup from "./MessageGroup"
import ConversationMessage, { genericMessageProps } from "./ConversationMessage"
import Actions from "../../refluxActions"
import Ratings from "../Ratings/Ratings"
import strings from "../../locale/strings"
import {
  discardLikeReactions,
  getLikeReaction,
} from "../../models/FeedbackReactionTypes"
import ShareReactionsModal from "./ShareReactionsModal"
import "./FeedbackMessage.less"

class FeedbackMessage extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  }

  static propTypes = {
    ...genericMessageProps,
    review: PropTypes.object.isRequired,
    onLike: PropTypes.func,
    updateReactionsAction: PropTypes.func.isRequired,
    isActionBarTop: PropTypes.bool,
  }

  static defaultProps = {
    isActionBarTop: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      showShareModal: false,
      showTooltip: false,
      justLiked: false,
    }
  }

  openShareModal = () => {
    this.setState({ showShareModal: true })
  }

  closeShareModal = () => {
    this.setState({ showShareModal: false })
  }

  handleLikeClick = () => {
    const { review } = this.props
    const currentlyLiked = this.isLiked()
    let updates = [{ reaction_type: "like" }] // eslint-disable-line camelcase
    if (currentlyLiked) {
      const likeReaction = review.reactions && getLikeReaction(review.reactions)
      updates = [{ id: likeReaction && likeReaction.id, _destroy: true }]
    }
    this.props.onLike({ review, updates: updates })
    this.setState({ justLiked: !currentlyLiked })
  }

  handleShare = ({ recipients, notes }) => {
    const {
      review: { id },
      intl: { formatMessage },
    } = this.props
    Actions.Feedback.shareReview({
      recipientIds: recipients.map((recipient) => recipient.id),
      notes,
      reviewId: id,
      formatMessage,
    }).then(() => this.closeShareModal())
  }

  handleTooltipVisibleChange = () => {
    this.setState({ showTooltip: true })
  }

  handleTooltipClose = () => {
    this.setState({ showTooltip: false })
  }

  isLiked() {
    const { review } = this.props
    return (
      this.state.justLiked ||
      (review.reactions && getLikeReaction(review.reactions))
    )
  }

  hasReactions() {
    const { review } = this.props
    return review.reactions && discardLikeReactions(review.reactions).length > 0
  }

  userIsReviewee() {
    const { review } = this.props
    return review.reviewee.email === this.context.user.email
  }

  renderReceipt() {
    const {
      review: { reviewee },
    } = this.props
    return (
      <FormattedMessage
        {...strings.pastFeedback.receiptText}
        values={{ firstName: User.getFirstName(reviewee) }}
      />
    )
  }

  renderLikeButton() {
    if (!this.props.onLike) {
      return null
    }

    const liked = this.isLiked()
    const likeClasses = {
      liked: liked,
      "just-liked": this.state.justLiked,
      "FeedbackMessage--like-button-reviewer-style": !this.userIsReviewee(),
    }

    return (
      <Button
        actionType="secondary"
        className={cx("FeedbackMessage--like-button", likeClasses)}
        onClick={this.userIsReviewee() ? this.handleLikeClick : null}
        icon={liked ? favoriteOn : favoriteOff}
      >
        <FormattedMessage
          {...(liked ? strings.general.liked : strings.general.like)}
        />
      </Button>
    )
  }

  renderShareButton() {
    const { reviewee } = this.props.review
    if (reviewee.email !== this.context.user.email) {
      return null
    }

    return (
      <Button
        actionType="secondary"
        className="FeedbackMessage--share-button"
        onClick={this.openShareModal}
        icon={sharing}
      >
        <FormattedMessage {...strings.general.share} />
      </Button>
    )
  }

  renderReactionsButton = () => {
    const hasReactions = this.hasReactions()
    return (
      <Button
        actionType="secondary"
        className={cx("FeedbackMessage--react-button", {
          "FeedbackMessage--react-button-has-reactions": hasReactions,
        })}
        onClick={this.handleTooltipVisibleChange}
        icon={hasReactions ? starOn : starOff}
      >
        <FormattedMessage {...strings.pastFeedback.isFeedbackHelpful} />
      </Button>
    )
  }

  render() {
    const {
      review: {
        reviewer,
        reviewee,
        notes,
        id,
        sharees,
        ratings,
        paid_for: paidFor,
      },
      timestamp,
      updateReactionsAction,
      review,
      isActionBarTop,
    } = this.props
    const { showShareModal, showTooltip } = this.state

    return (
      <div className="FeedbackMessage">
        {isActionBarTop && (
          <div className="FeedbackMessage--action-bar FeedbackMessage--action-bar-top layout horizontal start-justified">
            {this.renderLikeButton()}
            {this.renderShareButton()}
            {this.userIsReviewee() && this.renderReactionsButton()}
          </div>
        )}
        <div
          className={cx("FeedbackMessage--ConversationMessage", {
            "FeedbackMessage--ConversationMessage-withBorder":
              ratings.length > 0,
          })}
        >
          <ConversationMessage
            body={
              <div>
                <div>{notes}</div>
                {!!_.get(sharees, "length") &&
                  this.context.user.email === reviewee.email && (
                    <div className="FeedbackMessage--shared-list">
                      {
                        <FormattedMessage
                          {...strings.comments.share.sharedWith}
                          values={{
                            commaSeparatedNames: _.map(
                              sharees,
                              "full_name"
                            ).join(", "),
                          }}
                        />
                      }
                    </div>
                  )}
                {paidFor && !this.userIsReviewee() && (
                  <div className="FeedbackMessage--receipt">
                    {this.renderReceipt()}
                  </div>
                )}
                {!isActionBarTop && (
                  <div className="FeedbackMessage--action-bar layout horizontal start-justified">
                    {this.renderLikeButton()}
                    {this.renderShareButton()}
                    {this.userIsReviewee() && this.renderReactionsButton()}
                  </div>
                )}
              </div>
            }
            id={id}
            sender={reviewer}
            isWhiteBg
            isPaddingZero
          />
        </div>
        {ratings.length > 0 && <Ratings ratings={ratings} />}

        <ShareModal
          opened={showShareModal}
          onClose={this.closeShareModal}
          header={
            <FormattedMessage
              {...strings.comments.share.yourFeedback}
              values={{
                name: reviewer.email ? (
                  User.getDisplayName(reviewer)
                ) : (
                  <FormattedMessage {...strings.general.anonymous} />
                ),
              }}
            />
          }
          preview={
            <MessageGroup
              messages={[
                <ConversationMessage
                  key={id}
                  body={notes}
                  timestamp={timestamp}
                  id={id}
                  sender={reviewer}
                  isWhiteBg
                />,
              ]}
            />
          }
          onShare={this.handleShare}
        />

        <ShareReactionsModal
          opened={showTooltip}
          translucent={true}
          review={review}
          onUpdateReactions={updateReactionsAction}
          onClose={() => this.setState({ showTooltip: false })}
        />
      </div>
    )
  }
}

export default injectIntl(FeedbackMessage)
export { FeedbackMessage as RawFeedbackMessage }
