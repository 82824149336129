import React from "react"

import cx from "classnames"

import styles from "./PageLayout.scss"

import { Drawer } from "../Drawer/Drawer"
import { Dossier } from "../Dossier/Dossier"
import useCurrentUser from "../../../domainHooks/auth/useCurrentUser"
import FeatureFlags from "../../../constants/featureFlags"
import User from "../../../models/User"

export type DossierAndDrawerState = {
  dossierIsOpen: boolean
  drawerIsOpen: boolean
}

type DossierSettings = {
  isOpen: boolean
  content: React.ReactElement
  onClose?: () => void
  size?: "small" | "default"
}
type DrawerSettings = {
  heading?: React.ReactElement | string
  footer?: React.ReactElement | string
  isOpen: boolean
  content: React.ReactElement
  onToggle?: () => void
}

type PageLayoutProps = {
  children: React.ReactNode
  dossierSettings?: DossierSettings
  drawerSettings?: DrawerSettings
  headingContent?: React.ReactElement
  colorScheme?: "stone" | "subtle" | "white"
  legacyClassnames?: string
  forceShowHeader?: boolean
}

export const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
  children,
  colorScheme = "white",
  headingContent,
  drawerSettings,
  dossierSettings,
  legacyClassnames,
  forceShowHeader = false,
}) => {
  const currentUser = useCurrentUser()
  const enableHeader =
    User.hasFlag(currentUser, FeatureFlags.topNavigation) &&
    User.hasFlag(currentUser, FeatureFlags.zenHeader)

  return (
    <main
      className={cx(
        [styles.root, styles[`${colorScheme}Color`], legacyClassnames],
        {
          [styles.dossierOpen]: dossierSettings?.isOpen,
          [styles.dossierIsSmall]: dossierSettings?.size === "small",
          [styles.drawerOpen]: drawerSettings?.isOpen,
          [styles.hasDrawer]: !!drawerSettings,
        }
      )}
    >
      {(enableHeader || forceShowHeader) && headingContent && (
        <div className={cx(styles.headingWrapper)}>{headingContent}</div>
      )}
      <div className={cx(styles.container)}>{children}</div>
      {!!dossierSettings && (
        <Dossier
          isOpen={dossierSettings.isOpen}
          hasDrawer={!!drawerSettings}
          onClose={dossierSettings.onClose}
          size={dossierSettings.size}
        >
          {dossierSettings.content}
        </Dossier>
      )}
      {!!drawerSettings && (
        <Drawer
          drawerHeading={drawerSettings.heading}
          isOpen={drawerSettings.isOpen}
          onToggle={drawerSettings.onToggle}
          drawerFooter={drawerSettings.footer}
        >
          {drawerSettings.content}
        </Drawer>
      )}
    </main>
  )
}
